import styled from 'styled-components';
import { getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  .slide-item-text {
    word-break: break-word;
  }
  .hidden-text {
    display: none;
  }
  .slide-item-img-wrapper-video {
    position: absolute !important;
    width: 100%;
  }
  .slide-item-btn-video {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 60px;
    fill: #fff;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    margin-top: 16px;
  }
  .video-align-full {
    object-fit: fill;
  }
  .video-align-auto {
    max-height: 100vh;
    text-align: center;
  }
  .swiper-wrapper {
    display: flex;
  }
  .slide-show {
    position: relative;
    overflow: hidden;
  }

  .slide-img-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    background: var(--color_image_overlay);
    opacity: var(--image_overlay_opacity);
  }

  .initial {
    .swiper-slide {
      overflow: hidden;
    }
  }

  .swiper-slide {
    width: 100%;
    min-width: 100%;
    text-align: center;
    font-size: var(--font-18-14);
    /* background: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide-menu {
    height: 32px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .slide-navigation-bar {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide-indicator-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 var(--base-font-24-18);
  }

  .slide-indicator-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;

    + .slide-indicator-dot {
      margin-left: 12px;
    }
  }

  .slide-prev-btn,
  .slide-next-btn {
    cursor: pointer;
    font-size: 24px;
  }

  .pause-control-btn {
    width: 32px;
    height: 32px;
    margin-left: 8px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(21, 22, 27, 0.5);

    .icon {
      fill: #fff;
    }
  }

  .slide-item {
    width: 100%;
    position: relative;

    /* > div {
            height: auto;
        } */
  }
  .slide-item-img-wrapper {
    height: 100%;
    position: relative;
  }
  .slide-item-img {
    width: 100%;
    object-fit: cover;
    position: relative;
  }

  .default-img-box {
    border: none;
  }

  .img-left-top {
    object-position: left top;
  }

  .img-center-top {
    object-position: center top;
  }

  .img-right-top {
    object-position: right top;
  }

  .img-left-center {
    object-position: left center;
  }

  .img-center-center {
    object-position: center center;
  }

  .img-right-center {
    object-position: right center;
  }

  .img-left-bottom {
    object-position: left bottom;
  }

  .img-center-bottom {
    object-position: center bottom;
  }

  .img-right-bottom {
    object-position: right bottom;
  }

  .slide-item-btn {
    display: inline-flex;
    background: var(--color_button);
    font-size: var(--base-font-16-14);
    color: #fff;
    line-height: 16px;
    padding: 20px 32px;
    border-radius: var(--border_radius_btn);
  }

  /* .slide-item {
        height: 100%;
    } */

  .slide-item-img-wrapper,
  .slide-item-img,
  .video-wrapper {
    height: ${(props) => props.slideImageHeight};
  }
  .slide-item-desc {
    margin-top: 16px;
  }

  @media screen and (min-width: 751px) {
    .slide-navigation-bar {
      padding: 0 14px;
      background: rgba(21, 22, 27, 0.5);
    }

    .slide-prev-btn,
    .slide-next-btn {
      color: #fff;
    }

    .text-center-center {
      .slide-item-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .text-right-top,
    .text-center-top,
    .text-left-top {
      .slide-item-text {
        top: 80px;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }

    .text-right-bottom,
    .text-right-center,
    .text-right-top {
      .slide-item-desc,
      .slide-item-title,
      .slide-item-btn-wrapper {
        text-align: right;
      }
    }

    .text-left-bottom,
    .text-left-center,
    .text-left-top {
      .slide-item-desc,
      .slide-item-title,
      .slide-item-btn-wrapper {
        text-align: left;
      }
    }

    .text-center-bottom,
    .text-center-bottom,
    .text-center-top {
      .slide-item-desc,
      .slide-item-title,
      .slide-item-btn-wrapper {
        text-align: center;
      }
    }

    .text-right-center,
    .text-left-center {
      .slide-item-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .text-right-bottom,
    .text-center-bottom,
    .text-left-bottom {
      .slide-item-text {
        bottom: 80px;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }

    .slide-item-text {
      width: ${getPercentWidth(240)};
      position: absolute;
    }

    .slide-item-title {
      text-align: center;
      text-shadow: 0 0 4px rgba(21, 22, 27, 0.5);
      font-weight: bold;
    }

    .slide-item-desc {
      text-align: center;
      text-shadow: 0 0 4px rgba(21, 22, 27, 0.5);
    }

    .slide-menu {
      bottom: 16px;
    }

    .slide-item-btn {
      margin-top: var(--base-font-24-18);
    }

    .slide-indicator-dot {
      background: #fff;
      opacity: 0.6;
      &.slide-indicator-active {
        opacity: 1;
      }
    }

    /** 默认图 */
    /* .slide-item {
            max-height: 100vh;
        } */
  }

  .text-size-medium {
    .slide-item-title {
      ${(props) => props.theme.getBaseSize([24, 32])}
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
    }

    .slide-item-desc {
      ${(props) => props.theme.getBaseSize([16, 24])}
    }
  }

  .text-size-large {
    .slide-item-title {
      ${(props) => props.theme.getBaseSize([32, 48])}
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
    }

    .slide-item-desc {
      ${(props) => props.theme.getBaseSize([24, 32])}
    }
  }

  @media screen and (max-width: 750px) {
    .slide-item-desc {
      max-height: 70px;
      overflow: hidden;
    }
    .slide-item-img-wrapper,
    .slide-item-img,
    .video-wrapper {
      img {
        max-height: 100%;
      }
    }
    .slide-item-btn-video {
      width: 40px;
      height: 30px;
      .default-touxiang {
        height: 13px !important;
      }
    }

    /* 文本在图片下 */
    &.on-image-down {
      .hidden-text {
        display: block !important;
      }
      .slide-item-btn-video {
        position: absolute;
        top: ${(props) => `calc(${props.slideImageHeight} / 2 - 15px)`};
        left: 0;
        right: 0;
        margin: auto;
      }
      .slide-indicator-dot {
        background: var(--color_button);
        opacity: 0.6;
        &.slide-indicator-active {
          opacity: 1;
        }
      }
      .slide-item-text {
        margin: auto;
        padding: 44px 0 40px;
        width: ${getPercentWidth(24, 375)};
        color: #1a1a1b !important;
      }

      .slide-menu {
        width: ${getPercentWidth(16, 375)};
        transform: translate(-50%, -50%);
        background-color: #fff;
        top: ${(props) => `calc(${props.slideImageHeight})`};
        .slide-navigation-bar {
          width: 100%;
          padding: 0 19px;
          justify-content: space-between;
        }
      }
    }

    /* 文本在图片上 -样式和PC端保持一致，可配置文本的位置 */
    &.on-image-up {
      .slide-menu {
        height: auto;
        bottom: 16px;
        .slide-navigation-bar {
          padding: 0 14px;
          color: #fff;
          .slide-next-btn,
          .slide-prev-btn {
            display: none;
          }
          .slide-indicator-dot {
            background: var(--color_button);
            opacity: 0.6;
            &.slide-indicator-active {
              opacity: 1;
            }
          }
        }
      }
      .slide-item-text {
        width: 88%;
        position: absolute;
      }

      .text-center-top,
      .text-left-top,
      .text-right-top {
        .slide-item-text {
          top: 30px;
          transform: translate(-50%, 0%);
        }
      }
      .text-center-top {
        .slide-item-text {
          left: 50%;
        }
      }
      .text-left-top {
        .slide-item-text {
          left: 50%;
        }
      }
      .text-right-top {
        .slide-item-text {
          left: 50%;
        }
      }
      .text-right-bottom,
      .text-right-center,
      .text-right-top {
        .slide-item-text {
          text-align: right;
        }
      }

      .text-left-bottom,
      .text-left-center,
      .text-left-top {
        .slide-item-desc,
        .slide-item-title,
        .slide-item-btn-wrapper {
          text-align: left;
        }
      }

      .text-center-bottom,
      .text-center-top {
        .slide-item-desc,
        .slide-item-title {
          text-align: center;
        }
      }

      .text-left-center,
      .text-center-center,
      .text-right-center {
        .slide-item-text {
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .text-left-center {
        .slide-item-text {
          left: 50%;
        }
      }
      .text-right-center {
        .slide-item-text {
          left: 50%;
        }
      }
      .text-center-center {
        .slide-item-text {
          left: 50%;
        }
      }

      .text-right-bottom,
      .text-left-bottom,
      .text-center-bottom {
        .slide-item-text {
          bottom: 30px;
          transform: translate(-50%, 0%);
        }
      }
      .text-right-bottom {
        .slide-item-text {
          left: 50%;
        }
      }
      .text-left-bottom {
        .slide-item-text {
          left: 50%;
        }
      }
      .text-center-bottom {
        .slide-item-text {
          left: 50%;
        }
      }
    }

    .slide-item-btn {
      margin-top: var(--pm-16-10);
      padding: 12px 16px !important;
    }
  }
`;
