import React from 'react';
import { Dialog, Button } from 'zent';
import { downloadImage } from '../../../../util/image';
import { GlobalStyle } from './style';

export const Poster = ({ imgUrl, download, intl, closeDialog }) => {
  const savePicture = () => {
    downloadImage({
      fileName: intl.formatMessage({
        id: '0ff83a2b04f44b9d90d1d2474041c0ff',
        defaultMessage: '海报',
      }),
      imgUrl,
    });
  };

  return (
    <div>
      <GlobalStyle />
      <div className='poster-contain'>
        {/* 这是功能组件，无需alt */}
        <img src={imgUrl} className='wx-poster' />
      </div>
      {download ? (
        <div className='share-footer'>
          <Button onClick={closeDialog}>
            {intl.formatMessage({
              id: 'common_cancel',
            })}
          </Button>
          <Button type='primary' className='confirm-time' onClick={savePicture}>
            {intl.formatMessage({
              id: '698bd67771bf40edb296a9fe1773bfc7',
              defaultMessage: '下载海报',
            })}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
export const openPcPosterDialog = ({ intl, title, imgUrl, download = true, introduction = '', alreadyHaveMask }) => {
  const { openDialog, closeDialog } = Dialog;
  const ID = 'pc_dialog_qr_code_id';
  openDialog({
    className: 'distribution-poster-dialog',
    dialogId: ID,
    title,
    mask: !alreadyHaveMask,
    children: (
      <Poster
        intl={intl}
        download={download}
        imgUrl={imgUrl}
        introduction={introduction}
        closeDialog={() => {
          closeDialog(ID);
        }}
      />
    ),
  });
};
