// 动态表单 input
import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Validators, FormInputField } from 'zent';
import { Style } from './style';
import { FormKey } from '../../../form/config';

export const FormInput = ({ settings = {}, componentId, hasRequire, className, orderNumber }) => {
  const intl = useIntl();
  const { title = '', label = '', require = false, input_type: inputType = FormKey.FormText } = settings || {};

  return (
    <Style className={cn(`section-${componentId}`, className)}>
      <div className='form-field-item'>
        {!!title && (
          <p className={cn('form-title', { require, 'has-require': hasRequire && !require })}>
            {orderNumber ? `${orderNumber}.` : ''}
            {title}
          </p>
        )}
        <div className={cn('form-content', { 'form-has-require': hasRequire })}>
          {!!label && <p className='form-desc'>{label}</p>}
          <FormInputField
            props={
              inputType === FormKey.FormText
                ? {
                    type: 'text',
                    placeholder: intl.formatMessage({
                      id: 'common_please_input',
                      defaultMessage: '请输入',
                      description: '请输入',
                    }),
                  }
                : {
                    type: 'textarea',
                    autoSize: false,
                    placeholder: intl.formatMessage({
                      id: 'common_please_input',
                      defaultMessage: '请输入',
                      description: '请输入',
                    }),
                  }
            }
            withoutLabel
            name={componentId}
            validators={
              require
                ? [
                    Validators.required(
                      intl.formatMessage({
                        id: 'common_please_input',
                        defaultMessage: '请输入',
                        description: '请输入',
                      }),
                    ),
                  ]
                : undefined
            }
          />
        </div>
      </div>
    </Style>
  );
};
