// 对应装修标题
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Text } from 'components/base';
import ContainerSize from '../../components/container-size';
import isObject from 'lodash/isObject';
import { Style } from './style';
import { fontSizeMap } from '../../../../const/standard';
import { getUrl } from 'utils/formate';
import throttle from 'lodash/throttle';

const TextSize = {
  small: 'text-size-small',
  medium: 'text-size-medium',
  large: 'text-size-large',
};

const TextTitle = ({ settings = {}, elemClass, className = '' }) => {
  const [clientWidth, setClientWidth] = useState(500);
  const {
    announcement_link: announcementLink,
    background_color: backgroundColor,
    background_image: backgroundImage,
    background_style: backgroundStyle,
    bg_radius: bgRadius,
    box_width: boxWidth,
    text_alignment: textAlignment,
    button_text: buttonText,
    color_decorate: colorDecorate,
    color_decorate_line: colorDecorateLine,
    color_text: colorText,
    color_title: colorTitle,
    color_url_btn: colorUrlBtn,
    content_size: contentSize,
    content_text: contentText,
    show_padding: showPadding,
    title_size: titleSize,
    title_style: titleStyle,
    title_text: titleText,
  } = settings;

  const getClientWidth = () => {
    if (document) {
      setClientWidth(document.body.clientWidth);
    }
  };
  const handleResizeThrottle = throttle(getClientWidth, 100);

  useEffect(() => {
    getClientWidth();
    if (window) window.addEventListener('resize', handleResizeThrottle);
    return () => {
      if (window) window.removeEventListener('resize', handleResizeThrottle);
    };
  });
  const formateAnnouncementUrl = () => {
    let announcementUrl = '';
    try {
      announcementUrl = announcementLink ? getUrl(JSON.parse(announcementLink).url) : announcementLink;
    } catch (error) {
      console.log(error);
    }
    return announcementUrl;
  };
  const toLink = () => {
    let url = formateAnnouncementUrl();
    if (url) {
      window.location.href = url || '#';
    }
  };
  const containerPadding = showPadding
    ? boxWidth === 'fix_960'
      ? '40px'
      : clientWidth > 768
      ? '80px'
      : '16px'
    : '0px';
  // const maxDescWidth = clientWidth <= 375 ? '240px' : 'calc(100% - 100px)';
  const maxDescWidth = clientWidth <= 375 ? '240px' : '100%';
  const decorateSize = fontSizeMap['h' + titleSize][1];
  const newtitleText = titleText.replace(/<p/, '<p style="display:inline;" ');
  return (
    <Style
      className={cn('text-title-section', elemClass, className, `text-title-section-showPadding-${showPadding}`)}
      textAlignment={textAlignment}
      flexDirection={textAlignment === 'center center' ? 'column' : 'row'}
      colorDecorate={colorDecorate}
      colorDecorateLine={colorDecorateLine}
      decorateSize={decorateSize}
    >
      <ContainerSize boxWidth={boxWidth} needSafeSpace>
        <div
          className={cn('text-title-contaier', titleStyle)}
          style={{
            paddingLeft: containerPadding,
            paddingRight: containerPadding,
            borderRadius: bgRadius,
            backgroundColor: backgroundStyle === 'solid_color' ? backgroundColor : '',
            backgroundImage: backgroundStyle === 'background_image' ? 'url(' + backgroundImage + ')' : '',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <div
            className={cn('text-title-content')}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={titleStyle === 'style_seven' ? toLink : () => {}}
          >
            {titleStyle === 'style_three' ? <div className={cn('line')}></div> : ''}
            <div className={cn('title')} onClick={titleStyle === 'style_six' ? toLink : () => {}}>
              {textAlignment === 'left center' && titleStyle === 'style_four' ? (
                // <Text as="div" size={titleSize} color={colorDecorate} style={{fontWeight:900,marginRight:16}}>|
                //     <span className={cn('decorate-left')}></span>
                // </Text>
                <div>
                  <div
                    className={cn('decorate-' + titleSize + 'h')}
                    style={{ backgroundColor: colorDecorate, width: '5px', marginRight: '16px' }}
                  ></div>
                </div>
              ) : (
                ''
              )}
              {textAlignment === 'left center' && titleStyle === 'style_five' ? (
                <Text as='div' size={titleSize}>
                  <span className={cn('decorate-left')}></span>
                </Text>
              ) : (
                ''
              )}
              <Text
                as='div'
                color={colorTitle}
                size={titleSize}
                style={{
                  display: 'inline',
                  fontFamily: 'PingFang SC',
                }}
                dangerouslySetInnerHTML={{
                  __html: isObject(newtitleText) ? '' : newtitleText,
                }}
              ></Text>
              {textAlignment === 'center center' ? <span className={cn('decorate-bottom')}></span> : ''}
              {titleStyle === 'style_six' ? (
                <Text
                  as='span'
                  color={colorDecorate}
                  size={titleSize}
                  style={{ marginLeft: textAlignment === 'left center' && '0px' }}
                  className={cn('decorate')}
                >
                  →
                </Text>
              ) : (
                ''
              )}
            </div>
            <div className={cn('text-title-desc desc')}>
              <Text
                as='div'
                color={colorText}
                size={contentSize}
                style={{
                  marginTop: '16px',
                  maxWidth: maxDescWidth,
                }}
                dangerouslySetInnerHTML={{
                  __html: isObject(contentText) ? '' : contentText,
                }}
              ></Text>
              {titleStyle === 'style_seven' ? (
                <span className={cn('more-btn')} style={{ borderTopColor: colorTitle }}></span>
              ) : (
                ''
              )}
              {/* {announcementLink && */}
              {buttonText && titleStyle !== 'style_six' && titleStyle !== 'style_seven' ? (
                <div
                  className={cn('more-btn')}
                  style={{
                    marginTop: '16px',
                    color: colorUrlBtn,
                    cursor: 'pointer',
                    minWidth: '10px',
                  }}
                  onClick={toLink}
                >
                  {buttonText}
                  <span className={cn('link-arrow')} style={{ borderColor: colorUrlBtn }}></span>
                </div>
              ) : (
                ''
              )}
            </div>
            {titleStyle === 'style_three' ? <div className={cn('line')}></div> : ''}
          </div>
          {titleStyle === 'style_two' ? <div className={cn('line')}></div> : ''}
        </div>
      </ContainerSize>
    </Style>
  );
};

export default TextTitle;
