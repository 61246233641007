import { useState, useEffect, useMemo } from 'react';
import { setCookie, removeCookie } from 'utils/cookie';
import { MULTI_CURRENCY_COOKIE_EXPIRE, MULTI_LANGUAGE_COOKIE_EXPIRE } from '../../../../../../constant';
import { getMultiCurrencyCookieName } from '../../../../../../helper/multi-currency';
import { getMultiLanguageCookieName, getClientLanguage } from '../../../../../../helper/multi-language';
import { formateLacale } from '../../../../../../helper/multi-language';
import ShopApi from 'api/shop';

export const useMultiCurrencyLanguage = (kdtId, initialData = {}) => {
  const { shopSetting = {} } = initialData;
  const [language, setLanguage] = useState(shopSetting.language);
  const [languageList, setLanguageList] = useState([]);
  const [multiCurrency, setMultiCurrency] = useState(shopSetting.currency);
  const [multiCurrencies, setMultiCurrencies] = useState([]);
  const [isMultiCurrencyOpened, setIsMultiCurrencyOpened] = useState(false);

  // 语言列表超过1个，就相当于开启了多语言
  const isShowMultiLanguage = useMemo(() => {
    return languageList?.length > 1;
  }, [languageList]);
  const isShowMultiCurrency = useMemo(() => {
    return multiCurrencies?.length > 1;
  }, [multiCurrencies]);

  // 多币种和多语言开启一个就展示入口，两个都没开启则入口都不要展示了
  const isShowMultiSettingEntry = useMemo(() => {
    return isShowMultiCurrency || isShowMultiLanguage;
  }, [isShowMultiCurrency, isShowMultiLanguage]);

  useEffect(() => {
    const { multiCurrencyInfo, multiLanguageInfo } = initialData;
    setMultiCurrencies(multiCurrencyInfo.multiCurrencies || []);
    setLanguageList(multiLanguageInfo.languageList);
    const clientLanguage = getClientLanguage(initialData);
    if (clientLanguage) {
      setLanguage(clientLanguage);
    }
    if (multiCurrencyInfo.currency) {
      setMultiCurrency(multiCurrencyInfo.currency);
    }
  }, [kdtId]);
  const updateCurrencyCookie = (value) => {
    const multiCurrencyCookieName = getMultiCurrencyCookieName(kdtId);
    // 选择的币种跟店铺的币种一样
    if (value === shopSetting.currency) {
      removeCookie(multiCurrencyCookieName);
    } else {
      setCookie(multiCurrencyCookieName, value, {
        expires: MULTI_CURRENCY_COOKIE_EXPIRE,
      });
    }
  };
  const updateLanguageCookie = (value) => {
    const multiLanguageCookieName = getMultiLanguageCookieName(kdtId);
    setCookie(multiLanguageCookieName, value, {
      expires: MULTI_LANGUAGE_COOKIE_EXPIRE,
    });
  };

  const handleCurrencyChange = (item) => {
    // 为什么cookie的name还需要加kdtId呢？为了方式*.myallvalue.com 和 .myallvalue.com冲突
    const selectCurrency = item.currency;
    if (selectCurrency === multiCurrency) {
      // 选择了之前选择过的币种
      return;
    }
    updateCurrencyCookie(selectCurrency);
    setMultiCurrency(selectCurrency);
    // 刷新页面，重走服务端逻辑
    window.location.reload();
  };

  const handleLanuageChange = (item) => {
    const selectLanguage = item.language;
    if (selectLanguage === language) {
      // 选择了之前选择过的语言
      return;
    }
    updateLanguageCookie(selectLanguage);

    setLanguage(selectLanguage);
    // 刷新页面，重走服务端逻辑
    window.location.reload();
  };

  return {
    languageList,
    multiCurrencies,
    isMultiCurrencyOpened,
    isShowMultiLanguage,
    isShowMultiCurrency,
    isShowMultiSettingEntry,
    handleCurrencyChange,
    handleLanuageChange,
    language,
    multiCurrency,
    setLanguage,
    setMultiCurrency,
    updateLanguageCookie,
    updateCurrencyCookie,
  };
};

/**
 * 1. 带cookie -> isopen getCurrecncyList -> (removeCookie) -> setCurrency -> render
 *    initialData获取currencyList
 * 2. 无cookie -> 客户端
 */
