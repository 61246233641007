import styled, { css } from 'styled-components';

const getMobileDeviceFooterTextAlignCSS = (props) => {
  const { mobileDeviceFooterTextAlign, mobileDisplayTwoInOneLine } = props;

  let newMobileDeviceFooterTextAlign = 'left';
  if (mobileDisplayTwoInOneLine) {
    newMobileDeviceFooterTextAlign = 'left';
  } else {
    newMobileDeviceFooterTextAlign = mobileDeviceFooterTextAlign;
  }

  return css`
    text-align: ${newMobileDeviceFooterTextAlign};
  `;
};

export const Style = styled.div`
  text-align: left;
  max-width: 560px;
  .footer-news-title {
    font-size: ${(props) => props.theme.getHeaderFontSize([14, 16])};
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    line-height: 20px;
  }
  .footer-news-text {
    font-size: ${(props) => props.theme.getBaseFontSize([12, 14])};
    margin-top: 12px;
    margin-bottom: 24px;
    opacity: 0.6;
  }

  .input-style-wrapper {
    margin-top: ${(props) => props.theme.getSpace([10, 16])};
    &.input-style-style1 {
      width: 100%;
    }
    &.input-style-style2 {
      width: 100%;
      display: flex;
      .footer-news-email-input {
        min-width: 176px;
        flex: 1;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      .footer-news-email-btn {
        margin-top: 0;
        padding-left: 24px;
        padding-right: 24px;
        border-radius: 0 var(--border_radius_form) var(--border_radius_form) 0 !important;
        flex: 0;
        word-break: keep-all;
      }
    }
    .footer-news-email-input {
      border: 1px solid #d1d5db;
      height: 40px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      .zent-input {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .footer-news-email-btn {
      margin-top: ${(props) => props.theme.getSpace([10, 16])};
      width: 100%;
      font-size: ${(props) => props.theme.getBaseFontSize([12, 14])};
      line-height: 40px !important;
      height: 40px !important;
      border: none !important;
      background: ${(props) => props.colorButtonBg} !important;
      color: ${(props) => props.colorButtonText} !important;
      white-space: nowrap;
    }
  }
  @media screen and (max-width: 750px) {
    .footer-news-section {
      /* 对齐方式 */
      ${(props) => getMobileDeviceFooterTextAlignCSS(props)}
      .input-style-wrapper .footer-news-email-input {
        height: 44px;
      }
      .input-style-wrapper .footer-news-email-btn {
        height: 44px;
        line-height: 44px !important;
      }
    }
  }
`;
