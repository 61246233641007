import styled from 'styled-components';

export const InlineFormWrapper = styled.div`
  display: flex;
  .zent-input {
    width: 100%;
  }
`;
export const InlineFormItem = styled.div`
  flex: 1;
  & + & {
    margin-left: 10px;
  }
`;
