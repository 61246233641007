import styled from 'styled-components';

export default styled.div`
  .section-title {
    padding: ${(props) => props.theme.getSpace(props.theme.vTitleContent)} 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    .text {
      display: inline-block;
      ${(props) => props.theme.getHeaderSize([16, 20])};
      color: ${(props) => props.theme.colorText};
      padding: 0 30px;
    }
    &::before,
    &::after {
      flex: 1;
      content: '';
      height: 1px;
      background-color: var(--color_borders);
    }
    &.left {
      align-items: flex-start;

      &::before,
      &::after {
        content: none;
      }
      .text {
        padding: 0;
        font-weight: bold;
      }
    }
    &.no-line {
      &::before,
      &::after {
        content: none;
      }
    }
  }
`;
