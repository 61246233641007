import styled from 'styled-components';

export const PolicyItemStyle = styled.div.attrs({
  className: 'policy-item',
})`
  cursor: pointer;
  width: 100%;
  .policy-item-content {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color_body_text);
      fill-opacity: 0.8;
      flex-shrink: 0;
    }
    .description {
      flex: 1;
      padding-left: 10px;
      color: rgba(var(--color_body_text_rgb), 0.8);
      white-space: nowrap;
      /* width: 100%; */
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
    }
  }
`;

export const PolicyItemPopContentStyle = styled.div.attrs({
  className: 'policy-item-pop-content',
})`
  .view-detail {
    color: var(--color_button);
    display: inline-flex;
    align-items: center;
    margin-top: 4px;
    cursor: pointer;
    font-size: 12px;
    svg {
      fill: var(--color_button);
      margin-left: 2px;
    }
  }
`;
