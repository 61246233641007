// 动态表单 radio
import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Validators, FormInputField } from 'zent';
import { Style } from './style';

export const FormMulText = ({ settings = {}, componentId, hasRequire, className }) => {
  const intl = useIntl();
  const { title = '', label = '', require = false } = settings || {};

  return (
    <Style>
      <div className={className}>
        <p className={cn('form-title', require && 'required', hasRequire && !require && 'has-require')}>{title}</p>
        <div className={cn('form-container', hasRequire && 'has-require')}>
          {Boolean(label) && <p className={cn('form-desc')}>{label}</p>}
          <FormInputField
            props={{
              type: 'textarea',
              autoSize: false,
              placeholder: intl.formatMessage({
                id: 'common_please_input',
                defaultMessage: '请输入',
                description: '请输入',
              }),
            }}
            withoutLabel
            name={componentId}
            validators={
              require
                ? [
                    Validators.required(
                      intl.formatMessage({
                        id: 'common_please_input',
                        defaultMessage: '请输入',
                        description: '请输入',
                      }),
                    ),
                  ]
                : undefined
            }
          />
        </div>
      </div>
    </Style>
  );
};
