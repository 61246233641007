import styled from 'styled-components';

export default styled.div`
  &.toast-container {
    margin: 0;
    padding: 0;
  }

  .mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  .toast-wrap {
    position: fixed;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
    z-index: 1001;
  }
`;
