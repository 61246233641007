import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import goodsApi from 'api/goods';
import deliveryApi from 'api/delivery';
import { Style } from './style';
import { GOODS_FEATURE_TYPE } from 'client/const';

export const GoodsTags = (props) => {
  const { goodsId, goodsFeatureType } = props;
  const isPhysical = goodsFeatureType === GOODS_FEATURE_TYPE.physical;
  const intl = useIntl();
  const [visiblePickupTag, setVisiblePickupTag] = useState(false);
  const [supportedMerchantDeliveryMethod, setSupportedMerchantDeliveryMethod] = useState({
    isSupportShipping: 0,
    isSupportLocalDelivery: 0,
  });

  const getIsPickupGoods = () => {
    goodsApi
      .isPickupGoods({
        goodsId,
      })
      .then((res) => {
        setVisiblePickupTag(res === true);
      });
  };

  const getSupportedMerchantDeliveryMethod = () => {
    deliveryApi.getSupportedMerchantDeliveryMethod().then((res) => {
      setSupportedMerchantDeliveryMethod(res);
    });
  };

  useEffect(() => {
    getIsPickupGoods();
    getSupportedMerchantDeliveryMethod();
  }, []);

  return (
    <Style>
      {isPhysical && (
        <>
          {Boolean(supportedMerchantDeliveryMethod.isSupportShipping) && (
            <div className='goods-detail-tag'>
              <svg className='icon goods-tag-icon' aria-hidden='true' width='16' height='16'>
                <use xlinkHref='#iconic-chenggong' />
              </svg>
              <span className='goods-tag-name'>
                {intl.formatMessage({
                  description: '快递发货',
                  id: '02af7bd4e6b74e5099ec66fb16a59691',
                })}
              </span>
            </div>
          )}
          {Boolean(supportedMerchantDeliveryMethod.isSupportLocalDelivery) && (
            <div className='goods-detail-tag'>
              <svg className='icon goods-tag-icon' aria-hidden='true' width='16' height='16'>
                <use xlinkHref='#iconic-chenggong' />
              </svg>
              <span className='goods-tag-name'>
                {intl.formatMessage({
                  description: '同城配送',
                  id: 'local.delivery',
                })}
              </span>
            </div>
          )}
        </>
      )}
      {visiblePickupTag && (
        <div className='goods-detail-tag'>
          <svg className='icon goods-tag-icon' aria-hidden='true' width='16' height='16'>
            <use xlinkHref='#iconic-chenggong' />
          </svg>
          <span className='goods-tag-name'>
            {intl.formatMessage({
              description: '到店自提',
              id: '16618fd6664043e09f239532067dda09',
            })}
          </span>
        </div>
      )}
    </Style>
  );
};
