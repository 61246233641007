import { useMemo } from 'react';
import { intlLiToYuan } from 'utils/money';
import { MemberStatus, OrderStatus } from 'const/member';

export const useMemberInfo = ({ currency, intl, memberStatus, memberPlan, multiCurrencyInfo }) => {
  const spreadText = memberPlan?.spreadText;
  const textInfo = useMemo(() => {
    if (!memberStatus.status) {
      return {
        text: spreadText,
      };
    }
    const { status = MemberStatus.None, hadSavingAmount, orderStatus } = memberStatus;
    const planTitle = memberPlan?.title;
    const money = intlLiToYuan({
      currency,
      value: hadSavingAmount,
      multiCurrencyInfo,
    });
    if (status === MemberStatus.None || orderStatus === OrderStatus.Auditing) {
      return {
        text: spreadText,
      };
    }

    if (status === MemberStatus.Done || status === MemberStatus.Test) {
      return {
        text: intl.formatMessage(
          {
            id: '7ce491fc01b94224a8eb546f63f2bf3a',
            defaultMessage: `尊贵的${planTitle}, 已为您节省${money}`,
          },
          {
            title: planTitle,
            money,
          },
        ),
      };
    }
    if (status === MemberStatus.Expire || status === MemberStatus.Forbid) {
      return {
        text: intl.formatMessage(
          {
            id: '92785b5ab1c246d2a5b6587eda774cf9',
            defaultMessage: `${planTitle}已过期`,
          },
          {
            title: planTitle,
          },
        ),
      };
    }
  }, [memberStatus, spreadText]);

  return {
    memberStatus,
    textInfo,
  };
};
