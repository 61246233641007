/** 三页下单步骤 */
export const STEP_MAP = {
  INFO: 'info',
  SEND: 'send',
  PAY: 'pay',
};

export const STEP_EDIT_TYPE = {
  ADDRESS: 'address',
};

export const DISCOUNT_TYPE = {
  Cancel: 1,
  Applied: 2,
};

export const ApplySuccess = 'success';

/**
 * 折扣模式
 */
export const DiscountMode = {
  automaticDiscount: 'automaticDiscount', // 自动折扣
  discountCode: 'discountCode', // 手动折扣
  cardCode: 'cardCode', // 一卡一码
};

export const GOODS_STATUS = {
  ok: 'ok',
};

export const CHECKOUT_TYPE = {
  multiPage: 'multi_page',
  singlePage: 'single_page',
};

export const DELIVERY_METHOD = {
  local_delivery: 'local_delivery',
  shipping_method: 'shipping_method',
  pickup: 'pickup',
};

export const PROMOTION_DESCRIPTIONS_TYPE = {
  discount: 'discount',
  member: 'member',
  edit_price: 'edit_price',
};

export const PROMOTION_DESCRIPTIONS_SUBTYPE = {
  member_price: 'member_price',
  points_deduct: 'points_deduct',
  edit_goods_item: 'edit_goods_item',
};

export const POINTS_TYPE = {
  none: 'none',
  maximum: 'maximum',
  customer: 'customer',
};
