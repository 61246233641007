import { useEffect, useState } from 'react';
import get from 'lodash/get';

/**
 * coverImgUrl: 商品的第一个图片
 * hoverImgUrl： 商品第一个图片之后的下一个图片， 鼠标hover上去的时候使用， 有可能是null
 */
function useGoodsImage(goodsInfo) {
  const [coverImgUrl, setCoverImgUrl] = useState(null);
  const [hoverImgUrl, setHoverImgUrl] = useState(null);
  useEffect(() => {
    const { media = [] } = goodsInfo;
    for (let i = 0; i < media.length; i++) {
      const type = get(media[i], 'type', '');
      let coverUrl = get(media[i], 'url', '');
      if (i === 0) {
        if (type === 'VIDEO') {
          coverUrl = get(media[i], 'coverUrl', '');
        }
        setCoverImgUrl(coverUrl);
      } else if (type === 'VIDEO') {
        continue;
      } else {
        setHoverImgUrl(coverUrl);
        break;
      }
    }
  }, [goodsInfo]);

  return {
    coverImgUrl,
    hoverImgUrl,
  };
}

export default useGoodsImage;
