import { useEffect, useState } from 'react';
import fullfillImage from 'utils/fullfillImage';

const getIsIE = () => {
  if (!!window?.ActiveXObject || 'ActiveXObject' in window) {
    return true;
  }
  return false;
};

const getImage = (url = '', rule = 'origin', options = { toWebp: true, compress: true }) => {
  if (!url) return;
  if (url && typeof url === 'object') {
    const newObj = {};
    Object.keys(url).forEach((key) => {
      newObj[key] = fullfillImage(url?.[key], rule, options);
    });
    return newObj;
  } else {
    return fullfillImage(url, rule, options);
  }
};

const detectWebpSupport = function () {
  const elem = document.createElement('canvas');
  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
};

const useFillImage = (url = '', rule = 'origin', options = { toWebp: true, compress: true }) => {
  const [image, setImage] = useState();
  const [isIE, setIsIE] = useState(false);
  const [isCanWebP, setIsCanWebP] = useState(true);

  useEffect(() => {
    const isIeBrowser = getIsIE();
    setIsIE(isIeBrowser);
    const isSupportWebP = detectWebpSupport();
    setIsCanWebP(isCanWebP);
    if (isIeBrowser || !isSupportWebP) {
      setImage(url);
      return;
    }
    setImage(getImage(url, rule, options));
  }, [url, rule, options]);

  return {
    image,
    isIE,
    isCanWebP,
  };
};

export default useFillImage;
