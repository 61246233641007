import styled from 'styled-components';

export const TabHeader = styled.div`
  display: flex;
  align-items: center;
`;
export const TabHeaderItem = styled.div`
  &:not(:first-child) {
    margin-left: 20px;
  }
  padding-bottom: 6px;
  cursor: pointer;
  color: ${(props) => (props.active ? props.theme.colorBodyText : props.theme.colorBodyTexts[80])};
  border-bottom: ${(props) => (props.active ? `2px solid ${props.theme.colorButton}` : '2px solid transparent')};
`;
