import React from 'react';
import loadable from '@loadable/component';
import NotFindPage from '../pages/not-find-page';

export default [
  {
    path: '/',
    name: 'store-select',
    exact: true,
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    component: loadable(() => import('../pages/headquarters/store-select/index')),
    pageType: '多站点选择界面',
    cache: true,
    ssrPage: true,
  },
  {
    path: '/close',
    name: 'close',
    exact: true,
    hideHeader: true,
    hideFooter: true,
    component: loadable(() => import('../pages/store-close')),
    pageType: '店铺关闭页',
  },
  {
    path: '*',
    component: NotFindPage,
    exact: true,
  },
];
