export const getLocaleMsg = (locale) => {
  return window.i18nLang.lang;
};

/**
 * C端切换语言，需要存储在缓存中，优先拿缓存语言，拿不到再拿默认语言
 * @param {} locale
 */
export const getLocale = (locale) => {
  const language = locale || (!__isServer ? navigator?.language : 'en');
  const defaultLanguage = 'en';
  return language || defaultLanguage;
};

export const getDesignLocalText = (lang) => {
  return lang?.['zh-CN'] || lang;
};
