import styled from 'styled-components';

export default styled.div`
  left: 0;
  top: calc(100% + 1px);
  width: 100%;
  display: flex;
  z-index: 2;
  background: #fff;
  justify-content: center;

  &.first-level-header-sub-menu {
    padding: 32px 0;
    width: 100%;
  }

  &.header-sub-menu-not-first {
    font-size: var(--base-font-14-12);
    display: block;
    font-weight: 300;
    color: #36383b;
    line-height: 14px;
    padding-top: var(--pm-16-10);
  }

  .header-sub-menu-box {
    padding: 0 12px;

    .header-sub-menu-box {
      padding: 0;
    }
  }

  .header-sub-minu-item {
    font-size: var(--base-font-14-12);
    line-height: 14px;
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .header-sub-minu-item a {
    color: var(--color_text);
  }

  .first-level-menu {
    font-size: var(--base-font-16-14);
    line-height: 16px;
  }
  .first-level-menu a {
    color: var(--color_text);
  }
`;
