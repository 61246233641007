import styled, { createGlobalStyle } from 'styled-components';

export default styled.div`
  overflow: hidden;
  width: 100%;

  .section-marker {
    width: 100%;
  }
  .side-slide-close-icon {
    fill: ${(props) => props.subMenuTextColor} !important;
  }
  &.sortable-ghost {
    .side-slide {
      width: 0;
      height: 0;
    }
  }
  .side-header-icon {
    width: 18px;
    height: 18px;
    fill: ${(props) => props.subMenuTextColor} !important;
    display: inline-block;
    vertical-align: bottom;
    &:hover {
      opacity: 0.65;
    }
  }
  .menu-item {
    padding-left: 10px;
  }
  .header-menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .header-menu-item {
      color: ${(props) => props.subMenuTextColor};
      .wish-list-entry {
        width: 100%;
        text-align: left;
      }
    }
  }
  .side-slide {
    top: 0;
    z-index: 10;
    width: 0;
    height: 100vh;
    position: fixed;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
  }
  .side-slide-container-head-icon {
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    fill: ${(props) => props.gColor} !important;
  }
  .header-pc-side-menu-header {
    padding: 0;
    text-align: right;
  }
  .side-slide-back {
    cursor: pointer;
    fill: ${(props) => props.subMenuTextColor} !important;
  }
  .side-slide-container {
    background: #fff;
    height: 100%;
    position: relative;
  }
  .side-slide-left-scroll-body {
    height: calc(100% - 40px);
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .side-slide-left-scroll-content {
      width: 100%;
      max-height: 100%;
      margin: 24px 0;
    }
  }
  .side-slide-left-scroll-body::-webkit-scrollbar {
    width: 0 !important;
  }
  .side-slide-container-header {
    text-align: right;
    line-height: 72px;
    padding: 0 var(--pm-24-18) 0;
    box-shadow: 0px 0px 0px 0px #d1d5db;
    display: flex;
    justify-content: space-between;
  }

  .side-slide-content {
    width: 264px;
    margin: auto;
  }

  .pc-side-header-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 10;
    background: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.6)};
    opacity: 0.3;
  }
  .pc-side-menu-container {
    padding: 40px;
    box-sizing: border-box;
    .blank-menu-container {
      padding: 40px;
      box-sizing: border-box;
      .back-icon {
        width: 40px;
        height: 40px;
      }
      .child-menu-container {
        height: calc(100% - 40px);
      }
    }
    .side-slide-close-icon {
      width: 40px;
      height: 40px;
    }
    .header-menu-wrapper {
      margin: 40px 0;
      .header-menu-item {
        font-size: 16px;
        .side-header-icon {
          width: 24px;
        }
      }
      .header-menu-item:first-child {
        margin-bottom: 18px;
      }
    }
    .first-menu-title,
    .sub-menu-title-container,
    .menu-image {
      margin-top: 24px;
    }

    .sub-menu-icon {
      .right-icon {
        width: 32px;
        height: 32px;
      }
    }
  }
  .pad-menu-container {
    padding: 32px;
    box-sizing: border-box;
    .blank-menu-container {
      padding: 32px;
      box-sizing: border-box;
      .back-icon {
        width: 32px;
        height: 32px;
      }
      .child-menu-container {
        height: calc(100% - 32px);
      }
    }
    .side-slide-left-scroll-body {
      height: calc(100% - 32px);
    }
    .side-slide-close-icon {
      width: 32px;
      height: 32px;
    }
    .header-menu-wrapper {
      margin: 32px 0;
      .header-menu-item {
        font-size: 14px;
        .side-header-icon {
          width: 16px;
        }
      }
      .header-menu-item:first-child {
        margin-bottom: 12px;
      }
    }
    .first-menu-title,
    .sub-menu-title-container,
    .menu-image {
      margin-top: 20px;
    }
    .sub-menu-icon {
      .right-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .mobile-menu-container {
    padding: 16px;
    box-sizing: border-box;
    .blank-menu-container {
      padding: 16px;
      box-sizing: border-box;
      .back-icon {
        width: 24px;
        height: 24px;
      }
      .child-menu-container {
        height: calc(100% - 24px);
      }
    }
    .side-slide-left-scroll-body {
      height: calc(100% - 24px);
    }
    .side-slide-close-icon {
      width: 24px;
      height: 24px;
    }
    .header-menu-wrapper {
      margin: 24px 0;
      .header-menu-item {
        font-size: 13px;
        .side-header-icon {
          width: 16px;
        }
      }
      .header-menu-item:first-child {
        margin-bottom: 8px;
      }
    }
    .first-menu-title,
    .sub-menu-title-container,
    .menu-image {
      margin-top: 16px;
    }
    .sub-menu-icon {
      .right-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
export const GlobalStyle = createGlobalStyle`
    .h5-preview-content{
        width: 100vw;
        transition: all 0.2s ease-in-out;
        margin-left: ${(props) => props.slideLeft};
    }
`;
