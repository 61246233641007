// 装修共用-背景图片组件
// 可配置项：图片的地址url，布局方式fit，position
import React, { forwardRef, useContext } from 'react';
import Image from 'components/base/image';
import { DefaultImg } from 'cpn/default-img';
import { Style } from './style';
import RootContext from '../../../context/root';

const BackgroundImage = forwardRef((props, ref) => {
  const { url, fit = 'cover', position = 'center center', onLoad, showDefault = true, alt, compress, ...extra } = props;
  const { initialData = {} } = useContext(RootContext);
  const { seoInfo = {} } = initialData;
  return (
    <Style className='background-image' {...extra}>
      {url ? (
        <Image
          ref={ref}
          onLoad={(e) => {
            onLoad && onLoad(e);
          }}
          alt={alt || seoInfo.title}
          className='img'
          src={url}
          style={{ objectFit: fit, objectPosition: position }}
          compress={compress}
        />
      ) : (
        showDefault && <DefaultImg width='auto' height='41%' />
      )}
    </Style>
  );
});
export default BackgroundImage;
