import React from 'react';

import { CartItemMobile } from './cart-item-mobile';
import { CartItemPC } from './cart-item-pc';

export const CartItem = (props) => {
  const { isMobile = false } = props;

  if (isMobile) {
    return <CartItemMobile {...props} />;
  }
  return <CartItemPC {...props} />;
};
