import React, { useEffect } from 'react';
import { CALLBACK_TYPE } from 'const';
import userApi from '../../api/user';
import { Notify } from 'zent';
import { useIntl } from 'react-intl';
import { MAIN_DOMAIN_REDIRECT_STATUS } from '../../const';

const CallbackUrl = (props) => {
  const intl = useIntl();
  const initialData = props?.initialData;
  const { code, state, shopInfo = {} } = initialData;
  const { type = '' } = props?.match?.params || {};

  const onStartLineLogin = () => {
    const url = decodeURIComponent(state);
    const urlArray = url.split(',');
    if (type === CALLBACK_TYPE.line) {
      userApi.googleLogin({ channelCode: CALLBACK_TYPE.line, channelToken: code, extend: urlArray[1] }).then(() => {
        Notify.success(
          intl.formatMessage({
            id: 'account.login_success',
            defaultMessage: '登录成功',
          }),
        );
        window.location.href = `https://${window.location.host}${urlArray[0]}`;
      });
    }
  };

  useEffect(() => {
    const { trafficRedirect, primaryDomain } = shopInfo;
    // 跳转后再执行登录逻辑
    if (MAIN_DOMAIN_REDIRECT_STATUS.OPEN === trafficRedirect && primaryDomain !== window.location.host) {
      return;
    }
    onStartLineLogin();
  }, []);

  return <div />;
};

export default CallbackUrl;
