import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import cn from 'classnames';
import pick from 'lodash/pick';
import find from 'lodash/find';
import { NumberInput, Button, Notify } from 'zent';
import Style from './style';
import LocalStorage from '../../../util/local-storage';
import { GOODS_BUY_ONCE } from '../../../util/local-storage/config';
import { useIntl } from 'react-intl';
import Toast from 'cpn/toast';
import { buriedPoint } from '../../../util/log-buried-point';
import SkuList from 'cpn/sku-list';
import { intlMessage } from '../../../util/intl/formate';
import { pushToHistory } from 'utils/web-tool';
import { getSkuPrice } from 'utils/money';
import { TRACK } from 'const';
import RootContext from '../../context/root';
import * as cartHelper from 'helper/cart';
import { SHOP_CART } from 'utils/local-storage/config';
import { useGoodsLimit } from 'hook/goods/use-goods-limit';
import { useRequireLogin } from '../require-login-wrapper';
import { useI18n } from 'hook/intl';
import InputNumberStepper from 'components/base/input-number-stepper';

const SkuSelect = (props) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);
  const {
    goodsDetail,
    goodsId,
    showQuantitySelector = true,
    innerRef,
    history,
    enablePaymentButton,
    enableCartButton = true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSkuChange = () => {},
    setSkuPageCompleteStatus,
    setPrice,
    currency,
    selectSkuInfoCallBack,
    preVariantId,
    triggerSkuBlock,
    onCartSkuChange,
    preNum,
    onQuantityChange,
  } = props;
  const { initialData } = useContext(RootContext);
  const { multiCurrencyInfo } = initialData;
  const defaultSkuInfo = { quantity: 1, variantId: preVariantId };
  const [selectedSkuList, setSelectedSkuList] = useState([]);
  const [skuComposeMap, setSkuComposeMap] = useState({});
  const [count, setCount] = useState(cartHelper.getDefaultQuantity(goodsDetail));
  const [isSellOut, setIsSellOut] = useState(false);
  const [currentSelectSkuInfo, setCurrentSelectSkuInfo] = useState(defaultSkuInfo);

  const { checkedRequireLogin } = useRequireLogin();

  const getSkuComposeInfo = (skuComposeArr) => {
    const skuComposeKey = skuComposeArr.join(',');
    const skuComposeInfo = skuComposeMap[skuComposeKey];
    setCurrentSelectSkuInfo(skuComposeInfo || { quantity: 1, variantId: undefined });
  };

  const judgeIsSellOut = () => {
    const availableVariants = goodsDetail?.variants?.filter(({ quantity }) => !!quantity);
    const isSellout = !availableVariants || availableVariants.length === 0;

    return isSellout;
  };

  // 最小起购数是否超出库存数
  const isExceedMinPurchase = goodsDetail?.minPurchaseQty > goodsDetail?.quantity || (currentSelectSkuInfo.inventory && goodsDetail?.minPurchaseQty > currentSelectSkuInfo.inventory);

  const initSkuMap = (detail) => {
    const { variants = [] } = detail;
    variants.forEach((item = {}) => {
      const {
        optionValues = [],
        price,
        variantId,
        quantity: inventory = 0,
        requiresShipping,
        imageUrl,
        compareAtPrice,
        discountInfo,
        bestPriceInfo,
        memberInfo,
      } = item;
      const skuComposeArr = optionValues.map((optionValue) => {
        return optionValue.title;
      });
      const skuComposeKey = skuComposeArr.join(',');
      skuComposeMap[skuComposeKey] = {
        price,
        variantId,
        quantity: 1,
        inventory,
        requiresShipping,
        imageUrl,
        compareAtPrice,
        discountInfo,
        bestPriceInfo,
        memberInfo,
      };
    });
    setSkuComposeMap({ ...skuComposeMap });
  };

  useEffect(() => {
    if (goodsDetail) {
      const { variants, media, options = [] } = goodsDetail;
      // 单规格 或者 只有一个属性名的处理方式
      const isSingle = options?.length < 1 || options.every((item) => item?.optionValues?.length === 1);
      if (isSingle && variants) {
        const mainMedia = media?.[0] || {};
        let imageUrl = mainMedia?.url || '';
        if (mainMedia?.type === 'VIDEO') {
          imageUrl = mainMedia.coverUrl || '';
        }
        const variantInfo = pick(variants[0], [
          'price',
          'requiresShipping',
          'variantId',
          'requiresTax',
          'compareAtPrice',
          'discountInfo',
          'bestPriceInfo',
          'memberInfo',
          'quantity',
        ]);
        setCurrentSelectSkuInfo({
          imageUrl,
          ...variantInfo,
          inventory: variantInfo.quantity,
          init: true,
          quantity: 1,
        });
      } else {
        if (preVariantId) {
          const item = find(variants, { variantId: preVariantId });
          setCurrentSelectSkuInfo({ ...item, init: true });
        }
        if (!preVariantId) setCurrentSelectSkuInfo({ ...currentSelectSkuInfo, init: true });
      }

      initSkuMap(goodsDetail);
    }
  }, [goodsDetail]);

  useEffect(() => {
    const { variantId } = currentSelectSkuInfo;
    if (currentSelectSkuInfo?.init && setSkuPageCompleteStatus) setSkuPageCompleteStatus(true);
    if (variantId) {
      selectSkuInfoCallBack && selectSkuInfoCallBack(variantId);
      setCount(cartHelper.getDefaultQuantity({
        ...currentSelectSkuInfo,
        giftGoodsFlag: goodsDetail?.giftGoodsFlag,
        minPurchaseQty: goodsDetail?.minPurchaseQty,
      }));
    }
    onSkuChange(currentSelectSkuInfo);
    const price = getSkuPrice({ sku: currentSelectSkuInfo, goodsDetail, intl, currency, multiCurrencyInfo });
    if (setPrice) setPrice(price);
  }, [currentSelectSkuInfo]);


  const onNumChange = (value) => {
    setCount(value);
    onQuantityChange && onQuantityChange(value)
  };

  const handleSkuEmpty = () => {
    Toast.info(intlMessage(intl, 'goods.sku.empty'), 800);
  };

  const selectSku = (item={}, index) => {
    if(selectedSkuList[index] === item.title) {
      selectedSkuList[index] = undefined;
    }else {
      selectedSkuList[index] = item.title;
    }
    getSkuComposeInfo(selectedSkuList);
    setSelectedSkuList([...selectedSkuList]);
  };

  const addCar = useCallback(
    async (event, params = {}) => {
      if (!goodsDetail) return;
      if (checkedRequireLogin()) return;
      const { title, priceSymbol = '$', media = [], giftGoodsFlag } = goodsDetail;
      const {
        price,
        quantity,
        inventory,
        requiresShipping,
        variantId,
        imageUrl,
        requiresTax = 0,
      } = currentSelectSkuInfo;
      const mainMedia = media?.[0] || {};
      let mainMediaImageUrl = mainMedia?.url || '';
      if (mainMedia?.type === 'VIDEO') {
        mainMediaImageUrl = mainMedia?.coverUrl || '';
      }
      const cart = {
        imageUrl: imageUrl || mainMediaImageUrl,
        goodsId,
        price,
        priceSymbol,
        title,
        variantId,
        quantity,
        inventory,
        requiresTax,
        requiresShipping,
        sku: selectedSkuList,
        hasSelected: 1,
        goodsStatus: 'ok',
        discountGoodsFlag: 1,
      };
      if ((!variantId && goodsDetail?.options?.length > 0) || Number(cart?.count) === 0) {
        handleSkuEmpty();
        return;
      }
      // 限购的 maxGoodsNum 为 0 时, 会使 count 变更为 0, 导致请求参数报错
      // 赠品不受起购限制, 固定为 1
      const newCount = giftGoodsFlag == 1 ? 1 : count > 0 ? count : 1;
      if (onCartSkuChange) {
        triggerSkuBlock(false);
        onCartSkuChange(preVariantId, {
          giftGoodsFlag,
          goodsId,
          quantity: newCount,
          variantId,
        }); // 更新购物车的列表
        // onCartSkuChange(cartList); // 更新购物车的列表
      } else {
        await cartHelper.addGoods({
          giftGoodsFlag,
          goodsId,
          quantity: newCount,
          variantId,
          ...params,
        });
      }
      let list = (await cartHelper.getCartGoods()) || [];
      list = list.map((item) => {
        const newItem = { ...item };
        if (newItem.hasSelected === undefined) {
          newItem.hasSelected = 1;
        }
        return newItem;
      });
      LocalStorage.set(SHOP_CART, list, -1);
      Notify.success(
        onCartSkuChange
          ? intl.formatMessage({ id: 'd5fef22482eb4d9599dc9b2135330ac1', defaultMessage: '更新sku成功' })
          : intl.formatMessage({ id: 'has.add.car' }),
        1500,
      );
      buriedPoint(
        'add_cart',
        {
          source: '',
          goods_id: cart?.goodsId,
          ...cart,
          sku: [cart?.variantId],
          goodsType: goodsDetail?.goodsType,
          quantity: newCount,
        },
        {
          name: '添加购物车',
          eventType: 'click',
        },
      );
    },
    [goodsDetail, currentSelectSkuInfo, count, goodsId],
  );

  const buyNow = () => {
    if (!goodsDetail) return;
    if (checkedRequireLogin()) return;
    const { title, priceSymbol = '$', media = [], goodsType } = goodsDetail;
    const { price, quantity, requiresShipping, variantId, imageUrl } = currentSelectSkuInfo;
    // 限购的 maxGoodsNum 为 0 时, 会使 count 变更为 0, 导致请求参数报错
    const newCount = count > 0 ? count : 1;
    const cart = {
      imageUrl: imageUrl || media?.[0]?.url,
      goodsId,
      price,
      priceSymbol,
      title,
      variantId,
      quantity: newCount,
      requiresShipping,
      sku: selectedSkuList,
    };

    if (!variantId) {
      handleSkuEmpty();
      return;
    }

    // 判断是分组
    if (window.global_data.collectionId) {
      buriedPoint('category_buynow', {
        source: '',
        ...cart,
        goods_id: goodsId,
        goodsType,
      });
    }
    buriedPoint(
      'buy_now',
      {
        ...cart,
        source: {
          cartList: [cart],
        },
        goods_id: goodsId,
        goodsType,
      },
      {
        name: '立即购买',
        eventType: 'click',
      },
    );
    LocalStorage.set(GOODS_BUY_ONCE, [cart], -1);
    pushToHistory(history, '/order/subscribe', { step: 'info', refer: GOODS_BUY_ONCE });
  };

  useEffect(() => {
    if (innerRef) {
      innerRef.current.addCar = addCar;
      innerRef.current.buyNow = buyNow;
    }
  }, [addCar, buyNow]);

  useEffect(() => {
    const isSoldOut = judgeIsSellOut();
    setIsSellOut(isSoldOut);
  }, [goodsDetail]);

  useEffect(() => {
    if (preNum) {
      setCount(preNum);
    }
  }, [preNum]);

  // 起购, 限购提醒
  const {
    limitTips,
    minGoodsNum,
    maxGoodsNum,
    onMinusEvent,
    onPlusEvent,
  } = useGoodsLimit({
    intl,
    inventory: currentSelectSkuInfo?.variantId ? currentSelectSkuInfo.inventory : goodsDetail?.quantity,
    minPurchaseQty: goodsDetail?.minPurchaseQty,
    limitInfo: goodsDetail?.limitInfo,
    discountLimit: goodsDetail?.giftGoodsFlag == 1 ? 1: 0,
  });

  // 加购按钮文案
  const addCartBtnText = useMemo(() => {
    if (isSellOut) {
      return $fm('goods.tips.none_sales', '售罄');
    }
    if (isExceedMinPurchase) {
      return $fm('goods.none.enough_store', '库存不足');
    }
    if (preVariantId) {
      return $fm('c8c54b1c104c4e7bb00380c1521c0a85', '更新');
    }
    return $fm('goods.cart.add', '加入购物车');
  }, [isSellOut, isExceedMinPurchase, preVariantId]);

  // 立即购买文案
  const buyNowBtnText = useMemo(() => {
    if (isSellOut) {
      return $fm('goods.tips.none_sales', '售罄');
    }
    if (isExceedMinPurchase) {
      return $fm('goods.none.enough_store', '库存不足');
    }
    return $fm('goods.buy_once', '立即购买');
  }, [isSellOut, isExceedMinPurchase, preVariantId])

  return (
    <Style>
      <SkuList
        cart={{ sku: selectedSkuList }}
        options={goodsDetail?.options}
        variants={goodsDetail?.variants}
        withVariants={goodsDetail?.withVariants}
        onSkuSelect={selectSku}
        setSkuPageCompleteStatus={setSkuPageCompleteStatus}
        preVariantId={preVariantId}
      />
      {showQuantitySelector && (
        <div className='sku-line'>
          <p className='sku-title sku-title-num'>{intl.formatMessage({ description: '数量', id: 'common_count' })}</p>
          <div className='number-input-wrapper'>
            <InputNumberStepper
              value={count}
              min={minGoodsNum}
              max={maxGoodsNum}
              onMinusEvent={onMinusEvent}
              onPlusEvent={onPlusEvent}
              onChange={onNumChange}
              disabled={isSellOut || isExceedMinPurchase}
            />
            {limitTips && <div className='limit-tips'>{limitTips}</div>}
          </div>
        </div>
      )}
      <div className='operation-item'>
        {enableCartButton && (
          <Button
            onClick={addCar}
            disabled={isSellOut || isExceedMinPurchase}
            className={cn(
              'add-shop-car',
              preVariantId ? null : TRACK.AddCartButton,
              (isSellOut || isExceedMinPurchase) ? 'goods-sell-out-btn' : '',
            )}
            style={{ width: '100%' }}
            type={preVariantId ? 'primary' : 'default'}
          >
            {addCartBtnText}
          </Button>
        )}

        {enablePaymentButton && (
          <Button
            type='primary'
            onClick={buyNow}
            disabled={isSellOut || isExceedMinPurchase}
            className={cn(
              'featured-products-btn',
              TRACK.BuyOnceButton,
              !currentSelectSkuInfo?.variantId ? '' : 'featured-products-btn-active',
            )}
            style={{ width: '100%' }}
          >
            {buyNowBtnText}
          </Button>
        )}
      </div>
    </Style>
  );
};

export default SkuSelect;
