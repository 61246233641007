import styled from 'styled-components';
import { getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  @media screen and (max-width: 750px) {
    .quote-blocks-list {
      flex-direction: column;
      margin-top: -16px;
    }
    .quote-blocks-item:first-child {
      margin-top: 0px;
    }
    .quote-blocks-item + .quote-blocks-item {
      margin-left: 0px !important;
    }
    .quote-blocks-item {
      max-width: 100% !important;
    }
  }
  .quote {
    width: 100%;
    margin: auto;
    position: relative;
    box-sizing: border-box;
  }

  .hide-navigation {
    .swiper-pagination {
      display: none;
    }
  }

  .quote-content-title {
    text-align: center;
    ${(props) => props.theme.getBaseSize([16, 20])};
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    margin-bottom: ${(props) => props.theme.getSpace([16, 45])};
  }

  .quote-blocks-list {
    display: flex;
    margin: ${(props) => props.theme.getSpace([-20, 24])} auto 0;
    justify-content: center;
    flex-wrap: wrap;
  }

  .quote-blocks-symbol {
    width: ${(props) => props.theme.getSpace([60, 90])};
    height: ${(props) => props.theme.getSpace([60, 90])};
    box-sizing: border-box;
    border-width: ${(props) => props.theme.getSpace([2, 4])};
    border-style: solid;
    border-radius: 50%;
    overflow: hidden;
    margin: -${(props) => props.theme.getSpace([30, 45])} auto 24px;
    font-size: var(--base-font-24-18);
    .default-img-box {
      background: rgba(220, 222, 224, 1);
    }
  }

  .quote-blocks-item {
    position: relative;
    text-align: center;
    width: ${(props) => (props.theme.isMobile ? '100%' : '32%')};
    margin-right: 2%;
    padding: 0px ${(props) => props.theme.getSpace([16, 40])} ${(props) => props.theme.getSpace([16, 40])};
    box-sizing: border-box;
    margin-top: ${(props) => (props.theme.isMobile ? '46px' : '70px')};
    :nth-child(-n + 3) {
      margin-top: ${(props) => (props.theme.isMobile ? '46px' : '45px')};
    }
    &.is-mobile {
      width: 100%;
    }
    .hero-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .quote-blocks-desc {
    color: #66656e;
    color: var(--color_text);
  }

  .quote-blocks-author {
    margin-bottom: 16px;
    color: var(--color_text);
    ${(props) => props.theme.getBaseSize([16, 20])}
    font-weight: 500;
  }
`;
