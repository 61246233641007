import React from 'react';
import { Arrow, Indicator, IndicatorNoBackground, CustomArrow } from './style';
import { Text } from '../../../../components/base';
import cn from 'classnames';

function getTotalArrayByNumber(number) {
  return '1'.repeat(number).split('');
}

function formatNumber(number) {
  return number >= 10 ? number : `0${number}`;
}

function renderDotsArrow(props) {
  return (
    <>
      <Arrow className={cn('dots', 'slide-prev', { 'show-ad': props.showAd })} onClick={props.onPrevClick}>
        <svg className='slide-btn slide-btn-left' aria-hidden='true'>
          <use xlinkHref='#iconic-xiala' />
        </svg>
      </Arrow>
      <Arrow className={cn('dots', 'slide-next', { 'show-ad': props.showAd })} onClick={props.onNextClick}>
        <svg className='slide-btn slide-btn-right' aria-hidden='true'>
          <use xlinkHref='#iconic-xiala' />
        </svg>
      </Arrow>
    </>
  );
}

function renderArrow(props) {
  const { type, isPc } = props;
  if (!isPc || type === 'default') {
    return null;
  }
  if (type === 'dots') {
    return renderDotsArrow(props);
  }
  return (
    <>
      <Arrow className={cn(type, 'slide-prev')} onClick={props.onPrevClick}>
        <svg className='slide-btn slide-btn-left' aria-hidden='true'>
          <use xlinkHref='#iconic-xiala' />
        </svg>
      </Arrow>
      <Arrow className={cn(type, 'slide-next')} onClick={props.onNextClick}>
        <svg className='slide-btn slide-btn-right' aria-hidden='true'>
          <use xlinkHref='#iconic-xiala' />
        </svg>
      </Arrow>
    </>
  );
}

// 根据total， currentIndex 渲染 indicatorItem
function renderIndicatorItems({ total, currentIndex }) {
  const arr = getTotalArrayByNumber(total);
  return arr.map((item, index) => (
    <div key={index} className={cn('indicator-item', { active: currentIndex === index })} />
  ));
}

// dot indicator
function renderDotsIndicator({ total, currentIndex = 0, isPc, showAd }) {
  const arr = getTotalArrayByNumber(total);
  return (
    <Indicator className={cn('dots', { center: !isPc, 'show-ad': showAd })}>
      {renderIndicatorItems({ total, currentIndex })}
    </Indicator>
  );
}

// line indicator
function renderLineIndicator({ total, currentIndex = 0, showAd }) {
  const arr = getTotalArrayByNumber(total);
  return (
    <Indicator className={cn('line', { 'show-ad': showAd })}>
      <Text size={11} className='indicator-text left'>
        {formatNumber(currentIndex + 1)}
      </Text>
      {renderIndicatorItems({ total, currentIndex })}
      <Text size={11} className='indicator-text right'>
        {formatNumber(total)}
      </Text>
    </Indicator>
  );
}

// digital Indicator
function renderDigitalIndicator({ total, currentIndex = 0, showAd }) {
  return (
    <Indicator className={cn('digital', { 'show-ad': showAd })}>
      <Text size={11} className='indicator-text active'>
        {formatNumber(currentIndex + 1)}
      </Text>
      /
      <Text size={11} className='indicator-text'>
        {formatNumber(total)}
      </Text>
    </Indicator>
  );
}

// default Indicator, 包含箭头，不方便分开
function renderDefaultIndicator({ total, currentIndex = 0, onNextClick, onPrevClick, showAd }) {
  return (
    <Indicator className={cn('default', { 'show-ad': showAd })}>
      <svg className='slide-btn slide-btn-left' aria-hidden='true' onClick={onPrevClick}>
        <use xlinkHref='#iconic-xiala' />
      </svg>
      {renderIndicatorItems({ total, currentIndex })}
      <svg className='slide-btn slide-btn-right' aria-hidden='true' onClick={onNextClick}>
        <use xlinkHref='#iconic-xiala' />
      </svg>
    </Indicator>
  );
}

function renderDotsBtnIndicator({ total, currentIndex = 0, onIndexClick, className = '' }) {
  const arr = getTotalArrayByNumber(total);
  return (
    <IndicatorNoBackground className={cn('dots-btn', className)}>
      {arr.map((item, index) => (
        <div
          key={index}
          className={cn('indicator-item', { active: currentIndex === index })}
          onClick={() => onIndexClick(index)}
        />
      ))}
    </IndicatorNoBackground>
  );
}

function renderLineBtnIndicator({ total, currentIndex = 0, onIndexClick, className = '' }) {
  const arr = getTotalArrayByNumber(total);
  return (
    <IndicatorNoBackground className={cn('line-btn', className)}>
      <div className={cn('indicator-container')}>
        {arr.map((item, index) => (
          <div
            key={index}
            className={cn('indicator-item', { active: currentIndex === index })}
            onClick={() => onIndexClick(index)}
          />
        ))}
      </div>
    </IndicatorNoBackground>
  );
}

function renderArrowIndicator({ total, currentIndex = 0, onPrevClick, onNextClick, className = '' }) {
  return (
    <CustomArrow className={cn(className)}>
      <svg
        className={cn('arrow-icon', 'left', { disabled: currentIndex === 0 })}
        aria-hidden='true'
        onClick={onPrevClick}
      >
        <use xlinkHref='#iconic-zuoyouqiehuan' />
      </svg>
      <svg
        className={cn('arrow-icon', 'right', { disabled: currentIndex === total - 1 })}
        aria-hidden='true'
        onClick={onNextClick}
      >
        <use xlinkHref='#iconic-xiala' />
      </svg>
    </CustomArrow>
  );
}

function renderSquareIndicator({ total, type, currentIndex = 0, onPrevClick, onNextClick, className = '' }) {
  return (
    <CustomArrow className={cn(className)}>
      <div className={cn('arrow-container', type, 'left', { disabled: currentIndex === 0 })}>
        <svg className={cn('icon')} aria-hidden='true' onClick={onPrevClick}>
          <use xlinkHref='#iconic-zuoyouqiehuan' />
        </svg>
      </div>
      <div className={cn('arrow-container', type, 'right', { disabled: currentIndex === total - 1 })}>
        <svg className={cn('icon')} aria-hidden='true' onClick={onNextClick}>
          <use xlinkHref='#iconic-xiala' />
        </svg>
      </div>
    </CustomArrow>
  );
}

function renderBottomArrowIndicator({ total, type, currentIndex = 0, onPrevClick, onNextClick, className = '' }) {
  return (
    <CustomArrow className={cn(className, 'bottom-arrow-container')}>
      <svg
        className={cn('bottom-arrow-icon', 'left', { disabled: currentIndex === 0 })}
        aria-hidden='true'
        onClick={onPrevClick}
      >
        <use xlinkHref={type === 'arrow-bottom' ? '#iconic-zuoyouqiehuan' : '#iconic-jiantouzuo'} />
      </svg>
      <svg
        className={cn('bottom-arrow-icon', 'right', { disabled: currentIndex === total - 1 })}
        aria-hidden='true'
        onClick={onNextClick}
      >
        <use xlinkHref={type === 'arrow-bottom' ? '#iconic-xiala' : '#iconic-jiantouyou'} />
      </svg>
    </CustomArrow>
  );
}

function renderIndicator(props) {
  const { type = 'dots' } = props;
  if (type === 'dots') {
    return renderDotsIndicator(props);
  }
  if (type === 'line') {
    return renderLineIndicator(props);
  }
  if (type === 'digital') {
    return renderDigitalIndicator(props);
  }
  if (type === 'dots-btn') {
    return renderDotsBtnIndicator(props);
  }
  if (type === 'line-btn') {
    return renderLineBtnIndicator(props);
  }
  if (type === 'arrow') {
    return renderArrowIndicator(props);
  }
  if (type === 'square' || type === 'circle') {
    return renderSquareIndicator(props);
  }
  if (type === 'long-arrow' || type === 'arrow-bottom') {
    return renderBottomArrowIndicator(props);
  }
  return renderDefaultIndicator(props);
}

/**
 * 参数
 * type: default, dots, line, digital, 默认default， 后台可配置
 * onPrevClick： 点击左箭头的回调
 * onNextClick： 点击右箭头的回调
 * isPc： 是否是一pc端，用于区分pc和mobile样式， 可以跳过useThemeContext获取是否是pc
 * total： 轮播图的总数量，用于生成指示器数量
 * currentIndex： 当前展示的轮播图的index
 * showAd： 是否展示广告，默认是false，可以不传
 *
 * 注意：使用前必选保证该组件的父元素设置了相对定位或者position不为static
 */
function SwiperIndicatorArrow(props) {
  return (
    <>
      {renderArrow(props)}
      {renderIndicator(props)}
    </>
  );
}

export default SwiperIndicatorArrow;
