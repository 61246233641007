import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Style, { GlobalStyle } from './style';
import { useThemeContext } from '../../../hook/global-theme';

export const SideSlide = (props) => {
  const {
    children,
    expendIconName,
    headIconName = 'iconic-shanchu',
    customClass,
    iconSize = '20',
    showMask = true,
    title = '',
    settings = {},
    style = {},
    subMenuTextColor = '#000000',
    headerGlobalStyle,
    isMobile,
    firstMenuList = [],
  } = props;
  const {
    menu_width: menuWidth = 300,
    nav_position: navPosition = 'left',
    side_sub_menu_align: sideSubMenuAlign = 'top',
    sub_menu_bg_color: subMenuBgColor = '#ffffff',
    menu_open_type: menuOpenType = 'extrusion',
  } = settings;
  const [slideStatus, setSlideStatus] = useState(false);
  // const [menuOverBody, setMenuOverBody] = useState(false);
  const onSectionClick = () => {
    setSlideStatus(!slideStatus);
  };

  const themeContext = useThemeContext();
  const { isPad } = themeContext;
  let menuContainerClass = 'pc-side-menu-container';
  let menuCurrWidth = menuWidth;
  let navPositionStyle = {};
  if (isMobile) {
    if (menuWidth >= 300 && menuWidth <= 350) {
      menuCurrWidth = menuWidth * 0.9;
    } else if (menuWidth > 350 && menuWidth <= 400) {
      menuCurrWidth = menuWidth * 0.85;
    } else if (menuWidth > 400 && menuWidth <= 500) {
      menuCurrWidth = menuWidth * 0.7;
    } else if (menuWidth > 500 && menuWidth <= 600) {
      menuCurrWidth = menuWidth * 0.6;
    }
    menuContainerClass = 'mobile-menu-container';
  } else if (isPad) {
    if (menuWidth >= 300 && menuWidth <= 350) {
      menuCurrWidth = menuWidth * 0.95;
    } else if (menuWidth > 350 && menuWidth <= 400) {
      menuCurrWidth = menuWidth * 0.9;
    } else if (menuWidth > 400 && menuWidth <= 500) {
      menuCurrWidth = menuWidth * 0.85;
    } else if (menuWidth > 500 && menuWidth <= 600) {
      menuCurrWidth = menuWidth * 0.8;
    }
    menuContainerClass = 'pad-menu-container';
  }
  if (navPosition === 'left') {
    navPositionStyle = {
      left: 0,
      width: slideStatus ? `${menuCurrWidth}px` : '0',
      backgroundColor: subMenuBgColor,
    };
  } else {
    navPositionStyle = {
      right: 0,
      width: slideStatus ? `${menuCurrWidth}px` : '0',
      backgroundColor: subMenuBgColor,
    };
  }

  useEffect(() => {
    if (slideStatus && isMobile) {
      setSlideStatus(false);
    }
  }, [isMobile]);

  let slideLeft = '0';

  if (slideStatus && menuOpenType === 'extrusion') {
    if (navPosition === 'left') {
      slideLeft = `${menuCurrWidth}px !important`;
    } else {
      slideLeft = `${-menuCurrWidth}px !important`;
    }
  }

  return (
    <Style className={customClass} subMenuTextColor={subMenuTextColor} gColor={headerGlobalStyle.color}>
      <GlobalStyle slideLeft={slideLeft} />
      {slideStatus && showMask && (
        <div
          onClick={() => {
            setSlideStatus(false);
          }}
          className='pc-side-header-mask'
        />
      )}
      <div className='section-marker'>
        {firstMenuList.length > 0 && (
          <div onClick={onSectionClick} className='header-pc-side-menu-header'>
            {title && <div className='first-menu-name'>{title}</div>}
            <svg
              className='side-slide-container-head-icon'
              style={style}
              width={iconSize}
              height={iconSize}
              aria-hidden='true'
            >
              <use xlinkHref={`#${expendIconName}`} />
            </svg>
          </div>
        )}
        <div className='side-slide' style={navPositionStyle}>
          <div className={cn('side-slide-container', menuContainerClass)} style={{ backgroundColor: subMenuBgColor }}>
            <span onClick={() => setSlideStatus(false)}>
              <svg className='side-slide-close-icon' width='24' height='24' aria-hidden='true'>
                <use xlinkHref={`#${headIconName}`} />
              </svg>
            </span>
            {slideStatus ? (
              <div
                className='side-slide-left-scroll-body'
                style={{
                  justifyContent: sideSubMenuAlign === 'top' ? 'flex-start' : 'center',
                }}
              >
                <div className='side-slide-left-scroll-content'>{children}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Style>
  );
};
