import styled from 'styled-components';

export const DescStyle = styled.div`
  display: flex;
  align-items: center;
  .desc {
    margin-left: 8px;
    margin-right: 8px;
    flex: 1;
    color: rgba(var(--color_body_text_rgb), 0.6);
  }
  .icon {
    fill: #982424;
    margin-right: 6px;
  }
  .discount-tag-icon {
    border-color: var(--color_activity_label);
    color: var(--color_activity_label);
  }
`;
