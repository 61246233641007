import styled from 'styled-components';

export const CollapseTitleStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: auto;
`;

export const CollapseStyle = styled.div`
  .ht-collapse {
    .zent-collapse-panel {
      &:last-child {
        &.zent-collapse-panel--inactive {
          .zent-collapse-panel__title {
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: rgba(var(--color_body_text_rgb), 0.08);
          }
        }
        .zent-collapse-panel__content-box {
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: rgba(var(--color_body_text_rgb), 0.08);
        }
      }
      .zent-collapse-panel__title {
        padding: ${(props) => props.theme.getSpace(props.theme.pCardsNew)}
          ${(props) => props.theme.getSpace(props.theme.vTexts)};
        color: var(--color_body_text);
        background: none;
        border: none;
        font-weight: 600;

        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgba(var(--color_body_text_rgb), 0.08);

        &:hover {
          background: rgba(var(--color_body_text_rgb), 0.04);
        }
        svg {
          fill: var(--color_body_text);
        }
      }
      .zent-collapse-panel__content-box {
        background: none;
        padding: 4px 0;
        border: none;
      }
      .zent-collapse-panel__content {
        background: none;
        color: var(--color_body_text);
        padding: 0 0 ${(props) => props.theme.getSpace(props.theme.pCardsNew)}
          ${(props) => props.theme.getSpace(props.theme.vTexts)};
      }
    }
  }
`;
