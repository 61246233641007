import styled from 'styled-components';

export const Style = styled.div`
  padding: ${(props) => props.theme.getSpace([16, 80])};
  &.full-screen {
    padding: 0;
  }
  .youtube-video-container {
    position: relative;
    background-color: #000;
    transition: all 0.5s;
    overflow: hidden;
  }
  .mask-layer,
  .text-layer,
  .video-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .video-layer {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    &.youtube {
      height: 100%;
    }
  }
  .video-cover {
    z-index: 2;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .mask-layer {
    z-index: 3;
    background: var(--color_image_overlay);
    opacity: var(--image_overlay_opacity);
  }
  .text-layer {
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    padding: 0 ${(props) => props.theme.getSpace([16, 110])};
    &.title-medium {
      .video-title {
        ${(props) => props.theme.getHeaderSize([24, 32])};
      }
      .video-desc {
        ${(props) => props.theme.getBaseSize([16, 24])};
      }
    }
    &.title-large {
      .video-title {
        ${(props) => props.theme.getHeaderSize([32, 48])};
      }
      .video-desc {
        ${(props) => props.theme.getBaseSize([24, 32])};
      }
    }
    .video-title {
      color: #fff;
      ${(props) => props.theme.getHeaderFontInfo()};
    }
    .video-desc {
      color: #fff;
      margin-top: ${(props) => props.theme.getSpace([16])};
    }
  }
  .video-icon-wrap {
    width: ${(props) => props.theme.getSpace([24, 48])};
    height: ${(props) => props.theme.getSpace([24, 48])};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    svg {
      color: #fff;
    }
  }
  .icon-play {
    margin-top: ${(props) => props.theme.getSpace([24])};
  }
  .operate {
    position: absolute;
    z-index: 5;
    top: ${(props) => props.theme.getSpace([16, 24])};
    right: ${(props) => props.theme.getSpace([16, 24])};
  }
`;
