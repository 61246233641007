import { openPcPosterDialog } from './pc-poster-dialog';
import { openMobilePosterDialog } from './mobile-poster-dialog';

const openPosterDialog = (params) => {
  if (document.body.clientWidth >= 750) {
    openPcPosterDialog(params);
  } else {
    openMobilePosterDialog(params);
  }
};
export default openPosterDialog;
