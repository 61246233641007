import { transferLog } from './ambush-log';
import GoogleAnalytics from './google-analytics-4';
import FacebookPixel from './facebook-pixel-log';
import TiktokPixelLog from './tiktok-pixel-log';
import isEmpty from 'lodash/isEmpty';

const retryLog = (logFunc, sdkGlobalKey, i = 0) => {
  return () => {
    if (window[sdkGlobalKey]) {
      logFunc();
    } else if (i <= 100) {
      // eslint-disable-next-line no-plusplus
      i++;
      setTimeout(retryLog(logFunc, sdkGlobalKey, i), 500);
    }
  };
};

class BuriedPoint {
  constructor(eventName, data, action) {
    this.data = data;
    this.eventName = eventName;
    this.action = isEmpty(action)
      ? {
          eventType: 'click',
        }
      : action;
    this.googleAnalytics = new GoogleAnalytics(this.eventName, this.data);
    this.FacebookPixel = new FacebookPixel(this.eventName, this.data);
    this.TiktokPixel = new TiktokPixelLog(this.eventName, this.data);
  }
  // 天网 事件埋点
  ambushLog() {
    transferLog(this.eventName, this.data, this.action);
  }
  // 谷歌数据分析 事件埋点
  googleAnalyticsLog() {
    this.googleAnalytics.eventLog();
  }
  // facebook pixel 事件埋点
  facebookPixelLog() {
    this.FacebookPixel.eventLog();
  }
  tiktokPixelLog() {
    this.TiktokPixel.eventLog();
  }
  // 触发事件上报
  trigger() {
    //  有几种埋点 就触发几种

    retryLog(this.ambushLog.bind(this), '_ambush')();

    retryLog(this.googleAnalyticsLog.bind(this), 'gtag')();

    retryLog(this.facebookPixelLog.bind(this), 'fbq')();
    retryLog(this.tiktokPixelLog.bind(this), 'ttq')();
  }
}

const eventNameActionMap = {
  goods_view: { name: '商品曝光', eventType: 'view' },
};

// 封装埋点函数
const buriedPoint = (eventName, data = {}, action = {}) => {
  // eslint-disable-next-line no-param-reassign
  action = eventNameActionMap[eventName] || action;
  if (window.self !== window.top) {
    // 页面是在 iframe 中加载的
    console.log('页面在 iframe 中加载, 不上报埋点');
    return;
  }
  try {
    const buriedPointInstance = new BuriedPoint(eventName, data, action);
    buriedPointInstance.trigger();
  } catch (err) {
    console.warn(err);
  }
};

export { buriedPoint };
