/**
 * 商品相关的api接口封装
 */
import BaseApi from './base';

class SiteApi extends BaseApi {
  /** 分页查询模板 */
  bulkGetChainSites(params) {
    return this._get('/api/soa/com.youzan.i18n.chainshop.controller.web.ChainShopController/bulkGetChainSites', params);
  }

  getHqChainSites(params) {
    return this._get('/api/soa/com.youzan.i18n.chainshop.controller.web.ChainShopController/getHqChainSites', params);
  }
}

export default new SiteApi();
