import styled from 'styled-components';

export const Style = styled.div`
  .product-media-three {
    display: flex;
    &.right {
      flex-direction: row-reverse;
      .big-pictures {
        margin-left: 0px;
        margin-right: 8px;
      }
    }
  }
  .detail-left-container {
    flex: 1;
  }
`;
export const SmallStyle = styled.div`
  .small-pictures {
    display: inline-block;
    width: 100px;
    .scroll {
      overflow: hidden;
      .list {
        transition: all 1s;
      }
      .item {
        margin-bottom: ${(props) => props.gap}px;
        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          border: 2px solid ${(props) => props.theme.colorButton};
        }
      }
    }
    .changeGroup {
      display: flex;
      justify-content: center;
      margin-top: 8px;
      .arrow {
        fill: ${(props) => props.theme.colorButton};
        &.disabled {
          fill: ${(props) => props.theme.colorBodyTexts['8']};
        }
        &.left-arrow {
          margin-right: ${(props) => props.theme.getSpace(props.theme.hButtons)};
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;
export const BigStyle = styled.div`
  .big-pictures {
    margin-left: 8px;
    flex: 1;
    .item {
      margin-bottom: ${(props) => props.gap}px;
      &:last-child {
        margin-bottom: 0;
      }
      .image-container {
        .content {
          background-color: #000;
          .img {
            object-fit: contain !important;
          }
        }
      }
    }
  }
`;
