// 对应装修的商品分组2.0
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Wrapper } from 'components/base';
import SwiperCore from 'swiper';
import { useCollection, getCollectionInitialProps } from 'hook/collection';
import { Style } from './style';
import isObject from 'lodash/isObject';
import { View, NewButton } from '../../../../components/base';
import { Background } from '../../components/background';
import GoodsCollectionWithLayout from '../../components/goods-collection-with-layout';
import GoodsCollectionWithSwiper from '../../components/goods-collection-with-swiper';
import { useThemeContext } from '../../../../hook/global-theme';
import { buriedPoint } from 'utils/log-buried-point';

SwiperCore.use([]);
/**
 * @name 商品分组2.0
 *
 */
const CollectionV2 = (props) => {
  const { settings = {}, initialData, elemClass, data = [] } = props;
  const { kdtId, userInfo } = initialData;
  const { collection } = settings;
  const groupId = isObject(collection) ? collection?.collectionId : collection;
  const {
    grid = 3,
    rows = 2,
    link_style: linkStyle,
    link_text: linkText,
    bg_color: bgColor,
    box_width: boxWidth,
    text_align: textAlign = 'left',
    title_size: titleSize,
    sub_title_size: subTitleSize,
    text_line_limit: textLineLimit = 'auto',
    mobile_display_type: mobileDisplayType = {},
    background_style: backgroundStyle = 'null',
    background_color: backgroundColor = '#f8f8f8',
    background_image: backgroundImage,
    data_type: dataType = 'collection',
    product_list: productList = [],
    open_card: openCard,
    card_color: cardColor = '#ffffff',
  } = settings;

  const { goodsList } = useCollection({
    initData: data,
    type: dataType,
    kdtId,
    collection,
    grid,
    rows,
    productList,
    customerId: userInfo?.customerId,
  });
  const { title } = props.settings;
  const { main_val: mobileDisplayTypeMainVal = 'tiled', carousel = {} } = mobileDisplayType;
  const { auto_play_interval: mobileAutoPlayInterval = 3 } = carousel;
  const { handle } = settings?.collection ?? {};
  const hasMargin = !(boxWidth === 'full_screen');

  const themeContext = useThemeContext();
  const { isMobile } = themeContext;

  const Bg = Background(bgColor ? 'custom_color' : backgroundStyle);
  const [showSwiper, setShowSwiper] = useState(true);

  /** 播放间隔选项 */
  useEffect(() => {
    setShowSwiper(false);
    setTimeout(() => {
      setShowSwiper(true);
    });
  }, [mobileAutoPlayInterval]);

  useEffect(() => {
    if (goodsList.length) {
      buriedPoint('goods_view', {
        goods_component_location: 'HOME_collection-v2',
        goods_component_location_name: '商品分组2.0',
        goodsList: goodsList.map((item) => {
          // eslint-disable-next-line
          const { goodsId: goods_id, goodsType: goods_type = '', title } = item;
          return {
            goods_id,
            goods_type,
            title,
          };
        }),
      });
    }
  }, [goodsList.length]);

  return (
    <Bg backgroundColor={bgColor || backgroundColor} backgroundImage={backgroundImage}>
      <Style
        className={cn(`cn-${boxWidth}`, `cn-${textAlign}`, elemClass)}
        grid={grid}
        style={{ overflow: 'hidden' }}
        textLineLimit={textLineLimit}
        cardColor={cardColor}
      >
        <Wrapper paddingTop={hasMargin ? 'vContainer' : 0} paddingBottom={hasMargin ? 'vContainer' : 0}>
          <div className={cn('feature-collection-section', { 'open-container-card': openCard && !isMobile })}>
            {(title || (settings && settings.sub_title) || linkText) && (
              <div className={cn('section-intro', { 'full-screen': boxWidth === 'full_screen' })}>
                {title && (
                  <View as='h3' type='header' size={titleSize} className={cn('section-title')}>
                    {title}
                  </View>
                )}
                {settings && settings.sub_title && (
                  <View
                    size={subTitleSize}
                    className='section-sub-title'
                    dangerouslySetInnerHTML={{
                      __html: settings.sub_title || '',
                    }}
                  />
                )}

                {/* product/list/${handle} 不应该再用了 因为不是唯一的*/}
                {!!linkText && dataType === 'collection' && (
                  <NewButton
                    type={linkStyle === 'button' ? 'primary' : 'arrow'}
                    className='link-button'
                    href={handle ? `/product/list/${handle}` : `/product/list/collection?id=${groupId}`}
                  >
                    {linkText}
                  </NewButton>
                )}
              </div>
            )}
            {!(mobileDisplayTypeMainVal === 'carousel' && isMobile) && (
              <GoodsCollectionWithLayout
                className='feature-collection-grid'
                goodsList={goodsList}
                initialData={initialData}
                settings={settings}
                cardType={isMobile ? '' : 'big'}
              />
            )}
            {isMobile && mobileDisplayTypeMainVal === 'carousel' && showSwiper && (
              <GoodsCollectionWithSwiper goodsList={goodsList} initialData={initialData} settings={settings} />
            )}
          </div>
        </Wrapper>
      </Style>
    </Bg>
  );
};

CollectionV2.getInitialProps = getCollectionInitialProps;

export default CollectionV2;
