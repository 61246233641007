import React, { useEffect, useRef, useState } from 'react';
import localApi from 'api/local';
import cn from 'classnames';
import { Style } from './style';

const PreviewVideo = ({ mediaId, coverUrl, handle }) => {
  const [url, setUrl] = useState();
  const videoRef = useRef({});
  const [playStatus, setPlayStatus] = useState('paused');
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const togglePlay = async () => {
    const res = await localApi.getVideoPlayInfo({ mediaId });
    setUrl(res?.playUrl);
    const { paused = true, currentTime = 0 } = videoRef.current || {};
    setCurrentVideoTime(currentTime);
    if (paused) {
      setPlayStatus('play');
      videoRef.current.play();
    } else {
      setPlayStatus('paused');
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    setPlayStatus('paused');
    videoRef.current.pause();
  }, [url]);

  return (
    <Style>
      <div className='video-wrapper' onClick={togglePlay}>
        <div
          className={cn('video-body', `video-body-status-${playStatus}`, `video-body-status-time-${currentVideoTime}`)}
        >
          <video
            className='video-inner'
            ref={videoRef}
            src={url}
            onEnded={() => {
              // 播放结束后回到第一侦
              setPlayStatus('paused');
              videoRef.current.currentTime = 0;
            }}
          />
          {coverUrl && <img className='video-cover' src={coverUrl} alt={handle} />}
        </div>
        {playStatus === 'paused' && (
          <div className='video-action'>
            <div className='play-button'>
              <svg className='icon play-icon' aria-hidden='true'>
                <use xlinkHref='#iconbofang' />
              </svg>
            </div>
          </div>
        )}
      </div>
    </Style>
  );
};

export default PreviewVideo;
