import styled from 'styled-components';

export const Style = styled.div`
  .container-size {
    margin: 0 auto;
    box-sizing: border-box;
    // overflow: hidden;
    &.full-screen {
      height: 100vh;
      width: 100% !important;
      max-width: unset;
    }
    &.full-width {
      width: 100% !important;
      max-width: unset;
    }
    &.fix-960 {
      // js处理
      width: 960px;
      max-width: calc(
        100% - 2 * ${(props) => (props.needSafeSpace ? props.theme.getSpace(props.theme.hContainer) : '0px')}
      );
    }
    &.fix-1280 {
      // js处理
      width: 1280px;
      max-width: calc(
        100% - 2 * ${(props) => (props.needSafeSpace ? props.theme.getSpace(props.theme.hContainer) : '0px')}
      );
    }
    &.fix-1440 {
      // js处理
      width: 1440px;
      max-width: calc(
        100% - 2 * ${(props) => (props.needSafeSpace ? props.theme.getSpace(props.theme.hContainer) : '0px')}
      );
    }
    &.margin-80 {
      margin: 0 ${(props) => props.theme.getSpace(props.theme.hContainer)};
      max-width: unset;
      width: unset;
    }
  }
`;
