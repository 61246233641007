/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable indent */
import React from 'react';
import { SectionsMap } from '../../design/config/sections_map';
import { DecorationWrapper } from './hoc';
import { MessageType } from 'const';

export const memoedWrapedSectionsMap = {};

Object.keys(SectionsMap).forEach((section) => {
  // console.log(section, 'section', SectionsMap[section].component);
  const Ele = React.memo(DecorationWrapper(SectionsMap[section].component, section));
  Ele.getInitialProps = SectionsMap[section].getInitialProps;
  memoedWrapedSectionsMap[section] = Ele;
});

export const memoedWrappedSectionsMap = {};
export const memoedWrappedSectionsMapV2 = (NewSectionsMap = {}) => {
  Object.keys(NewSectionsMap).forEach((section) => {
    const Ele = React.memo(DecorationWrapper(NewSectionsMap[section].component, section));
    Ele.getInitialProps = NewSectionsMap[section].getInitialProps;
    memoedWrappedSectionsMap[section] = Ele;
  });
};
