import React from 'react';
import cn from 'classnames';
import Style from './style';

const HuntTag = (props) => {
  const { className, children, bordered = true } = props;
  return <Style className={cn(className, `hunt-tag-border-${bordered}`)}>{children}</Style>;
};

export default HuntTag;
