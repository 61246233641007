import styled from 'styled-components';

export const Style = styled.div`
  .empty-result {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 80px;
    .icon {
      width: 86px;
      height: 76px;
      fill: #b3b9c6;
    }
    .tips {
      font-size: var(--base-font-14-12);
      color: #aeb1b6;
    }
  }
  @media screen and (max-width: 750px) {
  }
`;
