import React from 'react';
import { DefaultImg } from 'cpn/default-img';
import cn from 'classnames';
import { Style } from './style';
import Image from 'components/base/image';

const ProductGallery = React.forwardRef(
  ({ mediaList, handle = '', galleryDirection, isGalleryWrap, activeIndex, onGoTO, onNext, onPrev, isZoom }, ref) => {
    const handleChange = (type) => {
      let index = 0;
      if (type === 'prve') {
        index = activeIndex === 0 ? 0 : activeIndex - 1;
        // eslint-disable-next-line no-unused-expressions
        onPrev && onPrev(index);
      }
      if (type === 'next') {
        index = activeIndex === mediaList.length - 1 ? mediaList.length - 1 : activeIndex + 1;
        // eslint-disable-next-line no-unused-expressions
        onNext && onNext(index);
      }
      onGoTO(index);
    };

    return (
      <Style>
        <div
          className={cn(
            'media-gallery',
            `media-gallery-direction-${galleryDirection}`,
            `${isGalleryWrap ? 'media-gallery-wrap' : ''}`,
            `${mediaList.length > 4 ? '' : 'media-gallery-padding'}`,
          )}
        >
          {mediaList.length > 4 && (
            <svg
              className={cn(
                'icon ',
                'horizontal-icon',
                activeIndex === 0 && 'disabled',
                // galleryDirection !== 'horizontal' && 'hidden',
                galleryDirection !== 'horizontal' && '',
              )}
              aria-hidden='true'
              width='24'
              height='24'
              onClick={() => {
                handleChange('prve');
              }}
            >
              <use xlinkHref='#iconic-zuoyouqiehuan' />
            </svg>
          )}
          {mediaList?.length > 1 && (
            <ul ref={ref} className='media-list'>
              {mediaList?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={cn('media-list-item', activeIndex === index && 'media-list-item-active', {
                      'media-list-item-cart': !isZoom,
                    })}
                    onClick={() => {
                      onGoTO(index);
                    }}
                  >
                    {item.type === 'VIDEO' ? (
                      <>
                        {item.coverUrl ? <Image src={item.coverUrl} alt={handle} /> : <DefaultImg />}
                        <div className='play-icon-wrapper'>
                          <svg className='icon play-icon' aria-hidden='true'>
                            <use xlinkHref='#iconbofang' />
                          </svg>
                        </div>
                      </>
                    ) : (
                      <>
                        {item.url || item.coverUrl ? (
                          <Image src={item.url || item.coverUrl} alt={handle} />
                        ) : (
                          <DefaultImg />
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          )}

          {mediaList.length > 4 && (
            <>
              <svg
                className={cn(
                  'icon',
                  'horizontal-icon',
                  'horizontal-icon-right',
                  activeIndex === mediaList.length - 1 && 'disabled',
                  galleryDirection !== 'horizontal' && '',
                  // galleryDirection !== 'horizontal' && 'hidden',
                )}
                aria-hidden='true'
                width='24'
                height='24'
                onClick={() => {
                  handleChange('next');
                }}
              >
                <use xlinkHref='#iconic-zuoyouqiehuan' />
              </svg>

              <span className={cn('vertical-arrow-list', galleryDirection !== 'vertical' && 'hidden')}>
                <svg
                  className={cn('icon', 'icon-arrow-top', activeIndex === 0 && 'disabled')}
                  aria-hidden='true'
                  width='30'
                  height='30'
                  onClick={() => {
                    handleChange('prve');
                  }}
                >
                  <use xlinkHref='#iconic-xiala1' />
                </svg>
                <svg
                  className={cn('icon', activeIndex === mediaList.length - 1 && 'disabled')}
                  aria-hidden='true'
                  width='30'
                  height='30'
                  onClick={() => {
                    handleChange('next');
                  }}
                >
                  <use xlinkHref='#iconic-xiala1' />
                </svg>
              </span>
            </>
          )}
        </div>
      </Style>
    );
  },
);

export default ProductGallery;
