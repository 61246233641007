import React, { useState, useEffect } from 'react';
import { Progress, Icon, Rate, Button, LitePagination, Dialog } from 'zent';
import { useIntl } from 'react-intl';
import { Style } from './style';
import moment from 'moment';
import cn from 'classnames';
import assign from 'lodash/assign';
import { intlMessage as $t } from 'utils/intl/formate';
import ImageView from 'cpn/photo-view';
import goodsApi from 'api/goods';
import ReviewsPoster from '../review-poster';
import SectionTitle from '../../../../../../pages/components/section-title';

const { openDialog, closeDialog } = Dialog;
const PAGE_SIZE = 20;
// todo
// 文本截断组件

export const ReviewStrList = ({ list = [] }) => {
  let len = list.length + 1;
  return (
    <div className='score-list-wrap'>
      {list.map((val, index) => {
        len -= 1;
        return (
          <div className='score-list-item' key={index}>
            <span className='score-item-value score-item-star'>{len}</span>
            <Icon className='score-star-icon' type='star' />
            <Progress percent={val} width={164} strokeWidth={8} showInfo={false} />
            <span className='score-item-value score-item-percent'>{val}%</span>
          </div>
        );
      })}
    </div>
  );
};

const ReviewsDetail = (props) => {
  const {
    reviewOverview,
    fetchGoodReviewOverview,
    reviewTotalCount: total,
    setReviewTotalCount,
    goodsDetail = {},
    titleConfig,
  } = props;
  const { hideTitle = false, textAlign = 'center', noLine = false } = titleConfig || {};
  const { kdtId, goodsId } = goodsDetail;
  const intl = useIntl();
  const initialQuery = {
    page: 1,
    isIncludeImage: 0,
    isReply: 0,
  };

  const [reviewList, setReviewList] = useState([]);
  const [query, setQuery] = useState(initialQuery);

  const fetchGoodReviewList = (pageOption) => {
    const { isReply, isIncludeImage } = query;
    const { current: currentPage = 1, pageSize = PAGE_SIZE } = pageOption;
    let params = {
      kdtId,
      goodsId,
      page: currentPage,
      pageSize,
    };

    if (isReply) {
      params = assign(params, {
        isReply,
      });
    }

    if (isIncludeImage) {
      params = assign(params, {
        isIncludeImage,
      });
    }

    goodsApi.searchReviewsWithPage(params).then((res) => {
      const { items, paginator: { totalCount } = {} } = res || {};
      setQuery({ ...query, page: currentPage });
      setReviewTotalCount(totalCount);
      setReviewList(items);
    });
  };

  const onAddReview = () => {
    const REVIEW_POSTER_DIALOG = 'REVIEW_POSTER_DIALOG';
    const onClose = () => {
      closeDialog(REVIEW_POSTER_DIALOG);
    };
    const onSuccess = () => {
      setQuery(initialQuery);
      fetchGoodReviewOverview();
      fetchGoodReviewList({ current: 1, pageSize: PAGE_SIZE });
    };
    openDialog({
      style: {
        width: '640px',
      },
      className: 'review-poster-dialog',
      dialogId: REVIEW_POSTER_DIALOG,
      children: <ReviewsPoster {...props} onSuccess={onSuccess} intl={intl} onClose={onClose} />,
      closeBtn: false,
    });
  };

  const onAllBtnClick = () => {
    setQuery({
      page: 1,
      isIncludeImage: 0,
      isReply: 0,
    });
  };

  const onHasImageClick = () => {
    setQuery({
      page: 1,
      isIncludeImage: 1,
      isReply: 0,
    });
  };

  const onHasReplyClick = () => {
    setQuery({
      page: 1,
      isIncludeImage: 0,
      isReply: 1,
    });
  };

  useEffect(() => {
    if (goodsId) {
      fetchGoodReviewList({ current: query.page, pageSize: PAGE_SIZE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodsId, query.isReply, query.isIncludeImage]);

  const showReviewOverview = reviewList.length && reviewOverview;

  return (
    <Style className={cn('reviews-content', { 'left-right-layout': hideTitle })}>
      {!hideTitle && (
        <SectionTitle textAlign={textAlign} noLine={noLine} title={`${$t(intl, 'product_comments')}(${total})`} />
      )}
      <div className='review-overview'>
        <div className='review-overview-box'>
          {showReviewOverview ? (
            <div className='overview-score-content'>
              <div className='review-overview-score'>
                <span className='score-number'>{Number(reviewOverview.avgScore).toFixed(1)}</span>
                <Rate allowHalf value={reviewOverview.avgScore} readOnly />
              </div>
              <ReviewStrList list={reviewOverview.scorePercent} />
            </div>
          ) : (
            <Rate className='rate-review-empty' allowHalf value={5} readOnly />
          )}
          <Button onClick={onAddReview} className='review-btn' type='primary'>
            {$t(intl, 'review.write')}
          </Button>
        </div>
        {showReviewOverview ? <ReviewStrList list={reviewOverview.scorePercent} /> : null}
      </div>
      <div className='reviews-filter'>
        <Button
          className={!query.isReply && !query.isIncludeImage ? 'reviews-filter-active' : 'reviews-filter-disable'}
          onClick={onAllBtnClick}
          type='primary'
          outline
        >
          {$t(intl, 'general.all')}
        </Button>
        <Button
          className={query.isIncludeImage ? 'reviews-filter-active' : 'reviews-filter-disable'}
          onClick={onHasImageClick}
        >
          {$t(intl, 'review.has.image')}
        </Button>
        <Button
          className={query.isReply ? 'reviews-filter-active' : 'reviews-filter-disable'}
          onClick={onHasReplyClick}
        >
          {$t(intl, 'review.has.reply')}
        </Button>
      </div>
      {reviewList.length ? (
        <>
          <ul className='review-list skiptranslate'>
            {reviewList.map((item) => {
              const { content, firstName, lastName, score, images = [], isReply: isAnswer, reply, createdAt } = item;

              return (
                <li className='review-list-item' key={item?.createdAt}>
                  <div className='review-author-info'>
                    <span className='review-author-name'>{`${lastName} ${firstName}`}</span>
                    <span className='review-time-str'>{moment(createdAt).format('YYYY-MM-DD')}</span>
                  </div>
                  <div className='review-content-info'>
                    <Rate className='review-score-rate' allowHalf value={score} readOnly />
                    <p className='review-text'>{content}</p>
                    {images.length ? (
                      <ImageView
                        className='review-image-list'
                        imageClassName='review-image-item'
                        photoImages={images}
                        handle={content}
                      />
                    ) : null}
                    {isAnswer && reply ? (
                      <div className='review-reply-content'>
                        <span className='review-reply-by'>
                          {$t(intl, 'common_seller')} {$t(intl, 'review.reply')}：
                        </span>
                        <span className='review-reply-text'>{reply}</span>
                      </div>
                    ) : null}
                  </div>
                </li>
              );
            })}
          </ul>
          {total > PAGE_SIZE ? (
            <LitePagination
              buttonBordered={false}
              current={query.page}
              pageSize={PAGE_SIZE}
              total={total}
              onChange={fetchGoodReviewList}
            />
          ) : null}
        </>
      ) : (
        <div className='review-list-empty'>
          {`${$t(intl, 'review.list.empty')} `}
          <span className='review-empty-btn' onClick={onAddReview}>
            {$t(intl, 'review.write')}
          </span>
        </div>
      )}
    </Style>
  );
};

export default ReviewsDetail;
