import Style from './style';
import React from 'react';
import cn from 'classnames';

const SectionTitle = ({ title, textAlign = 'center', noLine = false }) => {
  return (
    <Style>
      <div className={cn('section-title', textAlign, { 'no-line': noLine })}>
        <span className='text'>{title}</span>
      </div>
    </Style>
  );
};
export default SectionTitle;
