import styled from 'styled-components';

export const Style = styled.div`
  .section-head {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: var(--color_body_text);
    line-height: 28px;
  }

  .section-line {
    flex: 1;
    height: 1px;
    background-color: #dcdee0;
  }

  .review-overview {
    margin: 0 0 32px;
  }

  .review-overview-box {
    display: flex;
    align-items: center;

    .rate-review-empty {
      .zent-rate-star {
        margin-right: 8px;
      }

      .zent-rate-star-icon {
        font-size: 24px;
      }
    }
  }

  .review-overview-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    padding: 24px 0;
    border-radius: 4px;
    margin-right: 16px;

    .zent-rate-star {
      margin-right: 4px;
    }
  }

  .score-number {
    font-size: 40px;
    font-weight: 600;
    color: var(--color_body_text);
    line-height: 1;
    padding-bottom: 20px;
  }

  .score-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .zent-progress-wrapper {
      background-color: #ffc7001a !important;
    }

    .zent-progress-inner {
      border-radius: 4px !important;
      background-color: #ffc700 !important;
    }

    .zent-progress-info {
      color: var(--color_body_text) !important;
    }

    + .score-list-item {
      margin-top: 8px;
    }
  }

  .score-item-value {
    font-size: 14px;
    font-weight: bold;
    color: var(--color_body_text);
    line-height: 16px;
  }

  .score-item-percent {
    width: 48px;
    text-align: right;
  }

  .score-item-star {
    width: 18px;
    text-align: center;
  }

  .score-star-icon {
    padding-left: 4px;
    padding-right: 10px;
    color: #ffc700;
  }

  .review-btn {
    margin-left: auto;
    white-space: nowrap;
  }

  .reviews-filter {
    display: flex;
    margin-bottom: 16px;
    justify-content: flex-end;
    flex-wrap: wrap;

    .zent-btn {
      height: 36px !important;
      line-height: 36px !important;
      font-size: 16px !important;
      padding: 0 15px;
      background: transparent !important;
      color: rgba(var(--color_body_text_rgb), 0.8) !important;
      border-color: rgba(var(--color_body_text_rgb), 0.8) !important;
    }

    /* .reviews-filter-disable {
            color: rgba(var(--color_body_text_rgb), 0.2) !important;
            border-color: rgba(var(--color_body_text_rgb), 0.2) !important;
        } */
    .reviews-filter-active {
      color: var(--color_button) !important;
      border-color: var(--color_button) !important;
      background: transparent !important;
    }

    + .review-list-empty {
      padding-top: 24px;
    }
  }

  .review-list-item {
    display: flex;
    justify-content: space-between;

    padding: 24px 0;

    + .review-list-item {
      border-top: 1px solid var(--color_borders);
    }
  }

  .review-author-info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 12px;
  }

  .review-author-name {
    width: 200px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 20px;

    font-weight: 600;
    color: var(--color_body_text);
  }

  .review-time-str {
    line-height: 20px;
    font-weight: 400;
    color: #969799;
  }

  .review-score-rate svg.zent-rate-star-icon {
    font-size: 18px;
  }

  .review-text {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color_body_text);
    line-height: 20px;
    word-break: break-word;
    white-space: break-spaces;
  }

  .review-image-list {
    display: flex;
    margin-top: 16px;
  }

  .review-image-item {
    width: 80px;
    height: 80px;
    margin-right: 8px;
    margin-top: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .review-reply-content {
    margin-top: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #646566;
    line-height: 20px;
  }

  .overview-score-content {
    display: flex;
  }

  .review-list-empty {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--color_body_text);
    line-height: 20px;
  }

  .review-empty-btn {
    color: var(--color_text);
    text-decoration: underline;
    cursor: pointer;
  }

  .score-star-percent {
    margin-left: 1em;
  }

  @media screen and (min-width: 1081px) {
    .review-content-info {
      width: 78.6%;
    }

    .review-overview-score {
      background: rgba(var(--color_body_text_rgb), 0.04);
    }

    .review-author-name {
      margin-bottom: 8px;
    }

    .review-author-name {
      margin-bottom: 8px;
    }

    .review-score-rate {
      .zent-rate-star {
        margin-right: 6px;
      }
    }

    .review-overview > .score-list-wrap {
      display: none;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .review-list-item {
      flex-direction: column;
    }

    .review-overview-score {
      background: rgba(var(--color_body_text_rgb), 0.04);
    }

    .review-overview > .score-list-wrap {
      display: none;
    }

    .review-author-name {
      margin-bottom: 8px;
    }

    .review-score-rate {
      .zent-rate-star {
        margin-right: 6px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .review-list-item {
      flex-direction: column;
    }

    .review-image-list {
      flex-wrap: wrap;
      margin-top: 8px;
    }

    .image-list-1,
    .image-list-2 {
      .review-image-item {
        width: calc(50% - 4px);
        height: 0;
        padding-top: calc(50% - 4px);
        position: relative;

        img {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .image-list-3,
    .image-list-4,
    .image-list-5,
    .image-list-6 {
      .review-image-item {
        width: calc((100% - 16px) / 3);
        height: 0;
        padding-top: calc((100% - 16px) / 3);
        position: relative;

        img {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .image-list-3 {
      .review-image-item {
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .image-list-5,
    .image-list-6 {
      .review-image-item {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .overview-score-content .score-list-wrap {
      display: none;
    }

    .score-list-item .zent-progress {
      width: 100%;

      .zent-progress-wrapper {
        width: 100% !important;
      }
    }

    .review-overview-box {
      justify-content: space-between;
      margin-bottom: 40px;
    }

    .review-btn {
      margin: 0;
    }

    .review-author-info {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .review-score-rate {
      .zent-rate-star {
        margin-right: 4px;
      }

      svg.zent-rate-star-icon {
        font-size: 12px;
      }
    }

    .review-overview-score {
      padding: 0;
      align-items: flex-start;

      .score-number {
        padding-bottom: 4px;
      }
    }

    .score-number {
      padding-bottom: 10px;
      line-height: 1;
      font-size: 28px;
    }

    .reviews-filter {
      .zent-btn {
        height: 28px !important;
        line-height: 28px !important;
        font-size: 14px !important;
        padding: 0 14px;

        + .zent-btn {
          margin-left: 8px;
        }
      }
    }
  }
`;
