import styled from 'styled-components';

export const Style = styled.div`
  display: inline-block;
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // 大按钮
    &:hover,
    &:active {
      opacity: 0.8;
    }
    &[disabled] {
      opacity: 0.3;
    }
    &.large {
      ${(props) => props.theme.getHeaderSize([13, 14, 16])}; // todo 待出字号规范变量
    }
    // 中按钮
    &.medium {
      ${(props) => props.theme.getHeaderSize([12, 13, 14])}; // todo 待出字号规范变量
      font-weight: 500;
    }
    // 小按钮
    &.small {
      ${(props) => props.theme.getHeaderSize([11, 11, 12])}; // todo 待出字号规范变量
    }
    // 主要按钮
    &.primary {
      color: ${(props) => props.color || props.theme.colorButtonText};
      background-color: ${(props) => props.bgColor || props.theme.colorButton};
      border-radius: var(--border_radius_btn);
      &.large {
        padding: ${(props) => props.theme.getSpace([10, 11, 12])} ${(props) => props.theme.getSpace([24, 28, 32])};
      }
      &.medium {
        padding: ${(props) => props.theme.getSpace([8, 9, 10])} ${(props) => props.theme.getSpace([16, 20, 24])};
      }
      &.small {
        padding: ${(props) => props.theme.getSpace([6, 7, 8])} ${(props) => props.theme.getSpace([12, 16])};
      }
      font-weight: 500;
    }
    // 次要按钮
    &.secondary {
      border-radius: var(--border_radius_btn);
      border: 1px solid ${(props) => props.color || props.theme.colorSmallButtonTextBorder};
      color: ${(props) => props.color || props.theme.colorSmallButtonTextBorder};
      background-color: ${(props) => props.bgColor || props.theme.colorSmallButtonBg};
      // 次按钮有边框，padding需小1，box-sizing 在没有设定宽高时无效
      &.large {
        padding: ${(props) => props.theme.getSpace([9, 10, 11])} ${(props) => props.theme.getSpace([23, 27, 31])};
      }
      &.medium {
        padding: ${(props) => props.theme.getSpace([7, 8, 9])} ${(props) => props.theme.getSpace([15, 19, 23])};
      }
      &.small {
        padding: ${(props) => props.theme.getSpace([7, 8, 9])} ${(props) => props.theme.getSpace([15, 19, 23])};
      }
      font-weight: 500;
    }
    // 文字按钮
    &.text {
      color: ${(props) => props.color || props.theme.colorButton};
    }
    // 箭头按钮
    &.arrow {
      color: ${(props) => props.color || props.theme.colorButton};
    }
  }
`;
