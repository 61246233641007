import React from 'react';
import ReactDOM from 'react-dom';
import ActionSuccessNotice from 'cpn/action-success-notice';

export const useNotice = () => {
  const notifyId = 'shopping-cart-notify';
  const showNotice = (config) => {
    const cartRef = document.getElementById(notifyId);
    if (cartRef) return;
    const container = document.createElement('div');
    container.setAttribute('id', notifyId);
    document.body.appendChild(container);
    const targetElement = document.getElementById(config?.targetId);
    if (!targetElement) return;
    const locationInfo = targetElement.getBoundingClientRect();
    const { left, bottom } = locationInfo;
    const style = {
      left: `${left - 182}px`,
      top: `${bottom}px`,
      marginTop: '40px',
    };
    const showBox = () => <ActionSuccessNotice text={config.text} style={style} />;
    ReactDOM.render(React.createElement(showBox), container);
  };

  const closeNotice = () => {
    const cartRef = document.getElementById(notifyId);
    const parentDiv = cartRef?.parentNode;
    parentDiv?.removeChild(cartRef);
  };

  return {
    showNotice,
    closeNotice,
  };
};
