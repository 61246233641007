import React, { useState } from 'react';
import { Input, Button, Notify } from 'zent';
import { useIntl } from 'react-intl';
import { intlMessage as $t } from 'utils/intl';
import StoreApi from 'api/store';
import { Style } from './style';

/**
 * footer 订阅新闻
 * */
const FooterNews = ({
  settings = {},
  style,
  initialData,
  mobileDeviceFooterTextAlign,
  mobileDeviceMenuStyle,
  mobileDisplayTwoInOneLine,
}) => {
  const {
    btn_title: btnTitle = '',
    input_style: inputStyle = 'style_2',
    color_button_bg: colorButtonBg,
    color_button_text: colorButtonText,
    placeholder = '',
    text = '',
    title = '',
  } = settings;

  const intl = useIntl();
  const { kdtId } = initialData;
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const createCustomer = () => {
    if (!value || value.indexOf('@') === -1) {
      Notify.error($t(intl, 'pleaseInputEmailByValid'));
      return;
    }
    setIsLoading(true);
    StoreApi.subscribe({
      email: value,
      kdtId,
      acceptsMarketing: 1, // 订阅状态
    }).then((res) => {
      setIsLoading(false);
      Notify.success(res?.message || 'Successful.');
    });
  };

  return (
    <Style
      mobileDeviceFooterTextAlign={mobileDeviceFooterTextAlign}
      mobileDeviceMenuStyle={mobileDeviceMenuStyle}
      mobileDisplayTwoInOneLine={mobileDisplayTwoInOneLine}
      colorButtonBg={colorButtonBg}
      colorButtonText={colorButtonText}
    >
      <div className='footer-news-section'>
        <h3 className='footer-news-title'>{title}</h3>
        {text && (
          <div className='footer-news-text' style={style}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        )}
        <div className={`input-style-wrapper input-style-${inputStyle}`}>
          <Input
            size='large'
            className='footer-news-email-input'
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <Button className='footer-news-email-btn' loading={isLoading} type='button' onClick={createCustomer}>
            {btnTitle || 'SUBSCRIBE'}
          </Button>
        </div>
      </div>
    </Style>
  );
};

export default FooterNews;
