// 视频
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { DefaultImg } from 'cpn/default-img';
import cn from 'classnames';
import { Style } from './style';
import { useIntl } from 'react-intl';
import YouTubePlayer from 'youtube-player';
import { useMedioUrl } from '../../../../hook/video';
import { useThemeContext } from '../../../../hook/global-theme';
import styled from 'styled-components';
import Image from 'components/base/image';
import useFillImage from 'hook/use-fill-image';

const PC_HEIGHT_MAP = {
  small: 560,
  medium: 720,
  large: 880,
};

const MOBILE_HEIGHT_MAP = {
  small: 226,
  medium: 254,
  large: 310,
};

const PLAY_STATUS = {
  PLAYING: 'playing',
  PAUSED: 'paused',
};

const titleTextSizeClass = {
  medium: 'title-medium',
  large: 'title-large',
};

const getYouTubeId = (url) => {
  if (!url) return '';
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  return url.match(regExp)?.[2];
};

let player = null;

const Video = (props) => {
  const { settings = {}, elemClass } = props;
  const {
    height = 565,
    // image,
    image_alignment: imagePosition,
    show_overlay: showOverlay,
    text_size: textSize,
    video_link: videoLink,
    media_id: mediaId,
    video_title: videoTitle,
    video_sub_title: videoSubTitle,
    play_way: playWay,
    box_width: boxWidth,
  } = settings;
  const { image } = useFillImage(settings?.image);

  const { isMobile } = useThemeContext();

  // 移动端不支持自动播放
  const style = isMobile ? 'image_with_play' : settings.style;

  const [playStatus, setPlayStatus] = useState(PLAY_STATUS.PAUSED);

  const showTextLayer = useMemo(() => {
    return playStatus !== PLAY_STATUS.PLAYING || style === 'background';
  }, [playStatus, style]);

  const isYouToBe = videoLink?.indexOf('youtube') !== -1;

  let videoId;
  let videoIdContainer;

  if (isYouToBe) {
    videoId = videoLink ? getYouTubeId(videoLink) : '';
    videoIdContainer = `video-${elemClass}`;
  }

  const { medioUrl } = useMedioUrl(mediaId);

  const videoRef = useRef(null);

  const heightMap = useMemo(() => {
    return isMobile ? MOBILE_HEIGHT_MAP : PC_HEIGHT_MAP;
  }, [isMobile]);

  useEffect(() => {
    setTimeout(() => {
      if (isYouToBe) {
        // 自己等媒体库视频，不用走以下逻辑
        player = YouTubePlayer(videoIdContainer, {
          videoId,
          playerVars: {
            autohide: 0,
            autoplay: 0,
            branding: 0,
            controls: 0,
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
            wmode: 'opaque',
          },
        });
        // 如果是背景视频，那么就自动播放
        if (style === 'background') {
          setPlayStatus(PLAY_STATUS.PLAYING);
          player.playVideo();
        } else {
          player.pauseVideo();
          setPlayStatus(PLAY_STATUS.PAUSED);
        }
        player.on('stateChange', function (event) {
          if (event.data === 0) {
            // 播放结束
            if (style === 'background') {
              // 循环播放， player.setLoop不管用
              player.playVideo();
            } else {
              setTimeout(() => {
                // 播放结束之后等500ms再重置，用户体验稍微好一些
                resetVideo();
              }, 500);
            }
          }
        });
      } else {
        // 媒体视频
        if (style === 'background') {
          setPlayStatus(PLAY_STATUS.PLAYING);
          videoRef && videoRef.current && videoRef.current.play();
        } else {
          setPlayStatus(PLAY_STATUS.PAUSED);
          videoRef && videoRef.current && videoRef.current.pause();
        }
      }
    }, 500);
  }, [style]);

  // useEffect(() => {
  //     if (isYouToBe) {
  //         if (videoRef) {
  //             videoRef.current.pause()
  //         }
  //     } else {
  //         if (player) {
  //             player.destroy()
  //             player.pauseVideo()
  //         }
  //     }
  //     setPlayStatus(PLAY_STATUS.PAUSED)
  // }, [videoLink])

  const playVideo = () => {
    setPlayStatus(PLAY_STATUS.PLAYING);
    if (isYouToBe) {
      player.playVideo();
    } else {
      videoRef.current.play();
    }
  };

  const resetVideo = () => {
    if (isYouToBe) {
      // 先回到0
      player.seekTo(0);
      // 再暂停
      player.pauseVideo();
    } else {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
    // 再显示封面
    setPlayStatus(PLAY_STATUS.PAUSED);
  };

  const changePlayStatus = () => {
    if (playStatus === PLAY_STATUS.PLAYING) {
      setPlayStatus(PLAY_STATUS.PAUSED);
      if (isYouToBe) {
        player.pauseVideo();
      } else {
        videoRef.current.pause();
      }
    } else {
      setPlayStatus(PLAY_STATUS.PLAYING);
      if (isYouToBe) {
        player.playVideo();
      } else {
        videoRef.current.play();
      }
    }
  };

  useEffect(() => {
    // 从youtube切换到媒体库视频，销毁之前的player
    if (!isYouToBe && player) {
      player.destroy();
      setPlayStatus(PLAY_STATUS.PAUSED);
    }
  }, [videoLink]);

  const wrapperHeight = useMemo(() => {
    if (height === 'adpat_img' && playStatus !== PLAY_STATUS.PLAYING) {
      return 'auto';
    }
    const computedHeight = `${heightMap[height]}px`;
    return (playStatus === PLAY_STATUS.PLAYING || style === 'background') && !isYouToBe
      ? videoRef?.current?.offsetHeight || computedHeight
      : computedHeight;
  }, [playStatus, heightMap, height, style, boxWidth, videoRef?.current?.offsetHeight]);

  return (
    <Style className={cn('video-section', elemClass, `cn-${boxWidth}`, { 'full-screen': boxWidth === 'full_screen' })}>
      <div className='youtube-video-container' style={{ height: wrapperHeight }}>
        {/* 纯属是为了占位，撑起高度 */}
        {height === 'adpat_img' && image && playStatus !== PLAY_STATUS.PLAYING && (
          <Image src={image} width='100%' style={{ visibility: 'hidden' }} alt={videoTitle || videoSubTitle} />
        )}
        <div className={cn('video-layer', { youtube: isYouToBe })} id={videoIdContainer}>
          {medioUrl && !isYouToBe && (
            <video
              className='video'
              width='100%'
              height={playStatus === PLAY_STATUS.PLAYING ? 'auto' : '100%'}
              ref={videoRef}
              loop='loop'
              autoPlay={playWay === 'auto'}
            >
              <source src={medioUrl} type='video/mp4' />
              <source src={medioUrl} type='video/ogg' />
            </video>
          )}
        </div>
        {playStatus !== PLAY_STATUS.PLAYING && style !== 'background' && (
          <div
            className='video-cover'
            style={{
              backgroundImage: `url(${image || 'https://b.yzcdn.cn/i18n/offical/v2/theme/placeholder.jpeg'})`,
              backgroundPosition: imagePosition,
              backgroundSize: height === 'adpat_img' ? '100% 100%' : 'cover',
            }}
          />
        )}

        {showOverlay && <div className='mask-layer'>mask</div>}
        {showTextLayer && (
          <div className={cn('text-layer', titleTextSizeClass[textSize])}>
            {videoTitle && <div className='video-title'>{videoTitle}</div>}
            {videoSubTitle && <div className='video-desc' dangerouslySetInnerHTML={{ __html: videoSubTitle }} />}
            {style !== 'background' && (
              <div className='video-icon-wrap icon-play' onClick={playVideo}>
                <svg
                  className='icon'
                  aria-hidden='true'
                  height={isMobile ? '10px' : '20px'}
                  width={isMobile ? '10px' : '20px'}
                  fill='#fff'
                >
                  <use xlinkHref='#iconbofang' />
                </svg>
              </div>
            )}
          </div>
        )}
        <div className='operate'>
          {playStatus === PLAY_STATUS.PLAYING && style !== 'background' && (
            <svg
              onClick={resetVideo}
              className='icon icon-close'
              aria-hidden='true'
              height={isMobile ? '24px' : '48px'}
              width={isMobile ? '24px' : '48px'}
              fill='#fff'
            >
              <use xlinkHref='#iconic_shipin_guanbi' />
            </svg>
          )}
          {style === 'background' && (
            <div className='video-icon-wrap'>
              <svg
                onClick={changePlayStatus}
                className='icon'
                aria-hidden='true'
                height={isMobile ? '10px' : '20px'}
                width={isMobile ? '10px' : '20px'}
                fill='#fff'
              >
                <use xlinkHref={playStatus === PLAY_STATUS.PLAYING ? '#iconzanting' : '#iconbofang'} />
              </svg>
            </div>
          )}
        </div>
      </div>
    </Style>
  );
};

export default Video;
