import React, { useState, useEffect, useRef } from 'react';
import { getUrl } from '../../../../util/formate';
import { getQueryStringOfDesign } from '../../../../util/web-tool';
import { PcHeader } from './pc-header';
import { MobileHeader } from './mobile-header';
import shopApi from '../../../../api/shop';
import HeaderWrapper from './style';
import LocalStorage from '../../../../util/local-storage';
import { WISH_COUNT_CHANGE } from '../../../../util/local-storage/config';
import storeApi from 'api/store';
import OrderHeader from '../../../components/order-header';

export const getMenu = async ({ menuId, kdtId }) => {
  if (!kdtId) {
    return new Promise((resolve) => {
      resolve({});
    });
  }
  const res = await storeApi.getShopTemplateMenuItems({
    menuId,
    kdtId,
  });

  return new Promise((resolve) => {
    resolve(res);
  });
};

const Header = (props) => {
  const {
    history = {},
    settings: initSetting = {},
    initialData,
    elemClass,
    cartEnableAjax,
    data = {},
    checkoutHeader,
  } = props;
  const [headerHeight, setHeaderHeight] = useState(77);
  const [settings, setSettings] = useState(initSetting);
  const [totalCountWish, setTotalCountWish] = useState(0);
  const headerEl = useRef(null);
  const { userInfo = {} } = initialData || {};
  const [menuDetail, setMenuDetail] = useState(data);
  const customerId = userInfo?.customerId;
  const { main_linklist: mainLinkList = {} } = settings;

  useEffect(() => {
    const { kdtId, menuId } = mainLinkList || {};
    if (menuId) {
      getMenu({
        menuId,
        kdtId,
      }).then((res) => {
        setMenuDetail(res);
      });
    } else {
      setMenuDetail({});
    }
  }, [mainLinkList?.menuId]);

  const jumpToOtherPage = (path, query) => {
    if (window.isEdit) return;
    if (history) history.push(`${path}${getQueryStringOfDesign(query)}`);
  };

  const jumpToMenuPage = (event, url = '') => {
    if (window.isEdit) return;
    window.location.href = getUrl(url);
    event.stopPropagation();
  };

  const getMainDefaultMenu = () => {
    shopApi.getShopTemplateMenuInit().then((res) => {
      initSetting.main_linklist = res;
      setSettings(initSetting);
    });
  };

  const setHerderFullHeight = () => {
    setHeaderHeight(headerEl.current.clientHeight);
  };

  useEffect(() => {
    setSettings(initSetting);
  }, [initSetting?.show_announcement]);

  useEffect(() => {
    const { main_linklist: mainLinkList } = initSetting;

    if (mainLinkList === 'main-menu') {
      getMainDefaultMenu();
    } else {
      setSettings(initSetting);
    }
  }, [initSetting?.main_linklist]);

  useEffect(() => {
    let updateWishCount;
    if (customerId) {
      shopApi.getWishCountGoods({ customerId }).then((res) => {
        LocalStorage.set(WISH_COUNT_CHANGE, 0);
        if (typeof res === 'number') {
          setTotalCountWish(res);
          updateWishCount = () => {
            const count = Number(LocalStorage.get(WISH_COUNT_CHANGE));
            setTotalCountWish(res + count);
          };
          window.addEventListener('setItemEvent', updateWishCount);
          window.addEventListener('storage', updateWishCount);
        }
      });
    }
    return () => {
      window.removeEventListener('setItemEvent', updateWishCount);
      window.removeEventListener('storage', updateWishCount);
    };
  }, [customerId]);

  useEffect(() => {
    setHerderFullHeight();
  }, []);

  return (
    <HeaderWrapper>
      <div
        style={{
          height: `${headerHeight}px`,
        }}
      >
        <div ref={headerEl} className='fixed_header'>
          <div className='design-mobile-header'>
            <MobileHeader
              history={history}
              settings={settings}
              initialData={initialData}
              menuDetail={menuDetail}
              elemClass={elemClass}
              setHerderFullHeight={setHerderFullHeight}
              cartEnableAjax={cartEnableAjax}
              jumpToOtherPage={jumpToOtherPage}
              jumpToMenuPage={jumpToMenuPage}
              totalCountWish={totalCountWish}
              checkoutHeader={checkoutHeader}
            />
          </div>
          <div className='design-pc-header design-pc-header1'>
            {checkoutHeader ? (
              <OrderHeader settings={settings} jumpToOtherPage={jumpToOtherPage} />
            ) : (
              <PcHeader
                history={history}
                settings={settings}
                menuDetail={menuDetail}
                initialData={initialData}
                elemClass={elemClass}
                setHerderFullHeight={setHerderFullHeight}
                cartEnableAjax={cartEnableAjax}
                jumpToOtherPage={jumpToOtherPage}
                jumpToMenuPage={jumpToMenuPage}
                totalCountWish={totalCountWish}
              />
            )}
          </div>
        </div>
      </div>
      {/* 购物车节点挂载  */}
      <div id='shopping-cart-box' />
    </HeaderWrapper>
  );
};

Header.getInitialProps = (data) => {
  const { main_linklist: mainLinkList = {} } = data;
  const { kdtId, menuId } = mainLinkList || {};

  return getMenu({
    menuId,
    kdtId,
  });
};

export default Header;
