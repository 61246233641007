import styled from 'styled-components';
import { getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  .featured-content-desc {
    color: var(--color_body_text);
  }
  .featured-content-title {
    color: var(--color_text);
  }
  .text-size-small {
    .featured-content-title {
      ${(props) => props.theme.getBaseSize([14, 16])};
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
    }

    .featured-content-desc {
    }
  }

  .text-size-medium {
    .featured-content-title {
      ${(props) => props.theme.getBaseSize([18, 24])};
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
    }

    .featured-content-desc {
    }
  }

  .text-size-large {
    .featured-content-title {
      ${(props) => props.theme.getBaseSize([20, 32])};
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
    }

    .featured-content-desc {
    }
  }

  .featured-content {
    .title {
      text-align: center;
      color: #1a1a1b;
    }
  }

  .featured-content-desc {
    margin-top: ${(props) => props.theme.getSpace([8, 24])};
  }

  .featured-content {
    margin: 0 auto;
    ol,
    ul {
      list-style-position: inside;
    }
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style-type: disc;
    }
  }

  .screen-full-width {
    width: ${getPercentWidth(80)};
  }

  .screen-normal-width {
    width: ${(props) => (props.theme.isMobile ? getPercentWidth(80) : getPercentWidth(320))};
  }
`;
