import styled from 'styled-components';

export default styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: var(--border_radius_pd_card);
  overflow: hidden;
  &.customer-ratio {
    height: 0;
    padding-top: ${(props) => `calc(${props.heightPercent} / 2)`};
    padding-bottom: ${(props) => `calc(${props.heightPercent} / 2)`};
    .goods-img {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: ${(props) => (props.isCut ? 'cover' : 'contain')};
      object-position: center;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .hover-img {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.5s ease-in-out;
  }
  .goods-img {
    max-width: 100%;
    display: block;
    transition: all 0.5s ease-in-out;
    opacity: 1;
    width: 100%;
  }
  &.hover-show:hover {
    .hover-img {
      opacity: 1;
      z-index: 2;
      display: block;
    }
    .goods-img {
      opacity: 0;
    }
  }
  &.image-fill-type-contain {
    img {
      object-fit: contain !important;
    }
    .hover-img {
      background-size: contain !important;
    }
  }
  &.image-fill-type-cover {
    img {
      object-fit: cover !important;
    }
    .hover-img {
      background-size: cover !important;
    }
  }
`;
