import styled from 'styled-components';

export default styled.div`
  position: fixed;
  z-index: var(--zindex-drawer);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .drawer-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .drawer-body {
    position: absolute;
    right: 0;
    width: 83%;
    height: 100vh;
    background: #fff;
    transform: translateX(100%);
    transition: all 0.3s;
  }
  .drawer-body-show {
    transform: translateX(0);
  }
`;
