// 装修共用-文字布局组件
// 可配置项：文字内容布局
import React from 'react';
import Style from './style';
import cn from 'classnames';

const Position = {
  'left top': 'left-top', // 左上方
  'center top': 'center-top', // 顶部居中
  'right top': 'right-top', // 右上方
  'left center': 'left-center', // 中间居左
  'center center': 'center-center', // 中间居中
  'right center': 'right-center', // 中间局右
  'left bottom': 'left-bottom', // 左下方
  'center bottom': 'center-bottom', // 底部居中
  'right bottom': 'right-bottom', // 右下方
};
const TextPosition = (props) => {
  const { position = 'center center', className, ...extra } = props;
  return (
    <Style className={className} {...extra}>
      <div className={cn('text-position', Position[position])}>{props.children}</div>
    </Style>
  );
};

export default TextPosition;
