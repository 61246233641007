/**
 * 菜单相关的api接口封装
 */
import BaseApi from './base';

class StoreApi extends BaseApi {
  getShopTemplateMenuItems(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.template.controller.web.ShopTemplateMenuItemCustomerController/getShopTemplateMenuItems',
      params,
    );
  }
  createCustomer(params) {
    return this._post('/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/createCustomer', params, {
      throttle: true,
    });
  }
  subscribe(params) {
    return this._post('/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/subscribe', params, {
      throttle: true,
    });
  }
}

export default new StoreApi();
