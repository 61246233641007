import styled from 'styled-components';
import { getStylesBytextAlignment } from '../../../../style/fn';

export const Style = styled.div`
  .bar-item-text {
    position: absolute;
    z-index: 2;
    box-sizing: border-box;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.getSpace(props.theme.pCards)};
    ${(props) => getStylesBytextAlignment(props.textAlignment)}
    &.hover-show {
      opacity: 0;
      transition: all 0.5s;
      &:hover {
        opacity: 1;
      }
    }
  }
  .link-button {
    margin-top: 8px;
  }
  .image-bar-contaier {
    display: flex;
    gap: ${(props) => props.theme.getSpace(props.theme[props.gap])};
  }
  .bar-item {
    flex: 1;
    display: block;
    position: relative;
    + .bar-item .bar-item-img {
      border-left: 0;
    }
  }
  .bar-item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .bar-item-img-x-small {
    height: ${(props) => props.theme.getSpace([90, 125])};
  }

  .bar-item-img-small {
    height: ${(props) => props.theme.getSpace([211, 300])};
  }

  .bar-item-img-medium {
    height: ${(props) => props.theme.getSpace([375, 480])};
  }

  .bar-item-img-large {
    height: ${(props) => props.theme.getSpace([500, 650])};
  }

  .bar-item-img-x-large {
    height: ${(props) => props.theme.getSpace([666, 775])};
  }

  .image-position-top {
    object-position: center top;
  }

  .image-position-center {
    background-position: center center;
  }

  .image-position-bottom {
    background-position: center bottom;
  }
  .bar-item-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 560px) {
    .image-bar-contaier {
      display: flex;
      flex-direction: column;
    }
  }
`;
