import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { getUrl } from 'utils/formate';
import StoreApi from 'api/store';
import { Style } from './style';
import NewLink from 'cpn/new-link';
import { FooterMenuCollapse } from '../footer-menu-collapse';

/**
 * footer 系统菜单
 * */
const SubMenu = (props) => {
  const {
    shopTemplateMenuItemTreeNodeCustomerVO: menuList = [],
    menuIndex,
    openMethod,
    style,
    mobileDeviceMenuStyle,
  } = props;
  const isFirstLevel = menuIndex === 1;

  // 目前只渲染两级
  if (menuIndex > 2) {
    return null;
  }

  const newMenuList = useMemo(() => {
    const newMenuList = menuList;

    if (menuIndex === 2) {
      // 下一级的放上来
      let newList = [];
      newMenuList.forEach((item) => {
        newList.push(item);
        newList = newList.concat(item?.shopTemplateMenuItemTreeNodeCustomerVO);
      });
      return newList;
    }
    return newMenuList;
  }, [menuIndex, menuList]);

  return (
    <>
      {newMenuList.map((item, index) => {
        return (
          <div
            className={cn('sub-menu', isFirstLevel ? 'first-level-menu' : 'not-first-leave-menu')}
            key={`${index}-${menuIndex}-${item?.menuItemName}`}
          >
            <FooterMenuCollapse
              mobileDeviceMenuStyle='open'
              isMobileFoldIndent={false}
              style={style}
              title={
                <NewLink
                  className={cn('footer-menu-link', `footer-menu-link-level-${isFirstLevel ? 'first' : 'not-first'}`)}
                  href={getUrl(item?.url)}
                  target={openMethod === 'self' ? '_self' : '_blank'}
                  style={style}
                >
                  {item?.menuItemName}
                </NewLink>
              }
            >
              {!!item?.shopTemplateMenuItemTreeNodeCustomerVO?.length && menuIndex + 1 < 3 && (
                <SubMenu {...item} style={style} menuIndex={menuIndex + 1} />
              )}
            </FooterMenuCollapse>
          </div>
        );
      })}
    </>
  );
};

const FooterMenu = ({
  settings = {},
  style = {},
  mobileDisplayTwoInOneLine,
  mobileDeviceFooterTextAlign,
  mobileDeviceMenuStyle /** fold open */,
}) => {
  const { title, menu = {}, open_method: openMethod = 'self' } = settings;
  const [menus, setMenus] = useState({});

  useEffect(() => {
    let isMounted = true;
    const { menuId, kdtId } = menu;
    if (menuId) {
      StoreApi.getShopTemplateMenuItems({
        menuId,
        kdtId,
      }).then((res = {}) => {
        isMounted && setMenus(res || {});
      });
    } else {
      isMounted && setMenus({});
    }
    return () => {
      isMounted = false;
    };
  }, [menu.menuId]);

  return (
    <Style
      mobileDeviceFooterTextAlign={mobileDeviceFooterTextAlign}
      mobileDeviceMenuStyle={mobileDeviceMenuStyle}
      mobileDisplayTwoInOneLine={mobileDisplayTwoInOneLine}
    >
      <div
        className={cn(
          'footer-menu-section',
          `mobile-device-menu-style-${mobileDeviceMenuStyle}`,
          `mobile-display-two-in-one-line-${mobileDisplayTwoInOneLine}`,
        )}
      >
        <div className={cn('footer-menu-container')}>
          <FooterMenuCollapse
            mobileDeviceFooterTextAlign={mobileDeviceFooterTextAlign}
            mobileDisplayTwoInOneLine={mobileDisplayTwoInOneLine}
            mobileDeviceMenuStyle={mobileDeviceMenuStyle}
            isMobileFoldIndent={!mobileDisplayTwoInOneLine}
            title={
              <span className='footer-menu-title' style={style}>
                {title}
              </span>
            }
            style={style}
          >
            <div className='footer-menu-children'>
              <SubMenu
                {...menus}
                menuIndex={1}
                openMethod={openMethod}
                style={style}
                mobileDeviceMenuStyle={mobileDeviceMenuStyle}
              />
            </div>
          </FooterMenuCollapse>
        </div>
      </div>
    </Style>
  );
};

export default FooterMenu;
