/**
 * 埋点 lib 操作库
 *  1、window 中维护着 _ambushLog 的埋点SDK
 *  2、每次埋点的时候会判断 _ambushLog 是否已初始化
 *  3、未初始化的情况下会将需要埋点的数据推入到 logDelays 埋点队列中
 *  4、_ambushLog 初始化完毕后会依次发送 logDelays 队列中的埋点数据
 *  doc: http://fedoc.qima-inc.com/zan-web-tracker/docs/enterpage.html
 */

import set from 'lodash/set';
import assign from 'lodash/assign';
import { isMobile } from '../../env';
import { getLogUtmParams, getSourceObj, getBrowser } from '../util';
import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from '../../sdk/load-third-javascript';

import { getDistributionCookies } from '../../../helper/affiliate';

const delayEvents = []; // 延迟事件队列

/** 这个方法应该采用不可变数据的写法 */
const addGlobalParams = (params = {}) => {
  const affiliateInfo = getDistributionCookies();
  const affiliateId = affiliateInfo?.cookies?.fields.find((item) => item?.key === 'affiliateId')?.value;
  const utmParams = getLogUtmParams();
  const clientType = isMobile() ? 'mobile' : 'pc';
  const browser = getBrowser();
  const sourceObj = getSourceObj();

  set(params, 'clientType', clientType);
  set(params, 'browser', browser);

  if (affiliateId) {
    set(params, 'affiliateId', affiliateId);
  }

  /** 杭州的店铺模型是有deptId的，这里给个1 */
  return assign({ deptId: 1 }, params, utmParams, sourceObj);
};

/**
 * 触发埋点事件
 *  埋点 sdk 中提供方法比较多，不可能全部返回，通过 trigerAmbushEvent 做一个简单代理
 *  trigerAmbushEvent('log', data) 等同于 _ambush.log(data);
 * @param {*} eventName 事件名
 * @param {*} data 参数
 */
const triggerAmbushEvent = (eventName, data = {}) => {
  if (window.self !== window.top) {
    // 页面是在 iframe 中加载的
    console.log('页面在 iframe 中加载, 不上报埋点');
    return;
  }
  data.event.standard_env = window.i18n_env.APPLICATION_STANDARD_ENV;
  if (window._ambush) {
    window._ambush[eventName](data);
  } else {
    delayEvents.push({
      eventName,
      data,
    });
  }
};

/**
 * 执行素有延迟队列事件
 */
const runDelayEvents = () => {
  if (delayEvents.length > 0) {
    delayEvents.forEach((x) => triggerAmbushEvent(x.eventName, x.data));
    delayEvents.length = [];
  }
};

const log = (data = {}) => {
  const eventParams = addGlobalParams(data.event.params);

  set(data, 'event.params', eventParams);

  triggerAmbushEvent('log', data);
};

const enterpage = (settings = {}) => {
  const { params = {}, initialData = {}, en } = settings;
  const eventParams = addGlobalParams(params);
  const { shopSetting = { language: '', locale: '', shopId: '' } } = initialData;
  const { pathname } = window.location;

  triggerAmbushEvent('enterpage', {
    event: {
      pt: eventParams.pageType,
      et: 'display',
      ei: 'enterpage',
      si: initialData.kdtId || 1,
      en: en || '页面访问', // 事件名称
      params: {
        ...(eventParams || {}),
        path: eventParams.path || pathname,
        kdtId: String(initialData.kdtId || 1),
        shop_id: shopSetting.shopId || 1,
      },
    },
  });
};

const globalEventLog = (type, params = {}, option = {}) => {
  const initialData = window?.global_data ?? {};
  log({
    event: {
      et: option.eventType || 'click',
      ei: type,
      en: option.name || '',
      si: initialData.kdtId || 1,
      params,
    },
  });
};

const pageEventLog = (type, pageType, params = {}, option = {}) => {
  const initialData = window?.global_data ?? {};
  log({
    event: {
      et: option.eventType || 'click',
      ei: type,
      pt: pageType,
      en: option.name || '',
      si: initialData.kdtId || 1,
      params,
    },
  });
};

/**
 * 埋点事件转发
 *
 * 埋点更新
 * 全局事件：原有埋点方式
 * 页面事件：params传入pageType标识页面类型， 对应埋点事件中的event.pt
 * 组件事件：params传入component标识组件类型，对应埋点事件event.params.component
 */
const transferLog = (type, params = {}, option = {}) => {
  if (params.pageType) {
    // 获取pageType，并且删除params中的pageType
    const { pageType } = params;
    delete params.pageType;
    pageEventLog(type, pageType, params, option);
    return;
  }

  globalEventLog(type, params, option);
};

const initSdk = async ({ initialData = {} }) => {
  if (window.self !== window.top) {
    // 页面是在 iframe 中加载的
    console.log('页面在 iframe 中加载, 不上报埋点');
    return;
  }
  if (window._ambushLog) return;
  loadThirdJavascript(
    THIRD_JAVASCRIPT_CONFIG.ZAN_WEB_TRACKER.name,
    THIRD_JAVASCRIPT_CONFIG.ZAN_WEB_TRACKER.url,
    true,
  ).then(() => {
    // https://fedoc.qima-inc.com/zan-web-tracker/docs/setEnv.html
    try {
      const { userInfo = {} } = initialData;

      const trackerParams = {
        yai: 'av_c', // 业务标识 B端 av_b C端 av_c
        app: 'allvalue_c', // 这个app是啥？在哪里设置呢，默认是wscnode
        autoEnterpage: false, // 是否自动上报多页模式 enterpage 事件
        autoSinglePageEnterpage: false, // 是否自动上报单页模式 enterpage 事件
        // autoSinglePageEnterpage: true, // 是否自动上报单页模式 enterpage 事件
        // autoClick: false, // 是否自动上报点击事件
        autoClick: true, // 是否自动上报点击事件
        autoNodeClick: true, // 是否自动上报节点点击事件
        autoNodeView: true, // 是否自动上报节点曝光事件
      };

      if (window.location.host.indexOf('.myallvalue.com') === -1) {
        trackerParams.requestUrl = '/api/node/v3/js/log';
      }

      const tracker = new window.ZanTracker(trackerParams);
      tracker.setEnv({
        data: {
          ip: window.global_data.ip,
        },
      });
      tracker.setEvent({
        data: {
          si: initialData.kdtId || '',
          rurl: '',
        },
      });
      tracker.setUser({
        data: {
          li: userInfo.customerId || '',
          m: userInfo.phone || '',
          user_type: window.global_data?.memberStatus?.status === 'hadOpen' ? 'member' : '', // 会员还是普通用户
          user_type_explain: window.global_data?.memberStatus?.memberPlanInfo || '', // 用户类型补充说明
        },
      });
      window._ambush = tracker;

      runDelayEvents(); // 执行所有延迟事件队列
    } catch (err) {
      console.warn('@youzan/zan-web-tracker 初始化失败 ================>');
      console.warn(err);
      console.warn('@youzan/zan-web-tracker 初始化失败 ================<');
    }
  });
};

export { log, enterpage, globalEventLog, transferLog, initSdk };
