// 文本-图文列表
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Style from './style';
import { DefaultImg } from 'cpn/default-img';
import { Wrapper } from 'components/base';
import NewLink from 'cpn/new-link';
import { getImageInfo } from 'utils/image';
import { getFixedWidth } from '../../../../style/fn';
import isObject from 'lodash/isObject';
import LinkButton from '../../snippets/link-button';
import Image from 'components/base/image';
import { Background } from '../../components/background';

const classMapBySetting = {
  left: 'align-left',
  center: 'align-center',
};

// 图文列表
const FeatureColumns = (props) => {
  const { settings = {}, block_order: blockOrder = [], blocks, elemClass } = props;
  const [firstImageSize, setFirstImageSize] = useState('100%');
  const {
    align_text: alignText,
    bg_color: bgColor,
    btn_link: boxBtnLink,
    btn_title: boxBtnTitle,
    grid = 3,
    grid_mobile: gridMobile,
    title_align_text: titleAlignText,
    text_color: textColor,
    title,
    box_width: boxWidth,
    show_gap: showGap,
    pic_radius: picRadius,
    background_style: backgroundStyle = 'custom_color',
    background_image: backgroundImage,
  } = settings;
  let backgroundColor = settings.background_color;
  if (typeof backgroundColor === 'undefined') {
    backgroundColor = bgColor || 'rgba(250, 250, 250, 0)';
  }
  const Bg = Background(backgroundStyle);
  useEffect(() => {
    if (!blockOrder.length) return;
    const url = blocks[blockOrder[0]]?.settings?.image;
    getImageInfo(url).then((imageInfo) => {
      const { width, height } = imageInfo;
      const percent = (height / width) * 100;
      setFirstImageSize(`${percent}%`);
    });
  }, [blocks, blockOrder]);
  const onJump = (url, openTarget = 'self') => {
    if (window.isEdit) return;
    if (!url) return;
    window.open(url, `_${openTarget}`);
  };
  const pcGap = showGap ? 30 : 0;
  const mobileGap = showGap ? 15 : 0;
  const pcWidthItem = getFixedWidth('100%', grid, pcGap);
  const mobileWidthItem = getFixedWidth('100%', gridMobile, mobileGap);
  const firstImage =
    blocks[blockOrder[0]]?.settings?.image_size === 'with-first'
      ? firstImageSize
      : blocks[blockOrder[0]]?.settings?.image_size;
  const hasMargin = !(!title && boxWidth === 'full_screen');

  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage} style={{ overflow: 'hidden' }}>
      <Style
        className={cn('feature-columns-section', elemClass, `cn-${boxWidth}`)}
        grid={grid}
        gridMobile={gridMobile}
        pcGap={pcGap}
        mobileGap={mobileGap}
        pcWidthItem={pcWidthItem}
        mobileWidthItem={mobileWidthItem}
      >
        <Wrapper paddingTop={hasMargin ? 'vContainer' : 0} paddingBottom={hasMargin ? 'vContainer' : 0}>
          {settings.title && (
            <h3 className={cn('feature-columns-title', 'section-component-title', titleAlignText)}>{settings.title}</h3>
          )}

          <div className={cn('feature-columns-grid', classMapBySetting[alignText], `grid-${grid}`)}>
            {blockOrder.length > 0 &&
              blockOrder.map((orderId, index) => {
                const { settings: setting = {} } = blocks[orderId] || {};

                const {
                  image,
                  hover_image: hoverImage,
                  text,
                  title,
                  open_target: openTarget,
                  image_size: imageSize,
                  btn_title: buttonTitle,
                  btn_link: btnLink,
                  pic_link: picLink,
                } = setting;

                const isWithFirst = imageSize === 'with-first';
                const imagePaddingTop = isWithFirst ? firstImage : imageSize;
                const linkUrl = JSON.parse(btnLink || null)?.url;
                return (
                  <div key={orderId} className={cn('feature-columns-grid-item')}>
                    <div className='feature-columns-img-container' style={{ borderRadius: `${picRadius}%` }}>
                      {image ? (
                        <div
                          className='feature-columns-img-list'
                          style={{
                            paddingTop: index === 0 ? firstImage : imagePaddingTop,
                          }}
                        >
                          <NewLink href={picLink} target={`_${openTarget}`}>
                            <Image
                              className='feature-columns-img'
                              src={image}
                              alt={title || text}
                              style={{
                                height: isWithFirst && index === 0 ? 'auto' : '100%',
                                borderRadius: `${picRadius}%`,
                              }}
                            />
                            {hoverImage && (
                              <Image
                                className='feature-columns-img hover-image'
                                src={hoverImage}
                                alt={title || text}
                                style={{
                                  borderRadius: `${picRadius}%`,
                                }}
                              />
                            )}
                          </NewLink>
                        </div>
                      ) : (
                        <NewLink href={picLink} target={`_${openTarget}`}>
                          <DefaultImg square width='41%' height='auto' />
                        </NewLink>
                      )}

                      {buttonTitle && (
                        <p className='feature-columns-goods-btn-warp'>
                          <LinkButton
                            path={linkUrl}
                            target={`_${openTarget}`}
                            linkText={buttonTitle}
                            linkStyle='button'
                          />
                        </p>
                      )}
                    </div>
                    <div className='text-content'>
                      <NewLink href={picLink} target={`_${openTarget}`}>
                        <h3 className='feature-columns-goods-sub-title'>{isObject(title) ? '' : title}</h3>
                      </NewLink>
                      <div
                        className='feature-columns-goods-desc'
                        dangerouslySetInnerHTML={{
                          __html: text,
                        }}
                        style={{ color: textColor }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          {boxBtnTitle ? (
            <p className='feature-columns-goods-box-btn-warp'>
              <LinkButton path={JSON.parse(boxBtnLink || null)?.url} linkStyle='button' linkText={boxBtnTitle} />
            </p>
          ) : null}
        </Wrapper>
      </Style>
    </Bg>
  );
};

export default FeatureColumns;
