import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { DefaultImg } from 'cpn/default-img';
import { getDesignLocalText } from 'utils/i18n';
import { getYouTubeVideoSrc } from 'utils/image';
import NewLink from 'cpn/new-link';
import Image from 'components/base/image';
import { useMedioUrl } from 'hook/video';
// import { useThemeContext } from '../../../../../hook/global-theme';
import localApi from 'api/local';

const dealTextAlign = (str) => {
  const align = str?.split(' ');
  const [horizontal, vertical] = align;
  return `text-${horizontal}-${vertical}`;
};
export const VideoItem = ({ settings, globalSettings }) => {
  const {
    video_link: videoLinkOld,
    show_overlay: showOverlay,
    image,
    title,
    sub_title: subTitle,
    text_alignment: textAlignment,
    image_alignment: imageAlignment,
    text_size: textSize,
    video_alignment: videoAlignment,
    color_text: colorText,
    play_text_disappear: playTextDisappear,
    media_id: mediaId,
  } = settings;
  // const videoLink = useMedioUrl(mediaId).medioUrl || videoLinkOld;
  const [videoLink, setVideoLink] = useState(videoLinkOld);
  // const themeContext = useThemeContext();
  // const playWay = themeContext.isMobile ? 'manual' : settings.play_way;
  // 暂时这样，下周跟另外一个问题提起改
  const playWay = settings.play_way;
  const { mobile_text_type: mobileTextType } = globalSettings;
  const [isPlay, setPlay] = useState(false);
  const autoPlay = !!(playWay === 'auto');
  const alignClass = dealTextAlign(textAlignment);
  const alignmentClass = `img-${imageAlignment.split(' ').join('-')}`;

  const videoRef = useRef(null);
  const isYouToBe = videoLink?.indexOf('youtube') !== -1;

  // 暂时注释吧@静怡
  // useEffect(() => {
  //     const videoSrc = isYouToBe ? getYouTubeVideoSrc({ url: videoLink, autoPlay: Number(autoPlay) }) : videoLink;
  // }, ['mdia'])
  const videoSrc = isYouToBe ? getYouTubeVideoSrc({ url: videoLink, autoPlay: Number(autoPlay) }) : videoLink;
  const onPlay = () => {
    if (!videoLink) return;
    setPlay(true);
    if (!isYouToBe) {
      videoRef.current.play();
    }
  };
  const onPause = () => {
    setPlay(false);
    if (!isYouToBe) {
      videoRef.current.pause();
    }
  };
  useEffect(() => {
    if (playWay === 'auto') {
      onPlay();
    }
    // 点击按钮播放
    if (playWay !== 'auto' && isPlay) {
      onPause();
    }
  }, [settings]);

  const getMediaUrl = async (videoId) => {
    const res = await localApi.getVideoPlayInfo({ mediaId: videoId });
    setVideoLink(res?.playUrl);
  };

  useEffect(() => {
    if (mediaId) {
      getMediaUrl(mediaId);
    }
  }, [mediaId]);

  return (
    <div className={cn('slide-item', alignClass)}>
      <div
        className='slide-item-img-wrapper slide-item-img-wrapper-video'
        style={{ zIndex: image && !isPlay ? 'auto' : '-10' }}
      >
        <Image
          className={cn('slide-item-img', alignmentClass)}
          src={image}
          style={{ opacity: image && !isPlay ? 1 : 0 }}
          alt={title || subTitle}
        />
        {showOverlay && !isPlay && <div className='slide-img-mask' />}
      </div>
      <div className='video-wrapper'>
        <div style={{ height: '100%' }}>
          {videoLink && isYouToBe && (
            <iframe
              className={`video-align-${videoAlignment}`}
              width='100%'
              height='100%'
              className='video-youtube-iframe'
              src={videoSrc}
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          )}
          {videoLink && !isYouToBe && (
            <video
              width='100%'
              height='100%'
              className={`video-align-${videoAlignment}`}
              ref={videoRef}
              loop='loop'
              autoPlay={autoPlay}
              src={videoLink}
            />
            //     <source src={videoLink} type='video/mp4' />
            //     <source src={videoLink} type='video/ogg' />
            // </video>
          )}
          {!videoLink && <DefaultImg customName='slide-item-img' width='auto' height='41%' />}
        </div>
      </div>
      <div
        className={cn('slide-item-text', {
          'hidden-text': playTextDisappear && isPlay,
        })}
        style={{ color: colorText }}
      >
        <h3 className='slide-item-title'>{title}</h3>
        <div className='slide-item-desc'>
          <p
            dangerouslySetInnerHTML={{
              __html: getDesignLocalText(subTitle) || '',
            }}
          />
        </div>
        {!autoPlay && !isPlay && (
          <div className='slide-item-btn-wrapper'>
            <NewLink className='slide-item-btn-video' onClick={onPlay}>
              <svg className='default-touxiang' aria-hidden='true' height='26px' width='26px'>
                <use xlinkHref='#iconbofang' />
              </svg>
            </NewLink>
          </div>
        )}
      </div>
    </div>
  );
};
