import styled from 'styled-components';

export const GroupBuyingEntryStyle = styled.div`
  margin-top: ${(props) => props.theme.getSpace([16, 24])};
  padding: ${(props) => props.theme.getSpace([10, 16])} 0;
  border-top: 1px solid ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.08)};
  border-bottom: 1px solid ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.08)};
  .group-buy-title {
    ${(props) => props.theme.getBaseSize([14, 16])};
  }
`;
export const GroupBuyingItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0;
  margin-top: ${(props) => props.theme.getSpace([12, 16])};
  cursor: pointer;
  .left {
    display: flex;
    align-items: center;
    .profile-box-wrapper {
      margin-right: 8px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .group-desc {
      display: flex;
      flex-direction: column;
      text-align: right;
      /* background: var(--color_button) !important; */
      .price-sale,
      .price-symbol,
      .price-integer {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
      }
      .group-buying-text {
        ${(props) => props.theme.getBaseSize(props.theme.h12)};
        color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)};
      }
      margin-right: 8px;
    }
  }

  .group-count {
    ${(props) => props.theme.getBaseSize(props.theme.h10)};
    color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 1)};
  }
  .group-time {
    display: flex;
    ${(props) => props.theme.getBaseSize([10, 12])};
    margin-top: 4px;
    line-height: 18px;
    color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)};
    .end-in-text {
      margin-right: 6px;
      ${(props) => props.theme.getBaseSize(props.theme.h12)};
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)} !important;
    }
    .time-item {
      ${(props) => props.theme.getBaseSize(props.theme.h12)};
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)} !important;
    }
    .time-value {
      display: inline !important;
    }
  }
  .default-profile {
    width: ${(props) => props.theme.getSpace([36, 44])};
    height: ${(props) => props.theme.getSpace([36, 44])};
    background: var(--color_sale_text);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
`;
