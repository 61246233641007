import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from './load-third-javascript';
import userApi from '../../api/user';
import { LOGIN_CHANNELS } from '../../const';
import { Notify } from 'zent';

export const selfInitGoogleLogin = (id, intl, onLoginSuccess) => {
  if (!id) return;
  // const attachSignin = (element, auth) => {
  //     auth.attachClickHandler(
  //         element,
  //         {},
  //         function (googleUser) {
  //             const { id_token } = googleUser.getAuthResponse();
  //             console.log('???googleUser', googleUser, id_token);
  //             userApi.googleLogin({ channelCode: LOGIN_CHANNELS.google, channelToken: id_token }).then(() => {
  //                 Notify.success(
  //                     intl.formatMessage({
  //                         id: 'account.login_success',
  //                         defaultMessage: '登录成功',
  //                     }),
  //                 );
  //                 // onLoginSuccess();
  //             });
  //         },
  //         function (error) {
  //             // const errorMsg = JSON.stringify(error, undefined, 2);
  //             console.log('???error', error);
  //             Notify.error(
  //                 intl.formatMessage({
  //                     id: '372c7b3c66e149e18d2ed06813f56d6e',
  //                     defaultMessage: '登录失败，设置-清除浏览数据后重试',
  //                 }),
  //             );
  //         },
  //     );
  // };

  const startApp = function () {
    try {
      window?.google.accounts.id.initialize({
        client_id: id,
        cookiepolicy: 'single_host_origin',
        callback: (googleUser) => {
          const { credential } = googleUser;
          if (credential) {
            userApi.googleLogin({ channelCode: LOGIN_CHANNELS.google, channelToken: credential }).then(() => {
              Notify.success(
                intl.formatMessage({
                  id: 'account.login_success',
                  defaultMessage: '登录成功',
                }),
              );
              onLoginSuccess();
            });
          } else {
            console.log(googleUser);
            Notify.error(
              intl.formatMessage({
                id: '372c7b3c66e149e18d2ed06813f56d6e',
                defaultMessage: '登录失败，设置-清除浏览数据后重试',
              }),
            );
          }
        },
      });
      window?.google.accounts.id.renderButton(document.getElementById('customBtn'), {
        type: 'icon',
        theme: 'filled_blue',
        size: 'medium',
        shape: 'circle',
        click_listener: () => {
          // 监听点击唤起登陆按钮
        },
      });
    } catch (err) {
      console.log(err);
      Notify.error(
        intl.formatMessage({
          id: '372c7b3c66e149e18d2ed06813f56d6e',
          defaultMessage: '登录失败，设置-清除浏览数据后重试',
        }),
      );
    }

    // 旧的google登录
    // gapi.load('auth2', function () {
    //     // Retrieve the singleton for the GoogleAuth library and set up the client.
    //     const auth2 = gapi.auth2.init({
    //         client_id: id,
    //         cookiepolicy: 'single_host_origin',
    //         // Request scopes in addition to 'profile' and 'email'
    //         // scope: 'additional_scope'
    //     });
    //     attachSignin(document.getElementById('customBtn'), auth2);
    // });
  };

  loadThirdJavascript(THIRD_JAVASCRIPT_CONFIG.GOOGLE_LOGIN.name, `${THIRD_JAVASCRIPT_CONFIG.GOOGLE_LOGIN.url}`).then(
    () => {
      startApp();
    },
  );
};
