import React from 'react';
import { Form, FormControl } from 'zent';

const EmptyFormFileds = (props) => {
  const { name = '', defaultValue = '', validators = [] } = props;
  const model = Form.useField(name, defaultValue, [...(validators || [])]);
  model.destroyOnUnmount = true;

  return <FormControl />;
};

export default EmptyFormFileds;
