import React, { useState, useEffect } from 'react';
import { intlMessage as $t } from 'utils/intl/formate';
import { getQueryString } from 'utils';
import { Rate, FormStrategy, Dialog, Button, Form, Validators, FormField, Input, Pop } from 'zent';
import { Poster, Success, DialogStyle, Confirm } from './style';
import ImageUpload from 'cpn/image-upload';
import pick from 'lodash/pick';
import goodsApi from 'api/goods';
import isEmpty from 'lodash/isEmpty';
import { RESERVE_ENTRY_ENUM } from 'const';
import Image from 'components/base/image';

const { openDialog, closeDialog } = Dialog;

const REVIEW_SUCCESS_DIALOG = 'REVIEW_SUCCESS_DIALOG';
const REVIEW_CONFIRM_DIALOG = 'REVIEW_CONFIRM_DIALOG';

export const ReviewSuccess = ({ intl }) => {
  return (
    <Success className='review-success-box'>
      <Image
        className='review-success-img'
        src='https://su.yzcdn.cn/public_files/7f39579037fc90cbdb6c67c56c78f298.png'
        alt='review product success'
      />
      <p className='review-success-text'>
        <p className='success-text-title'>{$t(intl, 'review.success.thank')}</p>
        <p className='success-text-desc'>{$t(intl, 'review.success.text')}</p>
      </p>
      <DialogStyle />
    </Success>
  );
};

export const ConfirmBox = ({ intl, onOk, onCancel }) => {
  return (
    <Confirm className='review-confirm-box'>
      <h2 className='review-confirm-text'>{$t(intl, 'review.cancel.edit')}</h2>
      <div className='review-confirm-btn'>
        <Button onClick={onCancel}>{$t(intl, 'general.cancel')}</Button>
        <Button type='primary' onClick={onOk}>
          {$t(intl, 'review.cancel.btn')}
        </Button>
      </div>
    </Confirm>
  );
};

const ReviewPoster = (props) => {
  const { onClose, userInfo, intl, onSuccess, goodsDetail } = props;
  const { handle, title } = goodsDetail || {};
  const [showPopover, setShowPopover] = useState(false);
  const [rate, setRate] = useState(5);
  const [images, setImages] = useState([]);
  const form = Form.useForm(FormStrategy.View);

  const getAccountInfo = () => {
    if (isEmpty(userInfo)) {
      return {};
    }

    return {
      email: userInfo.customerNo,
      ...pick(userInfo, ['firstName', 'lastName']),
    };
  };

  const onCancelPosterClose = () => {
    closeDialog(REVIEW_CONFIRM_DIALOG);
    setShowPopover(false);
  };

  const onConfirmPosterClose = () => {
    closeDialog(REVIEW_CONFIRM_DIALOG);
    setShowPopover(false);
    if (onClose) {
      onClose();
    }
  };

  const openConfirmDialog = () => {
    openDialog({
      className: 'review-confirm-dialog',
      dialogId: REVIEW_CONFIRM_DIALOG,
      closeBtn: false,
      style: {
        width: '640px',
        maxWidth: '87%',
      },
      children: <ConfirmBox onCancel={onCancelPosterClose} onOk={onConfirmPosterClose} intl={intl} />,
    });
  };

  const openSuccessDialog = () => {
    openDialog({
      className: 'review-success-dialog',
      dialogId: REVIEW_SUCCESS_DIALOG,
      style: {
        width: '640px',
        maxWidth: '87%',
      },
      children: <ReviewSuccess intl={intl} />,
      onClose() {
        onSuccess();
        closeDialog(REVIEW_SUCCESS_DIALOG);
      },
    });

    setTimeout(() => {
      onSuccess();
      closeDialog(REVIEW_SUCCESS_DIALOG);
    }, 5000);
  };

  const onRateChange = (val) => {
    setRate(val);
  };

  const onSubmit = (formArea) => {
    const accountInfo = getAccountInfo();
    const value = formArea.getValue();
    const { handle, kdtId, title, goodsId } = props.goodsDetail || {};
    const { reviewsFrom = RESERVE_ENTRY_ENUM.GOODS_DETAIL, orderToken } = getQueryString(window.location.search);

    let params = {
      ...accountInfo,
      ...value,
      goodsId,
      kdtId,
      goodsTitle: title,
      goodsHandle: handle,
      reviewsFrom: Number(reviewsFrom),
      score: rate,
      images,
    };

    if (orderToken) {
      params = Object.assign(params, { orderToken });
    }

    goodsApi.publishReviews(params).then(() => {
      props.onClose();
      setTimeout(openSuccessDialog, 300);
    });
  };

  useEffect(() => {
    const accountInfo = getAccountInfo();
    form.initialize(accountInfo);
  }, [userInfo]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Poster className='review-poster-box'>
        <h1 className='review-poster-header'>{$t(intl, 'review.add.review')}</h1>
        <div className='review-score-content'>
          <h2 className='review-box-title'>{$t(intl, 'review.score.value')}</h2>
          <Rate allowClear={false} value={rate} onChange={onRateChange} className='review-score-rate' />
          <svg className='close-review-icon close-mobile-icon' onClick={openConfirmDialog} aria-hidden='true'>
            <use xlinkHref='#iconic-shanchu' />
          </svg>

          <Pop
            trigger='click'
            position='bottom-right'
            visible={showPopover}
            onVisibleChange={(visible) => {
              setShowPopover(visible);
            }}
            content={<ConfirmBox onCancel={onCancelPosterClose} onOk={onConfirmPosterClose} intl={intl} />}
          >
            <svg className='close-review-icon close-pc-icon' aria-hidden='true'>
              <use xlinkHref='#iconic-shanchu' />
            </svg>
          </Pop>
        </div>

        <div className='review-text-content'>
          <h2 className='review-box-title review-text-title'>{$t(intl, 'reviews')}</h2>
          <FormField
            name='content'
            normalize={(evt) => {
              return evt?.target?.value;
            }}
            // eslint-disable-next-line react/no-children-prop
            children={(childProps) => {
              return <Input {...childProps} type='textarea' placeholder={$t(intl, 'customers.form.content')} />;
            }}
            validators={[Validators.required($t(intl, 'reviews.empty.validator'))]}
          />
        </div>
        <ImageUpload
          className='review-image-upload'
          maxAmount={6}
          multiple
          sortable
          intl={intl}
          handle={handle || title}
          onChange={(imgs) => {
            setImages(imgs);
          }}
        />
        {isEmpty(userInfo) ? (
          <div className='review-person-info'>
            <h2 className='review-box-title'>{$t(intl, 'reviews.about.reviewer')}</h2>
            <div className='review-name-box'>
              <FormField
                name='firstName'
                normalize={(evt) => {
                  return evt?.target?.value;
                }}
                // eslint-disable-next-line react/no-children-prop
                children={(childProps) => {
                  return <Input {...childProps} placeholder={`${$t(intl, 'customers.form.first_name')}*`} />;
                }}
                validators={[Validators.required($t(intl, 'reviews.about.reviewer.name'))]}
              />
              <FormField
                name='lastName'
                normalize={(evt) => {
                  return evt?.target?.value;
                }}
                // eslint-disable-next-line react/no-children-prop
                children={(childProps) => {
                  return <Input {...childProps} placeholder={`${$t(intl, 'account.form.last_name')}*`} />;
                }}
                validators={[Validators.required($t(intl, 'account.form.please_enter_first_name'))]}
              />
            </div>
            <FormField
              name='email'
              normalize={(evt) => {
                return evt?.target?.value;
              }}
              // eslint-disable-next-line react/no-children-prop
              children={(childProps) => {
                return <Input {...childProps} placeholder={`${$t(intl, 'email')}*`} />;
              }}
              validators={[
                Validators.required($t(intl, 'pleaseInputEmail')),
                Validators.email($t(intl, 'pleaseInputEmailByValid')),
              ]}
            />
          </div>
        ) : null}
        <Button htmlType='submit' type='primary' className='review-submit-btn'>
          {$t(intl, 'reviews.submit.review')}
        </Button>
      </Poster>
    </Form>
  );
};

export default ReviewPoster;
