import HtFormInput from '../../../../standard/form-input';
import HtFormInputPassword from '../../../../standard/form-input-password';
import { Form, FormStrategy, Validators } from 'zent';
import HtButton from '../../../../standard/button';
import { OperateButtons, ForgotPassword } from './style';
import UserAccount from 'client/controllers/user-account';
import ThirdLogin from '../third-login';
import useThirdLogin from '../../../../../hook/login/use-third-login';

function LoginWithPassword(props) {
  const { onClose, onRegister, onForgotPassword, intl, withMobileLogin, language, onLoginSuccess } = props;
  const form = Form.useForm(FormStrategy.View);

  const { loginChannels, onLineLogin, onFbLogin, onProviderLogin } = useThirdLogin({
    onLoginSuccess,
    language,
    intl,
  });
  const onSubmit = () => {
    const formData = form.getValue();
    const { accountNo, password } = formData;
    UserAccount.loginByPwd({
      accountNo,
      password,
    })
      .then(() => {
        onLoginSuccess();
      })
      .catch((err) => {
        console.log('handleSave err', err);
      });
  };
  return (
    <Form layout='vertical' form={form} onSubmit={onSubmit}>
      <HtFormInput
        name='accountNo'
        width='100%'
        placeholder={
          withMobileLogin
            ? intl.formatMessage({
                id: '5dcb5505453443bf96d6d02232cdc27c',
                defaultMessage: '手机号或邮箱',
              })
            : intl.formatMessage({
                id: 'common_mailbox',
                defaultMessage: '邮箱',
              })
        }
        validators={[
          Validators.required(
            withMobileLogin
              ? intl.formatMessage({
                  id: 'fc4416e158204e87a566bbec39716fe9',
                  defaultMessage: '请输入手机号或邮箱',
                })
              : intl.formatMessage({
                  id: 'please_input_your_email',
                  defaultMessage: '请输入邮箱',
                }),
          ),
        ]}
      />
      <HtFormInputPassword
        name='password'
        width='100%'
        placeholder={intl.formatMessage({
          id: 'account.form.password',
          defaultMessage: '密码',
        })}
        validators={[
          Validators.required(
            intl.formatMessage({
              id: 'pleaseInputPassword',
              defaultMessage: '请输入密码',
            }),
          ),
        ]}
      />
      <ForgotPassword
        onClick={() => {
          onClose();
          onForgotPassword();
        }}
      >
        {intl.formatMessage({
          id: '5139553bb68a4f8d805405d5189fa25c',
          defaultMessage: '忘记密码？',
        })}
      </ForgotPassword>
      <OperateButtons>
        <HtButton full size='large' htmlType='submit'>
          {intl.formatMessage({
            id: 'account.login',
            defaultMessage: '登录',
          })}
        </HtButton>
        <HtButton
          full
          plain
          onClick={() => {
            if (onClose) {
              onClose();
            }
            onRegister();
          }}
        >
          {intl.formatMessage({
            id: 'account_register',
            defaultMessage: '注册',
          })}
        </HtButton>
      </OperateButtons>
      <ThirdLogin
        loginChannels={loginChannels}
        intl={intl}
        onLineLogin={onLineLogin}
        onFbLogin={onFbLogin}
        onProviderLogin={onProviderLogin}
      />
    </Form>
  );
}

export default LoginWithPassword;
