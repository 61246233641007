import styled from 'styled-components';

export default styled.div`
  .menu-container {
    padding: 0 18px;
    text-align: left;
    background-color: ${(props) => props.subMenuBgColor};
    color: ${(props) => props.subMenuTextColor};
    border-top: 0.5px solid rgba(38, 38, 38, 0.08);
    .sub-menu-container {
      margin-left: 24px;
    }
    .sub-menu-container {
      transition: height 200ms ease 0s;
      overflow: hidden;
      height: 0;
    }
    .first-menu-title {
      position: relative;
      width: 100%;
      .first-menu-title-text {
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        cursor: pointer;
      }
      .header-badge {
        position: absolute;
        top: -10px;
        max-width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        box-sizing: border-box;
      }
    }
    .sub-menu {
      .sub-menu-title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .sub-menu-title {
          position: relative;
          width: calc(100% - 28px);
        }
        .sub-menu-title-text {
          max-width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          cursor: pointer;
        }
        .header-badge {
          position: absolute;
          top: -10px;
          max-width: 30%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          pointer-events: none;
        }
        .sub-menu-icon {
          flex: 1;
          text-align: right;
        }
        .right-icon {
          transition: transform 200ms ease;
          fill: ${(props) => props.subMenuTextColor};
        }
      }
    }
    .menu-image {
      height: auto !important;
      line-height: 1 !important;
    }
    .menu-image-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 16px 8px;
      padding-right: 16px;
      padding-bottom: 16px;
      flex-wrap: wrap;
    }
    .menu-image-item {
      width: calc((100% - 8px) / 2);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .menu-image-item-image {
      width: 100%;
      background-color: rgba(220, 222, 224, 0.2);
      text-align: center;
      .default-icon {
        fill: #646566;
        opacity: 0.15;
        height: 100px;
      }
    }
    .menu-image-item-text {
      color: ${(props) => props.theme.hexToRGBA(props.subMenuTextColor, 0.6)};
      display: inline-block;
      word-break: break-word;
      border-bottom: 1px solid ${(props) => props.theme.hexToRGBA(props.subMenuTextColor, 0.6)};
      a {
        color: ${(props) => props.theme.hexToRGBA(props.subMenuTextColor, 0.6)} !important;
      }
    }
    .menu-level-one {
      font-size: ${(props) => props.theme.getBaseFontSize(props.theme[`h${props.levelOneMenuTextSize}`])};
      color: ${(props) => props.subMenuTextColor};
      padding: 16px 0;
      border-bottom: 0.5px solid rgba(38, 38, 38, 0.08);
      &.first-menu-title {
        margin-left: 0;
      }
    }
    .menu-level-two {
      font-size: ${(props) => props.theme.getBaseFontSize(props.theme[`h${props.levelTwoMenuTextSize}`])};
      color: ${(props) => props.theme.hexToRGBA(props.subMenuTextColor, 0.8)};
      margin-top: 16px;
      .sub-menu-title-text,
      .first-menu-title-text {
        font-weight: 400;
      }
    }
    .menu-level-three {
      font-size: ${(props) => props.theme.getBaseFontSize(props.theme[`h${props.levelThreeMenuTextSize}`])};
      color: ${(props) => props.theme.hexToRGBA(props.subMenuTextColor, 0.6)};
      margin-top: 16px;
      .sub-menu-title-text,
      .first-menu-title-text {
        font-weight: 400;
      }
    }
  }
  .blank-menu-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 100%;
    transition: all 0.2s ease-in-out;
    background-color: ${(props) => props.subMenuBgColor};
    .back-icon {
      fill: ${(props) => props.subMenuTextColor};
    }
    .child-menu-container {
      width: 100%;
      height: calc(100% - 24px);
      overflow-x: hidden;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      .child-menu {
        max-height: 100%;
      }
    }
  }
  .home-page-header-slide {
    .slide-left-scroll-body {
      display: flex;
      flex-direction: column;
    }
    .menu-container {
      flex: 1;
      overflow: scroll;
    }
  }
`;
