import styled from 'styled-components';

export const Indicator = styled.div`
  position: absolute;
  line-height: 1;
  bottom: ${(props) => props.theme.getSpace(props.theme.pCards)};
  z-index: 10;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  &.indicator-left {
    left: ${(props) => props.theme.getSpace(props.theme.hContainer)};
  }
  &.indicator-center {
    transform: translateX(-50%);
    left: 50%;
  }
  &.indicator-right {
    right: ${(props) => props.theme.getSpace(props.theme.hContainer)};
  }
  &.dots {
    .indicator-item {
      width: ${(props) => props.theme.getSpace([6, 8])};
      height: ${(props) => props.theme.getSpace([6, 8])};
      border-radius: 50%;
      background-color: #d1d5db;
      &.active {
        background-color: var(--color_button);
      }
    }
    .indicator-item + .indicator-item {
      margin-left: ${(props) => props.theme.getSpace([6, 8])};
    }
  }
  &.line {
    height: ${(props) => props.theme.getSpace([2, 4])};
    background-color: ${(props) => {
      if (props.color) return props.theme.hexToRGBA(props.color, 0.3);
      if (props.theme.colorButtons) return props.theme.colorButtons['30'];
    }};
    border-radius: ${(props) => props.theme.getSpace([1, 2])};
    width: ${(props) => `${props.total * 16}px`};
    .line-process-bar {
      transition: all 0.3s;
      position: absolute;
      left: ${(props) => `${props.currentIndex * 16}px`};
      top: 0;
      height: 100%;
      width: 16px;
      border-radius: ${(props) => props.theme.getSpace([1, 2])};
      background-color: ${(props) => props.color || props.theme.colorButton};
    }
  }
  &.digital {
    padding: ${(props) => props.theme.getSpace([1, 1, 2])} ${(props) => props.theme.getSpace([8])};
    border-radius: ${(props) => props.theme.getSpace([11, 11, 12])};
    background-color: ${(props) => (props.color ? props.color : 'rgba(0, 0, 0, 0.16)')};
    color: #fff;
    .indicator-text {
      &.active {
        color: #fff;
      }
    }
  }
`;
