import styled, { css } from 'styled-components';

const getMobileCSS = (props) => {
  if (props?.boxWidth === 'full_screen') {
    return css`
      padding-left: 0;
      padding-right: 0;
    `;
  }
  return css``;
};

export const Style = styled.div`
  .featured-grid--title {
    text-align: center;
    line-height: 32px;
    font-size: var(--base-header-font-26-20);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    margin-bottom: 30px;
    font-weight: 500;
    color: var(--color_text);
  }
  .feature-collection-section {
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.grid}, minmax(auto, 1fr))`};
    grid-column-gap: ${(props) => props.theme.getSpace(props.theme[`g${props.gap}`])};
    grid-row-gap: ${(props) => props.theme.getSpace(props.theme[`g${props.gap}`])};
    /* grid-template-rows: 100px 100px 100px; */
  }

  .item-1 {
    background-color: #ef342a;
  }

  .item-2 {
    background-color: #f68f26;
    grid-row: span 2;
    grid-column: span 2;
  }

  .item-3 {
    background-color: #4ba946;
  }

  .featured-grid--item {
    .featured-grid--image {
      transition: all 1.2s;
    }

    &.hover-scale-big {
      .featured-grid--image {
        transform: scale(1);
      }
      &:hover {
        .featured-grid--image {
          transform: scale(1.2);
        }
      }
    }
    &.hover-scale-small {
      .featured-grid--image {
        transform: scale(1.2);
      }
      &:hover {
        .featured-grid--image {
          transform: scale(1);
        }
      }
    }

    overflow: hidden;
    &.goods-item-row {
      grid-row: span 2;
    }
    &.goods-item-column {
      grid-column: span 2;
    }
  }
  .featured-grid--item--image {
    width: 100%;
    height: 100%;
    .featured-grid--image {
      &.remain-pro {
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
        width: 100%;
        /* width: 100%;
                height: 100%; */
      }

      &.fit-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .featured-grid--item--overlay-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .featured-grid--item--container {
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
  }
  .featured-grid--item--text-container {
    max-width: calc(100% - 2 * ${(props) => props.theme.getSpace(props.theme.pCards)}) !important;
    overflow: hidden;
    &.show_text_card {
      background: #fff;
      padding: var(--pm-24-16);
      box-sizing: border-box;
    }
    .featured-container--title,
    .featured-container--sub-title {
      margin-bottom: var(--pm-16-8);
    }
    .featured-container--title {
      font-size: var(--base-header-font-20-16) !important;
      font-family: var(--font-stack-header) !important;
      font-style: var(--font-style-header) !important;
      font-weight: var(--font-weight-header) !important;
      line-height: var(--base-header-font-lh-20-16) !important;
    }
    .featured-container--sub-title {
      font-size: var(--base-font-14-12) !important;
      font-family: var(--font-stack-body) !important;
      font-style: var(--font-style-body) !important;
      font-weight: var(--font-weight-body) !important;
      line-height: var(--base-font-lh-14-12) !important;
    }
  }
  .default-img-box {
    background: #fff !important;
    border: 0 !important;
  }

  @media screen and (max-width: 750px) {
    padding-left: 16px;
    padding-right: 16px;
    ${(props) => getMobileCSS(props)}
    .feature-collection-section {
      display: grid;
      grid-template-columns: ${(props) => `repeat(${props.gridMobile}, minmax(auto, 1fr))`};
    }
  }
`;
