import React, { useState, useCallback, useEffect } from 'react';
import NewTab from 'components/base/new-tabs';
import { GoodsDesc } from '../goods-desc';
import Reviews from '../reviews/review-list';
import { intlMessage } from 'utils/intl/formate';
import { useIntl } from 'react-intl';
import { GoodsMetafiled } from '../../goods-metafield';

const { TabPanel } = NewTab;
enum DetailAndCommentsShowStyle {
  Tabs = 'left_right_switch',
  UpDown = 'up_down',
}

enum TabList {
  Detail = 'good-detail-section',
  Review = 'good-detail-comments-section',
}

export const DetailContainer = ({
  detailConfigInfo = {},
  layoutStyle = {},
  LayoutType,
  goodsDetail,
  isMobile,
  kdtId,
  userInfo,
  reviewHook = {},
}) => {
  const intl = useIntl();
  const { descriptionHtml, options = [] } = goodsDetail;
  const [activeId, setActiveId] = useState(TabList.Detail);
  const onTabChange = useCallback((id) => {
    setActiveId(id);
  }, []);

  const titleConfig = {
    hideTitle: detailConfigInfo?.show_style === DetailAndCommentsShowStyle.Tabs,
    textAlign: layoutStyle === LayoutType.UpDown || isMobile ? 'center' : 'left',
    noLine: layoutStyle !== LayoutType.UpDown && !isMobile,
  };

  // /** 评价 */
  const { reviewOverview, reviewTotalCount, setReviewTotalCount, fetchGoodReviewOverview, showReview } = reviewHook;

  const reviewProps = {
    titleConfig,
    goodsDetail,
    userInfo,
    reviewOverview,
    reviewTotalCount,
    setReviewTotalCount,
    fetchGoodReviewOverview,
  };

  const reviewCount = goodsDetail?.goodsReviewsInfo?.reviewsCount || 0;

  return (
    <div className='detail-coments'>
      {/* 以tab形式展示商品详情和评价 */}
      {detailConfigInfo?.show_style === DetailAndCommentsShowStyle.Tabs ? (
        <NewTab activeId={activeId} onChange={onTabChange} type='normal'>
          <TabPanel key={TabList.Detail} tab={<span>{intlMessage(intl, 'product_detail')}</span>} id={TabList.Detail}>
            <GoodsDesc descriptionHtml={descriptionHtml} titleConfig={titleConfig} />
          </TabPanel>
          {!!showReview && (
            <TabPanel
              key={TabList.Review}
              tab={`${intlMessage(intl, 'product_comments')}(${reviewTotalCount || reviewCount})`}
              id={TabList.Review}
            >
              <Reviews {...reviewProps} />
            </TabPanel>
          )}
        </NewTab>
      ) : (
        <div>
          {/* 评价信息 */}
          {showReview ? (
            <div id={TabList.Review}>
              <Reviews {...reviewProps} />
            </div>
          ) : null}
          {/* 商品详细 */}
          {descriptionHtml && (
            <div id={TabList.Detail}>
              <GoodsDesc descriptionHtml={descriptionHtml} titleConfig={titleConfig} />
            </div>
          )}
        </div>
      )}
      <GoodsMetafiled goodsMetafields={goodsDetail.goodsMetafields} />
    </div>
  );
};
