import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    .layout-wrapper-section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
`;

export default styled.div`
  .not-find-page {
    text-align: center;
    padding: 198px 20px;
  }

  .not-find-icon {
    fill: rgba(var(--color_body_text_rgb), 0.4);
  }
  .not-find-title {
    font-size: 14px;
    line-height: 20px;
    /* 文本/正文/color_body_text 40% */
    color: rgba(var(--color_body_text_rgb), 0.4);
    margin-top: 26px;
  }
  .not-find-desc {
    font-size: 14px;
    line-height: 20px;
    /* 文本/正文/color_body_text 40% */
    color: rgba(var(--color_body_text_rgb), 0.4);
    margin-bottom: 24px;
  }
  .not-find-action {
    color: var(--color_button);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    svg {
      margin-left: 4px;
      fill: var(--color_button);
      transition: all 0.3s;
    }
    &:hover {
      svg {
        transform: translateX(3px);
      }
    }
  }
  @media screen and (min-width: 768px) {
    .not-find-page {
      text-align: center;
      padding: 80px 20px;
    }
  }
`;
