/* eslint-disable no-plusplus */
import { urlAdaptor, pathRuleAdapter } from './browser/cdn';

/**
 * 补全图片 url
 * @param  {string} url 欲补全的图片地址
 * @param {string} rule 规则，支持4个枚举值 small|middle|large|origin
 * @desc https://doc.qima-inc.com/pages/viewpage.action?pageId=178489375
 *
 * ### rule新（推荐）>= 2.2.5
 * - 支持4个枚举值 small|middle|large|origin
 * - 具体可以参考这个文档 https://doc.qima-inc.com/pages/viewpage.action?pageId=178489375
 *
 * ### rule老（兼容）< 2.2.5
 * - !80x80 图片尺寸
 * - .q40 图片质量，默认75
 * - .+2x 图片是否放大2倍，适合retina屏幕，默认不放大
 * - .jpg 图片类型
 * @param {object} options 指定的url集合，默认使用window._global.url.imgqn，如果_global没有这个对象，可以自己指定一个;
 *   options.isGifOptimation: 是否需要加上gif优化参数，默认不加
 * @return {string}     补全后的图片地址
 * @example
 * const url = 'https://img.yzcdn.cn/upload_files/2017/06/27/Fhgkb5fVQ5dM9DjS70lt-Ie5hnGb.jpg';
 * fullfillImage(url, '!50x50.jpg');
 * // => https://img.yzcdn.cn/upload_files/2017/06/27/Fhgkb5fVQ5dM9DjS70lt-Ie5hnGb.jpg?imageView2/2/w/50/h/50/q/75/format/webp
 * // 指定options
 * fullfillImage(url, '!50x50.jpg', { imgcdn: "https://img.yzcdn.cn" });
 */
export const fullfillImage = (url, rule, options = { toWebp: true, compress: true }) => {
  if (!url) return;
  if (url.match(/^data:/i)) return url;

  // 非有赞七牛路径不处理
  const yzcdnReg = /^(https?:)?\/\/([A-Za-z0-9-]+)?.yzcdn.cn/;
  if (!yzcdnReg.test(url)) {
    return url;
  }

  if (url.startsWith('https://intl-image.yzcdn.cn')) {
    return url;
  }
  const imgcdn = options.imgcdn || options.imgqn || 'https://img.yzcdn.cn';

  // 使用过的域
  const DOMAIN_REG_ARRAY = [
    /^(https?:)?\/\/imgqn.koudaitong.com/,
    /^(https?:)?\/\/kdt-img.koudaitong.com/,
    /^(https?:)?\/\/img.yzcdn.cn/,
    /^(https?:)?\/\/dn-kdt-img.qbox.me/,
  ];

  // console.log('cdn1.ruleAdapter', ruleAdapter, urlAdaptor);
  // 把url 的参数获取到
  let queryStr = '';
  if (url.indexOf('?') !== -1) {
    const urlArray = url?.split('?');
    url = urlArray?.[0];
    queryStr = urlArray?.[1];
  }
  // gif图片判断
  const isGif = new RegExp('.*.gif').test(url);

  // origin, middle, large, small的转化
  rule = pathRuleAdapter(url, rule, { ...options, isGif });

  for (let i = 0; i < DOMAIN_REG_ARRAY.length; i++) {
    // 并不是所有的域都支持HTTPS，所以把这些域替换成支持HTTPS的
    url = url.replace(DOMAIN_REG_ARRAY[i], imgcdn);
  }

  // 支持把 img01 换成 https 的
  url = url.replace(/^http:\/\/img(\d+).yzcdn.cn/, 'https://img$1.yzcdn.cn/');

  if (!url.match(/^(https?:)?\/\//i)) {
    url = `${imgcdn}/${url}${rule}`;
  } else if (
    url.match(imgcdn) ||
    url.match(/img(\d*)\.yzcdn\.cn/) ||
    url.match('dn-kdt-img-test.qbox.me') ||
    url.match('i18n-img.yzcdn.cn')
  ) {
    if (!url.match('!')) {
      url = `${url}?${rule}`;
    }
  } else {
    return url;
  }
  // toWebp为true（默认）的时候，就会走一层 '转化成七牛的格式' 的逻辑
  if (options.toWebp !== false) {
    url = urlAdaptor(url, options);
  }
  return `${url}&${queryStr}`;
};

export default fullfillImage;
