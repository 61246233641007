import React from 'react';
import { globalFormatMessage } from 'utils/lang';
import { Button } from 'zent';
import { Content, Footer, GlobalStyle } from './style';

export const QuitTipContent = () => {
  return (
    <Content>
      <div>
        {globalFormatMessage({
          id: '5dff6628e6734c008b926a90b201e6d6',
          defaultMessage: '申请成为分销员，需完成资料补充。',
        })}
      </div>
    </Content>
  );
};

export const QuitTipFooter = ({ closeDialog, continueEdit }) => {
  return (
    <>
      <GlobalStyle />
      <Footer>
        <Button onClick={closeDialog}>
          {globalFormatMessage({
            id: 'c3992269b4bced00356095dc9c5be6d2',
            defaultMessage: '退出',
          })}
        </Button>
        <Button type='primary' onClick={continueEdit}>
          {globalFormatMessage({
            id: 'a22b85b8024c414a87adbb643bf663a9',
            defaultMessage: '继续填写',
          })}
        </Button>
      </Footer>
    </>
  );
};
