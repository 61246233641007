import { css } from 'styled-components';
import isArray from 'lodash/isArray';
/** 传入水平间距计算水平方向百分比, PC标准宽度1440，H5标准宽度，用法：请全局搜索🔍 */
export const getPercentWidth = (size, base = 1440) => {
  return `${(1 - (size * 2) / base) * 100}%`;
};

/** 传入水平间距计算水平方向百分比 */
export const getSizeVw = (size, base = 1440) => {
  return `${(size * 100) / base}vw`;
};

/**
 * 计算指定宽度
 *  width 宽度
 *  num 元素个数
 *  padding 元素间距
 * */

export const getFixedWidth = (width, num, padding) => {
  // const a = (width - (num - 1) * padding);
  return `calc((${width} - ${(num - 1) * padding}px) / ${num})`;
};

// 定义断点
export const BREAK_POINTS = [768, 960];

function getBreackPointIndex(val, breackPoints) {
  let i = 0;
  for (; i < breackPoints.length; i++) {
    if (val < breackPoints[i]) {
      break;
    }
  }
  return i;
}

// 根据当前屏幕大小判断属于哪个区间
function getScreenType(clientWidth) {
  return getBreackPointIndex(clientWidth, BREAK_POINTS);
}

function getValueByIdx(idx, arr) {
  const len = arr.length;
  return idx > len - 1 ? arr[len - 1] : arr[idx];
}
// 定义隐射关系
const STANDARD_FONT_SIZE_MAP = {
  12: [12, 14],
  14: [14, 16],
  16: [16, 22],
  18: [18, 24],
  20: [20, 26],
  24: [24, 32],
  28: [28, 36],
  34: [34, 64],
  36: [14, 16],
};

const STANDARD_SPACE_MAP = {
  4: [4, 10],
  6: [6, 12],
  8: [8, 12],
  10: [10, 16],
  16: [16, 32],
  18: [18, 24],
  24: [24, 30],
  25: [25, 50],
  40: [40, 60],
};
// 定义转换函数

function getResponsiveSize(size, map, screenType) {
  // 如果传入的是一个数组，那么按照传入的值计算
  if (isArray(size)) {
    return getValueByIdx(screenType, size);
  }
  // 如果传入是是一个数值
  // 如果这个数值在标准隐射表里面有，那么返回隐射表里面的值
  // 否则，就直接返回
  if (map[size]) {
    return getValueByIdx(screenType, map[size]);
  }
  return size;
}

const LINE_HEIGHT_BREAK_POINT = [8, 12, 20, 30, 40, 50];
const LINE_HEIGHT_GROW = [4, 4, 6, 8, 10, 12, 12, 12];

export const getFontSize = (clientWidth, size) => {
  const screenType = getScreenType(clientWidth);
  return getResponsiveSize(size, STANDARD_FONT_SIZE_MAP, screenType);
};

export const getLineHeight = (size) => {
  // if（8px <= font-size < 12px）{ line-height = font-size + 4px }
  // if（12px <= font-size < 20px）{ line-height = font-size + 6px }
  // if（20px <= font-size < 30px）{ line-height = font-size + 8px }
  // if（30px <= font-size < 40px）{ line-height = font-size + 10px }
  // if（40px <= font-size < 50px）{ line-height = font-size + 12px }
  const idx = getBreackPointIndex(size, LINE_HEIGHT_BREAK_POINT);
  const grow = LINE_HEIGHT_GROW[idx];
  return `${size + grow}`;
};

export const getSpace = (clientWidth, size) => {
  const screenType = getScreenType(clientWidth);
  return `${getResponsiveSize(size, STANDARD_SPACE_MAP, screenType)}px`;
};
export const ALIGN_MAP = {
  left: 'flex-start',
  right: 'flex-end',
  top: 'flex-start',
  bottom: 'flex-end',
  center: 'center',
};

export const getStylesBytextAlignment = (alignment = 'center center', flexDirection = 'column') => {
  const alignmentArr = alignment.split(' ');
  const isRow = flexDirection === 'row';
  return css`
    text-align: ${alignmentArr[0]};
    align-items: ${isRow ? ALIGN_MAP[alignmentArr[1]] : ALIGN_MAP[alignmentArr[0]]};
    justify-content: ${isRow ? ALIGN_MAP[alignmentArr[0]] : ALIGN_MAP[alignmentArr[1]]};
  `;
};
