import React, { useState } from 'react';
import HtFormInput from '../form-input';
import HtIcon from '../../base/icon';
import { HtFormInputPasswordWrapper } from './style';
import { INPUT_TYPE } from '../form-input/const';
import { useThemeContext } from '../../../hook/global-theme';

function HtFormInputPassword(props) {
  const [type, setType] = useState(INPUT_TYPE.password);
  const { colorBodyTexts } = useThemeContext();
  const changeInputType = () => {
    setType(type === INPUT_TYPE.password ? INPUT_TYPE.text : INPUT_TYPE.password);
  };
  return (
    <HtFormInputPasswordWrapper>
      <HtFormInput {...props} type={type} maxLength={32} />
      <HtIcon
        onClick={() => changeInputType()}
        className='ht-password-icon'
        color={colorBodyTexts[40]}
        name={type === INPUT_TYPE.password ? 'iconic-yincang' : 'iconic-xianshi'}
        size={20}
      />
    </HtFormInputPasswordWrapper>
  );
}

export default HtFormInputPassword;
