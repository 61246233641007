import styled from 'styled-components';

export default styled.div`
  &.fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    &.site-switch-height {
      top: 40px;
    }
  }
  .header-announcement {
    min-height: 56px;
    width: 100%;
    padding: 0 80px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    .announcement-body-bg {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      &:after {
        content: '';
      }
    }
    .announcement-body {
      width: 100%;
      position: relative;
      display: flex;
      z-index: 1;
      align-items: center;
      box-sizing: border-box;
      &.announcement-width-1280px {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        .footer-link-container,
        .footer-local-info-container {
          padding-left: 0;
          padding-right: 0;
        }
      }
      &.announcement-width-960px {
        max-width: 960px;
        width: 100%;
        margin: 0 auto;
        .footer-link-container,
        .footer-local-info-container {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .announcement-body-left {
        flex: 1;

        .announcement-telephone {
          display: flex;
          align-items: center;
          span,
          a,
          svg {
            ${(props) => props.theme.getBaseSize([12, 14])};
          }
          word-break: break-word;
        }
      }
      .announcement-body-center {
        box-sizing: border-box;
        flex: 3;
        text-align: center;
        padding: ${(props) => props.theme.getSpace([4, 16])} ${(props) => props.theme.getSpace([16, 80])};
        .announcement_link {
          ${(props) => props.theme.getBaseSize([12, 14])};
          color: #fff;
        }
      }
      .announcement-body-right {
        flex: 1;
        .social-media-icons {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .social-icon-link {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    & {
      height: auto;
    }
    .header-announcement {
      min-height: 42px;
      padding: 6px 0;
      box-sizing: border-box;
      .announcement-body {
        flex-direction: column;
        .announcement-body-left {
          text-align: center;
          .announcement-telephone {
          }
        }
        .announcement-body-left {
          .announcement-telephone {
            margin: 4px 0;
          }
        }
        .announcement-body-right {
          .social-media-icons {
            margin: 4px 0;
          }
        }
      }
    }
  }
`;
