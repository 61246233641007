const initialData = __isServer ? {} : window?.global_data ?? {};

export const getUserInfo = () => {
  return initialData?.userInfo;
};

export const getKdtId = () => {
  return initialData?.kdtId;
};

export const getShopInfo = () => {
  return initialData?.shopInfo;
};

export const formatLocationText = (location) => {
  if (typeof location !== 'object') return '';
  return `${location?.address2} ${location?.address1} ${location?.city ? `${location.city}，` : ''}${
    location?.province ? `${location.province}，` : ''
  }${location?.country}`;
};
