import styled from 'styled-components';

export const Style = styled.div`
  display: inline-block;
  .text-container {
    .no-margin-top {
      margin-top: 0 !important;
    }
    .textH {
      margin-top: ${(props) => props.theme.getSpace(props.theme.vText)};
      p {
        word-break: break-word;
      }
    }

    .link {
      margin-top: ${(props) => props.theme.getSpace(props.theme.vTextButton)};
      margin-left: ${(props) => props.theme.getSpace(props.theme.hButtons)};
      &.no-margin-left {
        margin-left: 0;
      }
    }

    // 文字对齐方式
    &.left-align {
      text-align: left;
    }
    &.center-align {
      text-align: center;
    }
    &.right-align {
      text-align: right;
    }
    &.hidden {
      max-height: 100%;
      overflow-y: hidden;
    }
  }
`;
