import styled from 'styled-components';

export default styled.div`
  position: relative;
  a {
    pointer-events: none;
  }
  &.header,
  &.header-v2 {
    z-index: ${(props) => (props.openAnimation ? 1 : 'initial')};
  }
  &.active {
    &::after {
      pointer-events: none;
      content: '';
      display: block;
      border: 2.5px solid #ff6d00;
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 99;
    }
    &.header,
    &.header-v2 {
      .wrapper-section-name {
      }
    }
    &.header::after,
    &.header-v2::after,
    &.announcement-bar::after {
      position: fixed;
      height: ${(props) => props.styleInfo.height};
      position: ${(props) => props.styleInfo.position};
      top: ${(props) => props.styleInfo.top};
      z-index: ${(props) => props.styleInfo['z-index']};
      box-sizing: border-box;
    }
    .wrapper-section-name {
      position: absolute;
      height: 23px;
      line-height: 23px;
      left: 0px;
      top: 0px;
      padding: 0 3px;
      color: #fff;
      text-align: center;

      background: #ff6d00;
      border-radius: 0 0 4px 0;
      display: initial;
      z-index: 200;
      // 特殊处理某些固定定位的组件
      position: ${(props) => props.styleInfo.position};
      top: ${(props) => props.styleInfo.top};
    }
    .wrapper-section-operate {
      display: flex;
    }
    .wrapper-section-name.is-first {
      top: 0;
    }
  }
  .wrapper-section-name,
  .wrapper-section-operate {
    display: none;
  }
`;
