import React, { useState, useEffect, useRef } from 'react';
import { getUrl } from 'utils/formate';
import { getQueryStringOfDesign } from 'utils/web-tool';
import { PcHeader } from './pc-header';
import { MobileHeader } from './mobile-header';
import shopApi from 'api/shop';
import HeaderWrapper from './style';
import StoreApi from 'api/store';
import { CartModal } from 'cpn/open-cart-dialog';
import get from 'lodash/get';
import LocalStorage from '../../../../util/local-storage';
import { WISH_COUNT_CHANGE } from '../../../../util/local-storage/config';
import OrderHeader from '../../../components/order-header';
import { useThemeContext } from '../../../../hook/global-theme';
import { PcSideHeader } from './pc-side-header';
import debounce from 'lodash/debounce';

const setMenu = ({ defaultMenu, formateCustomMenu, defaultMenuOrder }) => {
  const defaultList = defaultMenu?.shopTemplateMenuItemTreeNodeCustomerVO ?? [];
  const customList = formateCustomMenu?.shopTemplateMenuItemTreeNodeCustomerVO ?? [];

  let shopTemplateMenuItemTreeNodeCustomerVO = [];
  if (defaultMenuOrder === 0 || defaultMenuOrder === -1) {
    shopTemplateMenuItemTreeNodeCustomerVO = [...defaultList, ...customList];
  } else {
    shopTemplateMenuItemTreeNodeCustomerVO = [
      ...customList.slice(0, defaultMenuOrder),
      ...defaultList,
      ...customList.slice(defaultMenuOrder, customList.length),
    ];
  }
  return new Promise((resolve) => {
    resolve({
      ...defaultMenu,
      shopTemplateMenuItemTreeNodeCustomerVO,
    });
  });
};

/**
 * 还需优化
 * @param {} formateCustomMenu
 */
export const setDefaultMenu = async (formateCustomMenu = {}, blockOrder, blocks) => {
  const defaultMenuKey = blockOrder.find((key) => blocks?.[key]?.type === 'default-menu');
  const defaultMenuOrder = blockOrder.indexOf(defaultMenuKey);
  const { kdtId, menuId } = blocks[defaultMenuKey]?.settings?.main_linklist || {};
  const { open_target = 'self' } = blocks[defaultMenuKey]?.settings || {};
  if (!menuId) {
    return setMenu({ defaultMenu: {}, formateCustomMenu, defaultMenuOrder: -1 });
    // 如果没有默认菜单
  }

  if (!__isServer && menuId && menuId === window?.default?.menuId) {
    return setMenu({
      formateCustomMenu,
      defaultMenu: window?.default?.defaultMenu,
      defaultMenuOrder,
    });
  }

  if (menuId) {
    const defaultMenu = await StoreApi.getShopTemplateMenuItems({
      menuId,
      kdtId,
    });
    if (!__isServer) {
      window.default = {
        menuId,
        defaultMenu,
      };
    }
    defaultMenu.shopTemplateMenuItemTreeNodeCustomerVO.map((item) => {
      item.open_target = open_target;
      if (item.shopTemplateMenuItemTreeNodeCustomerVO) {
        item.shopTemplateMenuItemTreeNodeCustomerVO.map((item) => {
          item.open_target = open_target;
        });
      }
    });
    return setMenu({ defaultMenu, formateCustomMenu, defaultMenuOrder });
  }
};

const formateMenu = (list, parent = {}) => {
  list.forEach((item, index) => {
    const { settings: itemSettings, blocks: itemBlocks, menuItemId, block_order: blockOrderItem } = item || {};
    const linkInfo = itemSettings?.announcement_link ? JSON.parse(itemSettings?.announcement_link) : {};
    if (!parent.shopTemplateMenuItemTreeNodeCustomerVO) {
      parent.shopTemplateMenuItemTreeNodeCustomerVO = [];
    }
    parent.shopTemplateMenuItemTreeNodeCustomerVO.push({
      type: item.type,
      settings: item.settings,
      url: linkInfo.url,
      urlType: linkInfo?.urlType,
      urlTitle: linkInfo?.urlTitle,
      open_target: itemSettings?.open_target || 'self',
      menuItemName: itemSettings?.title,
      menuItemId,
    });
    if (itemBlocks) {
      const subList = blockOrderItem.map((id) => ({ ...itemBlocks[id], menuItemId: id }));
      formateMenu(subList, parent.shopTemplateMenuItemTreeNodeCustomerVO[index]);
    }
  });
  return parent;
};

/**
 * 菜单分为自定义好的菜单 还是 手动填的菜单和链接，有可能两个都存在
 */
export const getCustomMenu = (blockOrder, blocks) => {
  if (!blockOrder?.length) return null;
  const MenuList = blockOrder.map((key) => ({ ...blocks[key], menuItemId: key }));
  const customMenus = MenuList.filter((item) => item.type === 'custom-menu');
  const formateCustomMenu = formateMenu(customMenus, {});
  return formateCustomMenu;
};

const Header = (props) => {
  const {
    history = {},
    block_order: blockOrder = [],
    blocks,
    settings: initSetting,
    initialData,
    theme,
    elemClass,
    cartEnableAjax,
    data = {},
    checkoutHeader,
  } = props;
  const isHome = props?.history?.location?.pathname === '/';
  /**
   * 获取公告栏的高度/公告栏会影响头部的定位
   */
  const sections = theme?.current?.sections || {};
  const getAnnouncementBarHeightHeight = () => {
    const pcHeight = 48;
    const announcementBarInfo = sections['announcement-bar'];
    if (!announcementBarInfo) return 0;
    const isExistAnnouncementBar = announcementBarInfo?.disabled !== true;
    const homeOnly = announcementBarInfo?.settings?.home_page_only;

    if (!isExistAnnouncementBar) return 0;
    if (homeOnly) {
      return isHome ? pcHeight : 0;
    } else {
      return pcHeight;
    }
  };
  const [menuDetail, setMenuDetail] = useState(data);
  const [headerHeight, setHeaderHeight] = useState();
  const [totalCountWish, setTotalCountWish] = useState(0);
  const [settings, setSettings] = useState(initSetting);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('down');
  const [announcementBarHeight, setAnnouncementBarHeight] = useState(getAnnouncementBarHeightHeight());
  const { userInfo = {} } = initialData || {};
  const customerId = userInfo?.customerId;
  const themeContext = useThemeContext();
  const headerRef = useRef();

  const {
    open_animation: openAnimation = false,
    open_page_transition: openPageTransition = false,
    animation_type: animationType = 'fade_in',
  } = get(theme, 'current', {});
  /**
   * 头部配置交互
   * 默认状态，头部是正常布局，滑动超过头部时，变固定到顶部，颜色：默认是home_bg_color/home_text_icon_color，鼠标悬浮和滑动时会用default_bg_color/default_text_icon_color（包括导航内所有文字）
   * openTransparent：启动透明配置时，头部会浮在内容上，滑动交互不变。
   * scrollUpHiddenNav： 滑动隐藏，如果滑动时隐藏导航
   */
  const {
    // 旧的数据
    home_bg_color: homeBgColor,
    home_text_icon_color: homeTextIconColor,
    default_bg_color: defaultBgColor,
    default_text_icon_color: defaultTextIconColor, // 默认文本和图标颜色
    scroll_up_hidden_nav: scrollUpHiddenNav,
    open_transparent: openTransparent,
    menu_height: menuHeight = 72,
    // 新的数据
    nav_type: navType = 'horizontal',
  } = settings;

  const getSiteSwitchNavHeight = () => {
    const { settings: siteSelectSettings, block_order: siteSelectBlockOrder } = sections['site-select'] ?? {};
    return siteSelectSettings?.switch_status && siteSelectBlockOrder?.length > 0 ? 40 : 0;
  };

  useEffect(() => {
    let addScrollTop = 0;
    // setHerderFullHeight();
    const scrollFun = (e) => {
      const newScrollTop = document?.documentElement?.scrollTop || document?.body?.scrollTop;

      setScrollTop((old) => {
        if (newScrollTop > old) {
          setScrollDirection('down');
        } else {
          setScrollDirection('up');
        }
        if (addScrollTop === newScrollTop + old) {
          return old;
        } else {
          addScrollTop = newScrollTop + old;
          return newScrollTop;
        }
      });
    };
    // 一开始获取的公告栏高度不准确，需要延迟一会再获取一次
    const timer = setTimeout(() => {
      changeAnnouncementBar();
    }, 2000);
    window.addEventListener('scroll', debounce(scrollFun, 100));
    return () => {
      window.removeEventListener('scroll', debounce(scrollFun, 100));
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const jumpToOtherPage = (path) => {
    if (window.isEdit) return;
    if (history) history.push(`${path}${getQueryStringOfDesign()}`);
  };

  const jumpToMenuPage = (event, url = '', openWay = 'self') => {
    if (window.isEdit) return;
    event.stopPropagation();
    // hack 解决未配置url的跳转问题
    if (url.indexOf('/') === -1) return;
    if (getUrl(url) === window.location.href.split('?')[0]) return;
    const baseUrl = getUrl(url);
    const queryString = getQueryStringOfDesign().substr(1);
    const fullUrl = baseUrl + (baseUrl.includes('?') ? `&${queryString}` : `?${queryString}`);
    window.open(fullUrl, `_${openWay}`);
  };

  const getMainDefaultMenu = () => {
    shopApi.getShopTemplateMenuInit().then((res) => {
      initSetting.main_linklist = res;
      setSettings(initSetting);
    });
  };

  useEffect(() => {
    setSettings(initSetting);
  }, [initSetting?.show_announcement]);

  useEffect(() => {
    const { main_linklist: mainLinkList } = initSetting;

    if (mainLinkList === 'main-menu') {
      getMainDefaultMenu();
    } else {
      setSettings(initSetting);
    }
  }, [initSetting?.main_linklist]);

  useEffect(() => {
    let updateWishCount;
    if (customerId) {
      shopApi.getWishCountGoods({ customerId }).then((res) => {
        LocalStorage.set(WISH_COUNT_CHANGE, 0);
        if (typeof res === 'number') {
          setTotalCountWish(res);
          updateWishCount = () => {
            const count = Number(LocalStorage.get(WISH_COUNT_CHANGE));
            setTotalCountWish(res + count);
          };
          window.addEventListener('setItemEvent', updateWishCount);
          window.addEventListener('storage', updateWishCount);
        }
      });
    }
    return () => {
      window.removeEventListener('setItemEvent', updateWishCount);
      window.removeEventListener('storage', updateWishCount);
    };
  }, [customerId]);
  /**
   * 还需优化
   * @param {} formateCustomMenu
   */

  useEffect(() => {
    const formateCustomMenu = getCustomMenu(blockOrder, blocks);
    setDefaultMenu(formateCustomMenu, blockOrder, blocks).then((res) => {
      setMenuDetail(res);
    });
  }, [blocks, blockOrder]);

  const changeAnnouncementBar = () => {
    const announcementBarEle = document.getElementsByClassName('header-announcement')[0];
    announcementBarEle && setAnnouncementBarHeight(announcementBarEle.offsetHeight);
  };

  useEffect(() => {
    changeAnnouncementBar();
  }, [
    sections?.['announcement-bar']?.settings.fixed_top,
    sections?.['announcement-bar']?.disabled,
    sections?.['announcement-bar']?.settings?.announcement_text,
    sections?.['announcement-bar']?.settings?.text_size,
    sections?.['announcement-bar']?.settings?.show_telephone,
    sections?.['announcement-bar']?.settings?.show_social_media_icon,
    themeContext.isMobile,
  ]);

  useEffect(() => {
    changeAnnouncementBar();
    const debounceFun = debounce(changeAnnouncementBar, 100);
    window.addEventListener('resize', debounceFun);
    return () => {
      window.removeEventListener('resize', debounceFun);
    };
  }, []);

  const siteSwitchNavHeight = getSiteSwitchNavHeight();
  const isScrollOver = !!(scrollTop > announcementBarHeight + siteSwitchNavHeight);
  const isScrollUp = scrollDirection === 'up';

  useEffect(() => {
    // 要延迟一点拿到的高度才是准确的
    setTimeout(() => {
      setHeaderHeight(headerRef?.current?.clientHeight);
    }, 300);
  }, [isScrollOver]);

  // const isScrollUp = false

  // 主页，鼠标没移上去，没有触发吸定
  const headerGlobalStyle = {
    background: isHome && !isMouseOver && !isScrollOver && !themeContext.isMobile ? homeBgColor : defaultBgColor,
    color: isHome && !isMouseOver && !isScrollOver && !themeContext.isMobile ? homeTextIconColor : defaultTextIconColor,
  };
  const PCHeaderStyle = {
    height: (!scrollUpHiddenNav && isScrollOver) || (isHome && openTransparent) ? 0 : `${headerHeight}px`,
    background: isHome && !isMouseOver && !isScrollOver ? homeBgColor : defaultBgColor,
  };

  const showAnnouncementHeightStatus =
    sections?.['announcement-bar']?.settings?.fixed_top && !sections?.['announcement-bar']?.disabled;

  const getHeaderTop = () => {
    // 上滑消失下滑出现
    const announcementHeightCal = showAnnouncementHeightStatus ? announcementBarHeight : 0;
    const siteSwitchNavHeightCal = announcementHeightCal ? siteSwitchNavHeight : 0;
    if (scrollUpHiddenNav && navType !== 'side') {
      if (isScrollOver) {
        if (isScrollUp) {
          return 0 + announcementHeightCal + siteSwitchNavHeightCal;
        }
        return `-${menuHeight}px`;
      }
      return `inherit`;
    }
    return isScrollOver ? 0 + announcementHeightCal + siteSwitchNavHeightCal : 'inherit';
  };
  const getPcHeaderDisplay = () => {
    if (scrollUpHiddenNav) {
      if (isScrollOver) {
        if (isScrollUp) {
          return 1;
        }
        return 0;
      }
      return 1;
    }
    return 1;
  };

  const [visible, setVisible] = useState(false);
  const onOpenCart = () => {
    window.postMessage('openRightShoppingCart', '*');
    setVisible(true);
  };

  return (
    <HeaderWrapper
      className={elemClass}
      style={checkoutHeader ? { height: '80px' } : PCHeaderStyle}
      menuHeight={menuHeight}
      isHome={isHome}
      defaultBgColor={defaultBgColor}
      openAnimation={openAnimation}
    >
      <div
        className='fixed_header'
        style={{
          position: isScrollOver && !checkoutHeader ? 'fixed' : 'absolute',
          top: getHeaderTop(),
          boxShadow: isScrollOver && isScrollUp ? '0px 1px 0px rgba(0, 0, 0, 0.05)' : 'none',
        }}
      >
        <div className='design-mobile-header design-mobile-header2' style={headerGlobalStyle}>
          {navType === 'horizontal' ? (
            <MobileHeader
              history={history}
              settings={settings}
              initialData={initialData}
              elemClass={elemClass}
              cartEnableAjax={cartEnableAjax}
              jumpToOtherPage={jumpToOtherPage}
              jumpToMenuPage={jumpToMenuPage}
              menuDetail={menuDetail}
              totalCountWish={totalCountWish}
              checkoutHeader={checkoutHeader}
              headerGlobalStyle={headerGlobalStyle}
              onOpenCart={onOpenCart}
            />
          ) : themeContext.isMobile ? (
            <PcSideHeader
              history={history}
              blockOrder={blockOrder}
              blocks={blocks}
              settings={settings}
              initialData={initialData}
              elemClass={elemClass}
              cartEnableAjax={cartEnableAjax}
              jumpToOtherPage={jumpToOtherPage}
              jumpToMenuPage={jumpToMenuPage}
              menuDetail={menuDetail}
              setIsMouseOver={setIsMouseOver}
              headerGlobalStyle={headerGlobalStyle}
              totalCountWish={totalCountWish}
              isScrollOver={isScrollOver}
              onOpenCart={onOpenCart}
            />
          ) : null}
        </div>
        <div className='design-pc-header design-pc-header2' ref={headerRef} style={headerGlobalStyle}>
          {checkoutHeader ? (
            <OrderHeader settings={settings} jumpToOtherPage={jumpToOtherPage} style={headerGlobalStyle} />
          ) : navType === 'horizontal' ? (
            <PcHeader
              style={{
                display: getPcHeaderDisplay(),
              }}
              history={history}
              blockOrder={blockOrder}
              blocks={blocks}
              settings={settings}
              initialData={initialData}
              elemClass={elemClass}
              cartEnableAjax={cartEnableAjax}
              jumpToOtherPage={jumpToOtherPage}
              jumpToMenuPage={jumpToMenuPage}
              menuDetail={menuDetail}
              setIsMouseOver={setIsMouseOver}
              headerGlobalStyle={headerGlobalStyle}
              totalCountWish={totalCountWish}
              isScrollOver={isScrollOver}
              onOpenCart={onOpenCart}
            />
          ) : (
            <PcSideHeader
              style={{
                display: getPcHeaderDisplay(),
              }}
              history={history}
              blockOrder={blockOrder}
              blocks={blocks}
              settings={settings}
              initialData={initialData}
              elemClass={elemClass}
              cartEnableAjax={cartEnableAjax}
              jumpToOtherPage={jumpToOtherPage}
              jumpToMenuPage={jumpToMenuPage}
              menuDetail={menuDetail}
              setIsMouseOver={setIsMouseOver}
              headerGlobalStyle={headerGlobalStyle}
              totalCountWish={totalCountWish}
              isScrollOver={isScrollOver}
              onOpenCart={onOpenCart}
            />
          )}
        </div>
      </div>
      {/* 购物车节点挂载  */}
      <div id='shopping-cart-box' />

      <CartModal
        initialData={initialData}
        visible={visible}
        onClose={() => {
          window.postMessage('closeRightShoppingCart', '*');
          setTimeout(() => {
            setVisible(false);
          }, 300);
        }}
      />
    </HeaderWrapper>
  );
};

Header.getInitialProps = (data) => {
  const { block_order: blockOrder, blocks } = data;
  const formateCustomMenu = getCustomMenu(blockOrder, blocks);
  return setDefaultMenu(formateCustomMenu, blockOrder, blocks);
};

export default Header;
