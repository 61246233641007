import loadable from '@loadable/component';

export default [
  {
    path: '/member/detail',
    canNotEditLayout: true,
    name: 'member-detail',
    canDesignPage: true,
    exact: true,
    component: loadable(() => import('../pages/member/detail')),
    pageType: '付费会员页面',
  },
  {
    path: '/member/goods',
    // canNotEditLayout: true,
    name: 'member-goods',
    exact: true,
    component: loadable(() => import('../pages/member/goods')),
    pageType: '会员特价商品',
    canDesignPage: true,
  },
  {
    path: '/member/pay',
    // canNotEditLayout: true,
    name: 'member-pay',
    canDesignPage: true,
    exact: true,
    component: loadable(() => import('../pages/member/pay')),
    pageType: '支付页',
    // hideHeader: true,
    hideFooter: true,
    checkoutHeader: true,
  },
  {
    path: '/member/pay/result',
    // canNotEditLayout: true,
    name: 'member-pay-result',
    canDesignPage: true,
    exact: true,
    component: loadable(() => import('../pages/member/pay-result')),
    pageType: '支付结果页',
    // hideHeader: true,
    hideFooter: true,
    checkoutHeader: true,
  },
  {
    path: '/member/protocol',
    // canNotEditLayout: true,
    name: 'member-protocol',
    canDesignPage: true,
    exact: true,
    component: loadable(() => import('../pages/member/protocol')),
    pageType: '协议页',
    hideFooter: true,
    checkoutHeader: true,
  },
];
