import React, { useState } from 'react';
import { Icon } from 'components/base';
import { TabType } from 'const/account';
import { Pop } from 'zent';
import Drawer from 'components/base/drawer';
import SelectLanguageCurrency from '../select-language-currency';
import { useMultiCurrencyLanguage } from '../../hooks/multi-currency-language';
import { useRootContext } from 'client/pages/context/root';
import { logout } from 'helper/account';
import Style, { GlobalStyle } from './style';

const DROP_DOWN_NAMES = {
  account: 'account',
  wish: 'wish',
  cart: 'cart',
  settings: 'settings',
  logout: 'logout',
};

function DropdownMenu(props) {
  const { menuList, onDropdownItemClick } = props;
  return (
    <div className='dropdown-list'>
      {menuList.map((item) => {
        return item.show ? (
          <div className='dropdown-item' onClick={() => onDropdownItemClick(item.name)}>
            <div className='dropdown-icon-wrapper'>
              <Icon name={item.icon} className='dropdown-icon' />
              {item.count > 0 && <span className='dropdown-icon-badge'>{item.count}</span>}
            </div>
            <div className='dropdown-name'>{item.text}</div>
          </div>
        ) : null;
      })}
    </div>
  );
}

function MobileHeaderOperate(props) {
  const {
    initialData,
    jumpToOtherPage,
    intl,
    totalOfGoods,
    totalCountWish = 0,
    showSearchIcon,
    showPersonalCenterIcon,
    showWishlistIcon,
    showShopCartIcon,
    defaultTextIconColor,
    isSide,
    onOpenCart,
  } = props;
  const { kdtId, userInfo = {} } = initialData;
  const [showSettingDrawer, setShowSettingDrawer] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const {
    isShowMultiSettingEntry,
    isShowMultiCurrency,
    isShowMultiLanguage,
    languageList,
    multiCurrencies,
    multiCurrency,
    language,
    setMultiCurrency,
    setLanguage,
    updateLanguageCookie,
    updateCurrencyCookie,
  } = useMultiCurrencyLanguage(kdtId, initialData);
  const { setLoginDialogInfo } = useRootContext();
  const menuList = [
    {
      icon: 'iconic-gerenzhanghao',
      text: intl.formatMessage({
        id: '102542a930ce453faa7aec9c00908045',
        defaultMessage: '账号',
      }),
      name: DROP_DOWN_NAMES.account,
      show: showPersonalCenterIcon,
    },
    {
      icon: 'iconic-shoucang2',
      text: intl.formatMessage({
        id: '04aa02460ca54c6c9addc40b06e67fbc',
        defaultMessage: '心愿单',
      }),
      name: DROP_DOWN_NAMES.wish,
      count: totalCountWish,
      show: showWishlistIcon,
    },
    {
      icon: isSide ? 'icondaohanglantubiao_gouwuche' : 'iconic-gouwuche',
      text: intl.formatMessage({
        id: 'shopping_cart',
        defaultMessage: '购物车',
      }),
      name: DROP_DOWN_NAMES.cart,
      count: totalOfGoods,
      show: showShopCartIcon,
    },
    {
      icon: 'iconduoyuyanduobizhong1',
      text: intl.formatMessage({
        id: '2504b470fe234a85b0225596c9a9b618',
        defaultMessage: '语言 / 币种',
      }),
      name: DROP_DOWN_NAMES.settings,
      show: isShowMultiSettingEntry,
    },
    {
      icon: 'iconic-tuichu',
      text: intl.formatMessage({
        id: 'account.cancel_login',
        defaultMessage: '退出登录',
      }),
      name: DROP_DOWN_NAMES.logout,
      show: userInfo?.customerId,
    },
  ];

  const { customerId } = userInfo;
  const totalCount = totalOfGoods + totalCountWish;
  const onDropdownItemClick = (name) => {
    switch (name) {
      case DROP_DOWN_NAMES.account:
        if (customerId) {
          jumpToOtherPage('/me');
        } else {
          setLoginDialogInfo({
            visible: true,
          });
        }
        break;
      case DROP_DOWN_NAMES.cart:
        onOpenCart();
        break;
      case DROP_DOWN_NAMES.wish:
        if (customerId) {
          jumpToOtherPage(`/me?tabId=${TabType.Wish}`);
        } else {
          setLoginDialogInfo({
            visible: true,
          });
        }
        break;
      case DROP_DOWN_NAMES.settings:
        setShowSettingDrawer(true);
        break;
      case DROP_DOWN_NAMES.logout:
        logout();
        break;
      default:
        console.log('no matched');
    }
    setShowDropDown(false);
  };

  const onVisibleChange = (value) => {
    setShowDropDown(value);
  };

  return (
    <Style>
      <GlobalStyle />
      {showSearchIcon && (
        <Icon
          name='iconic-sousuo'
          color={defaultTextIconColor}
          className='operate-icon'
          onClick={() => {
            jumpToOtherPage('/search');
          }}
        />
      )}
      {(showShopCartIcon || showWishlistIcon || showWishlistIcon || isShowMultiSettingEntry) && (
        <Pop
          visible={showDropDown}
          onVisibleChange={onVisibleChange}
          className='mobile-header-dropdown'
          wrapperClassName='settings-icon'
          trigger='click'
          position='bottom-right'
          content={<DropdownMenu menuList={menuList} onDropdownItemClick={onDropdownItemClick} />}
          cushion={25}
        >
          <div
            className='icon-wrapper'
            onClick={() => {
              setShowDropDown(!showDropDown);
            }}
          >
            <Icon name='iconic-gengduo' className='operate-icon' color={defaultTextIconColor} />
            {totalCount > 0 && <span className='total-count'>{totalCount}</span>}
          </div>
        </Pop>
      )}

      <Drawer visible={showSettingDrawer} onMaskClick={() => setShowSettingDrawer(false)}>
        <SelectLanguageCurrency
          language={language}
          languageList={languageList}
          multiCurrency={multiCurrency}
          multiCurrencies={multiCurrencies}
          setMultiCurrency={setMultiCurrency}
          setLanguage={setLanguage}
          updateLanguageCookie={updateLanguageCookie}
          updateCurrencyCookie={updateCurrencyCookie}
          isShowMultiCurrency={isShowMultiCurrency}
          isShowMultiLanguage={isShowMultiLanguage}
          intl={intl}
        />
      </Drawer>
    </Style>
  );
}

export default MobileHeaderOperate;
