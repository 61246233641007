import React from 'react';
import { Icon } from 'components/base';
import Style from './style';

/**
 * 折扣类型标签
 * */
const HuntPrompt = (props) => {
  const { showLink = true, title = '', desc = '', linkText = '', onLink, className } = props;

  return (
    <Style className={className}>
      {title && <div className='hunt-prompt-left'>{title && <span className='hunt-prompt-title'>{title}</span>}</div>}
      <div className='hunt-prompt-right-wrapper'>
        {desc && <div className='hunt-prompt-desc'>{desc}</div>}
        {showLink && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onLink();
            }}
            className='hunt-prompt-right'
          >
            {linkText && <span className='link-text'>{linkText}</span>}
            <Icon className='iconic-icon icon-right' name='iconic-xiala' size='12' />
          </div>
        )}
      </div>
    </Style>
  );
};

export default HuntPrompt;
