import { useEffect, useState } from 'react';
import localApi from '../api/local';

// 跳过mediaId获取medioUrl， medioUrl有失效问题，每次都需要重新获取
export const useMedioUrl = (mediaId) => {
  const [medioUrl, setMedioUrl] = useState('');
  useEffect(() => {
    if (!mediaId) {
      return;
    }
    localApi.getVideoPlayInfo({ mediaId }).then((res) => {
      setMedioUrl(res?.playUrl);
    });
  }, [mediaId]);

  return {
    medioUrl,
  };
};
