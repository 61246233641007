/**
 * 商品相关的api接口封装
 */
import BaseApi from './base';

class GroupBuying extends BaseApi {
  /**
   * 获取拼团列表
   * http://zanapi.qima-inc.com/site/service/view/1159337
   * @param params
   */
  searchGroupActivityGoodsWithPage(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.group.controller.web.GroupActivityController/searchGroupActivityGoodsWithPage',
      params,
    );
  }

  /**
   * 查询商品活动详情
   * http://zanapi.qima-inc.com/site/service/view/1159337
   * @param params
   */
  getPromotionActivityDescriptionByGoodsId(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.activity.controller.web.ActivityGoodsController/getPromotionActivityDescriptionByGoodsId',
      params,
    );
  }

  /**
   * 发起拼团
   * http://zanapi.qima-inc.com/site/service/view/1159338
   * @param params
   */
  createGroupBuying(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.group.controller.web.GroupActivityController/createGroupBuying',
      params,
    );
  }

  /**
   * 加入拼团
   * http://zanapi.qima-inc.com/site/service/view/1159339
   * @param params
   */
  joinGroupBuying(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.group.controller.web.GroupActivityController/joinGroupBuying',
      params,
    );
  }

  /**
   * 获取待拼团列表 / 未开始凑团时 不应该返回
   * http://zanapi.qima-inc.com/site/service/view/1159340
   * @param params
   */
  searchWaitingGroupOrderWithPage(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.group.controller.web.GroupActivityController/searchWaitingGroupOrderWithPage',
      params,
    );
  }

  /**
   * 获取团单商品详情
   * http://zanapi.qima-inc.com/site/service/view/1165532
   * @param params
   */
  getGroupDetailGoods(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.group.controller.web.GroupActivityController/getGroupDetailGoods',
      params,
    );
  }

  /**
   * 获取团单详情
   * @param params
   */
  getGroupResultDetail(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.group.controller.web.GroupActivityController/getGroupResultDetail',
      params,
    );
  }

  /**
   * 获取活动单
   * @param params
   */
  getGroupActivityGoodsById(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.group.controller.web.GroupActivityController/getGroupActivityGoodsById',
      { ...params, descriptionHtmlSource: 'pc' },
    );
  }

  /**
   * 获取拼团商品详情
   * @param params
   */
  getSimplePromotionGoods(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.activity.controller.web.ActivityGoodsController/getSimplePromotionGoods',
      params,
    );
  }

  /**
   * 获取拼团商品详情页分享短链
   * @param params
   */
  getGroupGoodsShareLink(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.group.controller.web.GroupActivityController/getGroupGoodsShareLink',
      params,
    );
  }

  /**
   * 获取拼团详情页分享短链
   * @param params
   */
  getGroupDetailShareLink(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.group.controller.web.GroupActivityController/getGroupDetailShareLink',
      params,
    );
  }
}

export default new GroupBuying();
