export const isNode = typeof window === 'undefined';

/** 使用方自己判断是否node端 */
export const isMobile = () => {
  return !!navigator.userAgent.match(
    /(AppleWebKit.*Mobile.*)|(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  );
};

// 是否来自小红书小程序
export const isFromXHS = (useAgent = navigator.userAgent) => {
  return useAgent.toLowerCase().match(/xhsminiapp/);
};

// 是否来自微信小程序
export const isFromWeapp = (useAgent = navigator.userAgent) => {
  return useAgent.toLowerCase().match(/miniprogram/);
};

// 是否来自小红书小程序和微信小程序
export const isFromXHSAndWeapp = (useAgent = navigator.userAgent) => {
  return isFromXHS(useAgent) || isFromWeapp(useAgent);
};
