import styled, { createGlobalStyle } from 'styled-components';

export default styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.is-mobile {
    flex: 1;
  }
  &.no-grow {
    flex-grow: 0 !important;
  }

  .header-center-logo-container .header-operate-container {
    position: absolute;
    transform: translateY(-50%);
    right: 80px;
    top: 50%;
  }

  .header-icon {
    width: ${(props) => (props.isSide ? '32px' : '24px')};
    height: ${(props) => (props.isSide ? '32px' : '24px')};
    fill: ${(props) => props.iconColor};
    display: inline-block;
    vertical-align: bottom;
    &:hover {
      opacity: 0.65;
    }
  }
  .side-header-icon {
    width: 32px;
    height: 32px;
  }

  .header-icon-container {
    margin-left: 24px;
    display: inline-block;
    cursor: pointer;
    padding: 6px 0;
  }
  @media (max-width: 769px) {
    .dynamic-display {
      display: none;
    }
  }
`;
export const GlobalStyle = createGlobalStyle`
    .setting-currency-pop {
        box-shadow: 0px 0px 16px rgb(0 0 0 / 8%) !important;
        background-color: var(--color_card, #fff) !important;;
        .zent-pop-arrow {
            background-color: var(--color_card, #fff) !important;;
        }
        .zent-pop-inner {
            padding: 0 !important;
        }
        .zent-select {
            margin-right: 0px !important;
        }
        .hunt-select-trigger input {
            height: calc(100% - 2px) !important;
        }
        .zent-pop-inner {
            background: transparent !important;
        }
        .hunt-select-trigger {
            color: ${(props) => props.theme.colorBodyText};
        }
        z-index: 1000;
    }
    .setting-zent-popover {
        z-index: 10011;
    }
    .account-popover{
       
        .account-popover-content{
            font-style: normal;
            font-weight: normal;
            ${(props) => props.theme.getBaseSize([14])};
            color: ${(props) => props.theme.colorBodyText};            
            margin-top:16px;
            cursor: pointer;
            &:first-child{
                margin-top:0px;
            }                       
            
        }
    }
    .settings-wrapper {
        padding: 0 16px 16px;
        border-radius: 4px;
        /* background: #fff; */
        .zent-select-text {
            margin-top: 8px;
            line-height: 28px;
            background-color: #fff !important;
        }
    }
    .currency-select-label {
        padding-top: 16px;
        font-weight: bold;
        margin-bottom: 8px;
        color: var(--color_body_text)
    }
`;
