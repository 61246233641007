import React, { useMemo, useContext, useState, useEffect, useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import cn from 'classnames';
import { Style } from './style';
import goodsApi from '../../../api/goods';
import MainMedia from './main-media';
import ProductGallery from './product-gallery';
import { ContextGoodDetail } from '../../context/context-good-detail';

const ProductMediaPreview = ({
  isZoom = false,
  handle = '',
  galleryDirection,
  isShowGallery,
  isGalleryWrap,
  onViewChange = () => {},
  arrows,
}) => {
  const { productMedia, updateProductMediaList, updateMainMediaIndex, layoutStyle } = useContext(ContextGoodDetail);
  const { mainMediaIndex, mediaList, mainMediaSrc } = productMedia;
  const mainMedia = mediaList[mainMediaIndex];

  const [newMainMediaSrc, setNewMainMediaSrc] = useState(mainMediaSrc);

  const productGalleryRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !!mainMediaSrc && updateMainMediaIndex(-1);
    setNewMainMediaSrc(mainMediaSrc);
  }, [mainMediaSrc]);

  const updateVideoMedia = () => {
    const mediaIdArr = [];
    if (mediaList && mediaList?.length) {
      mediaList?.forEach((item) => {
        if (item?.type === 'VIDEO') {
          mediaIdArr.push(item?.mediaId);
        }
      });
      if (mediaIdArr.length) {
        goodsApi
          .getVideosByIds({
            mediaIds: mediaIdArr,
            kdtId: window?.global_data?.kdtId,
          })
          .then((res) => {
            const newData = mediaList.slice();
            newData.forEach((item, index) => {
              if (item.type === 'VIDEO') {
                res.forEach((subItem) => {
                  if (item.mediaId === subItem.mediaId) {
                    newData[index] = { ...item, ...subItem, url: subItem.playUrl };
                  }
                });
              }
            });
            updateProductMediaList(newData);
          });
      }
    } else {
      updateProductMediaList([]);
    }
  };

  useDeepCompareEffect(updateVideoMedia, [mediaList]);

  const galleryList = useMemo(() => {
    switch (layoutStyle) {
      case 'style1':
      case 'style3':
        /** 样式1、2时，缩略图不展示sku图，详情咨询湘楠 */
        return mediaList.filter(({ variantId }) => !variantId);
      default:
        return mediaList;
    }
  }, [layoutStyle, mediaList]);

  const handleOnLeft = () => {
    if (mainMediaIndex < 1) return;
    const currentMainIndex = mainMediaIndex === 0 ? galleryList.length - 1 : mainMediaIndex - 1;
    updateMainMediaIndex(currentMainIndex);
    onViewChange();
    setNewMainMediaSrc('');
  };
  const handleOnRight = () => {
    if (mainMediaIndex >= galleryList.length - 1) return;

    const currentMainIndex = mainMediaIndex === galleryList?.length - 1 ? 0 : mainMediaIndex + 1;
    updateMainMediaIndex(currentMainIndex);
    onViewChange();
    setNewMainMediaSrc('');
  };

  const showArrows = galleryList?.length > 1 && arrows && mainMedia?.url;
  // const showArrows = galleryList?.length > 1 && arrows && mainMediaIndex < galleryList.length && mainMedia?.url;

  useEffect(() => {
    if (productGalleryRef.current) {
      const length = galleryList.length;
      if (length > 4 && mainMediaIndex > 1) {
        productGalleryRef.current.scrollTo({
          left: (mainMediaIndex - 2) * 140,
          behavior: 'smooth',
        });
      }
    }
  }, [mainMediaIndex, productGalleryRef]);

  return (
    <Style>
      <div className={cn('product-media-preview', `thumbnail-display-direction-${galleryDirection}`)}>
        <div
          className={cn('product-main-media-wrapper', `product-main-arrows-${arrows}`, {
            'hide-arrow': !isZoom,
          })}
        >
          <div className='main-media-wrapper'>
            {showArrows && (
              <span className={cn('arrow left-arrow', { disabled: mainMediaIndex < 1 })} onClick={handleOnLeft}>
                <svg className='icon' aria-hidden='true' width='32' height='32'>
                  <use xlinkHref='#iconic-zuoyouqiehuan' />
                </svg>
              </span>
            )}
            <MainMedia
              coverUrl={newMainMediaSrc || mainMedia?.coverUrl}
              url={newMainMediaSrc || mainMedia?.url}
              handle={handle}
              type={mainMedia?.type}
              mediaId={mainMedia?.mediaId}
              isZoom={isZoom}
            />
            {showArrows && (
              <span
                className={cn('arrow right-arrow', { disabled: mainMediaIndex >= galleryList.length - 1 })}
                onClick={handleOnRight}
              >
                <svg className='icon' aria-hidden='true' width='32' height='32'>
                  <use xlinkHref='#iconic-zuoyouqiehuan' />
                </svg>
              </span>
            )}
          </div>
        </div>
        {isShowGallery && (
          <ProductGallery
            ref={productGalleryRef}
            onNext={onViewChange}
            onPrev={onViewChange}
            isZoom={isZoom}
            mediaList={galleryList}
            isGalleryWrap={isGalleryWrap}
            galleryDirection={galleryDirection}
            activeIndex={mainMediaIndex}
            handle={handle}
            onGoTO={(index) => {
              onViewChange();
              setNewMainMediaSrc('');
              updateMainMediaIndex(index);
            }}
          />
        )}
      </div>
    </Style>
  );
};

export default ProductMediaPreview;
