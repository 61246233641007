import React from 'react';
import Style from './style';
import { SideSlide } from 'cpn/side-slide';
import { MenuList } from '../menu-list';

export const PcSideSubMenu = (props) => {
  const {
    menuDetail = {},
    menuHeight,
    totalCountWish,
    settings,
    initialData,
    jumpToOtherPage,
    levelOneMenuTextSize = 11,
    levelTwoMenuTextSize = 11,
    levelThreeMenuTextSize = 11,
    iconSize = '24',
    className = '',
    headerGlobalStyle,
    isMobile,
  } = props;
  const {
    sub_menu_bg_color: subMenuBgColor = '#ffffff',
    sub_menu_text_color: subMenuTextColor = '#000000',
    open_icon_style: openIconStyle = 'style1',
    sub_menu_open_type: subMenuOpenType = 'dropdown',
    side_sub_menu_align: sideSubMenuAlign = 'top',
  } = settings;
  const firstMenuList = menuDetail.shopTemplateMenuItemTreeNodeCustomerVO;
  const iconType = openIconStyle === 'style1' || subMenuOpenType === 'clickinto' ? '#iconic-xiala' : '#iconic_open';
  return (
    <Style
      className={className}
      menuHeight={menuHeight}
      levelOneMenuTextSize={levelOneMenuTextSize}
      levelTwoMenuTextSize={levelTwoMenuTextSize}
      levelThreeMenuTextSize={levelThreeMenuTextSize}
      subMenuTextColor={subMenuTextColor}
      subMenuBgColor={subMenuBgColor}
    >
      <SideSlide
        expendIconName='iconic-zhucaidan'
        iconSize={iconSize}
        totalCountWish={totalCountWish}
        settings={settings}
        initialData={initialData}
        jumpToOtherPage={jumpToOtherPage}
        subMenuTextColor={subMenuTextColor}
        headerGlobalStyle={headerGlobalStyle}
        isMobile={isMobile}
        firstMenuList={firstMenuList}
      >
        <MenuList
          firstMenuList={firstMenuList}
          iconType={iconType}
          subMenuOpenType={subMenuOpenType}
          sideSubMenuAlign={sideSubMenuAlign}
        />
      </SideSlide>
    </Style>
  );
};
