import styled from 'styled-components';
import { getSizeVw } from '../../../../style/fn';

export const Style = styled.div`
  .featured-row {
    display: flex;
    justify-content: space-between;
    margin: auto;
    box-sizing: border-box;
  }

  .featured-img-wrap {
    position: relative;
    font-size: 0;
  }

  .featured-row-default {
    position: relative;
    overflow: hidden;
  }

  .featured-row-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-word;
    padding: var(--pm-40-20);
  }

  .featured-text-top {
    justify-content: flex-start;
  }

  .featured-text-center {
    justify-content: center;
  }

  .featured-text-bottom {
    justify-content: flex-end;
  }

  .featured-text-space-between {
    justify-content: space-between;
  }

  .featured-row-title {
    color: var(--color_text);
    ${(props) => props.theme.getBaseSize([18, 24])};
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
  }

  .featured-row-desc {
    color: var(--color_body_text);
    margin-top: 16px;
    ${(props) => props.theme.getBaseSize([14, 16])};
  }
  .button-link {
    display: inline-block;
    font-size: var(--base-font-16-14);
    line-height: 1;
    color: var(--color_text);
    margin-top: ${(props) => props.theme.getSpace([16, 40])};
  }
  .image-title {
    position: absolute;
    color: #fff;
    top: 50%;
    z-index: 9;
    font-size: ${(props) => props.theme.getHeaderFontSize([24, 40])};
    line-height: ${(props) => props.theme.getHeaderLineHeight([24, 40])};
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    transform: (-50%, 0);
    transform: translateY(-50%);
  }

  .link-type-button {
    background-color: var(--color_button);
    color: var(--color_button_text);
  }

  .link-type-link {
    color: var(--color_button);
    text-decoration: underline;
  }

  /* .container-width-1280 {
        max-width: 1280px;
    }

    .container-width-960 {
        max-width: 960px;
    } */

  .featured-row-img {
    width: 100%;
  }

  @media screen and (min-width: 751px) {
    .align-right {
      flex-direction: row-reverse;
    }

    .featured-row-default {
      &.image-ratio-auto {
        width: ${getSizeVw(545)};
        max-width: 525px;
      }
    }

    .link-type-button {
      padding: 20px 32px;
    }

    .image-ratio-auto {
      width: auto;
      max-width: 50%;
    }
    .image-ratio-3-2 {
      width: 60%;
    }

    .image-ratio-2-3 {
      width: 40%;
    }
    .image-ratio-1-2 {
      width: 33.33%;
    }

    .image-ratio-1-3 {
      width: 25%;
    }

    .image-ratio-1-4 {
      width: 20%;
    }
  }
  @media screen and (max-width: 750px) {
    .featured-row {
      flex-direction: column;
    }

    .featured-row-default {
      width: 100%;
      height: 327px;
    }

    .default-square-img {
      height: 100%;
      padding-top: 0;
    }

    .featured-row-text {
      width: 100%;
    }

    .featured-row-text {
      box-sizing: border-box;
    }
  }

  .featured-row-img {
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
`;
