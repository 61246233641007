import { Button } from 'zent';
import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import { Style, NewGiftStyle } from './style';
import cn from 'classnames';

export const DiscountGiftWrapper = (props) => {
  const { onConfirm, onSwitch, totalGift, isEditGift, discountQuantity, selectedGoods = [] } = props;
  const isGiftFit = totalGift === discountQuantity; // 赠品是否选够了
  const intl = useIntl();
  const isApply = !!selectedGoods?.length; // 已经选择足够赠品了并且应用了折扣
  const [iconUp, setIconUp] = useState(false);

  return (
    <NewGiftStyle>
      <Style className='gift-wrapper'>
        <div className='title-operation'>
          <div className='title-left'>
            <svg aria-hidden='true' width={16} height={16}>
              <use xlinkHref='#iconicon_gift' />
            </svg>
            <span>
              {intl.formatMessage({
                id: 'de2f8159bfd4411b8c7e8dd7ed8f1437',
                defaultMessage: '选择优惠商品',
                description: '选择优惠商品',
              })}
            </span>
          </div>
          <div
            className='title-right'
            onClick={() => {
              setIconUp(!iconUp);
              onSwitch();
            }}
          >
            <span className='overview'>
              {isApply
                ? intl.formatMessage({ defaultMessage: '修改', id: 'e50dcc0343ec46b18704341d7ffe54aa' })
                : intl.formatMessage({
                    id: 'general.view',
                    defaultMessage: '查看',
                    description: '查看',
                  })}
            </span>
            <svg className={cn({ up: iconUp }, 'switch-icon')} aria-hidden='true' width={16} height={16}>
              <use xlinkHref='#iconic-xiala1' />
            </svg>
          </div>
        </div>

        {props.children}
        {isEditGift && (
          <div className='bottom'>
            <div>
              {intl.formatMessage(
                {
                  id: '4e664371e4884748a0b21e251ce56c31',
                  defaultMessage: `已经选择{totalGift} / {discountQuantity}件`,
                },
                {
                  totalGift,
                  discountQuantity,
                },
              )}
            </div>

            <Button onClick={onConfirm} disabled={!isGiftFit}>
              {intl.formatMessage({
                id: 'general.confirm',
                defaultMessage: '确认',
                description: '确认',
              })}
            </Button>
          </div>
        )}
      </Style>
    </NewGiftStyle>
  );
};
