import React from 'react';
import { useIntl } from 'react-intl';

const InitModule = () => {
  const intl = useIntl();
  Object.i18nIntl = intl;
  return <></>;
};

export default InitModule;
