import React from 'react';
import { FormInputField } from 'zent';
import { FormInputWrapper } from './style';
import { INPUT_SIZE, INPUT_SIZE_VALUE, INPUT_TYPE } from './const';

function HtFormInput(props) {
  const {
    size = INPUT_SIZE.large,
    showClear = true,
    placeholder,
    width,
    name,
    label,
    validators = [],
    type = INPUT_TYPE.text,
    onChange,
    className,
    maxLength,
    helpDesc,
    autoComplete = 'off',
  } = props;
  return (
    <FormInputWrapper className={className} size={INPUT_SIZE_VALUE[size] || INPUT_SIZE_VALUE.LARGE}>
      {/* <FormInputLabel>label</FormInputLabel> */}
      <FormInputField
        name={name}
        validators={validators}
        label={label}
        onChange={onChange}
        destroyOnUnmount
        helpDesc={helpDesc}
        props={{
          className: 'form-input',
          width,
          placeholder,
          showClear,
          type,
          maxLength,
          autoComplete,
          name,
        }}
      />
    </FormInputWrapper>
  );
}

export default HtFormInput;
