import loadable from '@loadable/component';
// import HeaderV2 from '../pages/design/sections/header';
// import CollectionCoverPic from '../pages/design/sections/';
// import sectionConfig from '../pages/group-buying/list/config';

export default [
  {
    path: '/group-buying/detail',
    canNotEditLayout: true,
    name: 'group-buying-product',
    canDesignPage: true,
    exact: true,
    component: loadable(() => import('../pages/group-buying/detail')),
    pageType: '拼团结果页',
  },
  {
    path: '/group-buying/product',
    canNotEditLayout: true,
    name: 'group-buying-product', // 和商品详情页
    exact: true,
    component: loadable(() => import('../pages/group-buying/product')),
    canDesignPage: true,
    pageType: '拼团商品详情页',
  },
  {
    path: '/group-buying/list',
    canNotEditLayout: true,
    name: 'group-buying-list',
    exact: true,
    component: loadable(() => import('../pages/group-buying/list')),
    pageType: '拼团商品列表页',
    canDesignPage: true,
    // ...sectionConfig,
  },
];
