import styled from 'styled-components';
import { getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  position: relative;
  .swiper-wrapper {
    display: flex;
  }
  .slide-show {
    position: relative;
    overflow: hidden;
  }
  .slide-item-text {
    width: ${getPercentWidth(240)};
  }
  .slide-img-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    background: var(--color_image_overlay);
    opacity: var(--image_overlay_opacity);
  }

  .initial {
    .swiper-slide {
      overflow: hidden;
    }
  }

  .swiper-slide {
    width: 100%;
    min-width: 100%;
    text-align: center;
    font-size: var(--font-18-14);
    /* background: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pause-control-btn {
    width: 32px;
    height: 32px;
    margin-left: 8px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(21, 22, 27, 0.5);

    .icon {
      fill: #fff;
    }
  }

  .slide-item {
    width: 100%;
    position: relative;
  }

  .slide-item-img {
    width: 100%;
    object-fit: cover;
    position: relative;
    height: ${(props) => props.slideImageHeight};
  }

  .default-img-box {
    border: none;
  }

  .img-left-top {
    object-position: left top;
  }

  .img-center-top {
    object-position: center top;
  }

  .img-right-top {
    object-position: right top;
  }

  .img-left-center {
    object-position: left center;
  }

  .img-center-center {
    object-position: center center;
  }

  .img-right-center {
    object-position: right center;
  }

  .img-left-bottom {
    object-position: left bottom;
  }

  .img-center-bottom {
    object-position: center bottom;
  }

  .img-right-bottom {
    object-position: right bottom;
  }

  .slide-item-btn {
    display: inline-flex;
    background: var(--color_button);
    color: var(--color_button_text);
    ${(props) => props.theme.getBaseSize([14, 16])};
    padding: ${(props) => props.theme.getSpace([6, 12])} ${(props) => props.theme.getSpace([16, 32])};
    border-radius: var(--border_radius_btn);
  }

  .slide-item {
    height: 100%;
  }

  .slide-navigation-bar {
    padding: 0 ${(props) => props.theme.getSpace([0, 14])};
    background: rgba(21, 22, 27, 0.5);
  }

  .slide-prev-btn,
  .slide-next-btn {
    color: #fff;
  }

  .text-center-center {
    .slide-item-text {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text-right-top,
  .text-center-top,
  .text-left-top {
    .slide-item-text {
      top: ${(props) => props.theme.getSpace([16, 80])};
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }

  .text-right-bottom,
  .text-right-center,
  .text-right-top {
    .slide-item-desc,
    .slide-item-title,
    .slide-item-btn-wrapper {
      text-align: right;
    }
  }

  .text-left-bottom,
  .text-left-center,
  .text-left-top {
    .slide-item-desc,
    .slide-item-title,
    .slide-item-btn-wrapper {
      text-align: left;
    }
  }

  .text-center-bottom,
  .text-center-bottom,
  .text-center-top {
    .slide-item-desc,
    .slide-item-title,
    .slide-item-btn-wrapper {
      text-align: center;
    }
  }

  .text-right-center,
  .text-left-center {
    .slide-item-text {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text-right-bottom,
  .text-center-bottom,
  .text-left-bottom {
    .slide-item-text {
      bottom: ${(props) => props.theme.getSpace([16, 80])};
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }

  .slide-item-text {
    max-width: calc(100% - ${(props) => props.theme.getSpace([16, 80])});
    max-height: calc(100% - ${(props) => props.theme.getSpace([16, 80])});
    overflow: hidden;
    position: absolute;
    word-wrap: break-word;
  }

  .slide-item-title {
    color: #fff;
    text-align: center;
    font-weight: bold;
  }

  .slide-item-desc {
    max-width: cal(100% - ${(props) => props.theme.getSpace([16, 80])});
    margin-top: ${(props) => props.theme.getSpace([8, 16])};
    color: #fff;
    text-align: center;
  }

  .slide-item-btn {
    margin-top: ${(props) => props.theme.getSpace([8, 16])};
  }

  .slide-indicator-dot {
    background: #fff;
    opacity: 0.6;
    &.slide-indicator-active {
      opacity: 1;
    }
  }

  .control-btn-h5 {
    display: none;
  }
  .slide-item-title {
    font-family: var(--font-stack-header) !important;
    font-style: var(--font-style-header) !important;
    font-weight: var(--font-weight-header) !important;
  }
  .slide-item-desc {
    font-family: var(--font-stack-body) !important;
    font-style: var(--font-style-body) !important;
    font-weight: var(--font-weight-body) !important;
  }
  .swiper-add {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 ${(props) => props.theme.getSpace(props.theme.pCards)};
    height: ${(props) => props.theme.getSpace([34, 52])};
    line-height: ${(props) => props.theme.getSpace([34, 52])};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    text-align: center;
  }
  .slide-item-btn-wrapper {
    margin-top: ${(props) => props.theme.getSpace(props.theme.vTextButton)};
  }
  .second-btn {
    margin-left: ${(props) => props.theme.getSpace(props.theme.hButtons)};
  }
  @media screen and (max-width: 768px) {
    .slide-item-btn-wrapper {
      .button-item + .button-item {
        margin-top: 12px;
      }
    }
  }
`;
