// 占位空组件
// 可配置项：颜色，间隔大小
import React from 'react';
import cn from 'classnames';
import { Style } from './style';

function SectionSpacing(props) {
  const {
    settings: { section_spacing: sectionSpacing, bg_color: bgColor },
    elemClass,
    className = '',
  } = props;
  return (
    <Style height={sectionSpacing} className={cn(elemClass, className)}>
      <div className='space' style={{ background: bgColor }}></div>
    </Style>
  );
}

export default SectionSpacing;
