import styled from 'styled-components';

export const Style = styled.div`
  .review-image-zoom {
    cursor: zoom-out;
  }

  .review-image-item {
    cursor: zoom-in;
  }

  .current-view {
    margin-top: 8px;
    height: 300px;
    object-fit: cover;
  }
`;
