// 对应装修的图片组合
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { DefaultImg } from 'cpn/default-img';
import { Style } from './style';
import TextPosition from '../../snippets/text-position';
import LinkButton from '../../snippets/link-button';
import { jumpToPage } from 'utils/web-tool';
import findIndex from 'lodash/findIndex';
import { getImageInfo } from 'utils/image';
import useWinSize from '../../../../hook/use-win-size';
import NewLink from 'cpn/new-link';
import { Wrapper } from 'components/base';
import { useThemeContext } from '../../../../hook/global-theme';
import Image from 'components/base/image';
import NewButton from 'components/base/new-button';
import { Background } from '../../components/background';

const FeaturedGrid = (props) => {
  const { settings = {}, blocks, block_order: blockOrder, elemClass } = props;
  const {
    title,
    grid,
    compress,
    grid_mobile: gridMobile,
    box_width: boxWidth = '100%',
    show_gap: showGap = false,
    pic_spacing: picPpacing = 0,
    bg_color: bgColor,
    background_style: backgroundStyle = 'custom_color',
    background_color: backgroundColor = 'rgba(250, 250, 250, 0)',
    background_image: backgroundImage,
  } = settings;
  const Bg = Background(backgroundStyle);
  const [blockList, setBlockList] = useState(blockOrder.map((blockId) => ({ hwRatio: '', blockId: String(blockId) })));
  const size = useWinSize();
  const themeContext = useThemeContext();
  // console.log('FeaturedGrid props', props, size);
  /**
   * 根据配置获取图片paddingTop
   * @param {*} item // 配置信息
   * @param {*} hwRatio 图片真实 高/宽 百分比
   */
  const getPaddingTop = (item, hwRatio) => {
    // const document featured-grid--item
    const {
      remain_image_proportion: remainImageProportion,
      two_block_width: twoBlockWidth,
      two_block_height: twoBlockHeight,
    } = item;
    const padding = showGap && twoBlockHeight ? 30 : 0;
    const isPaddingHalf = twoBlockWidth && !twoBlockHeight; // 高 / 宽 = 0.5
    const isPaddingTwice = !twoBlockWidth && twoBlockHeight; // 高 / 宽 = 2
    if (hwRatio && remainImageProportion) {
      // 是否保留图片真实比例
      return `${hwRatio}%`;
    }
    if (isPaddingHalf) {
      return '50%';
    } else if (isPaddingTwice) {
      return `calc(200% + ${padding}px)`;
    } else {
      return `calc(100% + ${padding}px)`;
    }
  };

  useEffect(() => {
    const newBlockList = blockOrder.map((blockId) => {
      const index = findIndex(blockList, { blockId: String(blockId) });
      if (index === -1) {
        return {
          hwRatio: '',
          blockId: String(blockId),
        };
      } else {
        return blockList[index];
      }
    });

    newBlockList.forEach(({ blockId }, index) => {
      const item = blocks[blockId]?.settings || {};
      getImageInfo(item?.image).then((imageInfo) => {
        if (imageInfo) {
          const { width, height } = imageInfo;
          const percent = (height / width) * 100;
          newBlockList[index].hwRatio = percent;
        }
        setBlockList([...newBlockList]);
      });
    });
  }, [blockOrder]);
  const hasMargin = !(!title && boxWidth === 'full_screen');
  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage} style={{ overflow: 'hidden' }}>
      <Style
        className={cn(`cnn-${boxWidth}`, 'featured-section', `grid-mobile-${gridMobile}`, elemClass)}
        boxWidth={boxWidth}
        grid={grid}
        gridMobile={gridMobile}
        id='feature-grid'
        gap={picPpacing}
        data-container='section'
      >
        <Wrapper marginTop={hasMargin ? 'vContainer' : 0} marginBottom={hasMargin ? 'vContainer' : 0}>
          {title && <div className={cn('featured-grid--title', 'section-component-title')}>{title}</div>}

          <div className={cn('feature-collection-section')}>
            {blockList.map(({ blockId, hwRatio }) => {
              const item = blocks[blockId]?.settings || {};
              const {
                sub_title: subTitle,
                link_type: linkStyle,
                overlay_color: overlayColor,
                show_text_card: showTextCard,
                card_color: cardColor,
                text_color: textColor,
                link_text: linkText,
                remain_image_proportion: remainImageProportion,
                two_block_width: twoBlockWidth,
                two_block_height: twoBlockHeight,
                text_position: textPosition,
                button_link: buttonLink,
                transition,
                image,
              } = item;
              const path = buttonLink ? JSON.parse(buttonLink)?.url : '';
              const paddingTopPer = getPaddingTop(item, hwRatio);
              const isPaddingHalf = twoBlockWidth && !twoBlockHeight;
              const isPaddingTwice = !twoBlockWidth && twoBlockHeight;
              return (
                <div
                  key={blockId}
                  className={cn(
                    'featured-grid--item',
                    { 'goods-item-row': item.two_block_height },
                    { 'goods-item-column': item.two_block_width },
                    { 'cn-link-trigger': path },
                    {
                      'hover-scale-big': transition === 'scale-big' && themeContext.isPc,
                      'hover-scale-small': transition === 'scale-small' && themeContext.isPc,
                    },
                  )}
                  onClick={() => {
                    jumpToPage(path);
                  }}
                >
                  <NewLink href={path}>
                    <div
                      className='featured-grid--item--container'
                      style={{ height: remainImageProportion ? 'auto' : '100%' }}
                    >
                      <div className='featured-grid--item--image'>
                        {!image ? (
                          <DefaultImg
                            square
                            paddingTopPer={paddingTopPer}
                            customName={cn({
                              'padding-half': isPaddingHalf,
                              'padding-twice': isPaddingTwice,
                            })}
                          />
                        ) : (
                          <div className={cn('featured-image')} style={{ paddingTop: paddingTopPer }}>
                            <Image
                              className={cn('featured-grid--image', {
                                'fit-cover': !remainImageProportion,
                                'remain-pro': remainImageProportion,
                              })}
                              alt={item.title || item.subTitle}
                              compress={compress}
                              src={image}
                            />
                          </div>
                        )}
                      </div>
                      <div className='featured-grid--item--overlay-background' style={{ background: overlayColor }} />

                      <TextPosition
                        className={cn('featured-grid--item--text-container', {
                          show_text_card: showTextCard,
                        })}
                        matchScreen
                        textPosition={textPosition}
                        style={{
                          color: textColor,
                          background: showTextCard ? cardColor : 'transparent',
                        }}
                        rDistance={size.width >= 750 ? '24px' : '16px'}
                        cDistance={size.width >= 750 ? '24px' : '16px'}
                      >
                        {item?.title && <h3 className='featured-container--title'>{item?.title}</h3>}
                        {subTitle && (
                          <div
                            className='featured-container--sub-title'
                            dangerouslySetInnerHTML={{
                              __html: subTitle || '',
                            }}
                          />
                        )}
                        <div style={{ display: 'inline-block' }}>
                          {linkText && (
                            <NewButton type={linkStyle === 'link' ? 'arrow' : 'primary'} href={path}>
                              {linkText}
                            </NewButton>
                          )}
                        </div>
                      </TextPosition>
                    </div>
                  </NewLink>
                </div>
              );
            })}
          </div>
        </Wrapper>
      </Style>
    </Bg>
  );
};

export default FeaturedGrid;
