import styled from 'styled-components';

export const Style = styled.div`
  .mobile-style,
  .pc-style {
    display: none;
  }
  /* &.cn-full_screen {
        .goods-name,
        .goods-price {
            padding: 0 ${(props) => props.theme.getSpace(props.theme.g16)};
        }
    } */

  @media screen and (max-width: 750px) {
    .link-button {
      display: flex;
      justify-content: center;
    }
    .feature-collection-section {
      flex-direction: column !important;
      .section-intro {
        text-align: center;
        margin: 0 auto;
      }
      .section-list {
        background: #f8f8f8;
      }
      .section-sub-title {
        margin-right: 0px;
      }
    }
  }
  @media screen and (min-width: 750px) {
    &.cn-center {
      .feature-collection-section {
        flex-direction: column;
        .section-intro {
          margin: 0 auto;
          text-align: center;
          margin-bottom: ${(props) => props.theme.getSpace(props.theme.vContainer)};
          .link-button {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    &.cn-right {
      .feature-collection-section {
        flex-direction: row-reverse;
        .section-intro {
          text-align: right;
          .link-button {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
    &.cn-left .section-intro {
      max-width: 25%;
      min-width: 0;
      margin-right: 24px;
      &.full-screen {
        margin-left: ${(props) => props.theme.getSpace(props.theme.hContainer)};
      }
    }
    &.cn-right .section-intro {
      max-width: 25%;
      min-width: 0;
      margin-left: 24px;
      &.full-screen {
        margin-right: ${(props) => props.theme.getSpace(props.theme.hContainer)};
      }
    }
    .pc-style {
      display: flex;
      /* flex-direction: row; */
      flex-wrap: wrap;
      /* .feature-collection-grid-item {
                margin-bottom: 20px;
                margin-right: 20px;
                width: ${(props) => `calc( (100% - ${`${(props.grid - 1) * 20}px`}) / ${props.grid}) `};
                :nth-of-type(${(props) => props.grid}n) {
                    margin-right: 0px;
                }
            } */
    }
  }

  .feature-collection-section {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    &.open-container-card {
      padding: 80px;
      background-color: ${(props) => props.cardColor};
    }
  }
  .section-intro {
    min-width: 264px;
    .shop-guide {
      margin-right: 20px;
    }
    .section-title {
      margin-bottom: ${(props) => props.theme.getSpace(props.theme.vText)};
      word-break: break-word;
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
      color: var(--color_text);
    }
    .section-sub-title {
      margin-bottom: ${(props) => props.theme.getSpace(props.theme.vTextButtons)};
      word-break: break-word;
    }
  }
  .shop-link {
    color: var(--color_button);
    text-decoration: underline;
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
  }

  .feature-collection-section {
    .section-intro {
      margin-bottom: ${(props) => props.theme.getSpace(props.theme.vContainer)};
    }
  }
`;
