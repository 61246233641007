import styled from 'styled-components';

export default styled.div`
  &.cart-item-section {
    display: flex;
    .cart-goods-item-image {
      width: 120px;
      height: 120px;
      margin-right: 16px;
      flex-shrink: 0;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    .cart-goods-item-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .goods-name {
        margin-bottom: 8px;
        font-size: var(--base-font-14-12) !important;
      }
      .cart-goods-item-info-price {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .origin-price {
          font-size: 18px;
          &.text-decoration {
            font-size: 14px;
          }
        }
      }
      .flex-item-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .delete-wrapper {
          display: inline-flex;
          align-items: center;
          gap: 16px;
        }
      }
    }
  }
`;
