import pick from 'lodash/pick';
import find from 'lodash/find';

/**
 * 把商品转换成sku级别的
 * 比如goodsList只有一项：商品A
 * 商品A有1，2，3个规格，则要返回3个元素
 * @param
 */

export const formateGoodsToSku = (goodsList) => {
  let skuList = [];
  goodsList?.map((item) => {
    const list = item?.variants?.map((sku) => {
      const titles = sku?.optionValues;
      return {
        ...pick(item, ['goodsId', 'handle', 'title', 'media']),
        ...pick(sku, ['price', 'requiresTax', 'requiresShipping', 'variantId', 'imageUrl']),
        sku: titles ? titles?.map((i) => i.title) : [],
        giftGoodsFlag: 1,
        quantity: 1,
        inventory: sku.inventory,
      };
    });
    skuList = skuList.concat(list);
  });
  return skuList.filter((item) => !!item?.quantity);
};

export const formateLineItemsList = (lineItems) => {
  return lineItems.map((item) => {
    return {
      ...pick(
        item,
        'price',
        'goodsId',
        'variantId',
        'title',
        'requiresTax',
        'requiresShipping',
        'imageUrl',
        'sku',
        'discountGoodsFlag',
        'giftGoodsFlag',
        'discountAmount',
        'hasSelected',
        'goodsStatus',
        'memberGoodsFlag',
        'memberLineItemInfoVO',
        'optionValues', // 这个主要是颜色sku 使用
        'quantity',
        'inventory',
      ),
    };
  });
};
/**
 *
 * @param list
 * @param selectedStatusList  旧的
 */
export const formatLineItemSelected = (list, selectedStatusList) => {
  const newList = list.map((item) => {
    const oldItem = find(selectedStatusList, { variantId: item?.variantId, giftGoodsFlag: item.giftGoodsFlag });
    return {
      ...item,
      hasSelected: item.hasSelected === undefined ? oldItem?.hasSelected ?? true : item.hasSelected,
    };
    // const isShowMaxTips = newItem?.quantity < item?.quantity && !newItem?.giftGoodsFlag;
    // if (newItem && !item?.giftGoodsFlag && !newItem?.giftGoodsFlag) {
    //     return { ...newItem, hasSelected: item?.hasSelected, isShowMaxTips };
    // } else {
    //     return { ...item, isShowMaxTips, discountAmount: newItem?.discountAmount };
    // }
  });

  return newList;
};

export const getFormatMultipleAutoDiscountsCart = (applyDiscountInfos, joinDiscountInfos, lineItems) => {
  // 未应用折扣的列表、以应用商品的列表、普通商品列表
  let applyDiscountInfosObj;
  let joinDiscountInfosObj;
  const noDiscountInfosList = [];

  const getApplyDiscountInfosMap = (lineItem, resObj = {}) => {
    // 获取满足折扣的折扣-商品合集合
    // const resObj = {};
    const { promotionId } = lineItem;
    applyDiscountInfos.forEach((item) => {
      if (promotionId === item.promotionId) {
        if (resObj[promotionId]) {
          resObj[promotionId].lineItems = resObj[promotionId].lineItems.concat([lineItem]);
        } else {
          resObj[promotionId] = {
            ...item,
            lineItems: [lineItem],
          };
        }
      }
    });
    return resObj;
  };

  const getJoinDiscountInfosMap = (lineItem, resObj = {}) => {
    const { joinPromotionIds } = lineItem;
    // const resObj = {};
    // 这里给Key 值 做下排序，key值 不会前后颠倒
    const joinPromotionIdsKey = joinPromotionIds?.sort()?.join('_');
    // 获取当前的所有折扣
    const activeJoinDiscountInfos = joinPromotionIds.map((joinPromotionId) => {
      return joinDiscountInfos.filter((joinDiscountInfo) => {
        return joinDiscountInfo.promotionId === joinPromotionId;
      })[0];
    });

    if (resObj[joinPromotionIdsKey]) {
      resObj[joinPromotionIdsKey] = {
        ...resObj?.[joinPromotionIdsKey],
        lineItems: resObj?.[joinPromotionIdsKey].lineItems.concat([lineItem]),
      };
    } else {
      resObj[joinPromotionIdsKey] = {
        activeJoinDiscountInfos,
        lineItems: [lineItem],
      };
    }
    return resObj;
  };

  lineItems.forEach((lineItem) => {
    const { joinPromotionIds = [], promotionId } = lineItem;
    // 非折扣商品
    if (joinPromotionIds.length === 0 && !promotionId) {
      noDiscountInfosList.push(lineItem);
    }
    // 满足折扣条件的商品
    // promotionId 这个是已满足的活动的ID 只会存在一个
    if (promotionId) {
      applyDiscountInfosObj = getApplyDiscountInfosMap(lineItem, applyDiscountInfosObj);
    }
    // 未满足折扣活动的商品
    if (joinPromotionIds.length !== 0) {
      joinDiscountInfosObj = getJoinDiscountInfosMap(lineItem, joinDiscountInfosObj);
    }
  });

  console.log('applyDiscountInfosMapList', applyDiscountInfosObj);
  console.log('joinDiscountInfosMapList', joinDiscountInfosObj);
  console.log('noDiscountInfosList', noDiscountInfosList);
  return { applyDiscountInfosObj, joinDiscountInfosObj, noDiscountInfosList };
};
