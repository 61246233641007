export default class SessionStorage {
  static setSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }

  static getSessionStorage(key) {
    return sessionStorage.getItem(key);
  }

  static removeSessionStorage(key) {
    sessionStorage.removeItem(key);
  }
}
