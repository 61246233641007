import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Notify } from 'zent';
import shopApi from 'api/shop';
import { buriedPoint } from 'utils/log-buried-point';
import LocalStorage from 'utils/local-storage';
import { WISH_COUNT_CHANGE } from 'utils/local-storage/config';
import { useRootContext } from 'client/pages/context/root';

export const useWish = ({ kdtId, customerId, goodsId, wishList, data = {} }) => {
  const { isWishList } = wishList || {};

  const [wishListId, setWishListId] = useState(wishList?.wishListId || '');
  const { setLoginDialogInfo } = useRootContext();
  useEffect(() => {
    setWishListId(wishList?.wishListId);
  }, [wishList]);

  const intl = useIntl();
  const [wishStatus, setWishStatus] = useState(false);
  const [wishActionLoading, setWishActionLoading] = useState(false);

  const handleChangeWishCount = (type) => {
    const wishChangeCount = LocalStorage.get(WISH_COUNT_CHANGE) || 0;
    if (type === 'plus') {
      LocalStorage.set(WISH_COUNT_CHANGE, wishChangeCount + 1);
    } else {
      LocalStorage.set(WISH_COUNT_CHANGE, wishChangeCount - 1);
    }
  };

  const addToWishList = () => {
    const params = { customerId, goodsId, kdtId };
    shopApi
      .addToWishList(params)
      .then((res) => {
        setWishStatus(true);
        Notify.success(
          intl.formatMessage({
            defaultMessage: '已添加至心愿单',
            id: 'has.add.to_wish',
          }),
          1000,
        );
        setWishListId(res?.wishListId);
        handleChangeWishCount('plus');
        buriedPoint(
          'product_add_to_wishlist',
          {
            source: '',
            ...data,
          },
          {
            name: '加心愿单',
            eventType: 'click',
          },
        );
      })
      .catch((res) => {
        Notify.error(res.message);
      })
      .finally(() => {
        setWishActionLoading(false);
      });
  };

  const deleteWishGoods = () => {
    shopApi
      .deleteWishGoods({
        customerId,
        wishListId,
        goodsId,
      })
      .then(() => {
        Notify.success(
          intl.formatMessage({
            defaultMessage: '已取消收藏',
            id: 'has.cancel.in_wish',
          }),
          1000,
        );
        setWishStatus(false);
        handleChangeWishCount('reduce');
      })
      .catch((res) => {
        Notify.error(res.message);
      })
      .finally(() => {
        setWishActionLoading(false);
      });
  };

  /** 心愿操作
   * 添加到心愿单或者从心愿单删除
   * */
  const handleChangeWishStatus = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (wishActionLoading) return;
    if (!customerId) {
      setLoginDialogInfo({
        visible: true,
      });
      setWishActionLoading(false);
      return;
    }

    setWishActionLoading(true);

    if (wishStatus) {
      deleteWishGoods();
    } else {
      addToWishList();
    }
  };

  useEffect(() => {
    setWishStatus(!!isWishList);
  }, [isWishList]);

  return {
    wishStatus,
    wishActionLoading,
    handleChangeWishStatus,
  };
};
