import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from './load-third-javascript';

export const loadFacebookPixel = (id) => {
  const code = `
    !function(f,b,e,v,n,t,s)
       {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
       n.callMethod.apply(n,arguments):n.queue.push(arguments)};
       if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${id}');
        window.fbPixelId='${id}';
        fbq('track', 'PageView');
    `;
  // 禁用 script代码
  // <noscript>
  // <img height="1" width="1" style="display:none"
  // src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"/>
  // </noscript>

  loadThirdJavascript(THIRD_JAVASCRIPT_CONFIG.FACEBOOK_PIXEL.name, undefined, true, {}, code);
};
