import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import Style from './style';
// import { getSeoConfig } from './seo';
// import ShopApi from '../../api/shop';
import { SectionsMap, globalConfigOfSectionMap } from '../design/config/sections_map';

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

const Custom = (props) => {
  const { initialData = {}, history, location } = props;
  const { pageData, kdtId, themeCustom, themeData: theme } = initialData;
  const { search } = location;
  const [data, setPageData] = useState(pageData);

  // const getData = async (query) => {
  //     const { pageData: newData } = await Custom.getInitialProps(query);
  //     setPageData(newData);
  // };
  // useEffect(() => {
  //     const customId = getQueryVariable('id');
  //     getData({ kdtId, id: customId });
  // }, [search]);

  const domain = get(initialData, 'shopInfo.primaryDomain', '');

  const getMediaStatus = () => {
    const { share_facebook: shareFacebook, share_twitter: shareTwitter, share_pinterest: sharePinterest } =
      theme?.current || {};

    return {
      shareFacebook,
      shareTwitter,
      sharePinterest,
    };
  };

  let contentForIndex = get(themeCustom, 'current.content_for_index', []);
  const sections = get(themeCustom, 'current.sections', {});
  const { cart_enable_ajax: cartEnableAjax = true } = get(theme, 'current', {});
  const { getValueAfterFormate } = globalConfigOfSectionMap.footer || {};
  const socialLinkArrFooter = isFunction(getValueAfterFormate) ? getValueAfterFormate(theme) : [];

  // 经常出现脏数据导致两个header  header footer 等都是layout渲染的
  contentForIndex = contentForIndex.filter((_) => {
    return !['site-select', 'announcement-bar', 'header', 'footer'].includes(_);
  });

  return (
    <Style>
      {!pageData?.cdnUrl && (
        <div className='custom-page' id='custom-page'>
          <h2 className='custom-page-title'>{data?.pageTitle}</h2>
          <div
            className='custom-page-content'
            dangerouslySetInnerHTML={{
              __html: data?.descriptionHtml,
            }}
          />
        </div>
      )}
      {contentForIndex.map((hashId) => {
        const item = sections[hashId];
        const Elem = SectionsMap[item?.type]?.component;
        const socialLinkArr = isFunction(getValueAfterFormate) ? getValueAfterFormate(theme) : [];
        const ElemWithProps = (
          <Elem
            {...item}
            theme={theme}
            key={hashId}
            domain={domain}
            history={history}
            initialData={initialData}
            elemClass={`section-${hashId}`}
            cartEnableAjax={cartEnableAjax}
            mediaStatus={getMediaStatus()}
            socialLinkArr={socialLinkArr}
            socialLinkArrFooter={socialLinkArrFooter}
          />
        );
        return !item?.disabled && Elem ? ElemWithProps : null;
      })}
    </Style>
  );
};

export default Custom;
