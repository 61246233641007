import React, { useEffect, useMemo } from 'react';
import { DiscountType } from 'const/discount';
import Style from './style';
import { DiscountTag } from '../discount-tag';
import isEmpty from 'lodash/isEmpty';

/**
 * 划线价标签
 * */

interface DashDiscountLabelProps {
  className?: string;
  price?: number;
  compareAtPrice?: number;
  showDashDiscountLabel?: boolean;
  bordered?: boolean;
  discountInfo?: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChangeShowDashDiscountLabel?: Function;
}

export const DashDiscountLabel = (props: DashDiscountLabelProps) => {
  const {
    className,
    price = 0,
    compareAtPrice = 0,
    showDashDiscountLabel,
    bordered = false,
    onChangeShowDashDiscountLabel,
    discountInfo,
  } = props;

  const dashDiscountLabelConfig = useMemo(() => {
    if (
      !isEmpty(discountInfo) ||
      !showDashDiscountLabel ||
      !compareAtPrice ||
      compareAtPrice === price ||
      compareAtPrice < price
    ) {
      return {
        showDashDiscountLabel: false,
        dashDiscountValue: 0,
      };
    }
    const dashDiscountValue = ((+compareAtPrice - Number(price)) / Number(compareAtPrice)) * 100 || 0;
    return {
      showDashDiscountLabel: true,
      dashDiscountValue: Math.floor(dashDiscountValue),
    };
  }, [price, compareAtPrice, showDashDiscountLabel, discountInfo]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    onChangeShowDashDiscountLabel && onChangeShowDashDiscountLabel(dashDiscountLabelConfig.showDashDiscountLabel);
  }, [dashDiscountLabelConfig.showDashDiscountLabel]);

  if (!dashDiscountLabelConfig.showDashDiscountLabel) return null;

  return (
    <Style className={className}>
      <DiscountTag
        discountType={DiscountType.Percentage}
        discountValue={dashDiscountLabelConfig.dashDiscountValue}
        bordered={bordered}
      />
    </Style>
  );
};
