import styled from 'styled-components';

export const Style = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .wish-text {
    ${(props) => props.theme.getBaseSize(props.theme.h11)};
    padding-left: 10px;
    color: var(--color_body_text);
  }
  /* 未添加 */
  .wish-wait {
    cursor: wait;
  }
  /* 已添加 */
  .wish-point {
    cursor: pointer;
  }
  &.wish-status-true {
    .goods-detail-icon {
      fill: var(--color_button);
    }
  }

  .goods-detail-icon {
    width: 20px;
    height: 20px;
    fill: var(--color_body_text);
  }
`;
