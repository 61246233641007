// 单个图文叠加
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { DefaultImg } from 'cpn/default-img';
import { Style } from './style';
import isObject from 'lodash/isObject';
import TextPosition from '../../snippets/text-position';
import NewLink from 'cpn/new-link';
import { useThemeContext } from '../../../../hook/global-theme';
import Image from 'components/base/image';
import useFillImage from 'hook/use-fill-image';
import NewButton from 'components/base/new-button';

const AlignMent = {
  top: 'align-top',
  center: 'align-center',
  bottom: 'align-bottom',
};

const HeroSize = {
  'x-small': 'size-x-small',
  small: 'size-small',
  medium: 'size-medium',
  large: 'size-large',
  'x-large': 'size-x-large',
};

const TextSize = {
  medium: 'text-medium',
  large: 'text-large',
};

function filterText(str) {
  if (str === '<p>&nbsp;</p>') {
    return null;
  }
  return str;
}

const Hero = ({ settings, elemClass, componentId }) => {
  const {
    alignment = 'top',
    hero_size: imgSize = 'x-small',
    hero_layout: heroLayout = 'full_width',
    text_size: textSize = 'medium',
    button_link: buttonLink,
    button_label: buttonLabel,
    title,
    show_card: showCard = false,
    color_card: colorCard = '#fff',
    text_color: textColor = 'rgba(255,255,255,1)',
    text_position: textPosition = 'center center',
    show_overlay: showOverlay = false,
    pic_link: picLink = '',
    compress = true,
  } = settings;

  const text = filterText(settings.text);

  const [textContainerHeight, setTextContainerHeight] = useState(0);

  const { image } = useFillImage(settings?.image, 'origin', {
    toWebp: true,
    compress,
  });

  const themeContext = useThemeContext();
  // 兼容旧数据， settings中有取settings中的，没有，则默认是false
  let picParallaxEffect = false;
  // eslint-disable-next-line no-prototype-builtins
  if (settings.hasOwnProperty('pic_parallax_effect')) {
    picParallaxEffect = settings.pic_parallax_effect;
  }
  // 移动端ios不支持background-attachment
  if (themeContext.isMobile) {
    picParallaxEffect = false;
  }
  // 图片高度自适应就不用attament
  if (imgSize === 'adapt') {
    picParallaxEffect = false;
  }
  const linkUrl = JSON.parse(buttonLink || null)?.url;
  const layoutClassName = {
    full_width: 'cn-full_screen',
    fix_960: 'cn-fix_960',
    fixed_width: 'cn-fix_1280',
    margin_80: 'cn-margin_80',
  };
  useEffect(() => {
    const dom = document.getElementsByClassName(`${componentId}-text`);
    if (themeContext.isMobile) {
      const textHeight = dom?.[0]?.clientHeight;
      setTextContainerHeight(textHeight ? textHeight + 32 : textHeight);
    }
  }, [themeContext.isMobile, title, text, buttonLabel]);
  return (
    <Style className={cn('hero-section', elemClass, layoutClassName[heroLayout])}>
      <div
        className={cn('hero', HeroSize[imgSize], AlignMent[alignment], TextSize[textSize], {
          attachment: picParallaxEffect,
        })}
        style={{
          backgroundImage: imgSize === 'adapt' ? null : `url(${image})`,
          height: themeContext.isMobile && textContainerHeight ? textContainerHeight : '',
        }}
      >
        <NewLink href={picLink} target='_self'>
          {/* 图片高度自适应就不要用背景图 */}
          {image && imgSize === 'adapt' && (
            <Image className='hero-img' src={image} alt={title || text} compress={compress} />
          )}
          {/* 装修的刚拖进来没有图片，展示默认图 */}
          {!image && <DefaultImg width='auto' height='41%' />}

          {showOverlay && <div className='hero-img-mask' />}
        </NewLink>
        <TextPosition
          className={showCard ? `hero-text show-pc ${componentId}-text` : `hero-text ${componentId}-text`}
          textPosition={textPosition}
          style={{
            color: textColor,
            backgroundColor: showCard ? `${colorCard}` : 'transparent',
            padding: showCard ? '25px' : 0,
          }}
        >
          {!isObject(title) && <h3 className='hero-title'>{isObject(title) ? '' : title}</h3>}
          {text && (
            <div
              className='hero-desc'
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          )}
          {buttonLabel ? (
            <NewButton type='primary' href={linkUrl}>
              {buttonLabel}
            </NewButton>
          ) : null}
        </TextPosition>
      </div>
    </Style>
  );
};

export default Hero;
