import React from 'react';
import { getUrl } from 'utils/formate';
import Style from './style';

export const HeaderAnnouncement = (props) => {
  const { showAnnouncement, colorBg, colorText, announcementText, announcementLink = {} } = props;

  const formateAnnouncementUrl = () => {
    let announcementUrl = '';
    try {
      announcementUrl = announcementLink ? getUrl(JSON.parse(announcementLink).url) : announcementLink;
    } catch (error) {
      console.log(error);
    }
    return announcementUrl;
  };
  return (
    <Style>
      {showAnnouncement && (
        <div
          className='header-announcement'
          style={{
            background: colorBg,
          }}
        >
          <a
            style={{
              color: colorText,
            }}
            className='announcement_link'
            href={formateAnnouncementUrl || '#'}
          >
            {announcementText}
          </a>
        </div>
      )}
    </Style>
  );
};
