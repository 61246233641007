/**
 *
 * @param name 引入第三方资源的名字（挂载在window下的全局属性）
 * @param url  资源地址
 * @param async 是否异步
 */
const loadThirdJavascript = (name, url, async = true, attr = {}, code = '') => {
  return new Promise((resolve, reject) => {
    if (window[name] === undefined) {
      const scrElement = document.createElement('script');
      if (url) scrElement.src = url;
      if (code) {
        scrElement.innerText = code;
      }
      scrElement.async = async;
      // eslint-disable-next-line array-callback-return
      Object.keys(attr).map((key) => {
        scrElement[key] = attr[key];
      });
      document.head.appendChild(scrElement);
      scrElement.onload = () => {
        resolve();
      };
      scrElement.onerror = (err) => {
        reject(err);
      };
    } else {
      resolve();
    }
  });
};

/**
 * 配置项 项目动态引入的资源汇总
 */
const THIRD_JAVASCRIPT_CONFIG = {
  STRIPE: { name: 'Stripe', url: 'https://js.stripe.com/v3/' },
  FACEBOOK_LOGIN: { name: 'FB', url: 'https://connect.facebook.net/en_US/sdk.js' },
  GOOGLE_ANALYTICS: { name: 'dataLayer', url: 'https://www.googletagmanager.com/gtag/js' },
  FACEBOOK_MESSAGE: { name: 'FB', url: 'https://connect.facebook.net/{lang}/sdk/xfbml.customerchat.js' },
  TIKTOK_PIXEL: { name: 'ttp', url: 'code' },
  FACEBOOK_PIXEL: { name: 'fbq', url: 'code' },
  GOOGLE_LOGIN: { name: 'gapi', url: 'https://accounts.google.com/gsi/client' },
  PAYPAL: { name: 'paypal', url: 'https://www.paypal.com/sdk/js?intent=authorize&client-id=' },
  ZAN_WEB_TRACKER: { name: 'ZanTracker', url: 'https://b.yzcdn.cn/zan-web-tracker/zan-web-tracker-1.1.13-min.js' },
  DRAGGABLE: {
    name: 'Draggable',
    url: 'https://cdn.jsdelivr.net/npm/@shopify/draggable@1.0.0-beta.13/lib/draggable.bundle.legacy.js',
  },
  VCONSOLE: { name: 'VConsole', url: 'https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.1/vconsole.min.js' },
  CLOVER: {
    name: 'Clover',
    devUrl: 'https://checkout.sandbox.dev.clover.com/sdk.js',
    url: 'https://checkout.clover.com/sdk.js',
  },
  TCaptcha: { name: 'captcha', url: 'https://turing.captcha.qcloud.com/TCaptcha.js' },
};

export { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG };
