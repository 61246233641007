import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { getDiscountDesc } from 'helper/discount';
import { DiscountTag } from '../discount-tag';
import HuntPrompt from '../../hunt-prompt';
import RootContext from '../../../context/root';
import { DescStyle } from './style';

/** 折扣条件限制 */
enum DiscountLimitType {
  Amount = 'amount', // 金额
  Quantity = 'quantity', // 数量
}

/**
 * 折扣描述
 * */
export const DiscountDesc = (props) => {
  const intl = useIntl();
  const {
    discountInfo = {},
    currency,
    className,
    onLink,
    title = '',
    linkText = intl.formatMessage({
      description: '详情',
      id: 'common_detail',
    }),
    showTag = true,
    showLink = false,
    showIcon = false,
    discountGoods = [],
    requireType,
  } = props;
  const { initialData, language } = useContext(RootContext);
  const { multiCurrencyInfo } = initialData;
  const { discountGets = {}, discountType } = discountInfo;
  const { discountValue = 10 } = discountGets;
  const discountDesc = discountType
    ? getDiscountDesc({ discountInfo, discountGoods, currency, intl, language, requireType, multiCurrencyInfo })
    : '';
  if (!discountType) return null;
  return (
    <HuntPrompt
      className={className}
      title={title}
      desc={
        <DescStyle className='discount-tag-desc'>
          {showIcon && (
            <svg aria-hidden='true' width={16} height={16} className='icon'>
              <use xlinkHref='#iconicon_gift' />
            </svg>
          )}
          {showTag && <DiscountTag discountType={discountType} discountValue={discountValue} language={language} />}
          <div className='desc'>{discountDesc}</div>
        </DescStyle>
      }
      onLink={onLink}
      linkText={linkText}
      showLink={showLink}
    />
  );
};
