import styled from 'styled-components';

export const Style = styled.div`
  &.goods-button {
    width: 100%;
    height: 46px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    padding: 12px 16px;
    box-sizing: border-box;
    background: transparent;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    border-radius: 2px;
    flex: 1;
    border-radius: var(--border_radius_btn);
  }
  &.goods-button-primary {
    background-color: var(--color_button);
    color: var(--color_button_text);
    border: none;
    &.button-active:active {
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorButtonText, 1.2)};
      background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorButton, 1.2)};
    }
  }
  &.goods-button-second {
    color: var(--color_small_button_text_border);
    border: 1px solid var(--color_small_button_text_border);
    background-color: var(--color_small_button_bg);
    &.button-active:active {
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorSmallButtonTextBorder, 1.2)};
      border-color: 1px solid ${(props) => props.theme.hexToRGBA(props.theme.colorSmallButtonTextBorder, 1.2)};
      background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorSmallButtonBg, 1.2)};
    }
  }
  &.goods-button-large {
    height: ${(props) => props.theme.getSpace([39, 42, 46])};
    font-size: ${(props) => props.theme.getSpace([13, 14, 16])};
    padding: ${(props) => props.theme.getSpace([10, 11, 12])} ${(props) => props.theme.getSpace([10, 11, 12])};
  }
  &.goods-button-medium {
    height: ${(props) => props.theme.getSpace([32, 37, 40])};
    font-size: ${(props) => props.theme.getSpace([12, 13, 14])};
    padding: ${(props) => props.theme.getSpace([8, 9, 10])} ${(props) => props.theme.getSpace([8, 9, 10])};
  }
  &.goods-button-small {
    height: ${(props) => props.theme.getSpace([27, 29, 34])};
    font-size: ${(props) => props.theme.getSpace([11, 11, 12])};
    padding: ${(props) => props.theme.getSpace([6, 7, 8])} ${(props) => props.theme.getSpace([6, 7, 8])};
  }
  &.button-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &.button-hover:hover {
    opacity: 0.8;
  }
`;
