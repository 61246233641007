import React from 'react';
import cn from 'classnames';
import NewButton from 'components/base/new-button';
import { Text } from 'components/base';
import { Style } from './style';
import { Background } from '../../components/background';
import TextPosition from '../../snippets/text-position';
import NewLink from 'cpn/new-link';

function Hero(props) {
  const {
    settings: {
      background_style: backgroundStyle,
      background_color: backgroundColor,
      background_image: backgroundImage,
      pic_link: picLink,
      title,
      text,
      title_size: titleSize,
      text_size: textSize,
      title_color: titleColor,
      text_color: textColor,
      btn_title: btnTitle,
      button_link: buttonLink,
      btn_type: btnType,
      color_button_bg: colorButtonBg,
      color_button_text: colorButtonText,
      text_position: textPosition = 'center center',
    },
    elemClass,
    style = {},
  } = props;
  const Bg = Background(backgroundStyle);

  return (
    <Style className={cn(elemClass)} style={style}>
      <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage} style={{ height: '100%' }}>
        <div className='content'>
          <NewLink href={picLink || undefined} style={{ height: '100%', position: 'relative' }}>
            <TextPosition textPosition={textPosition}>
              <Text as='div' color={titleColor} size={titleSize} className='title'>
                {title}
              </Text>

              <Text as='div' color={textColor} size={textSize} className='text'>
                {text && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                )}
              </Text>

              {/* 比较尴尬如果不加个wrapper 宽度100% */}
              <div>
                <NewButton bgColor={colorButtonBg} type={btnType} color={colorButtonText} href={buttonLink}>
                  {btnTitle}
                </NewButton>
              </div>
            </TextPosition>
          </NewLink>
        </div>
      </Bg>
    </Style>
  );
}

export default Hero;
