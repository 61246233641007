import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Style } from './style';
import { Icon } from 'components/base';
import cn from 'classnames';
import MobileMenuContent from '../mobile-menu-content';
import { parseSettings } from '../../utils';
import cloneDeep from 'lodash/cloneDeep';
import NewLink from 'cpn/new-link';
import { useIntl } from 'react-intl';

export const SecondMenuGroup = (props) => {
  const { block_order: blockOrder = [], blocks = {}, settings } = props;
  return (
    <div className='menu-second'>
      {blockOrder.map((blockId) => {
        const blockItem = blocks[blockId];
        const secondMenuItem = parseSettings(blockItem.settings);
        return (
          <div className='menu-second-item' key={blockId}>
            <div className='menu-second-item-title'>
              <NewLink href={secondMenuItem.path} target={secondMenuItem.openTarget}>
                {secondMenuItem.title}
              </NewLink>
            </div>
            <div className='menu-second-item-content'>
              {blockItem.block_order?.length > 0 && (
                <Icon className='menu-icon' name='iconic-xiala' size='medium'></Icon>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ThirdMenuGroup = (props) => {
  const {
    block_order: blockOrder = [],
    blocks = {},
    settings,
    blockTwoId,
    activeMap,
    handleSecondMenuItemClick,
    submenuTextColor,
  } = props;
  const secondMenuItem = parseSettings(settings);
  return secondMenuItem.title ? (
    <div className='menu-second'>
      <div className='menu-second-item' onClick={() => handleSecondMenuItemClick(blockTwoId)}>
        <div className='menu-second-item-title'>
          <NewLink href={secondMenuItem.path} target={secondMenuItem.openTarget}>
            {secondMenuItem.title}
          </NewLink>
        </div>
        {blockOrder?.length > 0 && (
          <Icon
            className={cn('menu-icon', {
              rotate: activeMap[blockTwoId],
            })}
            color={submenuTextColor}
            name='iconic-xiala'
            size='medium'
          ></Icon>
        )}
      </div>
      <div
        className={cn('menu-third', {
          active: activeMap[blockTwoId],
        })}
      >
        {blockOrder.map((blockId) => {
          const blockItem = blocks[blockId];
          if (typeof blockItem === 'undefined') return null;
          const thirdMenuItem = parseSettings(blockItem.settings);
          return (
            <div className='menu-third-item' key={blockId}>
              <NewLink href={thirdMenuItem.path} target={thirdMenuItem.openTarget}>
                {thirdMenuItem.title}
              </NewLink>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

function MobileMenu(props) {
  const intl = useIntl();
  const {
    settings: {
      text_color: textColor,
      nav_padding: navPadding,
      nav_width: navWidth,
      hover_text_color: hoverTextColor,
      card_color: cardColor,
      submenu_hover_text_color: submenuHoverTextColor,
      submenu_text_color: submenuTextColor,
      submenu_card_color: submenuCardColor,
    },
    elemClass,
    block_order: blockOrder,
    blocks,
  } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [menuContentTop, setMenuContentTop] = useState(0);
  const [activeMap, setActiveMap] = useState({});
  const [activeFirstMenuId, setActiveFirstMenuId] = useState('');

  const handleSecondMenuItemClick = (blockId) => {
    const newActiveMap = cloneDeep(activeMap);
    if (!newActiveMap[blockId]) {
      newActiveMap[blockId] = 1;
    } else {
      delete newActiveMap[blockId];
    }
    setActiveMap(newActiveMap);
  };

  const handleFirstMenuItemClick = (blockId) => {
    if (blockId === activeFirstMenuId) {
      // 点击了相同的menu
      setActiveFirstMenuId('');
    } else {
      setActiveFirstMenuId(blockId);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  }, [showMenu]);

  const getFirstMenuTitle = (blockItem) => {
    return blockItem.block_order?.map((item) => {
      const linkItem = blockItem.blocks[item];
      if (!linkItem) return null;
      const settings = parseSettings(linkItem.settings);
      return linkItem.type === 'link_text' ? (
        <a className='seperate'>{settings.title}</a>
      ) : (
        <NewLink href={settings.path} target={settings.openTarget}>
          {settings.title}
        </NewLink>
      );
    });
  };

  const showMenuContent = () => {
    // 先计算top值
    let distance = 0;
    try {
      const { y } = document.querySelector('.design-mobile-header').getBoundingClientRect();
      distance = 64 + y;
      distance = distance < 0 ? 0 : distance;
    } catch (err) {
      console.log(err);
    }
    setShowMenu(true);
    setMenuContentTop(distance);
  };

  return (
    <Style textColor={textColor} cardColor={cardColor} style={props.style}>
      <div className='menu-trigger' onClick={() => showMenuContent()}>
        <div className='menu-trigger-title'>
          {intl.formatMessage({
            id: '6ac06c82ac974d7f8b30c165c51192b8',
            defaultMessage: '类目',
          })}
        </div>
        <Icon className='menu-icon' color={textColor} name='iconic-xiala' size='medium'></Icon>
      </div>
      {createPortal(
        <MobileMenuContent
          intl={intl}
          textColor={textColor}
          submenuTextColor={submenuTextColor}
          cardColor={cardColor}
          submenuCardColor={submenuCardColor}
          menuContentTop={menuContentTop}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          blocks={blocks}
          blockOrder={blockOrder}
          getFirstMenuTitle={getFirstMenuTitle}
          handleSecondMenuItemClick={handleSecondMenuItemClick}
          handleFirstMenuItemClick={handleFirstMenuItemClick}
          activeFirstMenuId={activeFirstMenuId}
          activeMap={activeMap}
        />,
        document.querySelector('.h5-preview-content'),
      )}
    </Style>
  );
}

export default MobileMenu;
