export enum TabType {
  Account = 'account',
  Order = 'order',
  Coupon = 'coupon',
  Wish = 'wish',
  Address = 'address',
  Notify = 'notify',
  Safety = 'safety',
  Affiliate = 'affiliate',
  Points = 'points',
}

export const ACCOUNT_TYPE_MAP = {
  email: 'email',
  phone: 'phone',
};

export const STEP_ONE = 1;
export const STEP_TWO = 2;

export const SCENE_TYPE = {
  register: 'register',
  login: 'login',
  bind: 'bind',
  reset_pwd: 'reset_pwd',
};

export enum IdentityList {
  General = 'general',
  Affiliate = 'affiliate',
  B2b = 'b2b',
  Merber = 'merber',
}

export const CHANNEL_CODE = {
  XHS_MINI_APP: 'xhs_mini_app',
  WECHAT_MINI_APP: 'miniprogram',
  GOOGLE: 'google',
  LINE: 'line',
  FACEBOOK: 'facebook',
};

export const SOCIAL_MEDIA_LOGO = {
  [CHANNEL_CODE.XHS_MINI_APP]: 'https://intl-image.yzcdn.cn/images/public_files/168abce51c64c0f2eb5ef387fd99ecc1.png',
  [CHANNEL_CODE.WECHAT_MINI_APP]:
    'https://intl-image.yzcdn.cn/images/public_files/db9a6a1eb15a144db1837e0884b60976.png',
  [CHANNEL_CODE.GOOGLE]: 'https://intl-image.yzcdn.cn/images/public_files/4e32bc16063518e1abce44959bd6f9aa.png',
  [CHANNEL_CODE.FACEBOOK]: 'https://intl-image.yzcdn.cn/images/public_files/2b19fefbc262acfbbd1e077bc3d50a92.png',
  [CHANNEL_CODE.LINE]: 'https://intl-image.yzcdn.cn/images/public_files/0d2dad09d3b1785708522ba442065e99.png',
};
