import styled from 'styled-components';

export const Style = styled.div`
  display: inline-flex;
  align-items: center;
  .star-icon {
    width: ${(props) => `${props.scoreSize}px`};
    height: ${(props) => `${props.scoreSize}px`};
    /* margin-right: 4px; */
    fill: #ffc700;
  }
  .half-star-container {
    width: ${(props) => `${props.scoreSize}px`};
    height: ${(props) => `${props.scoreSize}px`};
    position: relative;
    /* margin-right: 4px; */
    .half-star-icon_left {
      width: ${(props) => `${props.scoreSize}px`};
      height: ${(props) => `${props.scoreSize}px`};
      fill: #ffc700;
      position: absolute;
      top: 0;
      left: 0;
    }
    .half-star-icon_right {
      width: ${(props) => `${props.scoreSize}px`};
      height: ${(props) => `${props.scoreSize}px`};
      fill: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.08)};
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate3d(0, 1, 0, 180deg);
    }
  }
  .half-star-icon_left {
    width: ${(props) => `${props.scoreSize}px`};
    height: ${(props) => `${props.scoreSize}px`};
    /* margin-right: 4px; */
    fill: #ffc700;
  }
  .grey-star-icon {
    width: ${(props) => `${props.scoreSize}px`};
    height: ${(props) => `${props.scoreSize}px`};
    /* margin-right: 4px; */
    fill: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.08)};
  }
`;
