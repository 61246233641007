// 对应装修富文本
import React from 'react';
import cn from 'classnames';
import { Wrapper } from 'components/base';
import { Style } from './style';
import { Text } from 'components/base';
import { Background } from '../../components/background';
const TextSize = {
  small: 'text-size-small',
  medium: 'text-size-medium',
  large: 'text-size-large',
};

const FeaturedContent = ({ settings = {}, elemClass }) => {
  const {
    section_title: sectionTitle,
    text,
    background_style: backgroundStyle = 'null',
    background_color: backgroundColor,
    background_image: backgroundImage,
  } = settings;
  let textSize = settings.text_size || 7;
  let fullWidth = settings.full_width;
  let boxWidth = settings.box_width;

  if (typeof textSize === 'string') {
    const sizeMap = {
      medium: 7,
      small: 10,
      large: 5,
    };
    textSize = sizeMap[textSize];
  }
  if (!boxWidth && typeof fullWidth === 'boolean') {
    const fullWidthMap = {
      true: 'margin_80',
      false: 'fix_960',
    };
    boxWidth = fullWidthMap[fullWidth];
  }

  const Bg = Background(backgroundStyle);

  return (
    <Style className={cn('featured-content-section', elemClass)}>
      <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage} style={{ height: '100%' }}>
        <Wrapper paddingTop='vContainer' paddingBottom='vContainer' className={`cn-${boxWidth}`}>
          <div className={cn('featured-content')}>
            <Text as='h3' size={textSize} className='title'>
              {sectionTitle}
            </Text>
            <div
              className='featured-content-desc'
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </div>
        </Wrapper>
      </Bg>
    </Style>
  );
};

export default FeaturedContent;
