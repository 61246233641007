import { GridLayoutStyle, GridItemStyle } from './style';
import cn from 'classnames';
import React from 'react';

export const getFixedWidth = (pWidth, row, gap) => {
  return `calc((${pWidth} - ${(row - 1) * gap}px) / ${row})`;
};

export const GridLayout = (props) => {
  const { width = '100%', className } = props;
  return (
    <GridLayoutStyle style={{ width }} className={className}>
      {props.children}
    </GridLayoutStyle>
  );
};
/**
 * row 一行几个
 * gap 间距
 * @param {*} param0
 */
export const GridItem = (props) => {
  const {
    pWidth = '100%',
    pGrid,
    pGap = 24,
    dGrid,
    dGap = 12,
    mGrid = 2,
    mGap = 8,
    pTop = 0,
    dTop = 0,
    mTop = 0,
    className,
    pBottom = 40,
    dBottom = 24,
    mBottom = 16,
  } = props;
  const pcWidth = getFixedWidth(pWidth, pGrid, pGap);
  const padWidth = getFixedWidth(pWidth, dGrid, dGap);
  const mobileWidth = getFixedWidth(pWidth, mGrid, mGap);

  return (
    <GridItemStyle
      className={cn(className, 'grid-item')}
      pcWidth={pcWidth}
      pGrid={pGrid}
      pGap={pGap}
      pTop={pTop}
      padWidth={padWidth}
      dGrid={dGrid}
      dGap={dGap}
      dTop={dTop}
      mobileWidth={mobileWidth}
      mGrid={mGrid}
      mGap={mGap}
      mTop={mTop}
      pBottom={pBottom}
      dBottom={dBottom}
      mBottom={mBottom}
    >
      {props.children}
    </GridItemStyle>
  );
};
