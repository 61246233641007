import React, { useMemo } from 'react';
import useGoodsImage from '../../../../hook/use-goods-image';
import Style from './style';
import { DefaultImg } from '../../../components/default-img';
import cn from 'classnames';

import Image from 'components/base/image';
import useFillImage from 'hook/use-fill-image';

/**
 * 展示商品图片
 */
function GoodsImage({
  goodsInfo,
  imageRatio = '',
  isCut = true.valueOf,
  hoverShowSecondPic = false,
  children,
  className = '',
  imageFillType = 'cover',
  skuImgUrl = '',
  defaultImageIsSquare = true,
}) {
  const { coverImgUrl = '', hoverImgUrl = '' } = useGoodsImage(goodsInfo);
  const { title, handle } = goodsInfo || {};
  const { image } = useFillImage(hoverImgUrl);

  const heightPercent = useMemo(() => {
    if (imageRatio) {
      // 兼容商品分组3.0旧数据
      if (imageRatio.includes('%')) {
        return imageRatio;
      }
      if (imageRatio !== 'auto' && imageRatio !== 'adapt') {
        const arr =
          imageRatio.indexOf('-') !== -1 ? imageRatio.split('-').map(Number) : imageRatio.split('/').map(Number);
        return `${(arr[1] / arr[0]) * 100}%`;
      }
    }
    return '100%';
  }, [imageRatio]);

  return (
    <Style
      className={cn(
        className,
        { 'hover-show': image && hoverShowSecondPic },
        { 'customer-ratio': imageRatio !== 'auto' && imageRatio !== 'adapt' },
        `image-fill-type-${imageFillType}`,
      )}
      heightPercent={heightPercent}
      isCut={isCut}
    >
      {coverImgUrl ? (
        <Image className='goods-img' src={skuImgUrl || coverImgUrl} alt={title || handle} />
      ) : (
        <DefaultImg
          customName='goods-default-img'
          square={defaultImageIsSquare}
          width='41%'
          height='41%'
          paddingTopPer={heightPercent}
        />
      )}
      {image && <div className='hover-img' style={{ backgroundImage: `url(${image})` }} />}
      {children}
    </Style>
  );
}

export default GoodsImage;
