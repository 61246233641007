import styled from 'styled-components';
import { getSizeVw } from '../../../../../../style/fn';

export default styled.div`
  display: flex;
  align-items: center;
  .logo {
    max-width: 225px;
    margin-right: ${getSizeVw(48)};
    max-height: 30px;
    object-fit: cover;
  }
  .shop-name {
    color: var(--color_text);
    font-size: var(--base-header-font-16-14);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    font-weight: 300;
    color: var(--color_text);
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    padding: 0 ${getSizeVw(32)};
    word-break: keep-all;
  }
  @media screen and (max-width: 768px) {
    .shop-name {
      line-height: normal;
    }
  }
`;
