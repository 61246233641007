import { useRef, useState, useEffect } from 'react';
import { Icon, InlineLoading, Notify } from 'zent';
import ImageView from 'cpn/photo-view/photo-view-mobile';
import goodsApi from 'api/goods';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { intlMessage as $t } from 'utils/intl/formate';
import { qiniuImageUpload } from 'utils/image';
import { Style } from './style';

const ImageUpload = (props) => {
  const [images, setImages] = useState([]);
  const {
    multiple = '',
    accept = 'image/*',
    maxAmount = Infinity,
    intl,
    handle,
    placeholderSlot,
    defaultValue,
    fileSize,
  } = props;
  const inputRef = useRef(null);
  const imagesRef = useRef([]);

  const onSuccess = ({ url }, index) => {
    imagesRef.current[index] = url;

    setImages([...imagesRef.current]);
    onChangeImage([...imagesRef.current]);
  };

  useEffect(() => {
    if (Array.isArray(defaultValue) && defaultValue.length > 0) {
      const filterImages = defaultValue.filter(value => !!value);
      imagesRef.current = filterImages;
      setImages(filterImages);
    }
  }, [defaultValue]);

  const onChangeImage = (images) => {
    const { onChange } = props;
    if (onChange) {
      const invalidUrls = filter(images, (item) => !!item);
      onChange(invalidUrls);
    }
  }

  const onImageUpload = async (file, index) => {
    const token = await goodsApi.getReviewImageUploadToken();

    qiniuImageUpload({
      file,
      token,
      onSuccess: (res) => onSuccess(res, index),
      onError() {
        imagesRef.current.splice(index, 1);
        setImages([...imagesRef.current]);
        onChangeImage([...imagesRef.current]);
      }
    });
  };

  const onFileChange = async (e) => {
    const files = e?.target?.files || null;
    if (!files) return;
    const isFileSizeLimit = fileSize && Array.from(files)?.some(item => item.size > fileSize * 1024 * 1024);
    if (isFileSizeLimit) {
      Notify.warn(
        intl.formatMessage({
          id: '16d09d5e961a4a6f9fc27f913a1bc8f1',
          defaultMessage: '图片不能超过{size}',
        }, {
          size: ` ${fileSize} MB`
        })
      );
      return;
    }
    const limitedFiles = Array.from(files).slice(0, maxAmount - imagesRef.current.length);
    const imgsUploading = map(limitedFiles, (file, index) => {
      onImageUpload(file, imagesRef.current.length + index);
      return '';
    });
    const current = imagesRef.current.concat(imgsUploading);

    setImages(current);
  };

  const selectFile = () => {
    inputRef.current && inputRef.current.click();
  };

  const onDelete = (index) => {
    images.splice(index, 1);

    setImages([...images]);
    onChangeImage([...images]);
  };

  const onTouchOrClick = (evt, index) => {
    evt.stopPropagation();
    evt.preventDefault();

    onDelete(index);
  };

  const renderImageItem = (src, index) => {
    return (
      <li className='review-image-item' key={index}>
        {src ? (
          <>
            <p
              className='image-icon-close'
              onTouchStart={(evt) => onTouchOrClick(evt, index)}
              onClick={(evt) => onTouchOrClick(evt, index)}
            >
              <Icon type='close-circle' />
            </p>
            <img src={src} alt={handle} />
          </>
        ) : (
          <InlineLoading loading icon='circle' colorPreset='grey' />
        )}
      </li>
    );
  };

  const renderTrigger = () => {
    return images.length < maxAmount ? (
      <div className='image-upload-trigger' onClick={selectFile}>
        {
          placeholderSlot ?
            placeholderSlot :
            <>
              <Icon className='image-upload-icon' type='plus' />
              <span className='image-trigger-text'>{$t(intl, 'general.upload.add_img')}</span>
            </>
        }
      </div>
    ) : null;
  };

  return (
    <Style>
      <input
        className='file-input'
        multiple={multiple}
        type='file'
        accept={accept}
        ref={inputRef}
        onChange={onFileChange}
      />
      <ImageView
        className='review-image-list'
        imageClassName='review-image-item'
        onDelete={onDelete}
        photoImages={images}
        renderItem={renderImageItem}
        renderTrigger={renderTrigger}
        handle={handle}
      />
      <ul className='review-image-list pc-review-image'>
        {images.map((item, index) => renderImageItem(item, index))}
        {renderTrigger()}
      </ul>
    </Style>
  );
};

export default ImageUpload;
