import React from 'react';
import { Form, FormControl, FormError, validateOption } from 'zent';
import { Select } from 'cpn/hunt-select';
import Style from './style';

const SelectFileds = (props) => {
  const {
    name = '',
    defaultValue = '',
    validators = [],
    label = '',
    data = [],
    optionValue = 'key',
    optionText = 'text',
    getSelectValue,
    labelType,
    placeholder = '',
    position = 'down',
    disabled = false,
    withoutLabel,
    canEmpty = false,
    onFilter,
  } = props;
  const model = Form.useField(name, defaultValue, [...(validators || [])]);
  model.destroyOnUnmount = true;

  const onChange = (item) => {
    if (disabled) return;
    model.isTouched = true;
    model.patchValue(item[optionValue]);
    getSelectValue && getSelectValue(item[optionValue]);
  };

  const validate = React.useCallback(() => {
    model.validate(validateOption);
  }, [model, validateOption]);

  return (
    <Style labelType={labelType}>
      <div className='filed-item'>
        <FormControl
          className={model.error ? 'has-error' : ''}
          label={labelType === 'inner' ? '' : label}
          withoutLabel={withoutLabel}
        >
          {labelType === 'inner' && <p className='filed-name'>{label}</p>}
          <div className='input-warp'>
            <Select
              size='small'
              width='auto'
              disabled={disabled}
              optionText={optionText}
              optionValue={optionValue}
              options={data}
              style={{ dispaly: 'block', width: 'auto' }}
              value={model.value}
              onChange={onChange}
              onBlur={validate}
              placeholder={placeholder}
              position={position}
              canEmpty={canEmpty}
              onFilter={onFilter}
            />
          </div>
        </FormControl>
        {model.error && <FormError>{model.error.message}</FormError>}
      </div>
    </Style>
  );
};

export default SelectFileds;
