import { PolicyStyle } from './style';
import { PolicyItem } from '../../../../../pages/components/policy-item';
import React, { useState, useEffect } from 'react';
import shopApi from 'api/shop';
import { useIntl } from 'react-intl';

export const GoodsPolicy = () => {
  const intl = useIntl();
  const [shippingPolicy, setShippingPolicy] = useState({
    name: intl.formatMessage({ defaultMessage: '物流配送', id: '61f2fe9d60154c9d98bed3106b2a8c4a' }),
    description: '',
    icon: '#iconwuliupeisong',
    detailUrl: 'Shipping-Policy',
    detailType: 'shipping',
  });
  const [refundsPolicy, setRefundsPolicy] = useState({
    name: intl.formatMessage({ defaultMessage: '退款政策', id: 'e996ed42c6ec4052b2c99a76755d2733' }),
    description: '',
    icon: '#icontuikuanzhengce',
    detailUrl: 'Return-Policy',
    detailType: 'refund',
  });
  const [paymentPolicy, setPaymentPolicy] = useState({
    name: intl.formatMessage({ defaultMessage: '安全支付', id: '44ab5fd0e596459385fbc24a6fc70679' }),
    description: '',
    icon: '#iconanquanbaozhang',
    detailUrl: 'Secure-Payment',
    detailType: 'pay',
  });
  const [pickUpPolicy, setPickUpPolicy] = useState({
    name: intl.formatMessage({ defaultMessage: '可选就近自提点并预约提货', id: 'e7d3f9bf15604082b80e5402b842da76' }),
    description: '',
    icon: '#icondizhi',
    detailUrl: '',
    detailType: '',
  });

  const [hasDetailRule, setHasDetailRule] = useState([]);

  useEffect(() => {
    shopApi.getAvailableRuleDetail({ kdtId: window?.global_data?.kdtId }).then((data) => {
      const { availableRules = [] } = data;
      setHasDetailRule(availableRules);
    });
  }, []);

  useEffect(() => {
    shopApi.getRuleIntro({ kdtId: window && window?.global_data?.kdtId }).then((data) => {
      const { payIntro, refundIntro, shippingIntro } = data;
      setShippingPolicy({ ...shippingPolicy, description: shippingIntro });
      setRefundsPolicy({ ...refundsPolicy, description: refundIntro });
      setPaymentPolicy({ ...paymentPolicy, description: payIntro });
    });
  }, []);

  if (
    !shippingPolicy?.description &&
    !refundsPolicy?.description &&
    !paymentPolicy?.description &&
    !pickUpPolicy?.description
  ) {
    return null;
  }

  return (
    <PolicyStyle>
      {shippingPolicy?.description && (
        <PolicyItem {...shippingPolicy} isShowBtn={hasDetailRule.includes(shippingPolicy.detailType)} />
      )}
      {refundsPolicy?.description && (
        <PolicyItem {...refundsPolicy} isShowBtn={hasDetailRule.includes(refundsPolicy.detailType)} />
      )}
      {paymentPolicy?.description && (
        <PolicyItem {...paymentPolicy} isShowBtn={hasDetailRule.includes(paymentPolicy.detailType)} />
      )}
      {pickUpPolicy?.description && (
        <PolicyItem {...pickUpPolicy} isShowBtn={hasDetailRule.includes(pickUpPolicy.detailType)} />
      )}
    </PolicyStyle>
  );
};
