import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { ContextGoodDetail } from '../../../../../../pages/context/context-good-detail';
import { SmallStyle, BigStyle, Style } from './style';
import ImageContainer from '../../../../../../pages/design/components/image-container';
import VideoContainer from '../../../../../../pages/design/components/video-container';
import cn from 'classnames';
import DefaultImg from 'cpn/default-img';
import throttle from 'lodash/throttle';

const Item = ({ item = {}, imageRatio, active, onClick, type = '' }) => {
  return (
    <div key={item.mediaId || item.url} className={cn('item', { active })} onClick={() => onClick && onClick()}>
      {item.type === 'IMAGE' || type === 'SmallPictures' ? (
        <ImageContainer
          heightType={imageRatio}
          heightRules='type3'
          fit='cover'
          position='center center'
          url={item.type === 'IMAGE' ? item?.url : item?.coverUrl}
        />
      ) : (
        // 这里利用了ImageContainer的宽高计算功能
        <ImageContainer heightType={imageRatio} heightRules='type3' hideImg>
          <VideoContainer
            autoPlay={false}
            muted // 声音如果不禁止，有可能视频不能播放
            mediaId={item.mediaId}
            firstImage={item.coverUrl}
          />
        </ImageContainer>
      )}
    </div>
  );
};
const ProductMediaThree = ({ children, className }) => {
  return (
    <Style>
      <div className={cn('product-media-three', className)}>{children}</div>
    </Style>
  );
};
// 缩略图默认配置
// DEFAULT_GROUPNUM：一组的数量
// DEFAULT_SMALL_IMAGE_WIDTH:图的宽度和高度
// DEFAULT_SMALL_IMAGE_GAP：图之间的间距
// DEFAULT_GROUP_HEIGHT:一组图的高度
const DEFAULT_GROUPNUM = 4;
const DEFAULT_SMALL_IMAGE_WIDTH = 100;
const DEFAULT_SMALL_IMAGE_GAP = 8;
const DEFAULT_GROUP_HEIGHT = DEFAULT_GROUPNUM * (DEFAULT_SMALL_IMAGE_WIDTH + DEFAULT_SMALL_IMAGE_GAP);
ProductMediaThree.SmallPictures = () => {
  const smallListRef = useRef();
  const { updateMainMediaIndex, productMedia, layoutStyle } = useContext(ContextGoodDetail);
  const { mediaList = [], mainMediaIndex } = productMedia;
  const [currentGroup, setCurrentGroup] = useState(1);

  // 切换到上一组
  const onGroupPre = () => {
    setCurrentGroup(currentGroup >= 2 ? currentGroup - 1 : 1);
  };
  // 切换到下一组
  const onGroupNext = () => {
    setCurrentGroup(currentGroup < totalPage ? currentGroup + 1 : totalPage);
  };

  const galleryList = useMemo(() => {
    switch (layoutStyle) {
      case 'style1':
      case 'style3':
        /** 样式1、2时，缩略图不展示sku图，详情咨询湘楠 */
        return mediaList.filter(({ variantId }) => !variantId);
      default:
        return mediaList;
    }
  }, [layoutStyle, mediaList]);

  const totalSize = galleryList.length;
  const totalPage = Math.ceil(totalSize / DEFAULT_GROUPNUM);
  const showArrows = totalSize > DEFAULT_GROUPNUM && mainMediaIndex < galleryList.length;

  useEffect(() => {
    // 处理缩略图的滑动
    if (!showArrows) {
      return;
    }
    smallListRef.current.style.marginTop = `${-DEFAULT_GROUP_HEIGHT * (currentGroup - 1)}px`;
  }, [currentGroup]);

  useEffect(() => {
    if (!showArrows) {
      return;
    }
    // 根据activeIndex计算当前显示的组
    setCurrentGroup(Math.floor(mainMediaIndex / DEFAULT_GROUPNUM) + 1);
  }, [mainMediaIndex]);

  return (
    <SmallStyle gap={DEFAULT_SMALL_IMAGE_GAP}>
      <div className='small-pictures'>
        <div
          className='scroll'
          style={{
            height: DEFAULT_GROUP_HEIGHT,
          }}
        >
          <div className='list' ref={smallListRef}>
            {galleryList.map((item, index) => {
              return (
                <Item
                  active={index == mainMediaIndex}
                  item={item}
                  imageRatio='1/1'
                  onClick={() => {
                    updateMainMediaIndex(index);
                  }}
                  type='SmallPictures'
                />
              );
            })}
          </div>
        </div>
        {showArrows && (
          <div className='changeGroup'>
            <span className={cn('arrow left-arrow', { disabled: currentGroup <= 1 })} onClick={onGroupPre}>
              <svg className='icon' aria-hidden='true' width='24' height='24'>
                <use xlinkHref='#iconic-xiala1' />
              </svg>
            </span>
            <span className={cn('arrow right-arrow', { disabled: currentGroup >= totalPage })} onClick={onGroupNext}>
              <svg className='icon' aria-hidden='true' width='24' height='24'>
                <use xlinkHref='#iconic-xiala1' />
              </svg>
            </span>
          </div>
        )}
      </div>
    </SmallStyle>
  );
};

ProductMediaThree.BigPictures = () => {
  const { updateMainMediaIndex, imageRatio, productMedia } = useContext(ContextGoodDetail);
  const { mediaList = [], mainMediaIndex } = productMedia;
  const [isSelfScroll, setIsSelfScroll] = useState(false);
  const bigImageRef = useRef();
  const proxyRef = useRef();
  proxyRef.current = {
    isSelfScroll,
    mainMediaIndex,
  };
  useEffect(() => {
    if (!isSelfScroll) {
      const dom = bigImageRef.current.getElementsByClassName('item')[mainMediaIndex];

      dom?.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    } else {
      setIsSelfScroll(!isSelfScroll);
    }
  }, [mainMediaIndex]);

  useEffect(() => {
    const listener = (e) => {
      const { mainMediaIndex } = proxyRef.current;
      const doms = Array.from(bigImageRef.current.getElementsByClassName('item'));
      doms?.forEach((item, index) => {
        // 获取item的高度
        const { height, top } = item?.getBoundingClientRect();
        // 根据item的位置得到当前active的 item
        if (top <= height / 2 && top >= -height / 2 && index !== mainMediaIndex) {
          setIsSelfScroll(true);
          updateMainMediaIndex(index);
        }
      });
    };
    const handleScrollThrottle = throttle(listener, 100);
    document.body.addEventListener('mousewheel', handleScrollThrottle);
    return () => {
      document.body.removeEventListener('mousewheel', handleScrollThrottle);
    };
  }, []);

  return (
    <BigStyle gap={DEFAULT_SMALL_IMAGE_GAP}>
      <div className='big-pictures' ref={bigImageRef}>
        {mediaList?.length > 0 ? (
          mediaList.map((item, index) => {
            return <Item item={item} imageRatio={imageRatio} />;
          })
        ) : (
          <DefaultImg square height='41%' width='auto' />
        )}
      </div>
    </BigStyle>
  );
};

export default ProductMediaThree;
