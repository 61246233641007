import styled from 'styled-components';

export default styled.div`
  width: 100%;
  /* background: rgba(152, 36, 36, 0.05); */
  background: rgba(var(--color_activity_label_rgb), 0.1);
  /* border-radius: 4px; */
  padding: 20px;
  justify-content: space-between;
  font-size: 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: var(--border_radius_other);
  .hunt-prompt-left {
    .hunt-prompt-title {
      color: var(--color-text);
      margin-right: 20px;
    }
  }
  .hunt-prompt-right-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    width: 100%;
  }
  .hunt-prompt-desc {
    color: #646566;
    flex: 1;
  }
  .hunt-prompt-right {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color_button_text);
  }

  .link-text {
    color: rgba(var(--color_body_text_rgb), 0.6);
  }

  .icon-right {
    fill: rgba(var(--color_body_text_rgb), 0.6);
  }

  @media screen and (max-width: 750px) {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    padding: 20px !important;
    .hunt-prompt-title {
      margin-right: 0px !important;
      min-width: 80px;
    }
    .hunt-prompt-right-wrapper {
      margin-top: 8px;
      align-items: flex-start !important;
    }

    /* .hunt-prompt-right {
            min-width: 52px;
        } */
    .discount-tag-desc {
      align-items: flex-start !important;
    }
  }
`;
