import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
  .ht-dialog.pick-up-dialog {
    max-width: 640px !important;
    min-height: 560px;
    .icon-back {
      width: 20px;
      height: 20px;
      fill: var(--color_body_text);
      cursor: pointer;
    }
    /* .zent-dialog-r-body {
      padding: 0;
    }
    .zent-dialog-r-title {
      font-size: 18px;
      line-height: 24px;
      border-bottom: none;
      padding: 0 24px 16px;
    } */
  }
`;

export const Style = styled.div`
  .pick-up-dialog-container {
    .search-container {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      color: var(--color_body_text);
      border-bottom: 1px solid rgba(var(--color_body_text_rgb), 0.2);
      .icon-search {
        width: 24px;
        height: 24px;
        fill: var(--color_body_text);
      }
      .search-input-inner {
        flex: 1;
        height: 24px;
        line-height: 24px;
        border: none;
        outline: none;
        padding: 0;
        margin: 0 6px;
        background-color: transparent;
      }
      .search-input-inner::placeholder {
        color: rgba(var(--color_body_text_rgb), 0.4);
      }
      .search-input-inner::-webkit-input-placeholder {
        color: rgba(var(--color_body_text_rgb), 0.4);
      }
      .search-input-inner:-moz-placeholder {
        color: rgba(var(--color_body_text_rgb), 0.4);
      }
      /* .search-btn {
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
        color: var(--color_button);
      } */
    }
  }
  .pick-up-list {
    overflow-y: auto;
    max-height: 60vh;
    min-height: 360px;
    /* padding: 0 24px; */
    .location-info-item {
      display: flex;
      align-items: stretch;
      padding: 24px 0;
      cursor: pointer;
      + .location-info-item {
        border-top: 1px solid var(--color_borders);
      }
      &__radio {
        display: flex;
        align-items: center;
        margin-right: 12px;
      }
      &__left {
        flex: 1;
        margin-right: 8px;
      }
      &__title {
        color: var(--color_body_text);
        font-weight: 500;
        margin-bottom: 12px;
      }
      &__address,
      &__tel {
        display: flex;
        align-items: center;
        margin-top: 4px;
        font-size: 12px;
        line-height: 18px;
        color: rgba(var(--color_body_text_rgb), 0.6);
        .icon {
          width: 12px;
          height: 12px;
          fill: var(--color_body_text);
          margin-right: 4px;
          flex-shrink: 0;
        }
      }
      &__right {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        color: rgba(var(--color_body_text_rgb), 0.4);
        .icon {
          width: 16px;
          height: 16px;
          fill: rgba(var(--color_body_text_rgb), 0.4);
        }
      }
    }
  }
  .pick-up-location-empty {
    color: var(--color_body_text);
    text-align: center;
    margin-top: 30%;
  }
  .zent-pagination.zent-pagination--lite {
    justify-content: flex-end !important;
    padding: 16px 16px 0;
  }
  .pick-up-location-detail {
    min-height: 400px;
    .location-info {
      &__title {
        font-size: 14px;
        line-height: 20px;
        color: var(--color_body_text);
        font-weight: 600;
        margin-bottom: 16px;
      }
      &__address,
      &__tel {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 18px;
        margin-top: 8px;
      }
      .icon {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        fill: var(--color_body_text);
        margin-right: 4px;
        flex-shrink: 0;
      }
    }
    .location-time,
    .location-instruction {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid rgba(var(--color_body_text_rgb), 0.08);
      &__title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 12px;
      }
      &__content {
        font-size: 12px;
        line-height: 18px;
        color: rgba(var(--color_body_text_rgb), 0.8);
      }
      .location-time-list {
        .location-time-item {
          padding: 8px;
          font-size: 12px;
          line-height: 20px;
          color: rgba(var(--color_body_text_rgb, 0.6));
          background-color: rgba(var(--color_text_field_text_rgb), 0.04);
          + .location-time-item {
            margin-top: 12px;
          }
        }
      }
    }
  }
  .pick-up-dialog-footer {
    margin: 0 -24px;
    border-top: 1px solid var(--color_borders);
    padding: 20px 24px 0;
    display: flex;
    justify-content: flex-end;
    /* box-shadow: 0px -2px 4px rgba(0,0,0,0.04); */
    button {
      height: 40px !important;
      line-height: 40px !important;
      padding: 0 24px;
      font-size: 14px !important;
    }
    .cancel-btn {
      margin-right: 12px;
    }
  }
`;
