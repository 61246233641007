import React from 'react';
import Style from './style';
import cn from 'classnames';
import { Text, Badge } from '../../../../../../components/base';
import { getUrlFromConfig } from 'utils/formate';
import Image from 'components/base/image';

export const PcSubMenu = (props) => {
  const {
    menuList = [],
    menuIndex,
    refNode,
    headerGlobalStyle,
    menuGroupWidth,
    subMenuBgColor,
    subMenuTextColor,
    levelTwoMenuTextSize,
    levelThreeMenuTextSize,
    submenuAdaptContentWidth,
    menuContainerLeft,
    type,
  } = props;
  const isFirstLevelMenu = menuIndex === 1;

  const getTextSize = (type, menuIndex) => {
    // 自定义菜单type是空
    if (type === 'menu_group' || !type) {
      return menuIndex === 1 ? levelTwoMenuTextSize : levelThreeMenuTextSize;
    }
    return levelTwoMenuTextSize;
  };

  return (
    <Style
      ref={refNode}
      menuGroupWidth={`${menuGroupWidth}px`}
      textColor={subMenuTextColor}
      style={{
        background: isFirstLevelMenu ? subMenuBgColor || '#fff' : '',
        left: submenuAdaptContentWidth ? menuContainerLeft : 0,
      }}
      className={cn(
        'header-sub-menu-container',
        isFirstLevelMenu ? 'first-level-header-sub-menu' : 'header-sub-menu-not-first',
        {
          'adapt-content-width': submenuAdaptContentWidth,
        },
      )}
    >
      {Array.isArray(menuList) &&
        !!menuList?.length &&
        menuList.map((item, index) => {
          const { menuItemName, shopTemplateMenuItemTreeNodeCustomerVO: subMenuList = [], url, settings = {} } = item;

          return (
            <div className='header-sub-menu-box' key={index}>
              {/* 只有三级菜单组才展示这个标题， 默认是三级菜单组 */}
              {((isFirstLevelMenu && (item.type === 'menu_group' || !item.type)) || !isFirstLevelMenu) && (
                <Text
                  as='div'
                  fontWeight={menuIndex === 2 ? 'regular' : 'medium'}
                  size={getTextSize(type, menuIndex, menuItemName, item)}
                  className={cn(
                    'header-sub-minu-item',
                    {
                      'menu-level-3': menuIndex === 2 && type === 'menu_group',
                    },
                    isFirstLevelMenu && 'first-level-menu',
                  )}
                >
                  <a href={url || '#'} target={`_${item.open_target}`} className='link_has_label'>
                    {menuItemName}
                    {item.settings?.show_label && item.settings?.label_text && (
                      <Badge
                        className='header-badge'
                        color={item.settings?.label_text_color}
                        bgColor={item.settings?.label_bg_color}
                      >
                        {item.settings?.label_text}
                      </Badge>
                    )}
                  </a>
                </Text>
              )}
              {item.type === 'image' && (
                <a href={getUrlFromConfig(settings.link)} className='header-menu-item-text'>
                  <Image src={settings.image} className='header-menu-item-image' alt={menuItemName} />
                  <Text
                    as='div'
                    fontWeight='medium'
                    size={settings.text_size}
                    className='first-level-menu'
                    style={{ textAlign: settings.align_text }}
                  >
                    <span
                      className='header-menu-item-text decorative-style'
                      style={{
                        textAlign: settings.align_text,
                        borderBottom:
                          settings.show_decorative_lines === undefined || settings.show_decorative_lines ? '' : 'none',
                      }}
                      dangerouslySetInnerHTML={{ __html: settings.text }}
                    />
                  </Text>
                </a>
              )}
              {/* 只有三级菜单组才会嵌套 */}
              {!!subMenuList?.length && (
                <PcSubMenu
                  type={item.type}
                  subMenuBgColor={subMenuBgColor}
                  subMenuTextColor={subMenuTextColor}
                  levelTwoMenuTextSize={levelTwoMenuTextSize}
                  levelThreeMenuTextSize={levelThreeMenuTextSize}
                  menuIndex={menuIndex + 1}
                  menuList={subMenuList}
                  headerGlobalStyle={headerGlobalStyle}
                />
              )}
            </div>
          );
        })}
    </Style>
  );
};
