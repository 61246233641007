// 多图列表
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from 'zent';
import cn from 'classnames';
import { Style, GlobalStyle } from './style';

const { openDialog, closeDialog } = Dialog;
const DIALOG_ID = 'mobile-fold-select-popup';

const MobileFoldPopup = (props) => {
  const { sectionThemeData = {}, intl } = props;
  const { block_order: blockOrder = [], blocks = {} } = sectionThemeData;
  const [siteSel, setSiteSel] = useState({});

  const onChangeSite = (item) => {
    setSiteSel(item);
    setTimeout(() => {
      window.location.href = 'https://' + item?.mainHost;
    }, 200);
  };

  return (
    <>
      <div className='popup-header'>
        <span className='popup-title'>
          {intl.formatMessage({
            id: '9307427b3c1249c7a4c064eeb4783101',
            defaultMessage: '站点切换',
          })}
        </span>
        <svg
          className='close-icon'
          onClick={() => {
            closeDialog(DIALOG_ID);
          }}
        >
          <use xlinkHref='#iconguanbi' />
        </svg>
      </div>
      <div className='list-wrapper'>
        {blockOrder.map((key) => {
          const item = blocks?.[key]?.settings || {};
          return (
            <div
              className='item-wrapper'
              key={key}
              onClick={() => {
                onChangeSite(item);
              }}
            >
              <div className='left-content'>
                {item.image ? (
                  <img src={item.image} mode='aspectFill' className='site-logo' />
                ) : (
                  <svg className='default-logo' aria-hidden='true'>
                    <use xlinkHref='#icondianputouxiang' />
                  </svg>
                )}

                <span className='site-name'>{item.site_name}</span>
              </div>
              <div className='sel-icon-wrapper'>
                {siteSel?.kdtId === item?.kdtId && (
                  <svg
                    width={16}
                    height={16}
                    fill='#2654FF'
                    className={cn('sel-icon', { 'show-icon': siteSel?.kdtId === item.kdtId })}
                  >
                    <use xlinkHref='#iconic-gouxuan'></use>
                  </svg>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const MobileFold = (props) => {
  const { sectionThemeData, kdtId, colorNavText } = props;
  const { block_order: blockOrder = [], blocks = {} } = sectionThemeData;
  let currentSite = {};
  for (let key in blocks) {
    if (blocks[key]?.settings?.kdtId === kdtId) {
      currentSite = blocks[key]?.settings;
    }
  }
  const intl = useIntl();
  const openMobileSelectPopup = () => {
    openDialog({
      dialogId: DIALOG_ID, // id is used to close the dialog
      children: <MobileFoldPopup intl={intl} sectionThemeData={sectionThemeData} />,
      className: 'site-switch-mobile-fold-dialog',
      closeBtn: false,
    });
  };

  return (
    <>
      <GlobalStyle />
      <Style colorNavText={colorNavText}>
        <div className='current-site-info' onClick={openMobileSelectPopup}>
          {currentSite.image ? (
            <img src={currentSite.image} mode='aspectFill' className='site-logo' />
          ) : (
            <svg className='default-logo' aria-hidden='true' fill={colorNavText}>
              <use xlinkHref='#iconwuliupeisong' />
            </svg>
          )}
          <span className='site-name'>{currentSite.site_name}</span>
          <svg width={16} height={16} fill={colorNavText}>
            <use xlinkHref='#iconic-xiala'></use>
          </svg>
        </div>
      </Style>
    </>
  );
};
