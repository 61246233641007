// 对应装修感言2.0
import React, { useState } from 'react';
import cn from 'classnames';
import SwiperCore, { Pagination } from 'swiper';
import { Wrapper } from 'components/base';
import { DefaultImg } from '../../../components/default-img';
import { Style } from './style';
import Image from 'components/base/image';
import ContainerSize from '../../components/container-size';

SwiperCore.use([Pagination]);

const QuotesV2 = ({ settings = {}, blocks, block_order: blockOrder, elemClass }) => {
  const { title, bg_color: bgColor = '#fff', box_width: boxWidth } = settings;
  const slidesPerView = 1;

  const navigationClass = blockOrder.length > slidesPerView ? 'show-navigation' : 'hide-navigation';

  return (
    <Style className={cn('quote-section', elemClass)}>
      <ContainerSize boxWidth={boxWidth} needSafeSpace>
        <Wrapper marginTop='vContainer' marginBottom='vContainer'>
          <div className={cn('quote', navigationClass)}>
            {title && <h3 className={cn('quote-content-title', 'section-component-title')}>{title}</h3>}
            <div className='quote-blocks-list'>
              {blockOrder.map((blockId) => {
                const { image, quote, author } = blocks[blockId]?.settings || {};
                return (
                  <div className='quote-blocks-item' style={{ background: bgColor }}>
                    <div className='quote-blocks-symbol' style={{ borderColor: bgColor }}>
                      {image ? (
                        <Image className='hero-img' src={image} alt={title} />
                      ) : (
                        <DefaultImg width='auto' height='41%' square />
                      )}
                    </div>

                    <div className='quote-blocks-author'>{author}</div>
                    <div
                      className='quote-blocks-desc'
                      dangerouslySetInnerHTML={{
                        __html: quote,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Wrapper>
      </ContainerSize>
    </Style>
  );
};

export default QuotesV2;
