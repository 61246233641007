export const parseSettings = (settings = {}) => {
  const { announcement_link: announcementLink, open_target: openTarget, title } = settings;
  let path = null;

  try {
    path = announcementLink ? JSON.parse(announcementLink)?.url : '';
  } catch (err) {
    console.log(err);
  }

  return {
    path,
    title,
    openTarget,
  };
};
