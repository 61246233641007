import React from 'react';
import { useIntl } from 'react-intl';
import ReviewsDetail from '../review-detail';
import { ReviewPosterStyle, Style } from './style';

const Reviews = (props) => {
  const intl = useIntl();

  return (
    <Style className='reviews-box'>
      <ReviewsDetail {...props} />
      <ReviewPosterStyle />
    </Style>
  );
};

export default Reviews;
