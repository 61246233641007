import React from 'react';
import View from 'components/base/view';
import { TitleStyle } from './style';

export const GoodsTitle = ({ title, productNameSize }) => {
  return (
    <TitleStyle>
      <View className='title' size={productNameSize}>
        {title}
      </View>
    </TitleStyle>
  );
};
