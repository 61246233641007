import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { getIsOverStandard } from 'helper/shop';
import cn from 'classnames';
import { MemberStatus } from 'const/member';
import { useMemberInfo } from './hook';
import { useMemberPlan } from 'hook/member';
import { getMemberGlobalStyle } from 'helper/member';
import { Icon } from 'components/base';
import RootContext from '../../../context/root';
import Style from './style';

enum MemberSwitchType {
  Close = 'close',
  Open = 'open',
}

const MemberInfo = (props) => {
  const intl = useIntl();
  const globalData = window?.global_data ?? {};
  const { className, showIcon = true } = props;
  const { packageInfo = {} } = globalData;
  const isOverStandard = getIsOverStandard(packageInfo?.versionCode);

  const globalStyle = getMemberGlobalStyle(globalData?.memberThemeData?.current ?? {}); // 会员全局装修数据
  const { memberStatus = {} } = globalData;

  const kdtId = globalData?.kdtId;
  const { memberPlan = {} } = useMemberPlan({ kdtId });
  const { currency, timeZone } = globalData.shopSetting;
  const { userInfo = {} } = globalData;
  const { label_bg: labelBg, label_text: labelText } = globalStyle;
  const { multiCurrencyInfo } = globalData?.multiCurrencyInfo?.multiCurrencies.filter(
    (item) => item.currency === globalData?.multiCurrencyInfo?.currency,
  );
  const { member } = useContext(RootContext);
  const isMember = member?.isMember;

  // useMemberTheme();

  const { textInfo = {} } = useMemberInfo({
    locale: intl.locale,
    userInfo,
    currency,
    timeZone,
    intl,
    kdtId,
    memberStatus,
    memberPlan,
    multiCurrencyInfo,
  });

  const onJump = () => {
    window.location.href = '/member/detail';
  };

  const memberPlanSwitch = memberPlan?.memberPlanSwitch === MemberSwitchType.Open;
  /** 如果店铺版本不够 或者商家没有开启 或者开启了没配置 都不显示 */
  if (!memberPlan?.spreadText || !isOverStandard || !memberPlanSwitch || isMember) {
    return null;
  }

  return (
    <Style
      className={cn('member-status', className, {
        expire: memberStatus?.status === MemberStatus.Expire || memberStatus?.status === MemberStatus.Forbid,
      })}
      onClick={onJump}
      style={{
        background: labelBg,
        color: labelText,
      }}
    >
      <div className='left-item'>
        {showIcon && <Icon className='iconic-icon' name='iconic-huiyuanbiaoqian' size='24' />}
        <div className='text'>{textInfo?.text}</div>
      </div>

      <Icon className='iconic-icon' name='iconic-xiala' size='12' style={{ color: labelText }} />
    </Style>
  );
};

export default MemberInfo;
