import { Dialog } from 'zent';
import React, { useState } from 'react';
import { HtTabHeader, HtTabPanel } from '../tabs';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, DialogHeaderWidthIcon, DialogHeaderWidthIconTitle } from './style';
import cn from 'classnames';
import { rest } from 'lodash';

const { openDialog, closeDialog } = Dialog;

const DialogTitile = (props) => {
  const { titleTabs = [], activeTabId, setActiveTabId, titleIcon, title } = props;
  if (titleTabs.length > 0) {
    return (
      <HtTabHeader
        tabs={titleTabs}
        activeTabId={activeTabId}
        onTabChange={(id) => {
          setActiveTabId(id);
        }}
      />
    );
  }
  if (!titleIcon) {
    return title;
  }
  return (
    <DialogHeaderWidthIcon>
      {titleIcon}
      <DialogHeaderWidthIconTitle>{title}</DialogHeaderWidthIconTitle>
    </DialogHeaderWidthIcon>
  );
};

const DialogChildren = (props) => {
  const { children, titleTabs = [], bodyTabContents = [], activeTabId } = props;
  if (titleTabs.length > 0) {
    if (bodyTabContents.length > 0) {
      return bodyTabContents.map((tabContentItem) => {
        return (
          <HtTabPanel key={tabContentItem.id} activeTabId={activeTabId} tab={tabContentItem}>
            {tabContentItem.content}
          </HtTabPanel>
        );
      });
    } else {
      console.error('bodyTabContents没有传');
    }
  }
  return children;
};

function HtDialog(props) {
  const {
    visible,
    onClose,
    title,
    titleIcon,
    className,
    children,
    titleTabs = [],
    bodyTabContents = [],
    footer,
    size = 'normal',
    maskClosable = true,
    width,
  } = props;
  const [activeTabId, setActiveTabId] = useState(titleTabs[0] && titleTabs[0].id);
  return (
    <div>
      <GlobalStyle />
      <Dialog
        visible={visible}
        onClose={onClose}
        title={
          <DialogTitile
            titleTabs={titleTabs}
            activeTabId={activeTabId}
            setActiveTabId={setActiveTabId}
            titleIcon={titleIcon}
            title={title}
          />
        }
        className={cn(`ht-dialog`, className, {
          [`ht-dialog-${size}`]: true,
        })}
        footer={footer}
        maskClosable={maskClosable}
        style={{ width }}
      >
        <DialogChildren titleTabs={titleTabs} bodyTabContents={bodyTabContents} activeTabId={activeTabId}>
          {children}
        </DialogChildren>
      </Dialog>
    </div>
  );
}

export default HtDialog;

HtDialog.openHtDialog = (props) => {
  const { dialogId, onClose, autoClose = true, title, titleIcon, className, children, footer, theme, ...rest } = props;
  openDialog({
    dialogId,
    onClose: () => {
      if (onClose) {
        onClose();
      }
      autoClose && closeDialog(dialogId);
    },
    className: `ht-dialog ${className}`,
    title: <DialogTitile titleIcon={titleIcon} title={title} />,
    children: (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <DialogChildren>{children}</DialogChildren>
      </ThemeProvider>
    ),
    footer,
    ...rest,
  });
};
HtDialog.closeHtDialog = closeDialog;
