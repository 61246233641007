import styled from 'styled-components';
import { getPercentWidth, getSizeVw } from '../../../../../style/fn';

export default styled.div`
  .pay-safe {
    flex: 1;
    flex-direction: row-reverse;
    color: #48ab7c;
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  .header-announcement {
    line-height: 24px;
    padding: 12px 0;
    background: #1a1a1b;
    text-align: center;
  }
  .announcement_link {
    /* color: #fff; */
  }
  .sub-header-container {
    padding: 24px ${getSizeVw(80)};
    position: relative;
    background: #fff; // 头部背景色不随全局设置改变
    border-bottom: 1px solid var(--color_borders);
  }
  .wrapper {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }

  .mask {
    left: -0;
    top: 0px;
    z-index: 99;
    width: 100%;
    height: 200%;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
  }

  /* @media screen and (max-width: 376px) { */
  .menu-of-mobile {
    display: block;
  }
  .menu-list {
    display: none;
    margin-right: ${getSizeVw(80)};
  }
  .header-mobile-home {
    font-size: var(--base-font-16-14);
    cursor: pointer;
    font-weight: 600;
    color: var(--color_text);
    line-height: 64px;
    word-break: break-word;
  }
  .header-mobile-first-menu {
    font-size: var(--base-font-16-14);
    font-weight: 400;
    color: var(--color_text);
    padding: 0 16px;
  }

  .header-show-sub-menu-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .menu-back-header {
    text-align: left;
    .slide-container-head-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      fill: var(--color_text);
    }
    font-size: var(--font-18-14);
    font-weight: 400;
    color: var(--color_text);
    /* color: #1a1a1b; */
  }

  .header-mobile-menu {
    width: 100% !important;
    height: 100%;
    .zent-menu__inline-submenu-title {
      height: auto;
      line-height: 1.4 !important;
      padding: 24px 32px 24px 16px !important;
      font-size: var(--base-header-font-16-14);
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
      font-weight: 600;
      /* color: #041b4a; */
      color: var(--color_text);
      .zenticon-right {
        right: 0;
        padding: 12px;
      }
    }
    .zenticon-right {
      color: var(--color_text) !important;
      font-size: var(--base-font-16-14) !important;
      font-weight: normal !important;
    }
    /* .zent-menu__inline-submenu:hover {
            color: var(--color_text) !important;
        } */
    .zent-menu-item:hover {
      background: #f0f2f4;
      color: var(--color_text);
    }
    .zent-menu-item.zent-menu__inline-item {
      height: auto;
      line-height: 1.4 !important;
      padding: 24px 32px 24px 16px !important;
      font-size: var(--base-font-16-14);
      font-weight: 400;
      color: var(--color_text);
    }
    .zent-menu__inline-item-selected {
      background: none !important;
    }
    .zent-menu__inline-item-selected {
      background: none !important;
      font-weight: 600 !important;
      /* color: #041b4a !important; */
      color: var(--color_text) !important;
    }
  }
  /* } */
`;
