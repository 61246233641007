import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Style, DialogStyle } from './style';
import { Dialog, LitePagination, Radio } from 'zent';
import { debounce } from 'lodash';
import HtButton from '../../standard/button';
import { formatLocationText } from 'utils/global';
import deliveryApi from 'api/delivery';
import HtDialog from '../../standard/dialog';

type IntlFun = (id: string, fm: string, options?: object) => string;

enum TimePickerConfigType {
  allDay = 'all_day',
  weekRepetition = 'week_repetition',
  daysRepetition = 'days_repetition',
  workdayRepetition = 'workday_repetition',
}

interface IPickupLocationFilter {
  current: number;
  searchInput: string;
}

interface ITimeSection {
  startTime: string;
  endTime: string;
}

interface IPickupTimeCustomDetail {
  intervals: string[];
  timeSections: ITimeSection[];
}

interface IPickupTimeRule {
  pickupTimeCustomDetails?: IPickupTimeCustomDetail[];
  pickupTimeInterval: string;
  pickupTimeIntervalDetail: string;
}

interface ILocation {
  address1: string;
  address2: string;
  city: string;
  country: string;
  countryCode: string;
  instruction: string;
  isEnablePickup: number;
  locationId: number;
  name: string;
  phone: string;
  pickupTimeRule?: IPickupTimeRule;
  province: string;
  provinceCode: string;
  zip: string;
}

interface IPickupLocationDialogProps {
  $t: IntlFun;
  visibleDialog: boolean;
  onClose(): void;
  locationList?: ILocation[];
  locationId?: number;
  isSelect?: boolean;
  onConfirm(args?: ILocation | null): void;
}

interface IPickupListProps {
  $t: IntlFun;
  list: ILocation[];
  filter: IPickupLocationFilter;
  setFilter(args: IPickupLocationFilter): void;
  total: number;
  toViewDetail(args: ILocation): void;
  isSelect?: boolean;
  selectedLocationId: number | null;
  handleSelect(args: ILocation): void;
}

const WEEK_MAP = {
  Sun: { text: '周日', id: 7 },
  Mon: { text: '周一', id: 1 },
  Tues: { text: '周二', id: 2 },
  Wed: { text: '周三', id: 3 },
  Thur: { text: '周四', id: 4 },
  Fri: { text: '周五', id: 5 },
  Sat: { text: '周六', id: 6 },
};

const { openHtDialog } = HtDialog;
const PAGE_SIZE = 10;
const PickupLocationDialog: React.FC<IPickupLocationDialogProps> = (props) => {
  const { $t, visibleDialog, onClose, locationList = [], isSelect, locationId, onConfirm } = props;

  const [filterLocationList, setFilterLocationList] = useState<ILocation[]>(locationList || []);
  const [filter, setFilter] = useState<IPickupLocationFilter>({
    current: 1,
    searchInput: '',
  });
  const [total, setTotal] = useState(locationList.length || 0);
  const [searchInput, setSearchInput] = useState<string>('');
  const [isView, setIsView] = useState<boolean>(false); // 查看详情
  const [locationDetail, setLocationDetail] = useState({});
  const [selectedLocationId, setSelectedLocationId] = useState(locationId || null);
  const [selectedLocationItem, setSelectedLocationItem] = useState<ILocation | null>(null);

  // 搜索词防抖
  const changeFilterSearchInput = useCallback(
    debounce((searchInput) => {
      setFilter({
        current: 1,
        searchInput,
      });
    }, 200),
    [],
  );
  useEffect(() => {
    changeFilterSearchInput(searchInput);
  }, [searchInput]);

  // 搜索
  useEffect(() => {
    const { current, searchInput } = filter;
    if (searchInput) {
      const filterList = locationList.filter((item) => item.name.toLowerCase().includes(searchInput.toLowerCase()));
      setFilterLocationList(handleDataSlice(filterList, current));
      setTotal(filterList.length);
    } else {
      setFilterLocationList(handleDataSlice(locationList, current));
      setTotal(locationList.length);
    }
  }, [locationList, filter]);

  // 数据分页
  const handleDataSlice = (list, current) => {
    const startIndex = (current - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return list.slice(startIndex, endIndex);
  };

  // 查看详情
  const toViewDetail = (item) => {
    setIsView(true);
    setLocationDetail(item);
  };

  // 选择提货点
  const handleSelect = (item) => {
    if (isSelect) {
      setSelectedLocationId(item.locationId);
      setSelectedLocationItem(item);
    }
  };

  // 确认提货点
  const confirm = () => {
    onConfirm(selectedLocationItem);
  };
  // 关闭弹窗
  const close = () => {
    setIsView(false);
    onClose();
  };

  return (
    <HtDialog
      className='pick-up-dialog'
      visible={visibleDialog}
      titleIcon={
        isView ? (
          <svg onClick={() => setIsView(false)} className='icon icon-back' aria-hidden='true'>
            <use xlinkHref='#iconic-zuoyouqiehuan' />
          </svg>
        ) : null
      }
      title={
        isView
          ? $t('152298be4b1d43a99ce0524f465cb85f', '提货点详情')
          : $t('f2cda4df426c45fe8bba99750c373c48', '可提货点')
      }
      onClose={close}
    >
      <DialogStyle />
      <Style>
        <div className='pick-up-dialog-container'>
          {isView ? (
            <PickupLocationDetail $t={$t} locationDetail={locationDetail} />
          ) : (
            <>
              <div className='search-container'>
                <svg className='icon icon-search' aria-hidden='true'>
                  <use xlinkHref='#iconic-sousuo' />
                </svg>
                <input
                  className='search-input-inner'
                  type='text'
                  placeholder={$t('4153db158baa4ebcb9fa713be9ff82d6', '请输入自提点名称')}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
              <PickupList
                $t={$t}
                list={filterLocationList}
                filter={filter}
                setFilter={setFilter}
                total={total}
                toViewDetail={toViewDetail}
                isSelect={isSelect}
                selectedLocationId={selectedLocationId}
                handleSelect={handleSelect}
              />
            </>
          )}
          {isSelect && (
            <div className='pick-up-dialog-footer'>
              <HtButton className='cancel-btn' onClick={close} type='secondary'>
                {$t('common_cancel', '取消')}
              </HtButton>
              <HtButton onClick={confirm}>{$t('common_confirm', '确定')}</HtButton>
            </div>
          )}
        </div>
      </Style>
    </HtDialog>
  );
};

function PickupList(props: IPickupListProps) {
  const { $t, list, filter, setFilter, total, toViewDetail, isSelect, selectedLocationId, handleSelect } = props;

  return (
    <>
      <div className='pick-up-list'>
        {list.length > 0 ? (
          list.map((item) => (
            <div
              key={item.locationId}
              className='location-info-item'
              onClick={() => {
                if (isSelect) return;
                toViewDetail(item);
              }}
            >
              {isSelect && (
                <div onClick={() => handleSelect(item)} className='location-info-item__radio'>
                  <Radio checked={item.locationId == selectedLocationId}></Radio>
                </div>
              )}
              <div onClick={() => handleSelect(item)} className='location-info-item__left'>
                <div className='location-info-item__title'>{item.name}</div>
                <div className='location-info-item__address'>
                  <svg className='icon' aria-hidden='true'>
                    <use xlinkHref='#icondizhi' />
                  </svg>
                  <span>{formatLocationText(item)}</span>
                </div>
                <div className='location-info-item__tel'>
                  <svg className='icon' aria-hidden='true'>
                    <use xlinkHref='#iconic_phone' />
                  </svg>
                  <span>{item.phone}</span>
                </div>
              </div>
              <div onClick={() => toViewDetail(item)} className='location-info-item__right'>
                <span>{$t('common_detail', '详情')}</span>
                <svg className='icon icon-arrow-right' aria-hidden='true'>
                  <use xlinkHref='#iconic-xiala' />
                </svg>
              </div>
            </div>
          ))
        ) : (
          <div className='pick-up-location-empty'>{$t('setting.general.table_tips', '没有更多数据了')}</div>
        )}
      </div>
      {total > PAGE_SIZE && (
        <LitePagination
          current={filter.current}
          pageSize={PAGE_SIZE}
          total={total}
          onChange={(e) => {
            setFilter({
              ...filter,
              current: e.current,
            });
          }}
        />
      )}
    </>
  );
}

const locationMaps = new Map();
function PickupLocationDetail(props) {
  const { locationDetail, $t } = props;
  const [locationInfo, setLocationInfo] = useState<ILocation>();

  // 时间区间展示
  const timeRuleEle = (rules: IPickupTimeRule) => {
    const { pickupTimeCustomDetails, pickupTimeInterval } = rules;
    const timeList: IPickupTimeCustomDetail[] = [];
    switch (pickupTimeInterval) {
      // 每天
      case TimePickerConfigType.allDay: {
        timeList.push({
          intervals: [$t('884ba1ac50ad4d7286ccbcd0ec6ff65b', '每天')],
          timeSections: [{ startTime: '00: 00', endTime: '23:59' }],
        });
        break;
      }
      // 默认
      default: {
        pickupTimeCustomDetails?.forEach(({ intervals, timeSections }) => {
          const temp: IPickupTimeCustomDetail = {
            intervals: [],
            timeSections,
          };
          intervals.forEach((item) => {
            temp.intervals.push($t(`pickup.time.week.${WEEK_MAP[item]?.id}`, WEEK_MAP[item]?.text));
          });
          timeList.push(temp);
        });
        break;
      }
    }
    return (
      <ul className='location-time-list'>
        {timeList.map((item) => (
          <li className='location-time-item'>
            <div>
              {item.timeSections.map((item, i) => (
                <>
                  {i !== 0 && <span style={{ margin: '0 10px' }}>/</span>}
                  <span>
                    {item.startTime} - {item.endTime}
                  </span>
                </>
              ))}
            </div>
            <div>
              {item.intervals.map((item, i) => (
                <>
                  {i !== 0 && <span>、</span>}
                  <span>{item}</span>
                </>
              ))}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    // 自提点详情, map 缓存
    if (locationDetail?.locationId) {
      const locationId = locationDetail?.locationId;
      const info = locationMaps.get(locationId);
      if (info) {
        setLocationInfo(info);
      } else {
        deliveryApi
          .getByLocationId({
            locationId,
          })
          .then((res) => {
            setLocationInfo(res);
            locationMaps.set(locationId, res);
          });
      }
    }
  }, [locationDetail?.locationId]);

  return (
    <div className='pick-up-location-detail'>
      <div className='location-info'>
        <div className='location-info__title'>{locationInfo?.name}</div>
        <div className='location-info__address'>
          <svg className='icon' aria-hidden='true'>
            <use xlinkHref='#icondizhi' />
          </svg>
          <span>{formatLocationText(locationInfo)}</span>
        </div>
        <div className='location-info__tel'>
          <svg className='icon' aria-hidden='true'>
            <use xlinkHref='#iconic_phone' />
          </svg>
          <span>{locationInfo?.phone}</span>
        </div>
      </div>
      <div className='location-time'>
        <div className='location-time__title'>{$t('ea9c4b6379404b8b93f25ab01da9c77f', '营业时间')}</div>
        {locationInfo?.pickupTimeRule ? (
          timeRuleEle(locationInfo.pickupTimeRule)
        ) : (
          <div className='location-time__content'>-</div>
        )}
      </div>
      <div className='location-instruction'>
        <div className='location-instruction__title'>{$t('setting.account_detail', '详细信息')}</div>
        <div className='location-instruction__content'>{locationInfo?.instruction || '-'}</div>
      </div>
    </div>
  );
}

export const openPickupLocationDetailDialog = (props) => {
  const { intl, theme } = props;
  const $t = (id, defaultMessage = 'default', params = {}) => {
    if (!id) return '';
    return intl.formatMessage({ id, defaultMessage }, params);
  };

  openHtDialog({
    theme,
    className: 'person-form-dialog',
    title: $t('152298be4b1d43a99ce0524f465cb85f', '提货点详情'),
    children: (
      <Style>
        <DialogStyle />
        <div className='pick-up-dialog-container'>
          <PickupLocationDetail {...props} $t={$t} />
        </div>
      </Style>
    ),
  });
};

export default PickupLocationDialog;
