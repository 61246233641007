import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { downloadImage } from '../../../../util/image';
import Style from './style';

const Poster = ({ intl, closeDialog, imgUrl = '' }) => {
  const [animateName, setAnimateName] = useState(false);
  useEffect(() => {
    setAnimateName(true);
  }, []);
  const close = () => {
    setAnimateName(false);
    closeDialog();
  };

  const savePicture = () => {
    downloadImage({
      fileName: intl.formatMessage({
        id: '0ff83a2b04f44b9d90d1d2474041c0ff',
        defaultMessage: '海报',
      }),
      imgUrl,
    });
  };

  return (
    <Style>
      <div className='popup-shadow-mask' onClick={close}>
        <div
          className={cn('popup-contain', { 'popup-contain-animate': animateName })}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className='poster'>
            <div className='icon-close'>
              <svg onClick={close} aria-hidden='true' className='icon'>
                <use xlinkHref='#iconguanbi2' />
              </svg>
            </div>
            {/* 这是功能组件，无需alt */}
            <img src={imgUrl} />
            <p className='save-tip'>
              {intl.formatMessage({
                id: 'a00e044c56cf4396a91d7270a64a8e95',
                defaultMessage: '长按图片保存至相册',
              })}
            </p>
          </div>
        </div>
      </div>
    </Style>
  );
};

const openMobilePosterDialog = (params) => {
  const body = document.querySelector('body');
  const holder = document.createElement('div');
  body.appendChild(holder);
  body.style.setProperty('overflow', 'hidden');
  const closeDialog = () => {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(holder);
      body.style.removeProperty('overflow');
    }, 210);
  };
  ReactDOM.render(<Poster closeDialog={closeDialog} {...params} />, holder);
};
export { openMobilePosterDialog };
