import { Button } from 'zent';
import { Style } from './style';
import React from 'react';
import NewLink from 'cpn/new-link';
import cn from 'classnames';

const LinkButton = (props) => {
  const { linkStyle, linkText, className, style, path, target } = props;

  const onJump = () => {
    if (window.isEdit) return;
    if (!path) return;
    const { origin } = window.location;
    window.location.href = `${origin}${path}`;
  };
  if (!linkText) {
    return null;
  }
  return (
    <Style className={className} style={style}>
      <NewLink
        target={target}
        href={path}
        className={cn('shop-link', 'shop-guide', { 'zent-btn-primary zent-btn': linkStyle === 'button' })}
      >
        {linkText} {linkStyle !== 'button' && '→'}
      </NewLink>
    </Style>
  );
};

export default LinkButton;
