let storage = {};
typeof window === 'object' && (storage = window.localStorage);

// window.addEventListener('setItemEvent', function (e) {
//     alert(e.newValue);
// });
// localStorage.setItem('name', 'wang');

export default class LocalStorage {
  static setItem(key, newValue) {
    storage.setItem(key, newValue);
    const setItemEvent = new Event('setItemEvent');
    setItemEvent.newValue = newValue;
    window.dispatchEvent(setItemEvent);
  }

  // 小时为单位, -1代表永久缓存
  static set(key, value, expire = -1) {
    const wrappedValue = JSON.stringify({
      value,
      expire: expire > 0 ? Date.now() + expire * 3600 * 1000 : -1,
    });
    const storageKey = key;

    try {
      // storage.setItem(storageKey, wrappedValue);
      LocalStorage.setItem(storageKey, wrappedValue);
    } catch (e) {
      if (e.code === 22) {
        storage.clear();
        // storage.setItem(storageKey, wrappedValue);
        LocalStorage.setItem(storageKey, wrappedValue);
      }
    }
  }

  static get(key) {
    const storageKey = key;
    try {
      const wrappedValue = JSON.parse(storage.getItem(storageKey));
      if (!wrappedValue) {
        return null;
      }
      if (wrappedValue.expire < 0) {
        return wrappedValue.value;
      }
      if (Date.now() <= wrappedValue.expire) {
        return wrappedValue.value;
      }
      // return wrappedValue;

      LocalStorage.remove(key);
      return null;
    } catch (e) {
      // 不做处理
    }
  }

  static remove(key) {
    try {
      return storage.removeItem(key);
    } catch (e) {
      // 不做处理
    }
  }

  static clear() {
    try {
      return storage.clear();
    } catch (e) {
      // 不做处理
    }
  }
}
