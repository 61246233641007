import React from 'react';
import cn from 'classnames';
import Style from './style';

import { LitePagination } from 'zent';

const CollectionPager = ({ total, pageSize, current, onPageChange, settings }) => {
  const { style = 'style1', align } = settings || {};

  const maxCurrent = Math.ceil(total / pageSize) || 0;

  const handleLeft = () => {
    if (current === 1) {
      return;
    }

    onPageChange({
      current: current - 1,
      pageSize,
    });
  };

  const handleRight = () => {
    if (current + 1 > maxCurrent) {
      return;
    }
    onPageChange({
      current: current + 1,
      pageSize,
    });
  };

  if (total <= pageSize) {
    return null;
  }

  return (
    <Style align={align}>
      <div className='collection-pager'>
        {style === 'style1' && (
          <div className='collection-pager-style collection-pager-style-1'>
            <LitePagination
              current={current}
              pageSize={pageSize}
              total={total}
              onChange={(data) => {
                onPageChange(data);
              }}
            />
          </div>
        )}
        {style === 'style2' && (
          <div className='collection-pager-style collection-pager-style-2'>
            <svg
              className={cn('style-2-icon', 'icon-left', +current === 1 && 'style-2-icon-disabled')}
              aria-hidden='true'
              onClick={handleLeft}
            >
              <use xlinkHref='#iconic-jiantouzuo' />
            </svg>
            <svg
              className={cn('style-2-icon', 'icon-right', +current === maxCurrent && 'style-2-icon-disabled')}
              aria-hidden='true'
              onClick={handleRight}
            >
              <use xlinkHref='#iconic-jiantouyou' />
            </svg>
          </div>
        )}
        {style === 'style3' && (
          <div className='collection-pager-style collection-pager-style-3'>
            <div className='collection-pager-style-3-body'>
              <span
                className={cn('style-3-icon', 'icon-left', +current === 1 && 'style-3-icon-disabled')}
                onClick={handleLeft}
              >
                <svg aria-hidden='true'>
                  <use xlinkHref='#iconic-zuoyouqiehuan' />
                </svg>
              </span>
              <span
                className={cn('style-3-icon', 'icon-right', +current === maxCurrent && 'style-3-icon-disabled')}
                onClick={handleRight}
              >
                <svg aria-hidden='true'>
                  <use xlinkHref='#iconic-xiala' />
                </svg>
              </span>
            </div>
          </div>
        )}
      </div>
    </Style>
  );
};

export default CollectionPager;
