/**
 * 菜单相关的api接口封装
 */
import BaseApi from './base';

class ShareApi extends BaseApi {
  createPosterPicture(params) {
    return this._post('/api/posterController/createPosterPicture', params);
  }
  // 分享链接获取积分
  shareShopGetPoints(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.points.account.controller.web.OnlinePointsChangeRecordController/shareShop',
      params,
    );
  }
}

export default new ShareApi();
