import styled from 'styled-components';

export const Style = styled.div`
  height: 100%;
  .video-wrapper {
    height: 100%;
    position: relative;
    .video-body {
      height: 100%;
      background: #000;
      display: flex;
      position: relative;

      &.video-body-status-paused {
        &.video-body-status-time-0 {
          .video-cover {
            display: block;
            object-fit: contain;
          }
          .video-inner {
            visibility: hidden;
          }
        }
      }
      .video-inner {
        width: 100%;
      }
      .video-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
      }
    }

    .video-action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .play-button {
        height: 80px;
        width: 80px;
        border-radius: 80px;
        background: rgba(21, 22, 27, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 4px #fff;
        cursor: pointer;
      }
      .play-icon {
        width: 40px;
        height: 40px;
        fill: #fff;
      }
    }
  }
`;
