import styled from 'styled-components';

export const TimeStyle = styled.div`
  font-size: 10px;
  display: flex;
  .time-value {
    font-size: 12px;
    display: inline-block;
    text-align: center;
  }
  .time-item + .time-item {
    margin-left: 5px;
  }

  &.normal {
    font-size: 12px;
    color: #969799;
    .time-value {
      background: transparent;
      color: #969799;
      padding: 0;
    }
    .time-item + .time-item {
      margin-left: 2px !important;
    }
  }
  &.style2 {
    color: #969799;
    .time-value {
      background: ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
      color: #fff;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
    }
    .time-item + .time-item {
      margin-left: 5px;
    }
  }

  &.style3 {
    color: #fff;
    align-items: center;
    .time-value {
      background: #fff;
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
    }
    .time-item + .time-item {
      margin-left: 5px;
    }
  }
`;
