export const chineseCheckPattern = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
export const passwordPattern = /[^\u4E00-\u9FA5\s]{10,}$/;
export const letterPattern = /[a-zA-Z]/;

export const isValidPassword = (message: string) => {
  return (value: string) => {
    if (!passwordPattern.test(value)) {
      return {
        name: 'error',
        message,
      };
    }
  };
};

export const isSamePassword = (form, message: string) => {
  return (value: string) => {
    const { password } = form.getValue();
    console.log('isSamePassword', value, 'password', password);
    if (value !== password) {
      return {
        name: 'error',
        message,
      };
    }
  };
};

export const isEmpty = (message: string) => {
  return (value: string) => {
    if (!value.trim()) {
      return {
        name: 'error',
        message,
      };
    }
  };
};

// 只检测是否包含 @
export const isValidEmail = (message: string) => {
  return (value: string) => {
    if (!value.includes('@')) {
      return {
        name: 'error',
        message,
      };
    }
  };
};

// 只检测不包含中文
export const isValidMobile = (message: string) => {
  return (value: string) => {
    if (chineseCheckPattern.test(value)) {
      return {
        name: 'error',
        message,
      };
    }
  };
};

export const checkPhone = (value, intl) => {
  if (new RegExp(/[\u4e00-\u9fa5]+$/g).test(value)) {
    return {
      name: 'error',
      message: intl.formatMessage({
        id: 'd4f29244f20d4712aa95b76e8c6daf3c',
        defaultMessage: '该输入框不可输入汉字',
      }),
    };
  }
  // if (!new RegExp(/^\S*$/).test(value)) {
  //     return {
  //         name: 'error',
  //         message: intl.formatMessage({
  //             id: 'order.address.please_input_phone',
  //             defaultMessage: '请填写电话',
  //         }),
  //     };
  // }
  return null;
};

export const checkLetter = (message: string) => {
  return (value: string) => {
    if (letterPattern.test(value)) {
      return {
        name: 'error',
        message,
      };
    }
  };
};

export const AddressSettingsEnum =  {
  optional: 'optional',
  required: 'required',
  none: 'none',
  onlyLastName: 'only_last_name',
  onlyFirstName: 'only_first_name',
  all: 'all',
}

// 获取自定义地址校验
export const getCustomerAddressSettings = () => {
  const customerAddressSettings = !__isServer ? window?.global_data?.checkoutSettings?.customerAddressSettings?.[0] || {} : {};
  const {
    name: customerAddressNameSetting,
    address2: customerAddress2Setting,
    zip: customeAddressZipSetting,
  } = customerAddressSettings;

  return {
    customerAddressSettings,
    AddressSettingsEnum,
    isRequiredFirstName: [AddressSettingsEnum.onlyFirstName, AddressSettingsEnum.all].includes(customerAddressNameSetting),
    isRequiredLastName: [AddressSettingsEnum.onlyLastName, AddressSettingsEnum.all].includes(customerAddressNameSetting),
    isRequiredAddress2: customerAddress2Setting === AddressSettingsEnum.required,
    isRequiredZip: customeAddressZipSetting === AddressSettingsEnum.required,
  }
}

