// 对应装修的商品分组2.0
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import { Wrapper } from 'components/base';
import SwiperCore from 'swiper';
import { GridLayout, GridItem } from 'cpn/layout-ui/grid';
import { GroupCard } from 'components/page/group-buying/group-card';
import { buriedPoint } from 'utils/log-buried-point';

import { Style } from './style';

import { Swiper, SwiperSlide } from 'swiper/react';

import { View, NewButton } from '../../../../components/base';
import { useGroupBuyingList } from './hook';

SwiperCore.use([]);

const GoodsPrev = (props) => {
  const { kdtId, currency, language, settings = {}, item = {}, history } = props;

  const {
    product_name_size: productNameSize,
    product_price_size: productPriceSize,
    image_ratio: imageRatio,
    hover_show_second_pic: hoverShowSecondPic,
    show_badge: showBadge = true,
    show_group_button: showGroupButton,
    text_line_limit: textLineLimit,
    product_price_color: productPriceColor,
    text_color: textColor,
    is_card: isCard,
    card_color: cardColor,
  } = settings;
  return (
    <>
      <div className='feature-collection-grid-item'>
        <GroupCard
          goodsInfo={item}
          priceColor={productPriceColor}
          titleColor={textColor}
          titleSize={productNameSize}
          priceSize={productPriceSize}
          currency={currency}
          imageRatio={imageRatio}
          showBadge={showBadge}
          hoverShowSecondPic={hoverShowSecondPic}
          language={language}
          showGroupBuyBtn={showGroupButton}
          textLineLimit={textLineLimit}
          history={history}
          isCard={isCard}
          cardColor={cardColor}
          isDesignSection
        />
      </div>
    </>
  );
};

const FeaturedGroupBuying = (props) => {
  const { settings = {}, initialData, elemClass, data = [], history } = props;
  const { kdtId, shopSetting = {} } = initialData;
  const { currency, language } = shopSetting;

  const {
    grid = 3,
    rows = 2,
    link_style: linkStyle,
    link_text: linkText,
    bg_color: bgColor,
    box_width: boxWidth,
    text_align: textAlign = 'left',
    title_size: titleSize,
    sub_title_size: subTitleSize,
    text_line_limit: textLineLimit = 'auto',
    sub_title: subTitle = '',
    text_color: textColor,
  } = settings;

  const { groupList = [], totalList = [], pageSize } = useGroupBuyingList(rows, grid, kdtId);
  const { title } = props.settings;

  const hasMargin = !(boxWidth === 'full_screen');
  const itemProps = {
    kdtId,
    currency,
    language,
    settings,
    history,
  };

  useEffect(() => {
    if (totalList.length) {
      buriedPoint('goods_view', {
        goods_component_location: 'HOME_featured-group-buying',
        goods_component_location_name: '拼团分组',
        goodsList: totalList.slice(0, pageSize).map((item) => {
          // eslint-disable-next-line
          const { goodsId: goods_id, goodsType: goods_type = '', title } = item;
          return {
            goods_id,
            goods_type,
            title,
          };
        }),
      });
    }
  }, [totalList.length]);

  return (
    <Style
      className={cn(`cn-${boxWidth}`, `cn-${textAlign}`, elemClass)}
      grid={grid}
      style={{ background: bgColor, overflow: 'hidden' }}
      textLineLimit={textLineLimit}
    >
      <Wrapper paddingTop={hasMargin ? 'vContainer' : 0} paddingBottom={hasMargin ? 'vContainer' : 0}>
        <div className={cn('feature-collection-section')}>
          {(title || subTitle || linkText) && (
            <div className={cn('section-intro', { 'full-screen': boxWidth === 'full_screen' })}>
              {title && (
                <View
                  as='h3'
                  type='header'
                  size={titleSize}
                  className={cn('section-title')}
                  style={{
                    color: textColor,
                  }}
                >
                  {title}
                </View>
              )}
              {subTitle && (
                <View
                  size={subTitleSize}
                  className='section-sub-title'
                  dangerouslySetInnerHTML={{
                    __html: subTitle || '',
                  }}
                  style={{
                    color: textColor,
                  }}
                />
              )}

              {linkText && (
                <NewButton
                  type={linkStyle === 'button' ? 'primary' : 'arrow'}
                  className='link-button'
                  href='group-buying/list'
                >
                  {linkText}
                </NewButton>
              )}
            </div>
          )}

          <div className='section-list'>
            <GridLayout className='pc-style'>
              {groupList.map((item, index) => (
                <GridItem
                  key={`${item?.goodsId ?? 0 + index}`}
                  pGrid={grid}
                  pGap={40}
                  pTop={40}
                  className={cn(`collection-v2-item-${grid}`)}
                >
                  <GoodsPrev key={item?.goodsId} item={item} {...itemProps} />
                </GridItem>
              ))}
            </GridLayout>
            <div className='mobile-style'>
              <Swiper onSwiper={(swiper) => (window.swiper = swiper)} spaceBetween={25} scrollbar={{ draggable: true }}>
                {groupList.map((item, index) => (
                  <SwiperSlide key={index}>
                    <GoodsPrev key={item?.goodsId} item={item} {...itemProps} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </Wrapper>
    </Style>
  );
};

export default FeaturedGroupBuying;
