import React, { useEffect, useState, useRef, useMemo } from 'react';
import { PcSubMenu } from '../components/pc-submenu';
import { HeaderOperte } from '../components/header-operte';
import HeaderLogo from '../components/header-logo';
import { HeaderAnnouncement } from '../components/header-announcement';
import HeaderWrapper from './style';

export const PcHeader = (props) => {
  const {
    history = {},
    settings,
    initialData,
    elemClass,
    jumpToOtherPage,
    jumpToMenuPage,
    setHerderFullHeight,
    totalCountWish,
    menuDetail,
  } = props;
  const {
    main_linklist: mainLinkList = {},
    show_announcement: showAnnouncement,
    announcement_text: announcementText,
    announcement_link: announcementLink,
    logo_max_width: logoMaxWidth,
    color_bg: colorBg,
    color_text: colorText,
    align_logo: alignLogo,
    logo,
  } = settings;
  const { shopSetting = {} } = initialData;
  const [subMenuList, setSubMenuList] = useState([]);
  const [showHeadStatus, setShowHeadStatus] = useState(false);
  const [mouseTop, setMousePosition] = useState(0);
  const containerRef = useRef(null);
  const PcSubMenuRef = useRef(null);
  const isLeftLogo = alignLogo === 'left';
  const isCenterLogo = alignLogo === 'center';
  const showSubMenu = (evt, item) => {
    const top = evt.target.offsetTop + evt.target.clientHeight;
    const subMenu = item.shopTemplateMenuItemTreeNodeCustomerVO || [];

    setMousePosition(top);
    setSubMenuList(subMenu);
    setShowHeadStatus(!!subMenu.length);
  };

  useEffect(() => {
    setHerderFullHeight();
  }, [menuDetail.shopTemplateMenuItemTreeNodeCustomerVO]);
  return (
    <HeaderWrapper>
      <div onMouseLeave={() => setShowHeadStatus(false)} className={elemClass}>
        <HeaderAnnouncement
          showAnnouncement={showAnnouncement}
          colorBg={colorBg}
          colorText={colorText}
          announcementText={announcementText}
          announcementLink={announcementLink}
        />
        {isCenterLogo && logo && (
          <div className='header-center-logo-container'>
            <div className='header-center-logo'>
              <HeaderLogo jumpToOtherPage={jumpToOtherPage} logoMaxWidth={logoMaxWidth} logo={logo} />
              <HeaderOperte
                customClass='header-operate-container'
                initialData={initialData}
                history={history}
                totalCountWish={totalCountWish}
              />
            </div>
          </div>
        )}

        <div className='sub-header-container'>
          <div className='wrapper'>
            {(isLeftLogo || !logo) && (
              <HeaderLogo
                isLeftLogo={isLeftLogo}
                logoMaxWidth={logoMaxWidth}
                logo={logo}
                jumpToOtherPage={jumpToOtherPage}
                shopName={shopSetting.name}
              />
            )}
            <div className='menu-list'>
              {Array.isArray(menuDetail.shopTemplateMenuItemTreeNodeCustomerVO) &&
                menuDetail.shopTemplateMenuItemTreeNodeCustomerVO.map((item, index) => {
                  return (
                    <div
                      key={index}
                      ref={PcSubMenuRef}
                      onMouseOver={(evt) => showSubMenu(evt, item)}
                      onClick={(e) => jumpToMenuPage(e, item.url)}
                      className='menu-item'
                    >
                      {item.menuItemName}
                    </div>
                  );
                })}
            </div>
            {(isLeftLogo || !logo) && (
              <HeaderOperte
                jumpToOtherPage={jumpToOtherPage}
                initialData={initialData}
                totalCountWish={totalCountWish}
              />
            )}
          </div>

          <div
            className='sub-menu-content'
            style={{
              display: showHeadStatus ? 'block' : 'none',
              top: mouseTop,
            }}
            ref={containerRef}
          >
            {Array.isArray(subMenuList) && subMenuList.length > 0 && <PcSubMenu menuList={subMenuList} menuIndex={1} />}
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};
