import styled, { createGlobalStyle } from 'styled-components';

export const ReviewPosterStyle = createGlobalStyle`
    @media screen and (min-width: 769px) {
        .review-poster-dialog {
            /* border-radius: 4px !important; */
        }
    }

    @media screen and (max-width: 768px) {
        .review-poster-dialog {
            min-width: 100vw !important;
            max-width: 100vw !important;
            padding-top: 0 !important;
        }
    }
`;

export const Style = styled.div`
    
}`;
