import styled, { css } from 'styled-components';

const COVER_IMG_HEIGHT = '200';
const MOBILE_COVER_IMG_HEIGHT = '100';

const FULL_SCREEN = 'full_screen';

/**
 * 获取 样式风格
 *
 * */
export const getCollectionStyleCSS = (props) => {
  const { settingsStyle, showCoverImage, containerWidth } = props;
  // 不显示封面
  if (!showCoverImage) {
    //  宽度小于等于1280 没有左右边距
    if (containerWidth === '1280' || containerWidth === '960') {
      return css`
        padding: 40px 0;
      `;
    }
    return css`
      padding: 40px 80px;
    `;
  }

  // 样式风格1
  if (settingsStyle === 'style1') {
    return css`
      position: absolute;
      /* top: ${(props) => props.theme.getSpace([58, 80])};
            left: ${(props) => props.theme.getSpace([24, 80])};
            right: ${(props) => props.theme.getSpace([24, 80])}; */
      height: ${(props) => props.theme.getSpace([84, 282])};
      overflow: auto;
      display: flex;
      align-items: center;
    `;
  }
  return '';
};

/**
 * 获取宽度
 *
 * */
const getContainerWidthCSS = (props) => {
  const { containerWidth } = props;
  let computedWidth = '100%';
  let maxWidth = '100%';
  let marginTop = '';
  if (containerWidth === FULL_SCREEN) {
    computedWidth = '100%';
  } else if (containerWidth === 'margin_80') {
    computedWidth = `calc(100% - ${props.theme.getSpace([32, 160])})`;
    marginTop = '40px';
  } else {
    computedWidth = `calc(100% - ${props.theme.getSpace([32, 160])})`;
    maxWidth = `${containerWidth}px`;
    marginTop = '40px';
  }
  return css`
    width: ${computedWidth};
    max-width: ${maxWidth};
    margin-top: ${marginTop};
    margin-bottom: ${marginTop};
  `;
};

/**
 * 获取文本宽度
 *
 * */
const getTextWidthCSS = (props) => {
  const { textWidth = FULL_SCREEN } = props;
  let computedWidth = '100%';
  let maxWidth = '100%';
  if (textWidth === FULL_SCREEN) {
    computedWidth = '100%';
  } else if (textWidth === 'margin_80') {
    computedWidth = `calc(100% - ${props.theme.getSpace([32, 160])})`;
  } else {
    computedWidth = `calc(100% - ${props.theme.getSpace([32, 160])})`;
    maxWidth = `${textWidth}px`;
  }
  return css`
    width: ${computedWidth};
    max-width: ${maxWidth};
  `;
};

/**
 * 文本卡片样式
 *
 * */
const getTextCardCSS = (props) => {
  const { containerWidth, settingsStyle, showTextCard, textCardColor } = props;
  if (showTextCard && settingsStyle === 'style1') {
    return css`
      padding: ${(props) => props.theme.getSpace([24, 40])};
      margin: 0 0;
      background-color: ${textCardColor};
    `;
  } else if (settingsStyle === 'style2') {
    if (containerWidth !== FULL_SCREEN && containerWidth < 1440) {
      return css`
        padding: ${(props) => props.theme.getSpace([16, 40])} ${(props) => props.theme.getSpace([16, 0])};
      `;
    }

    return css`
      padding: ${(props) => props.theme.getSpace([16, 40])} ${(props) => props.theme.getSpace([16, 80])};
    `;
  }
  return css``;
};

/**
 * 蒙层样式
 *
 * */
const getMaskCSS = (props) => {
  const { showMask, maskColor } = props;
  if (showMask) {
    return css`
      .collection-img {
        position: relative;
      }
      .collection-img:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: ${maskColor};
      }
    `;
  }
  return css``;
};

/**
 * 显示封面
 *
 * */
const getShowCoverImageCSS = (props) => {
  const { showCoverImage, coverPicRatio } = props;
  if (showCoverImage && coverPicRatio === 'auto') {
    return css`
      background-color: #f2f3f5;
    `;
  } else if (showCoverImage) {
    return css`
      height: ${(props) => props.theme.getSpace([MOBILE_COVER_IMG_HEIGHT, COVER_IMG_HEIGHT])};
      background-color: #f2f3f5;
    `;
  }
  return css``;
};

/**
 * 图片填充方式
 *
 * */
const getCoverPicRatioCSS = (props) => {
  const { coverPicRatio } = props;

  const cssMap = {
    // 原比例
    auto: css`
      background-size: initial;
      background-repeat: no-repeat;
    `,
    // 自适应
    adaptive: css`
      background-size: contain;
      background-repeat: no-repeat;
    `,
    // 裁剪
    crop: css`
      background-size: cover;
      background-repeat: no-repeat;
    `,
    // 平铺
    tiled: css`
      background-size: initial;
      background-repeat: repeat;
    `,
  };
  return cssMap[coverPicRatio];
};

/**
 * 文本对齐方式
 *
 * */
const getTextAlignment = (props) => {
  const { textAlignment } = props;
  const cssMap = {
    left_center: css`
      text-align: left;
    `,
    center_center: css`
      text-align: center;
    `,
    right_center: css`
      text-align: right;
    `,
  };
  if (!textAlignment) {
    return cssMap.left_center;
  }
  return cssMap[textAlignment.replace(' ', '_')];
};

export const ImgSpreadBackground = styled.section`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  /* 获取图片填充方式 */
  ${(props) => getCoverPicRatioCSS(props)}
`;

const getTextShadow = (props) => {
  if (props.isEditMode && props.settingsStyle === 'style1') {
    return css`
      text-shadow: 1px 1px 3px rgb(0 0 0 / 16%);
    `;
  }
};

export const Style = styled.div`
  min-height: ${(props) => props.showCoverImage && props.theme.getSpace([MOBILE_COVER_IMG_HEIGHT, COVER_IMG_HEIGHT])};
  .collection-cover-pic-section {
    display: flex;
    justify-content: center;
    .collection-cover-pic-section-body {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .collection-img {
        /* 获取宽度 */
        ${(props) => getContainerWidthCSS(props)}
        /* 是否显示封面的样式 */
                ${(props) => getShowCoverImageCSS(props)}
                img {
          width: 100%;
        }
      }
      /* 获取蒙层样式 */
      ${(props) => getMaskCSS(props)}
      .collection-cover-pic-content {
        /* 获取宽度 */
        ${(props) => getTextWidthCSS(props)}
        /* 获取风格展示方式 */
                ${(props) => getCollectionStyleCSS(props)}
                .collection-cover-pic-content-text-card {
          box-sizing: border-box;
          max-width: 100%;
          max-height: 100%;
          width: 100%;
          /* 对齐方式 */
          ${(props) => getTextAlignment(props)}
          .text-card-body {
            display: inline-block;
            /* 文字卡片样式 */
            ${(props) => getTextCardCSS(props)}
            /* 文字阴影 */
                        ${(props) => getTextShadow(props)}
                        .collection-title {
              /* 标题颜色 */
              color: ${(props) => props.titleColor} !important;
            }
            .collection-desc {
              margin-top: 24px;
              * {
                /* 描述颜色 */
                color: ${(props) => props.descColor} !important;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
  }
`;
