/**
 * 商品相关的api接口封装
 */
import BaseApi from './base';

class GoodsApi extends BaseApi {
  // 获取推荐商品
  getRecommendGoods(params) {
    return this._get('/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/searchGoodsWithHot', params);
  }
  // 获取商品列表
  searchGoodsWithPage(params) {
    return this._get('/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/searchGoodsWithPage', params);
  }
  getAllCollectionGoods(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.goods.customer.web.GoodsCollectionConsumerController/getAllCollectionGoods',
      params,
    );
  }
  getAllTag(params) {
    return this._get('/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/getAllTags', params);
  }
  getGoodsById(params, options = {}) {
    return this._get(
      '/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/getGoodsById',
      { ...params, descriptionHtmlSource: 'pc' },
      {
        ...options,
        throttle: true,
      },
    );
  }
  /**
   * 获取分组详情
   * http://zanapi.qima-inc.com/site/service/view/964254
   * @param {*} params
   */
  getGoodsCollectionDetail(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/getGoodsCollectionDetail',
      params,
    );
  }

  searchGoodsCollectionsWithPage(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/searchGoodsCollectionsWithPage',
      params,
    );
  }

  getVideosByIds(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.file.controller.web.ShopVideoCustomerController/getVediosByIds',
      params,
    );
  }

  publishReviews(params) {
    return this._post('/api/soa/com.youzan.i18n.reviews.controller.web.ReviewsController/publishReviews', params);
  }

  getReviewsAggregateByGoodsId(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.reviews.controller.web.ReviewsController/getReviewsAggregateByGoodsId',
      params,
    );
  }

  searchReviewsWithPage(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.reviews.controller.web.ReviewsController/searchReviewsWithPage',
      params,
    );
  }

  // 获取商品第三方链接
  getThirdPlatformGoodsLinkInfo(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.goods.customer.web.GoodsThirdPlatformCustomerController/getThirdPlatformGoodsLinkInfo',
      params,
    );
  }

  /**
   * 商品评论图片上传
   */
  getReviewImageUploadToken() {
    return this._get('/api/soa/com.youzan.i18n.reviews.controller.web.ReviewsController/getUploadToken');
  }

  /**
   * 商品评论文件上传
   */
  getReviewsSettingsByKdtId(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.reviews.controller.web.ReviewsController/getReviewsSettingsByKdtId',
      params,
    );
  }

  searchGoodsSmartFilter(params) {
    const customerId = __isServer ? null : window?.global_data?.userInfo?.customerId;
    return this._get('/api/soa/com.youzan.i18n.smartfilter.controller.web.SmartFilterController/searchGoods', {
      featureManageType: 'goods',
      customerId,
      ...params,
    });
  }

  searchGoodsSmartFilter2(params, { locale, language }) {
    const customerId = __isServer ? null : window?.global_data?.userInfo?.customerId;
    if (locale === language || !params?.keyWord) {
      return this.searchGoodsSmartFilter(params);
    }
    return this._requestGateway('/api/searchGoods', {
      customerId,
      ...params,
      target: language,
    });
  }

  getGlobalConfig() {
    return this._get('/api/soa/com.youzan.i18n.goods.controller.web.GoodsController/getGlobalConfig');
  }

  bulkGetGoodsById(params) {
    return this._get('/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/bulkGetGoodsById', {
      ...params,
      descriptionHtmlSource: 'pc',
    });
  }

  getCartGoods(params) {
    return this._post('/api/soa/com.youzan.i18n.cart.controller.web.ShoppingCartController/getCartGoods', params);
  }
  // 是否为自提商品
  isPickupGoods(params) {
    return this._post('/api/soa/com.youzan.i18n.pickup.controller.web.PickupLocationController/isPickupGoods', params);
  }
}

export default new GoodsApi();
