import styled from 'styled-components';

export default styled.div`
  .header-announcement {
    line-height: 24px;
    padding: 12px 0;
    background: #1a1a1b;
    text-align: center;
  }
  .announcement_link {
    color: #fff;
  }
`;
