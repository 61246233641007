export const POSTER_TYPE = {
  shop: 'shop',
  wxShop: 'wxShop',
  goods: 'goods',
  wxGoods: 'wxGoods',
  wxGoodsGroupBuying: 'wxGoodsGroupBuying',
};

export const POSTER_TEMPLATE_LIST = {
  [POSTER_TYPE.shop]: 'shop',
  [POSTER_TYPE.wxShop]: 'wx-shop',
  [POSTER_TYPE.goods]: 'goods',
  [POSTER_TYPE.wxGoods]: 'wx-goods',
  [POSTER_TYPE.wxGoodsGroupBuying]: 'wx-goods-group-buying', // 拼团商品
};

export const POSTER_IMG_TYPE = {
  cdn: 'cdn',
  base64: 'base64',
};
