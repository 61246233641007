import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import HtIcon from 'client/components/base/icon';
import shopApi from '../../../../api/shop';
import { Style, Wrapper } from './style';
import debounce from 'lodash/debounce';

const GoodsAcceptPayment = ({ setAcceptPaymentNum, type, align }) => {
  const [paymentList, setPaymentList] = useState([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [containerInfo, setContainerInfo] = useState({
    scrollLeft: 0,
    scrollWidth: 0,
    offsetWidth: 0,
  });
  const [showArrows, setShowArrows] = useState(false);

  const containerRef = useRef();

  const getAllPaymentGateways = () => {
    return shopApi
      .getPaymentGatewaysByCategory({ kdtId: window.global_data?.kdtId })
      .then((res) => {
        // 仅保留唯一的自定义图标
        let payIconList = [];
        res.forEach((item) => {
          if (item?.paymentCategory === 'PAYPAL') {
            payIconList.push({
              logoUrl: item?.logoUrl,
              paymentMethodName: 'PAYPAL',
            });
          } else {
            payIconList = payIconList.concat(item?.allPaymentMethods);
          }
        });
        setPaymentList(payIconList);
        if (setAcceptPaymentNum) setAcceptPaymentNum(payIconList?.length);
      })
      .catch(() => {
        setPaymentList([]);
      });
  };

  useEffect(() => {
    getAllPaymentGateways();
  }, []);

  const handleArrowClick = (direction) => {
    const containerDom = containerRef.current;
    const currentLeft = containerDom.scrollLeft;
    const scrollDis = containerDom.offsetWidth;
    containerDom.scrollTo({
      left: direction === 'left' ? currentLeft - scrollDis : currentLeft + scrollDis,
      top: 0,
      behavior: 'smooth',
    });
  };

  const setArrowStatus = (scrollLeft, scrollWidth, offsetWidth) => {
    if (scrollLeft === 0) {
      // 最左边
      setShowLeftArrow(false);
      setShowRightArrow(true);
    } else if (scrollLeft >= scrollWidth - offsetWidth) {
      // 最右边
      setShowLeftArrow(true);
      setShowRightArrow(false);
    } else {
      // 中间
      setShowLeftArrow(true);
      setShowRightArrow(true);
    }
  };
  const handleWrapperScroll = debounce(() => {
    console.log(containerRef.current.scrollLeft);
    const { scrollLeft, scrollWidth, offsetWidth } = containerRef.current;
    setContainerInfo({
      scrollWidth,
      offsetWidth,
      scrollLeft,
    });
    setArrowStatus(scrollLeft, scrollWidth, offsetWidth);
  }, 50);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, offsetWidth } = containerRef.current;
      setContainerInfo({
        scrollWidth,
        offsetWidth,
        scrollLeft,
      });
      if (scrollWidth > offsetWidth) {
        setShowArrows(true);
      }
    }
  }, [containerRef, paymentList.length]);

  const onMouseEnter = () => {
    const { scrollLeft, scrollWidth, offsetWidth } = containerRef.current;
    setContainerInfo({
      scrollWidth,
      offsetWidth,
      scrollLeft,
    });
    if (scrollWidth > offsetWidth) {
      setShowArrows(true);
      setArrowStatus(scrollLeft, scrollWidth, offsetWidth);
    }
  };
  const onMouseLeave = () => {
    setShowLeftArrow(false);
    setShowRightArrow(false);
  };

  const showRightArrowWrapper =
    showArrows &&
    containerInfo.scrollWidth > containerInfo.offsetWidth &&
    containerInfo.scrollWidth - containerInfo.offsetWidth !== containerInfo.scrollLeft;

  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Style nowrap={type === 'nowrap'} align={align} ref={containerRef} onScroll={handleWrapperScroll}>
        {paymentList.map((item) => {
          return (
            <div className='img-wrapper goods-accept-payment-logo' key={item?.paymentMethodName}>
              <img src={item?.logoUrl} alt={item?.paymentMethodName} />
            </div>
          );
        })}
        <div className='fix-style' />
      </Style>
      {showArrows && containerInfo.scrollLeft > 0 && (
        <div className='arrow-wrapper arrow-left' onClick={() => handleArrowClick('left')}>
          {showLeftArrow && (
            <div className='arrow-icon-wrapper'>
              <HtIcon name='iconxiayige' size='mini' className='arrow-icon' />
            </div>
          )}
        </div>
      )}

      {showRightArrowWrapper && (
        <div className='arrow-wrapper arrow-right' onClick={() => handleArrowClick('right')}>
          {showRightArrow && (
            <div className='arrow-icon-wrapper'>
              <HtIcon name='iconxiayige' size='mini' className='arrow-icon' />
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default GoodsAcceptPayment;
