import styled from 'styled-components';

export const Style = styled.div`
  width: 100%;
  overflow: hidden;
  .news-letter {
    height: 100%;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    padding: ${(props) => props.theme.getSpace([12, 14, 16])};
    /* height: 242px; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-end; */
    &-left {
      text-align: left;
      .input-btn {
        justify-content: flex-start;
      }
    }
    &-center {
      text-align: center;
      .input-btn {
        justify-content: center;
      }
    }
    &-right {
      text-align: right;
      .input-btn {
        justify-content: flex-end;
      }
    }
    .news-letter-title {
    }
    .news-letter-text {
      margin-top: ${(props) => props.theme.getSpace(props.theme.vText)};
    }
    .input-btn {
      display: flex;
      margin-top: ${(props) => props.theme.getSpace(props.theme.vTextButtons)};
    }
    .footer-news-email-input {
      height: ${(props) => props.theme.getSpace([27, 29, 34])};
      flex: 1;
      max-width: 400px;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .footer-news-email-btn {
      height: 34px !important;
      line-height: 34px !important;
      .button.primary {
        border-radius: 0 var(--border_radius_form) var(--border_radius_form) 0 !important;
      }
    }
  }
`;
