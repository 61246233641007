import styled, { createGlobalStyle } from 'styled-components';

export default styled.div``;

export const GlobalStyle = createGlobalStyle`
    .distribution-poster-dialog{
        width:640px;
        text-align: center;
        text-align: center;
        .zent-dialog-r-title-text{
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;   
            color:#15161B;
        }
        .zent-dialog-r-body{
            padding:0;
        }
        .poster-contain{
            padding:20px 0;
            box-sizing: border-box;
            max-height: 511px;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            .wx-poster {
                border: 1px solid #eee;
                display: block;
                margin: 0 auto;
                width: 250px;
                height: auto;
            }
            .qr-code-introduction{
                margin-top:16px;
                text-align:center;
            }            
            .download-poster{
                display: none;
            }
        }

        .share-footer {
            padding:16px 16px 0;
            border-top: 1px solid #ebedf0;
            display: flex;
            justify-content: flex-end;
        }
    }
    @media screen and (max-width :750px){
        .distribution-poster-dialog{
            width:85%;
            max-width: 500px !important;
            min-width: 0 !important;
            text-align: center;
            text-align: center;
            .zent-dialog-r-title-text{
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;   
                color:#15161B;
            }
            .zent-dialog-r-body{
                padding:0;
                -webkit-overflow-scrolling:touch;
            }
            .poster-contain{
                padding:16px 0;
                max-height: 460px;
                overflow-y: auto;
                .wx-poster {
                    border: 1px solid #eee;
                    display: block;
                    margin: 0 auto;
                    width: 240px;
                    height: auto;
                }
                .qr-code-introduction{
                    margin-top:16px;
                    text-align:center;
                }            
            }

            .share-footer {
                padding:16px 16px 0;
                border-top: 1px solid #ebedf0;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
`;
