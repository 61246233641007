import styled from 'styled-components';

export default styled.div`
  font-size: 0;
  // logo在左边
  &.pc-header-logo {
    margin-right: 8px;
  }
  /* .logo {
        max-width: 250px;
    } */
  img {
    max-height: ${(props) => `${props.pcHeight}px`};
  }
  .shop-name {
    font-size: var(--base-header-font-16-14);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    font-weight: 300;
    line-height: 20px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    margin-top: -6px;
    color: ${(props) => props.logoColor};
  }
  @media screen and (max-width: 768px) {
    .shop-name {
      line-height: normal;
    }
    .mobile-header-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .logo {
      max-width: 200px;
      display: block;
    }
  }
`;
