import React, { useState } from 'react';
import { useRootContext } from 'client/pages/context/root';


const isLogin = __isServer ? false : !!window?.global_data?.userInfo?.customerId;
export const isRequireLogin = __isServer ? false : Boolean(window.global_data?.checkoutSettings?.requiresLogin);

// 强制登录按钮高阶组件
export const RequireLoginWrapper = (WrapComponent) => {
  return (props) => {
    const [requireLogin, setRequireLogin] = useState(isRequireLogin);
    const { setLoginDialogInfo }: any = useRootContext();

    const handleClick = (e) => {
      if (requireLogin && !isLogin) {
        setLoginDialogInfo({
          visible: true,
        });
        // 阻止事件向下传递
        e.stopPropagation();
      }
    };

    return (
      <div onClickCapture={handleClick}>
        <WrapComponent {...props} />
      </div>
    );
  };
}

// 强制登录 hook
export const useRequireLogin = () => {
  const [requireLogin, setRequireLogin] = useState(isRequireLogin);
  const { setLoginDialogInfo }: any = useRootContext();

  const checkedRequireLogin = (action = null as any) => {
    if (typeof action === 'function') {
      return (params) => {
        const needLogin = requireLogin && !isLogin;
        return needLogin ?
          setLoginDialogInfo({
            visible: true,
          }) :
          action(params);
      }
    } else {
      const needLogin = requireLogin && !isLogin;
      if (needLogin) {
        setLoginDialogInfo({
          visible: true,
        });
        if (action && typeof action.preventDefault === 'function') {
          action.preventDefault();
        }
        if (action && typeof action.stopPropagation === 'function') {
          action.stopPropagation();
        }
        return true;
      } else {
        return false;
      }
    }
  };

  return {
    requireLogin,
    checkedRequireLogin,
  }
}