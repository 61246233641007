import styled from 'styled-components';

export const Style = styled.div`
  margin-top: 24px;
  .goods-name {
    color: var(--color_body_text);
  }
  .zent-grid-th {
    font-weight: 400 !important;
  }
  .mobile-cart-list {
    padding: 24px 16px;
    box-sizing: border-box;
  }

  .price-part {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .gift-list {
    margin-top: 24px;
  }
  .custom-number-input {
    border-radius: var(--border_radius_btn) !important;
  }

  .gift-prompt {
    background-color: transparent;
    padding: 0px !important;
    .select-tip {
      margin-left: 8px;
      color: var(--color_body_text);
      font-size: 14px;
      font-weight: 400;
    }
    .hunt-prompt-right {
      font-size: 14px;
    }
  }
`;
