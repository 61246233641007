import styled from 'styled-components';

export default styled.div`
  .card {
    display: inline-block;
    width: auto;
    padding: ${(props) => props.theme.getSpace(props.theme.pCards)};
    background-color: ${(props) => props.bgColor};
  }
`;
