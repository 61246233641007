import { hexToRGBA, RGBAToHex } from 'utils/color';
import styled from 'styled-components';

export const Style = styled.div`
  position: relative;
  grid-area: custom-menu;
  color: ${(props) => props.color};
  width: 100%;
  height: 100%;
  /* width: ${(props) => `${props.width}px`}; */
  text-align: left;
  box-sizing: border-box;
  .scroll-container {
    /* overflow-y: scroll; */
  }
  .scroll-container ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  /* width: ${(props) => props.theme.getSpace([140, 200, 260])}; */
  * {
    box-sizing: border-box;
  }
  .scroll-container-ul,
  .scroll-container-nav {
    height: 100%;
  }
  .scroll-container-nav {
    position: relative;
  }
  .scroll-container-ul {
    overflow: scroll;
    background-color: ${(props) => props.cardColor};
  }
  .nav-item {
    position: relative;
    padding: ${(props) => `${props.padding}px`} 16px;
    &:hover {
      background-color: ${(props) => hexToRGBA(props.color, 0.1)};
    }
  }

  a {
    color: ${(props) => props.color};
    word-break: break-word;
    &:hover {
      color: ${(props) => props.hoverTextColor};
    }
  }
  .menu-item-content a {
    color: ${(props) => props.submenuTextColor};
    &:hover {
      color: ${(props) => props.submenuHoverTextColor};
    }
  }
  .menu-item-content {
    position: absolute;
    left: 100%;
    z-index: 100;
    max-height: 100%;
    background-color: ${(props) => props.submenuCardColor};
    max-width: ${(props) => props.menuItemMaxWidth + 'px'};
    width: max-content;
    visibility: hidden;
    overflow-y: scroll;
    &.active {
      visibility: visible;
    }
    &.hidden {
      display: none;
    }
  }

  .scrollup-icon {
    visibility: ${(props) => (props.showScrollUp ? 'visible' : 'hidden')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    text-align: center;
    svg {
      width: 24px;
      height: 24px;
      transform: rotate(270deg);
    }

    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 1s;
    fill: ${(props) => props.textColor};

    background-image: linear-gradient(
      to bottom,
      ${(props) => `${hexToRGBA(RGBAToHex(props.cardColor), 1)} 0%`},
      ${(props) => hexToRGBA(RGBAToHex(props.cardColor), 0)} 100%
    );
  }

  .scrolldown-icon {
    transition: opacity 1s;
    visibility: ${(props) => (props.showScrollDown ? 'visible' : 'hidden')};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    text-align: center;
    svg {
      width: 24px;
      height: 24px;
      fill: ${(props) => props.textColor};
      transform: rotate(90deg);
    }

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: linear-gradient(
      to bottom,
      ${(props) => `${hexToRGBA(RGBAToHex(props.cardColor), 0)} 0%`},
      ${(props) => hexToRGBA(RGBAToHex(props.cardColor), 1)} 100%
    );
  }
  .seperate {
    margin-left: 4px;
    margin-right: 4px;
  }

  @media screen and (max-width: 800px) {
    text-align: center;
    li {
      padding: 8px;
    }
    padding-left: 8px;

    .scrollup-icon,
    .scrolldown-icon {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
