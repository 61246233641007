import styled, { css } from 'styled-components';
import { gapMap } from '../../../const/standard';
import isArray from 'lodash/isArray';
import { getPadding } from '../padding/style';
import { getMargin } from '../margin/style';

export default styled.div`
  ${(props) => getMargin(props)}
  ${(props) => getPadding(props)}
`;
