/**
 * @description 以下url中发生的错误都会标记 alert 的标识
 */
export const ATTENTION_URLS = [];

/** 只上传js内置错误类型 */
export const TYPE_NEED_LOG = [
  'EvalError',
  'InternalError',
  'RangeError',
  'ReferenceError',
  'SyntaxError',
  'TypeError',
  'URIError',
];

export const TAG_KEYS = ['kdtId', 'sid', 'token', 'locale', 'timeStr'];
