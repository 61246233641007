import React, { useMemo } from 'react';
import { Icon } from 'components/base';
import get from 'lodash/get';
import cn from 'classnames';
import Style from './style';
const noop = () => {};
const CAN_NOT_MOVE_SECTIONS = ['footer', 'announcement-bar', 'header-v2'];

function ShotCut(props) {
  const {
    onSectionMoveUp = noop,
    onSectionMoveDown = noop,
    onSectionDelete = noop,
    onSectionCopy = noop,
    moveConfig = {},
    className,
  } = props;

  return (
    <Style className={className}>
      <div className='short-cut-item' onClick={onSectionMoveUp}>
        <Icon
          name='iconshangyi'
          size='small'
          fill='#fff'
          className={cn({
            'move-disabled': !moveConfig.up,
          })}
        />
      </div>
      <div className='short-cut-item roate180' onClick={onSectionMoveDown}>
        <Icon
          name='iconshangyi'
          size='small'
          fill='#fff'
          className={cn({
            'move-disabled': !moveConfig.down,
          })}
        />
      </div>
      <div className='short-cut-item' onClick={onSectionCopy}>
        <Icon name='iconfuzhizujian' size='small' fill='#fff' />
      </div>
      <div className='short-cut-item' onClick={onSectionDelete}>
        <Icon name='iconic_Delete' size='small' fill='#fff' />
      </div>
    </Style>
  );
}

export default ShotCut;
