// 需要设置css变量的集合
export const globalSettingForm = {
  form_select_color: '',
  form_title_color: '',
  form_card_color: '',
  form_page_bg_color: '',
  form_border_color: '',
  form_bg_color: '',
  form_name_size: '',
  form_title_size: '',
  form_text_align: '',
  form_title_scale: '',
  form_color_bg: '',
  form_background_image: '',
};
export enum FormKey {
  'FormCheckbox' = 'form-checkbox',
  'FormMulText' = 'form-mul-text',
  'FormRadio' = 'form-radio',
  'FormSelect' = 'form-select',
  'FormText' = 'form-text',
  'FormOption' = 'form-option',
  'FormInput' = 'form-input',
}

export enum OPTION_TYPE {
  radio = 'form-radio',
  checkbox = 'form-checkbox',
  select = 'form-select',
}

export enum DIRECTION {
  portrait = 'portrait', // 纵向
  transverse = 'transverse', // 横向
}
