import styled from 'styled-components';

export const Button = styled.button`
  outline: none;
  border: none;
  background: none;
  position: relative;
  z-index: 1;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 20%;
    z-index: -1;
  }
  &.ht-button-full {
    width: 100%;
  }
  &.ht-button-disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.ht-button-hidden {
    display: none;
  }
  &.ht-button-size-large {
    ${(props) => props.theme.getBaseSize(props.theme.h10)};
    padding: ${(props) => props.theme.getSpace([10, 11, 12])} ${(props) => props.theme.getSpace([24, 28, 32])};
  }
  &.ht-button-size-medium {
    ${(props) => props.theme.getBaseSize(props.theme.h11)};
    padding: ${(props) => props.theme.getSpace([8, 9, 10])} ${(props) => props.theme.getSpace([16, 20, 24])};
  }
  &.ht-button-size-small {
    ${(props) => props.theme.getBaseSize(props.theme.h12)};
    padding: ${(props) => props.theme.getSpace([6, 7, 8])} ${(props) => props.theme.getSpace([8, 12, 16])};
  }
  &.ht-button-type-primary {
    background: ${(props) => props.theme.colorButton};
    color: ${(props) => props.theme.colorButtonText};
    border-radius: var(--border_radius_btn);
    :hover::after {
      background: ${(props) => props.theme.colorCards[20]};
    }
    :active::after {
      background: ${(props) => props.theme.colorBodyTexts[20]};
    }
    &.ht-button-disabled::after {
      background: ${(props) => props.theme.colorCards[60]};
    }
  }
  &.ht-button-type-secondary {
    border: 1px solid ${(props) => props.theme.colorSmallButtonTextBorder};
    color: ${(props) => props.theme.colorSmallButtonTextBorder};
    background: ${(props) => props.theme.colorSmallButtonBg};
    border-radius: var(--border_radius_btn);
    :hover::after {
      background: ${(props) => props.theme.colorBodyTexts[20]};
    }
    :active::after {
      background: ${(props) => props.theme.colorCards[20]};
    }
    &.ht-button-disabled::after {
      background: ${(props) => props.theme.colorCards[60]};
    }
  }
  &.ht-button-plain {
    color: ${(props) => props.theme.colorButton};
    background: transparent;
  }
  &.ht-button-size-full {
    display: block;
    width: 100%;
  }
`;
