import styled from 'styled-components';

export default styled.div`
  background: #fff;

  .hidden-block {
    display: none !important;
  }
  .input-container {
    display: flex;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .zent-form-label {
    display: none;
  }

  .zent-form-control {
    margin-bottom: 16px !important;
    .zent-form-control-content {
      margin-left: 0px;
      .zent-input-wrapper {
        width: 100%;
        input {
          width: 100%;
          height: 50px;
          padding-left: 16px;
        }
        &.zent-input--size-normal {
          height: auto;
        }
      }
    }
  }

  .name-input {
    flex: 1;
  }

  .sub-btn {
    margin-top: var(--pm-24-16);
    width: 100%;
  }

  .hunt-select-trigger {
    height: 50px;
    &.active {
      height: 50px;
    }
    .hunt-select-switch {
      line-height: 56px;
    }
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .label-text {
    display: none;
  }
  @media screen and (max-width: 750px) {
    .input-container {
      flex-direction: column;
    }
    .name-input {
      margin-right: 0;
    }
    .label-text {
      margin-top: 0px;
    }
  }
`;
