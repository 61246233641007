import groupBuyingApi from 'api/group-buying';
import { useState, useEffect } from 'react';
import { filterEmptyObj } from 'utils';

export const useGroupInfo = ({ goodsId, customerId }) => {
  const [groupInfo, setGroupInfo] = useState({
    minGroupPrice: 0,
    participateNum: 0,
    activityId: '',
    groupOrderInfo: {},
    activityEndAt: '',
  });

  useEffect(() => {
    if (!goodsId) return;
    const params = filterEmptyObj({
      goodsId,
      optionsParam: {
        withGroup: 1,
      },
    });
    groupBuyingApi.getPromotionActivityDescriptionByGoodsId(params).then((res) => {
      setGroupInfo(res?.groupActivityDescription);
    });
  }, [goodsId]);

  return {
    activityEndAt: groupInfo?.activityEndAt,
    minGroupPrice: groupInfo?.minGroupPrice,
    participateNum: groupInfo?.participateNum,
    activityId: groupInfo?.activityId,
  };
};
