import React, { useMemo, useEffect } from 'react';
import { Style, ImgSpreadBackground } from './style';
import { Text, View } from 'components/base';
import { useIntl } from 'react-intl';
import { DEFAULT_COLLECTION_COVER_PIC } from '../../default-settings';
import useFillImage from 'hook/use-fill-image';
import { getSectionData, htmlTranText } from '../../hooks/use-collection-theme';

/**
 * 分组封面
 * */
const CollectionCoverPic = ({ theme, isEdit: isEditMode = false, ...rest }) => {
  const intl = useIntl();

  const collectionCoverPicSectionData = Object.assign(
    DEFAULT_COLLECTION_COVER_PIC,
    getSectionData(theme, 'collection-cover-pic') || {},
  );
  const settings = collectionCoverPicSectionData?.settings || {};
  // 分组封面的风格
  const settingsStyle = settings?.style;
  const newSetting = useMemo(() => {
    if (!settings) {
      return {};
    }
    return settings?.settings[settings?.style];
  }, [settings]);

  const {
    show_cover_image: showCoverImage, // true

    container_width: containerWidth, // "full_screen"
    cover_pic_ratio: coverPicRatio, // "crop"
    text_alignment: textAlignment, // "left center"
    text_width: textWidth,
    title_size: titleSize, // 3
    title_color: titleColor, // "rgba(255,255,255,1)"

    desc_size: descSize, // 9
    desc_color: descColor, // "rgba(255,255,255,1)"

    show_mask: showMask, // false
    mask_color: maskColor, // "rgba(0,0,0,0.3)"

    show_text_card: showTextCard, // false
    text_card_color: textCardColor, // "rgba(0,0,0,0.3)",
    compress = false,
  } = newSetting;

  const group = rest?.initialData?.group || {};
  const newGroup = useMemo(() => {
    // 在预览模式下 全部商品页面会有分组显示
    if (isEditMode) {
      const title = intl.formatMessage({ id: 'Product_group_title', defaultMessage: '商品分组标题' });
      const descriptionHtml = intl.formatMessage({
        id: 'cd217e4028cf4ecf92c9f030c2d9ab97',
        defaultMessage: '通过图片以及文本描述您的分组内容',
      });
      return {
        title,
        descriptionHtml,
        collectionId: 'all-ID',
        cover: 'https://img01.yzcdn.cn/upload_files/2021/07/27/FoB_zdM_CSw1ts0dMZVTrGnogTnn.png',
      };
    }
    return group;
  }, [group, isEditMode, intl]);

  const { collectionId, title, descriptionHtml = '' } = newGroup;
  const { image: cover } = useFillImage(newGroup?.cover, 'origin', {
    toWebp: true,
    compress,
  });

  // 文本宽度兼容旧数据
  const newTextWidth = useMemo(() => {
    let newWidth = 'margin_80';
    if (!textWidth && containerWidth) {
      switch (containerWidth) {
        case 'full_screen':
          newWidth = 'margin_80';
          break;
        case '1440':
          newWidth = '1280';
          break;
        case '1280':
          newWidth = '960';
          break;
        case '960':
          newWidth = '800';
          break;
        default:
          newWidth = 'margin_80';
          break;
      }
    }
    return newWidth;
  }, [textWidth]);

  // 不是分组 不展示
  if (!collectionId) {
    return null;
  }

  return (
    <Style
      isEditMode={isEditMode}
      settingsStyle={settingsStyle}
      showCoverImage={showCoverImage && cover}
      containerWidth={containerWidth}
      textWidth={newTextWidth}
      descColor={descColor}
      titleColor={titleColor}
      showTextCard={showTextCard}
      textCardColor={textCardColor}
      showMask={showMask}
      maskColor={maskColor}
      textAlignment={textAlignment}
      className={rest.elemClass}
      coverPicRatio={coverPicRatio}
    >
      <div className='collection-cover-pic-section'>
        <div className='collection-cover-pic-section-body'>
          {showCoverImage && cover && (
            <div className='collection-img'>
              {coverPicRatio === 'auto' ? (
                <img src={cover} />
              ) : (
                <ImgSpreadBackground imageUrl={cover} coverPicRatio={coverPicRatio} />
              )}
            </div>
          )}
          <div className='collection-cover-pic-content'>
            <div className='collection-cover-pic-content-text-card'>
              <div className='text-card-body'>
                <div className='collection-title'>
                  <Text size={titleSize}>{title}</Text>
                </div>
                {descriptionHtml && (
                  <div className='collection-desc'>
                    {settingsStyle === 'style1' ? (
                      <Text size={descSize}>
                        <span dangerouslySetInnerHTML={{ __html: htmlTranText(descriptionHtml) }}></span>
                      </Text>
                    ) : (
                      <View size={descSize}>
                        <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                      </View>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default CollectionCoverPic;
