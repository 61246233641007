import React, { useState, useContext, useCallback } from 'react';
import { Button } from 'zent';
import View from 'components/base/view';
import { HtSkuPrice } from 'cpn/goods-price';
import NewLink from 'cpn/new-link';
import { useIntl } from 'react-intl';
import { getValueByLang } from 'helper/discount';
import groupBuyingApi from 'api/group-buying';
import GoodsWishBtn from '../../wish-btn';
import { Style, BadgeStyle, TitleStyle, TextStyle } from './style';
import { ShopModal } from '../shop-modal';
import cn from 'classnames';
import RootContext from '../../../../pages/context/root';
import GoodsImage from '../../../../pages/design/components/goods-image';
import GoodsColorSku from '../../../../pages/design/components/goods-color-sku';
import GoodsScore from '../../../../pages/design/components/goods-score';
import { useThemeContext } from '../../../../hook/global-theme';
import { getReviewCountStr } from 'utils/formate';
import { useRequireLogin } from 'cpn/require-login-wrapper';
import { isNaN } from 'lodash';

/** 拼团角标样式
 * type: first  two  three
 *
 */
const GroupBuyingBadge = ({ type = 'first', discountOff, badgeSize = 'normal' }) => {
  const intl = useIntl();
  const { language } = useContext(RootContext);
  const discount = getValueByLang(discountOff, language, true);
  if (type === 'three') {
    return (
      <BadgeStyle className={cn(type, badgeSize)}>
        <div className='group-buy-content'>
          <div className='content-text'>SALE</div>
        </div>
      </BadgeStyle>
    );
  }

  return (
    <BadgeStyle className={cn(type, badgeSize)}>
      <div className='group-buy-content'>
        <div className='content-value'>{discount}</div>
        <div className='content-text'>
          {intl.formatMessage({
            id: '80d035631d1640c2becaa95d44d84771',
            defaultMessage: 'OFF',
          })}
        </div>
      </div>
      <div className={cn('triangle')} />
    </BadgeStyle>
  );
};

export const GroupCard = (props) => {
  const intl = useIntl();
  const {
    // imgUrl = '',
    title = '',
    minPrice = 2,
    compareAtPrice = 10,
    goodsId,
    // isDeleted = false,
    quantity,
    // offShelf = false,
    discountOff = 10,
    activityId,
    participateNum = 1,
    wishList = {},
    vendor = '',
    goodsReviewsInfo = {},
    soldNum = 0,
    // discountInfo = {},
    // bestVariant,
  } = props?.goodsInfo ?? {};

  const {
    goodsInfo = {},
    containerWidth,
    history,
    kdtId,
    customerId,
    titleSize = 10,
    priceSize = 10,
    currency,
    badgeSize,
    imageRatio = '1-1',
    showBadge = true,
    hoverShowSecondPic = true,
    showGroupBuyBtn = false,
    showWish = false,
    language,
    showTitle = true,
    showPrice = true,
    alignText = 'left',
    priceColor = '',
    titleColor = '',
    textLineLimit = '2', // auto / 1 / 2 /3 /4/5/6
    isCard = false,
    cardColor = '',
    commentConfig = {},
    showSale = false,
    showVendor = false,
    showProductColorSku = false,
    returnGoodsNameNode = () => {},
    returnBottomInfoNode = () => {},
    goodsNameHeight = 0,
    bottomInfoHeight = 0,
    isDesignSection = false,
  } = props;
  const [goodsDetail, setGoodsDetail] = useState({});
  const [showSkuModal, setShowSkuModal] = useState(false);
  const [currentSkuImage, setCurrentSkuImage] = useState('');
  const themeContext = useThemeContext();
  const { isMobile } = themeContext;

  const { checkedRequireLogin } = useRequireLogin();

  const goodsNameRef = useCallback((node) => {
    if (node !== null) {
      returnGoodsNameNode(goodsId, node);
    }
  }, []);

  const bottomInfoRef = useCallback((node) => {
    if (node !== null) {
      returnBottomInfoNode(goodsId, node);
    }
  }, []);

  // const imageInfo = {
  //     goodsInfo: props.goodsInfo,
  //     coverImgUrl: imgUrl,
  //     hoverImgUrl: imgUrl,
  //     hoverShowSecondPic,
  //     imageRatio,
  // };

  const fetchGoods = () => {
    const kdtId = window.global_data?.kdtId;
    if (!kdtId || isNaN(Number(goodsId)) || isNaN(Number(activityId))) return {};
    return groupBuyingApi.getGroupActivityGoodsById({
      kdtId,
      goodsId,
      activityId,
    });
  };

  const onGroupBuying = () => {
    fetchGoods().then((res) => {
      setGoodsDetail(res);
      setShowSkuModal(true);
    });
  };

  return (
    <Style textColor='#000' priceColor={priceColor} isCard={isCard} cardColor={cardColor} titleColor={titleColor}>
      <NewLink
        href={`/group-buying/product?goodsId=${goodsId}&activityId=${activityId}`}
        className={isDesignSection ? 'group-design-section' : ''}
      >
        {showBadge && <GroupBuyingBadge discountOff={discountOff} badgeSize={badgeSize} language={language} />}
        {/* 心愿单按钮 */}
        {showWish && (
          <GoodsWishBtn
            className='group-goods-wish'
            settings={containerWidth}
            data={{
              wishList,
              goodsId,
            }}
            initData={{
              kdtId,
              userInfo: {
                customerId,
              },
            }}
          />
        )}

        {/* <GoodsImage {...imageInfo} skuImgUrl={currentSkuImage} /> */}
        <div className='goods-image-container'>
          <GoodsImage
            goodsInfo={goodsInfo}
            imageRatio={imageRatio}
            hoverShowSecondPic={hoverShowSecondPic}
            skuImgUrl={currentSkuImage}
            className={cn('group-goods-card-image')}
          />
          {!quantity && (
            <span className={cn('sell-out-tag')}>
              {intl.formatMessage({ id: 'goods.tips.none_sales' }).toUpperCase()}
            </span>
          )}
        </div>
        <div style={{ width: '100%', position: 'relative' }}>
          <GoodsColorSku
            settings={{ show_product_color_sku: showProductColorSku }}
            data={goodsInfo}
            onChange={(item) => {
              setCurrentSkuImage(item);
            }}
          />
        </div>
        <TextStyle alignText={alignText} isCard={isCard} cardColor={cardColor}>
          {showTitle && (
            <TitleStyle textLineLimit={textLineLimit} style={{ minHeight: goodsNameHeight, textAlign: alignText }}>
              <View ref={goodsNameRef} className='title' size={titleSize} color={titleColor}>
                {title}
              </View>
            </TitleStyle>
          )}

          {showPrice && (
            <View className='space'>
              <HtSkuPrice price={minPrice} compareAtPrice={compareAtPrice} currency={currency} size={priceSize} />
            </View>
          )}
          {showPrice && (
            <div className='tag space'>
              <span className='tag-text'>
                {intl.formatMessage(
                  {
                    id: 'd39eaca590cd472797d774612dd8a927',
                    defaultMessage: `${participateNum}人团`,
                  },
                  {
                    num: participateNum,
                  },
                )}
              </span>
            </div>
          )}
        </TextStyle>
      </NewLink>
      {showSkuModal && (
        <ShopModal
          goodsDetail={goodsDetail}
          currency={currency}
          activityId={activityId}
          history={history}
          onClose={() => {
            setShowSkuModal(false);
          }}
        />
      )}
      <div className={cn('bottom-container', `product-title-align-${alignText}`)}>
        <div
          className={cn('bottom-info-container', { 'show-margin-top': bottomInfoHeight > 0 })}
          style={{ minHeight: bottomInfoHeight }}
        >
          {(commentConfig?.showComment || showSale || (showVendor && vendor)) && (
            <div ref={bottomInfoRef} className='bottom-info'>
              {!isMobile && (
                <div className='sell-comment'>
                  {commentConfig?.showComment && (
                    <GoodsScore
                      className='sell-goods-score'
                      styleType={commentConfig?.style}
                      score={goodsReviewsInfo?.reviewsCount ? goodsReviewsInfo?.averageScore : 5}
                    />
                  )}
                  {commentConfig?.showCommentNum && goodsReviewsInfo?.reviewsCount ? (
                    <div className='comment-num'>
                      {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : '('}
                      {getReviewCountStr(goodsReviewsInfo?.reviewsCount)}&nbsp;
                      {intl.formatMessage({ id: 'reviews' })}
                      {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : ')'}
                    </div>
                  ) : null}
                  {showSale && (
                    <span className='sell-num'>
                      {soldNum || 0}&nbsp;
                      {intl.formatMessage({
                        id: '576fd3c7319145ce830919b66e4f78cc',
                        defaultMessage: '销量',
                      })}
                    </span>
                  )}
                </div>
              )}
              {isMobile && (
                <div className='sell-comment'>
                  <div className='comment-container'>
                    {commentConfig?.showComment && (
                      <GoodsScore
                        className='sell-goods-score'
                        styleType={commentConfig?.style}
                        score={goodsReviewsInfo?.reviewsCount ? goodsReviewsInfo?.averageScore : 5}
                      />
                    )}
                    {commentConfig?.showCommentNum && goodsReviewsInfo?.reviewsCount ? (
                      <div className='comment-num'>
                        {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : '('}
                        {getReviewCountStr(goodsReviewsInfo?.reviewsCount)}&nbsp;
                        {intl.formatMessage({ id: 'reviews' })}
                        {commentConfig?.style === undefined || commentConfig?.style === 'style1' ? '' : ')'}
                      </div>
                    ) : null}
                  </div>
                  {showSale && (
                    <span className='sell-num'>
                      {soldNum || 0}&nbsp;
                      {intl.formatMessage({
                        id: '576fd3c7319145ce830919b66e4f78cc',
                        defaultMessage: '销量',
                      })}
                    </span>
                  )}
                </div>
              )}
              {showVendor && vendor && <span className='vendor-name'>{vendor}</span>}
            </div>
          )}
        </div>
        {/* {skuPageShow && (
                    <div className="sku-page-container">
                        <SkuPage />
                    </div>
                )} */}
        {showGroupBuyBtn && (
          <div className='operate-btn'>
            {!!quantity && (
              <Button type='primary' onClick={checkedRequireLogin(onGroupBuying)}>
                {intl.formatMessage({
                  id: '01722335147e4738a1ebc7f485c00fc5',
                  defaultMessage: '发起拼团',
                })}
              </Button>
            )}

            {!quantity && (
              <Button type='primary' disabled>
                {intl.formatMessage({
                  id: 'goods.sold_out',
                  defaultMessage: '售罄',
                })}
              </Button>
            )}
          </div>
        )}
      </div>
    </Style>
  );
};
