import styled, { css } from 'styled-components';

const getMobileDeviceFooterTextAlignCSS = (props) => {
  const { mobileDeviceFooterTextAlign, mobileDisplayTwoInOneLine } = props;

  let newMobileDeviceFooterTextAlign = 'left';
  //  || mobileDeviceMenuStyle === 'fold'
  if (mobileDisplayTwoInOneLine) {
    newMobileDeviceFooterTextAlign = 'left';
  } else {
    newMobileDeviceFooterTextAlign = mobileDeviceFooterTextAlign;
  }

  return css`
    text-align: ${newMobileDeviceFooterTextAlign};
  `;
};

export const Style = styled.div`
  .title {
    font-weight: 600;
    font-size: ${(props) => props.theme.getHeaderFontSize([14, 16])};
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    line-height: 20px;
  }

  .desc {
    font-size: ${(props) => props.theme.getBaseFontSize([12, 14])};
    margin-top: 12px;
    /* opacity: 0.6; */
  }

  @media screen and (max-width: 750px) {
    .footer-text-section {
      /* 对齐方式CSS */
      ${(props) => getMobileDeviceFooterTextAlignCSS(props)}
    }
  }
`;
