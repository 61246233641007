import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;
  .default-min-height {
    min-height: 180px;
  }
  &.default-square-img {
    width: 100%;
    height: 0;
    padding-top: ${(props) => props.paddingTopPer};
    position: relative;

    .default-img-box {
      position: absolute;
      top: 0;
    }
  }

  .default-img-box {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border: rgba(var(--color_body_text_rgb), 0.08);
    background: rgba(var(--color_body_text_rgb), 0.06);
    box-sizing: border-box;
    flex-direction: column;
    .default-img {
      height: 80px;
    }
  }
  .description-wrapper {
    margin-top: 12px;
    font-size: 14px;
    color: #c8c9cc;
    line-height: 14px;
  }

  .icon {
    fill: var(--color_body_text);
    fill-opacity: 0.06;
  }
`;
