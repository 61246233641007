import cdn1 from './cdn';
import jsCookie from 'js-cookie';

// 检测是否支持webp
const detectWebpSupport = function () {
  const elem = document.createElement('canvas');
  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
};
/**
 * @memberof module:browser
 * @desc
 * ##### 如果是新格式的图片则使用这种方式切换模式
 * 1、 image.toWebp(src)
 *  - 如果支持webp,给图片转化为新格式。类似`?imageView2/w/200/h/100/format/webp`
 *  - 如果不支持，转jpg
 *
 * 2、 image.checkCanWebp()
 *
 * 检查是否支持Webp格式
 */
const image = {};
image.toWebp = (_src, options = {}, urlParams = null) => {
  let src = _src;
  if (__isServer) {
    return src;
  }
  const { isGifOptimation } = options;
  var urlParams = image.getCanWebp() && (urlParams || cdn1.parseUrl(_src, isGifOptimation));
  if (urlParams) {
    const { hasWatermark } = urlParams;
    const { format } = urlParams;
    const { width } = urlParams;
    const { height } = urlParams;
    const { quality } = urlParams;
    const { imgSuffixAndWatermark } = urlParams;
    const query = `imageView2/2/w/${width}/h/${height}/q/${quality}/format/${format}`;
    const divider = hasWatermark ? '|' : '?';
    // 图片带水印的话，paras[1]中包含了图片后缀以及水印参数
    src = `${src.replace(cdn1.getCdnRegex(), '.') + imgSuffixAndWatermark}${divider}${encodeURIComponent(query)}`;
  } else {
    // 如果不能匹配，则再判断一下图片地址是否为七牛格式的
    // 类似于：?imageView2/w/200/h/100/format/webp这样的新格式
    src = cdn1.transQiniuFormatToWebp(src);
  }
  return src;
};
image.getCanWebp = () => {
  let canWebp = true;
  if (__isServer) {
    return canWebp;
  }
  try {
    canWebp = window.localStorage.getItem('canwebp') === 'ok';
  } catch (e) {
    console.log(e);
    return canWebp;
  }
  return canWebp;
};
image.checkCanWebp = (function () {
  return function () {
    if (__isServer) return;
    if (typeof window?.localStorage === 'object') {
      try {
        const canwebp = localStorage.getItem('canwebp');
        if (canwebp === 'ok') {
          jsCookie.set('_canwebp', '1', {
            path: '/',
            domain: window.location.hostname,
            expires: 3650,
          });
        } else if (canwebp !== 'no') {
          const canWebP = detectWebpSupport();
          localStorage.setItem('canwebp', canWebP ? 'ok' : 'no');
          if (canWebP) {
            jsCookie.set('_canwebp', '0', {
              path: '/',
              domain: window.location.hostname,
              expires: 3650,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
})();

// 运行时先检测一次，后面只需要取localstorage中结果就行
image.checkCanWebp();

export default image;
