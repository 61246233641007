import React from 'react';
import { Style } from './style';
import { getDesignLocalText } from 'utils/i18n';
import { hexToRGBA } from '../../../../../../util/color';

/**
 * footer 文本组件
 * */
const FooterText = ({
  settings = {},
  style,
  mobileDeviceFooterTextAlign,
  mobileDeviceMenuStyle,
  mobileDisplayTwoInOneLine,
}) => {
  const { text, title } = settings;

  return (
    <Style
      mobileDeviceFooterTextAlign={mobileDeviceFooterTextAlign}
      mobileDeviceMenuStyle={mobileDeviceMenuStyle}
      mobileDisplayTwoInOneLine={mobileDisplayTwoInOneLine}
    >
      <div className='footer-text-section'>
        {title && (
          <h3 className='title' style={style}>
            {getDesignLocalText(title)}
          </h3>
        )}
        <div
          className='desc'
          style={{ ...style, color: hexToRGBA(style?.color, 0.6) }}
          dangerouslySetInnerHTML={{
            __html: text || '',
          }}
        />
      </div>
    </Style>
  );
};

export default FooterText;
