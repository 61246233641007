import styled from 'styled-components';

export default styled.div`
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .setting-list {
    flex: 1;
    padding: 8px 0;
  }
  .setting-item {
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
  }
  .radio-group {
    width: 100%;
  }
  .radio-item {
    display: block;
    margin-top: 16px;
    color: ${(props) => props.theme.colorText};
  }
  .setting-item-label {
    font-size: 14px;
    font-weight: bold;
    color: var(--color_body_text);
  }
`;
