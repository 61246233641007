import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Style } from './style';
import ProductGrid, { GoodsPrev } from '../../../design/sections/product-grid';
import ContainerSize from '../../../design/components/container-size';
import { useCollection } from 'hook/collection';
import { Wrapper } from 'components/base';
import SectionTitle from '../../../components/section-title';
import { intlMessage as $t } from 'utils/intl/formate';
import { useThemeContext } from 'hook/global-theme';
import cn from 'classnames';
import { buriedPoint } from 'utils/log-buried-point';

const DEFAULT_TOTAL = 12;
const RecommendProduct = (props) => {
  const intl = useIntl();
  const { settings = {}, initialData = {}, elemClass } = props;
  const {
    show_style: showStyle,
    box_width: boxWidth,
    show_product_recommendation: showProductRecommendation,
  } = settings;
  const productGridSettings = {
    grid: DEFAULT_TOTAL,
    text_align: 'left',
    box_width: boxWidth,
    image_ratio: '1/1',
    show_product_name: settings.show_product_name,
    show_product_price: settings.show_product_price,
    title: $t(intl, 'recommend-goods'),
  };

  let { goodsList } = useCollection({
    kdtId: initialData?.kdtId,
    grid: DEFAULT_TOTAL,
    type: 'recommend',
    needDefault: false,
  });

  useEffect(() => {
    if (goodsList.length && !props.isEdit) {
      buriedPoint('goods_view', {
        goods_component_location: 'HOME_collection-v2',
        goods_component_location_name: '商品分组2.0',
        goodsList: goodsList.map((item) => {
          // eslint-disable-next-line
          const { goodsId: goods_id, goodsType: goods_type = '', title } = item;
          return {
            goods_id,
            goods_type,
            title,
          };
        }),
      });
    }
  }, [goodsList.length, props.isEdit]);

  // 装修时没有推荐商品，需要创造占位图
  if (props.isEdit && goodsList.length < 1) {
    goodsList = [];
    [0, 0, 0, 0, 0].forEach(() => {
      goodsList.push({
        title: "Your product's name",
        minPrice: 19.99,
      });
    });
  }
  const { isMobile } = useThemeContext();
  return (
    <Style className={elemClass}>
      {showProductRecommendation && goodsList && goodsList.length > 0 && (
        <div className={cn('recommend-product', { 'is-mobile': isMobile })}>
          {showStyle === 'up_down' ? (
            <Wrapper marginBottom='vContainer'>
              <ContainerSize boxWidth={boxWidth} needSafeSpace>
                <SectionTitle title={$t(intl, 'recommend-goods')} />
                <div className={cn('grid')}>
                  {goodsList.map((item) => (
                    <GoodsPrev
                      key={item?.goodsId}
                      kdtId={initialData?.kdtId}
                      {...item}
                      currency={initialData?.shopSetting?.currency}
                      settings={productGridSettings}
                      language={initialData?.shopSetting?.language}
                      onTriggerClick={() => {
                        buriedPoint('select_item', item);
                      }}
                    />
                  ))}
                </div>
              </ContainerSize>
            </Wrapper>
          ) : (
            <ProductGrid type='recommend' initialData={initialData} data={goodsList} settings={productGridSettings} />
          )}
        </div>
      )}
    </Style>
  );
};
RecommendProduct.getInitialProps = () => {}; // getInitialProps存在表示需要服务端渲染
export default RecommendProduct;
