import styled from 'styled-components';

export const Style = styled.div`
  .media-gallery {
    display: flex;
    align-items: center;
    &.media-gallery-wrap {
      .media-list {
        /* flex-wrap: wrap;
                .media-list-item {
                    margin-bottom: 12px;
                    :nth-child(4n) {
                        margin-right: 0;
                    }
                } */
      }
    }
    &.media-gallery-padding {
      padding: 0 24px;
    }
    &.media-gallery-direction-vertical {
      margin-right: 12px;
      display: flex;
      flex-direction: row-reverse;
      align-items: start;
      flex-direction: column;
      .media-list {
        flex-direction: column;
        height: 460px;
        width: auto;
        margin-top: 0;
        .media-list-item {
          margin-left: 0;
          margin-bottom: 12px;
        }
      }
      .vertical-arrow-list {
        text-align: center;
        width: 100%;
        .icon-arrow-top {
          transform: rotate(180deg);
        }
      }
    }
    .hidden {
      display: none;
    }
    svg.icon {
      /* fill: var(--color_button); */
      flex-shrink: 0;
      fill: var(--color_body_text);
      cursor: pointer;
      &.disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }
      &.horizontal-icon-right {
        transform: rotate(180deg);
      }
    }
    .media-list {
      width: 544px;
      display: flex;
      overflow: auto;
      margin: 12px 6px 0;
      &::-webkit-scrollbar {
        display: none;
      }
      .media-list-item {
        position: relative;
        width: 106px;
        height: 106px;
        min-width: 106px;
        box-sizing: border-box;
        border-radius: var(--border_radius_pd_card);
        overflow: hidden;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        + .media-list-item {
          margin-left: 12px;
        }
        &.media-list-item-active {
          border: 2px solid var(--color_button) !important;
        }
        img {
          /* width: 100%; */
          height: 100%;
          object-fit: cover;
        }
        .play-icon-wrapper {
          width: 18px;
          height: 18px;
          background: rgba(21, 22, 27, 0.5);
          border-radius: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 10px;
          bottom: 10px;
          box-shadow: 0px 0px 4px #fff;
          .play-icon {
            width: 12px;
            height: 12px;
            fill: #fff;
          }
        }
      }
      .media-list-item-cart {
        width: 70px;
        min-width: 70px;
        height: 70px;
      }
    }
  }
  /* @media screen and (max-width: 750px) { */
  /* @media screen and (max-width: 768px) { */
  @media screen and (max-width: 960px) {
    .media-gallery.media-gallery-direction-vertical {
      display: flex;
      align-items: center;
      flex-direction: initial;
      margin-right: 0;
      .media-list {
        display: flex;
        overflow: auto;
        width: 460px;
        margin: 12px auto 0;
        align-items: center;
        flex-direction: inherit;
        height: auto;
        .media-list-item {
          width: 106px;
          height: 106px;
          min-width: 106px;
          margin-right: 12px;
          box-sizing: border-box;
          &.media-list-item-active {
            border: 4px solid var(--color_button) !important;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .horizontal-icon {
        display: block;
      }
      .vertical-arrow-list {
        display: none;
      }
    }
  }
`;
