import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    .open-quit-tipe-dialog.ht-dialog.zent-dialog-r {
        padding-bottom: 16px !important;
    }
`;
export const Content = styled.div`
  padding-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
