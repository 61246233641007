import styled from 'styled-components';

export default styled.div`
  overflow: hidden;
  width: 100%;
  .section-marker {
    width: 100%;
  }
  &.sortable-ghost {
    .slide {
      width: 0;
      height: 0;
    }
  }
  .header-icon {
    width: 18px;
    height: 18px;
    fill: ${(props) => props.subMenuTextColor};
    display: inline-block;
    vertical-align: bottom;
    &:hover {
      opacity: 0.65;
    }
  }
  .menu-item {
    padding-left: 10px;
  }
  .header-menu-wrapper {
    margin: 6px 6px 16px;
    height: 56px;
    display: flex;
    .header-menu-item {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .wish-list-entry {
        width: 100%;
        border-left: solid 1px
          ${(props) =>
            props.defaultTextIconColor ? props.theme.hexToRGBA(props.defaultTextIconColor, 0.08) : '#dcdee0'};
        text-align: center;
      }
    }
  }
  .slide {
    top: 0;
    z-index: 10;
    left: 0px;
    width: 300px;
    height: 100vh;
    position: fixed;
    /* background: #fff; */
    transition: all 0.2s ease-in-out;
    transform: translateX(-300px);
    overflow: hidden;
  }
  .slide-container-head-icon {
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    .menu-back-text {
      max-width: 70%;
      display: inline-block;
      text-align: right;
      overflow: hidden;
      max-height: 64px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .slide-container-close-icon {
    fill: ${(props) => props.subMenuTextColor};
  }
  .slide-menu-icon {
    fill: ${(props) => props.subMenuTextColor};
  }
  .header-mobile-menu-header {
    padding: 0;
    margin-right: var(--pm-16-10);
    text-align: right;
  }
  .slide-back {
    cursor: pointer;
  }
  .slide-container {
    background: ${(props) => props.subMenuBgColor || '#fff'};
    height: 100%;
  }
  .slide-left-scroll-body {
    height: calc(100% - 65px);
    display: flex;
    flex-direction: column;
    .zent-menu {
      flex: 1;
      overflow: scroll;
    }
    /* overflow-x: hidden;
        overflow-y: scroll; */
  }
  .slide-left-scroll-body::-webkit-scrollbar {
    width: 0 !important;
  }
  .slide-container-header {
    text-align: right;
    line-height: 72px;
    padding: 0 var(--pm-24-18) 0;
    border-bottom: 1px solid #d1d5db;
    box-shadow: 0px 0px 0px 0px #d1d5db;
    display: flex;
    justify-content: flex-end;
    .shop-name {
      color: ${(props) => props.subMenuTextColor};
    }
  }

  .slide-content {
    width: 264px;
    margin: auto;
  }

  .mobile-header-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 10;
    background: rgba(26, 26, 27, 0.3);
    opacity: 0.3;
  }
  .blank-menu-container {
    padding: 18px;
    box-sizing: border-box;
    .back-icon {
      width: 24px;
      height: 24px;
    }
  }
`;
