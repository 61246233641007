import { shareUseAgent } from '../const/share-type';

export const isFromBrowser = (useAgent) => {
  const browserAgentList = ['Firefox', 'AppleWebKit', 'Chrome', 'MSIE', 'rv', 'Mozilla', 'Opera'];
  let flag = false;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < browserAgentList.length; i++) {
    const item = browserAgentList[i];
    if (useAgent.includes(item)) {
      flag = true;
      break;
    }
  }
  return flag;
};

export const getShareImgUrl = (useAgent, shopLogoUrl, imgUrl) => {
  let shareImgUrl = '';
  switch (useAgent) {
    case shareUseAgent.Line:
      shareImgUrl = shopLogoUrl;
      break;
    default:
      shareImgUrl = imgUrl;
  }
  return shareImgUrl;
};

export const openWindow = (params) => {
  if (window.isEdit) return;
  const { url, width = 600, height = 400 } = params;
  // 有些手机不兼容window.open
  if (document.body.clientWidth <= 750) {
    window.location.href = url;
  } else {
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    window.open(
      url,
      'newWindow',
      `height=${height}, width=${width} ,left=${left},top=${top} , toolbar=no, 
                menubar=no, scrollbars=yes , resizable=yes, +
                location=no, status=no`,
    );
  }
};

/**
 * 设置分享配置
 * @param {*} param0
 */
export const initSeoConfig = ({ seo, social, useAgent }) => {
  let seoInfo = {};
  const { shopLogoUrl, imgUrl } = social;
  const shareImgUrl = getShareImgUrl(useAgent, shopLogoUrl, imgUrl);
  seoInfo = {
    /** SEO主要用于搜索引擎爬取 */
    seo: {
      title: seo.title,
      description: seo.desc,
    },
    /** 第三方分享渠道分享时 公用的信息 */
    matePropertyListOfSeo: [
      {
        value: social.siteName,
        type: 'og:site_name',
      },
      {
        value: social.requestHref,
        type: 'og:url',
      },
      {
        value: social.title,
        type: 'og:title',
      },
      {
        value: 'product',
        type: 'og:type',
      },
      {
        value: social.desc,
        type: 'og:description',
      },
      {
        value: shareImgUrl,
        type: 'og:image',
      },
      {
        value: shareImgUrl,
        type: 'og:image:url',
      },
    ],
    /** 一些特殊渠道需要的信息 */
    mateNameListOfSeo: [
      // 推特暂时加这一个，其他的可以共用og标签图谱
      {
        value: 'summary_large_image',
        type: 'twitter:card',
      },
      {
        value: shareImgUrl,
        type: 'twitter:image',
      },
      {
        value: social.requestHref,
        type: 'twitter:site',
      },
      {
        value: social.requestHref,
        type: 'twitter:url',
      },
      {
        value: social.title,
        type: 'twitter:title',
      },
      {
        value: social.desc,
        type: 'twitter:description',
      },
    ],
  };
  return seoInfo;
};
