// 该组件在商品详细页和单个商品组件都有用到
import React from 'react';
import { ScreenShow } from '../../../../../../style/media';
import PreviewProduct from 'cpn/preview-product';
import MediaSwiper from 'cpn/preview-product/media-swiper';
import { buriedPoint } from 'utils/log-buried-point';

export const ProductMedia = ({ goodsDetail, isZoom = true, arrows = true }) => {
  const { handle, title } = goodsDetail || {};
  const onViewChange = () => {
    buriedPoint(
      'product_changepicture',
      {
        source: '',
        goods_id: goodsDetail.goodsId,
        goods_ids: goodsDetail.goodsId,
        ...goodsDetail,
      },
      {
        name: '',
        eventType: 'click',
      },
    );
  };

  return (
    <div>
      <ScreenShow min='769px'>
        <PreviewProduct
          handle={handle || title}
          goodsId={goodsDetail?.goodsId}
          onViewChange={onViewChange}
          isZoom={isZoom}
          isShowGallery
          isGalleryWrap
          galleryDirection=''
          arrows={arrows}
        />
      </ScreenShow>
      <ScreenShow max='768px'>
        <MediaSwiper onViewChange={onViewChange} />
      </ScreenShow>
    </div>
  );
};
