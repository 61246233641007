import BuyNow from '../buy-now-v2';
import SkuStyle from './style.js';
import { createPortal } from 'react-dom';
import React, { useState, useRef, useEffect } from 'react';

const SkuPageV2 = ({ goodsId, setSkuPageShow, preVariantId, updateCartList, quantity, onCartSkuChange }) => {
  const isPC = window && document.body.clientWidth > 750;
  const containerDisplay = isPC ? 'block' : 'flex';
  const skuSelectRef = useRef({});
  const { kdtId, shopSetting = {} } = window?.global_data;
  const skuElement = (
    <SkuStyle>
      <div
        style={{
          display: containerDisplay,
        }}
        className='add-shopping-car-container'
        onClick={(e) => e.stopPropagation()}
      >
        <BuyNow
          skuSelectRef={skuSelectRef}
          goodsId={goodsId}
          kdtId={kdtId}
          currency={shopSetting?.currency}
          triggerSkuBlock={setSkuPageShow}
          preVariantId={preVariantId}
          preNum={quantity}
          updateCartList={updateCartList}
          isPC={isPC}
          onCartSkuChange={onCartSkuChange}
        />
      </div>
    </SkuStyle>
  );
  if (isPC) {
    return skuElement;
  }
  return createPortal(skuElement, document.getElementById('root'));
};

export default SkuPageV2;
