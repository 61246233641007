import userApi from 'api/user';
// 业务场景：register -> 手机号注册, login -> 手机号登录, bind -> 绑定手机号, reset_pwd -> 重置密码
// export type SCENE_ENUM = 'register' | 'login' | 'bind' | 'reset_pwd';

export enum SCENE_ENUM {
  register = 'register',
  login = 'login',
  bind = 'bind',
  // eslint-disable-next-line camelcase
  reset_pwd = 'reset_pwd',
}

interface SendSmsVerifyCodeParams {
  mobileCountryCode: string;
  mobile: string;
  scene: SCENE_ENUM;
}
interface sendMailVerifyCodeParams {
  email: string;
  scene: SCENE_ENUM;
}

export const sendSmsVerifyCode = (params: SendSmsVerifyCodeParams, options) => {
  return new Promise((resolve, reject) => {
    userApi
      .sendSmsVerifyCode(params, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendMailVerifyCode = (params: sendMailVerifyCodeParams, options) => {
  console.log('sendMailVerifyCode', params);
  return new Promise((resolve, reject) => {
    // setTimeout(() => {
    //     resolve(123);
    // }, 500);
    userApi
      .sendMailVerifyCode(params, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
