import styled from 'styled-components';

export const MultipleSpecificationsStyle = styled.div`
  .size {
    font-size: var(--base-font-14-12);
    color: #9b9ea4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-break: break-word;
  }
`;
export const ImgContainerStyle = styled.div`
  position: relative;
  width: 64px !important;
  min-width: 64px;
  min-height: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 16px;
  border-radius: var(--border_radius_pd_card);
  overflow: hidden;
  .img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
`;
export const GoodsTitleStyle = styled.div`
  font-size: var(--base-header-font-14-12);
  font-family: var(--font-stack-body);
  font-style: var(--font-style-body);
  font-weight: var(--font-weight-body);
  position: relative;
  flex: 1;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const DeleteBtnStyle = styled.div`
  .delete-icon {
    fill: #b3b9c6;
  }
`;

export const TagStyle = styled.div`
  margin-top: 10px;
  color: var(--color_sale_text);
  background: rgba(var(--color_sale_text_rgb), 0.1);
  padding: 2px;
  font-size: 12px;
  display: inline-block;
  border-radius: 1px;
`;

export default styled.div`
  display: flex;
  flex: 2;
  align-items: flex-start;
  position: relative;
  .invalid-text {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 102px;
    span {
      font-size: 16px;
      padding: 0 6px;
      height: 24px;
      line-height: 24px;
      color: #fff;
      background: rgba(0, 0, 0, 0.8);
    }
  }
  .invalid-opacity {
    opacity: 0.2;
  }
  .discount-info-wrapper {
    font-size: 12px !important;
    text-align: right;
    & > div {
      margin-top: 5px !important;
    }
  }
  .line-row {
    display: flex;
    justify-content: space-between;
  }
  .mt10 {
    margin-top: 8px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .info-wrapper {
    &.info {
      flex: 1;
      color: var(--color_body_text);
      min-height: 102px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &.cart-goods-item {
    /* .delete-btn {
            position: absolute;
            right: 0;
            bottom: 16px;
        } */
    .flex-item-bottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    .img-container {
      width: 104px;
      height: 112px;
    }
    .info {
      width: 100%;
    }
    .zent-input-wrapper {
      width: 117px !important;
    }
    /* .zent-number-input {
            height: 24px !important;
        }
        .zent-number-input-arrows {
            height: 24px !important;
        } */
  }
`;
export const GiftGoodsStyle = styled.div`
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .goods-name,
  .size,
  .origin-price {
    font-size: 12px;
  }
  /* .zent-input-wrapper {
        width: 50px !important;
        .zent-input {
            min-width: 30px !important;
        }
    } */
  .info {
    flex: 1;
  }
  /* .img-container img {
        width: 40px;
        height: 40px;
    } */
`;

export const DiscountInfoStyle = styled.div`
  border-radius: var(--border_radius_tag);
  overflow: hidden;
  .right {
    text-align: right;
  }
  .left {
    text-align: right;
  }
  display: inline-block;

  .discount-code-detail {
    display: inline-block;
    color: var(--color_sale_text);
    background: rgba(var(--color_sale_text_rgb), 0.2);
    padding: 5px;
    .discount-code-icon {
      margin-right: 2px;
      vertical-align: middle;
      fill: var(--color_sale_text);
      width: 16px;
      height: 16px;
    }
    .discount-code {
      max-width: 88%;
      font-size: 12px;
      font-weight: 600;
      line-height: 9px;
      color: var(--color_sale_text);
    }
  }
  .price-detail {
    font-weight: 500;
  }
`;

export const PriceItemStyle = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: #1a1a1b;
  line-height: 14px;
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  .price-detail {
    font-weight: 500;
    display: flex;
    .goods-info-discount-money {
      color: var(--color_body_text);
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
    }
  }

  &.column {
    .price-detail {
      flex-direction: column;
    }
    .goods-info-discount-money {
      margin-left: 0px;
      margin-top: 4px;
    }
  }
  &.row {
    .goods-info-discount-money {
      margin-right: 8px;
    }
  }
  .origin-price {
    color: #9b9ea4;
  }
  .discount-price,
  .origin-price {
    &.is-discount {
      text-decoration: line-through;
    }
  }
`;
