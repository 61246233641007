import styled from 'styled-components';

export const BackgroundColor = styled.div`
  background-color: ${(props) => props.backgroundColor};
`;

export const BackgroundImage = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  /* background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
  background-size: ${(props) => props.backgroundSize || 'cover'};
  background-position: center;
  background-repeat: no-repeat;
`;

export const BackgroundNull = styled.div``;

export const Background = (type) => {
  if (type === 'custom_color') {
    return BackgroundColor;
  }
  if (type === 'background_image') {
    return BackgroundImage;
  }

  if (type === 'null') {
    return BackgroundNull;
  }
};
