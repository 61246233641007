/**
 * 商品相关的api接口封装
 */
import BaseApi from './base';

class DiscountApi extends BaseApi {
  /**
   * 获取赠品列表
   */
  getDiscountActivityGoods(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/searchDiscountActivityGoods',
      params,
    );
  }

  /**
   * 分页搜索折扣活动对应的商品
   */
  searchActivityGoods(params) {
    const customerId = __isServer ? null : window?.global_data?.userInfo?.customerId;
    return this._post('/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/searchActivityGoods', {
      customerId,
      ...params,
    });
  }

  /**
   * 获取赠品列表-包括sku等信息
   */
  getActivityGoodsSkuById(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/getActivityGoodsSkuById',
      params,
    );
  }

  /**
   * 商详折扣活动商品列表
   */
  getDiscountActivityInfo(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/getDiscountActivityInfo',
      params,
    );
  }

  /**
   * 查询待领取的优惠描述
   * */
  getPromoCodeDescription(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.discount.controller.web.PromoCodeController/getPromoCodeDescription',
      params,
    );
  }

  /**
   * 领取一卡一码
   * */
  takePromoCode(params) {
    return this._get('/api/soa/com.youzan.i18n.ump.discount.controller.web.PromoCodeController/takePromoCode', params);
  }

  /**
   * @name 获取抽奖活动信息
   * */
  getDrawActivitySetting(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.draw.controller.web.DrawActivityController/getDrawActivitySetting',
      params,
    );
  }
}

export default new DiscountApi();
