import styled from 'styled-components';

export const SendSuccessTips = styled.div`
  background-color: #f7f7f7;
  display: flex;
  padding: 0 19px;
  height: 72px;
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 16px;
  .icon-wrapper {
    display: flex;
    align-items: center;
  }
  .tips-wrapper {
    .title {
      color: #1a1a1b;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
    .tips {
      color: #36383b;
      font-size: 14px;
      line-height: 14px;
    }
    display: flex;
    flex-direction: column;
    padding: 16px;
    justify-content: space-between;
  }
`;
