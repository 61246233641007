export const changeGoogleLanguage = (language) => {
  const googTeCombo = document.querySelector('.goog-te-combo');
  if (googTeCombo && googTeCombo.children.length > 0) {
    const children = Array.prototype.slice.call(googTeCombo.children || []);
    let idx = children.findIndex((item) => item.getAttribute('value') === language);
    console.log('test idx start', idx);
    if (idx === -1) {
      const optionItem = document.createElement('option');
      optionItem.setAttribute('value', language);
      optionItem.textContent = language;
      googTeCombo.appendChild(optionItem);
      idx = children.length;
    }
    googTeCombo.selectedIndex = idx;
    googTeCombo.dispatchEvent(new Event('change'));
  } else {
    setTimeout(() => {
      changeGoogleLanguage(language);
    }, 500);
  }
};
