// 文本-上拉图文列表
import React from 'react';
import cn from 'classnames';
import ImageContainer from '../../components/image-container';
import { jumpToPage } from 'utils/web-tool';
import { Wrapper } from 'components/base';
import { Style } from './style';
import LinkButton from '../../snippets/link-button';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useThemeContext } from '../../../../hook/global-theme';
import useFillImage from 'hook/use-fill-image';
import { Background } from '../../components/background';

SwiperCore.use([Pagination]);
const CollectionItem = ({ settings, index, grid, imageRatio }) => {
  const { collection, title, description, button_text: buttonText, button_link: buttonLink } = settings;

  const { image } = useFillImage(settings?.image);

  const path = buttonLink ? JSON.parse(buttonLink)?.url : '';
  const clickClass = collection ? 'click-able' : '';
  const themeContext = useThemeContext();
  return (
    <li
      className={cn('feature-collection-grid-item', {
        'is-pc': !themeContext.isMobile,
        'no-margin-top': index < grid,
      })}
      onClick={() => {
        jumpToPage(path);
      }}
    >
      <div className={cn('grid-info-wrap', clickClass)}>
        <ImageContainer
          heightType={imageRatio}
          heightRules='type3'
          fit='cover'
          position='center center'
          alt={title}
          url={image}
        />
        <div className={cn('feature-content', themeContext.isMobile ? 'is-mobile' : 'is-pc')}>
          <h3 className='title'>{title}</h3>
          {(description || buttonText) && (
            <div className='feature-content--bottom'>
              <div className='sub-title'>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
              {buttonText && (
                <LinkButton linkStyle='button' className='link-button' linkText={buttonText} path={path} />
              )}
            </div>
          )}
        </div>
      </div>
    </li>
  );
};
// 上拉图文列表
const CollectionList = (props) => {
  const { settings = {}, history, elemClass, block_order: blockOrder, blocks } = props;

  const {
    grid = 3,
    box_width: boxWidth,
    title,
    image_ratio: imageRatio,
    overlay_bg_color: overlayBgColor,
    text_color: textColor,
    desc_text_color: descTextColor = '#ffffff',
    btn_bg_color: btnBgColor,
    btn_text_color: btnTextColor,
    background_style: backgroundStyle = 'custom_color',
    background_color: backgroundColor = 'rgba(250, 250, 250, 0)',
    background_image: backgroundImage,
  } = settings;
  const Bg = Background(backgroundStyle);

  const gridClass = `collection-grid-${grid}`;
  const hasMargin = !(!title && boxWidth === 'full_screen');
  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage} style={{ overflow: 'hidden' }}>
      <Style
        className={cn('feature-collection-list-section', elemClass, `cn-${boxWidth}`)}
        overlayBgColor={overlayBgColor}
        textColor={textColor}
        btnBgColor={btnBgColor}
        btnTextColor={btnTextColor}
        descTextColor={descTextColor}
      >
        <Wrapper marginTop={hasMargin ? 'vContainer' : 0} marginBottom={hasMargin ? 'vContainer' : 0}>
          <div className={cn('feature-collection-box', gridClass)}>
            {title && (
              <h3
                className={cn('feature-collection-title', 'section-component-title')}
                style={{
                  textAlign: settings.text_align || 'center',
                }}
              >
                {settings.title}
              </h3>
            )}
            <ul className='feature-collection-grid pc'>
              {blockOrder.map((blockId, index) => {
                return (
                  <CollectionItem
                    key={blockId}
                    index={index}
                    grid={grid}
                    history={history}
                    settings={blocks[blockId]?.settings}
                    imageRatio={imageRatio}
                  />
                );
              })}
            </ul>
            <div className='mobile-style'>
              <Swiper spaceBetween={25} pagination={{ clickable: true }}>
                {blockOrder.map((blockId) => (
                  <SwiperSlide key={blockId}>
                    <CollectionItem
                      key={blockId}
                      history={history}
                      settings={blocks[blockId]?.settings}
                      imageRatio={imageRatio}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </Wrapper>
      </Style>
    </Bg>
  );
};

export default CollectionList;
