// 按钮或者a样式的跳转组件
// 可配置按钮大小size，类型type，文本颜色color，背景颜色bgColor，跳转路径href打开方式target|onClick

import { Style } from './style';
import React from 'react';
import NewLink from 'cpn/new-link';
import cn from 'classnames';

const Size = {
  large: 'large', // 大按钮
  medium: 'medium', // 中按钮，默认大小
  small: 'small', // 小按钮
};
const Type = {
  primary: 'primary', // 主按钮
  secondary: 'secondary', // 次按钮
  text: 'text', // 文本按钮
  arrow: 'arrow', // 带箭头按钮
};
const NewButton = (props) => {
  const {
    type = 'secondary',
    size = 'medium',
    bgColor,
    color,
    children,
    href,
    target,
    onClick,
    className,
    style,
    disabled = false,
    ...extra
  } = props;

  let realLink = href;
  try {
    if (href && typeof href == 'string' && href.indexOf('{') > -1 && href.indexOf('}') > -1) {
      realLink = JSON.parse(href).url;

      if (typeof realLink === 'undefined') {
        realLink = href;
      }
    }
  } catch (e) {
    console.log('newbuton parse href error', e);
  }

  return (
    <Style bgColor={bgColor} color={color} {...extra} className={className}>
      <NewLink
        disabled={disabled}
        style={style}
        className={cn('button', Type[type], Size[size])}
        target={target}
        href={realLink || undefined}
        onClick={onClick}
      >
        {children} {type === 'arrow' && '→'}
      </NewLink>
    </Style>
  );
};

export default NewButton;
