import { ProductMedia } from './components/product-media';
import { ProductMediaTwo } from './components/product-media-two';
import ProductMediaThree from './components/product-media-three';
import React, { Fragment } from 'react';

export const GoodsImageContainer = (props) => {
  const { baseImageStyle, baseConfigInfo, goodsDetail, containerLeft, contentLeft } = props;
  /* 左侧商品图 */
  /* 样式3的缩略图不在左部滚动范围，所以缩略图和大图要分开写 */
  return (
    <React.Fragment>
      {baseImageStyle === 'style3' ? (
        <ProductMediaThree className={baseConfigInfo?.layout}>
          <ProductMediaThree.SmallPictures />
          <div className='detail-left-container' ref={containerLeft}>
            <div className='detail-left-content' ref={contentLeft}>
              {baseImageStyle === 'style3' && <ProductMediaThree.BigPictures />}
            </div>
          </div>
        </ProductMediaThree>
      ) : (
        <div className='detail-left-container' ref={containerLeft}>
          <div className='detail-left-content' ref={contentLeft}>
            {baseImageStyle === 'style1' && <ProductMedia goodsDetail={goodsDetail} />}
            {baseImageStyle === 'style2' && <ProductMediaTwo grid={baseConfigInfo?.show_num} />}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
