import { useMemo } from 'react';
import { LIMIT_PERIOD, LIMIT_TYPE } from 'const/goods';
import { isEmpty, isNil, min } from 'lodash';
import { useI18n } from '../intl';
import { Notify } from 'zent';

interface GoodsLimitProps {
  intl: any;
  // 商品库存
  inventory: number;
  // 赠品数量限制
  discountLimit?: number;
  limitInfo: {
    // 可购买数量
    availableQty: number;
    // 是否被限购
    limited: number;
    // 限购周期： 每天：day 每周：week 每月：month
    limitPeriod: LIMIT_PERIOD;
    // 限购原因
    limitReason: string;
    // 限额
    quota: number;
    // 数量限购类型： 周期限购：period 每单限购：order
    quantityLimitType: LIMIT_TYPE;
    // 已购数量
    usedQuota: number;
  };
  // 起购数
  minPurchaseQty: number;
}

export const useGoodsLimit = (props: GoodsLimitProps) => {
  const {
    intl,
    limitInfo,
    inventory = 0,
    minPurchaseQty = 1,
    discountLimit = 0,
  } = props;
  const { $fm } = useI18n(intl);

  // 最大数量
  const maxGoodsNum = useMemo(() => {
    // 赠品数量限制优先, -1 则没有限制
    if (!isNil(discountLimit) && discountLimit > 0) return discountLimit;
    // 配置了商品限购
    if (!isEmpty(limitInfo)) {
      const { availableQty, quota } = limitInfo;
      return min([availableQty, quota, inventory]);
    }
    return inventory;
  }, [limitInfo, discountLimit, inventory]);

  // 起购限购提示
  const limitTips = useMemo(() => {
    // 赠品不受限制
    if (!isNil(discountLimit) && discountLimit > 0) return null;
    // 无起购和限购
    if ((!minPurchaseQty || minPurchaseQty == 1) && isEmpty(limitInfo)) return null;
    let tips = '';
    if (minPurchaseQty > 1) {
      tips += $fm('5936a79f3e254f84ad6d16410e123985', '{num} 件起购', { num: minPurchaseQty });
    }
    if (!isEmpty(limitInfo)) {
      const { availableQty, quota, quantityLimitType } = limitInfo;
      if (quantityLimitType === LIMIT_TYPE.order) { // 每单限购
        tips += `${tips ? ', ' : ''}${$fm('9fec834c04bb4485991797065023653e', '每单限购 {quota} 件', { quota })}`;
      }
      if (quantityLimitType === LIMIT_TYPE.period) { // 每天限购
        tips += `${tips ? ', ' : ''}${$fm('c0e00f3d68454e4fb6b8d9b9f0ca7777', '每天限购 {quota} 件，还可购买 {availableQty} 件', { availableQty, quota })}`;
      }
    }
    return tips;
  }, [maxGoodsNum, limitInfo, minPurchaseQty]);

  // 点击减少
  const onMinusEvent = (value) => {
    if (inventory <= 0 || discountLimit > 0) return;
    if (minPurchaseQty > 1 && value <= minPurchaseQty) {
      Notify.warn($fm('900f2a6858eb4a08b28fc370e11fafa8', '该商品 {num} 件起购', { num: minPurchaseQty }));
    }
  }

  // 点击增加
  const onPlusEvent = (value) => {
    if (isEmpty(limitInfo) || inventory <= 0 || discountLimit > 0) return;
    if (value >= limitInfo?.quota) {
      const { quota, quantityLimitType } = limitInfo;
      let errMsg = '';
      if (quantityLimitType === LIMIT_TYPE.order) { // 每单限购
        errMsg = $fm('f2d93f10775041d488c436934a407f49', '该商品每单限购{num}件', { num: quota })
      }
      if (quantityLimitType === LIMIT_TYPE.period) { // 每天限购
        errMsg = $fm('089379e618f548928f5a4e6de23cb9ce', '该商品每天限购{num}件', { num: quota })
      }
      Notify.warn(errMsg);
    }
  }

  return {
    limitTips,
    minGoodsNum: minPurchaseQty,
    maxGoodsNum,
    onMinusEvent,
    onPlusEvent,
  };
};
