import React from 'react';
import { useIntl } from 'react-intl';
import { Style } from './style';

const ListEmpty = () => {
  const intl = useIntl();

  return (
    <Style>
      <div className='empty-result'>
        <div>
          <svg className='icon search-icon' aria-hidden='true'>
            <use xlinkHref='#iconsousuowujieguo' />
          </svg>
        </div>
        <div className='tips'>
          {' '}
          {intl.formatMessage({
            id: 'goods.search_nothing1',
            defaultMessage: '拼团分组的商品需要去拼团应用中去创建',
          })}
        </div>
      </div>
    </Style>
  );
};

export default ListEmpty;
