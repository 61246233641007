import camelCase from 'camelcase';
import { parseInt } from 'lodash';

export const formateCamelcase = (obj, cvtFun = camelCase) => {
  let newObj;
  if (typeof obj === 'string') {
    return cvtFun(obj);
  } else if (obj instanceof Array) {
    newObj = [];
  } else if (obj instanceof Object) {
    newObj = {};
  } else {
    return obj;
  }

  Object.keys(obj).forEach((k) => {
    const newKey = cvtFun(k);
    const value = obj[k];
    if (typeof value === 'object') {
      newObj[newKey] = formateCamelcase(value);
    } else {
      newObj[newKey] = value;
    }
  });
  return newObj;
};
// 格式化富文本html字符串为纯文本
export const formateHtml = (desc = '', replaceStr = '') => {
  return desc.replace(/\<(.*?)\>/g, replaceStr);
};

export const isUrl = (url) => {
  return /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/.test(url);
};

export const getUrl = (url) => {
  if (isUrl(url)) return url;
  const { origin } = window?.location || {};
  return `${origin}${url}`.trim();
};

export const getUrlFromConfig = (url = '{}') => {
  try {
    const obj = JSON.parse(url);
    return obj.url;
  } catch (err) {
    return null;
  }
};

// 商品评论数量格式化 -- 规范1 后缀+号
export const getReviewCountStr = (reviewCount) => {
  let reviewCountStr = `${reviewCount}` || '0';
  if (reviewCount > 100 && reviewCount <= 999) {
    reviewCountStr = `${parseInt(reviewCount / 100) * 100}+`;
  } else if (reviewCount >= 1000 && reviewCount <= 999999) {
    reviewCountStr = `${parseInt(reviewCount / 1000)}K+`;
  } else if (reviewCount >= 1000000 && reviewCount <= 999999999) {
    reviewCountStr = `${parseInt(reviewCount / 1000000)}M+`;
  } else if (reviewCount >= 1000000000) {
    reviewCountStr = `${parseInt(reviewCount / 1000000000)}G+`;
  }
  return reviewCountStr;
};

// 商品评论数量格式化 -- 规范2 后缀.0
export const getReviewCountStrWithZero = (reviewCount) => {
  let reviewCountStr = `${reviewCount}` || '0';
  if (reviewCount >= 1000 && reviewCount <= 999999) {
    reviewCountStr = Number(reviewCount / 1000).toFixed(1);
    if (reviewCountStr.endsWith('0')) {
      reviewCountStr = Number(reviewCountStr).toFixed(0);
    }
    reviewCountStr = `${reviewCountStr}k`;
  } else if (reviewCount >= 1000000 && reviewCount <= 999999999) {
    reviewCountStr = Number(reviewCount / 1000000).toFixed(1);
    if (reviewCountStr.endsWith('0')) {
      reviewCountStr = Number(reviewCountStr).toFixed(0);
    }
    reviewCountStr = `${reviewCountStr}M`;
  } else if (reviewCount >= 1000000000) {
    reviewCountStr = Number(reviewCount / 1000000000).toFixed(1);
    if (reviewCountStr.endsWith('0')) {
      reviewCountStr = Number(reviewCountStr).toFixed(0);
    }
    reviewCountStr = `${reviewCountStr}G`;
  }

  return reviewCountStr;
};
