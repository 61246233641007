import styled from 'styled-components';

export const FormInputWrapper = styled.div`
  .form-input {
    height: ${(props) => props.theme.getSpace(props.size)} !important;
    box-shadow: none !important;
    background-color: var(--color_text_field);
  }
  > .zent-form-control {
    margin-bottom: 16px;
  }
  .zent-input-close {
    font-size: 16px;
  }
  .zent-form-error {
    max-width: fit-content !important;
  }
`;
export const FormInputLabel = styled.label`
  color: red;
`;
