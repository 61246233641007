import styled from 'styled-components';

export const CodeInputWrapper = styled.div`
  position: relative;
  .get-code {
    position: absolute;
    right: 0;
    top: 6px;
  }
`;

export const OperateButtons = styled.div`
  margin-top: ${(props) => props.theme.getSpace(props.theme.pCardsNew)};
`;

export const ForgotPassword = styled.div`
  text-align: right;
  color: var(--color_button);
  cursor: pointer;
  font-weight: normal;
  font-size: ${(props) => props.theme.getSpace(props.theme.h11)};
`;
