import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Style } from './style';
import { Collapse } from 'zent';
import { useClientWidth } from 'hook/global-theme';

export const FooterMenuCollapse = (props) => {
  const {
    title,
    mobileDeviceMenuStyle,
    mobileDeviceFooterTextAlign,
    mobileDisplayTwoInOneLine,
    style = {},
    children,
    className,
    isMobileFoldIndent,
  } = props;
  const { clientWidth } = useClientWidth();
  const isMobile = clientWidth > 780;

  const [activeKey, setActiveKey] = useState('');
  const subMenuStatus = activeKey === '1';

  const changeSubMenuStatus = () => {
    // 展开 不允许折叠
    if (mobileDeviceMenuStyle === 'open' || isMobile) {
      setActiveKey('1');
      return;
    }
    setActiveKey(activeKey ? '' : '1');
  };

  useEffect(() => {
    if (isMobile) {
      setActiveKey('1');
      return;
    }
    setActiveKey(mobileDeviceMenuStyle === 'open' ? '1' : '');
  }, [mobileDeviceMenuStyle, clientWidth]);

  const isShowTitleBtn = useMemo(() => {
    if (!children) {
      return false;
    }
    return mobileDeviceMenuStyle === 'fold';
  }, [mobileDeviceMenuStyle]);

  const NewTitle = () => {
    return (
      <div className='footer-menu-collapse-title'>
        {title}
        {isShowTitleBtn && (
          <svg
            className='footer-menu-collapse-title-btn'
            onClick={changeSubMenuStatus}
            aria-hidden='true'
            width='20'
            height='20'
            fill={style?.color}
          >
            <use xlinkHref={`#${subMenuStatus ? 'iconic_close' : 'iconic_open'}`} />
          </svg>
        )}
      </div>
    );
  };

  return (
    <Style mobileDeviceMenuStyle={mobileDeviceMenuStyle}>
      <div
        className={cn(
          'footer-menu-collapse',
          `mobile-device-footer-text-align-${mobileDeviceFooterTextAlign}`,
          `mobile-device-menu-style-${mobileDeviceMenuStyle}`,
        )}
      >
        <Collapse activeKey={activeKey} onChange={changeSubMenuStatus} panelTitleBackground='none' bordered={false}>
          <Collapse.Panel title={<NewTitle />} key='1' showArrow={false}>
            {!!children && (
              <div
                className={cn(
                  'footer-menu-collapse-content-box',
                  `sub-menu-status-${activeKey === 1}`,
                  `is-mobile-fold-indent-${isMobileFoldIndent}`,
                )}
              >
                {children}
              </div>
            )}
          </Collapse.Panel>
        </Collapse>
      </div>
    </Style>
  );
};
