import styled, { css } from 'styled-components';
import { getSizeVw } from '../../../../style/fn';

export const Style = styled.div`
    background: #f7f7f7;
    margin-top: auto;
    .page-footer {
          .page-footer-width {
            max-width: 100%;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 80px;
            &.footer-width-1280px {
                width: 1280px;
                padding-right: 0;
                padding-left: 0;
            }
            &.footer-width-1440px {
                width: 1440px;
                padding-right: 0;
                padding-left: 0;
            }Î 
        }
    }

    .footer-link-container {
        display: flex;
        .link-item {
            flex: 1;
            padding-left: 24px;
            padding-left: 64px;
            &:first-child {
                padding-left: 0;
            }
        }
    } 
   
    @media screen and (max-width: 1280px) {
        .page-footer  .page-footer-width.footer-width-1280px {
            padding-left: ${getSizeVw(80)};
            padding-right: ${getSizeVw(80)};
        }
    }
    @media screen and (max-width: 1440px) {
        .page-footer  .page-footer-width.footer-width-1440px {
            padding-left: ${getSizeVw(80)};
            padding-right: ${getSizeVw(80)};
        }
    }

    @media screen and (max-width: 750px) {
        .page-footer {
            padding: 0;
            box-sizing: border-box;
            /* 购物车页面不显示页脚 */
            &[data-path='/shopping-cart'] {
                display: none !important;
            }
            .page-footer-width {
                padding: 40px 16px !important;
                 .footer-link-container {
                    display: flex;
                    flex-direction: column;
                   
                    box-sizing: border-box;
                    height: auto;
                    .link-item {
                        padding-left: 0;
                        width: 100%;
                        &:last-child {
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }
                    }
                }
                .footer-local-info-container {
                    padding-bottom: 0;
                    border-top: 0;
                    padding-top: 0;
                    .footer-company-info {
                        display: block;
                    }
                }
            }
           

            &.mobile-device-menu-style-open { 
                .link-item {
                    margin-bottom: 32px;
                }
            }

            /* 折叠 */
            &.mobile-device-menu-style-fold {
                .footer-link-container {
                    display: block;
                }
                /* 折叠的话 只有title 只有之中方式 */
                .footer-menu-collapse .footer-menu-collapse-title {
                    justify-content: space-between;
                }
                .link-item {
                    margin-top: 32px;
                    margin-bottom: 32px;
                    &:first-child {
                        margin-top: 0;
                    }
                    /* 最后一个 没有边框 */
                    &:last-child {
                        border-bottom: 0;
                        margin-bottom: 32px;
                    }
                    /* 添加菜单的上下边框 */
                    &.link-item-type-custom-menu,
                    &.link-item-type-link_list {
                        margin-bottom: 0;
                        margin-top: 0;
                        border-top: 1px solid ${(props) => props.borderColor || '#ddd'};
                        border-bottom: 1px solid ${(props) => props.borderColor || '#ddd'};
                    }
                }
                .link-item-is-menu-true + .link-item-is-menu-true{
                    border-top: 0;
                }
                /* 折叠状态下 只有系统菜单支持两行 */
                &.mobile-display-two-in-one-line-true {
                    .footer-link-container {
                        .link-item {
                            &.link-item-type-text, &.link-item-type-custom-menu {
                               width: 100%;
                               /* padding-right: 16px; */
                            }
                        }
                    }
                }
            }
            &.mobile-display-two-in-one-line-true {
                .footer-link-container {
                    flex-direction: initial;
                    flex-wrap: wrap;
                    /* gap: 0 16px; */
                    .link-item {
                        flex: none;
                        &.link-item-type-text, &.link-item-type-custom-menu {
                            flex: none;
                            width: 50%;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
`;
