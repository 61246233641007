import isEmpty from 'lodash/isEmpty';

const eventMap = new Map();
eventMap.set('add_cart', 'add_to_cart');
eventMap.set('search', 'search');
eventMap.set('completeOrder', 'completeOrder');
eventMap.set('Cartview_gocheckout', 'begin_checkout');
eventMap.set('buy_now', 'begin_checkout');
eventMap.set('pay_success', 'purchase');
eventMap.set('add_payment_info', 'add_payment_info');
eventMap.set('add_shipping_info', 'add_shipping_info');
eventMap.set('login', 'login');
eventMap.set('view_cart', 'view_cart');
eventMap.set('remove_from_cart', 'remove_from_cart');
eventMap.set('select_item', 'select_item');
eventMap.set('share', 'share');
eventMap.set('sign_up', 'sign_up');
eventMap.set('view_item', 'view_item');
class GoogleAnalytics {
  constructor(eventName, data) {
    const { currency } = window.global_data?.shopSetting;
    this.eventName = eventMap.get(eventName);
    this.data = data;
    this.currency = currency;
    this.dataMatch = { currency };
  }
  // 根据事件不同 数据格式化
  formatData(eventName) {
    const eventDataFormat = new Map();
    // 事件：添加到购物车
    eventDataFormat.set('add_to_cart', () => {
      const { goodsId: item_id, title: item_name, sku, price, variantId, quantity } = this.data;
      this.dataMatch = {
        ...this.dataMatch,
        items: [
          {
            item_id,
            item_name,
            promotion_id: 'none',
            item_variant: variantId,
            price: price / 1000,
            currency: this.currency,
            quantity,
          },
        ],
        value: this.data.price / 1000,
      };
    });
    // 事件：此事件表示从列表中选择了商品。
    eventDataFormat.set('select_item', () => {
      const { filter, item } = this.data;
      const item_list_name = filter?.goodsTags || [];
      const items = [{ item_id: item?.goodsId, item_name: item?.title, promotion_id: 'none', promotion_name: 'none' }];
      this.dataMatch = { item_list_name: item_list_name[0], items };
    });
    // 事件：商品搜索
    eventDataFormat.set('search', () => {
      this.dataMatch = this.data;
    });

    eventDataFormat.set('completeOrder', () => {
      const { goodsList = [], discountInfo = {} } = this.data?.source;
      const coupon = discountInfo?.discountCode || '';
      let value = 0;
      const items = goodsList.map((item) => {
        const item_id = item?.variantId;
        const item_name = item?.title;
        const quantity = item?.quantity;
        const promotion_id = 'none';
        const promotion_name = 'none';
        const coupon = item?.discountCode || '';
        const item_variant = item?.sku;
        const price = (item?.price * quantity) / 1000;
        value += price;
        return { item_id, item_name, quantity, coupon, promotion_id, promotion_name, item_variant, price };
      });
      this.dataMatch = {
        ...this.dataMatch,
        items,
        value,
        coupon,
      };
    });
    eventDataFormat.set('view_item', () => {
      const {
        source: { goodsDetail },
      } = this.data;
      const { goodsId, title, minPrice } = goodsDetail;
      this.dataMatch = {
        ...this.dataMatch,
        items: [
          {
            item_id: goodsId,
            item_name: title,
            promotion_id: 'none',
            price: minPrice / 1000,
            currency: this.currency,
          },
        ],
        value: minPrice / 1000,
      };
    });
    eventDataFormat.set('view_cart', () => {
      const {
        source: { cartList },
      } = this.data;
      this.dataMatch = {
        ...this.dataMatch,
        items: cartList.map(({ goodsId, title, variantId, price, quantity }) => {
          return {
            item_id: goodsId,
            item_name: title,
            promotion_id: 'none',
            item_variant: variantId,
            price: price / 1000,
            currency: this.currency,
            quantity,
          };
        }),
        value:
          cartList.reduce((total, item) => {
            return total + item.price;
          }, 0) / 1000,
      };
    });

    // 事件：开始结账流程
    eventDataFormat.set('begin_checkout', () => {
      // 购物车和立即购买都算
      let cartList = [];
      if (this.data.goods_id) {
        cartList = [this.data];
      } else {
        cartList = this.data?.source?.cartList;
      }
      this.dataMatch = {
        ...this.dataMatch,
        items: cartList.map(({ goodsId, title, variantId, price, quantity }) => {
          return {
            item_id: goodsId,
            item_name: title,
            promotion_id: 'none',
            item_variant: variantId,
            price: price / 1000,
            currency: this.currency,
            quantity,
          };
        }),
        value:
          cartList.reduce((total, item) => {
            return total + item.price;
          }, 0) / 1000,
      };
    });
    // 事件成功购买
    eventDataFormat.set('purchase', () => {
      const { source = {} } = this.data;
      const { formData = {} } = source;
      const { lineItems = [] } = formData;
      const items = lineItems.map((item) => {
        return {
          item_id: item?.goodsId,
          item_name: item?.title,
          promotion_id: 'none',
          promotion_name: 'none',
          quantity: item?.quantity,
          item_variant: item?.variantId,
          discount: item?.totalDiscount / 1000,
          price: item?.unitPrice / 1000,
        };
      });
      this.dataMatch = {
        ...this.dataMatch,
        items,
        transaction_id: formData.name,
        value: formData?.totalPrice / 1000,
        shipping: formData?.totalShippingPrice / 1000,
      };
    });
    // 事件 提交付款信息
    eventDataFormat.set('add_payment_info', () => {
      const {
        source: { formData },
      } = this.data;
      this.dataMatch = {
        ...this.dataMatch,
        value: formData.totalLineItemsPrice / 1000,
        payment_type: this.data?.payment_type,
        items: formData.lineItems.map(({ goodsId, title, variantId, unitPrice, quantity }) => {
          return {
            item_id: goodsId,
            item_name: title,
            promotion_id: 'none',
            item_variant: variantId,
            price: unitPrice / 1000,
            currency: this.currency,
            quantity,
          };
        }),
      };
    });

    // 事件 提交付款信息
    eventDataFormat.set('add_shipping_info', () => {
      const {
        source: { formData },
      } = this.data;
      this.dataMatch = {
        ...this.dataMatch,
        value: formData.totalLineItemsPrice / 1000,
        payment_type: this.data?.payment_type,
        items: formData.lineItems.map(({ goodsId, title, variantId, unitPrice, quantity }) => {
          return {
            item_id: goodsId,
            item_name: title,
            promotion_id: 'none',
            item_variant: variantId,
            price: unitPrice / 1000,
            currency: this.currency,
            quantity,
          };
        }),
      };
    });

    // 事件 分享
    eventDataFormat.set('share', () => {
      this.dataMatch = {
        method: this.data?.method,
      };
    });

    // 事件 从购物车移除
    eventDataFormat.set('remove_from_cart', () => {
      const { item = [] } = this.data;
      const itemDelete = item[0];
      this.dataMatch = {
        ...this.dataMatch,
        currency: 'USD',
        value: (itemDelete?.price * itemDelete?.quantity) / 1000,
        items: [
          {
            item_id: itemDelete?.goodsId,
            item_name: itemDelete?.title,
            price: itemDelete?.price / 1000,
            quantity: itemDelete?.quantity,
          },
        ],
      };
    });

    // 执行对应的数据格式化
    if (eventDataFormat.get(eventName)) eventDataFormat.get(eventName)();
  }
  eventLog() {
    console.log('this.eventName', this.eventName, 'this.data', this.data);
    if (!this.eventName) {
      return;
    }
    this.formatData(this.eventName);
    console.log('this.dataMatch', this.dataMatch);
    if (isEmpty(this.dataMatch)) {
      console.log('-------执行-------', this.eventName);
      window.gtag('event', this.eventName);
    } else {
      console.log('-------执行-------', this.eventName);
      window.gtag('event', this.eventName, this.dataMatch);
    }
  }
}

export default GoogleAnalytics;
