import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 11px 24px;
  cursor: pointer;
  .left-item {
    display: flex;
    align-items: center;
    .text {
      margin-left: 4px;
      line-height: 16px;
    }
  }
  .ic_cm_huiyuanbiaoqian {
    margin-right: 10px;
  }
  .member-status {
    &.expire {
      color: var(--color-text-assist) !important;
      background: rgba(0, 0, 0, 0.1) !important;
      .icon {
        color: var(--color-text-assist) !important;
      }
    }
  }
  @media screen and (max-width: 750px) {
    padding: 11px 16px;
  }
`;
