import React, { useState } from 'react';
import cn from 'classnames';
import Style from './style';

export const SlideLeft = (props) => {
  const {
    children,
    expendIconName,
    headIconName = 'iconic-shanchu',
    customClass,
    iconSize = '20',
    customHeaderClass = '',
    menuBackText = '',
    showMask = true,
    title = '',
    settings = {},
    style = {},
    defaultTextIconColor,
  } = props;
  const [slideStatus, setSlideStatus] = useState(false);
  const { sub_menu_text_color: subMenuTextColor } = settings;
  const onSectionClick = () => {
    setSlideStatus(!slideStatus);
  };

  return (
    <Style className={customClass} defaultTextIconColor={defaultTextIconColor} subMenuTextColor={subMenuTextColor}>
      {slideStatus && showMask && (
        <div
          onClick={() => {
            setSlideStatus(false);
          }}
          className='mobile-header-mask'
        />
      )}
      <div className='section-marker'>
        <div onClick={onSectionClick} className='header-mobile-menu-header'>
          {title && <div className='first-menu-name'>{title}</div>}
          <svg
            className='slide-container-head-icon'
            style={style}
            width={iconSize}
            height={iconSize}
            aria-hidden='true'
          >
            <use xlinkHref={`#${expendIconName}`} />
          </svg>
        </div>
        <div
          className='slide'
          style={{
            transform: slideStatus ? 'translateX(0%)' : 'translateX(-300px)',
          }}
        >
          <div className='slide-container'>
            <div className={cn('slide-container-header', customHeaderClass)}>
              {/* <HeaderLogo
                                jumpToOtherPage={jumpToOtherPage}
                                logoMaxWidth={settings.logoMaxWidth}
                                logo={settings.logo}
                                shopName={shopSetting.name}
                                logoColor={subMenuTextColor}
                            /> */}
              <span className='slide-back' onClick={() => setSlideStatus(false)}>
                <svg className='slide-container-close-icon' width='24' height='24' aria-hidden='true'>
                  <use xlinkHref={`#${headIconName}`} />
                </svg>
                <span className='menu-back-text'>{menuBackText}</span>
              </span>
            </div>
            {slideStatus ? <div className='slide-left-scroll-body'>{children}</div> : null}
          </div>
        </div>
      </div>
    </Style>
  );
};
