import styled from 'styled-components';

export const Style = styled.div`
  .goods-description-title {
    color: #333333;
    margin-top: 16px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .service-wrapper {
    margin-top: 0;
  }
  .item-wrapper {
    /* border-bottom: 1px solid #f5f5f5;
        padding-bottom: 16px; */
  }
  .remove-border {
    border: none;
  }
  @media screen and (max-width: 750px) {
    .service-wrapper {
      margin-top: 0;
    }
  }
`;
