import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Style from './style';
import cn from 'classnames';
import { debounce, isNaN, isNil } from 'lodash';
import Toast from 'cpn/toast';
import { useIntl } from 'react-intl';
import { formatDecimalNumber } from 'utils/money';

const isNumber = (value) => {
  return !isNil(value) && !isNaN(Number(value))
}

const InputNumberStepper = (props) => {
  const {
    className,
    value,
    onChange,
    min,
    max,
    onMinusEvent,
    onPlusEvent,
    disabled = false,
  } = props;

  const intl = useIntl();

  const [nativeInputValue, setNativeInputValue] = useState(value || 0);
  const minusDisabled = (isNumber(min) && nativeInputValue <= min) || disabled;
  const plusDisabled = (isNumber(max) && nativeInputValue >= max) || disabled;
  const inputDisabled = (minusDisabled && plusDisabled) || disabled;

  useEffect(() => {
    if (isNumber(value)) {
      setNativeInputValue(value);
    }
  }, [value]);

  const onChangeValue = useCallback(
    debounce((value) => {
      onChange && onChange(value);
    }, 200), [onChange]);

  const formatValue = (value) => {
    let newValue = formatDecimalNumber(value, 0);
    if (isNumber(min) && newValue < min) {
      newValue = min;
    } else if (isNumber(max) && newValue > max) {
      newValue = max;
    }
    return newValue;
  }

  const handleInput = (e) => {
    let value = formatDecimalNumber(e.target.value, 0);
    setNativeInputValue(value);
  }

  const onMinus = () => {
    if (disabled) return;
    if (typeof onMinusEvent === 'function') {
      onMinusEvent(nativeInputValue);
    };
    const newValue = parseInt(nativeInputValue) - 1;
    if (isNumber(min) && newValue < min) return;
    setNativeInputValue(newValue);
    onChangeValue(newValue);
  }
  const onPlus = () => {
    if (disabled) return;
    if (typeof onPlusEvent === 'function') {
      onPlusEvent(nativeInputValue);
    };
    const newValue = parseInt(nativeInputValue) + 1;
    if (isNumber(max) && newValue > max) return;
    setNativeInputValue(newValue);
    onChangeValue(newValue);
  }

  const onBlur = () => {
    const newValue = formatValue(nativeInputValue);
    setNativeInputValue(newValue);
    onChangeValue(newValue);
  };

  return (
    <Style className={cn(className)} >
      <div className='stepper-container'>
        <div
          className={cn([
            "minus-btn",
            { disabled: minusDisabled }
          ])}
          onClick={onMinus}
        >
          <svg aria-hidden='true'>
            <use xlinkHref='#icona-01jichu_jian' />
          </svg>
        </div>
        <input
          className={cn([
            'stepper-input-inner',
            { disabled: inputDisabled }
          ])}
          type="number"
          value={nativeInputValue}
          onInput={handleInput}
          disabled={inputDisabled}
          onBlur={onBlur}
        />
        <div
          className={cn([
            "plus-btn",
            { disabled: plusDisabled }
          ])}
          onClick={onPlus}
        >
          <svg aria-hidden='true'>
            <use xlinkHref='#icona-01jichu_jia' />
          </svg>
        </div>
      </div>
    </Style>
  );
};
export default InputNumberStepper;
