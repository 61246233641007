/* eslint-disable */

import React, { forwardRef, useContext, useState } from 'react';
import { DiscountType } from 'const/discount';
import { formateLiToYuan, getCurrenyInfo } from 'utils/money';
import { getMemberGlobalStyle } from 'helper/member';
import cn from 'classnames';
import { Icon } from 'components/base';
import { DashDiscountLabel } from '../discount/dash-discount-label';
import { calcGoodsDiscountPrice } from 'helper/discount';

import {
  GoodsDetailPriceStyle,
  PriceSkuStyle,
  PriceStyle,
  HtGoodsPriceWithDiscountStyle,
  HtGoodsPriceWithDiscountStyle2,
  MemberPriceStyle,
  HtGoodsListSkuPriceStyle,
  PriceSkuMemberStyle,
  PriceItemStyle,
} from './style';
import { useIntl } from 'react-intl';
import RootContext from '../../context/root';
import { isNil } from 'lodash';

/**
 * 单个价格展示
 * @param param0
 * @param type 样式类型：普通还是加粗特色样式： 'sale' ｜ 'sale-origin'  根据商品原价 商品原价（否折扣和划线）来区分不同样式
 * @param moneyType 钱的类型，negative表示负数，默认正数
 * @param showZero 是否展示小数点的0
 */
export const HtPrice = (props) => {
  const { className = '', type = 'sale', price, showZero = false, moneyType, currency, isSpecial = true } = props;
  const { initialData } = useContext(RootContext);
  const defaultCurrency = initialData?.shopSetting?.currency || currency;
  const calcCurrency = initialData.multiCurrencyInfo?.currency || defaultCurrency;
  const symbol = getCurrenyInfo(calcCurrency)?.symbol || '';
  const formatePrice = `${formateLiToYuan({
    value: price,
    showZero,
    currency: calcCurrency,
    multiCurrencyInfo: initialData.multiCurrencyInfo,
  })}`;
  const integer = formatePrice.split('.')?.[0];
  const decimal = formatePrice.split('.')?.[1];
  const defaultSizeMap = { sale: 7, 'sale-origin': 10 };

  return (
    <PriceStyle
      size={`h${props.size || defaultSizeMap[type]}`}
      className={cn(className, 'skiptranslate', `price-${type}`, 'price-item', { 'is-special': isSpecial })}
    >
      {moneyType === 'negative' ? <span>-</span> : null}
      <span className='price-symbol'>{symbol}</span>
      <span className='price-integer'>{integer}</span>
      {decimal && <span className='price-decimal'>.{decimal}</span>}
    </PriceStyle>
  );
};

// 商品级价格
export const GoodsPrice = ({ goodsDetail = {}, currency }) => {
  const { minPrice, maxPrice, discountInfo = {}, withVariants } = goodsDetail;
  const { discountType, discountGets = {} } = discountInfo;
  const { discountValue = 0 } = discountGets;
  let discountPrice = 0;
  const isPercentage = discountType === DiscountType.Percentage;
  if (isPercentage) {
    discountPrice = minPrice * ((100 - discountValue) / 100);
  }
  const hasPriceRange = minPrice !== maxPrice;

  return (
    <PriceItemStyle className='goods-price skiptranslate'>
      <div className={cn('cur-price')}>
        <HtPrice price={minPrice} currency={currency} />
        {hasPriceRange && (
          <div className='max-price'>
            <span className='line-item'>-</span>
            <HtPrice price={maxPrice} currency={currency} />
          </div>
        )}
      </div>
    </PriceItemStyle>
  );
};

const MemberPrice = (props) => {
  const { memberPrice, currency, size } = props;
  const { initialData, member } = useContext(RootContext);
  const isMember = member?.isMember;
  const globalStyle = getMemberGlobalStyle(initialData.memberThemeData) || {};
  const { label_bg: gLabelBg = '#FEF4E0', label_text: gLabelText = '#382702' } = globalStyle;
  const iconStyle = {
    fill: gLabelBg,
  };

  if (memberPrice === undefined) return null;

  return (
    <MemberPriceStyle className='member-price-wrapper skiptranslate' gLabelText={gLabelText} size={size}>
      {isMember ? (
        <div className='member-price is-member'>
          <HtPrice price={memberPrice} showZero={false} type='strong' size={size || 10} currency={currency} />
          <div className='member-icon'>
            <Icon name='iconic-huiyuanbiaoqian' size='mini' style={iconStyle} />
          </div>
        </div>
      ) : (
        <div
          className='member-price default'
          style={{
            background: gLabelBg,
          }}
        >
          <HtPrice price={memberPrice} showZero={false} size={size || 12} currency={currency} />
          <div className='member-icon'>
            <Icon name='iconic-huiyuanbiaoqian' size='mini' style={iconStyle} />
          </div>
        </div>
      )}
    </MemberPriceStyle>
  );
};

/** 商品详情页专用  */
export const HtGoodsDetailPrice = ({
  goodsDetail = {},
  currency,
  size = 'big',
  quantity,
  variantId,
  setVisibleDiscountTag,
}) => {
  const { minPrice, maxPrice, variants = [], bestVariant = {}, discountInfos = [] } = goodsDetail;
  const bestVariantInfo =
    variants.find((item) => (variantId ? item.variantId === variantId : item.variantId === bestVariant?.variantId)) ||
    {}; // 最优规格
  // const showRange = variants?.length > 1 && maxPrice !== minPrice;
  const memberPrice = bestVariantInfo?.memberInfo?.memberPrice; // 最优规格的会员价
  const { member } = useContext(RootContext);
  const isMember = member?.isMember;
  const showMemberPriceFirst = isMember && bestVariant?.memberInfo; // 优先展示会员价

  // 计算折扣之后的价格
  let currenctVariantPrice: any = null;
  if (variantId) {
    currenctVariantPrice = variants.find((item) => item.variantId == variantId)?.price;
  } else if (variants.length === 1 || maxPrice === minPrice) {
    currenctVariantPrice = variants[0]?.price;
  }
  let calcDiscountPrice: any = null;
  if (currenctVariantPrice) {
    const activityId = bestVariantInfo?.discountInfo?.activityId;
    const newDiscountInfos = discountInfos.filter((item) => item.activityId == activityId);
    const calcParams = {
      price: currenctVariantPrice,
      quantity,
      discountInfos: newDiscountInfos,
    };
    calcDiscountPrice = calcGoodsDiscountPrice(calcParams);
    // 存在活动优惠情况, 不展示折扣标签
    setVisibleDiscountTag && setVisibleDiscountTag(!calcDiscountPrice);
  }

  /** 只有一个规格,或者所有规格价格都一样，展示具体规格的价格 */
  if (!isNil(currenctVariantPrice)) {
    return (
      <HtGoodsSkuPrice
        {...bestVariantInfo}
        size={size}
        price={currenctVariantPrice}
        calcDiscountPrice={calcDiscountPrice}
      />
    );
  }

  return (
    <GoodsDetailPriceStyle
      className={cn(
        {
          'member-price-first': showMemberPriceFirst,
          'origin-price-first': !showMemberPriceFirst,
        },
        'skiptranslate',
        size,
      )}
      size={size}
    >
      <div className='cur-price'>
        <HtPrice
          price={minPrice}
          type={showMemberPriceFirst ? 'normal' : 'strong'}
          // size={showMemberPriceFirst ? 'middle' : 'big'}
          size={size}
          currency={currency}
        />
        <span className='line-item'> - </span>
        <div className='max-price'>
          <HtPrice
            price={maxPrice}
            type={showMemberPriceFirst ? 'normal' : 'strong'}
            // size={size || showMemberPriceFirst ? 'middle' : 'big'}
            size={size}
            currency={currency}
          />
        </div>
      </div>
      <div className='member-price-box'>
        <MemberPrice memberPrice={memberPrice} size='big' />
      </div>
    </GoodsDetailPriceStyle>
  );
};

/**
 * 用于-商品列表（商品列表只展示最低价）/商品详情的价格展示
 *
 * SKU级价格展示
 * 价格和折扣价展示
 * type 样式类型，上下布局/左右布局 row｜column
 * styleType 现价的样式-普通 还是强调
 * price：现价
 * compareAtPrice 划线价-原价
 * showZero 是否展示小数点的0
 * @param {*} param0
 * size: small middle
 */
export const HtGoodsSkuPrice = (props) => {
  const {
    price = 0,
    size = 'big',
    compareAtPrice = 0,
    className,
    discountInfo = {},
    memberInfo = {},
    showDashDiscountLabel, // 是否显示划线价标签
    calcDiscountPrice,
  } = props;
  const intl = useIntl();
  const discount = price - compareAtPrice < 0;
  const { member } = useContext(RootContext);
  const isMember = member?.isMember;
  const memberPrice = memberInfo?.memberPrice;
  const discountPrice = discountInfo?.discountPrice;
  const hasDiscount = discountPrice || discountPrice === 0; /** 存在固定折扣活动 */
  const [newShowDashDiscountLabel, setNewShowDashDiscountLabel] = useState(showDashDiscountLabel);

  /** 会员价 */
  if (memberPrice || memberPrice === 0) {
    return (
      <PriceSkuMemberStyle className={cn('skiptranslate', size)}>
        {!isMember && <HtPrice price={price} type='strong' size={size} className='origin-price-first' />}
        <MemberPrice memberPrice={memberPrice} isMember={isMember} size='big' />
        {isMember && <HtPrice price={price} type='normal' size={size} className='cur-price-not-first' />}
      </PriceSkuMemberStyle>
    );
  }

  // 下面的size其实应该拆分的更细 现在没这个需求就先不改了
  return (
    <PriceSkuStyle className={cn('goods-sku-price', size, `show-dash-discount-label-${newShowDashDiscountLabel}`)}>
      {/* 现价 */}
      <div className={cn('cur-price')}>
        <HtPrice type='sale' price={price} size={size} />
      </div>
      {/* 划线价标签 */}
      <DashDiscountLabel
        className='dash-discount-label'
        price={price}
        discountInfo={discountInfo}
        compareAtPrice={compareAtPrice}
        showDashDiscountLabel={showDashDiscountLabel}
        onChangeShowDashDiscountLabel={setNewShowDashDiscountLabel}
      />
      {(calcDiscountPrice || calcDiscountPrice === 0) && (
        <div className='discount-text'>
          <span className='desc-text'>
            {intl.formatMessage({
              id: '40634e941b6448219bf5d2bb0167f401',
              defaultMessage: '折后',
            })}
            &nbsp;
          </span>
          <HtPrice price={calcDiscountPrice} isSpecial={false} />
        </div>
      )}
      {/* 划线价 */}
      {!!discount && !calcDiscountPrice && calcDiscountPrice !== 0 && (
        <div className={cn('underlined-price')}>
          <HtPrice price={compareAtPrice} type='sale-origin' isSpecial={false} />
        </div>
      )}
    </PriceSkuStyle>
  );
};

/**
 * 用于-活动列表（买X送Y） / 拼团列表
 *
 * SKU级价格展示
 * 价格和折扣价展示
 * type 样式类型，上下布局/左右布局 row｜column
 * styleType 现价的样式-普通 还是强调
 * price：现价
 * compareAtPrice 划线价-原价
 * showZero 是否展示小数点的0
 * @param {*} param0
 */
export const HtSkuPrice = (props) => {
  const {
    styleType = 'sale',
    price = 0,
    compareAtPrice = 0,
    showZero = false,
    discountInfo = {},
    currency,
    size = 10,
  } = props;
  const intl = useIntl();
  const discount = price - compareAtPrice < 0;
  const { discountType, discountGets = {} } = discountInfo;
  const { discountValue = 0 } = discountGets;
  let discountPrice = 0;
  const isPercentage = discountType === DiscountType.Percentage;
  if (isPercentage) {
    discountPrice = price * ((100 - discountValue) / 100);
  }

  // return null;

  // 下面的size其实应该拆分的更细 现在没这个需求就先不改了
  return (
    <PriceSkuStyle className='goods-sku-price skiptranslate' size={size}>
      {/* 现价 */}
      <div className={cn('discount-price', { 'has-discount': !!isPercentage })}>
        <HtPrice size={size} price={price} type={styleType} showZero={showZero} currency={currency} />
      </div>

      {/* 划线价 */}
      {!isPercentage && !!discount && (
        <div className={cn('underlined-price')}>
          <HtPrice size={size} price={compareAtPrice} type='sale-origin' showZero={showZero} currency={currency} />
        </div>
      )}

      {/* 折后价，固定折扣时需要计算出折后的价格,原价是0就不显示了 */}
      {isPercentage && !!price && (
        <div className='discount-text'>
          <span className='desc-text'>
            {intl.formatMessage({
              id: '40634e941b6448219bf5d2bb0167f401',
              defaultMessage: '折后',
            })}
            &nbsp;
          </span>
          <HtPrice size={size} type='sale-origin' price={discountPrice} currency={currency} />
        </div>
      )}
    </PriceSkuStyle>
  );
};

/**
 * 使用地方：所有的商品列表展示-goods-card
 * 价格展示逻辑
 * 1. 无任何活动，展示价格，划线价
 * 2. 只有固定折扣活动，展示折扣价，划线价
 * 3. 只有会员活动，展示 1. 价格，划线价 2。 展示会员价，若当前用户不是会员，会员价在下面，若是会员，会员价展示在上面
 * 4. 有固定折扣也有会员，按照第2条展示价格，划线价  然后按照第三条继续展示会员价
 *
 *
 * bestVariant 最优规格价格信息
 * showCompareAtPrice 是否展示化线价
 * showOnlyOnePrice // 只展示一个价格
 */
export const HtGoodsListPrice = forwardRef(
  ({ className, bestVariant = {}, showCompareAtPrice = true, currency, size = 10, variants = [] }, ref) => {
    const { price, compareAtPrice } = bestVariant;

    const variantItem = variants.find((item) => item.variantId == bestVariant?.variantId);
    const memberInfo = bestVariant?.memberInfo || variantItem?.memberInfo;
    const discountInfo = bestVariant?.discountInfo || variantItem?.discountInfo;

    const memberPrice = memberInfo?.memberPrice;
    const { member } = useContext(RootContext) || {};
    const isMember = member?.isMember;
    const showMemberPriceFirst = isMember && memberInfo; // 优先展示会员价

    return (
      <HtGoodsListSkuPriceStyle
        ref={ref}
        className={cn(className, 'skiptranslate', {
          'member-price-first': showMemberPriceFirst,
        })}
      >
        {/* 其他价格 【包含折扣价】 */}
        <HtGoodsPriceWithDiscount
          discountInfo={discountInfo}
          price={price}
          compareAtPrice={compareAtPrice}
          showCompareAtPrice={showCompareAtPrice}
          currency={currency}
          showMemberPriceFirst={showMemberPriceFirst}
          size={size}
        />
        {/* 会员价格 */}
        <MemberPrice memberPrice={memberPrice} currency={currency} size={size} />
      </HtGoodsListSkuPriceStyle>
    );
  },
);

/**
 * 计算当前价
 */
const getPrice = ({ discountInfo, price, compareAtPrice }) => {
  let curPrice = price;
  let curCompareAtPrice = compareAtPrice;
  const { discountPrice } = discountInfo;
  if (discountPrice && discountPrice !== 0) {
    curPrice = discountPrice;
    curCompareAtPrice = price;
  }
  return {
    curPrice,
    curCompareAtPrice,
  };
};

/**
 * 目前使用地方：拼团 / 会员 / 列表价格基础组件
 * 现价和划线价展示
 * type 样式类型，上下布局/左右布局 row｜column
 * styleType 现价的样式-普通 还是强调 normal ｜ strong
 * price：商品价格-商家后台配置的
 * compareAtPrice 划线价-原价
 * showCompareAtPrice 是否展示划线价
 * @param {*} param0
 */
export const HtGoodsPriceWithDiscount = (props) => {
  const {
    className,
    price,
    compareAtPrice = 0,
    discountInfo = {},
    showCompareAtPrice = true,
    size = 10,
    currency,
    showMemberPriceFirst,
  } = props;
  const { curPrice, curCompareAtPrice } = getPrice({
    discountInfo,
    price,
    compareAtPrice,
  });
  const discount = curPrice - curCompareAtPrice < 0; // 原价比现价大 才显示划线

  return (
    <HtGoodsPriceWithDiscountStyle
      className={cn(className, 'goods-price-style skiptranslate', {
        'show-member-first': showMemberPriceFirst,
      })}
    >
      {/* 现价 */}
      <div className={cn('cur-price')}>
        <HtPrice price={curPrice} size={size} currency={currency} />
      </div>

      {/* 划线价 */}
      {showCompareAtPrice && !!discount && !showMemberPriceFirst && (
        <div className={cn('underlined-price')}>
          <HtPrice price={curCompareAtPrice} type='sale-origin' size={size} currency={currency} />
        </div>
      )}
    </HtGoodsPriceWithDiscountStyle>
  );
};

/**
 * 目前使用地方：拼团 / 会员 / 列表价格基础组件
 * 现价和划线价展示
 * type 样式类型，上下布局/左右布局 row｜column
 * styleType 现价的样式-普通 还是强调 normal ｜ strong
 * price：商品价格-商家后台配置的
 * compareAtPrice 划线价-原价
 * showCompareAtPrice 是否展示划线价
 * @param {*} param0
 */
export const HtGoodsPriceWithDiscount2 = (props) => {
  const {
    className,
    price,
    compareAtPrice = 0,
    showCompareAtPrice = true,
    size,
    initialData: initialDataProp,
    direction = 'column', // 搞不懂为什么以前默认是列，丑的很，加个配置项
    align = 'stretch',
  } = props;
  const discount = price - compareAtPrice < 0; // 原价比现价大 才显示划线
  const context = useContext(RootContext);
  const initialData = initialDataProp || context?.initialData;
  const globalStyle = getMemberGlobalStyle(initialData?.memberThemeData);
  const { label_bg: gLabelBg = '#FEF4E0', label_text: gLabelText = '#382702' } = globalStyle;
  const iconStyle = {
    fill: gLabelBg,
  };

  return (
    <HtGoodsPriceWithDiscountStyle2
      className={cn(className, 'goods-price-style', 'skiptranslate')}
      direction={direction}
      align={align}
    >
      {/* 现价 */}
      <div className={cn('cur-price')}>
        <HtPrice price={price} size={size} />
        <div className='member-icon' style={{ background: gLabelText }}>
          <Icon name='iconic-huiyuanbiaoqian' size='small' style={iconStyle} />
        </div>
      </div>

      {/* 划线价 */}
      {showCompareAtPrice && !!discount && (
        <div className={cn('underlined-price')}>
          <HtPrice price={compareAtPrice} type='sale-origin' size={size} />
        </div>
      )}
    </HtGoodsPriceWithDiscountStyle2>
  );
};
