// 装修共用-图片容器组件
// 可配置项：容器的宽高widthType｜heightType（有几种规则，请选择需要的规则heightRules），是否显示叠加showOverlay，叠加颜色overlayColor，其他配置见background-image组件
// hideImg 配置扩展了该组件的功能，让其可以做为一个容器组件用
// 当使用hideImg，且children内容是轮播组件时，url传第一张轮播图地址，还可以完成轮播组件高度适应第一张图的功能
import BackgroundImg from '../background-image';
import { Style } from './style';
import cn from 'classnames';
import React, { useRef, useEffect } from 'react';
import RootContext from '../../../../pages/context/root';

const HeightType = {
  // 适合单个图文叠加之类
  type1: {
    adapt: 'adapt', // 适应第一张图片
    'full-height': 'full-height',
    'x-small': 'x-small',
    small: 'small',
    medium: 'medium',
    large: 'large',
    'x-large': 'x-large',
  },
  // 适合大图，轮播图
  type2: {
    adapt: 'adapt', // 适应第一张图片
    'full-height': 'full-height',
    small: 'small2',
    medium: 'medium2',
    large: 'large2',
  },
  // 适合宽高比展示情况 等设计出规范
  type3: {
    adapt: 'adapt', // 适应第一张图片
    'full-height': 'full-height',
    '16/9': 'radio-16-9',
    '4/3': 'radio-4-3',
    '1/1': 'radio-1-1',
    '3/2': 'radio-3-2',
    '3/4': 'radio-3-4',
    '2/3': 'radio-2-3',
  },
};

function ImageContainer(props) {
  const {
    heightType = 'adapt',
    heightRules = 'type1', // 指定用哪个高度规则
    showOverlay = false,
    overlayColor,
    children,
    className,
    url,
    position,
    fit = 'cover',
    hideImg = false, // 是否隐藏图片，适用情况：只用该组件的宽高计算功能,
    showDefault = true,
    alt,
    compress = true,
    ...extra
  } = props;
  const ref = useRef(); // 本身ref
  const imageRef = useRef(); // 图片ref
  const onLoad = (e) => {
    // 拿到图片的宽高
    const realWidth = e?.target?.naturalWidth;
    const realHeight = e?.target?.naturalHeight;
    ref.current.style.paddingTop = `${(realHeight / realWidth) * 100}%`;
  };
  useEffect(() => {
    if (heightType === 'adapt') {
      // 如果没有图片地址，显示固定的高度
      if (!url) {
        ref.current.style.paddingTop = '100%';
      } else {
        // 拿到图片的宽高
        const realWidth = imageRef?.current?.naturalWidth;
        const realHeight = imageRef?.current?.naturalHeight;
        ref.current.style.paddingTop = `${(realHeight / realWidth) * 100}%`;
      }
    }
  }, [heightType]);
  return (
    <Style className={className} {...extra}>
      <div ref={ref} className={cn('image-container', HeightType[heightRules][heightType], { 'hide-img': hideImg })}>
        <BackgroundImg
          showDefault={showDefault}
          className={cn('bg-img')}
          ref={imageRef}
          url={url}
          position={position}
          fit={fit}
          alt={alt}
          onLoad={heightType === 'adapt' && onLoad}
          compress={compress}
        />

        {showOverlay && <div className='img-mask' style={{ background: overlayColor }} />}
        {children && <div className='content'>{children}</div>}
      </div>
    </Style>
  );
}

export default ImageContainer;
