import React, { useContext } from 'react';
import HuntTag from '../../hunt-tag';
import { useIntl } from 'react-intl';
import { DiscountType } from 'const/discount';
import { getValueByLang } from 'helper/discount';
import RootContext from '../../../context/root';
import Style from './style';
/**
 * 折扣类型标签
 * */
export const DiscountTag = (props) => {
  const { className, discountType, discountValue, bordered = true } = props;
  const { language } = useContext(RootContext);
  const intl = useIntl();
  const discount = getValueByLang(discountValue, language);
  const discountTagMap = {
    [DiscountType.FixedAmount]: intl.formatMessage({
      id: 'b21322fb73cb4c2e83613cb5c587ae83',
      defaultMessage: '满减',
    }),
    [DiscountType.Bxgy]: intl.formatMessage({
      id: 'bbc986c0f4784700800b73828e37c520',
      defaultMessage: '满赠',
    }),
    [DiscountType.Percentage]: intl.formatMessage(
      {
        id: '7a4a1421e69e44e18c48a59606586eed',
        defaultMessage: `${discount}%OFF`,
      },
      {
        discount,
      },
    ),
  };

  return (
    <Style className={className}>
      <HuntTag className='discount-tag-icon' bordered={bordered}>
        {discountTagMap[discountType]}
      </HuntTag>
    </Style>
  );
};
