// 对应装修-感言
import React, { useState, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import isObject from 'lodash/isObject';
import { Wrapper } from 'components/base';
import { useClientWidth } from '../../../../hook/global-theme';
import { Style } from './style';
import ContainerSize from '../../components/container-size';

SwiperCore.use([Pagination]);

const SpaceBetween = 40;
const WidthPoint = 750;

const QuoteItem = ({ showSymbol, quote, author, isMobile = false }) => {
  return (
    <div className={cn('quote-blocks-item', { 'is-mobile': isMobile })}>
      {showSymbol ? (
        <div className='quote-blocks-symbol'>
          <svg className='return-icon' aria-hidden='true' height='24px' width='24px'>
            <use xlinkHref='#iconic_thanks' />
          </svg>
        </div>
      ) : null}
      <div
        className={cn('quote-blocks-desc', { 'is-mobile': isMobile })}
        dangerouslySetInnerHTML={{
          __html: isObject(quote) ? '' : quote,
        }}
      />
      <div className='quote-blocks-author'>{isObject(author) ? '' : author}</div>
    </div>
  );
};

const Quotes = ({ settings = {}, blocks, block_order: blockOrder, elemClass }) => {
  const { show_symbol: showSymbol, title, box_width: boxWidth } = settings;
  const [slidesPerView, setSlidesPerView] = useState(3);

  const navigationClass = blockOrder.length > slidesPerView ? 'show-navigation' : 'hide-navigation';
  const { clientWidth } = useClientWidth();
  const isMobile = useMemo(() => {
    return clientWidth <= WidthPoint;
  }, [clientWidth]);
  const sliderRef = useRef();
  const setPerView = () => {
    const blockLen = blockOrder.length;
    const bodyWidth = document.body.clientWidth;

    if (!blockLen) return;
    if (bodyWidth < WidthPoint) {
      setSlidesPerView(1);
    } else if (bodyWidth > WidthPoint && blockLen < 3) {
      setSlidesPerView(blockLen);
    } else {
      setSlidesPerView(3);
    }
    // onResize事件里执行update一直不生效，加个延时很重要！！！
    setTimeout(() => {
      sliderRef?.current?.swiper?.update();
    }, 500);
  };
  useEffect(() => {
    setPerView();
  }, [settings, blocks]);
  // 容器更改，swiper要重新计算宽度

  useEffect(() => {
    sliderRef?.current?.swiper?.update();
  }, [boxWidth]);
  return (
    <Style
      className={cn('quote-section', elemClass, {
        'show-pagination': blockOrder.length > slidesPerView,
      })}
    >
      <ContainerSize boxWidth={boxWidth} needSafeSpace>
        <Wrapper marginTop='vContainer' marginBottom='vContainer'>
          <div className={cn('quote', navigationClass)}>
            <h3 className={cn('quote-content-title', 'section-component-title')}>{title}</h3>
            <div className={cn('quote-blocks-list', { 'is-mobile': isMobile })}>
              {!isMobile && (
                <Swiper
                  ref={sliderRef}
                  onInit={setPerView}
                  onResize={setPerView}
                  spaceBetween={40}
                  slidesPerView={slidesPerView}
                  slidesPerGroup={slidesPerView}
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                >
                  {blockOrder.map((blockId) => {
                    const { quote, author } = blocks[blockId]?.settings || {};

                    return (
                      <SwiperSlide key={blockId}>
                        <QuoteItem isMobile={isMobile} quote={quote} author={author} showSymbol={showSymbol} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
              {isMobile &&
                blockOrder.map((blockId) => {
                  const { quote, author } = blocks[blockId]?.settings || {};
                  return <QuoteItem isMobile={isMobile} quote={quote} author={author} showSymbol={showSymbol} />;
                })}
            </div>
          </div>
        </Wrapper>
      </ContainerSize>
    </Style>
  );
};

export default Quotes;
