import { useEffect, useRef } from 'react';

export const useDidMount = (fn) => {
  useEffect(() => {
    fn();
  }, []); // 第二个参数设置为[], 表示不必对任何数据， 所以只在首次渲染时调用
};

export const useDidUpdate = (fn, dep) => {
  const ref = useRef({ fn, mounted: false });
  ref.current.fn = fn;

  useEffect(() => {
    if (!ref.current.mounted) {
      ref.current.mounted = true;
      // 首次渲染不执行
    } else {
      ref.current.fn();
    }
  }, dep);
};
