// 商品限购
export enum LIMIT_TYPE {
  period = 'period',
  order = 'order',
}
// 限购周期
export enum LIMIT_PERIOD {
  day = 'day',
  week = 'week',
  month = 'month',
}
