import styled from 'styled-components';
import { getSizeVw } from '../../../../../style/fn';

export default styled.div`
  .header-announcement {
    line-height: 24px;
    padding: 12px 0;
    background: #1a1a1b;
    text-align: center;
  }
  .announcement_link {
    color: #fff;
  }

  .sub-header-container {
    padding: 18px ${getSizeVw(80)};
    position: relative;
    min-height: 77px;
    box-sizing: border-box;
    background: #fff; // 头部背景色不随全局设置改变
    border-bottom: 1px solid var(--color_borders);
  }

  .sub-menu-content {
    position: fixed;
    left: 0;
    width: 100%;

    box-shadow: 0 4px 8px 0 rgba(21, 22, 27, 0.2);
  }

  .wrapper {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }

  .menu-list {
    display: flex;
    margin-left: getPercentWidth(80px);
    flex-wrap: wrap;
  }
  .menu-item {
    font-size: var(--base-header-font-16-14);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    font-weight: 300;
    color: var(--color_text);
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    padding: 0 ${getSizeVw(32)};
  }

  .home {
    font-weight: 500;
    color: var(--color_text);
    font-size: var(--base-header-font-16-14);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
  }

  .mask {
    left: -0;
    top: 0px;
    z-index: 99;
    width: 100%;
    height: 200%;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
  }

  .header-center-logo-container .header-operate-container {
    position: absolute;
    transform: translateY(-50%);
    right: 80px;
    top: 50%;
  }

  .header-center-logo {
    position: relative;
    text-align: center;
    box-shadow: 0px 1px 0px 0px #d1d5db;
  }
  .header-center-logo-container {
    padding-top: 32px;
    background: #fff;
  }
`;
