import styled from 'styled-components';

export const Style = styled.div`
  .title {
    color: var(--color_body_text);
    font-weight: var(--font-weight-header);
    word-break: break-word;
  }
  .space {
    margin-top: ${(props) => props.theme.getSpace([16, 24])};
  }
  .zent-number-input {
    background: var(--color_text_field);
    .zent-number-input-count {
      background: var(--color_text_field);
      color: var(--color_text_field_text);
      &.zent-number-input-count-disable {
        color: rgba(var(--color_text_field_text_rgb), 0.2);
      }
    }
    .zent-number-input-countreduce {
      border-right: none;
      font {
        content: '-';
        font {
          display: none;
        }
      }
    }
    .zent-number-input-countadd {
      border-left: none;
    }
  }

  .detail-desc {
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .vendor-wrapper {
    color: #bfbfbf;
    line-height: 20px;
    margin-top: var(--pm-16-8);
  }
  .price-wish-wrapper {
    margin-top: var(--pm-16-10);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rate-area {
    margin-top: var(--pm-16-10);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => {
      if (props.theme.colorBodyTexts) return props.theme.colorBodyTexts['80'];
    }};
    span.line {
      display: inline-block;
      height: 16px;
      width: 1px;
      background: #b3b9c6;
      margin: 0 15px;
    }
  }

  .seperate-dot {
    margin-left: 4px;
    margin-right: 4px;
  }
  .custom-number-input {
    border-radius: var(--border_radius_btn) !important;
  }
  .limit-tips {
    display: flex;
    align-items: center;
    color: var(--color_activity_label);
    margin-left: 8px;
    font-size: 12px;
    line-height: 16px;
  }
  @media screen and (max-width: 768px) {
    .rate-area {
      display: none;
    }
  }
`;

export const PriceStyle = styled.div`
  @media screen and (max-width: 750px) {
    .detail-desc {
      margin-top: 16px !important;
      margin-bottom: 0px !important;
    }
    .price-sale {
      margin-top: 0px !important;
    }
    .discount-text {
      margin-left: 16px !important;
    }
  }
`;

export const GoodsScoreInfoStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) => props.theme.getBaseSize([12])};
  color: rgba(var(--color_body_text_rgb), 0.8);
  flex-wrap: wrap;
  flex-basis: 100%;
  .goods-score-container {
    margin-right: 8px;
    margin-top: 8px;
  }
`;
export const GoodsInfoText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  max-width: 100%;
  &::after {
    content: '';
    width: 1px;
    height: 12px;
    background-color: rgba(var(--color_body_text_rgb), 0.2);
    margin: auto 8px;
    display: block;
  }
  &:last-child::after {
    display: none;
  }
  .goods-vendor {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    margin-top: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`;
