import styled from 'styled-components';

export const Style = styled.div`
  .zent-number-input-count-disable .zent-input {
    background: var(--color_text_field);
  }
`;

export const WarnTipsStyle = styled.div`
  color: rgba(51, 51, 51, 0.6);
  margin-top: 4px;
  font-size: 12px;
  .zero-warn {
    color: #cc1d14;
  }
  .less-warn {
    position: absolute;
  }
`;
