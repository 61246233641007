import React, { useState } from 'react';
import { Form, FormStrategy, Validators } from 'zent';
import HtButton from '../../../../standard/button';
import CodeInput from '../../../code-input';
import { OperateButtons } from './style';
import HtFormInputPhone from 'client/components/standard/form-input-phone';
import ThirdLogin from '../third-login';
import useThirdLogin from '../../../../../hook/login/use-third-login';
import { SCENE_TYPE } from 'client/const/account';
import { isEmpty } from 'client/helper/verify';
import UserAccount from 'client/controllers/user-account';

function LoginWithCode(props) {
  const { onClose, onRegister, intl, language, onLoginSuccess } = props;
  const [mobile, setMobile] = useState('');
  const [mobileCountryCode, setMobileCountryCode] = useState('');
  const onMobileChange = (val) => {
    setMobile(val.trim());
  };
  const onMobileCountryCodeChange = (val) => {
    console.log('onMobileCountryCodeChange', val);
    setMobileCountryCode(val.trim());
  };
  console.log('mobileCountryCode1', mobileCountryCode);

  const form = Form.useForm(FormStrategy.View);

  const onSubmit = () => {
    const formData = form.getValue();
    const { mobile, verifyCode } = formData;
    console.log('mobileCountryCode', mobileCountryCode);
    UserAccount.loginByVerifyCode({
      mobile,
      verifyCode,
      mobileCountryCode,
    })
      .then(() => {
        onLoginSuccess();
      })
      .catch((err) => {
        console.log('handleSave err', err);
      });
  };
  const { loginChannels, onLineLogin, onFbLogin, onProviderLogin } = useThirdLogin({
    onLoginSuccess,
    language,
    intl,
  });
  return (
    <div>
      <Form layout='vertical' form={form} onSubmit={onSubmit}>
        <HtFormInputPhone
          width='100%'
          intl={intl}
          form={form}
          onMobileChange={onMobileChange}
          onMobileCountryCodeChange={onMobileCountryCodeChange}
          validators={[
            Validators.required(
              intl.formatMessage({
                id: '1b3c254437d149c8a84c8b95c62867c1',
                defaultMessage: '请输入手机号',
              }),
            ),
            isEmpty(
              intl.formatMessage({
                id: '1b3c254437d149c8a84c8b95c62867c1',
                defaultMessage: '请输入手机号',
              }),
            ),
          ]}
        />
        <CodeInput
          intl={intl}
          mobile={mobile}
          form={form}
          scene={SCENE_TYPE.login}
          mobileCountryCode={mobileCountryCode}
          verifyCodeId='verify_code_login'
        />
        <OperateButtons>
          <HtButton full size='large' htmlType='submit'>
            {intl.formatMessage({
              id: 'account.login',
              defaultMessage: '登录',
            })}
          </HtButton>
          <HtButton
            full
            plain
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              onClose && onClose();
              onRegister();
            }}
          >
            {intl.formatMessage({
              id: 'account_register',
              defaultMessage: '注册',
            })}
          </HtButton>
        </OperateButtons>
        <ThirdLogin
          loginChannels={loginChannels}
          intl={intl}
          onLineLogin={onLineLogin}
          onFbLogin={onFbLogin}
          onProviderLogin={onProviderLogin}
        />
      </Form>
    </div>
  );
}

export default LoginWithCode;
