import React from 'react';
import NewLink from 'cpn/new-link';
import { Style } from './style';
import { View } from 'components/base';

function SecondMenuGroup(props) {
  const { width, menuInfo = {} } = props;
  const { block_order: blockOrder = {}, blocks: blocks = {} } = menuInfo;
  return (
    <Style width={width}>
      {blockOrder.map((blockId) => {
        if (!blocks[blockId]) return null;
        const blockItem = blocks[blockId];
        const { settings = {} } = blockItem;
        const { announcement_link: announcementLink, open_target: openTarget, title } = settings;
        const path = announcementLink ? JSON.parse(announcementLink)?.url : '';
        return (
          title && (
            <View as='li' size={11} className='menu-item'>
              <NewLink href={path} target={openTarget} key={blockId}>
                {title}
              </NewLink>
            </View>
          )
        );
      })}
    </Style>
  );
}

export default SecondMenuGroup;
