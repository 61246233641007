/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-cycle */
import image1 from './image';

/**
 * 根据宽度转成相应枚举值
 * @param {Number} width 1000
 * @return {String} enum
 */
const convertWidthToEnum = (width = 1000) => {
  // 默认原始图
  let size = 'origin';
  if (width <= 260) {
    size = 'small';
  } else if (width <= 520) {
    size = 'middle';
  } else if (width <= 900) {
    size = 'large';
  }
  return size;
};
// 容错图片格式，只支持4种格式；默认转jpg; 支持webp就写webp
const lastSuffixAdaptor = (format = '') => {
  format = format.toLocaleLowerCase();
  const transReg = /(jpg|gif|png|webp)/;
  if (!transReg.test(format)) {
    format = 'jpg';
  }
  if (image1.getCanWebp()) {
    format = 'webp';
  }
  return format;
};

// 拿个!middle.jpg这样的后缀
const getExactSuffix = (rule, format) => {
  const cdnForamt = lastSuffixAdaptor(format);
  return `!${rule}.${cdnForamt}`;
};

/**
 * 获取正规的url，把不同的宽度转化成
 * small, middle, large, origin这几个值
 * @url {String} url链接
 * @urlParams {String} 解析过的url 参数
 * @return {String} http://xx.com/xx.jpg!small.jpg
 */
const getExactUrl = (url, urlParams) => {
  const { originFormat } = urlParams;
  const { format } = urlParams;
  const { width } = urlParams;
  const size = convertWidthToEnum(width);
  const plainUrl = url.replace(exports.getCdnRegex(), '');
  const suffix = getExactSuffix(size, format);
  // 格式：xx.jpg!small.jpg
  return `${plainUrl}.${originFormat}${suffix}`;
};

// 通过图片url拿格式，这个要保留原来的格式类型
const getFormatByUrl = (url) => {
  const urlReg = /\.(jpe?g|png|bmp|gif)/i;
  const regResult = url.match(urlReg);
  let format = 'jpg';
  if (regResult) {
    format = regResult[1];
  }
  return format;
};

/**
 * n年前老代码
 * 如果是七牛的格式，会对图片格式进行一次替换；
 * 支持webp的就改成webp
 * 否则就改成jpg
 */
export const transQiniuFormatToWebp = (src) => {
  const canWebp = image1.getCanWebp();
  // 匹配(?imageView2/2/w/200/h/200/q/75/format)(jpg)
  const pattern = /(\?imageView2\/\d\/w\/\d+\/h\/\d+\/q\/\d+\/format\/)(\w+)/;
  let result;
  result = src;
  if (pattern.test(src)) {
    const format = src.match(pattern)[2];
    if (canWebp) {
      if (format !== 'webp') {
        result = src.replace(pattern, '$1webp');
      }
    } else if (format === 'webp') {
      // 这里还是要拿出原始格式
      const originFormat = getFormatByUrl(src);
      // 对于不支持webp格式的浏览器把错误的地址切换回去
      result = src.replace(pattern, `$1${originFormat}`);
    }
  }
  return result;
};

/**
 * 匹配类似 .(jpg)!(1000)x(1000)q(100)(+2x).jpg;
 * 不写一个单独常量是因为正则的lastIndex坑
 */
export const getCdnRegex = () => {
  const CDN_REGEX = /\.([^.!]+)!([0-9]{1,4})x([0-9]{1,4})q?([0-9]{0,2}|100)?(\+2x)?\..+/;
  return CDN_REGEX;
};

/**
 * 完全提取自之前image.toWebp的代码，估计有不少老坑，不敢轻易改动
 * 解析url链接，匹配这样的字符串
 * .(jpg)!(1000)x(1000)q(100)(+2x).jpg;
 * @return {Object} parseResult
 * @example 链接有效：{
 *  hasWatermark,
 *  originFormat,
 *  format,
 *  width,
 *  height,
 *  quality,
 *  imgSuffixAndWatermark
 *  multiple // 倍数 1/2
 * }
 *
 * 链接无效：返回null
 */
export const parseUrl = (url, options = {}) => {
  const canWebp = image1.getCanWebp();
  const { isGifOptimation } = options;
  // 水印参数 ?watermark/xxxx/
  const watermarkRegex = /([^.!]+)\?watermark\/.*\/$/;
  // 倍数
  let multiple = 1;
  const paras = url.match(exports.getCdnRegex());
  // 魔术数字4解释：4是正则匹配是否成功的分界点，老逻辑，细节未知
  if (!paras || paras.length < 4) {
    return null;
  }
  // gif需要加上这样的后缀才能保证不模糊，例如：imageView2/2/w/730/h/0/format/gif/unoptimize/1
  const gifPostfix = isGifOptimation ? 'gif/unoptimize/1' : 'gif';
  const watermarkParams = paras[1].match(watermarkRegex);
  let hasWatermark = false;
  let originFormat;
  // 判断url中是否含有水印参数
  if (watermarkParams && watermarkParams.length) {
    originFormat = watermarkParams[1];
    hasWatermark = true;
  } else {
    originFormat = paras[1];
  }
  // 生成图片格式
  let format = originFormat || '';
  if (originFormat === 'gif') {
    format = gifPostfix;
  } else if (canWebp) {
    format = 'webp';
  } else if (originFormat === 'webp' || originFormat === 'jpeg') {
    // 如果不支持webp，类型是webp或jpeg则强制转换成jpg
    format = 'jpg';
  }
  if (paras[5] === '+2x') {
    multiple = 2;
  }
  return {
    hasWatermark,
    originFormat,
    format: format.toLocaleLowerCase(),
    width: parseInt(paras[2], 10) * multiple,
    height: parseInt(paras[3], 10) * multiple,
    quality: paras[4] || 75,
    // 图片带水印的话，paras[1]中包含了图片后缀以及水印参数
    imgSuffixAndWatermark: paras[1],
    multiple, // 倍数 1/2
  };
};

/**
 * origin, middle, large, small的转化
 * @param {String} rule
 * @return rule
 */
export const ruleAdapter = (url = '', rule = '') => {
  rule = rule.trim();
  const ruleReg = /^origin|middle|large|small$/i;
  const regResult = rule.match(ruleReg);
  if (regResult) {
    const format = getFormatByUrl(url);
    const newRule = regResult[0];
    rule = getExactSuffix(newRule, format);
  }
  return rule;
};

export const pathRuleAdapter = (url = '', rule = '', options) => {
  rule = rule.trim();
  const ruleReg = /^origin|middle|large|small$/i;
  const regResult = rule.match(ruleReg);
  if (regResult) {
    const { compress = true, isGif = false } = options || {};
    if (isGif) {
      rule = `imageView2/2/q/${compress ? 75 : 100}/format/gif/unoptimize/1`;
    } else {
      rule = `imageView2/2/q/${compress ? 75 : 100}/format/webp`;
    }
  }
  return rule;
};

/**
 * 拦截老逻辑里的 .jpg!100x100+2xq100 转成 imageview的格式 逻辑
 * 如果有这样的格式.jpg!100x100+2xq100，不是水印的就转成正式格式：
 *  origin, middle, large;
 *  是水印就走老逻辑，转成一大串watermark/imageview/字符串
 *
 * 走switchQiniuFormat，如果是imageview/的格式，会对webp进行一次转化；否则什么都不做
 *
 * @param {string} url
 * @param {options} options
 * @return url
 */
export function urlAdaptor(url, options) {
  const urlParams = parseUrl(url, options);
  // .jpg!100x100+2xq100
  if (urlParams) {
    if (urlParams.hasWatermark) {
      url = image1.toWebp(url, options, urlParams);
    } else {
      url = getExactUrl(url, urlParams);
    }
  } else {
    url = transQiniuFormatToWebp(url);
  }
  return url;
}
