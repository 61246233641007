import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
.ht-form-phone-select {
  z-index: 10000;
}
.mobile-country-code {
  position: relative;
  background: transparent;
  .zent-select-text {
    color: transparent !important;
  }
  .zent-select-placeholder {
    color: transparent !important;
  }
}
`;

export const HtFormInputPassPhone = styled.div`
  display: flex;
  .zent-select-text {
    border-right: none;
    position: relative;
    border-radius: var(--border_radius_form) 0 0 var(--border_radius_form);
  }
  .zent-select {
    margin-right: 0;
  }
  .zent-input-wrapper {
    /* border-left: none !important; */
  }
  .form-input {
    border-radius: 0 var(--border_radius_form) var(--border_radius_form) 0 !important;
  }
  .ht-form-phone-input {
    flex: 1;
    width: 0;
  }
`;

export const MobileCountryCodeWrapper = styled.div`
  position: relative;
  height: 66px;
`;
export const MobileCountryCodeLabel = styled.div`
  position: absolute;
  top: ${(props) => props.theme.getSpace([12, 14, 16])};
  left: 14px;
  color: var(--color_body_text);
  /* z-index: -1; */
`;
