import React, { forwardRef } from 'react';
import Text from '../text';

type Props = { children: React.ReactNode; size: number };
export type Ref = HTMLButtonElement;

// Text组件的语法糖
const View = forwardRef<Ref, Props>((props, ref) => {
  const { as = 'div', ...restProps } = props;
  return <Text ref={ref} {...restProps} as={as} />;
});

export default View;
