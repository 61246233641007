// 对应商品分组-装修
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import isObject from 'lodash/isObject';
import { Wrapper } from 'components/base';
import { useCollection, getCollectionInitialProps } from 'hook/collection';
import NewLink from 'cpn/new-link';
import { Style } from './style';
import { View } from '../../../../components/base';
import GoodsCollectionWithLayout from '../../components/goods-collection-with-layout';
import GoodsCollectionWithSwiper from '../../components/goods-collection-with-swiper';
import { useThemeContext } from '../../../../hook/global-theme';
import { Background } from '../../components/background';
import { buriedPoint } from 'utils/log-buried-point';

const Collection = (props) => {
  const { settings = {}, initialData, elemClass, data = [] } = props;
  const {
    grid = 3,
    rows = 2,
    title,
    title_size: titleSize = 7,
    box_width: boxWidth,
    mobile_display_type: mobileDisplayType = {},
    background_style: backgroundStyle = 'null',
    background_color: backgroundColor,
    background_image: backgroundImage,
    data_type: dataType = 'collection',
    product_list: productList = [],
  } = settings;
  const { collection } = settings;
  const groupId = isObject(collection) ? collection?.collectionId : collection;
  const { kdtId, userInfo } = initialData;
  const intl = useIntl();
  const { main_val: mobileDisplayTypeMainVal = 'tiled', carousel = {} } = mobileDisplayType;
  const { auto_play_interval: mobileAutoPlayInterval = 3 } = carousel;

  const { goodsList } = useCollection({
    initData: data,
    type: dataType,
    kdtId,
    collection,
    grid,
    rows,
    productList,
    customerId: userInfo?.customerId,
  });
  const themeContext = useThemeContext();
  const { isMobile } = themeContext;

  const Bg = Background(backgroundStyle);
  const [showSwiper, setShowSwiper] = useState(true);

  /** 播放间隔选项 */
  useEffect(() => {
    setShowSwiper(false);
    setTimeout(() => {
      setShowSwiper(true);
    });
  }, [mobileAutoPlayInterval]);

  useEffect(() => {
    if (goodsList.length) {
      buriedPoint('goods_view', {
        goods_component_location: 'HOME_collection',
        goods_component_location_name: '商品分组',
        goodsList: goodsList.map((item) => {
          // eslint-disable-next-line
          const { goodsId: goods_id, goodsType: goods_type = '', title } = item;
          return {
            goods_id,
            goods_type,
            title,
          };
        }),
      });
    }
  }, [goodsList.length]);

  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Style className={cn('feature-collection-section', `cn-${boxWidth}`, elemClass)}>
        <Wrapper paddingTop='vContainer' paddingBottom='vContainer'>
          <div className={cn('feature-collection-box')}>
            {title && (
              <View
                type='header'
                size={titleSize}
                className={cn('feature-collection-title', {
                  'full-screen': boxWidth === 'full_screen',
                })}
                style={{
                  textAlign: settings.text_align || 'left',
                }}
              >
                {title}
              </View>
            )}
            {!(mobileDisplayTypeMainVal === 'carousel' && isMobile) && (
              <GoodsCollectionWithLayout
                className='feature-collection-grid'
                goodsList={goodsList}
                initialData={initialData}
                settings={settings}
              />
            )}
            {isMobile && mobileDisplayTypeMainVal === 'carousel' && showSwiper && (
              <GoodsCollectionWithSwiper goodsList={goodsList} initialData={initialData} settings={settings} />
            )}
            {settings.show_view_all && groupId && dataType === 'collection' ? (
              <div className='view-container'>
                <NewLink className='ui-big-button' href={`/product/list/collection?id=${groupId}`}>
                  {intl.formatMessage({
                    id: 'design.preview_all',
                  })}
                </NewLink>
              </div>
            ) : null}
          </div>
        </Wrapper>
      </Style>
    </Bg>
  );
};

Collection.getInitialProps = getCollectionInitialProps;

export default Collection;
