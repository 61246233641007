/**
 * 16进
 * #5f5fbe  - 95,95,190
 * @param {*} originColor
 */

export const hexToRGB = (originColor) => {
  let color = originColor.toLowerCase();
  const pattern = /^#([0-9|a-f]{3}|[0-9|a-f]{6})$/;
  if (color && pattern.test(color)) {
    if (color.length === 4) {
      // 将三位转换为六位
      color = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
    }
    // 处理六位的颜色值
    const colorNew = [];
    for (let i = 1; i < 7; i += 2) {
      const item = parseInt(`0x${color.slice(i, i + 2)}`);
      colorNew.push(item);
    }
    return colorNew.join(',');
  }
  return color;
};

export const hexToRGBA = (color, opacity) => {
  if (!color) {
    console.warn('您传入了不存在的color', color);
    return '';
  }
  if (color.startsWith('rgba')) {
    color = RGBAToHex(color);
  }
  let sColor = color.toLowerCase();
  // 十六进制颜色值的正则表达式
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    // 添加颜色加深处理逻辑，opacity > 1表示需要加深颜色
    if (opacity > 1) {
      const str = sColor.replace('#', '');
      const hxs = str.match(/../g);
      for (let i = 0; i < 3; i++) hxs[i] = Math.floor(parseInt(hxs[i], 16) * (2 - opacity));
      return `rgba(${hxs.join(',')},1)`;
    }

    if (sColor.length === 4) {
      let sColorNew = '#';
      for (var i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    // 处理六位的颜色值
    const sColorChange = [];
    for (var i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt(`0x${sColor.slice(i, i + 2)}`));
    }
    return `rgba(${sColorChange.join(',')},${opacity})`;
  }
  return sColor;
};

export const RGBAToHex = (color) => {
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');

  const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2]))
    .toString(16)
    .slice(1)}`;
  // console.log(hex); // #f90606
  return hex;
};
