import { CodeInputWrapper } from './style';
import HtFormInput from '../../standard/form-input';
import HtButton from '../../standard/button';
import { Validators, Notify, Pop } from 'zent';
import { useEffect, useMemo, useRef } from 'react';
import { useGetCode, STATUS_MAP } from './hooks';
import { ACCOUNT_TYPE_MAP, SCENE_TYPE } from 'client/const/account';
import { chineseCheckPattern } from 'helper/verify';
import { useManMachineVerify } from 'hook/account/use-man-machine-verify';
import emitter from '../../../event';

function CodeInput(props) {
  const {
    intl,
    mobile = '',
    accountType = ACCOUNT_TYPE_MAP.phone,
    email = '',
    form,
    scene = SCENE_TYPE.register,
    mobileCountryCode,
    verifyCodeId = '',
  } = props;
  const { status, getCode, leftTime } = useGetCode(mobile);

  const propsRef = useRef()
  propsRef.current = props

  useManMachineVerify({ id: verifyCodeId });

  const isDisabled = useMemo(() => {
    if (accountType === ACCOUNT_TYPE_MAP.phone) {
      return !mobile || chineseCheckPattern.test(mobile) || status === STATUS_MAP.sended;
    }
    return !email.includes('@') || status === STATUS_MAP.sended;
  }, [mobile, status, email, accountType]);

  const handleBtnClick = (customI18nRecaptchaToken) => {
    const formData = form.getValue();
    const { mobile } = formData;
    const { mobileCountryCode, scene, accountType, email } = propsRef.current
    getCode({
      mobileCountryCode,
      mobile,
      scene,
      email,
      accountType,
    }, customI18nRecaptchaToken).then(() => {
      // if (accountType === ACCOUNT_TYPE_MAP.email) {
      Notify.success(intl.formatMessage({
        id: 'c48f1233d35a43b79013c539aa99ed40',
        description: '验证码已发送，有效期为10分钟。',
      }))
      // }
    });
  };

  useEffect(() => {
    if (verifyCodeId) {
      emitter.on(verifyCodeId, handleBtnClick)
    }
    return () => {
      if (verifyCodeId) {
        emitter.off(verifyCodeId, handleBtnClick)
      }
    }
  }, [])

  return (
    <CodeInputWrapper>
      <HtFormInput
        name='verifyCode'
        width='100%'
        placeholder={intl.formatMessage({
          id: 'valid_code',
          defaultMessage: '验证码',
        })}
        type='number'
        className={accountType === ACCOUNT_TYPE_MAP.email ? 'code-input-email' : ''}
        helpDesc={
          accountType === ACCOUNT_TYPE_MAP.email && status === STATUS_MAP.sended ? (
            <Pop
              trigger='hover'
              position='bottom-right'
              content={intl.formatMessage({
                id: '82fa62e91d8140ca9b13aee3125b5d3b',
                defaultMessage: '若未收到邮箱验证码，请检查垃圾邮箱或再次点击发送',
              })}
            >
              <div className='email-tips'>
                {intl.formatMessage({
                  id: '4ddb7bc3bf514dd1b299fbf500f8b463',
                  defaultMessage: '未收到邮箱验证码？',
                })}
              </div>
            </Pop>
          ) : null
        }
        showClear={false}
        validators={[
          Validators.required(
            intl.formatMessage({
              id: 'tip.input_valid_code_please',
              defaultMessage: '请输入验证码',
            }),
          ),
        ]}
      // autoComplete='new-password'
      />

      {/* <HtButton verifyCodeId={verifyCodeId} className='get-code' plain disabled={isDisabled} onClick={handleBtnClick}> */}
      <HtButton verifyCodeId={verifyCodeId} className='get-code' plain disabled={isDisabled}>
        {status === STATUS_MAP.unsend
          ? intl.formatMessage({
            id: 'get_verification_code',
            defaultMessage: '获取验证码',
          })
          : intl.formatMessage(
            {
              id: '87dd12c3087c409d96389a6f681ba6eb',
              defaultMessage: '重新获取({leftTime}s)',
            },
            {
              leftTime,
            },
          )}
      </HtButton>
    </CodeInputWrapper>
  );
}

export default CodeInput;
