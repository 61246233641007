import styled from 'styled-components';

export const Style = styled.div`
  .cart-activity-card {
    background-color: var(--color_card);
    padding: 24px;
    .hunt-prompt {
      padding: 0 0 24px !important;
      .discount-title {
        .discount-tag {
          line-height: 1;
        }
      }
    }
    .cart-item-section + .cart-item-section {
      margin-top: 24px;
    }
  }
  .cart-activity-card + .cart-activity-card {
    /* margin-top: 24px; */
    margin-top: 16px;
  }
  .empty-cart-box {
    text-align: center;
    padding: 40px;
    background-color: var(--color_card);
    .empty-cart-icon {
      fill: rgba(var(--color_body_text_rgb), 0.4);
    }
    p {
      margin-top: 24px;
      font-size: 12px;
      color: rgba(var(--color_body_text_rgb), 0.4);
    }
  }
`;
