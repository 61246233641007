import React, { useMemo, useState, useEffect, forwardRef, useContext } from 'react';
import useFillImage from 'hook/use-fill-image';
import { useDidUpdate } from 'hook/life-cycle';
import RootContext from '../../../pages/context/root';

export const DEFAULT_IMAGE_SRC = 'https://img01.yzcdn.cn/upload_files/2021/08/16/FmjmzgML0mx3cCH8SvTQ2xvcopVV.png';
const LAZY_LOAD_CLASS_NAME = 'lazyload';

export interface IImageProps {
  className: string;
  src: string;
  size: string;
  defaultImg: string;
  isLazyLoad: boolean;
  alt: string;
  [key: string]: any;
  compress?: boolean;
}

const Image = forwardRef((props: IImageProps, ref) => {
  const { initialData = {} } = useContext(RootContext);
  const { seoInfo = {} } = initialData;
  const {
    className = '',
    src = '',
    compress = true,
    size = 'origin',
    defaultImg = DEFAULT_IMAGE_SRC,
    isLazyLoad = true,
    alt = seoInfo.title || 'goods image',
    ...reset
  } = props;

  const { image, isIE } = useFillImage(src, size, {
    toWebp: true,
    compress,
  });

  // IE 不使用懒加载
  const newIsLazyLoad = isIE ? false : isLazyLoad;

  const newClassName = useMemo(() => {
    // 先把可能存在的lazyload  替换掉
    const names = className.replace(LAZY_LOAD_CLASS_NAME, '').split(' ') || [];
    if (isLazyLoad) {
      names.push(LAZY_LOAD_CLASS_NAME);
    }
    return names.join(' ');
  }, [className, isLazyLoad]);

  const [imageSrc, setImageSrc] = useState(newIsLazyLoad ? defaultImg : image);

  // 首次不执行setImageSrc 操作
  useDidUpdate(() => {
    if (image) setImageSrc(image);
  }, [image]);

  return <img ref={ref} data-ie={isIE} className={newClassName} data-src={image} alt={alt} src={imageSrc} {...reset} />;
});

export default Image;
