import styled from 'styled-components';

export const Style = styled.div`
  grid-area: carousel;
  /* max-width: 500px; */
  /* width: ${(props) => props.theme.getSpace([140, 200, 260])}; */
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  .swiper-container {
    height: 100%;
    position: relative;
  }
  .swiper-text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    box-sizing: border-box;
    padding: ${(props) => props.theme.getSpace(props.theme.pCards)};
  }
  .swiper-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
