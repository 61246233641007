import Big from 'big.js';
import { isNil, isNaN } from 'lodash';
import currenyMap from './config/curreny.json';

export const getCurrenyInfo = (currency) => {
  const currencyInfo = currenyMap[currency];
  return currencyInfo;
};

/**
 * @param fen 分 1元=1000厘
 * @param units 进率（厘）
 * showZero 是否展示小数点后面的0
 */
export const formateLiToYuan = (params = {}) => {
  const { value = 0, units = 1000, currency, showZero = false, multiCurrencyInfo } = params;
  if (isNaN(Number(value))) {
    console.warn('传入价格不是数字');
    return;
  }
  let calcCurrency = currency;
  let divValue = units;
  if (multiCurrencyInfo?.currency) {
    // 多币种,需要更换货币符号和进行汇率转换
    calcCurrency = multiCurrencyInfo.currency;
    divValue = units * multiCurrencyInfo.exchangeRate;
  }
  const currenyDecimalDigits = getCurrenyInfo(calcCurrency)?.decimalDigits;
  const decimalDigits = typeof currenyDecimalDigits === 'undefined' ? 2 : currenyDecimalDigits;
  const price = new Big(Number(value)).div(divValue).toFixed(decimalDigits);
  return showZero ? price : Number(price);
};

/**
 * @param yuan 元 1元=1000厘
 * @param units 进率（厘）
 */
export const formateYuanToLi = (yuan = 0, units = 1000) => {
  if (isNaN(Number(yuan))) {
    console.warn('传入价格不是数字');
    return;
  }

  return Number(new Big(Number(yuan)).times(units).toFixed(2));
};

/*
 * 带单位的
 * @param param1
 */
export const intlLiToYuan = ({ currency = '', value, showZero = false, multiCurrencyInfo = {} }) => {
  let calcCurrency = currency;
  if (multiCurrencyInfo?.currency) {
    calcCurrency = multiCurrencyInfo.currency;
  }
  const symbol = getCurrenyInfo(calcCurrency)?.symbol || '';
  const yuan = formateLiToYuan({ value, currency, showZero, multiCurrencyInfo });
  return symbol + yuan;
};

export const getSkuPrice = ({ sku, goodsDetail, intl, currency, priceName, multiCurrencyInfo }) => {
  const { maxPrice = 0, minPrice = 0 } = goodsDetail || {};
  const skuPriceVal = intlLiToYuan({
    currency,
    value: sku?.price || 0,
    multiCurrencyInfo,
  });
  const maxPriceVal = intlLiToYuan({
    currency,
    value: maxPrice || 0,
    multiCurrencyInfo,
  });

  const minPriceVal = intlLiToYuan({
    currency,
    value: minPrice || 0,
    multiCurrencyInfo,
  });

  const priceOfName = intlLiToYuan({
    currency,
    value: sku[priceName] || 0,
    multiCurrencyInfo,
  });

  if (priceName) {
    return priceOfName;
  }

  if (sku?.variantId) {
    return skuPriceVal;
  } else if (minPrice === maxPrice) {
    return minPriceVal;
  }

  return `${minPriceVal}-${maxPriceVal}`;
};

// 货币最小单位值
export const formatAsSmallestUnit = (currency, amount) => {
  let decimalDigits = 2;
  const currencyInfo = getCurrenyInfo(currency);
  if (currencyInfo && currencyInfo.decimalDigits) {
    decimalDigits = currencyInfo.decimalDigits;
  }
  if (decimalDigits == 0) {
    return amount / 1000;
  } else if (decimalDigits == 1) {
    return amount / 100;
  } else {
    return decimalDigits == 2 ? amount / 10 : amount;
  }
};

/** 四舍六入五成双，传入金额是*1000的，返回数字也是*1000 */
export const formatAmountWithFixed = ({ currency = 'USD', amount = 0 }) => {
  const num = amount / 1000;
  const decimalDigits = getCurrenyInfo(currency)?.decimalDigits;
  const amountStr = num.toFixed(decimalDigits);
  return Number(amountStr) * 1000;
};

/**
 * @description: 根据小数点动态替换 输入值
 * @param {string} value
 * @param {string|number} decimal
 * @return {string|number}
 */
 export const formatDecimalNumber = (value, decimal) => {
  if (isNil(value) || value === '') return '';
  try {
    const newVal = value.toString();
    if (decimal == 0) {
      return newVal.replace(/[^\d]/g, '');
    }
    const regex = new RegExp(`^\\d+(\\.\\d{0,${decimal}})?`);
    const match = newVal.match(regex);
    if (match) {
      return match[0] || '';
    }
  } catch (err) {
    console.error(err);
  }
}
