// 多图列表
import React from 'react';
import { GridLayout, GridItem } from 'cpn/layout-ui/grid';
import cn from 'classnames';
import { Style } from './style';
import { Wrapper } from 'components/base';
import useFillImage from 'hook/use-fill-image';
import fullfillImage from 'utils/fullfillImage';
import ImageContainer from '../../components/image-container';
import ContainerSize from '../../components/container-size';
import { Background } from '../../components/background';

const LogoBar = (props) => {
  const { block_order: blockOrder, blocks, settings = {}, elemClass } = props;

  const {
    title,
    image_ratio: imageRadio,
    box_width: boxWidth,
    grid = 4,
    background_style: backgroundStyle = 'custom_color',
    background_color: backgroundColor = 'rgba(250, 250, 250, 0)',
    background_image: backgroundImage,
  } = settings;
  const Bg = Background(backgroundStyle);
  const onImgClick = (url) => {
    if (window.isEdit) return;
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <Bg
      backgroundColor={backgroundColor}
      backgroundImage={fullfillImage(backgroundImage, 'origin')}
      style={{ overflow: 'hidden' }}
    >
      <Style className={cn('logo-bar-section', elemClass)}>
        <div className='content'>
          <ContainerSize boxWidth={boxWidth} needSafeSpace>
            <Wrapper marginTop='vContainer' marginBottom='vContainer'>
              <div className='logo-bar'>
                {title && <div className={cn('section-component-title')}>{title}</div>}
                <GridLayout className='logo-block-list'>
                  {Array.isArray(blockOrder) &&
                    blockOrder.map((blockId) => {
                      if (!blocks[blockId]) return null;
                      const block = blocks[blockId]?.settings || {};
                      const { link } = block;
                      const image = fullfillImage(block?.image, 'origin');
                      const linkUrl = JSON.parse(link || null)?.url;

                      return (
                        <GridItem
                          key={blockId}
                          pGrid={grid}
                          pGap={24}
                          pTop={0}
                          pBottom={24}
                          mGap={8}
                          mTop={0}
                          mBottom={8}
                          className={cn('logo-block-item goods-item', {
                            'click-able': linkUrl,
                          })}
                        >
                          <ImageContainer
                            heightType={imageRadio}
                            heightRules='type3'
                            fit='cover'
                            position='center center'
                            url={image}
                            alt={title}
                            onClick={() => onImgClick(linkUrl)}
                          />
                        </GridItem>
                      );
                    })}
                </GridLayout>
              </div>
            </Wrapper>
          </ContainerSize>
        </div>
      </Style>
    </Bg>
  );
};

export default LogoBar;
