import React, { useEffect, useMemo, useState } from 'react';
import { Style } from './style';
import NewLink from 'cpn/new-link';
import { getUrl } from 'utils/formate';
import { FooterMenuCollapse } from '../footer-menu-collapse';
import cn from 'classnames';

const getAnnouncementLinkUrl = (announcementLink) => {
  if (announcementLink) {
    try {
      const linkData = JSON.parse(announcementLink);
      const linkDataUrl = getUrl(linkData?.url);
      return linkDataUrl;
    } catch (e) {}
  }
  return '';
};

/**
 * footer 自定义菜单
 * */
const FooterCustomMenuItem = ({ settings, style }) => {
  const { title, open_target: openTarget, announcement_link: announcementLink = '' } = settings;
  const [url, setUrl] = useState();

  if (!title) {
    return null;
  }

  return (
    <div className='footer-custom-menu-section-item'>
      <NewLink
        className='footer-custom-menu-section-item-link'
        href={getAnnouncementLinkUrl(announcementLink) || '#'}
        target={openTarget === 'self' ? '_self' : '_blank'}
        style={style}
      >
        {title}
      </NewLink>
    </div>
  );
};

const FooterCustomMenu = (props) => {
  const {
    settings,
    style,
    block_order,
    blocks,
    mobileDisplayTwoInOneLine,
    mobileDeviceFooterTextAlign,
    mobileDeviceMenuStyle /** fold open */,
  } = props;
  const { announcement_link: announcementLink = '', open_target: openTarget = '', title } = settings || {};

  return (
    <Style
      mobileDeviceFooterTextAlign={mobileDeviceFooterTextAlign}
      mobileDeviceMenuStyle={mobileDeviceMenuStyle}
      mobileDisplayTwoInOneLine={mobileDisplayTwoInOneLine}
    >
      <div className={cn('footer-custom-menu-section', `mobile-device-menu-style-${mobileDeviceMenuStyle}`)}>
        <FooterMenuCollapse
          mobileDisplayTwoInOneLine={mobileDisplayTwoInOneLine}
          mobileDeviceMenuStyle={mobileDeviceMenuStyle}
          mobileDeviceFooterTextAlign={mobileDeviceFooterTextAlign}
          style={style}
          isMobileFoldIndent
          title={
            <NewLink
              className='footer-custom-menu-section-title-link'
              href={getAnnouncementLinkUrl(announcementLink)}
              target={openTarget === 'self' ? '_self' : '_blank'}
              style={style}
            >
              {title}
            </NewLink>
          }
        >
          {block_order.map((order) => {
            return <FooterCustomMenuItem key={order} settings={blocks?.[order]?.settings} style={style} />;
          })}
        </FooterMenuCollapse>
      </div>
    </Style>
  );
};

export default FooterCustomMenu;
