const mobileSideHeight = {
  small: '190px',
  medium: '227.5px',
  large: '252px',
};
const pcSideHeight = {
  small: '475px',
  medium: '650px',
  large: '720px',
};
const TextSize = {
  medium: 'text-size-medium',
  large: 'text-size-large',
};
export { mobileSideHeight, pcSideHeight, TextSize };
