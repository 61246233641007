import React from 'react';

export interface IProductImage {
  type: string;
  coverUrl: string;
  mediaId: number;
  url: string;
}
export interface IContextGoodDetail {
  /** 数据部分 */
  imageRatio: string;
  layoutStyle: string; // 商详布局方式
  activeIndex?: number;
  setActiveIndex?: Function;
  productMedia: {
    mainMediaIndex: number;
    mediaList: IProductImage[];
  };

  /** 方法部分 */
  updateMainMediaIndex: Function;
  updateProductMediaList: Function;
}

// 商品详细页的装修配置,一些配置需要一层一层传递，就可以写这里，如：图片比列
export const ContextGoodDetail = React.createContext<IContextGoodDetail>({
  imageRatio: 'adapt',
  layoutStyle: '',
  activeIndex: 0, // 样式3适用，当前active的商品图
  setActiveIndex: () => {},
  productMedia: {
    mainMediaIndex: 0,
    mediaList: [],
  },
  updateMainMediaIndex: () => {},
  updateProductMediaList: () => {},
});
