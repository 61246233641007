import { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { getFontSize, getLineHeight, getSpace, BREAK_POINTS } from '../style/fn';
import throttle from 'lodash/throttle';
import isArray from 'lodash/isArray';
import { css, ThemeContext } from 'styled-components';
import { configurationColorMap, gapMap, customerGap, specialColorMap, fontSizeMap } from '../const/standard';
import { hexToRGBA } from '../util/color';

// 将 checkout_body_background_color 转换成 checkoutBodyBackgroundColor
function transformVariableName(name) {
  return name.toString().replace(/[-_](\w)/g, function (...args) {
    return args[1].toUpperCase();
  });
}
const isMobileReg = /Android|webOS|iPhone|iPod|BlackBerry/i;

type GetSize = (size: number | Array<number>) => string;
export interface GlobalTheme {
  isMobile: boolean;
  clientWidth: number;
  getFontSize: GetSize;
  getBaseFontSize: GetSize;
  getHeaderFontSize: GetSize;
  getBaseSize: GetSize;
  getHeaderSize: GetSize;
  getBaseLineHeight: (size: string) => string;
  getHeaderLineHeight: (size: string) => string;
  getSpace: GetSize;
}

export const useGlobalTheme = ({ clientWidth, themeData = {}, userAgent = '' }) => {
  const current = themeData.current || {};
  Object.keys(configurationColorMap).forEach((key) => {
    // 历史的主题里面没有对应的key的情况，不兼容页面会白屏
    if (configurationColorMap[key] && !current[key]) {
      current[key] = '#fff';
    }
  });
  const themeVariables = useMemo(() => {
    const varibles = { ...specialColorMap };
    Object.keys(current).forEach((key) => {
      if (configurationColorMap[key]) {
        const varibleKey = transformVariableName(key);
        varibles[varibleKey] = current[key];
        if (isArray(configurationColorMap[key])) {
          // 一个颜色可能会有多种透明度
          const colors = {};
          configurationColorMap[key].forEach((opacity) => {
            colors[opacity] = hexToRGBA(current[key], opacity / 100);
          });
          varibles[`${varibleKey}s`] = colors;
        }
      }
    });

    return varibles;
  }, [current]);
  const isMobile = clientWidth <= BREAK_POINTS[0] || isMobileReg.test(userAgent);
  const isPad = clientWidth <= BREAK_POINTS[1];
  const isPc = clientWidth > BREAK_POINTS[1];
  const {
    type_header_base_size: typeHeaderBaseSize,
    type_base_size: typeBaseSize = 100,
    type_sale_price_size: typeSalePriceSize = 100,
    type_sale_origin_price_size: typeSaleOriginPriceSize = 100,
    container_width: containerWidth = 1280,
  } = current;
  const getRadius = useCallback((percent) => {
    const _percent = parseInt(percent, 10);
    if (_percent >= 50) {
      return '50%';
    }
    if (_percent <= 8) {
      return '8%';
    }
    // eslint-disable-next-line no-nested-ternary
    return isPc ? `${percent}%` : isPad ? `${percent - 1}%` : `${percent - 2}%`;
  }, []);

  return {
    ...themeVariables,
    ...gapMap,
    ...customerGap,
    ...fontSizeMap,
    hexToRGBA,
    clientWidth,
    containerWidth,
    isMobile,
    isPad,
    isPc,
    getRadius,
    getFontSize: useCallback(
      (size) => {
        return `${getFontSize(clientWidth, size)}px`;
      },
      [clientWidth],
    ),
    getBaseFontSize: useCallback(
      (size) => {
        return `${(getFontSize(clientWidth, size) * typeBaseSize) / 100}px`;
      },
      [clientWidth, typeBaseSize],
    ),
    getHeaderFontSize: useCallback(
      (size) => {
        return `${(getFontSize(clientWidth, size) * typeHeaderBaseSize) / 100}px`;
      },
      [clientWidth, typeHeaderBaseSize],
    ),
    getBaseLineHeight: useCallback(
      (size) => {
        const fontSize = (getFontSize(clientWidth, size) * typeBaseSize) / 100;
        return `${getLineHeight(fontSize)}px`;
      },
      [clientWidth, typeBaseSize],
    ),
    getHeaderLineHeight: useCallback(
      (size) => {
        const fontSize = (getFontSize(clientWidth, size) * typeHeaderBaseSize) / 100;
        return `${getLineHeight(fontSize)}px`;
      },
      [clientWidth, typeHeaderBaseSize],
    ),
    getSpace: useCallback(
      (size) => {
        return getSpace(clientWidth, size);
      },
      [clientWidth],
    ),
    getBaseSize: useCallback(
      (size) => {
        const fontSize = (getFontSize(clientWidth, size) * typeBaseSize) / 100;
        const lineHeight = getLineHeight(fontSize);
        return css`
          font-size: ${fontSize}px;
          line-height: ${lineHeight}px;
        `;
      },
      [clientWidth, typeBaseSize],
    ),
    getBaseFontAll: useCallback(
      (size) => {
        const fontSize = (getFontSize(clientWidth, size) * typeBaseSize) / 100;
        const lineHeight = getLineHeight(fontSize);
        return css`
          font-size: ${fontSize}px;
          line-height: ${lineHeight}px;
          font-family: var(--font-stack-body);
          font-style: var(--font-style-body);
          font-weight: var(--font-weight-body);
        `;
      },
      [clientWidth, typeBaseSize],
    ),
    getBaseFontInfo: () => {
      return css`
        font-family: var(--font-stack-body);
        font-style: var(--font-style-body);
        font-weight: var(--font-weight-body);
      `;
    },
    getHeaderSize: useCallback(
      (size) => {
        const fontSize = (getFontSize(clientWidth, size) * typeHeaderBaseSize) / 100;
        const lineHeight = getLineHeight(fontSize);
        return css`
          font-size: ${fontSize}px;
          line-height: ${lineHeight}px;
        `;
      },
      [clientWidth, typeHeaderBaseSize],
    ),
    getHeaderSizeAll: useCallback(
      (size) => {
        const fontSize = (getFontSize(clientWidth, size) * typeHeaderBaseSize) / 100;
        const lineHeight = getLineHeight(fontSize);
        return css`
          font-size: ${fontSize}px;
          line-height: ${lineHeight}px;
          font-family: var(--font-stack-header);
          font-style: var(--font-style-header);
          font-weight: var(--font-weight-header);
        `;
      },
      [clientWidth, typeHeaderBaseSize],
    ),
    getHeaderFontInfo: () => {
      return css`
        font-family: var(--font-stack-header);
        font-style: var(--font-style-header);
        font-weight: var(--font-weight-header);
      `;
    },
    getSalePriceFontSize: useCallback(
      (size) => {
        const fontSize = (getFontSize(clientWidth, size) * typeSalePriceSize) / 100;
        const lineHeight = getLineHeight(fontSize);
        return css`
          font-size: ${fontSize}px;
          line-height: ${lineHeight}px;
          font-family: var(--font-stack-sale-price);
          font-style: var(--font-style-sale-price);
          font-weight: var(--font-weight-sale-price);
        `;
      },
      [clientWidth, typeSalePriceSize],
    ),
    getSaleOriginPriceFontSize: useCallback(
      (size) => {
        const fontSize = (getFontSize(clientWidth, size) * typeSaleOriginPriceSize) / 100;
        const lineHeight = getLineHeight(fontSize);
        return css`
          font-size: ${fontSize}px;
          line-height: ${lineHeight}px;
          font-family: var(--font-stack-sale-origin-price);
          font-style: var(--font-style-sale-origin-price);
          font-weight: var(--font-weight-sale-origin-price);
        `;
      },
      [clientWidth, typeSaleOriginPriceSize],
    ),
  };
};

export const useClientWidth = () => {
  const [clientWidth, setClientWidth] = useState(1024);
  const [clientHeight, setClientHeight] = useState(0);
  useEffect(() => {
    setClientWidth(document.documentElement.clientWidth);
    setClientHeight(document.documentElement.clientHeight);
    // eslint-disable-next-line no-undef
    if (!__isServer) {
      window.addEventListener(
        'resize',
        throttle(() => {
          setClientWidth(document.documentElement.clientWidth);
          setClientHeight(document.documentElement.clientHeight);
        }, 500),
      );
    }
  }, []);
  return {
    clientWidth,
    clientHeight,
  };
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
