import React from 'react';
import { Form, FormStrategy, Validators, Notify } from 'zent';
import HtFormInputPassword from 'client/components/standard/form-input-password';
import HtButton from 'client/components/standard/button';
import UserAccount from 'client/controllers/user-account';
import { useIntl } from 'react-intl';
import { isSamePassword, isValidPassword } from 'helper/verify';

function ResetPassword(props) {
  const intl = useIntl();
  const { step1Info = {}, setLoginDialogInfo, onClose } = props;
  const form = Form.useForm(FormStrategy.View);
  const onSubmit = () => {
    console.log('onSubmit', form.getValue());
    const { password } = form.getValue();
    const { mobileCountryCode, mobile, verifyCode } = step1Info;
    UserAccount.resetPwdByMobile({
      mobileCountryCode,
      mobile,
      verifyCode,
      password,
    }).then(() => {
      Notify.success(
        intl.formatMessage({
          id: 'account_update_success',
          defaultMessage: '修改成功',
        }),
      );
      onClose();
      setLoginDialogInfo({
        visible: true,
      });
    });
  };
  return (
    <Form layout='vertical' form={form} onSubmit={onSubmit}>
      <HtFormInputPassword
        name='password'
        width='100%'
        placeholder={intl.formatMessage({
          id: 'new_password',
          defaultMessage: '新密码',
        })}
        validators={[
          Validators.required(
            intl.formatMessage({
              id: 'Please_enter_a_new_password',
              defaultMessage: '请输入新密码',
            }),
          ),
          isValidPassword(
            intl.formatMessage({
              id: 'bf1ed1a42da342f887137ff2bccf5308',
              defaultMessage: '密码仅支持输入数字、字母、特殊符号。最长32个字符，最短10个字符。',
            }),
          ),
        ]}
      />
      <HtFormInputPassword
        name='confirmPassword'
        width='100%'
        placeholder={intl.formatMessage({
          id: 'Confirm_new_password',
          defaultMessage: '确认新密码',
        })}
        validators={[
          Validators.required(
            intl.formatMessage({
              id: '3069d1354695456685a0bf7e5b467171',
              defaultMessage: '请输入确认密码',
            }),
          ),
          isSamePassword(
            form,
            intl.formatMessage({
              id: 'a646e4e4bf864096a07801750bff2d30',
              defaultMessage: '两次密码输入不一致',
            }),
          ),
        ]}
      />
      <HtButton full type='primary' size='large' htmlType='submit'>
        {intl.formatMessage({
          id: 'common_confirm',
          defaultMessage: '确定',
        })}
      </HtButton>
    </Form>
  );
}

export default ResetPassword;
