import React from 'react';
import { BUTTON_TYPE, BUTTON_SIZE } from './const';
import cn from 'classnames';
import { Button } from './style';

/**
 * 类型：primary|secondary|plain, default: 类型：primary
 * 尺寸：large|medium|small|full, default: medium
 * loading: true|false, default: false
 * disabled: true|false, default: false
 */

function HtButton(props) {
  const {
    children,
    size = BUTTON_SIZE.medium,
    type = BUTTON_TYPE.primary,
    htmlType = 'button',
    disabled = false,
    full = false,
    plain = false,
    className,
    verifyCodeId = '',
    hidden = false,
    ...rest
  } = props;
  const classes = cn(className, {
    [`ht-button-size-${size}`]: true,
    [`ht-button-type-${type}`]: true,
    [`ht-button-full`]: full,
    [`ht-button-plain`]: plain,
    [`ht-button-disabled`]: disabled,
    [`ht-button-hidden`]: hidden,
  });
  return (
    <Button id={verifyCodeId} type={htmlType} className={classes} {...rest}>
      {children}
    </Button>
  );
}

export default HtButton;
