import ProductTemplate from './components/product-template';
import RecommendProduct from './components/recommend-product';
import { baseSectionsMap } from '../../config/design-section';

enum CpnKey {
  ProductTemplate = 'product-template',
  RecommendProduct = 'product-recommendation',
}

const sectionsMap = {
  [CpnKey.ProductTemplate]: {
    component: ProductTemplate,
  },
  [CpnKey.RecommendProduct]: {
    component: RecommendProduct,
  },
};

export default {
  pageSectionsMap: {
    ...baseSectionsMap, // 基础组件
    ...sectionsMap, // 业务组件
  },
  sectionsIndex: ['header', CpnKey.ProductTemplate, CpnKey.RecommendProduct, 'footer'],
  defaultActiveKey: CpnKey.ProductTemplate,
};
