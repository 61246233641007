import styled from 'styled-components';

export const Style = styled.div`
  text-align: left;
  grid-area: custom-menu;
  width: 100%;
  padding-top: 16px;
  .menu-trigger {
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 16px;
    ${(props) => props.theme.getBaseSize([16])};
    font-weight: bold;
    background-color: ${(props) => props.cardColor};
    color: ${(props) => props.textColor};
    border-radius: 8px;
    position: relative;
  }
  .menu-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
`;
