import React from 'react';
import { HeaderOperte } from '../components/header-operte';
import HeaderLogo from '../components/header-logo';
import { MobileSubMenu } from '../components/mobile-submenu';
import { HeaderAnnouncement } from '../components/header-announcement';
import HeaderWrapper from './style';
import { useIntl } from 'react-intl';
import cn from 'classnames';

export const MobileHeader = (props) => {
  const {
    settings,
    initialData,
    elemClass,
    jumpToOtherPage,
    jumpToMenuPage,
    menuDetail,
    totalCountWish,
    checkoutHeader,
    headerGlobalStyle,
    onOpenCart,
  } = props;
  const {
    show_announcement: showAnnouncement,
    announcement_text: announcementText,
    announcement_link: announcementLink,
    color_bg: colorBg,
    color_text: colorText,
    logo,
    align_logo: alignLogo,
    menu_height: menuHeight = 72,
    default_bg_color: defaultBgColor,
    default_text_icon_color: defaultTextIconColor,
    level_one_menu_text_size: levelOneMenuTextSize,
    level_two_menu_text_size: levelTwoMenuTextSize,
    level_three_menu_text_size: levelThreeMenuTextSize,
    sub_menu_bg_color: subMenuBgColor,
    sub_menu_text_color: subMenuTextColor,
    show_search_icon: showSearchIcon,
    show_personal_center_icon: showPersonalCenterIcon = true,
    show_wishlist_icon: showWishlistIcon = true,
    show_shop_cart_icon: showShopCartIcon = true,
  } = settings;
  const { shopSetting = {} } = initialData;
  const intl = useIntl();
  return (
    <HeaderWrapper
      menuHeight={menuHeight}
      defaultBgColor={defaultBgColor}
      defaultTextIconColor={defaultTextIconColor}
      subMenuBgColor={subMenuBgColor}
      subMenuTextColor={subMenuTextColor}
      gColor={headerGlobalStyle.color}
    >
      <div className={elemClass}>
        <HeaderAnnouncement
          showAnnouncement={showAnnouncement}
          colorBg={colorBg}
          colorText={colorText}
          announcementText={announcementText}
          announcementLink={announcementLink}
        />
        <div className='sub-header-container'>
          <div className={cn('wrapper', { 'header-layout': alignLogo === 'center' })}>
            <MobileSubMenu
              jumpToMenuPage={jumpToMenuPage}
              jumpToOtherPage={jumpToOtherPage}
              menuDetail={menuDetail}
              menuHeight={menuHeight}
              totalCountWish={totalCountWish}
              settings={settings}
              initialData={initialData}
              levelOneMenuTextSize={levelOneMenuTextSize}
              levelTwoMenuTextSize={levelTwoMenuTextSize}
              levelThreeMenuTextSize={levelThreeMenuTextSize}
              defaultBgColor={defaultBgColor}
              defaultTextIconColor={defaultTextIconColor}
            />
            <HeaderLogo
              className={cn('mobile-header-logo', {
                'absolute-center': alignLogo === 'center' && !checkoutHeader,
              })}
              jumpToOtherPage={jumpToOtherPage}
              logo={logo}
              menuHeight={60}
              shopName={shopSetting.name}
              logoColor={headerGlobalStyle.color}
            />
            {checkoutHeader ? (
              <div className='pay-safe'>
                <span>
                  {intl.formatMessage({
                    defaultMessage: '安全支付',
                    id: '82255fdf2a344a43b51150dff6b6593a',
                  })}
                </span>
                <svg width={20} height={20} fill='#48AB7C'>
                  <use xlinkHref='#iconanquanzhifu' />
                </svg>
              </div>
            ) : (
              <HeaderOperte
                jumpToOtherPage={jumpToOtherPage}
                initialData={initialData}
                alignLogo={alignLogo}
                isMobile
                showSearchIcon={showSearchIcon}
                showPersonalCenterIcon={showPersonalCenterIcon}
                showWishlistIcon={showWishlistIcon}
                showShopCartIcon={showShopCartIcon}
                totalCountWish={totalCountWish}
                defaultTextIconColor={defaultTextIconColor}
                onOpenCart={onOpenCart}
              />
            )}
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};
