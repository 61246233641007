import React from 'react';
import { Arrow, Indicator } from './style';
import { Text } from '../../../../components/base';
import cn from 'classnames';

function getTotalArrayByNumber(number) {
  return '1'.repeat(number).split('');
}

function formatNumber(number) {
  return number >= 10 ? number : '0' + number;
}

// 根据total， currentIndex 渲染 indicatorItem
function renderIndicatorItems({ total, currentIndex }) {
  const arr = getTotalArrayByNumber(total);
  return arr.map((item, index) => (
    <div key={index} className={cn('indicator-item', { active: currentIndex === index })}></div>
  ));
}

// dots indicator
function renderDotsIndicator({ total, currentIndex = 0, isPc, position = 'center', color }) {
  const arr = getTotalArrayByNumber(total);
  return (
    <Indicator className={cn('dots', 'indicator-' + position)} color={color}>
      {renderIndicatorItems({ total, currentIndex })}
    </Indicator>
  );
}

// line indicator
function renderLineIndicator({ total, currentIndex = 0, position = 'left', color }) {
  const arr = getTotalArrayByNumber(total);
  return (
    <Indicator className={cn('line', 'indicator-' + position)} total={total} currentIndex={currentIndex} color={color}>
      <div className='line-process-bar'></div>
    </Indicator>
  );
}

// digital Indicator
function renderDigitalIndicator({ total, currentIndex = 0, position = 'center', color }) {
  return (
    <Indicator className={cn('digital', 'indicator-' + position)} color={color}>
      <Text size={11} className='indicator-text active'>
        {currentIndex + 1}/{total}
      </Text>
    </Indicator>
  );
}

function renderIndicator(props) {
  const { type = 'dots' } = props;
  if (type === 'dots' || type === 'style_one') {
    return renderDotsIndicator(props);
  }
  if (type === 'line' || type === 'style_two') {
    return renderLineIndicator(props);
  }
  if (type === 'digital' || type === 'style_three') {
    return renderDigitalIndicator(props);
  }
  return null;
}

/**
 * 参数
 * type:, dots, line, digital,  默认：dots 后台可配置
 * total： 轮播图的总数量，用于生成指示器数量
 * currentIndex： 当前展示的轮播图的index
 *
 * 注意：使用前必选保证该组件的父元素设置了相对定位或者position不为static
 */
function SwiperIndicator(props) {
  return renderIndicator(props);
}

export default SwiperIndicator;

export const INDICATORE_MAP = {
  style_one: 'dots',
  style_two: 'digital',
  style_three: 'line',
};
