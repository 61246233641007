import styled from 'styled-components';

export const Style = styled.div`
  @keyframes init {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: init 0.3s;
  z-index: 9500;
  position: fixed;
  right: 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
  width: 230px;
  height: 120px;
  display: flex;
  background-color: var(--color_card, #fff);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .tips-icon {
    width: 34px;
    height: 34px;
  }
  .text-description {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
    word-break: break-word;
    color: var(--color_body_text);
  }
  .triangle {
    width: 0;
    height: 0;
    border-bottom: 10px solid var(--color_card, #fff);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    top: -10px;
    right: 25px;
    z-index: 10;
  }
  .triangle:after {
    content: '';
    position: absolute;
    top: 2px;
    left: -7px;
    border-bottom: 8px solid var(--color_card, #fff);
    border-right: 4px solid transparent;
    border-left: 8px solid transparent;
  }
`;
