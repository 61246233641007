// 图文重叠，图文叠加
import React from 'react';
import cn from 'classnames';
import { Style } from './style';
import { DefaultImg } from 'cpn/default-img';
import { View, NewButton } from '../../../../components/base';
import { useThemeContext } from '../../../../hook/global-theme';
import Image from 'components/base/image';
import ContainerSize from '../../components/container-size';
import { Background } from '../../components/background';

const ImageBar = (props) => {
  const { settings = {}, block_order: blockOrder, blocks = {}, elemClass } = props;
  const {
    section_height: sectionHeight = 'small',
    pic_spacing: picSpacing = 0,
    btn_tag_color: btnTagColor,
    mask_color: maskColor,
    show_mask: showMask = false,
    text_alignment: textAlignment = 'center center',
    text_color: textColor,
    text_floating: textFloating = true,
    text_size: textSize,
    box_width: boxWidth,
    background_style: backgroundStyle = 'custom_color',
    background_color: backgroundColor = 'rgba(250, 250, 250, 0)',
    background_image: backgroundImage,
  } = settings;
  const Bg = Background(backgroundStyle);

  const themeContext = useThemeContext();

  const onImgClick = (url) => {
    if (window.isEdit) return;
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage} style={{ overflow: 'hidden' }}>
      <Style className={cn('image-bar-section', elemClass)} gap={`g${picSpacing}`} textAlignment={textAlignment}>
        <ContainerSize boxWidth={boxWidth} needSafeSpace>
          <div className='image-bar-contaier'>
            {blockOrder.map((key) => {
              const item = blocks[key]?.settings;
              const { alignment, cta_label, text, image } = item;
              const linkUrl = item.link ? JSON.parse(item.link || null)?.url : '';

              const itemClass = `bar-item-img image-position-${alignment} bar-item-img-${sectionHeight}`;
              return (
                <div
                  onClick={() => onImgClick(linkUrl)}
                  key={key}
                  className={cn('bar-item', { 'click-able': linkUrl })}
                >
                  {(cta_label || text) && (
                    <div
                      className={cn('bar-item-text', {
                        'hover-show': themeContext.isPc && textFloating,
                      })}
                    >
                      {text && (
                        <View size={textSize} color={textColor}>
                          {text}
                        </View>
                      )}
                      {cta_label && (
                        <NewButton className='link-button' color={btnTagColor} href={linkUrl} type='arrow' size='large'>
                          {cta_label}
                        </NewButton>
                      )}
                    </div>
                  )}
                  {showMask && <div className='bar-item-mask' style={{ backgroundColor: maskColor }} />}
                  {image ? (
                    <Image className={itemClass} src={image} alt={text || cta_label} />
                  ) : (
                    <DefaultImg customName={itemClass} width='41%' height='auto' />
                  )}
                </div>
              );
            })}
          </div>
        </ContainerSize>
      </Style>
    </Bg>
  );
};

export default ImageBar;
