import React from 'react';
import { Style } from './style';
import { View } from 'components/base';
import NewLink from 'cpn/new-link';
import { parseSettings } from '../../utils';

function ThirdMenuGroup(props) {
  const { width, menuInfo = {} } = props;
  const { blocks = {}, block_order: blockOrder = [], settings = {} } = menuInfo;
  const secondMenu = parseSettings(settings);
  return (
    <Style width={width}>
      {secondMenu.title && (
        <View className='menu-title' size={11}>
          <NewLink href={secondMenu.path} target={secondMenu.openTarget}>
            {secondMenu.title}
          </NewLink>
        </View>
      )}
      <ul className='menu-items'>
        {blockOrder.map((blodkId) => {
          const blockItem = blocks[blodkId];
          if (typeof blockItem === 'undefined') return null;
          const thirdMenu = parseSettings(blockItem.settings);
          return (
            thirdMenu.title && (
              <View key={blodkId} as='li' size={11} className='menu-item'>
                <NewLink href={thirdMenu.path} target={thirdMenu.openTarget}>
                  {thirdMenu.title}
                </NewLink>
              </View>
            )
          );
        })}
      </ul>
    </Style>
  );
}

export default ThirdMenuGroup;
