import styled from 'styled-components';

export default styled.div`
  .fixed_header {
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    min-height: 77px;
  }
  .design-mobile-header {
    display: none;
  }
  .design-pc-header {
    display: block;
  }
  @media screen and (max-width: 768px) {
    .design-mobile-header {
      display: block;
    }
    .design-pc-header {
      display: none;
    }
  }
`;
