import React, { useEffect, useState } from 'react';
import Style from './style';
import cn from 'classnames';

function Drawer(props) {
  const { children, visible, onMaskClick } = props;
  const [enterIn, setEnterIn] = useState(false);

  useEffect(() => {
    // 要有一个稍微的延迟，才能看得见动画
    setTimeout(() => {
      if (visible) {
        setEnterIn(true);
      } else {
        setEnterIn(false);
      }
    }, 50);
  }, [visible]);
  return visible ? (
    <Style>
      <div className='drawer-mask' onClick={onMaskClick} />
      <div
        className={cn('drawer-body', {
          'drawer-body-show': enterIn,
        })}
      >
        {children}
      </div>
    </Style>
  ) : null;
}

export default Drawer;
