/* eslint-disable lean-imports/import */
import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { Wrapper } from 'components/base';
import { useCollection, getCollectionInitialProps } from 'hook/collection';
import { Style } from './style';
import { View } from '../../../../components/base';
import GoodsCollectionWithSwiper from '../../components/goods-collection-with-swiper';
import GoodsPrev from '../../components/goods-prev';
import { useThemeContext } from '../../../../hook/global-theme';
import { Background } from '../../components/background';
import { set } from 'lodash';
import { buriedPoint } from 'utils/log-buried-point';

const CollectionV3 = (props) => {
  const { settings = {}, initialData, elemClass, data } = props;
  const {
    grid = 3,
    rows = 2,
    strong_product: strongProduct = false,
    image_size: imageSize,
    is_cut: isCut,
    is_card: isCard,
    title_size: titleSize,
    box_width: boxWidth,
    text_line_limit: textLineLimit = 'auto',
    background_style: backgroundStyle = 'null',
    background_color: backgroundColor,
    background_image: backgroundImage,
    data_type: dataType = 'collection',
    product_list: productList = [],
    show_comment: showComment = {},
    show_shopping_car: showShoppingCar = {},
    mobile_display_type: mobileDisplayType = {},
    show_wish: showWish,
    show_sale: showSale,
    show_vendor: showVendor,
    open_card: openCard,
    card_color: cardColor,
    show_shopping_buy: showShoppingBuy,
    product_title_align: productTitleAlign,
    product_name_size: productNameSize,
    image_fill_type: imageFillType,
    show_dash_discount_label: showDashDiscountLabel = false,
  } = settings;
  const { main_val: mobileDisplayTypeMainVal = 'tiled', carousel = {} } = mobileDisplayType;
  const { auto_play_interval: mobileAutoPlayInterval = 3 } = carousel;
  const { main_val: showCommentMainVal = false, true: showCommentTrue = {} } = showComment;
  const { show_comment_num: showCommentNum = false, style: commentStyle = 'style1' } = showCommentTrue;
  const { main_val: showShoppingCarMainVal = false } = showShoppingCar;

  const { collection } = settings;
  const { kdtId, userInfo } = initialData;
  const { goodsList } = useCollection({
    initData: data,
    type: dataType,
    kdtId,
    collection,
    grid,
    rows,
    productList,
    customerId: userInfo?.customerId,
  });

  const goodsNameNodeList = useRef({});
  const [goodsNameHeightList, setgoodsNameHeightList] = useState({});
  const goodsPriceNodeList = useRef({});
  const [goodsPriceHeightList, setgoodsPriceHeightList] = useState({});
  const bottomInfoNodeList = useRef({});
  const [bottomInfoHeightList, setBottomInfoHeightList] = useState({});
  const tagListNodeList = useRef({});
  const [tagListHeightList, setTagListHeightList] = useState({});
  const themeContext = useThemeContext();
  const { clientWidth, isMobile } = themeContext;
  const [showSwiper, setShowSwiper] = useState(true);
  const [buttonDisplayType, setButtonDisplayType] = useState();
  const Bg = Background(backgroundStyle);

  const setGoodsNameNode = (goodsId, node) => {
    const newValue = { ...goodsNameNodeList.current };
    set(newValue, [goodsId], node);
    goodsNameNodeList.current = newValue;
  };

  const setGoodsPriceNode = (goodsId, node) => {
    const newValue = { ...goodsPriceNodeList.current };
    set(newValue, [goodsId], node);
    goodsPriceNodeList.current = newValue;
  };

  const setBottomInfoNode = (goodsId, node) => {
    const newValue = { ...bottomInfoNodeList.current };
    set(newValue, [goodsId], node);
    bottomInfoNodeList.current = newValue;
  };
  const setTagListNode = (goodsId, node) => {
    const newValue = { ...tagListNodeList.current };
    set(newValue, [goodsId], node);
    tagListNodeList.current = newValue;
  };

  const computedHeight = (nodeList) => {
    const newHeightList = {};
    // 宽度小于768(pad最小值)时，每行是固定两个，总数不变,需要重新计算grid和rows
    let newGrid = grid;
    let newRows = rows;
    if (clientWidth <= 768) {
      newGrid = 2;
      newRows = parseInt((grid * rows) / 2);
    }
    for (const key in nodeList) {
      set(newHeightList, [key], nodeList[key].clientHeight);
    }
    goodsList.slice(0, newGrid * newRows).forEach((item, index) => {
      // 除了强调商品的展示规律不同外，其他商品卡片按照规律计算高度
      if (strongProduct && !isMobile) {
        // 两行后的元素按规律计算
        if (index + 1 > newGrid * 2 - 3) {
          if (
            (newGrid === 5 && index % newGrid === 1) ||
            (newGrid === 4 && index % newGrid === 0) ||
            (newGrid === 3 && index % newGrid === 2) ||
            (newGrid === 2 && index % newGrid === 1)
          ) {
            const heightArr = [];
            for (let i = 0; i < newGrid; i += 1) {
              const { goodsId } = goodsList[index - i];
              heightArr.push(newHeightList[goodsId] || 0);
            }
            const maxheight = Math.max(...heightArr);
            for (let i = 0; i < newGrid; i += 1) {
              const { goodsId } = goodsList[index - i];
              newHeightList[goodsId] = maxheight;
            }
          }
        } else if (newGrid * 2 - 3 === index + 1) {
          // 两行前的元素特殊处理
          const rowGrid = index / 2;
          const heightArr = [];
          // 第二行的高度计算
          for (let i = 0; i < rowGrid; i += 1) {
            const { goodsId } = goodsList[index - i];
            heightArr.push(newHeightList[goodsId] || 0);
          }
          // 除了正常排列的商品外，强调的商品高度算入第二行内
          heightArr.push(newHeightList[goodsList[1].goodsId] || 0);
          const maxheight = Math.max(...heightArr);
          for (let i = 0; i < rowGrid; i += 1) {
            const { goodsId } = goodsList[index - i];
            newHeightList[goodsId] = maxheight;
          }
          newHeightList[goodsList[1].goodsId] = maxheight;
        } else if (index === (newGrid * 2 - 4) / 2) {
          // 第一行的高度计算
          const heightArr = [];
          for (let i = index; i >= 0; i -= 1) {
            // 除了正常排列的商品外，强调的商品高度不算入第一行内
            if (i !== 1) {
              const { goodsId } = goodsList[i];
              heightArr.push(newHeightList[goodsId] || 0);
            }
          }
          const maxheight = Math.max(...heightArr);
          for (let i = index; i >= 0; i -= 1) {
            if (i !== 1) {
              const { goodsId } = goodsList[i];
              newHeightList[goodsId] = maxheight;
            }
          }
        }
      } else if ((index + 1) % newGrid === 0 || index + 1 === goodsList.slice(0, newGrid * newRows).length) {
        let gridNum = newGrid;
        if (index + 1 === goodsList.slice(0, newGrid * newRows).length && (index + 1) % newGrid !== 0) {
          gridNum = (index + 1) % newGrid;
        }
        const heightArr = [];
        for (let i = 0; i < gridNum; i += 1) {
          const { goodsId } = goodsList[index - i];
          heightArr.push(newHeightList[goodsId] || 0);
        }
        const maxheight = Math.max(...heightArr);
        for (let i = 0; i < gridNum; i += 1) {
          const { goodsId } = goodsList[index - i];
          newHeightList[goodsId] = maxheight;
        }
      }
    });
    return newHeightList;
  };

  useEffect(() => {
    if (mobileDisplayTypeMainVal === 'carousel' && isMobile) {
      return;
    }
    if (
      goodsNameNodeList.current &&
      Object.keys(goodsNameNodeList.current).length >= goodsList?.slice(0, grid * rows).length
    ) {
      // 非不限制行数的时候，需要计算同行兄弟元素的商品标题高度
      if (textLineLimit !== 'auto' || strongProduct) {
        setgoodsNameHeightList(computedHeight(goodsNameNodeList.current));
      } else {
        setgoodsNameHeightList({});
      }
    }
  }, [goodsList.length, collection, grid, rows, openCard, textLineLimit, productNameSize, strongProduct]);

  useEffect(() => {
    if (mobileDisplayTypeMainVal === 'carousel' && isMobile) {
      return;
    }
    if (bottomInfoNodeList.current && Object.keys(bottomInfoNodeList.current).length) {
      if (showVendor || showSale || showCommentMainVal) {
        setBottomInfoHeightList(computedHeight(bottomInfoNodeList.current));
      } else {
        setBottomInfoHeightList({});
      }
    }
  }, [
    grid,
    rows,
    openCard,
    goodsList.length,
    collection,
    showVendor,
    showSale,
    showCommentMainVal,
    strongProduct,
    showCommentNum,
    commentStyle,
  ]);

  useEffect(() => {
    if (mobileDisplayTypeMainVal === 'carousel' && isMobile) {
      return;
    }
    if (
      goodsPriceNodeList.current &&
      Object.keys(goodsPriceNodeList.current).length >= goodsList?.slice(0, grid * rows).length
    ) {
      setgoodsPriceHeightList(computedHeight(goodsPriceNodeList.current));
    }
  }, [goodsList.length, collection, grid, rows]);

  useEffect(() => {
    if (mobileDisplayTypeMainVal === 'carousel' && isMobile) {
      return;
    }
    if (tagListNodeList.current && Object.keys(tagListNodeList.current).length) {
      setTagListHeightList(computedHeight(tagListNodeList.current));
    }
  }, [grid, rows, goodsList.length, collection, strongProduct, openCard]);

  useEffect(() => {
    // 装修的时候切换屏幕大小，获取到的商品名称实际宽度不准确，需要延时获取
    const timeout = 1000; // 延时时长
    const timer = setTimeout(() => {
      if (goodsNameHeightList && Object.keys(goodsNameHeightList).length) {
        setgoodsNameHeightList(computedHeight(goodsNameNodeList.current));
      }
      if (bottomInfoHeightList && Object.keys(bottomInfoHeightList).length) {
        setBottomInfoHeightList(computedHeight(bottomInfoNodeList.current));
      }
      if (tagListHeightList && Object.keys(tagListHeightList).length) {
        setTagListHeightList(computedHeight(tagListNodeList.current));
      }
      if (goodsPriceHeightList && Object.keys(goodsPriceHeightList).length) {
        setgoodsPriceHeightList(computedHeight(goodsPriceNodeList.current));
      }
    }, timeout);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [clientWidth]);

  /** 播放间隔选项 */
  useEffect(() => {
    setShowSwiper(false);
    setTimeout(() => {
      setShowSwiper(true);
    });
  }, [mobileAutoPlayInterval]);

  useEffect(() => {
    if (goodsList.length) {
      buriedPoint('goods_view', {
        goods_component_location: 'HOME_collection-v3',
        goods_component_location_name: '商品分组3.0',
        goodsList: goodsList?.slice(0, grid * rows).map((item) => {
          // eslint-disable-next-line
          const { goodsId: goods_id, goodsType: goods_type = '', title } = item;
          return {
            goods_id,
            goods_type,
            title,
          };
        }),
      });
    }
  }, [goodsList.length]);

  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Style
        className={cn('feature-collection-section', elemClass, `cn-${boxWidth}`)}
        grid={grid}
        gap={40}
        textLineLimit={textLineLimit}
        mobileGrid={mobileDisplayTypeMainVal === 'rowTwo' ? 1 : 2}
      >
        <Wrapper paddingTop='vContainer' paddingBottom='vContainer'>
          <div className={cn('feature-collection-box')}>
            <View
              as='h3'
              type='header'
              size={titleSize}
              className={cn('feature-collection-title')}
              style={{
                textAlign: settings.text_align || 'center',
              }}
            >
              {settings.title}
            </View>
            {!(mobileDisplayTypeMainVal === 'carousel' && isMobile) && (
              <div className='feature-collection-grid'>
                {goodsList?.length > 0 &&
                  goodsList?.slice(0, grid * rows).map((item, index) => {
                    return (
                      <div
                        key={item.goodsId || index}
                        className={cn('goods-prev-container', {
                          'goods-prev-container--strong': index === 1 && strongProduct,
                        })}
                      >
                        <GoodsPrev
                          key={item.goodsId}
                          goodsInfo={item}
                          initialData={initialData}
                          settings={{ ...settings, image_ratio: imageSize }}
                          grid={grid}
                          imageFillType={isCut || (index === 1 && strongProduct) ? 'cover' : imageFillType}
                          commentConfig={{
                            showComment: showCommentMainVal,
                            showCommentNum: showComment?.[showCommentMainVal]?.show_comment_num,
                            style: showComment?.[showCommentMainVal]?.style,
                          }}
                          shoppingCarConfig={{
                            showShoppingCar: showShoppingCarMainVal || showShoppingCar === true,
                            style: showShoppingCar?.[showShoppingCarMainVal]?.style || 'style1',
                          }}
                          showShoppingBuy={showShoppingBuy}
                          showSale={showSale}
                          showWish={showWish}
                          showVendor={showVendor}
                          openCard={isCard === true || openCard}
                          cardColor={cardColor}
                          productTitleAlign={productTitleAlign}
                          returnGoodsNameNode={setGoodsNameNode}
                          returnGoodsPriceNode={setGoodsPriceNode}
                          returnBottomInfoNode={setBottomInfoNode}
                          returnTagListNode={setTagListNode}
                          goodsNameHeight={goodsNameHeightList[item.goodsId] || 0}
                          goodsPriceHeight={goodsPriceHeightList[item.goodsId] || ''}
                          bottomInfoHeight={bottomInfoHeightList[item.goodsId] || 0}
                          tagListHeight={tagListHeightList[item.goodsId] || 0}
                          buttonDisplayType={buttonDisplayType}
                          setButtonDisplayType={(type) => {
                            if (index === 0) {
                              setButtonDisplayType(type);
                            }
                          }}
                          defaultImageIsSquare={!(strongProduct && index === 1)}
                          showDashDiscountLabel={showDashDiscountLabel}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
            {isMobile && mobileDisplayTypeMainVal === 'carousel' && showSwiper && (
              <GoodsCollectionWithSwiper
                goodsList={goodsList}
                initialData={initialData}
                settings={{
                  ...settings,
                  open_card: isCard === true || openCard,
                  image_ratio: imageSize,
                }}
              />
            )}
          </div>
        </Wrapper>
      </Style>
    </Bg>
  );
};

CollectionV3.getInitialProps = getCollectionInitialProps;

export default CollectionV3;
