import styled, { createGlobalStyle } from 'styled-components';

export default styled.div``;
export const GlobalStyle = createGlobalStyle`
    .new-popover-list{
        padding:4px;
        .list{
            .item{
                padding:8px;
                position: relative;
                font-style: normal;
                font-weight: normal;
                ${(props) => props.theme.getBaseSize([14])};
                color: var(--color_body_text);
                cursor: pointer;
                &:hover{
                    /* 文本/正文/4% */
                    background: ${(props) => props?.theme?.colorBodyTexts?.['4']};
                }
                &.disabled{
                    /* 文本/正文/20% */
                    color: ${(props) => props?.theme?.colorBodyTexts?.['20']};
                    cursor: default;

                }
                .show-red-point{
                    position: relative;
                }
                .show-red-point::before{
                    content: '';
                    position:absolute;
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    right: -8px;
                    top:0px;
                    background:#EC5B56 ;
                }
        
            }
        }
    }
    
`;
