import React, { useRef, useContext, useState, useEffect, useMemo } from 'react';
import { Style } from './style';
import { ContextGoodDetail } from '../../../context/context-good-detail';
import ImageContainer from '../../../design/components/image-container';
import SwiperContainer from '../../../design/components/swiper-container';
import fullfillImage from 'utils/fullfillImage';
import cn from 'classnames';
import { getQueryString } from 'utils';

const ProductPreview = ({ onViewChange = () => {} }) => {
  const { productMedia } = useContext(ContextGoodDetail);
  const { mediaList, mainMediaSrc } = productMedia;

  const [mainMediaIndex, setMainMediaIndex] = useState(productMedia?.mainMediaIndex || 0);

  const { imageRatio } = useContext(ContextGoodDetail);
  const swiperRef = useRef();

  const [newMainMediaSrc, setNewMainMediaSrc] = useState(mainMediaSrc);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !!mainMediaSrc && setMainMediaIndex(-1);
    setNewMainMediaSrc(mainMediaSrc);
  }, [mainMediaSrc]);

  const getImageStyle = (cdnSrc) => {
    let { width, height } = getQueryString(cdnSrc);
    width = Number(width);
    height = Number(height);
    if (width && height) {
      if (+width < +height) {
        return {
          width: `${(64 / height) * width}px`,
          height: '64px',
        };
      } else {
        return {
          width: '64px',
          height: `${(64 / width) * height}px`,
        };
      }
    }
    return {
      width: '64px',
      height: '64px',
      transform: `scale(1)`,
    };
  };

  const galleryList = useMemo(() => {
    const temp = [];
    mediaList.forEach((item) => {
      // 不显示带有sku的图片
      if (item.variantId) return;
      if (item.type === 'VIDEO') {
        temp.push({
          type: 'video',
          mediaId: item.mediaId,
          muted: true,
          firstImage: item.coverUrl,
          src: item.coverUrl,
        });
      }
      if (item.type === 'IMAGE') {
        const imgStyle = getImageStyle(item.url);
        temp.push({
          type: 'img',
          src: item.url,
          position: 'center center',
          imgStyle,
        });
      }
    });
    return temp;
  }, [mediaList.length]);

  const contents = useMemo(() => {
    if (newMainMediaSrc) {
      return [
        {
          type: 'img',
          src: newMainMediaSrc,
          position: 'center center',
        },
      ];
    }
    return galleryList;
  }, [galleryList.length, newMainMediaSrc]);

  const onResize = () => {
    setTimeout(() => {
      swiperRef && swiperRef?.current?.swiper?.update();
    }, 200);
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(mainMediaIndex + 1);
    }
  }, [mainMediaIndex]);

  const getUrl = (data) => {
    if (!data) return '';
    let url = data.src;
    if (data.src) {
      url = fullfillImage(data.src, 'origin');
    }
    return url;
  };

  const getDefaultMainUrl = () => {
    return mediaList[0] && mediaList[0].type === 'VIDEO' ? mediaList[0]?.coverUrl : mediaList[0]?.url;
  };
  return (
    <Style>
      <div className='media-swiper'>
        {/* 这里巧妙的运用了ImageContainer的容器功能，url传轮播图第一张地址，也能巧妙实现轮播图高度自适应第一张的情况 */}
        <ImageContainer
          heightType={imageRatio}
          heightRules='type3'
          fit='cover'
          position='center center'
          hideImg={mediaList.length > 0}
          url={getDefaultMainUrl()}
        >
          <SwiperContainer
            ref={swiperRef}
            contents={contents}
            currentIndex={mainMediaIndex}
            autoPlay={false}
            loop
            onSwiper={onViewChange}
            paginatorStyle='dots'
            width='100%'
            height='100%'
            onInit={onResize}
            onResize={onResize}
          />
        </ImageContainer>
        <div className='media-list-gallery'>
          {galleryList.map((item, index) => {
            return (
              <div
                className={cn(
                  'media-gallery-item',
                  `media-gallery-item-${index === mainMediaIndex && !newMainMediaSrc ? 'active' : 'no-active'}`,
                )}
                onClick={() => {
                  setNewMainMediaSrc('');
                  setMainMediaIndex(index);
                }}
              >
                <img
                  key={item.src}
                  src={getUrl(item)}
                  // height={item?.imgStyle?.height}
                  // width={item?.imgStyle?.width}
                  preview='0'
                  style={item?.imgStyle}
                  // style={{ transform: item?.imgStyle?.transform }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Style>
  );
};

export default ProductPreview;
