import Style from './style';
import React from 'react';
import cn from 'classnames';
import { Tabs } from 'zent';

const { TabPanel } = Tabs;
const NewTabs = (props) => {
  const { children, ...extra } = props;
  return (
    <Style>
      <div className={cn('new-tabs')}>
        <Tabs {...extra}>{children}</Tabs>
      </div>
    </Style>
  );
};
NewTabs.TabPanel = TabPanel;
export default NewTabs;
