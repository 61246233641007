import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { shareHandle } from '../../../util/share-handle';
import { ShareListType } from 'const/share-type';
import { Style } from './style';
import { buriedPoint } from '../../../util/log-buried-point';
import shopApi from '../../../api/shop';
import { useIntl } from 'react-intl';

export const ShareButtons = (props) => {
  const intl = useIntl();
  const SHARE_STYLE = {
    rectangle: 'rectangle',
    circular: 'circular',
  };
  const { customClass, title, handle, goodsDetail = {}, socialShareData = {} } = props || {};
  const [shareList, setShareList] = useState([]);
  const {
    share_button_shape: shareButtonShape = SHARE_STYLE.circular,
    share_button_custom: shareButtonCustom,
    share_button_color: shareButtonColor,
    show_share_button: showShareButton = true,
  } = socialShareData;

  const buriedPointShare = (method) => {
    buriedPoint('share', { method });
  };

  const onShareGoods = (shareType) => {
    const linkUrl = `${window.location.origin}/products/${encodeURIComponent(handle || title)}`;
    shareHandle({ intl, shareType, linkUrl, goodsDetail });
    buriedPointShare(shareType);
  };

  useEffect(() => {
    shopApi.getShopSpreadBasicInfo({ kdtId: window?.global_data?.kdtId }).then((res) => {
      let { shopSpreadChannels } = res;
      if (!navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
        // 现在手机端才有messenger，pc端需要appid账号
        shopSpreadChannels = shopSpreadChannels.filter((item) => item.type !== ShareListType.Messenger);
      }
      setShareList(shopSpreadChannels);
    });
  }, []);

  return showShareButton && shareList.length > 0 ? (
    <Style>
      <div className={cn('share-buttons-container skiptranslate', customClass)}>
        {shareButtonShape === SHARE_STYLE.rectangle &&
          shareList.map((item) => {
            return (
              <span
                className='featured-products-share-item'
                onClick={() => {
                  onShareGoods(item.type, goodsDetail);
                }}
                key={item.type}
              >
                <svg
                  className={cn(`${item.type}-icon`, 'featured-products-share-icon')}
                  aria-hidden='true'
                  style={{ fill: shareButtonCustom ? shareButtonColor : item.defaultColor }}
                >
                  <use xlinkHref={item.icon} />
                </svg>
                {intl.formatMessage({ id: item.name })}
              </span>
            );
          })}
        {shareButtonShape === SHARE_STYLE.circular &&
          shareList.map((item) => {
            return (
              <span
                className='circular-icon-item'
                key={item.type}
                onClick={() => {
                  onShareGoods(item.type, goodsDetail);
                }}
              >
                <svg aria-hidden='true' style={{ fill: shareButtonCustom ? shareButtonColor : item.defaultColor }}>
                  <use xlinkHref={item.icon} />
                </svg>
              </span>
            );
          })}
      </div>
    </Style>
  ) : null;
};
