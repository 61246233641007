import styled, { createGlobalStyle } from 'styled-components';

export const PreviewStyle = createGlobalStyle`
    .PhotoView-SlideWrap.review-image-list {
        .PhotoView-PhotoSlider__BannerWrap {
            width: 100%;
            height: 72px;
            background: #fff;
            box-shadow: 0px 0px 0px 0px #d1d5db;
        }

        .PhotoView-PhotoSlider__Counter {
            display: none;
        }

        .PhotoView-PhotoSlider__BannerRight {
            width: 100%;
        }

        .PhotoView-PhotoSlider__toolbarIcon {
            display: none;
        }

        .PhotoView-PhotoSlider__ArrowLeft,
        .PhotoView-PhotoSlider__ArrowRight {
            display: none;
        }
    }
`;

export const ToolbarStyle = styled.div`
  &.preview-tool-bar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color_body_text);
  }

  .preview-back-icon,
  .preview-counter,
  .preview-item-action {
    width: 100%;
    flex: 1;
  }

  .preview-back-icon {
    text-align: left;
  }

  .preview-counter {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
  }

  .action-icon {
    width: 24px;
    height: 24px;
    box-sizing: content-box;
    padding: 0 16px;
    cursor: pointer;
  }
`;
