import styled from 'styled-components';

export const Style = styled.ul`
  width: ${(props) => props.width + 'px'};
  .menu-item {
    color: #000;
    font-weight: 700;
    padding-left: 0;
    margin-bottom: 8px;
  }
`;
