import React, { useState, useRef, useEffect } from 'react';
import { PcSubMenu } from '../components/pc-submenu';
import { HeaderOperte } from '../components/header-operte';
import HeaderLogo from '../components/header-logo';
import { HeaderAnnouncement } from '../components/header-announcement';
import HeaderWrapper from './style';
import cn from 'classnames';
import { Text, Badge, Icon } from '../../../../../components/base';

let timer = null;

export const PcHeader = (props) => {
  const {
    history = {},
    settings,
    initialData,
    elemClass,
    jumpToOtherPage,
    menuDetail = {},
    headerGlobalStyle,
    style,
    setIsMouseOver,
    totalCountWish,
    isScrollOver,
    onOpenCart,
  } = props;
  const {
    show_announcement: showAnnouncement,
    announcement_text: announcementText,
    announcement_link: announcementLink,
    color_bg: colorBg,
    color_text: colorText,
    align_logo: alignLogo,
    logo,
    menu_height: menuHeight = 72,
    default_text_icon_color: defaultTextIconColor,
    box_width: boxWidth,
    main_menu_align: mainMenuAlign,
    sub_menu_align: subMenuAlign,
    menu_group_width: menuGroupWidth,
    level_one_menu_text_size: levelOneMenuTextSize = 11,
    level_two_menu_text_size: levelTwoMenuTextSize = 11,
    level_three_menu_text_size: levelThreeMenuTextSize = 11,
    submenu_adapt_content_width: submenuAdaptContentWidth = false,
    show_search_icon: showSearchIcon,
    show_personal_center_icon: showPersonalCenterIcon = true,
    show_wishlist_icon: showWishlistIcon = true,
    show_shop_cart_icon: showShopCartIcon = true,
    pc_menu_padding: pcMenuPadding = 16,
  } = settings;

  // 兼容旧数据
  const subMenuBgColor = settings.sub_menu_bg_color || settings.default_bg_color; // 子菜单背景色
  const subMenuTextColor = settings.sub_menu_text_color || settings.default_text_icon_color; // 子菜单文字颜色

  const { shopSetting = {} } = initialData;
  const isLeftLogo = alignLogo === 'left' || isScrollOver;
  const isCenterLogo = alignLogo === 'center' && !isScrollOver;
  const [activeIndex, setActiveIndex] = useState();
  const [menuContainerLeft, setMenuContainerLeft] = useState(0);
  const [showRightScrollIcon, setShowRightScrollIcon] = useState(false);
  const [showLeftScrollIcon, setShowLeftScrollIcon] = useState(false);
  const menuListRef = useRef(null);

  // 内容过多，才会显示滚动的icon
  const isShowScrollIcon = () => {
    const menuListWidth = menuListRef.current.offsetWidth;
    const menuListScrollWidth = menuListRef.current.scrollWidth;
    if (menuListWidth < menuListScrollWidth) {
      setShowRightScrollIcon(true);
    } else {
      setShowRightScrollIcon(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', isShowScrollIcon);
    return () => {
      window.removeEventListener('resize', isShowScrollIcon);
    };
  }, []);
  // 将导航栏滚动到最右边
  const handleMenuListMove = (direction) => {
    const dom = menuListRef.current;
    const menuListWidth = dom.offsetWidth;
    const menuListScrollWidth = dom.scrollWidth;
    const distance = menuListScrollWidth - menuListWidth;
    let { scrollLeft } = dom;
    timer = setInterval(() => {
      if (scrollLeft <= distance) {
        if (scrollLeft <= 0) {
          clearInterval(timer);
        }
        // eslint-disable-next-line no-plusplus
        dom.scrollLeft = direction === 'left' ? --scrollLeft : ++scrollLeft;
      } else {
        clearInterval(timer);
      }
    }, 16);
  };
  // 手动触发滚动
  const handleManualScroll = (e) => {
    const { scrollLeft } = e.target;
    const menuListWidth = menuListRef.current.offsetWidth;
    const menuListScrollWidth = menuListRef.current.scrollWidth;
    if (scrollLeft === 0) {
      setShowLeftScrollIcon(false);
    } else if (scrollLeft === menuListScrollWidth - menuListWidth) {
      setShowRightScrollIcon(false);
    } else {
      setShowLeftScrollIcon(true);
      setShowRightScrollIcon(true);
    }
  };
  useEffect(() => {
    if (!menuListRef.current) return;
    isShowScrollIcon();
    menuListRef.current.addEventListener('scroll', handleManualScroll);
  }, [menuListRef]);

  /**
   * 设置pc端下单header菜单距离左边的距离
   *
   */
  const setMenuOffsetLeft = (e, index) => {
    const targetDom = e.target;
    setTimeout(() => {
      const subMenuContainer = document.querySelectorAll('.first-level-header-sub-menu')[index];
      const subMenuContainerWidth = subMenuContainer.offsetWidth;
      const navigateWidth = subMenuContainer.offsetParent.offsetWidth;
      const { offsetWidth } = targetDom;
      const offsetLeft = targetDom.offsetLeft + 24;
      const { scrollLeft } = menuListRef.current;

      const containerLeft = offsetLeft + offsetWidth / 2 - subMenuContainerWidth / 2;
      if (containerLeft < 0) {
        // 子容器的宽度超过了左边界
        console.log('超过了左边界');
        setMenuContainerLeft(0);
      } else if (containerLeft + subMenuContainerWidth > navigateWidth) {
        // 子容器的宽度超过了右边界
        console.log('超过了右边界');
        setMenuContainerLeft(`${navigateWidth - subMenuContainerWidth}px`);
      } else {
        // 子容器的宽度在屏幕中间
        setMenuContainerLeft(`${offsetLeft - scrollLeft + offsetWidth / 2 - subMenuContainerWidth / 2}px`);
      }
    }, 0);
  };
  return (
    <HeaderWrapper
      className={`cn-${boxWidth}`}
      gColor={headerGlobalStyle.color}
      defaultTextIconColor={defaultTextIconColor}
      mainMenuAlign={mainMenuAlign}
      showRightScrollIcon={showRightScrollIcon}
      pcMenuPadding={pcMenuPadding}
    >
      <div className={elemClass} style={style}>
        <HeaderAnnouncement
          showAnnouncement={showAnnouncement}
          colorBg={colorBg}
          colorText={colorText}
          announcementText={announcementText}
          announcementLink={announcementLink}
        />
        {isCenterLogo && logo && (
          <div className='header-center-logo-container'>
            <div className='header-center-logo'>
              <HeaderLogo jumpToOtherPage={jumpToOtherPage} logo={logo} menuHeight={menuHeight} />
              <HeaderOperte
                customClass='header-operate-container'
                initialData={initialData}
                history={history}
                iconColor={headerGlobalStyle.color}
                jumpToOtherPage={jumpToOtherPage}
                totalCountWish={totalCountWish}
                showSearchIcon={showSearchIcon}
                showPersonalCenterIcon={showPersonalCenterIcon}
                showWishlistIcon={showWishlistIcon}
                showShopCartIcon={showShopCartIcon}
                onOpenCart={onOpenCart}
              />
            </div>
          </div>
        )}

        <div
          className='sub-header-container'
          style={{
            height: `${menuHeight}px`,
            color: headerGlobalStyle.color,
          }}
        >
          <div className={cn('wrapper', `cn-${mainMenuAlign}`)}>
            {(isLeftLogo || !logo) && (
              <HeaderLogo
                menuHeight={menuHeight}
                className='pc-header-logo'
                isLeftLogo={isLeftLogo}
                logo={logo}
                logoColor={headerGlobalStyle.color}
                jumpToOtherPage={jumpToOtherPage}
                shopName={shopSetting.name}
              />
            )}
            <div
              className='menu-list-wrapper'
              onMouseLeave={() => {
                setIsMouseOver(false);
                setActiveIndex('');
              }}
            >
              {showLeftScrollIcon && (
                <Icon
                  onMouseEnter={() => {
                    handleMenuListMove('left');
                  }}
                  onMouseLeave={() => {
                    clearInterval(timer);
                  }}
                  className='scroll-icon scroll-icon-left'
                  size='24'
                  name='icongengduozhankai'
                  color={headerGlobalStyle.color}
                />
              )}
              {/* 添加了导航栏超出滚动之后，y方向就自动hidden了，所以吧这部分提出来 */}
              <div className='menu-content-list'>
                {Array.isArray(menuDetail.shopTemplateMenuItemTreeNodeCustomerVO) &&
                  menuDetail.shopTemplateMenuItemTreeNodeCustomerVO?.length > 0 &&
                  menuDetail.shopTemplateMenuItemTreeNodeCustomerVO.map((item, index) => {
                    return Array.isArray(item?.shopTemplateMenuItemTreeNodeCustomerVO) &&
                      item?.shopTemplateMenuItemTreeNodeCustomerVO.length > 0 ? (
                      <div
                        className={cn('pc-sub-menu', `cn-sub-${subMenuAlign}`, {
                          active: activeIndex === index,
                        })}
                        key={index}
                      >
                        <PcSubMenu
                          menuGroupWidth={menuGroupWidth}
                          subMenuBgColor={subMenuBgColor}
                          subMenuTextColor={subMenuTextColor}
                          levelTwoMenuTextSize={levelTwoMenuTextSize}
                          submenuAdaptContentWidth={submenuAdaptContentWidth}
                          levelThreeMenuTextSize={levelThreeMenuTextSize}
                          menuContainerLeft={menuContainerLeft}
                          menuList={item?.shopTemplateMenuItemTreeNodeCustomerVO}
                          menuIndex={1}
                          textColor={defaultTextIconColor}
                          headerGlobalStyle={headerGlobalStyle}
                        />
                      </div>
                    ) : (
                      <div className='first-level-header-sub-menu empty-div' key={index} />
                    );
                  })}
              </div>
              <div className='menu-list' ref={menuListRef}>
                {Array.isArray(menuDetail.shopTemplateMenuItemTreeNodeCustomerVO) &&
                  menuDetail.shopTemplateMenuItemTreeNodeCustomerVO?.length > 0 &&
                  menuDetail.shopTemplateMenuItemTreeNodeCustomerVO.map((item, index) => {
                    return (
                      <div className='first-menu-item-wrapper' key={index}>
                        <div
                          className='first-menu-item'
                          onMouseEnter={(e) => {
                            setActiveIndex(index);
                            setIsMouseOver(true);
                            setMenuOffsetLeft(e, index);
                          }}
                        >
                          <Text
                            as='a'
                            size={levelOneMenuTextSize}
                            className='first-menu-item-inner link_has_label'
                            href={item.url || '#'}
                            target={`_${item?.open_target ?? 'self'}`}
                          >
                            {item.menuItemName}
                            {item.settings?.show_label && item.settings?.label_text && (
                              <Badge
                                className='header-badge'
                                color={item.settings?.label_text_color}
                                bgColor={item.settings?.label_bg_color}
                              >
                                {item.settings?.label_text}
                              </Badge>
                            )}
                          </Text>
                        </div>
                        {Array.isArray(item?.shopTemplateMenuItemTreeNodeCustomerVO) &&
                        item?.shopTemplateMenuItemTreeNodeCustomerVO.length > 0 ? (
                          <div
                            className={cn('pc-sub-menu', `cn-sub-${subMenuAlign}`, {
                              active: activeIndex === index,
                            })}
                          >
                            <PcSubMenu
                              menuGroupWidth={menuGroupWidth}
                              subMenuBgColor={subMenuBgColor}
                              subMenuTextColor={subMenuTextColor}
                              levelTwoMenuTextSize={levelTwoMenuTextSize}
                              submenuAdaptContentWidth={submenuAdaptContentWidth}
                              levelThreeMenuTextSize={levelThreeMenuTextSize}
                              menuContainerLeft={menuContainerLeft}
                              menuList={item?.shopTemplateMenuItemTreeNodeCustomerVO}
                              menuIndex={1}
                              textColor={defaultTextIconColor}
                              headerGlobalStyle={headerGlobalStyle}
                            />
                          </div>
                        ) : (
                          <div className='first-level-header-sub-menu empty-div' key={index} />
                        )}
                      </div>
                    );
                  })}
              </div>
              {showRightScrollIcon && (
                <Icon
                  onMouseEnter={() => {
                    handleMenuListMove('right');
                  }}
                  onMouseLeave={() => {
                    clearInterval(timer);
                  }}
                  className='scroll-icon scroll-icon-right'
                  size='24'
                  name='icongengduozhankai'
                  color={headerGlobalStyle.color}
                />
              )}
            </div>
            {(isLeftLogo || !logo) && (
              <HeaderOperte
                customClass='pc-header-operate'
                jumpToOtherPage={jumpToOtherPage}
                initialData={initialData}
                iconColor={headerGlobalStyle.color}
                totalCountWish={totalCountWish}
                showSearchIcon={showSearchIcon}
                showPersonalCenterIcon={showPersonalCenterIcon}
                showWishlistIcon={showWishlistIcon}
                showShopCartIcon={showShopCartIcon}
                onOpenCart={onOpenCart}
              />
            )}
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};
