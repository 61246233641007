import { DISTRIBUTION_COOKIES } from '../../../util/local-storage/config';
import SessionStorage from '../../../util/session-storage';
import { DISTRIBUTION_SESSIONS } from '../../../util/session-storage/config';
import LocalStorage from '../../../util/local-storage';
import { DiscountType, DiscountLimitType } from 'const/discount';
import distributionApi from '../../../api/distribution';
import { intlLiToYuan } from 'utils/money';

export const getDistributionCookies = () => {
  const { cookies, expiresAt } = LocalStorage.get(DISTRIBUTION_COOKIES);
  if (expiresAt && expiresAt > 0) {
    return cookies;
  }
  if (expiresAt === 0) {
    return SessionStorage.getSessionStorage(DISTRIBUTION_SESSIONS);
  }
};

export const storeDistributionCookies = (cookies, expiresAt) => {
  SessionStorage.removeSessionStorage(DISTRIBUTION_COOKIES);
  LocalStorage.remove(DISTRIBUTION_COOKIES);
  if (expiresAt > 0) {
    return LocalStorage.set(DISTRIBUTION_COOKIES, { ...cookies }, expiresAt * 24);
  }
  SessionStorage.setSessionStorage(DISTRIBUTION_SESSIONS, JSON.stringify(cookies));
};

export const removeDistributionCookies = () => {
  SessionStorage.removeSessionStorage(DISTRIBUTION_COOKIES);
  LocalStorage.remove(DISTRIBUTION_COOKIES);
};

export const reportClick = (params) => {
  distributionApi.reportClick(params);
};

/**
 * 满多少元减钱
 * @param params
 */
const getFixedAmountAmountDesc = (params) => {
  const { intl, discountInfo = {}, currency } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
  } = discountInfo;
  const { discountValue = 10 } = discountGets;
  const { requireValue } = minimumRequirement;
  const amount = intlLiToYuan({ currency, value: requireValue });
  const discountAmount = intlLiToYuan({ currency, value: discountValue });
  return intl.formatMessage(
    {
      id: 'shared_discountAmount_off',
    },
    {
      amount,
      discountAmount,
    },
  );
};
/**
 * 满多少件减钱
 * @param params
 */
const getFixedAmountQuantity = (params) => {
  const { intl, discountInfo = {}, currency } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
  } = discountInfo;
  const { discountValue = 10 } = discountGets;
  const { requireValue } = minimumRequirement;
  const amount = intlLiToYuan({ currency, value: discountValue });
  return intl.formatMessage(
    {
      id: 'full_pieces_off',
    },
    {
      requireValue,
      amount,
    },
  );
};

/**
 * 满多少元打折
 * @param params
 */
const getPercentageAmount = (params) => {
  const { intl, discountInfo = {}, currency } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
  } = discountInfo;
  const { discountValue = 10 } = discountGets;
  const { requireValue } = minimumRequirement;
  const requireAmount = intlLiToYuan({ currency, value: requireValue });
  return intl.formatMessage(
    {
      id: 'buy_enjoy_off',
    },
    {
      requireAmount,
      discountValue,
    },
  );
};

/**
 * 满多少件打折
 * @param params
 */
const getPercentageQuantity = (params) => {
  const { intl, discountInfo = {} } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
  } = discountInfo;
  const { discountValue = 10 } = discountGets;
  const { requireValue } = minimumRequirement;

  return intl.formatMessage(
    {
      id: 'off_with_purchase',
    },
    {
      requireValue,
      discountValue,
    },
  );
};

/**
 * 免运费 满多少件
 * @param params
 */
const getFreeShippingQuantity = (params) => {
  const { discountInfo = {}, intl } = params;
  const {
    minimumRequirement = {}, // 折扣条件
  } = discountInfo;
  const { requireValue } = minimumRequirement;
  return intl.formatMessage(
    {
      id: 'free_shipping_orders',
    },
    {
      requireValue,
    },
  );
};

/**
 * 免运费 满多少元
 * @param params
 */
const getFreeShippingAmount = (params) => {
  const { intl, discountInfo = {}, currency } = params;
  const {
    minimumRequirement = {}, // 折扣条件
  } = discountInfo;
  const { requireValue } = minimumRequirement;
  const requireAmount = intlLiToYuan({ currency, value: requireValue });

  return intl.formatMessage(
    {
      id: 'free_shipping_over',
    },
    {
      requireAmount,
    },
  );
};

/** 获取的折扣信息的描述文本 */
export const getDiscountDesc = (params) => {
  const { discountInfo = {}, intl, currency } = params;
  const {
    discountType = DiscountType.FixedAmount, // 折扣类型
    minimumRequirement = {},
    discountGets = {},
  } = discountInfo;
  const { type } = minimumRequirement;
  const isLimitAmount = type === DiscountLimitType.Amount;
  // 存在最低要求
  if (type) {
    switch (discountType) {
      case DiscountType.FixedAmount:
        return isLimitAmount ? getFixedAmountAmountDesc(params) : getFixedAmountQuantity(params);

      case DiscountType.Percentage:
        return isLimitAmount ? getPercentageAmount(params) : getPercentageQuantity(params);

      case DiscountType.FreeShipping:
        return isLimitAmount ? getFreeShippingAmount(params) : getFreeShippingQuantity(params);

      default:
        return '';
    }
  }
  // 无最低要求
  if (!type) {
    const { discountValue } = discountGets;
    const discountAmount = intlLiToYuan({ currency, value: discountValue });
    switch (discountType) {
      case DiscountType.FixedAmount:
        return intl.formatMessage(
          {
            id: 'shared_with_discountAmount',
          },
          {
            discountAmount,
          },
        );

      case DiscountType.Percentage:
        return intl.formatMessage(
          {
            id: 'shared_with_discountValue',
          },
          {
            discountValue,
          },
        );

      case DiscountType.FreeShipping:
        return intl.formatMessage(
          {
            id: 'shared_free_shipping',
          },
          {
            discountValue,
          },
        );

      default:
        return '';
    }
  }
};
