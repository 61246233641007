import styled from 'styled-components';
// 本组件的难点：容器宽度有关键点，如果屏幕尺寸在关键点之间的，需要自动适配
// 解决方案，1:拿到容器实际展示的宽度，取配置的宽度和屏幕宽度的较小值
// 2:组件里的内容都按相对实际宽度的百分比布局（除规范外）
export const Style = styled.div`
  /* 如果存在多个视频的情况，会使用sticky布局，以实现视觉上的效果：滚动时页面不滚动，滚动视频 */
  .area-stage {
    .area-content {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
  }
  .slide-video-pic-section {
    display: flex;
    justify-content: center;
    width: 100%;
    // 根据容器真实宽度按比列计算高度
    height: ${(props) => (800 / 1440) * props.boxWidthReal}px;
    background: url(${(props) => props.bgImg}) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .slide-video-pic {
    margin: 0 auto;
    height: 100%;
    width: ${(props) => props.boxWidthReal}px;
    // 视频布局位置
    display: flex;
    justify-content: space-evenly;
    &.video-left {
      flex-direction: row-reverse;
    }

    .imgs-container {
      height: 100%;
      width: calc(520 / 1440 * 100%);
      & > .swiper {
        height: 100%;
        width: 100%;
        & .swiper-container {
          height: 100%;
          width: 100%;
        }
      }
    }
    .videos-container {
      overflow: hidden;
      width: calc(640 / 1440 * 100%);
      height: 100%;
      overflow-y: hidden;
      .videos-swiper {
        transition: all 0.5s;
      }
      .video-text-container {
        height: ${(props) => (800 / 1440) * props.boxWidthReal}px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding: ${(props) => props.theme.getSpace(props.theme.pCards)} 0;
        .txt-container {
          // 最大高度=实际容器高度-上下边距-视频高度-底部margin
          max-height: ${(props) =>
            (800 / 1440) * props.boxWidthReal -
            props.theme.getSpace(props.theme.pCards) * 2 -
            (360 / 1440) * props.boxWidthReal -
            props.theme.getSpace(props.theme.vContainer)}px;
          overflow-y: hidden;
          margin-bottom: ${(props) => props.theme.getSpace(props.theme.vContainer)};
        }
        .video-div {
          width: 100%;
          height: ${(props) => (360 / 1440) * props.boxWidthReal}px;
          .video {
            border-radius: 16px; // 待设计出圆角规范
          }
          .background-image img {
            border-radius: 16px; // 待设计出圆角规范
          }
        }
      }
    }
    // 特殊情况处理
    // 没有视频时
    .no-video {
      .txt-container {
        max-height: 100%;
        margin-bottom: 0;
      }
    }

    // 没图片时，文字与视频左右布局
    &.no-image {
      .videos-container {
        width: 100%;
        height: 100%;
      }
      .video-text-container {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        .txt-container {
          max-height: 100%;
          width: calc(640 / 1440 * 100%);
          margin-bottom: 0;
        }
        .video-div {
          width: calc(640 / 1440 * 100%);
        }
      }
      &.video-left .video-text-container {
        flex-direction: row-reverse;
      }
    }
  }
  // 手机端特有布局
  .is-mobile {
    height: auto;
    .slide-video-pic {
      flex-direction: column;
      width: 100%;
      .imgs-container {
        width: 100%;
        // 根据容器真实宽度按比列计算高度
        height: ${(props) => props.boxWidthReal}px;
      }
      .videos-container {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        z-index: 9;
        .video-text-container {
          height: auto;
          flex-direction: column-reverse;
          .video-div {
            // 根据视频宽度计算高度
            height: ${(props) => ((props.boxWidthReal - 32) * 9) / 16}px;
          }
          .txt-container {
            margin-top: 16px;
            margin-bottom: 0;
          }
        }
      }
    }
    // 有图片，且第一个视频容器里有视频链接的，上移视频一半高度
    .has-image .first-has-video {
      // 上移视频高度的一半
      margin-top: -${(props) => ((props.boxWidthReal - 32) * 9) / 16 / 2 + 16}px;
    }
    // 特殊情况
    .no-image {
      .videos-container {
        .txt-container {
          width: 100% !important;
        }
        .video-div {
          width: 100% !important;
        }
      }
    }
  }
`;
