import styled from 'styled-components';
import { getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  .hero {
    margin: auto;
    position: relative;
    box-sizing: border-box;
    line-height: 0;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    &.attachment {
      background-attachment: fixed;
    }
  }

  /** 图片对齐方式 */
  .align-top.hero {
    background-position: top;
  }

  .align-bottom.hero {
    background-position: bottom;
  }

  .align-center.hero {
    background-position: center;
  }
  /** 图片对齐方式 */

  /** 分区高度 */
  .size-x-small {
    height: 215px;
  }

  .size-small {
    height: 300px;
  }

  .size-medium {
    height: 475px;
  }

  .size-large {
    height: 650px;
  }

  .size-x-large {
    height: 775px;
  }
  /** 分区高度 */
  .hero-desc {
    margin-top: ${(props) => props.theme.getSpace([8, 16])};
  }
  .button.primary {
    margin-top: ${(props) => props.theme.getSpace(props.theme.vTextButton)};
  }
  /** 文本大小 */
  .text-medium {
    .hero-title {
      ${(props) => props.theme.getBaseSize([16, 24])};
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
    }

    .hero-desc {
      ${(props) => props.theme.getBaseSize([14, 16])}
    }
  }

  .text-large {
    .hero-title {
      ${(props) => props.theme.getBaseSize([24, 32])}
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
    }

    .hero-desc {
      ${(props) => props.theme.getBaseSize([16, 24])}
    }
  }
  /** 文本大小 */

  .hero-img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
  .cn-margin_80 {
    .hero-img-mask {
      width: calc(100% - 160px);
      margin: 0 80px;
    }
  }
  .hero-img-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--color_image_overlay);
    opacity: var(--image_overlay_opacity);
  }

  .hero-text {
    word-break: break-word;
    position: absolute;
    color: var(--color_image_overlay_text);
    box-sizing: border-box;
  }

  .hero-desc {
    margin-top: ${(props) => props.theme.getSpace([8, 16])};
  }

  .hero-button-label {
    display: inline-block;
    margin-top: var(--base-font-24-18);
    text-align: center;
    background-color: var(--color_button);
    color: var(--color_button_text);
    line-height: 1;
  }

  .default-img-box {
    border: none;
  }

  @media screen and (min-width: 751px) {
    .show-pc {
      display: block;
    }
    .show-h5 {
      display: none;
    }
    /** 布局 */
    .layout-full-width {
      .hero-text {
        width: ${getPercentWidth(320)};
      }
    }

    .layout-fixed-width {
      padding: 60px 0;
      width: ${getPercentWidth(80)};

      .hero-text {
        width: ${getPercentWidth(240)};
      }
    }
    /** 布局 */

    .hero-title {
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
      word-break: break-word;
    }

    .hero-button-label {
      padding: 20px 32px;
    }
  }
`;
