import { useState, useEffect } from 'react';
import { goToAuthPage } from '../../helper/auth';
import fbLogin from '../../util/hook/facebook-login';
import { selfInitGoogleLogin } from '../../util/sdk/google-login';
import { LOGIN_CHANNELS } from '../../const';
import userApi from 'api/user';
import shopApi from 'api/shop';

function useThirdLogin(params) {
  const { onLoginSuccess, language, intl } = params;
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [loginChannels, setLoginChannels] = useState<Array<object>>([]);

  const { onFbLogin } = fbLogin({ onLoginSuccess, language, intl });

  const onLineLogin = (appKey) => {
    const { host } = window.location;
    const redirectUrl = `https://${host}/callback/line`;
    const searchPath = window.location.search ? `${window.location.search}&from=thridLogin` : '?from=thridLogin';
    const currentUrl = `${window.location.pathname}${searchPath}`;
    const state = encodeURIComponent(`${currentUrl},${redirectUrl}`);
    const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${appKey}&redirect_uri=${redirectUrl}&state=${state}&scope=profile%20openid%20email&nonce=09876xyz&test=23`;
    window.location.href = url;
  };

  /**
   * 第三方授权app
   */
  const onProviderLogin = (providerInfo) => {
    const referer = window.location.href;
    goToAuthPage(providerInfo.appId, referer);
  };

  useEffect(() => {
    setLoginChannels([]);
  }, []);

  const formateProviders = (providers = []) => {
    if (Array.isArray(providers)) {
      return providers.map((item) => {
        return {
          ...item,
          channelCode: LOGIN_CHANNELS.thirdApp,
        };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    // 获取新接口  支持的第三方登录
    const promises = [userApi.getSupportChannels({}), shopApi.getProviders({})];
    Promise.all(promises).then(([channels, providers]) => {
      setLoginChannels([...channels, ...formateProviders(providers)]);
      const google = channels.find((item) => item?.channelCode === LOGIN_CHANNELS.google);
      if (google) {
        setTimeout(() => {
          selfInitGoogleLogin(google?.appKey, intl, onLoginSuccess);
        }, 10);
      }
    });
  }, []);

  return {
    loginChannels,
    onLineLogin,
    onFbLogin,
    onProviderLogin,
  };
}

export default useThirdLogin;
