import styled from 'styled-components';

export const Style = styled.div`
  &.hidden-step {
    margin-top: 40px;
  }
  &.step-header-wrapper {
    display: flex;
    height: 68px;
    align-items: center;
  }
  .back {
    color: var(--color_body_text);
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;
    svg {
      fill: var(--color_body_text);
    }
  }
  .cannot-back-true {
    cursor: not-allowed;
  }
  .step {
    flex-grow: 1;
    text-align: center;
    color: #8c8c8c;
    display: flex;
    justify-content: center;
    align-items: center;
    .step-line {
      height: 1px;
      width: 80px;
      background: #6d7080;
      margin: 0 8px;
    }
    .current {
      color: var(--color_button);
    }
    .current-line {
      background: var(--color_button);
    }
  }
  @media screen and (max-width: 750px) {
    &.is-multi-page {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 16px;
    }
    &.is-single-page {
      height: 14px;
      line-height: 14px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &.hidden-step {
      margin-top: 16px;
    }
    .step-line {
      width: 38px !important;
    }
    .step {
      width: 100%;
      align-items: center;
      padding-top: 10px;
    }
  }
`;

export const QuitStyle = styled.div`
  .quit-action-wrapper {
    text-align: right;
    margin-top: 40px;
  }
`;
