// 装修共用-文本区域组件
// 可配置项：
// 文本按钮数组blocks
// [
//     {
//         type: 'text', // 类型：text｜btn
//         description: '这是文本', // 文本内容
//         textSize: 3, // 字号
//         textColor, // 字体颜色
//     },
//     {
//         type: 'btn', // 类型：text｜btn
//         btnText: '', // 按钮文本
//         btnLink: '', //按钮链接
//         btnSize: 'small', //按钮大小
//         btnType: 'primary', // 按钮样式
//         textColor: '', //文本颜色
//         bgColor: '', //文本颜色
//     },
// ];
// 文本对齐方式buttonLabelAlign，
// 是否显示文本卡片showCard，
// 卡片颜色cardColor
import React from 'react';
import cn from 'classnames';
import { Style } from './style';
import NewButton from 'components/base/new-button';
import Card from '../card';
import View from 'components/base/view';
import { formateCamelcase } from 'utils/formate';

// 可配置文字对齐方式
const TextAlign = {
  left: 'left-align',
  center: 'center-align',
  right: 'right-align',
};

const TextContainer = (props) => {
  const {
    blocks, // 文本按钮数组
    buttonLabelAlign = 'left', // 对齐方式
    showCard = false, // 是否显示卡片
    colorCard = '#fff', // 卡片颜色
    overflow = 'auto', // 内容超高的处理方案，最大高度取的100%
    className,
  } = props;

  const isEmpty = (blocks) => {
    return (
      !blocks ||
      blocks.length <= 0 ||
      blocks.every((block) => {
        if (block.type === 'text') {
          return !block.description;
        } else {
          return !block.btnText;
        }
      })
    );
  };
  let btnIsFirstRow = true; // 按钮是不是第一行来决定按钮有没有上边距
  const content = isEmpty(blocks) ? null : (
    <Style colorCard={colorCard} className={className}>
      <div className={cn('text-container', { hidden: overflow === 'hidden' }, TextAlign[buttonLabelAlign])}>
        {blocks &&
          blocks.map((block, index) => {
            if (block.type === 'text') {
              btnIsFirstRow = false;
            }
            return block.type === 'text' ? (
              <View
                key={index}
                size={block.textSize}
                className={cn('textH', { 'no-margin-top': index === 0 })}
                color={block.textColor}
                dangerouslySetInnerHTML={{
                  __html: block.description,
                }}
              />
            ) : (
              <NewButton
                key={index}
                className={cn('link', {
                  'no-margin-top': btnIsFirstRow,
                  'no-margin-left': index === 0 || blocks[index - 1].type === 'text',
                })}
                bgColor={block.bgColor}
                color={block.textColor}
                href={block.btnLink}
                size={block.btnSize}
                type={block.btnType}
              >
                {block.btnText}
              </NewButton>
            );
          })}
      </div>
    </Style>
  );
  return showCard ? <Card bgColor={colorCard}>{content}</Card> : content;
};

export const formateTextContainerProps = (blockOrder = [], blocks = {}, alignText) => {
  const textContainerProps = { blocks: [], buttonLabelAlign: alignText };
  blockOrder?.forEach((textBtnKey) => {
    let newBlock2 = blocks[textBtnKey];
    newBlock2 = formateCamelcase(newBlock2);
    const settings = newBlock2?.settings;
    // 按钮链接地址需要转换
    if (newBlock2?.type === 'btn') {
      const btnLink = settings?.btnLink || '';
      if (btnLink) {
        settings.btnLink = JSON.parse(btnLink).url;
      }
    }
    textContainerProps.blocks.push({ type: newBlock2?.type, ...newBlock2?.settings });
  });
  return textContainerProps;
};

export default TextContainer;
