import styled from 'styled-components';

export const Style = styled.div`
  .footer-menu-collapse {
    /* 隐藏title 按钮 */
    .footer-menu-collapse-title-btn {
      display: none;
    }
    .zent-collapse {
      .zent-collapse-panel__title {
        display: block;
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: auto;
        .footer-menu-collapse-title {
          ${(props) => props.theme.getHeaderFontInfo()};
          display: flex;
          align-items: center;
        }
      }
      .zent-collapse-panel__content-box {
        background-color: transparent;
        padding: 0;
        border: none;
        .zent-collapse-panel__content {
          padding: 0;
          .footer-menu-collapse-content-box {
            padding-top: 12px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    .footer-menu-collapse {
      &.mobile-device-menu-style-fold {
        .footer-menu-collapse-title-btn {
          display: block;
        }
        .footer-menu-collapse-content-box {
          padding-top: 20px;
          &.is-mobile-fold-indent-true {
            padding-left: 24px;
            padding-top: 24px !important;
            padding-bottom: 8px;
          }
        }
      }
    }
  }
`;
