import styled from 'styled-components';

export const GridLayoutStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const GridItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 960px) {
    width: ${(props) => props.pcWidth};
    margin-right: ${(props) => `${props.pGap}px`};
    margin-top: ${(props) => `${props.pTop}px`};
    margin-bottom: ${(props) => `${props.pBottom}px`};
    :nth-of-type(${(props) => `${props.pGrid}n`}) {
      margin-right: 0px;
    }
    /* first row item & in the last row */
    /* &:nth-child(${(props) => `${props.pGrid}n+1`}):nth-last-child(${(props) => `-n+${props.pGrid}`}), */
    /* all the remaining children */
    /* &:nth-child(${(props) => `${props.pGrid}n+1`}):nth-last-child(${(props) => `-n+${props.pGrid}`}) ~ & {
            margin-bottom: 0px;
        } */
    &.grid-item:last-child {
      margin-right: 0px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 960px) {
    width: ${(props) => props.padWidth};
    margin-right: ${(props) => `${props.dGap}px`};
    margin-top: ${(props) => `${props.dTop}px`};
    margin-bottom: ${(props) => `${props.dBottom}px`};
    :nth-of-type(${(props) => `${props.dGrid}n`}) {
      margin-right: 0px;
    }
    /* first row item & in the last row */
    /* &:nth-child(${(props) => `${props.dGrid}n+1`}):nth-last-child(${(props) => `-n+${props.dGrid}`}), */
    /* all the remaining children */
    /* &:nth-child(${(props) => `${props.dGrid}n+1`}):nth-last-child(${(props) => `-n+${props.dGrid}`}) ~ & {
            margin-bottom: 0px;
        } */
    &.grid-item:last-child {
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    width: ${(props) => props.mobileWidth};
    margin-right: ${(props) => `${props.mGap}px`};
    margin-top: ${(props) => `${props.mTop}px`};
    margin-bottom: ${(props) => `${props.mBottom}px`};
    :nth-of-type(${(props) => `${props.mGrid}n`}) {
      margin-right: 0px;
    }
    /* first row item & in the last row */
    /* &:nth-child(${(props) => `${props.mGrid}n+1`}):nth-last-child(${(props) => `-n+${props.mGrid}`}), */
    /* all the remaining children */
    /* &:nth-child(${(props) => `${props.mGrid}n+1`}):nth-last-child(${(props) => `-n+${props.mGrid}`}) ~ & {
            margin-bottom: 0px;
        } */
  }
`;
