import styled from 'styled-components';

export const Style = styled.div`
  .product-media-preview {
    width: 520px;
    /* width: 604px; */
    // 详细页组件样式调整
    //margin-right: 56px;
    .main-media-wrapper {
      width: 100%;
    }
    /* 缩略图垂直方式 */
    &.thumbnail-display-direction-vertical {
      display: flex;
      flex-direction: row-reverse;
      align-items: start;
    }
    .product-main-media-wrapper {
      /* width: 460px;
            margin: 0 auto; */
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 30px;
      box-sizing: border-box;
      &.hide-arrow {
        svg {
          opacity: 0;
          width: 24px;
          height: 24px;
          fill: var(--color_body_text);
        }
        &:hover {
          svg {
            opacity: 1;
          }
        }
      }
      &.product-main-arrows-true {
        /* width: auto; */
        .main-media-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          /* width: 100%; */
          /* width: 460px; */
        }
        &:hover {
          .arrow {
            /* visibility: visible; */
            display: flex;
          }
        }
        .arrow {
          z-index: 3;
          display: none;
          position: absolute;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          cursor: pointer;
          fill: #fff;
          background-color: rgba(0, 0, 0, 0.4);
          border-radius: 6px;
          &.disabled {
            opacity: 0.4;
          }
        }
        .left-arrow {
          left: 16px;
          /* margin-right: var(--pm-16-10); */
        }
        .right-arrow {
          /* margin-left: var(--pm-16-10); */
          right: 16px;
          transform: rotateY(180deg);
        }
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  /* @media screen and (max-width: 750px) { */
  /* @media screen and (max-width: 768px) { */
  @media screen and (max-width: 960px) {
    .product-media-preview {
      width: 100%;
      margin-right: 0;
      &.thumbnail-display-direction-vertical {
        display: block;
      }
      .product-main-media-wrapper {
        /* width:  */
        max-width: 100%;
      }
    }
  }
`;
