import loadable from '@loadable/component';

/**
 * @name 活动页面
 *
 */

export default [
  {
    component: loadable(() => import('../pages/activity/lucky-draw')),
    path: '/activity/lucky-draw',
    exact: true,
    hideHeader: false,
    hideFooter: false,
    isGreyBody: true,
    name: 'luckyDraw',
    layout: false,
    pageType: '抽奖活动',
  },
];
