import styled, { createGlobalStyle } from 'styled-components';
import { hexToRGBA } from 'utils/color';

export const Style = styled.div`
  display: none;
  overflow-x: auto;
  --webkit-overflow-scrolling: touch;
  max-width: 200px;
  .current-site-info {
    height: 40px;
    display: flex;
    align-items: center;
    .site-logo {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      border-radius: 2px;
    }
    .default-logo {
      border-radius: 2px;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    .site-name {
      color: ${(props) => props.colorNavText};
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media screen and (max-width: 750px) {
    display: block;
  }
`;

export const GlobalStyle = createGlobalStyle`
    .right-fold-select-popup{
        .zent-select-popup {
            border-radius: 0 !important;
            box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.08) !important;
        }
    }
    .site-switch-mobile-fold-dialog {
        padding: 0 !important;
        .zent-dialog-r-body {
            padding: 16px !important;
        }
        .popup-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .popup-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
            }
            .close-icon {
                width: 16px;
                height: 16px;
            }
        }
    }
    .list-wrapper {
        background: white;
        height: 336px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        .item-wrapper {
            display: flex;
            align-items: center;
            padding: 16px 0;
            & + .item-wrapper {
                border-top: 1px solid rgba(21, 22, 27, 0.08);
            }
            .left-content {
                flex: 1;
                width: 0;
                display: flex;
                align-items: center;
                .site-logo {
                    margin-right: 8px;
                    width: 24px;
                    height: 24px;
                    object-fit: cover;
                    margin-right: 8px;
                    flex-shrink: 0;
                }
                .default-logo {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                    flex-shrink: 0;
                }
                .site-name {
                    box-sizing: border-box;
                    width: 100%;
                    margin-right: 8px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #15161B;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .sel-icon-wrapper {
                width: 16px;
            }
        }
    }
`;
