import styled from 'styled-components';

export const Style = styled.div`
  .pick-up-overview {
    font-size: 12px;
    line-height: 18px;
    padding: 12px;
    background-color: rgba(var(--color_body_text_rgb), 0.04);
    border-radius: var(--border_radius_other);
    color: var(--color_body_text);
    display: flex;
    .location-item {
      &__left {
        flex: 1;
      }
      &__name {
        font-weight: 500;
      }
      &__detail {
        color: rgba(var(--color_body_text_rgb), 0.8);
        margin-top: 4px;
      }
      &__right {
        flex-shrink: 0;
        margin-left: 24px;
      }
      &__more {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: rgba(var(--color_body_text_rgb), 0.6);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .icon-arrow-right {
      width: 16px;
      height: 16px;
      fill: var(--color_body_text);
    }
  }
`;
