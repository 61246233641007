import styled from 'styled-components';

export const LayoutWrapper = styled.div.attrs({
  className: 'layout-wrapper-section',
})`
  max-width: ${(props) => (props.theme?.containerWidth ? `${props.theme?.containerWidth}px` : '1280px')};
  width: 100%;
  margin: 0 auto;
  flex: 1;
  @media screen and (max-width: 750px) {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }
`;
