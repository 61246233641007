import styled from 'styled-components';

export const Style = styled.div`
  position: relative;
  .feature-collection-section {
    margin-bottom: 30px;
  }
  .feature-collection-grid {
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.grid}, minmax(auto, 1fr))`};
    grid-column-gap: ${(props) => props.theme.getSpace([8, 12, 24]) || `${props.gap}px` || '0px'};
    grid-row-gap: ${(props) => props.theme.getSpace([8, 12, 24]) || `${props.gap}px` || '0px'};
  }
  /* .goods-prev-container {
        overflow: hidden;
    } */
  .goods-prev-container--strong {
    grid-column: span 2;
    grid-row: span 2;
    .feature-collection-grid-item {
      flex: 1;
      .grid-info-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        .grid-info-image {
          flex: 1;
          img {
            height: 100%;
          }
        }
      }
    }
  }
  .click-able {
    cursor: pointer;
  }

  .feature-collection-title {
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    color: var(--color_text);
    margin-bottom: 40px;
  }
  /* .feature-collection-grid-item {
        position: relative; */
  /* 开启强调产品 */
  /* &.feature-collection-grid-item--strong {
            grid-row: span 2;
            grid-column: span 2;
        } */
  /* } */

  .view-container {
    margin-top: ${(props) => props.theme.getSpace([24, 50])};
    text-align: center;
  }

  .view-all-btn {
    display: inline-block;
    width: 200px;
    cursor: pointer;
    background-color: var(--color_button);
    color: var(--color_button_text);
  }

  .collection-grid-placeholder {
    height: 0;
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 750px) {
    .feature-collection-grid {
      display: grid;
      grid-template-columns: ${(props) => `repeat(${props.mobileGrid}, minmax(auto, 1fr))`};

      .goods-prev-container--strong {
        grid-column: span 1;
        grid-row: span 1;
      }
    }
  }
`;
