import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Style } from './style';
export const Wish = ({ wishStatus, wishActionLoading, toggleToWishList, themeColor }) => {
  const intl = useIntl();
  return (
    <Style
      className={cn('wish-icon-wrapper', {
        'wish-wait': wishActionLoading,
        'wish-point': !wishActionLoading,
        'wish-status-true': !!wishStatus,
      })}
      onClick={toggleToWishList}
    >
      {wishStatus ? (
        <svg className='icon goods-detail-icon' aria-hidden='true' fill={themeColor || '#F54F29'}>
          <use xlinkHref='#iconic-shoucang1' />
        </svg>
      ) : (
        <svg className='icon goods-detail-icon' aria-hidden='true'>
          <use xlinkHref='#iconic-shoucang2' />
        </svg>
      )}
      <span className='wish-text'>
        {wishStatus
          ? intl.formatMessage({
              defaultMessage: '已加入心愿单',
              id: 'baa734f1382145168f002214553ccbb0',
            })
          : intl.formatMessage({
              defaultMessage: '加入心愿单',
              id: '2f0e4928d1ef4687bd914e4ff5991735',
            })}
      </span>
    </Style>
  );
};
