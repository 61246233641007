import React, { useState, useEffect, useContext } from 'react';
import { DiscountTag } from 'cpn/discount';
import HuntPrompt from 'cpn/hunt-prompt';
import { getDiscountDesc } from 'helper/discount';
import { ApplyDiscountStatus } from 'const/discount';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { Style } from './style';
import RootContext from '../../../context/root';

export const DiscountWrapper = (props) => {
  const { discountGoods = [], discountInfo, currency, className, isExpiredGoodsStatus } = props;
  const { language } = useContext(RootContext);
  const { multiCurrencyInfo } = window?.global_data;
  const { discountType, discountGets, applyDiscountStatus, promotionId } = props?.discountInfo;
  const intl = useIntl();
  const [desc, setDesc] = useState();

  // const isInShopCartPage = window?.location.pathname.indexOf('shopping-cart') > -1;
  useEffect(() => {
    const discountDesc = getDiscountDesc({
      discountInfo,
      currency,
      intl,
      requireType: 'calcResult',
      discountGoods, // : discountGoods.filter((item) => item?.hasSelected || !isInShopCartPage),
      language,
      multiCurrencyInfo,
    });
    setDesc(discountDesc);
  }, [discountGoods, discountInfo]);

  const onLink = () => {
    const url = `${window.location.origin}/discount/${
      discountInfo?.discountType ?? 'fix'
    }/goods?activityId=${promotionId}`;
    window.open(url);
  };
  // 1：已满足折扣，2：提示去凑单，3：提示选赠品，0：应用结果为空
  return (
    <Style className={cn(className, 'hunt-prompt-wrapper')}>
      {!isExpiredGoodsStatus && desc && (
        <HuntPrompt
          className='hunt-prompt'
          linkText={
            applyDiscountStatus === ApplyDiscountStatus.Fit || applyDiscountStatus === ApplyDiscountStatus.SelectGift
              ? intl.formatMessage({
                  id: 'go_to_shopping_more',
                  defaultMessage: '再逛逛',
                  description: '再逛逛',
                })
              : intl.formatMessage({
                  id: 'go_to_get_order',
                  defaultMessage: '去凑单',
                  description: '去凑单',
                })
          }
          onLink={onLink}
          desc={
            <div className='discount-title'>
              <DiscountTag
                className='discount-tag'
                discountType={discountType}
                discountValue={discountGets?.discountValue}
                language={language}
              />
              <span className='discount-desc'> {desc}</span>
            </div>
          }
        />
      )}
      {props.children}
    </Style>
  );
};
