import React from 'react';
import { pushToHistory } from 'utils/web-tool';
import { withRouter } from 'react-router-dom';
import Style from './style';
import { onQuitConfirm } from '../../../../../order/component/step-header/order-leave';
import { useIntl } from 'react-intl';
import { STEP_MAP } from '../../../../../order/const';
import Image from 'components/base/image';

const COMPLETE_PAGE_PATH = '/order/complete';

const HeaderLogo = ({ logoMaxWidth, logo: image, shopName, history, location, isOrderHeader = false }) => {
  const intl = useIntl();
  const urlSearchParams = new URLSearchParams(location.search);
  const orderToken = urlSearchParams.get('orderToken');
  const step = urlSearchParams.get('step');
  const isCompletePage = location?.pathname === COMPLETE_PAGE_PATH;
  const linkToIndex = () => {
    pushToHistory(history, '/');
  };
  const onClickLogo = () => {
    if (isOrderHeader && window?.global_data?.userInfo?.customerId && orderToken && !isCompletePage) {
      onQuitConfirm(intl, linkToIndex);
      return;
    }
    linkToIndex();
  };

  return (
    <Style>
      {image && (
        <Image
          onClick={onClickLogo}
          className='logo'
          style={{
            width: `${logoMaxWidth}px`,
          }}
          src={image}
          alt={shopName}
        />
      )}
      {!image && (
        <span onClick={onClickLogo} className='shop-name'>
          {shopName}
        </span>
      )}
    </Style>
  );
};

export default withRouter(HeaderLogo);
