import styled from 'styled-components';

export default styled.div`
  .collection-pager {
    text-align: ${(props) => props.align};
    margin: 40px 0;
    .collection-pager-style {
      display: inline-block;
    }
    /* 样式一 */
    .collection-pager-style-1 {
      .zent-pagination-page-list--normal {
        button:first-child {
          margin-left: 0 !important;
        }
      }
      .zent-btn {
        color: ${(props) => props.theme.colorBodyTexts['40']} !important;
        &.zent-pagination-arrow-button {
          color: ${(props) => props.theme.colorBodyText} !important;
          svg {
            stroke: ${(props) => props.theme.colorBodyText} !important;
          }
        }
        &.zent-pagination-arrow-button[disabled] {
          color: ${(props) => props.theme.colorBodyTexts['20']} !important;
          svg {
            stroke: ${(props) => props.theme.colorBodyTexts['20']} !important;
          }
        }
        &.zent-btn-primary {
          /* color: ${(props) => props.theme.colorButton} !important; */
          color: var(--color_button) !important;
        }
      }
    }
    /* 样式二 */
    .collection-pager-style-2 {
      .style-2-icon {
        cursor: pointer;
        &.style-2-icon-disabled {
          fill: ${(props) => props.theme.colorBodyTexts['20']};
          cursor: not-allowed;
        }
      }
      svg {
        width: 24px;
        height: 24px;
        fill: ${(props) => props.theme.colorBodyText};
        &:disabled {
          fill: #ddd;
        }
      }
      svg + svg {
        margin-left: 22px;
      }
    }
    /* 样式三 */
    .collection-pager-style-3 {
      .collection-pager-style-3-body {
        display: flex;
        .style-3-icon {
          width: 24px;
          height: 24px;
          border: 1px solid ${(props) => props.theme.colorBodyTexts['20']};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.style-3-icon-disabled {
            cursor: not-allowed;
            svg {
              fill: ${(props) => props.theme.colorBodyTexts['20']};
            }
          }
          svg {
            width: 18px;
            height: 18px;
            fill: ${(props) => props.theme.colorBodyText};
          }
        }
        .style-3-icon + .style-3-icon {
          margin-left: 16px;
        }
      }
    }
  }
`;
