import styled, { css } from 'styled-components';

const getLayoutCSS = (props) => {
  const { layout } = props;
  if (layout === 'vertical') {
    return css`
      display: flex;
      padding-top: 24px;
    `;
  }
};

/**
 * 宽度
 * 每行数量
 * 图片之间的间距
 */
const getContainerWidthCSS = (props) => {
  const { grid = 4, picPadding = 10 } = props;
  const gridTemplateColumns = `repeat(${grid}, 1fr)`;
  // 图片之间的间距 指左右的间距 上下固定40px

  return css`
    grid-template-columns: ${gridTemplateColumns};
    grid-column-gap: ${props.theme.getSpace([picPadding / 2, picPadding])};
    grid-row-gap: ${props.theme.getSpace([16, 24, 40])};
  `;
};

const getWidthCSS = (props) => {
  const { containerWidth } = props;
  return css`
    width: ${containerWidth === 'full_screen' ? '100%' : `${containerWidth}px`};
    margin: 0 auto;
    max-width: 100%;
  `;
};

/**
 * 图片填充方式
 *
 * */
// const getCoverPicRatioCSS = (props) => {
//     const { coverPicRatio } = props;

//     const cssMap = {
//         // 原比例
//         auto: css`
//             background-size: initial;
//             background-repeat: no-repeat;
//         `,
//         // 自适应
//         adaptive: css`
//             background-size: contain;
//             background-repeat: no-repeat;
//         `,
//         // 裁剪
//         crop: css`
//             background-size: cover;
//             background-repeat: no-repeat;
//         `,
//         // 平铺
//         tiled: css`
//             background-size: initial;
//             background-repeat: repeat;
//         `,
//     };
//     return cssMap[coverPicRatio];
// };

// const getBgStyle = (props) => {
//     const { backgroundStyle, backgroundColor, backgroundImage } = props;
//     if (backgroundStyle === 'solid_color') {
//         return css`
//             background: ${backgroundColor || props.theme.colorBodyBg};
//         `;
//     } else {
//         return css`
//             background-image: url(${backgroundImage});
//             ${(props) => getCoverPicRatioCSS(props)};
//         `;
//     }
// };

export const Style = styled.div`
  width: 100%;
  /* ${(props) => getBgStyle(props)}; */
  padding: ${(props) =>
    props.containerWidth === 'full_screen'
      ? '0'
      : `${props.theme.getSpace([16, 24, 40])} ${props.theme.getSpace([16, 80])}`};
  box-sizing: border-box;
  @media screen and (min-width: 750px) {
    .goods-list {
      ${(props) => getWidthCSS(props)};
    }
  }

  flex-wrap: wrap;
  .goods-list {
    display: grid;
    /* 获取宽度 每行数量 图片之间的间距 样式*/
    ${(props) => getContainerWidthCSS(props)};
  }
`;
