import styled from 'styled-components';

export default styled.section`
  /* position: absolute; */
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .add-shopping-car-container {
    width: 720px;
    overflow: auto;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 8%);
    background-color: var(--color_card, #fff);
    border-radius: var(--border_radius_card);
  }
  @media screen and (max-width: 750px) {
    .add-shopping-car-container {
      width: 720px;
      overflow: auto;
      align-self: flex-end;
    }
  }
`;
