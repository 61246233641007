import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    .pop-list-dialog {
        padding: 0 !important;
        background: none!important;
        min-width: 327px!important;
        .zent-dialog-r-body {
            padding: 0 !important;
        }
    }
`;
