import React from 'react';
import HtCollapse from 'components/standard/collapse';
import { GoodsMetafiledStyle } from './style';

export const GoodsMetafiled = ({ goodsMetafields = [] }) => {
  const goodsMetafieldsFilter = goodsMetafields.filter(({ value }) => value);
  return goodsMetafields.length > 0 ? (
    <GoodsMetafiledStyle>
      <HtCollapse itemsData={goodsMetafieldsFilter} />
    </GoodsMetafiledStyle>
  ) : null;
};
