import styled from 'styled-components';

export const HtFormSelectWrapper = styled.div`
  .ht-form-select {
    .zent-select-text {
      padding: ${(props) => props.theme.getSpace([12, 14, 16])} 25px ${(props) => props.theme.getSpace([12, 14, 16])}
        12px;
    }
    &.zent-form-control {
      margin-bottom: 16px;
    }
    .zent-select--visible {
      border-color: var(--color_button) !important;
    }
    &.has-error {
      .zent-select-text {
        border-color: #df4545 !important;
      }
    }
  }
`;
