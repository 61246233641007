import styled, { createGlobalStyle } from 'styled-components';
export default styled.div``;
export const GlobalStyle = createGlobalStyle`
    .new-popover-popup-wrapper{
        padding: 8px;
        background-color: var(--color_card, #fff);
        z-index:1000;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        border-radius: 4px !important;   
        position: relative;
        
        &::before{
            content: '';
            position: absolute;
            right: 16px;
            top: -6px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid var(--color_card, #fff);
        }
        &.zent-popover-position-bottom-right::before{
            right: 16px;
            top: -6px;
        }
        &.zent-popover-position-bottom-center::before{
            left: 50%;
            transform: translateX(-50%);
            top: -6px;
        }
        &.zent-popover-position-bottom-left::before{
            left: 16px;
            top: -6px;
        }
        &.zent-popover-position-top-right::before{
            border-top: 6px solid #fff;
            border-bottom:none;
            right: 16px;
            top: 100%;
        }
        &.zent-popover-position-top-center::before{
            border-top: 6px solid #fff;
            border-bottom:none;
            left: 50%;
            transform: translateX(-50%);
            top: 100%;
        }
        &.zent-popover-position-top-left::before{
            border-top: 6px solid #fff;
            border-bottom:none;
            left: 16px;
            top: 100%;
        }
        &.zent-popover-position-left-top::before{
            left: 100%;
            top: 16px;
            border-left: 6px solid #fff;
            border-right: none;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
        }
        &.zent-popover-position-left-center::before{
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            border-left: 6px solid #fff;
            border-right: none;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
        }
        &.zent-popover-position-left-bottom::before{
            left: 100%;
            bottom: 16px;
            top:unset;
            border-left: 6px solid #fff;
            border-right: none;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
        }
        &.zent-popover-position-right-top::before{
            right: 100%;
            top: 16px;
            left: unset;
            bottom: unset;
            border-right: 6px solid #fff;
            border-left: none;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
        }
        &.zent-popover-position-right-center::before{
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            left: unset;
            bottom: unset;
            border-right: 6px solid #fff;
            border-left: none;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
        }
        &.zent-popover-position-right-bottom::before{
            right: 100%;
            bottom: 16px;
            left: unset;
            top: unset;
            border-right: 6px solid #fff;
            border-left: none;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
        }
    }
`;
