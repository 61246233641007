import styled from 'styled-components';

export const Style = styled.div`
  display: flex;
  flex-wrap: wrap;

  .file-input {
    display: none;
  }

  .image-upload-trigger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color_body_text);
    margin-bottom: 10px;
  }

  .upload-image-list {
    display: flex;
    flex-wrap: wrap;

    + .image-upload-trigger {
      margin-left: 10px;
    }
  }

  .image-upload-trigger,
  .review-image-item {
    width: 90px;
    height: 90px;
    background-color: rgba(var(--color_body_text_rgb), 0.04);
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .review-image-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;

    .image-icon-close {
      cursor: pointer;
      position: absolute;
      right: -14px;
      top: -16px;
      padding: 6px;
      font-size: 16px;
      color: rgba(21, 22, 27, 0.5);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .image-trigger-text {
    display: inline-flex;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  @media screen and (min-width: 769px) {
    .mobile-review-image {
      display: none !important;
    }
  }

  @media screen and (max-width: 768px) {
    .pc-review-image {
      display: none !important;
    }
  }
`;
