import React from 'react';
import { Popover } from 'zent';
import Style, { GlobalStyle } from './style';
import cn from 'classnames';

function NewPopover(props) {
  return (
    <Style>
      <GlobalStyle />
      <Popover {...props} className={cn('new-popover-popup-wrapper', props.className)}>
        {props.children}
      </Popover>
    </Style>
  );
}
NewPopover.Trigger = Popover.Trigger;
NewPopover.Content = Popover.Content;
NewPopover.Position = Popover.Position;

export default NewPopover;
