import styled, { createGlobalStyle } from 'styled-components';
import { hexToRGBA } from 'utils/color';

export const Style = styled.div`
  &.fixed-top {
    position: fixed;
    height: 40px;
    top: 0;
    width: 100%;
    z-index: 1111;
  }
  overflow-x: auto;
  --webkit-overflow-scrolling: touch;
  .top-nav-site {
    background: ${(props) => props.colorNavBackground};
    height: 40px;
    .container-size {
      height: 40px;
    }
    .expand-site-list {
      width: 100%;
      height: 100%;
      height: 40px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      display: flex;
      align-items: center;
      .shop-item {
        max-width: 200px;
        box-sizing: border-box;
        padding: 0 12px;
        height: 100%;
        line-height: 100%;
        display: flex;
        align-items: center;
        .shop-logo {
          height: 16px;
          width: auto;
        }
        .default-logo {
          width: 16px;
          height: 16px;
          display: inline-block;
        }
        .shop-name {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.5px;
          color: ${(props) => props.colorNavText};
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .logo-item + .shop-name {
          margin-left: 8px;
        }
      }
      .shop-item.active {
        background: ${(props) => props.colorNavSelBackground};
        .shop-name {
          color: ${(props) => props.colorNavSelText};
        }
      }
      .shop-item:not(.active) {
        &:hover {
          background: ${(props) => hexToRGBA(props.colorNavSelBackground, 0.1)};
        }
      }
    }
    .right-fold-select-wrapper {
      display: flex;
      justify-content: flex-end;
      .zent-select {
        height: 40px;
        line-height: 40px;
        .item-wrapper {
          justify-content: flex-end;
          max-width: 140px;
          .shop-name {
            flex: initial;
            max-width: 100%;
            width: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .zent-select-text {
        display: flex;
        justify-content: flex-end;
        border: 0 !important;
        background: transparent !important;
        color: ${(props) => props.colorNavText} !important;
        .item-wrapper {
          .shop-name {
            color: ${(props) => props.colorNavText} !important;
          }
        }
        box-shadow: none !important;
        .zent-select-placeholder {
          color: ${(props) => props.colorNavText} !important;
        }
        .item-wrapper {
          svg {
            fill: ${(props) => props.colorNavText} !important;
            width: 16px;
            height: 16px;
            flex-shrink: 0;
          }
          .shop-name {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    .multi-select-container {
      display: none !important;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
    .right-fold-select-popup {
        z-index: var(--zindex-popup) !important;
        margin-top: -2px !important;
        box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.08);
        .zent-select-popup {
            border-radius: 0 !important;
            box-shadow: 0 !important;
        }
    }
`;
