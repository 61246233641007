import styled, { css } from 'styled-components';

const POSITION_SPACE = 16;
const MOBILE_POSITION_SPACE = 8;

const getPositionCSS = (props) => {
  const { containerWidth, grid, textDisplay, textPosition = 'right top' } = props;

  let newPositionSpace = POSITION_SPACE;
  // 宽 960 且 5列 间距 改为 距离修改为 8
  if (+containerWidth === 960 && +grid === 5) {
    newPositionSpace = 8;
  }
  const textSpace = props.theme.getSpace([MOBILE_POSITION_SPACE, newPositionSpace]);
  // 显示在图片下面
  if (textDisplay === 'under_pic') {
    return css`
      position: absolute;
      right: ${textSpace};
      top: ${textSpace};
    `;
  } else if (textDisplay === 'superimposed') {
    const textPositionMap = {
      left_top: css`
        top: ${textSpace};
        right: ${textSpace};
      `,
      right_top: css`
        top: ${textSpace};
        left: ${textSpace};
      `,
      left_bottom: css`
        bottom: ${textSpace};
        right: ${textSpace};
      `,
      right_bottom: css`
        bottom: ${textSpace};
        left: ${textSpace};
      `,
      center_center: css`
        top: ${textSpace};
        right: ${textSpace};
      `,
    };
    const textPositionStr = textPositionMap[textPosition.replace(' ', '_')];

    if (!textPosition) {
      return;
    }

    return css`
      position: absolute;
      ${textPositionStr};
    `;
  }
};

// 添加心愿单按钮
export const Style = styled.div`
  /* 心愿单按钮位置 */
  ${(props) => getPositionCSS(props)}
  .add-wish-btn,
    .add-cart-btn {
    background-color: #fff;
    margin-top: 8px;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.04);
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    .wish-icon,
    .cart-icon {
      width: 16px;
      height: 16px;
      &.wish-icon-active {
        fill: var(--color_button);
      }
    }
  }
  .add-wish-btn:first-child,
  .add-cart-btn:first-child {
    margin-top: 0;
  }

  .add-cart-disabled {
    opacity: 0.6;
  }
  @media screen and (min-width: 960px) {
    .add-wish-btn,
    .add-cart-btn {
      width: 32px;
      height: 32px;
      border-radius: 6px;
      .wish-icon,
      .cart-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 960px) {
    .add-wish-btn,
    .add-cart-btn {
      width: 28px;
      height: 28px;
      border-radius: 5px;
      .wish-icon,
      .cart-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .add-wish-btn,
    .add-cart-btn {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      .wish-icon,
      .cart-icon {
        width: 14px;
        height: 14px;
      }
    }
  }
`;
