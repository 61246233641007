import styled from 'styled-components';

export const Style = styled.div`
  .paypal-wrapper {
    display: flex;
  }
  .other-pay-method {
    max-width: 46vw;
    margin-top: 16px;
    color: #041b4a;
    text-decoration-line: underline;
    text-align: center;
    cursor: pointer;
  }
  #paypal-button-container {
    display: flex;
    .pay-btn {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
    }
    .inside-buy {
      width: 32px;
      height: 44px;
      background-color: #ffc439;
      box-shadow: inset 1px 0px 0px rgba(255, 255, 255, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      cursor: pointer;
      .is-expand {
        transform: rotate(180deg);
      }
    }
  }
  .third-buy-link {
    width: 243px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .img-container {
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 46px;
        max-width: 46px;
      }
    }
    .text {
      color: #fff;
      margin-left: 8px;
      white-space: nowrap;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
    }
  }
  .sold-out-button {
    width: 100%;
  }

  /* .goods-buy-button-2 {
        line-height: 44px !important;
        height: 44px !important;
    } */
`;

export const OtherStyle = styled.div`
  padding: 16px;
  height: 20px;
  width: 276px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.08);
  border-radius: 2px;
  max-width: 40vw;
  cursor: pointer;
`;
