// 轮播图
/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import React, { useState, useEffect } from 'react';
import SwiperCore, { Navigation, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import NewLink from 'cpn/new-link';
import { DefaultImg } from '../../../components/default-img';
import { getDesignLocalText } from '../../../../util/i18n';
import useSlide from '../../hooks/slide';
import { Style } from './style';
import SwiperIndicatorArrow from '../../components/swiper-indicator-arrow';
import { useThemeContext } from '../../../../hook/global-theme';
import { NewButton, View } from 'components/base';
import { useTextSize } from './hook';
import Image from 'components/base/image';

SwiperCore.use([Navigation, Scrollbar, A11y, Autoplay]);

const dealTextAlign = (str) => {
  const align = str?.split(' ');
  const [horizontal, vertical] = align;
  return `text-${horizontal}-${vertical}`;
};

const filterBlockOrder = (blockOrder = [], blocks = {}) => {
  let newBlockOrder = [];
  const exited = Object.keys(blocks);
  newBlockOrder = blockOrder.filter((key) => {
    return exited.includes(key);
  });
  return newBlockOrder;
};
const RenderSlider = ({ blockOrder = [], blocks = {}, showOverlay = true, oldTextAlignment, textSizeMap = {} }) => {
  // 这里装修有时候会出bug blockOrder里面有 blocks里面没有  需要过滤
  const realBlockOrder = filterBlockOrder(blockOrder, blocks);
  return realBlockOrder.map((key) => {
    const settings = blocks[key]?.settings || {};
    const {
      image,
      slide_title: slideTitle,
      subheading,
      alignment = 'center center',
      compress,
      text_alignment: textAlignment = oldTextAlignment || 'center center',
      button_label: buttonLabel,
      button_link: buttonLink,
      button_link_second: buttonLinkSecond,
      button_label_second: buttonLabelSecond,
      title_color: titleColor = '#fffff',
      desc_color: descColor = '#fffff',
      pic_link: picLink = '',
    } = settings;
    const linkUrl = JSON.parse(buttonLink || null)?.url;
    const linkUrlSecond = JSON.parse(buttonLinkSecond || null)?.url;
    const alignmentClass = `img-${alignment.split(' ').join('-')}`;
    const noSwipingClass = realBlockOrder.length <= 1 ? 'swiper-no-swiping' : '';
    const alignClass = dealTextAlign(textAlignment);
    const slideTxt = slideTitle ? getDesignLocalText(slideTitle) : '';
    const imageElem = (() => {
      const imageComp = image ? (
        <Image
          className={cn('slide-item-img', alignmentClass)}
          compress={compress}
          src={image}
          alt={slideTxt || buttonLabel}
        />
      ) : (
        <DefaultImg customName='slide-item-img' width='auto' height='41%' alt='goods-image' />
      );
      return (
        <NewLink href={picLink} target='_self'>
          {imageComp}
          {showOverlay ? <div className='slide-img-mask' /> : null}
        </NewLink>
      );
    })();

    return (
      <div className={cn('slide-item', noSwipingClass, { 'cn-link-trigger': picLink }, alignClass)}>
        <div className='slide-item-img'>{imageElem}</div>
        {(slideTitle || subheading || buttonLabel) && (
          <div className='slide-item-text'>
            {slideTitle && (
              <View as='h3' size={textSizeMap.title} className='slide-item-title' style={{ color: titleColor }}>
                {slideTxt}
              </View>
            )}
            {subheading && (
              <View className='slide-item-desc' size={textSizeMap.desc} style={{ color: descColor }}>
                {getDesignLocalText(subheading)}
              </View>
            )}
            {(buttonLabel || buttonLabelSecond) && (
              <div className='slide-item-btn-wrapper'>
                {buttonLabel && (
                  <NewButton size='large' type='primary' href={linkUrl} className='button-item'>
                    {buttonLabel}
                  </NewButton>
                )}
                {buttonLabelSecond && (
                  <NewButton className='second-btn button-item' size='large' href={linkUrlSecond}>
                    {buttonLabelSecond}
                  </NewButton>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  });
};

const renderAd = ({ adTitle, colorAd, colorAdBg }) => {
  return (
    <View size={11} className='swiper-add' style={{ color: colorAd, backgroundColor: colorAdBg }}>
      {adTitle}
    </View>
  );
};

const SlideShow = (props) => {
  const { settings = {}, block_order: blockOrder = [], blocks, elemClass } = props;

  const {
    slideshow_height: slideshowHeight = 'medium',
    text_size: textSize = 'medium',
    autorotate_speed: autoRotateSpeed = 3,
    autorotate: autoRotate = false,
    show_overlay: showOverlay,
    text_alignment: oldTextAlignment,
    slideshow_paginator_style: slideshowPaginatorStyle = 'default',
    show_ad: showAd = false,
    ad_title: adTitle,
    color_ad: colorAd,
    color_ad_bg: colorAdBg,
    box_width: boxWidth = 'full_width',
  } = settings;

  const { textSizeMap } = useTextSize(settings);
  const themContext = useThemeContext();
  const { sliderRef, sliderWrapRef, slideImageHeight, textSizeClass } = useSlide({
    blockOrder,
    blocks,
    slideshowHeight,
    settings,
    textSize,
    eventName: 'slide-show',
    isMobile: themContext.isMobile,
  });

  const initialSlideList = RenderSlider({
    blockOrder,
    blocks,
    showOverlay,
    oldTextAlignment,
    textSizeMap,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paused, setPauseStatus] = useState(autoRotate);
  const [status, setSwiperStatus] = useState('initial');
  const [autoPlayParams, setAutoPlayParams] = useState({});
  const [showSwiper, setShowSwiper] = useState(true);
  const [slideList, setSlideList] = useState(initialSlideList);

  const onNextClick = () => {
    sliderRef?.current?.swiper?.slideNext();
  };

  const onPrevClick = () => {
    sliderRef?.current?.swiper?.slidePrev();
  };

  const onPlayClick = () => {
    sliderRef?.current?.swiper?.autoplay?.start();
    setPauseStatus(false);
  };

  const onPauseClick = () => {
    sliderRef?.current?.swiper?.autoplay?.stop();
    setPauseStatus(true);
  };

  const slideToIndex = (index) => {
    sliderRef?.current?.swiper?.slideTo(index);
  };

  const renderPauseBtn = (type) => {
    const pauseBtnClass = `control-btn-${type}`;

    return (
      <div className={cn('pause-control-btn', pauseBtnClass)}>
        {paused ? (
          <span onClick={onPlayClick}>
            <svg className='icon conic-mima' width='16' height='16' aria-hidden='true'>
              <use xlinkHref='#iconzanting' />
            </svg>
          </span>
        ) : (
          <span onClick={onPauseClick}>
            <svg className='icon conic-mima' width='16' height='16' aria-hidden='true'>
              <use xlinkHref='#iconbofang' />
            </svg>
          </span>
        )}
      </div>
    );
  };

  /** 是否播放选项 */
  useEffect(() => {
    const autoplay = { delay: autoRotateSpeed * 1000 };

    if (autoRotate && slideList.length > 1) {
      setAutoPlayParams({ autoplay });
      sliderRef?.current?.swiper?.autoplay?.start();
    } else {
      setAutoPlayParams({});
      sliderRef?.current?.swiper?.autoplay?.stop();
    }
  }, [autoRotateSpeed, autoRotate, slideList]);

  /** 播放间隔选项 */
  useEffect(() => {
    setShowSwiper(false);
    setTimeout(() => {
      setShowSwiper(true);
    });
  }, [autoRotateSpeed]);

  /** 图片选项 */
  useEffect(() => {
    const currentList = RenderSlider({
      blockOrder,
      blocks,
      showOverlay,
      textSizeMap,
    });
    setSlideList(currentList);
  }, [blockOrder, blocks, textSizeMap]);

  /** 幻灯片自动播放配置 */
  useEffect(() => {
    setPauseStatus(autoRotate);
  }, [autoRotate]);

  return (
    <Style
      className={cn('slide-show-section no-margin', elemClass, `cn-${boxWidth}`)}
      slideImageHeight={slideImageHeight}
    >
      <div ref={sliderWrapRef} className={cn('slide-show', status, textSizeClass)}>
        {showSwiper ? (
          <Swiper
            key={boxWidth}
            loop
            {...autoPlayParams}
            ref={sliderRef}
            noSwiping
            pagination={false}
            onSwiper={() => {
              setSwiperStatus('loaded');
            }}
            onSlideChange={(swiper) => {
              const { realIndex } = swiper;
              setCurrentIndex(realIndex);
            }}
            // navigation
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
          >
            {slideList.map((Slide, index) => (
              <SwiperSlide key={index}>{Slide}</SwiperSlide>
            ))}
          </Swiper>
        ) : null}

        {/* {renderPauseBtn(PAUSE_BTN_TYPE.H5)} */}
        {slideList.length > 1 && (
          <SwiperIndicatorArrow
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            type={slideshowPaginatorStyle}
            isPc={themContext.isPc}
            total={slideList.length}
            showAd={showAd}
            currentIndex={currentIndex}
          />
        )}
      </div>
      {showAd && renderAd({ adTitle, colorAd, colorAdBg })}
    </Style>
  );
};

export default SlideShow;
