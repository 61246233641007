import styled from 'styled-components';
// import { getSizeVw, getPercentWidth } from '../../../style/fn';

export default styled.div`
  position: relative;
  /* overflow: hidden; */
  @media screen and (max-width: 750px) {
    width: 100%;
  }
  .price {
    font-size: var(--base-font-16-14);
    color: var(--color_sale_text);
  }
  @keyframes init {
    from {
      bottom: -300px;
    }
    to {
      bottom: 0;
    }
  }
  .animate-sku {
    animation: init 0.5s;
  }
  .sku-container {
    background: var(--color_card, #fff);
    position: relative;
    padding: 0 var(--pm-16-10) var(--pm-16-10);
    box-shadow: 0px 2px 24px 0px rgba(209, 213, 219, 0.4);
    border-radius: var(--border_radius_card);
  }

  .sku-close-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sku-block {
    cursor: pointer;
    padding-top: var(--pm-16-10);
  }

  .close-buy-now-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    fill: var(--color_body_text);
  }
`;

export const PriceStyle = styled.div`
  .max-price,
  .cur-price {
    .price-symbol,
    .price-integer,
    .price-decimal {
      color: var(--color_sale_text) !important;
    }
  }
`;
