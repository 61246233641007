import React, { useEffect, useMemo } from 'react';
import { Form, FormInputField, Validators } from 'zent';
import cn from 'classnames';
import pick from 'lodash/pick';
import Style from './style';

const BankDetail = ({ intl, form, disabled = false, distributorInfo = {}, mr = 'mr-24' }) => {
  const paymentMethodValue = Form.useFieldValue(form.model.get('paymentMethod'));
  const BANK_TYPE = 'debit.card';
  const isShowBankDetailInfo = paymentMethodValue === BANK_TYPE;
  const openBankExtendStatus = useMemo(() => {
    return paymentMethodValue === BANK_TYPE && !distributorInfo?.extend?.bankBranch;
  }, [paymentMethodValue, distributorInfo]);

  useEffect(() => {
    if (isShowBankDetailInfo) {
      form.patchValue(pick(distributorInfo, ['extend']).extend);
    }
  }, [paymentMethodValue]);
  return (
    <Style>
      {isShowBankDetailInfo && (
        <>
          <div className={cn('input-container', { hidden: !isShowBankDetailInfo })}>
            <div className={`name-input ${mr}`}>
              <p className='label-text'>
                {intl.formatMessage({
                  id: 'c7fe206c3e5a434c8b47bfc1486617cf',
                  defaultMessage: `银行名称`,
                })}
              </p>
              <FormInputField
                destroyOnUnmount
                name='bank'
                validators={
                  isShowBankDetailInfo
                    ? [
                        Validators.required(
                          intl.formatMessage({
                            id: 'd4d3dfc18b6b4c4d93cf6862e973b19f',
                            defaultMessage: `请输入银行名称`,
                          }),
                        ),
                      ]
                    : []
                }
                props={{
                  disabled: !openBankExtendStatus && disabled,
                  placeholder: intl.formatMessage({
                    id: 'd4d3dfc18b6b4c4d93cf6862e973b19f',
                    defaultMessage: '请输入银行名称',
                  }),
                }}
              />
            </div>
            <div className='name-input'>
              <p className='label-text'>
                {intl.formatMessage({
                  id: '2d97910bb5444c6f85f908312aac90a0',
                  defaultMessage: `支行信息`,
                })}
              </p>
              <FormInputField
                destroyOnUnmount
                name='bankBranch'
                validators={
                  isShowBankDetailInfo
                    ? [
                        Validators.required(
                          intl.formatMessage({
                            id: '6c4d0f6ddef4431dad10546e295d6094',
                            defaultMessage: '请输入支行信息',
                          }),
                        ),
                      ]
                    : []
                }
                props={{
                  disabled: !openBankExtendStatus && disabled,
                  placeholder: intl.formatMessage({
                    id: '6c4d0f6ddef4431dad10546e295d6094',
                    defaultMessage: '请输入支行信息',
                  }),
                }}
              />
            </div>
          </div>
          <div className={cn('mb-20', { hidden: !isShowBankDetailInfo })}>
            <div className='name-input  mb-20'>
              <p className='label-text'>
                {intl.formatMessage({
                  id: 'ad2838adc1b74c4b8dfbc1bec877c26d',
                  defaultMessage: `存款类型`,
                })}
              </p>
              <FormInputField
                destroyOnUnmount
                name='bankDepositType'
                props={{
                  disabled: !openBankExtendStatus && disabled,
                  placeholder: intl.formatMessage({
                    id: '778c7887dd134db08ea573c16f2dbced',
                    defaultMessage: '请输入存款类型',
                  }),
                }}
              />
            </div>
          </div>
        </>
      )}
    </Style>
  );
};
export default BankDetail;
