export enum ShareListType {
  Facebook = 'FACEBOOK',
  Messenger = 'MESSENGER',
  WeChat = 'WECHAT',
  WhatsApp = 'WHATS_APP',
  Twitter = 'TWITTER',
  Pinterest = 'PINTEREST',
  Line = 'LINE',
  QrCode = 'QRCODE',
  CopyLink = 'COPY_LINK',
}

export const shareUseAgent = {
  Facebook: 'facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)',
  Messenger: 'facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)',
  Twitter: 'Twitterbot/1.0',
  Line: 'facebookexternalhit/1.1;line-poker/1.0',
  Pinterest: 'Mozilla/5.0 (compatible; Pinterestbot/1.0; +http://www.pinterest.com/bot.html)',
};
