// 订阅
import React, { useState } from 'react';
import { Input, Button, Notify } from 'zent';
import { useIntl } from 'react-intl';
import { intlMessage as $t } from '../../../../util/intl';
import StoreApi from '../../../../api/store';
import { Style } from './style';
import { hexToRGB } from 'utils/color';
import cn from 'classnames';
import Image from 'components/base/image';
import { buriedPoint } from 'utils/log-buried-point';

const Newsletter = ({ settings = {}, style, initialData, socialLinkArrFooter, elemClass }) => {
  const {
    btn_title: btnTitle = '',
    input_style: inputStyle = 'style_2',
    text = '',
    title = '',
    image = '', // 背景图
    bg_color = '#fff',
    button_tips: buttonTips,
    show_social_media_icon: showSocialMediaIcon = false,
    social_media_icon_color: socialMediaIconColor,
    box_width: boxWidth,
    alignment,
  } = settings;

  const bgColorRgb = `rgba(${hexToRGB(bg_color)}, 1)`;
  const intl = useIntl();

  const { kdtId } = initialData;
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const subscribe = () => {
    if (!value || value.indexOf('@') === -1) {
      Notify.error($t(intl, 'pleaseInputEmailByValid'));
      return;
    }
    setIsLoading(true);
    StoreApi.subscribe({
      email: value,
      kdtId,
    })
      .then((res) => {
        setIsLoading(false);
        buriedPoint('Subscribe');
        Notify.success(res?.message || 'Successful.');
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Style
      className={cn(`cn-${alignment}`, `cn-${boxWidth}`, boxWidth === 'full_screen' ? 'no-margin' : '', elemClass)}
      bgColor={hexToRGB(bg_color)}
    >
      <div className={cn('newsletter-section', `cn-${boxWidth}`)} style={{ background: bgColorRgb }}>
        <div className='newsletter__inner'>
          {image && (
            <div className='newsletter__image-container'>
              <Image src={image} alt={title || btnTitle || text || 'news-letter'} />
            </div>
          )}
          <div className='newsletter__cta'>
            <h3 className='footer-news-title'>{title}</h3>
            {text && (
              <div className='footer-news-text' style={style}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            )}
            {showSocialMediaIcon && !!socialLinkArrFooter?.length && (
              <div className='social-media-icon'>
                {socialLinkArrFooter.map((item, index) => {
                  const { icon, link } = item;
                  return (
                    <a className='footer-social-icon-link' href={link} key={index}>
                      <svg
                        className='footer-social-icon'
                        aria-hidden='true'
                        height='20px'
                        width='20px'
                        fill={socialMediaIconColor}
                      >
                        <use xlinkHref={`#${icon}`} />
                      </svg>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
          <div className={`newsletter__form input-style-wrapper input-style-${inputStyle}`}>
            <Input
              size='large'
              className='footer-news-email-input'
              placeholder={buttonTips}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            <Button
              className='footer-news-email-btn'
              loading={isLoading}
              type='primary'
              onClick={subscribe}
              title={buttonTips}
            >
              {btnTitle || 'SUBSCRIBE'}
            </Button>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default Newsletter;
