import styled from 'styled-components';

export const Style = styled.div`
  .discount-title {
    display: flex;
    align-items: center;
    align-items: flex-start;
    /* color: #333333; */
    color: var(--color_body_text);
    .discount-desc {
      margin-left: 8px;
      flex: 1;
      font-weight: 400;
    }
  }
  .discount-desc,
  .link-text,
  .discount-tag-icon {
    font-size: 14px;
  }

  .hunt-prompt {
    background: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    align-items: flex-start;
  }
`;
