import React, { useEffect, useState, useContext } from 'react';
import { CopyButton, Notify } from 'zent';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import distributionApi from '../../../api/distribution';
// import { pushToHistory } from '../../../util/web-tool';
import { getDistributionCookies } from '../../../helper/affiliate';
import { getDiscountDesc } from './util';
import { DISTRIBUTION_TAG, AFFILIATE_DISCOUNT_COPY } from '../../../const/distribution';
import RootContext from '../../context/root';
import Style from './style';

export const DistributionCoupon = (props) => {
  const { goodsId, customerId = '', currency } = props;
  const { language } = useContext(RootContext);
  const [couponDialogStatus, setCouponDialogStatus] = useState(false);
  const [discountsInfo, setDiscountsInfo] = useState({});
  const [distributionCouponStatus, setDistributionCouponStatus] = useState(false);
  const [discountDesc, setDiscountDesc] = useState('');
  const intl = useIntl();

  // 获取分销折扣信息。显示弹窗
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isDistributionCoupon = Number(urlParams.get(DISTRIBUTION_TAG)) === 1;
    const metafields = getDistributionCookies() || {};
    const fields = get(metafields, 'cookies.fields', []);
    const { value } = fields.find((item) => item?.key === 'atPs') || {};
    setDistributionCouponStatus(isDistributionCoupon);
    distributionApi
      .getAffiliateDiscount({
        atPs: value,
        customerId,
        goodsId,
      })
      .then((res) => {
        const { discountTitle, firstName, lastName, discountType } = res;
        setCouponDialogStatus(!!discountTitle);
        setDiscountsInfo({ discountTitle, firstName, lastName, discountType });
        const discountDescText = getDiscountDesc({ discountInfo: res, currency, intl, language });
        setDiscountDesc(discountDescText);
      });
  }, []);

  const hideDialog = () => {
    setCouponDialogStatus(false);
    window.history.pushState({}, 0, window.location.origin);
  };

  const { firstName, lastName, discountTitle } = discountsInfo || {};
  const isShowDashboardCoupon = discountTitle && couponDialogStatus && !goodsId && distributionCouponStatus;
  const isShowDetailCoupon = discountTitle && !!goodsId && distributionCouponStatus;

  const copySuccess = () => {
    sessionStorage.setItem(AFFILIATE_DISCOUNT_COPY, discountTitle);
    Notify.info(
      intl.formatMessage({
        id: 'copy_is_successful',
        description: '复制成功啦！',
      }),
    );
  };
  return (
    <Style>
      {isShowDashboardCoupon && (
        <div>
          <div className='mask' />
          <div className='pc-coupon'>
            <p className='coupon-close'>
              <svg className='close-coupon-icon' onClick={hideDialog} aria-hidden='true'>
                <use xlinkHref='#iconic-shanchu' />
              </svg>
            </p>
            <p className='distribution-name'>{`${firstName} ${lastName}`}</p>
            <div
              className='coupon-text'
              dangerouslySetInnerHTML={{
                __html: discountDesc,
              }}
            />
            <div className='coupon-name'>{discountTitle}</div>
            <div>
              <CopyButton text={discountTitle} onCopySuccess={copySuccess}>
                <p className='copy'>
                  {intl.formatMessage({
                    id: 'copy_discount_code',
                    description: '复制折扣码',
                  })}
                </p>
              </CopyButton>
            </div>
          </div>
        </div>
      )}
      {isShowDetailCoupon && (
        <div className='coupon-block'>
          <div className='coupon-block-left'>
            <p className='coupon-text'>
              {`${firstName} ${lastName}`}{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: discountDesc,
                }}
              />
            </p>
            <div className='coupon-name'>{discountTitle}</div>
          </div>
          <div className='coupon-block-right'>
            <CopyButton text={discountTitle} onCopySuccess={copySuccess}>
              <p className='copy-coupon'>
                {intl.formatMessage({
                  id: 'copy_discount_code',
                  description: '复制折扣码',
                })}
              </p>
            </CopyButton>
          </div>
        </div>
      )}
    </Style>
  );
};
