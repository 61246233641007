import styled from 'styled-components';
// import { getPercentWidth, getSizeVw } from '../../../style/fn';

export const Style = styled.div`
  &.cn-center {
  }
  .newsletter-section {
    overflow: hidden;
  }
  @media screen and (min-width: 750px) {
    .footer-news-email-input {
      min-width: 415px;
    }
    &.cn-center {
      .newsletter__inner {
        flex-direction: column;
        text-align: center;
        align-items: center;
        .newsletter__form {
          margin-top: 20px;
          display: flex;
        }
        .newsletter__image-container {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &.cn-left {
      .newsletter__inner {
        flex-direction: row;
        text-align: left;
        justify-content: center;
        align-items: center;
        .newsletter__form {
          margin-left: 60px;
          display: flex;
        }
      }
    }
    &.cn-right {
      .newsletter__inner {
        flex-direction: row-reverse;
        text-align: right;
        justify-content: center;
        align-items: center;
        .newsletter__cta {
          margin-left: 60px;
        }
        .newsletter__image-container {
          left: auto;
          right: 0;
        }
      }
    }
  }

  .newsletter__inner {
    max-width: 1280px;
    position: relative;
    margin: 0 auto;
    padding: 90px 0;
    display: flex;

    .newsletter__image-container {
      position: absolute;
      left: 0;
      top: 0;
      height: 99%;
      width: auto;
      img {
        height: 100%;
      }
    }
  }

  .newsletter__image-container::before {
    left: -2px !important;
    background-image: ${(props) => `linear-gradient(
            to right,
            rgba(${props.bgColor}, 1) 0%,
            rgba(${props.bgColor}, 0.9) 40px,
            rgba(${props.bgColor}, 0.1) 100%
        )`};
  }
  .newsletter__image-container::after {
    right: -2px !important;
    left: auto !important;
    background-image: ${(props) => `linear-gradient(
            to left,
            rgba(${props.bgColor}, 1) 0%,
            rgba(${props.bgColor}, 0.9) 40px,
            rgba(${props.bgColor}, 0.1) 100%
        )`};
  }

  .newsletter__image-container::before,
  .newsletter__image-container::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .newsletter__cta {
    position: relative;
    .footer-news-title {
      font-size: ${(props) => props.theme.getHeaderFontSize([20, 26])};
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
      line-height: 30px;
      margin-bottom: 15px;
      color: var(--color_text);
    }
    .footer-news-text {
      line-height: 24px;
      margin-top: 16px;
      font-size: ${(props) => props.theme.getBaseFontSize([14, 16])};
    }
    .footer-social-icon {
      margin-right: 12px;
      margin-top: 16px;
    }
  }
  .newsletter__form {
    display: flex;
    align-items: center;
    position: relative;
    .footer-news-email-input {
      font-size: 16px !important;
      border: none !important;
      /* border-radius: 0px; */
      height: 52px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .footer-news-email-btn {
      margin-top: 0;
      font-size: ${(props) => props.theme.getBaseFontSize([14, 16])};
      color: #fff;
      line-height: 52px;
      padding: 0px 15px;
      height: 52px !important;
      border: none;
      border-radius: 0 var(--border_radius_form) var(--border_radius_form) 0;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 750px) {
    .footer-news-email-input {
      width: 100% !important;
    }
    &.cn-center {
      .newsletter__inner {
        flex-direction: column;
        text-align: center;
        align-items: center;
      }
    }
    &.cn-left {
      .newsletter__inner {
        flex-direction: row;
        text-align: left;
      }
    }
    &.cn-right {
      .newsletter__inner {
        flex-direction: row-reverse;
        text-align: right;
        align-items: flex-end;
      }
    }
    .newsletter__inner {
      padding: 40px 24px;
      flex-direction: column !important;
      .newsletter__form {
        margin-top: 20px;
        display: flex;
      }
    }
  }
`;
