/* eslint-disable lean-imports/import */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { GroupCard } from 'components/page/group-buying/group-card';
import isEmpty from 'lodash/isEmpty';
import groupBuyingApi from 'api/group-buying';
import CollectionPager from '../../../goods-list/sections/collection-pager';
import ListEmpty from './list-empty';
import { Background } from '../../../design/components/background';
import { Style } from './style';
import { useThemeContext } from '../../../../hook/global-theme';
import { set } from 'lodash';

const GoodsList = (props) => {
  const { list = [], currency, language, settings = {}, history, initialData, containerWidth } = props;
  const {
    grid = 2,
    product_name_size: titleSize,
    product_name_color: titleColor,
    product_price_size: priceSize,
    product_price_color: priceColor,
    show_product_name: showTitle,
    show_product_price: showPrice,
    show_group_button: showGroupBuyBtn,
    show_badge: showBadge,
    align_text: alignText,
    hover_show_second_pic: hoverShowSecondPic,
    image_ratio: imageRatio,
    text_line_limit: textLineLimit = 'auto',
    open_card: openCard = false,
    card_color: cardColor = '#ffffff',
    show_comment: showComment = {},
    show_sale: showSale = false,
    show_vendor: showVendor = false,
    show_wish: showWish = false,
    show_product_color_sku: showProductColorSku = false,
  } = settings;
  const { main_val: showCommentMainVal = false, true: showCommentTrue = {} } = showComment;
  const { show_comment_num: showCommentNum = false, style: commentStyle = 'style1' } = showCommentTrue;

  const goodsNameNodeList = useRef({});
  const [goodsNameHeightList, setgoodsNameHeightList] = useState({});
  const bottomInfoNodeList = useRef({});
  const [bottomInfoHeightList, setBottomInfoHeightList] = useState({});

  const kdtId = initialData?.kdtId;
  const customerId = initialData?.userInfo?.customerId;
  const themeContext = useThemeContext();
  const { clientWidth } = themeContext;

  const setGoodsNameNode = (goodsId, node) => {
    const newValue = { ...goodsNameNodeList.current };
    set(newValue, [goodsId], node);
    goodsNameNodeList.current = newValue;
  };

  const setBottomInfoNode = (goodsId, node) => {
    const newValue = { ...bottomInfoNodeList.current };
    set(newValue, [goodsId], node);
    bottomInfoNodeList.current = newValue;
  };

  const computedHeight = (nodeList) => {
    const newHeightList = {};
    // 宽度小于768(pad最小值)时，每行是固定两个，总数不变,需要重新计算grid和rows
    let newGrid = grid;
    if (clientWidth <= 768) {
      newGrid = 2;
    }
    for (const key in nodeList) {
      set(newHeightList, [key], nodeList[key].clientHeight);
    }
    list.forEach((item, index) => {
      if ((index + 1) % newGrid === 0 || index + 1 === list.length) {
        let gridNum = newGrid;
        if (index + 1 === list.length && (index + 1) % newGrid !== 0) {
          gridNum = (index + 1) % newGrid;
        }
        const heightArr = [];
        for (let i = 0; i < gridNum; i += 1) {
          const { goodsId } = list[index - i];
          heightArr.push(newHeightList[goodsId] || 0);
        }
        const maxheight = Math.max(...heightArr);
        for (let i = 0; i < gridNum; i += 1) {
          const { goodsId } = list[index - i];
          newHeightList[goodsId] = maxheight;
        }
      }
    });
    return newHeightList;
  };

  useEffect(() => {
    if (goodsNameNodeList.current && Object.keys(goodsNameNodeList.current).length >= list?.length) {
      // 非不限制行数的时候，需要计算同行兄弟元素的商品标题高度
      if (textLineLimit !== 'auto' && showTitle === true) {
        setgoodsNameHeightList(computedHeight(goodsNameNodeList.current));
      } else {
        setgoodsNameHeightList({});
      }
    }
  }, [list.length, grid, openCard, textLineLimit, titleSize, showTitle]);

  useEffect(() => {
    if (bottomInfoNodeList.current && Object.keys(bottomInfoNodeList.current).length >= list?.length) {
      // 非不限制行数的时候，需要计算同行兄弟元素的商品标题高度
      if (showVendor || showSale || showCommentMainVal) {
        setBottomInfoHeightList(computedHeight(bottomInfoNodeList.current));
      } else {
        setBottomInfoHeightList({});
      }
    }
  }, [grid, openCard, list.length, showVendor, showSale, showCommentMainVal, showCommentNum, commentStyle]);

  useEffect(() => {
    // 装修的时候切换屏幕大小，获取到的商品名称实际高度不准确，需要延时获取
    const timeout = 1000; // 延时时长
    const timer = setTimeout(() => {
      if (goodsNameHeightList && Object.keys(goodsNameHeightList).length) {
        setgoodsNameHeightList(computedHeight(goodsNameNodeList.current));
      }
      if (bottomInfoHeightList && Object.keys(bottomInfoHeightList).length) {
        setBottomInfoHeightList(computedHeight(bottomInfoNodeList.current));
      }
    }, timeout);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [clientWidth]);

  return (
    <>
      {list.map((item) => (
        <div className='card-wrapper' key={item.goodsId}>
          <GroupCard
            goodsInfo={item}
            currency={currency}
            language={language}
            imageRatio={imageRatio}
            hoverShowSecondPic={hoverShowSecondPic}
            showGroupBuyBtn={showGroupBuyBtn}
            priceSize={priceSize}
            titleSize={titleSize}
            showTitle={showTitle}
            showPrice={showPrice}
            showBadge={showBadge}
            alignText={alignText}
            priceColor={priceColor}
            titleColor={titleColor}
            history={history}
            kdtId={kdtId}
            customerId={customerId}
            containerWidth={containerWidth}
            showWish={showWish}
            textLineLimit={textLineLimit}
            isCard={openCard}
            cardColor={cardColor}
            commentConfig={{
              showComment: showCommentMainVal,
              showCommentNum: showComment?.[showCommentMainVal]?.show_comment_num,
              style: showComment?.[showCommentMainVal]?.style,
            }}
            showSale={showSale}
            showVendor={showVendor}
            showProductColorSku={showProductColorSku}
            returnGoodsNameNode={setGoodsNameNode}
            returnBottomInfoNode={setBottomInfoNode}
            goodsNameHeight={goodsNameHeightList[item.goodsId] || ''}
            bottomInfoHeight={bottomInfoHeightList[item.goodsId] || ''}
          />
        </div>
      ))}
    </>
  );
};

const GroupBuyingProductContainer = (props) => {
  const { sectionSetting = {}, initialData = {}, history } = props;

  const defaultSetting = {
    style: 'style1',
    align: 'left',
    grid: 4,
    container_width: '1280',
    background_style: 'null',
    background_color: '',
    background_image: '',
    cover_pic_ratio: 'crop',
    product_name_size: 11,
    product_name_color: 'rgba(0,0,0,0.2)',
    product_price_size: 11,
    product_price_color: 'rgba(0,0,0,0.2)',
    show_product_name: true,
    show_product_price: true,
    show_group_button: true,
    show_badge: true,
    align_text: 'left',
    hover_show_second_pic: true,
    image_ratio: '4-3',
  };

  const { currency, language } = initialData?.shopSetting ?? {};
  const settingData = sectionSetting?.settings?.settings?.horizontal ?? {};
  const settings = isEmpty(settingData) ? defaultSetting : settingData;
  const customerId = initialData?.userInfo?.customerId;
  const {
    style,
    align,
    grid,
    container_width: containerWidth,
    pic_padding: picPadding,
    background_style: backgroundStyle = 'null',
    background_color: backgroundColor,
    background_image: backgroundImage,
    // cover_pic_ratio: coverPicRatio,
    mobile_display_type: mobileDisplayType = 'tiled',
  } = settings;

  const themeContext = useThemeContext();
  const { isMobile } = themeContext;

  const [groupBuyingGoods, setGroupBuyingGoods] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    pageSize: 16,
    total: 0,
    current: 1,
  });

  const newGrid = useMemo(() => {
    if (isMobile) {
      if (mobileDisplayType === 'rowTwo') {
        return 1;
      }
      return 2;
    }
    return grid;
  }, [grid, isMobile, mobileDisplayType]);

  const onPageChange = (current = 1) => {
    const params = {
      page: current,
      customerId,
      pageSize: pageInfo?.pageSize, // 目前默认最大只会展示20条
    };

    groupBuyingApi.searchGroupActivityGoodsWithPage(params).then((res) => {
      setGroupBuyingGoods(res?.items ?? []);
      setPageInfo({
        ...pageInfo,
        total: res?.paginator?.totalCount,
        current,
      });
    });
  };

  useEffect(() => {
    onPageChange();
  }, [customerId]);

  if (pageInfo.current === 1 && !groupBuyingGoods?.length) {
    return <ListEmpty />;
  }

  const Bg = Background(backgroundStyle === 'solid_color' ? 'custom_color' : backgroundStyle);

  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Style
        grid={newGrid}
        picPadding={picPadding}
        containerWidth={containerWidth}
        backgroundStyle={backgroundStyle}
        backgroundColor={backgroundColor}
        // coverPicRatio={coverPicRatio}
        backgroundImage={backgroundImage}
      >
        <div className='goods-list'>
          <GoodsList
            list={groupBuyingGoods}
            settings={settings}
            currency={currency}
            language={language}
            history={history}
            initialData={initialData}
            containerWidth={containerWidth}
          />
        </div>
        <CollectionPager
          settings={{ style, align }}
          total={pageInfo.total}
          current={pageInfo.current}
          pageSize={pageInfo.pageSize}
          onPageChange={(data) => {
            onPageChange(data?.current);
          }}
        />
      </Style>
    </Bg>
  );
};
export default GroupBuyingProductContainer;
