import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
//设置全局css
body {
    width: 100%;
    overflow-x: hidden;
    .clover-footer {
        display: 'none' !important,
    }
}
/* 懒加载前的图片样式 */
.lazyload {
    background-color: #f7f7f7;
    border: 1px solid #dedede;
}
/**Dialog 无title的情况下 关闭图标按钮 */
.login-dialog-style{
        .zent-dialog-r-close {
            top: 24px !important;
            right: 24px !important;
        }
        padding-top:0!important;
        padding-top:0!important;
        
    }

.swiper-scrollbar {
    border-radius: 0px !important;
    .swiper-scrollbar-drag {
        background: var(--color_button) !important;
        border-radius: 0px !important;
    }
}
.swiper-slide-wrapper {
        height: auto !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

 .component-frame{
     border-radius: 0!important;
     background-color: #ffc439!important;
 } 

 #third-channel-container a:not(:last-child){
      margin-bottom:16px
 }
 
 #robin_tab_container{
     right: 24px!important;
     bottom: 88px!important;
 }

@media screen and (max-width:560px){
    .zent-dialog-r{
        max-width: 80vw;
        min-width: 312px!important;
        width: 312px!important;
    }
    .delete-confirm .zent-dialog-r-body{
        padding:0!important
    }
    .delete-confirm{
        padding-bottom: 0!important;
    }
}


@font-face{
    font-family: 'SourceHanSansCN-Bold';
    src : url('https://b.yzcdn.cn/i18n-c/client/fonts/SourceHanSansCN-Bold.otf');
}

@font-face {
    font-family: "Abril Fatface";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/public_files/2becdf2014cbb481ff4e67756642b082.woff2") format("woff2");
}
@font-face {
    font-family: "Alegreya";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/alegreya_n4.e11eae1e0759cc132406e1e869f9834032b237cb.woff2") format("woff2");
}
@font-face {
    font-family: "Alegreya";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/alegreya_n7.6993f448596276f8f2e25c22c8ce68e631278e1e.woff2") format("woff2");
}

@font-face {
    font-family: "Alegreya";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/alegreya_i4.c67e1a23b1d4d61d89dd7a0e893d2b2af9cb1215.woff2") format("woff2");
}
@font-face {
    font-family: "Alegreya";
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/alegreya_i7.ac02a640bd5de344e852a869e0444981170bb2bb.woff2") format("woff2");
}

@font-face {
    font-family: "Abel";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/abel_n4.2b4bc5e26465e4423e1674428c5593b0074dff23.woff2") format("woff2");
}

@font-face {
    font-family: "Alegreya Sans";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://su.yzcdn.cn/alegreyasans_n4.59e120541f4f6f427ecd086379922b7764465df9.woff2") format("woff2");
}
@font-face {
    font-family: "Alegreya Sans";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/alegreyasans_n7.475a79fe0fef75789bc066fc255516ef43ccbd1f.woff2") format("woff2");
}


@font-face {
    font-family: "Arapey";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://su.yzcdn.cn/arapey_n4.2d2866546ce54f39e3baf69f5d944e54b2e0771b.woff2") format("woff2");
}

@font-face {
    font-family: "Arapey";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url("https://su.yzcdn.cn/arapey_i4.76b9c74110c86df9446495f3b98d8ff62e62229e.woff2") format("woff2");
}


@font-face {
    font-family: "Archivo";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/archivo_n4.abb5ec97705c33fe369ef678fc93b9cf87658330.woff2") format("woff2");
}
@font-face {
    font-family: "Archivo";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/archivo_n7.6f363ab30b12ea00d5e6243ed0e977a11393a3ad.woff2") format("woff2");
}

@font-face {
    font-family: "Chivo";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://su.yzcdn.cn/chivo_n4.bf4dc1c0e4649d2f5a28281870307415c2a9c652.woff2") format("woff2");
}
@font-face {
    font-family: "Chivo";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://su.yzcdn.cn/chivo_n7.d83b4aaa520af8b24ea0f1eb81a6d2d8b1a2ad49.woff2") format("woff2");
}

@font-face {
    font-family: "Nunito Sans";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/nunitosans_n3.ttf");
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/nunitosans_n4.ttf");
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/nunitosans_n7.ttf");
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/nunitosans_i4.ttf");
}

@font-face {
    font-family: "Overt";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/overt_n7.woff");
}

@font-face {
    font-family: "Pacifico";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/pacifico_n7.woff");
}

@font-face {
    font-family: "Zerog";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/zerog_n6.woff");
}

@font-face {
    font-family: "Zerog Wide";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/zerog-wide_n6.woff");
}

@font-face {
    font-family: "Playfair Display";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/playfair-display_n4.woff");
}

@font-face {
    font-family: "Playfair Display";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/playfair-display_n5.woff");
}

@font-face {
    font-family: "Playfair Display";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/playfair-display_n7.woff");
}

@font-face {
    font-family: "Playfair Display";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/playfair-display_i4.woff");
}

@font-face {
    font-family: "Playfair Display";
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/playfair-display_i5.woff");
}

@font-face {
    font-family: "Playfair Display";
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/playfair-display_i7.woff");
}

@font-face {
    font-family: "Youshe";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/youshe_n7.woff");
}

@font-face {
    font-family: "Coco";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/coco_n7.woff");
}

@font-face {
    font-family: "Fifa Welcome";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/fifa-welcome_n7.woff");
}

@font-face {
    font-family: "Maler";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/maler_n7.woff");
}


@font-face {
    font-family: "Oswald";
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/oswald_n2.woff");
}

@font-face {
    font-family: "Oswald";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/oswald_n3.woff");
}

@font-face {
    font-family: "Oswald";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/oswald_n4.woff");
}

@font-face {
    font-family: "Oswald";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/oswald_n5.woff");
}

@font-face {
    font-family: "Oswald";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/oswald_n6.woff");
}

@font-face {
    font-family: "Oswald";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/oswald_n7.woff");
}

@font-face {
    font-family: "Peace Sans";
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/peace-sans_n9.woff");
}


@font-face {
    font-family: "Poppins";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_n3.woff");
}

@font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_n4.woff");
}

@font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_n5.woff");
}

@font-face {
    font-family: "Poppins";
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_n8.woff");
}

@font-face {
    font-family: "Poppins";
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_n9.woff");
}

@font-face {
    font-family: "Poppins";
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_i3.woff");
}

@font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_i4.woff");
}

@font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_i5.woff");
}

@font-face {
    font-family: "Poppins";
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_i8.woff");
}

@font-face {
    font-family: "Poppins";
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/poppins_i9.woff");
}

@font-face {
    font-family: "Sh Pinscher";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/sh-pinscher_n4.woff");
}

@font-face {
    font-family: "Sitka";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/sitka_n4.woff");
}

@font-face {
    font-family: "Sontoloyo";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/sontoloyo_n4.woff");
}

@font-face {
    font-family: "The Night Watch";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/the-night-watch_n4.woff");
}

@font-face {
    font-family: "Wano Quin";
    font-weight: 100;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/wano-quin_n1.woff");
}


@font-face {
    font-family: "Akrobat";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/akrobat_n4.woff");
}

@font-face {
    font-family: "Akrobat";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/akrobat_n6.woff");
}

@font-face {
    font-family: "Akrobat";
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/akrobat_n8.woff");
}

@font-face {
    font-family: "Gentium Book";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/gentium-book_n4.woff");
}

@font-face {
    font-family: "Gentium Book";
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/gentium-book_n8.woff");
}

@font-face {
    font-family: "Gentium Book";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/gentium-book_i4.woff");
}

@font-face {
    font-family: "Gentium Book";
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/gentium-book_i8.woff");
}




@font-face {
    font-family: "Ibm Serif";
    font-weight: 100;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_n1.woff");
}

@font-face {
    font-family: "Ibm Serif";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_n3.woff");
}

@font-face {
    font-family: "Ibm Serif";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_n4.woff");
}

@font-face {
    font-family: "Ibm Serif";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_n5.woff");
}

@font-face {
    font-family: "Ibm Serif";
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_n8.woff");
}

@font-face {
    font-family: "Ibm Serif";
    font-weight: 100;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_ii.woff");
}

@font-face {
    font-family: "Ibm Serif";
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_i3.woff");
}

@font-face {
    font-family: "Ibm Serif";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_i4.woff");
}

@font-face {
    font-family: "Ibm Serif";
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_i5.woff");
}

@font-face {
    font-family: "Ibm Serif";
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    src: url("https://b.yzcdn.cn/ibm-serif_i8.woff");
}

@font-face {
    font-family: "Inter";
    font-weight: 100;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/Inter-Thin.ttf");
}
@font-face {
    font-family: "Inter";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/Inter-Regular.ttf");
}
@font-face {
    font-family: "Inter";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/Inter-Medium.ttf");
}
@font-face {
    font-family: "Inter";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url("https://b.yzcdn.cn/Inter-SemiBold.ttf");
}


  
html{
    font-size: 16px;
    color: var(--color_body_text);
    font-size: var(--base-font-14-12);
   
}

body,p {
    margin: 0;
    font-family: var(--font-stack-body);
    font-style: var(--font-style-body);
    font-weight: var(--font-weight-body);
}

/**
    公用样式类-通用
*/

.cn-link-trigger {
    cursor: pointer;
}

/* 公共字体 */
.i18n-text-standard-h12 {
    ${(props) => props.theme.getBaseSize(props.theme.h12)};
}
.i18n-text-standard-h11 {
    ${(props) => props.theme.getBaseSize(props.theme.h11)};
}
.i18n-text-standard-h10 {
    ${(props) => props.theme.getBaseSize(props.theme.h10)};
}
.i18n-text-standard-h9 {
    ${(props) => props.theme.getBaseSize(props.theme.h9)};
}
.i18n-text-standard-h8 {
    ${(props) => props.theme.getBaseSize(props.theme.h8)};
}
.i18n-text-standard-h7 {
    ${(props) => props.theme.getBaseSize(props.theme.h7)};
}
.i18n-text-standard-h6 {
    ${(props) => props.theme.getBaseSize(props.theme.h6)};
}
.i18n-text-standard-h5 {
    ${(props) => props.theme.getBaseSize(props.theme.h5)};
}
.i18n-text-standard-h4 {
    ${(props) => props.theme.getBaseSize(props.theme.h4)};
}
.i18n-text-standard-h3 {
    ${(props) => props.theme.getBaseSize(props.theme.h3)};
}
.i18n-text-standard-h2 {
    ${(props) => props.theme.getBaseSize(props.theme.h2)};
}
.i18n-text-standard-h1 {
    ${(props) => props.theme.getBaseSize(props.theme.h1)};
}



/**
    公用样式类--容器宽度-目前用于店铺装修
*/

.max_width_1920 {
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    padding:0 ${(props) => props.theme.getSpace([16, 80])};
    box-sizing: border-box;
}

.max_width_1280 {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    padding:0 ${(props) => props.theme.getSpace([16, 80])};
    box-sizing: border-box;
}

.max_width_960 {
    max-width: 960px;
    margin: 0 auto;
    width: 100%;
    padding:0 ${(props) => props.theme.getSpace([16, 80])};
    box-sizing: border-box;
}

@media screen and (min-width: 768px) {
    .cn-full_screen {
        width: 100%;
    }
    .cn-margin_80 {
        width: 100%;
        padding: 0 80px;
        box-sizing: border-box;
    }

    .cn-fix_960 {
        width: 100%;
        max-width: calc(960px + 160px);
        padding: 0 80px;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .cn-fix_1280 {
        width: 100%;
        max-width: calc(1280px + 160px);
        margin: 0 auto;
        padding: 0 80px;
        box-sizing: border-box;
    }
    .cn-fix_1440 {
        width: 100%;
        max-width: calc(1440px + 160px);
        margin: 0 auto;
        padding: 0 80px;
        box-sizing: border-box;
    }
    .cn-fix_1760 {
        width: 100%;
        max-width: calc(1760px + 160px);
        margin: 0 auto;
        padding: 0 80px;
        box-sizing: border-box;
    }
}
/**
    公用样式类--容器宽度-不带边距-目前用于店铺装修
*/
@media screen and (min-width: 768px) {
    .cnn-full_screen {
        width: 100%;
    }
    .cnn-margin_80 {
        width: 100%;
        padding: 0 80px;
        box-sizing: border-box;
    }

    .cnn-fix_960 {
        width: 100%;
        width: 960px;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .cnn-fix_1280 {
        width: 100%;
        width: 1280px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .cnn-fix_1440 {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        box-sizing: border-box;
    }
}

.logistics-detail-tabs {
    display: flex;
    gap: 24px;
    overflow-x: auto;
    .logistics-detail-tab {
      padding-bottom: 14px;
      cursor: pointer;
    }
    .logistics-detail-tab.active {
      border-bottom: 2px solid var(--color_button);
      padding-bottom: 13px;
    }
}
.zent-dialog-r-title:has(.logistics-detail-tabs) {
  padding-bottom: 0!important;
}

/**
    公用样式类--H5
*/
@media screen and (max-width: 768px) {
    .cn-full_screen {
        width: 100%;
    }
    .cn-margin_80,.cn-fix_960,.cn-fix_1280,.cn-fix_1440,.cn-fix_1760 {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }
    .logistics-detail-tabs {
      gap: 16px;
    }
}



#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

:root {
    --pm-80-40: 80px;
    --pm-80-25: 80px;
    --pm-60-54: 60px;
    --pm-60-40: 60px;
    --pm-50-20: 50px;
    --pm-50-24: 50px;
    --pm-45-34: 45px;
    --pm-40-34: 40px;
    --pm-40-20: 40px;
    --pm-40-24: 40px;
    --pm-40-15: 40px;
    --pm-36-30: 36px;
    --pm-32-16: 32px;
    --pm-30-0: 30px;
    --pm-30-24: 30px;
    --pm-24-18: 24px;
    --pm-24-16: 24px;
    --pm-16-8: 16px;
    --pm-16-10: 16px;
    --pm-12-8: 12px;
    --pm-12-6: 12px;
    --pm-12-4: 12px;
    --pm-10-4: 10px;
    --pm-24-0: 24px;
    
    @media screen and (max-width: 750px) {
        --pm-80-40: 40px;
        --pm-80-25: 25px;
        --pm-60-40: 40px;
        --pm-60-54: 54px;
        --pm-50-20: 20px;
        --pm-50-24: 24px;
        --pm-45-34: 35px;
        --pm-40-24: 24px;
        --pm-40-15: 15px;
        --pm-40-34: 32px;
        --pm-40-20: 20px;
        --pm-36-30: 30px;
        --pm-32-16: 16px;
        --pm-30-24: 24px;
        --pm-30-0: 0px;
        --pm-24-18: 18px;
        --pm-24-16: 16px;
        --pm-24-0: 0px;
        --pm-16-8: 8px;
        --pm-16-10: 10px;
        --pm-12-6: 6px;
        --pm-12-8: 8px;
        --pm-12-4: 4px;
        --pm-10-4: 4px;
    }
}

:root {
    --font-14-12:14px;
    --font-16-14:16px;
    --font-18-14:18px;
    --font-20-14:20px;
    --font-20-16:20px;
    --font-22-16:22px;
    --font-24-18:24px;
    --font-24-16:24px;
    --font-26-20:26px;
    --font-32-24:32px;
    --font-36-28:36px;
    --font-64-34:64px;
    --font-18-16:18px;

    @media screen and (max-width: 750px) {
        --font-14-12:12px;
        --font-16-14:14px;
        --font-18-16:16px;
        --font-18-14:14px;
        --font-20-16:16px;
        --font-20-14:14px;
        --font-22-16:16px;
        --font-24-18:18px;
        --font-24-16:16px;
        --font-26-20:20px;
        --font-32-24:24px;
        --font-36-28:28px;
        --font-64-34:34px;
        --font-18-16:16px;
    }
}

/**
    line-height
    if（8px <= font-size < 12px）{ line-height = font-size + 4px }
    if（12px <= font-size < 20px）{ line-height = font-size + 6px }
    if（20px <= font-size < 30px）{ line-height = font-size + 8px }
    if（30px <= font-size < 40px）{ line-height = font-size + 10px }
    if（40px <= font-size < 50px）{ line-height = font-size + 12px }

*/
:root {
    --lh-14-12:20px;
    --lh-16-14:22px;
    --lh-18-14:24px;
    --lh-20-14:28px;
    --lh-20-16:28px;
    --lh-22-16:30px;
    --lh-24-18:32px;
    --lh-26-20:34px;
    --lh-32-24:42px;
    --lh-36-28:46px;
    --lh-64-34:80px;
    --lh-18-16:18px;

    @media screen and (max-width: 750px) {
        --lh-14-12:18px;
        --lh-16-14:20px;
        --lh-18-16:22px;
        --lh-20-14:20px;
        --lh-20-16:22px;
        --lh-22-16:22px;
        --lh-24-18:24px;
        --lh-26-20:28px;
        --lh-32-24:32px;
        --lh-36-28:36px;
        --lh-64-34:44px;
        --lh-18-16:22px;
    }
}

:root {
    /* --color_body_bg_rgb */
}

:root {
    --base-font-14-12: calc(var(--font-14-12) * var(--type_base_size));
    --base-font-16-14: calc(var(--font-16-14) * var(--type_base_size));
    --base-font-18-14: calc(var(--font-18-14) * var(--type_base_size));
    --base-font-20-14: calc(var(--font-20-14) * var(--type_base_size));
    --base-font-20-16: calc(var(--font-20-16) * var(--type_base_size));
    --base-font-22-16: calc(var(--font-22-16) * var(--type_base_size));
    --base-font-24-18: calc(var(--font-24-18) * var(--type_base_size));
    --base-font-24-16: calc(var(--font-24-16) * var(--type_base_size));
    --base-font-26-20: calc(var(--font-26-20) * var(--type_base_size));
    --base-font-32-24: calc(var(--font-32-24) * var(--type_base_size));
    --base-font-36-28: calc(var(--font-36-28) * var(--type_base_size));
    --base-font-64-34: calc(var(--font-64-34) * var(--type_base_size));
    --base-font-18-16: calc(var(--font-18-16) * var(--type_base_size));

    --base-font-lh-14-12: calc(var(--lh-14-12) * var(--type_base_size));
    --base-font-lh-16-14: calc(var(--lh-16-14) * var(--type_base_size));
    --base-font-lh-18-14: calc(var(--lh-18-14) * var(--type_base_size));
    --base-font-lh-20-14: calc(var(--lh-20-14) * var(--type_base_size));
    --base-font-lh-20-16: calc(var(--lh-20-16) * var(--type_base_size));
    --base-font-lh-22-16: calc(var(--lh-22-16) * var(--type_base_size));
    --base-font-lh-24-18: calc(var(--lh-24-18) * var(--type_base_size));
    --base-font-lh-26-20: calc(var(--lh-26-20) * var(--type_base_size));
    --base-font-lh-32-24: calc(var(--lh-32-24) * var(--type_base_size));
    --base-font-lh-36-28: calc(var(--lh-36-28) * var(--type_base_size));
    --base-font-lh-64-34: calc(var(--lh-64-34) * var(--type_base_size));
    --base-font-lh-18-16: calc(var(--lh-18-16) * var(--type_base_size));

    --base-header-font-14-12: calc(var(--font-14-12) * var(--type_header_base_size));
    --base-header-font-16-14: calc(var(--font-16-14) * var(--type_header_base_size));
    --base-header-font-18-14: calc(var(--font-18-14) * var(--type_header_base_size));
    --base-header-font-20-14: calc(var(--font-20-14) * var(--type_header_base_size));
    --base-header-font-20-16: calc(var(--font-20-16) * var(--type_header_base_size));
    --base-header-font-22-16: calc(var(--font-22-16) * var(--type_header_base_size));
    --base-header-font-24-18: calc(var(--font-24-18) * var(--type_header_base_size));
    --base-header-font-26-20: calc(var(--font-26-20) * var(--type_header_base_size));
    --base-header-font-32-24: calc(var(--font-32-24) * var(--type_header_base_size));
    --base-header-font-36-28: calc(var(--font-36-28) * var(--type_header_base_size));
    --base-header-font-64-34: calc(var(--font-64-34) * var(--type_header_base_size));
    --base-header-font-18-16: calc(var(--font-18-16) * var(--type_header_base_size));

    --base-header-font-lh-14-12: calc(var(--lh-14-12) * var(--type_header_base_size));
    --base-header-font-lh-16-14: calc(var(--lh-16-14) * var(--type_header_base_size));
    --base-header-font-lh-18-14: calc(var(--lh-18-14) * var(--type_header_base_size));
    --base-header-font-lh-20-14: calc(var(--lh-20-14) * var(--type_header_base_size));
    --base-header-font-lh-20-16: calc(var(--lh-20-16) * var(--type_header_base_size));
    --base-header-font-lh-22-16: calc(var(--lh-22-16) * var(--type_header_base_size));
    --base-header-font-lh-24-18: calc(var(--lh-24-18) * var(--type_header_base_size));
    --base-header-font-lh-26-20: calc(var(--lh-26-20) * var(--type_header_base_size));
    --base-header-font-lh-32-24: calc(var(--lh-32-24) * var(--type_header_base_size));
    --base-header-font-lh-36-28: calc(var(--lh-36-28) * var(--type_header_base_size));
    --base-header-font-lh-64-34: calc(var(--lh-64-34) * var(--type_header_base_size));
    --base-header-font-lh-18-16: calc(var(--lh-18-16) * var(--type_header_base_size));
    // 需要新增的时候，请充分考虑值是否合理
    // 固定在顶部
    --zindex-fix-top: 1;
    // 固定在底部
    --zindex-fix-bottom: 1;
    // 固定在中间，比如返回顶部，侧边悬浮
    --zindex-fix-middle: 10;
    // zent的Affix组件，sticky效果
    --zindex-affix: 10;
    // 弹窗
    --zindex-dialog: 1050;
    --zindex-dialog-mask: 1050;
    --zindex-modal: 1050;
    --zindex-modal-mask: 1050;
    // 抽屉
    --zindex-drawer: 1050;
    --zindex-drawer-mask: 1050;
    // 全局loading
    --zindex-loading: 1100;
    --zindex-popover: 2000;
    // 气泡提示
    --zindex-tooltip: 2000;
    --zindex-popup: 2000;
    --zindex-cascader: 2000;
    --zindex-picker: 2000;
    --zindex-notify: 10000;
}
/* type_base_size: '', // 正文文本 - 基本尺寸
    type_header_base_size:

--font-14-12:12px; */


:root {
    --border-radius-r1:4px;
    --border-radius-r2:8px;
    --border-radius-r3:12px;
    --border-radius-r4:16px;
    --border-radius-r5:20px;
    --border-radius-r6:24px;
    --border-radius-r7:28px;
    --border-radius-r8:32px;
    --border-radius-r9:36px;
    --border-radius-r10:40px;
    @media screen and (max-width: 1024px) {
        --border-radius-r1:2px;
        --border-radius-r2:4px;
        --border-radius-r3:8px;
        --border-radius-r4:12px;
        --border-radius-r5:16px;
        --border-radius-r6:20px;
        --border-radius-r7:24px;
        --border-radius-r8:28px;
        --border-radius-r9:32px;
        --border-radius-r10:36px;
    }
    @media screen and (max-width: 750px) {
        --border-radius-r1:2px;
        --border-radius-r2:2px;
        --border-radius-r3:4px;
        --border-radius-r4:8px;
        --border-radius-r5:12px;
        --border-radius-r6:16px;
        --border-radius-r7:20px;
        --border-radius-r8:24px;
        --border-radius-r9:28px;
        --border-radius-r10:32px;
    }
}

// 颜色
:root {
    --in-N1: 247, 248, 250;
    --in-N2: 242, 243, 245;
    --in-N3: 235, 237, 240;
    --in-N4: 220, 222, 224;
    --in-N5: 200, 201, 204;
    --in-N6: 150, 151, 153;
    --in-N7: 100, 101, 102;
    --in-N8: 50, 50, 51;

    --in-white: 255, 255, 255;
    --in-black: 0, 0, 0;

    --in-B1: 233, 238, 255;
    --in-B2: 190, 204, 255;
    --in-B3: 81, 118, 255;
    --in-B4: 38, 84, 255;
    --in-B5: 30, 67, 204;
    --in-B6: 23, 50, 153;
    --in-B7: 185, 191, 201;

    --in-O1: 255, 245, 237;
    --in-O4: 237, 106, 12;

    --in-R1: 255, 235, 235;
    --in-R4: 201, 60, 51;

    --in-G1: 226, 244, 238;
    --in-G4: 49, 175, 132;

    --in-color-N1: rgb(var(--in-N1));
    --in-color-N2: rgb(var(--in-N2));
    --in-color-N3: rgb(var(--in-N3));
    --in-color-N4: rgb(var(--in-N4));
    --in-color-N5: rgb(var(--in-N5));
    --in-color-N6: rgb(var(--in-N6));
    --in-color-N7: rgb(var(--in-N7));
    --in-color-N8: rgb(var(--in-N8));
    --in-color-mask: rgba(21, 22, 27, 0.5);

    --in-color-white: rgb(var(--in-white));
    --in-color-black: rgb(var(--in-black));

    --in-color-B1: rgb(var(--in-B1));
    --in-color-B2: rgb(var(--in-B2));
    --in-color-B3: rgb(var(--in-B3));
    --in-color-B4: rgb(var(--in-B4));
    --in-color-B5: rgb(var(--in-B5));
    --in-color-B6: rgb(var(--in-B6));
    --in-color-B7: rgb(var(--in-B7));

    --in-color-O1: rgb(var(--in-O1));
    --in-color-O4: rgb(var(--in-O4));

    --in-color-R1: rgb(var(--in-R1));
    --in-color-R4: rgb(var(--in-R4));

    --in-color-G1: rgb(var(--in-G1));
    --in-color-G4: rgb(var(--in-G4));
}

.section-component-title {
    font-size: ${(props) => props.theme.getHeaderFontSize([18, 24])} !important;
    font-family: var(--font-stack-header) !important;
    font-style: var(--font-style-header) !important;
    font-weight: var(--font-weight-header) !important;
    color: var(--color_text) !important;
    line-height: ${(props) => props.theme.getHeaderLineHeight([18, 24])} !important;
    margin-top: 0 !important;
    margin-bottom: ${(props) => props.theme.getSpace([16, 32, 40])} !important; 
}



/*

calc((var(--font-14-12) * var(--type_base_size)))

type_base_size: 100,
type_header_base_size: 100,


*/


/** 分页组件 */
 .zent-pagination.zent-pagination--lite {
    display: flex;
    justify-content: center !important;
 }
 .zent-input--has-focus {
    border-color: #041B4A !important;
 }
.zent-select--visible {
    border-color: #041B4A !important;
}

/* .address-select-dialog{
    .zent-input-wrapper{
    max-width: 70vw;
   }
   .filed-item{
    max-width: 70vw;
   }

}  */

.zent-number-input-count +.zent-input{
    text-align: center;
}
.zent-pop {
    &.zent-popover {
        background: var(--color_card,#fff);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
    }
    .zent-popover-content {
        .zent-pop-inner {
            border-radius: 4px
        }
        .zent-pop-inner, .zent-pop-arrow {
            background: var(--color_card,#fff);
        }
        .zent-pop-arrow {
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        }
    }
}

/**
 *select
 */ 
.zent-popover-content .zent-select-popup {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    background: var(--color_card, #fff) !important;;
   .zent-select-option {
        background: var(--color_card, #fff) !important;;
        &.active, &.current, &:hover, &.hover  {
            background: var(--color_card, #fff) !important;;
            &:hover {
                background: var(--color_card, #fff) !important;;
            }
        }
        &.disabled {
            color: rgba(var(--color_text_field_text_rgb), 0.2) !important;
            background: var(--color_card, #fff) !important;
            &:before {
                background: var(--color_card, #fff) !important;
            }
        }
        &:hover {
            color: rgba(var(--color_body_text_rgb), 0.7)
        }
    }
}

.zent-number-input {
    background: var(--color_text_field);
    .zent-number-input-count {
        background: var(--color_text_field);
        color: var(--color_text_field_text);
        &.zent-number-input-count-disable {
            color: rgba(var(--color_text_field_text_rgb), 0.2);
            background: var(--color_text_field);
        }
    }
    .zent-number-input-countreduce {
        border-right: none;
        font {
            content: '-';
            font {
                /* display: none; */
            }
        }
    }
    .zent-number-input-countadd {
        border-left: none;
        &:hover {
            color: var(--color_text_field_text);
        }
    }
    .zent-number-input-countreduce,
    .zent-number-input-countadd {
      font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Roboto, Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei", SimSun, sans-serif;
    }
}

/** button */
.zent-btn:not(.zent-pagination-arrow-button, .zent-pagination-page-number-button, .message-box-submit, .zent-dropdown-button) {
    height: 40px !important;
    line-height: 40px !important;
    color: var(--color_small_button_text_border) !important;
    border-color: var(--color_small_button_text_border) !important;
    background-color: var(--color_small_button_bg) !important;
    border-radius: var(--border_radius_btn);
    &.zent-btn-primary {
        background: var(--color_button) !important;
        color: var(--color_button_text) !important;
        border: none !important;
    }
    &.zent-btn-disabled {
        opacity:0.3;
    }
    &.zent-btn-large {
        height: 56px !important;
        line-height: 56px !important;
    }
}
.sweet-confirm-actions .zent-sweetalert-confirm-btn-confirm{
    color:#fff!important;
    border:none!important;
}

.zent-popover{
    z-index:100
}

.zent-notify .zent-notify-content {
  word-break: break-word;
}

@media screen and (max-width: 750px) {
    .zent-btn.zent-btn-large {
        height: 44px !important;
        line-height: 44px !important;
    }
    .zent-notify-container{
        width: fit-content;
    }
}

.zent-btn + .zent-btn {
    margin-left: ${(props) => props.theme.getSpace([8, 16])} !important;
}

.goods-button + .goods-button {
    margin-left: ${(props) => props.theme.getSpace([8, 16])} !important;
}

.ui-big-button.zent-btn:not(.zent-pagination-arrow-button, .zent-pagination-page-number-button){
    height: ${(props) => props.theme.getSpace([32, 44])} !important;
    line-height: ${(props) => props.theme.getHeaderLineHeight([14, 14])} !important;
}
.ui-big-button {
    height: ${(props) => props.theme.getSpace([32, 44])} !important;
    ${(props) => props.theme.getHeaderSize([14, 14])};
    display: flex;
    align-items: center;
    justify-content: center;
    padding:${(props) => props.theme.getSpace([6, 12])} ${(props) => props.theme.getSpace([16, 32])};
    box-sizing: border-box;
    width: fit-content;
    background-color: var(--color_button);
    color: var(--color_button_text);
    ${(props) => props.theme.getBaseFontInfo()};
    font-weight: 500;
}

/**
checkbox 
*/

.zent-checkbox-wrap {
    .zent-checkbox-inner {
        background: transparent;
        border-color: rgba(var(--color_body_text_rgb),0.6);
    }
    /* .zent-checkbox-label {
        color: rgba(var(--color_body_text_rgb),0.6)
    } */
    &.zent-checkbox-checked > .zent-checkbox > .zent-checkbox-inner {
        border-color: var(--color_button) !important;
        background-color: var(--color_button) !important;
    }
}



 .zent-pagination.zent-pagination--lite .zent-pagination-page-button--no-border{
      color: #36383B !important;
      background: none !important;
      font-family: FZLTXHK--GBK1-0, FZLTXHK--GBK1;
      border: none !important;
      margin-left:16px!important;
      font-size: 16px!important;
      &:hover {
          color: #1A1A1B !important;
      }
  }

  .zent-pagination.zent-pagination--lite .zent-btn-primary{
      color:#1A1A1B!important; 
      font-weight:600  
  }
  
  .zent-pagination  .zent-btn.zent-pagination-page-number-button {
    color:var(--color_body_text) !important;
    opacity: 0.6;
    font-family: var(--font-stack-body);
    font-style: var(--font-style-body);
    font-weight: var(--font-weight-body);
  }
  /** 当前选中的样式 */
  .zent-pagination  .zent-btn.zent-pagination-page-number-button.zent-btn-primary {
    font-weight: 600 !important;
    opacity: 1;
  }

  /**
  zent-number-input
  */

  .zent-number-input {

    .zent-input {
        min-width: 68px !important;
    }
    &.zent-input-wrapper {
        width: 126px !important;
        height: 24px !important;
    }
  }


/**
基础class
 */

 .f-js-ac {
     display: flex;
     justify-content: space-between;
     align-items:center;
 }

/**
表单样式全局替换
 */
.zent-form-label {
    color: var(--color_body_text);
}
.zent-form-control .zent-form-error {
    color: rgb(236, 91, 86);
}

 .zent-input-wrapper{
    border-color: var(--color_text_field_border) !important;
    border-radius: var(--border_radius_form) !important;
    .zent-input {
        color: var(--color_text_field_text) !important;
        background-color: var(--color_text_field);
    }
   &.zent-input--has-focus{
        border-color: var(--color_button) !important;
    }
 }
 .zent-form-control.has-error .zent-input-wrapper {
    border-color: var(--theme-error-4, #df4545) !important;
}
 .zent-select .zent-select-text, .zent-select-search {
    border-color: var(--color_text_field_border) !important;
    color: var(--color_text_field_text) !important;
    background-color: var(--color_text_field) !important;
    border-radius: var(--border_radius_form);
 }
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
    -webkit-text-fill-color: var(--color_text_field_text) !important;
    -webkit-box-shadow: 0 0 0px 1000px var(--color_text_field) inset !important;
    background-image: none !important;
}

/* radio 单选框 */
.zent-radio-wrap {
    & > .zent-radio > .zent-radio-inner { 
        background-color: transparent;
    }
    &.zent-radio-checked > .zent-radio > .zent-radio-inner {
        border-color: var(--color_button) !important;
        &::after {
            background-color: var(--color_button) !important;
        }
    }
}

/* input 数字步进器 */
.zent-number-input-arrows {
    border-left-color: var(--color_text_field_border) !important;
    .zent-number-input-arrowup {
        border-bottom-color: var(--color_text_field_border) !important;
    }
    .zent-number-input-arrowdown {
        border-left-color: var(--color_text_field_border) !important;
    }
}


/**
    默认 
*/
.zent-input-wrapper.zent-number-input {
    height: 38px !important;
}
.zent-number-input-arrows {
    height: 38px !important;
    .zent-number-input-arrow {
        height: 19px !important;
        line-height: 19px !important;
    }
}
@media screen and (max-width: 750px) {
    .zent-input-wrapper.zent-number-input {
        height: 28px !important;
        line-height: 28px !important;
    }
    .zent-number-input-arrows {
        height: 28px !important;
        .zent-number-input-arrow {
            height: 14px !important;
            line-height: 14px !important;
        }
    }
}

.zent-select-option {
    &.disabled {
        position: relative;
        pointer-events: none;

        &::before {
            cursor: not-allowed;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.6;
            top: 0;
            left: 0;
            background: #fff;
        }

        &:hover {
            background-color: #fff !important;
        }
    }
}

/* table */
.zent-grid {
    color: var(--color_body_text) !important;
    .zent-grid-empty {
        border-bottom-color: var(--color_borders);
    }
}
.zent-grid-table {
    .zent-grid-thead .zent-grid-th {
        border-bottom-color: rgba(51,51,51,0.08)!important;
        background-color: transparent;
    }
    .zent-grid-tr {
        &:last-child .zent-grid-td {
            border-bottom: none;
        }
    }
    .zent-grid-td {
        padding: 16px !important;
        border-bottom-color: rgba(51,51,51,0.08)
    }
}


.zent-table .thead .tr {
    background-color: transparent !important;
}

.zent-dialog-r-wrap {
    .zent-dialog-r {
        background-color: var(--color_card, #fff);
        border-radius: var(--border_radius_card);
    }
    .zent-dialog-r-title {
        /* font-family: 'PingFang SC'; */
        border-bottom: solid 1px rgba(var(--color_body_text_rgb), 0.08);
        color: var(--color_body_text);
        .zent-dialog-r-title-text {
            color: var(--color_body_text);
        }
    }
    .zent-dialog-r-close {
        background-color: var(--color_card, #fff);
        color: var(--color_body_text);
        top: 24px !important;
        right: 24px !important;
    }
    .zent-dialog-r-body {
        color: var(--color_body_text);
    }
}
.zent-sweetalert-title {
    color: var(--color_body_text);
}
.sweet-confirm-actions .zent-sweetalert-confirm-btn-confirm {
    background: var(--color_button) !important;
    color: var(--color_button_text) !important;
}

.zent-loading-mask {
    background: transparent !important;
    .zent-loading-icon-circle {
        border-top-color: rgba(var(--color_button_rgb), 1) !important;
        border-right-color: rgba(var(--color_button_rgb), 0.75) !important;
        border-bottom-color: rgba(var(--color_button_rgb), 0.6) !important;
        border-left-color: transparent !important;
    }
}
// google自动翻译的一些样式
.goog-te-banner-frame {
    display: none !important;
}

#google_translate_element, #goog-gt-tt {
    display: none !important;
}
body {
    top: 0 !important;
}
.fb_dialog_content {
  iframe {
    margin-bottom: 50px !important;
  }
}
`;
