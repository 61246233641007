import { Notify } from 'zent';
import Toast from 'cpn/toast';
import { useNotice } from './notice';

export const useTips = () => {
  const { showNotice, closeNotice } = useNotice();
  let closeTipsTimer;
  /**
   * 添加成功提示 （购物车或者心愿单） 移动端为轻提示
   */
  const showSuccessTip = (config) => {
    clearTimeout(closeTipsTimer);
    closeTipsTimer = setTimeout(() => {
      closeNotice();
    }, 1800);
    const width = document.body.clientWidth;
    if (width > 768) {
      showNotice(config);
    } else {
      Notify.success(config?.text);
    }
  };
  /** 移除成功提示 */
  const showRemoveTips = (text) => {
    Toast.info(text, 800);
  };

  return { showSuccessTip, showRemoveTips };
};
