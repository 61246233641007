import moment from 'moment';
import { init, captureException, withScope, Severity } from '@sentry/browser';
import * as Sentry from '@sentry/browser';

import { beforeSendHandler } from './handler';

export const initSentry = () => {
  try {
    if (__isServer) return;
    const runMode = window.i18n_env.APPLICATION_STANDARD_ENV;
    console.log('==========初始化sentry===========');
    console.log(runMode);
    // eslint-disable-next-line no-undef
    // if (runMode !== 'pre' && runMode !== 'prod') {
    //     return;
    // }

    const dsn = 'https://e2fc6846bce54290959cf927f044cb8d@crash.youzan.com/177';
    /** 统一线上环境变量值 */

    /** 使用打包时的时间作为 release 版本控制 */
    // eslint-disable-next-line no-undef
    const release = moment(__buildTime).format('YYYY-MM-DD HH:mm:ss');

    /** 初始化 传入配置 */
    init({
      dsn, // 识别
      release, // 发布版本
      environment: 'prod', // 环境
      beforeSend: beforeSendHandler, // 上报的钩子 附加信息
    });
    window.Sentry = Sentry;
  } catch (error) {
    console.error('sentry 初始化失败');
  }
};

export const catchReqException = (err) => {
  withScope((scope) => {
    scope.setLevel(Severity.Warning);
    captureException(new Error(JSON.stringify(err)));
  });
};

/** 自定义上传sentry */
export const catchDebugException = (err) => {
  withScope((scope) => {
    scope.setLevel(Severity.Debug);
    captureException(new Error(JSON.stringify(err)));
  });
};

export default initSentry;
