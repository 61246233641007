import styled from 'styled-components';

export const TagStyle = styled.div`
  display: flex;
  margin-top: 4px;
  .tag-item + .tag-item {
    margin-left: 6px;
  }
  .tag-item-inner,
  .discount-tag-icon {
    ${(props) => props.theme.getBaseSize(props.theme.h12)};
    height: ${(props) => props.theme.getSpace([15, 18])};
    line-height: ${(props) => props.theme.getSpace([15, 18])};
  }
`;
