import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import NewLink from 'cpn/new-link';
import { getUrl } from 'utils/formate';
import { Style } from './style';
import { hexToRGB, RGBAToHex } from 'utils/color';
import MenuItemContent from './components/menu-item-content';
import { parseSettings } from './utils';

function CustomMenu(props) {
  const {
    settings: {
      text_color: textColor,
      nav_padding: navPadding,
      nav_width: navWidth,
      hover_text_color: hoverTextColor,
      card_color: cardColor,
      submenu_hover_text_color: submenuHoverTextColor,
      submenu_text_color: submenuTextColor,
      submenu_card_color: submenuCardColor,
    },
    elemClass,
    block_order: blockOrder,
    blocks,
  } = props;
  const [menuItemContentTop, setMenuItemContentTop] = useState(0);
  const [menuItemMaxWidth, setMenuItemMaxWidth] = useState(1000);
  const [containerHeight, setContainerHeight] = useState(0);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [activeMenuItemContentIndex, setActiveMenuItemContentIndex] = useState(null);
  const menuRef = useRef();

  let containerDom;
  let navDom;

  const calcMenuItemMaxWidth = () => {
    const width = menuRef.current.offsetWidth;
    const parentWidth = menuRef.current.parentNode?.offsetWidth;
    // 宽度 = parentWidth - 1.5倍导航栏宽度， 设计师要求
    setMenuItemMaxWidth(parentWidth - width * 1.5);
  };
  useEffect(() => {
    calcMenuItemMaxWidth();
    window.addEventListener('resize', calcMenuItemMaxWidth);
    return () => {
      window.removeEventListener('resize', calcMenuItemMaxWidth);
    };
  }, [menuRef]);
  // 展开逻辑
  useEffect(() => {
    if (!containerDom) return;
    // 拿到nav容器Style的高度和容器高度对比，如果超出则需要展示展开
    const calContainerHeight = containerDom.offsetHeight;
    setContainerHeight(calContainerHeight);
    const containerScrollHeight = containerDom.scrollHeight;
    if (containerScrollHeight > calContainerHeight) {
      setShowScrollDown(true);
    } else {
      setShowScrollDown(false);
    }
  }, [blockOrder]);

  useEffect(() => {
    if (!containerDom || !navDom) return;
    containerDom.addEventListener('scroll', (e) => {
      const navDomReal = e.target.getElementsByTagName('nav')[0];
      if (e.target.scrollTop == 0) {
        setShowScrollUp(false);
        setShowScrollDown(true);
      } else {
        setShowScrollUp(true);
        setShowScrollDown(false);
      }
    });
  }, [containerDom, navDom]);
  const navButtonDownClick = () => {
    // 如果滚动到底部就不滚动了
    if (containerDom.scrollTop >= navDom.offsetHeight) {
      return;
    }

    if (containerDom.offsetHeight < navDom.offsetHeight) {
      setShowScrollUp(true);
    }

    // 滚动到下一屏幕逻辑
    // 如果有n屏就滚动到下一屏
    const currentTop = containerDom.scrollTop;
    const scrollDis = containerDom.offsetHeight;
    containerDom.scrollTo({
      top: currentTop + scrollDis,
      left: 0,
      behavior: 'smooth',
    });
  };
  const navButtonUpClick = () => {
    // 如果滚动到底部就不滚动了
    if (containerDom.scrollTop == 0) {
      return;
    }

    // if (containerDom.offsetHeight<navDom.offsetHeight) {
    //     setShowScrollUp(true)
    // }

    // 滚动到下一屏幕逻辑
    // 如果有n屏就滚动到下一屏
    const currentTop = containerDom.scrollTop;
    const scrollDis = containerDom.offsetHeight;
    containerDom.scrollTo({
      top: currentTop - scrollDis,
      left: 0,
      behavior: 'smooth',
    });

    if (containerDom.scrollTop == 0) {
      setShowScrollUp(false);
      return;
    }
  };

  const onMouseEnter = (e, index) => {
    setActiveMenuItemContentIndex(index);
    const offsetTop = e.target.offsetTop;
    // 根据menuContentHeight，containerHeight，offsetTop计算合适的top值
    try {
      const menuContentHeight = menuRef.current.querySelectorAll('.menu-item-content')[index]?.offsetHeight;
      const containerHeight = containerDom.offsetHeight;
      if (menuContentHeight >= containerHeight) {
        setMenuItemContentTop(8);
      } else {
        const distance = containerHeight - menuContentHeight;
        let topDistance = Math.min(offsetTop, distance);
        // 保留阴影
        topDistance = topDistance <= 8 ? 8 : topDistance - 8;
        setMenuItemContentTop(topDistance);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onMouseLeave = () => {
    setActiveMenuItemContentIndex(null);
  };
  // 第一个版本的历史数据
  const renderOldBlockItem = (blockItem) => {
    if (!blockItem || !blockItem.settings) return null;
    const menuItem = parseSettings(blockItem.settings);
    return (
      <NewLink href={menuItem.path} target={menuItem.openTarget}>
        {menuItem.title}
      </NewLink>
    );
  };
  // 新版本的数据渲染
  const renderNewBlockItem = (blockItem) => {
    return blockItem.block_order?.map((item) => {
      if (!(blockItem && blockItem.blocks && blockItem.blocks[item])) {
        return null;
      }
      const { settings = {}, type } = blockItem?.blocks[item];
      const { announcement_link, open_target, title } = settings;
      const path = announcement_link ? JSON.parse(announcement_link)?.url : '';
      return type === 'link_text' ? (
        <span className='seperate' key={item}>
          {title}
        </span>
      ) : (
        <NewLink href={path} target={open_target} key={item}>
          {title}
        </NewLink>
      );
    });
  };

  return (
    <Style
      className={cn(elemClass)}
      color={textColor}
      padding={navPadding}
      hoverTextColor={hoverTextColor}
      cardColor={cardColor}
      textColor={textColor}
      submenuHoverTextColor={submenuHoverTextColor}
      submenuTextColor={submenuTextColor}
      submenuCardColor={submenuCardColor}
      width={navWidth}
      menuItemMaxWidth={menuItemMaxWidth}
      showScrollDown={showScrollDown}
      showScrollUp={showScrollUp}
      onMouseLeave={() => onMouseLeave()}
      ref={menuRef}
    >
      <div className='scroll-container' style={{ height: '100%', position: 'relative' }}>
        <nav ref={(node) => (navDom = node)} className='scroll-container-nav'>
          <ul className='scroll-container-ul' style={props.style} ref={(node) => (containerDom = node)}>
            {blockOrder.map((blockId, index) => {
              // 历史数据兼容
              if (!blocks?.[blockId]?.blocks) {
                return null;
              }
              const isOldData = !blocks?.[blockId]?.blocks;
              return (
                <li className='nav-item' key={blockId} onMouseEnter={(e) => onMouseEnter(e, index)}>
                  {isOldData ? renderOldBlockItem(blocks[blockId]) : renderNewBlockItem(blocks[blockId])}
                </li>
              );
            })}
          </ul>
          <div>
            {blockOrder.map((blockId, index) => {
              if (!blocks[blockId]) return null;
              const { blocks_two: blocksTwo, block_order_two: blockOrderTwo, settings } = blocks[blockId];

              return blockOrderTwo?.length > 0 ? (
                <MenuItemContent
                  key={blockId}
                  containerHeight={containerHeight}
                  settings={settings}
                  blocksTwo={blocksTwo}
                  blockOrderTwo={blockOrderTwo}
                  style={{
                    top: menuItemContentTop + 'px',
                    ...props.style,
                  }}
                  className={cn('menu-item-content', {
                    active: activeMenuItemContentIndex === index,
                  })}
                ></MenuItemContent>
              ) : (
                <div className='menu-item-content hidden'></div>
              );
            })}
          </div>
          <div className='scrollup-icon' onClick={navButtonUpClick}>
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#icongengduozhankai' />
            </svg>
          </div>

          <div className='scrolldown-icon' onClick={navButtonDownClick}>
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#icongengduozhankai' />
            </svg>
          </div>
        </nav>
      </div>
    </Style>
  );
}

export default CustomMenu;
