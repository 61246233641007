/**
 * 菜单相关的api接口封装
 */
import BaseApi from './base';

class DistributionApi extends BaseApi {
  getRecruitingPoster(params) {
    return this._get('/api/soa/com.youzan.i18n.affiliate.setting.controller.web.SettingController/getPoster', params);
  }

  // http://zanapi.qima-inc.com/site/service/view/1072764 获取个人信息
  getPersonalInfo() {
    return this._get('/api/node/getPersonalInfo');
  }

  updatePersonalInfo(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.affiliate.partner.controller.web.AffiliateController/updatePersonalInfo',
      params,
    );
  }

  // http://zanapi.qima-inc.com/site/service/view/1072765 注册与申请成分销员
  register(params) {
    return this._post('/api/soa/com.youzan.i18n.affiliate.partner.controller.web.AffiliateController/register', params);
  }

  // http://zanapi.qima-inc.com/site/service/view/1072762 申请成分销员
  apply(params) {
    return this._post('/api/soa/com.youzan.i18n.affiliate.partner.controller.web.AffiliateController/apply', params);
  }

  // 分页查询账单
  searchAffiliateBillWithPage(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.bill.controller.web.AffiliateBillCustomerController/searchAffiliateBillWithPage',
      params,
    );
  }

  // http://zanapi.qima-inc.com/site/service/view/1082621 获取商品推荐
  searchRecommendedGoodsWithPage(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.affiliate.partner.controller.web.AffiliateController/searchRecommendedGoodsWithPage',
      params,
    );
  }

  // 获取分享地址
  getShareLink(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.affiliate.tracking.controller.TrackingController/createReferralLink',
      params,
    );
  }

  createReferralLink(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.affiliate.tracking.controller.web.TrackingController/createReferralLink',
      params,
    );
  }

  // 分页查询订单
  searchOrderWithPage(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.order.controller.web.AffiliateOrderCustomerController/searchOrderWithPage',
      params,
    );
  }
  getReferralLink(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.tracking.controller.web.TrackingController/getReferralLink',
      params,
    );
  }
  clickShareLink(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.tracking.controller.TrackingController/clickShareLink',
      params,
    );
  }

  clickReferralLink(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.tracking.controller.web.TrackingController/clickReferralLink',
      params,
    );
  }

  getAssets() {
    return this._get('/api/node/getAssets');
  }

  // http://zanapi.qima-inc.com/site/service/view/1081482 帐号登录
  login(params) {
    return this._post('/api/node/distributionLogin', params);
  }

  logout() {
    return this._post('/api/node/distributionLogout');
  }

  isLogin() {
    return this._post('/api/node/isLogin');
  }

  getAffiliateCommissionRate(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.commission.controller.web.CommissionRuleCustomerController/getAffiliateCommissionRate',
      params,
    );
  }

  getAffiliateDiscount(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.shopping.controller.web.ShoppingController/getAffiliateDiscount',
      params,
    );
  }

  getAdvancedSetting(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.commission.controller.web.CommissionRuleCustomerController/getAdvancedSetting',
      params,
    );
  }

  getResetPasswordLink(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.partner.controller.web.AffiliateController/getResetPasswordLink',
      params,
    );
  }

  resetPassword(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.partner.controller.web.AffiliateController/resetPassword',
      params,
    );
  }

  getAccountByResetCode(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.partner.controller.web.AffiliateController/getAccountByResetCode',
      params,
    );
  }

  reapply(params) {
    return this._get('/api/soa/com.youzan.i18n.affiliate.partner.controller.web.AffiliateController/reapply', params);
  }

  getShopDefaultGroupRule(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.commission.controller.web.CommissionRuleCustomerController/getShopDefaultGroupRule',
      params,
    );
  }

  reportClick(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.affiliate.tracking.controller.web.TrackingController/reportClick',
      params,
    );
  }

  getAffiliateHeadImgUploadData(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.affiliate.partner.controller.web.AffiliateController/getAffiliateHeadImgUploadData',
      params,
    );
  }

  getAffiliateDiscountV2(params = {}) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.shopping.controller.web.ShoppingController/getAffiliateDiscountV2',
      params,
    );
  }

  // 获取分销员邀请链接
  getAffiliateInviteLink(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.tracking.controller.web.TrackingController/getAffiliateInviteLink',
      params,
    );
  }

  // 上报客户点击分销员邀请链接事件
  reportAffiliateInviteLinkClick(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.tracking.controller.web.TrackingController/reportAffiliateInviteLinkClick',
      params,
    );
  }

  // 分销数据
  getAffiliateDeal(params) {
    return this._get('/api/soa/com.youzan.i18n.data.admin.web.affiliate.AffiliateController/getAffiliateDeal', params);
  }

  // 查询分销裂变设置
  getAffiliateFissionSetting(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.commission.controller.web.CommissionRuleCustomerController/getAffiliateFissionSetting',
      params,
    );
  }

  getAffiliateAbility(params = {}) {
    return this._get(
      '/api/soa/com.youzan.i18n.affiliate.setting.controller.web.SettingController/getAffiliateAbility',
      params,
    );
  }
}

export default new DistributionApi();
