import styled from 'styled-components';

export default styled.div`
  height: 19px;
  padding: 0px 6px;
  font-size: 12px !important;
  line-height: 17px;
  font-family: 'PingFang SC' !important;
  font-weight: 400;
  color: var(--color_activity_label);
  border: 1px solid var(--color_activity_label);
  /* border-radius: 2px; */
  border-radius: var(--border_radius_tag);
  box-sizing: border-box;
  display: inline-block;
  &.hunt-tag-border-false {
    border: none;
    background: rgba(var(--color_activity_label_rgb), 0.08);
  }
`;
