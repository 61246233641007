import React, { Children } from 'react';
import { Link } from 'react-router-dom';
import isObject from 'lodash/isObject';
// type: 'a' 适合跳转服务端渲染的页面,'link'  适合路由跳转，非服务端渲染的页面
// children
// href
// className
// alt
// style
// ...
const NewLink = ({ type = 'a', children, href, className, alt, style, onClick, target, ...extra }) => {
  // if (!href) {
  //     return children;
  // }
  // eslint-disable-next-line no-param-reassign
  if (target === 'self') target = '_self';
  let realHref = href;
  try {
    if (href && typeof href === 'string' && href.indexOf('{') > -1 && href.indexOf('}') > -1) {
      realHref = JSON.parse(href).url;

      if (typeof realHref === 'undefined') {
        realHref = href;
      }
    }
  } catch (e) {
    console.log('NewLink parse error', e);
  }
  const hrefObj = {};

  const targetObj = {};
  if (realHref) {
    targetObj.target = target;
    hrefObj.href = realHref;
  }
  return type === 'a' ? (
    <a
      className={className}
      alt={alt}
      {...hrefObj}
      {...targetObj}
      style={style}
      onClick={onClick}
      {...extra}
      rel='nofollow'
    >
      {children}
    </a>
  ) : (
    <Link to={realHref} className={className} style={style} onClick={onClick} {...extra}>
      {children}
    </Link>
  );
};
export default NewLink;
