import styled from 'styled-components';

export const Style = styled.div`
  overflow-x: auto;
  text-align: ${(props) => (props.align ? props.align : 'left')};
  white-space: ${(props) => (props.nowrap ? 'nowrap' : 'wrap')};
  &::-webkit-scrollbar {
    display: none;
  }
  /* .img-flex-wrapper {
        flex: 1;
    } */
  .img-wrapper {
    vertical-align: top;
    height: 22px;
    width: 38px;
    border: 1px solid var(--color_borders);
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    flex: 1;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .fix-style {
    flex: 100;
  }
  .img-wrapper:not(:last-child) {
    margin-right: 12px;
  }
`;

export const Wrapper = styled.div`
    position: relative;
    .arrow-wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 26px;
        width: 55px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .arrow-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        background: ${(props) => props.theme.colorBodyTexts['60']}};
    }
    .arrow-left {
        left: 0;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, ${(props) => props.theme.colorCard} 100%);
        justify-content: flex-start;
        .arrow-icon-wrapper {
            transform: rotate(180deg);
        }
    }
    .arrow-right {
        right: 0;
        background: linear-gradient(270deg, ${(props) => props.theme.colorCard} 0%, rgba(255, 255, 255, 0) 100%);
        justify-content: flex-end;
    }
    .arrow-icon {
        fill: ${(props) => props.theme.colorCard};
    }
`;
