import { createGlobalStyle } from 'styled-components';

// zoom in 放大
// zoom out 缩小
export default createGlobalStyle`
    .i18n-animation-hover-zoom-in {
        transition: all 1.2s;
        &:hover {
            transform: scale(1.2);
        }
    }
    .i18n-animation-hover-zoom-out {
        transition: all 1.2s;
        transform: scale(1.2);
        &:hover {
            transform: scale(1);
        }
    }
    .i18n-animation-fade-in {
        transition: all 1.2s;
        opacity: 0;
        &:hover {
            opacity: 1;
        }
    }
    .i18n-animation-fade-out {
        transition: all 1.2s;
        opacity: 1;
        &:hover {
            opacity: 0;
        }
    }

    .animated {
        animation-duration: 1s;
        animation-fill-mode: both;
    }

    .shown-on-scroll {
        opacity: 0.5;
        animation-delay: .25s;
    }

    @keyframes zoom-fade-small {
        0% {
            opacity: 0;
            transform: scale(1.1);
        }
        10% {
            opacity: 0.1;
        }
        20% {
            opacity: 0.2;
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0.5;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeInUp {
        0% {
            opacity: 0.5;
            transform: translate3d(0,10%,0)
        }

        to {
            opacity: 1;
            transform: translateZ(0)
        }
    }

    
    @keyframes fadeInLeft {
        0% {
            opacity: 0.5;
            -webkit-transform: translate3d(-10%,0,0);
            transform: translate3d(-10%,0,0)
        }

        to {
            opacity: 1;
            -webkit-transform: translateZ(0);
            transform: translateZ(0)
        }
    }

    .fadeInLeft {
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft
    }

    .fadeInUp {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
    }

    .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
    }

    /* .animations-disabled .fadeIn, .animations-disabled .fadeInUp, .animations-disabled .fadeInLeft {
        animation-name: none;
    } */

    /* body.page-fade {
    opacity: 0;
    transition: opacity 0.4s ease-in;
    } */
    /* body.page-fade.loaded {
    opacity: 1;
    }
    body.page-fade.unloading {
    opacity: 0;
    } */
    body {
        transition: opacity 0.4s ease-in;
    }
    body.loaded {
    opacity: 1;
    }
    body.unloading {
    opacity: 0.5;
    }
    .disable-scroll {
        overflow: hidden;
    }
    .opacity1 {
        opacity: 1;
    }
    /* 隐藏 google 翻译头部、loading效果、翻译弹窗 */
    body .skiptranslate > iframe.skiptranslate,
    body .VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc,
    body #goog-gt-.skiptranslate{
      display: none !important;
    }
`;
