import styled from 'styled-components';

export default styled.div`
  .popup-shadow-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup-contain {
    width: 220px;
    transform: translateY(100%);
    opacity: 0;
    transition: all 0.2s ease-in-out;
    .poster {
      .icon-close {
        text-align: right;
        .icon {
          width: 24px;
          height: 24px;
          margin-bottom: 10px;
          fill: white;
        }
      }
      img {
        width: 220px;
        min-height: 300px;
        display: block;
        margin: 0 auto;
      }
      .save-tip {
        font-size: 14px;
        color: #fff;
        text-align: center;
        margin-top: 16px;
      }
    }
  }
  .popup-contain-animate {
    transform: translateY(0);
    opacity: 1;
  }
  @media screen and (min-width: 750px) {
    display: none;
  }
`;
