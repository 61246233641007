import styled from 'styled-components';

export default styled.div`
  box-sizing: border-box;
  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .feature-columns-title {
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
    word-break: break-word;
    color: var(--color_text);
    font-size: var(--base-header-font-26-20);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    margin-bottom: 40px;
    margin-top: 16px;
    &.left {
      text-align: left;
    }
    &.center {
      text-align: center;
    }
  }

  .feature-columns-grid {
    display: flex;
    flex-flow: row wrap;
  }

  .feature-columns-img-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      .feature-columns-goods-btn-warp {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }
  .feature-columns-img-list {
    position: relative;
    width: 100%;
    overflow: hidden;
    .feature-columns-img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      &.hover-image {
        width: 100%;
        height: 100%;
        opacity: 0;
        top: 0;
        &:hover {
          opacity: 1;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }

  .feature-columns-goods-title {
    font-weight: 600;
    font-size: var(--base-header-font-20-14);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    color: var(--color_text);
    line-height: 30px;
    margin: 24px 0px 12px 0px;
  }
  .feature-columns-goods-sub-title {
    color: var(--color_body_text);
    font-weight: 600;
    ${(props) => props.theme.getBaseSize([12, 16])};
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    margin: 24px 0px 8px 0px;
    cursor: pointer;
  }

  .feature-columns-goods-desc {
    font-weight: 400;
    color: var(--color_body_text);
    ${(props) => props.theme.getBaseSize([12, 14])};
  }

  .feature-columns-goods {
    margin-top: var(--base-font-24-18);
    background-color: var(--color_body_bg) !important;
  }

  .feature-columns-grid-item {
    &:nth-child(${(props) => `-n+${props.grid}`}) {
      margin-top: 0;
    }
    margin-top: ${(props) => props.theme.getSpace([18, 28])};
    align-self: flex-start;
    &.grid-item-place-holder {
      height: 0;
      visibility: hidden;
      padding: 0;
      margin: 0;
    }
    width: ${(props) => props.pcWidthItem};
  }
  .feature-columns-goods-btn-warp {
    position: absolute;
    z-index: 10;
    max-width: calc(100% - 32px);
    opacity: 0;
    .zent-button {
      width: 100%;
      height: auto !important;
      span {
        word-break: break-word;
        line-height: 20px !important;
        display: block;
        padding: 20px 0;
      }
    }
  }

  @media screen and (min-width: 751px) {
    &.cn-full_screen {
      .text-content {
        padding: 0 24px;
        box-sizing: border-box;
      }
    }
    .feature-columns-grid {
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
    }

    .feature-columns-grid-item {
      box-sizing: border-box;
      margin-right: ${(props) => `${props.pcGap}px`};
      :nth-of-type(${(props) => `${props.grid}n`}) {
        margin-right: 0px;
      }
    }
  }
  .feature-columns-goods-box-btn-warp {
    margin-top: ${(props) => props.theme.getSpace(props.theme.vTextButton)};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 751px) {
    &.cn-full_screen {
      .text-content {
        padding: 0 12px;
        box-sizing: border-box;
      }
    }
    .feature-columns-goods-sub-title {
      margin-bottom: 0;
      margin: 12px 0px 0px 0px;
    }
    .feature-columns-grid-item {
      /* 覆盖 */
      &:nth-child(${(props) => `-n+${props.grid}`}) {
        margin-top: ${(props) => props.theme.getSpace([18, 28])};
      }
      &:nth-child(${(props) => `-n+${props.gridMobile}`}) {
        margin-top: 0;
      }
      margin-top: ${(props) => props.theme.getSpace([18, 28])};
      width: ${(props) => props.mobileWidthItem};
      margin-right: ${(props) => `${props.mobileGap}px`};
      :nth-of-type(${(props) => `${props.gridMobile}n`}) {
        margin-right: 0px;
      }
    }
  }
`;
