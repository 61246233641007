import React from 'react';
import { Style } from './style';

/**
 *
 * @param {object} style 内联样式
 * @param {string} text 提示文案
 */
const ActionSuccessNotice = (props) => {
  const { text = '', style = {} } = props;
  return (
    <Style style={style}>
      <div className='triangle' />
      <div>
        <svg className='icon tips-icon' aria-hidden='true' fill='#409979'>
          <use xlinkHref='#iconic-chenggong' />
        </svg>
      </div>
      <div className='text-description'>{text}</div>
    </Style>
  );
};

export default ActionSuccessNotice;
