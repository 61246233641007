import { useState, useEffect } from 'react';
import { sendSmsVerifyCode, sendMailVerifyCode } from 'helper/verify-code';
import { ACCOUNT_TYPE_MAP } from 'client/const/account';

export const STATUS_MAP = {
  unsend: 'unsend',
  sended: 'sended',
};

const throttleTime = 60;

export const useGetCode = () => {
  const [status, setStatus] = useState(STATUS_MAP.unsend);
  const [leftTime, setLeftTime] = useState(0);

  useEffect(() => {
    if (leftTime > 0) {
      setTimeout(() => {
        setLeftTime(leftTime - 1);
      }, 1000);
    } else {
      setStatus(STATUS_MAP.unsend);
    }
  }, [leftTime]);

  const getCode = (params, customI18nRecaptchaToken) => {
    return new Promise((resolve) => {
      const options = {
        header: {
          'custom-i18n-recaptcha-token': JSON.stringify(customI18nRecaptchaToken)
        }
      }
      const { mobile, mobileCountryCode, scene, accountType = ACCOUNT_TYPE_MAP.phone, email } = params;
      
      if (mobile && accountType === ACCOUNT_TYPE_MAP.phone) {
        sendSmsVerifyCode({
          mobileCountryCode,
          mobile,
          scene,
        }, options).then(() => {
          setLeftTime(throttleTime);
          setStatus(STATUS_MAP.sended);
          resolve();
        });
      }
      if (email && accountType === ACCOUNT_TYPE_MAP.email) {
        sendMailVerifyCode({
          email,
          scene,
        }, options).then(() => {
          setLeftTime(throttleTime);
          setStatus(STATUS_MAP.sended);
          resolve();
        });
      }
    });
  };
  return {
    status,
    getCode,
    leftTime,
  };
};
