// 装修共用-卡片组件
// 控制卡片间距，圆角等样式
// 可配置项：卡片颜色
import React from 'react';
import Style from './style';
import cn from 'classnames';

const Card = (props) => {
  const { bgColor = '#FFF', className, ...extra } = props;
  return (
    <Style bgColor={bgColor} className={className} {...extra}>
      <div className={cn('card')}>{props.children}</div>
    </Style>
  );
};

export default Card;
