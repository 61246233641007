import { DiscountType, DiscountLimitType, DISCOUNT_LIMIT_FREQUENCY } from 'const/discount';
import { intlLiToYuan } from 'utils/money';
import Big from 'big.js';

enum RequireType {
  Detail = 'detail', // 折扣详情描述
  CalcResult = 'calcResult', // 实时计算的折扣条件状态
}

// 根据语言环境返回需要的数据
export const getValueByLang = (discountValue, language, withSign = false) => {
  if (language === 'zh-CN' || language === 'zh-TW') {
    const value = (10 - discountValue / 10).toFixed(2);
    return parseFloat(value);
  } else {
    return withSign ? `${discountValue}%` : discountValue;
  }
};

/**
 * 获取距离满足条件的差值
 * @param param0
 */
export const getFitDistance = ({ type, requireValue, discountGoods = [] }) => {
  // 过滤掉活动商品
  const goodsList = discountGoods.filter((item) => !item.giftGoodsFlag);

  if (type === DiscountLimitType.Amount) {
    const totalValues = goodsList.reduce((pre, item) => pre + item.price * item.quantity, 0);
    const distance = requireValue - totalValues;
    return distance;
  }

  if (type === DiscountLimitType.Quantity) {
    const totalNum = goodsList.reduce((pre, item) => pre + Number(item.quantity), 0);
    const distance = requireValue - totalNum;
    return distance;
  }
  return 0;
};

/**
 * 根据阶梯规则, 计算下一个阶梯的满减距离
 */
export const getLadderDistance = (params) => {
  const { type, rules, discountGoods = [] } = params;
  // 过滤掉活动商品
  const goodsList = discountGoods.filter((item) => item.giftGoodsFlag === 0);
  if (type === DiscountLimitType.Amount) {
    const totalPrice = goodsList.reduce((pre, item) => pre + item.price * item.quantity, 0);
    const item = rules.find((item) => item?.minimumRequirement?.requireValue > totalPrice);
    return {
      distance: item?.minimumRequirement?.requireValue - totalPrice,
      discountValue: item?.discountGets?.discountValue,
      distanceRequireValue: item?.minimumRequirement?.requireValue,
    };
  }
  if (type === DiscountLimitType.Quantity) {
    const totalCount = goodsList.reduce((pre, item) => pre + Number(item.quantity), 0);
    const item = rules.find((item) => item?.minimumRequirement?.requireValue > totalCount);
    return {
      distance: item?.minimumRequirement?.requireValue - totalCount,
      discountValue: item?.discountGets?.discountValue,
      distanceRequireValue: item?.minimumRequirement?.requireValue,
    };
  }
  return {
    distance: 0,
    discountValue: 0,
    distanceRequireValue: 0,
  };
};

/**
 * @description: 限制客户使用次数文案
 * @return {*}
 */
export const getLimitDesc = ({ intl, limitQuotaRule, requireType }) => {
  if (requireType === RequireType.CalcResult) return null;
  const { limitFrequency, limitNum } = limitQuotaRule;
  if (limitFrequency === DISCOUNT_LIMIT_FREQUENCY.everyDay) {
    return intl.formatMessage(
      { id: 'b9a9b3a4ccf64e03a065b75d9d12a8df', defaultMessage: '每个用户可使用 {num} 次/天' },
      { num: limitNum },
    );
  }
  if (limitFrequency === DISCOUNT_LIMIT_FREQUENCY.cycle) {
    return intl.formatMessage(
      { id: '2e63e17302b94402b46868d3f692f14d', defaultMessage: '每个用户可使用 {num} 次' },
      { num: limitNum },
    );
  }
  return null;
};

/**
 * 满多少元减钱
 * @param params
 */
const getFixedAmountAmountDesc = (params) => {
  const {
    intl,
    discountInfo = {},
    discountGoods,
    currency,
    requireType = RequireType.Detail,
    multiCurrencyInfo,
  } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
    totalDiscountAmount, // 折扣金额
    promotionRules = [], // 梯度价格
    limitQuotaRule = {}, // 客户使用限制
  } = discountInfo;
  const { discountValue = 10 } = discountGets;
  const { type, requireValue = 1 } = minimumRequirement;
  const amount = intlLiToYuan({ currency, value: requireValue, multiCurrencyInfo });
  const discountAmount = intlLiToYuan({ currency, value: discountValue, multiCurrencyInfo });
  const totalDiscountAmountValue = intlLiToYuan({ currency, value: totalDiscountAmount, multiCurrencyInfo });

  const text: any = [];
  if (requireType === RequireType.Detail) {
    // 梯度价格文案
    if (promotionRules.length > 1) {
      promotionRules.forEach((item) => {
        const amount = intlLiToYuan({ currency, value: item?.minimumRequirement?.requireValue, multiCurrencyInfo });
        const discountAmount = intlLiToYuan({ currency, value: item?.discountGets?.discountValue, multiCurrencyInfo });
        text.push(
          intl.formatMessage(
            { id: 'c74f5cf9c8c34671a3e83b8774331fdd', defaultMessage: '满 {condition} 减 {result}' },
            { condition: amount, result: discountAmount },
          ),
        );
      });
    } else {
      text.push(
        intl.formatMessage(
          { id: '744a2d0e7fd24129bf373d1a2eb77e4e', defaultMessage: '买满 {requireValue}，立减 {discountValue}' },
          { requireValue: amount, discountValue: discountAmount },
        ),
      );
    }
  } else {
    const rules = promotionRules.length > 0 ? promotionRules : [{ discountGets, minimumRequirement }];
    const { distance, discountValue, distanceRequireValue } = getLadderDistance({ type, rules, discountGoods });
    if (totalDiscountAmount > 0) {
      if (distance > 0) {
        text.push(
          intl.formatMessage(
            {
              id: '8ce5730d112d493cbfe3d723a958bdb7',
              defaultMessage: '已节省 {totalDiscountAmountValue}，多购买 {amount}，可共减免 {discountValue}',
            },
            {
              totalDiscountAmountValue,
              discountValue: intlLiToYuan({ currency, value: discountValue, multiCurrencyInfo }),
              amount: intlLiToYuan({ currency, value: distance, multiCurrencyInfo }),
            },
          ),
        );
      } else {
        text.push(
          intl.formatMessage(
            {
              id: '292a860de098468495662c3bb8574e7b',
              defaultMessage: '已满 {requireValue}，已减 {discountValue}',
            },
            { requireValue: amount, discountValue: totalDiscountAmountValue },
          ),
        );
      }
    } else {
      if (distance > 0) {
        text.push(
          intl.formatMessage(
            {
              id: '264ef0de8fd44bffbecb05580ade994d',
              defaultMessage: '买满 {requireValue} 立减 {discountValue}，还差 {amount}',
            },
            {
              requireValue: intlLiToYuan({ currency, value: distanceRequireValue, multiCurrencyInfo }),
              discountValue: intlLiToYuan({ currency, value: discountValue, multiCurrencyInfo }),
              amount: intlLiToYuan({ currency, value: distance, multiCurrencyInfo }),
            },
          ),
        );
        ``;
      }
    }
  }
  const limitDesc = getLimitDesc({ intl, limitQuotaRule, requireType });
  limitDesc && text.push(limitDesc);
  return text.join(', ');
};
/**
 * 满多少件减钱
 * @param params
 */
const getFixedAmountQuantity = (params) => {
  const {
    intl,
    discountInfo = {},
    discountGoods,
    currency,
    requireType = RequireType.Detail,
    multiCurrencyInfo,
  } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
    totalDiscountAmount, // 折扣金额
    promotionRules = [], // 阶梯价格
    limitQuotaRule = {}, // 客户使用限制
  } = discountInfo;
  const { discountValue = 10 } = discountGets;
  const { type, requireValue = 1 } = minimumRequirement;

  const amount = intlLiToYuan({ currency, value: discountValue, multiCurrencyInfo });
  const totalDiscountAmountValue = intlLiToYuan({ currency, value: totalDiscountAmount, multiCurrencyInfo });

  const text: any = [];
  if (requireType === RequireType.Detail) {
    // 梯度价格文案
    if (promotionRules.length > 1) {
      promotionRules.forEach((item) => {
        const amount = intlLiToYuan({ currency, value: item?.discountGets?.discountValue, multiCurrencyInfo });
        text.push(
          intl.formatMessage(
            { id: '026c8f49eaab4108a163083e7a1ab763', defaultMessage: '满 {condition} 件减 {result}' },
            { condition: item?.minimumRequirement?.requireValue || 0, result: amount },
          ),
        );
      });
    } else {
      text.push(
        intl.formatMessage(
          { id: '1e5c4e8d4a0c47c9ba3c09aff5e2fba3', defaultMessage: '买满 {requireValue} 件，立减 {discountValue}' },
          { discountValue: amount, requireValue },
        ),
      );
    }
  } else {
    const rules = promotionRules.length > 0 ? promotionRules : [{ discountGets, minimumRequirement }];
    const { distance, discountValue, distanceRequireValue } = getLadderDistance({ type, rules, discountGoods });
    if (totalDiscountAmount > 0) {
      if (distance > 0) {
        text.push(
          intl.formatMessage(
            {
              id: 'c10050443dda4711891bf54004f5e165',
              defaultMessage: '已节省 {totalDiscountAmountValue}，多购买 {amount} 件，可共减免 {discountValue}',
            },
            {
              totalDiscountAmountValue,
              discountValue: intlLiToYuan({ currency, value: discountValue, multiCurrencyInfo }),
              amount: distance,
            },
          ),
        );
      } else {
        text.push(
          intl.formatMessage(
            {
              id: '8db06f12ea344aea9ac26fab26f0cda6',
              defaultMessage: '已满 {requireValue} 件，已减 {discountValue}',
            },
            { requireValue, discountValue: totalDiscountAmountValue },
          ),
        );
      }
    } else {
      if (distance > 0) {
        text.push(
          intl.formatMessage(
            {
              id: 'cart_discount_amount_not_full_hint',
              defaultMessage: '买满 {requireValue} 件立减 {discountValue}，还差 {amount} 件',
            },
            {
              requireValue: distanceRequireValue,
              discountValue: intlLiToYuan({ currency, value: discountValue, multiCurrencyInfo }),
              amount: distance,
            },
          ),
        );
      }
    }
  }
  const limitDesc = getLimitDesc({ intl, limitQuotaRule, requireType });
  limitDesc && text.push(limitDesc);
  return text.join(', ');
};

/**
 * 满多少元打折
 * @param params
 */
const getPercentageAmount = (params) => {
  const {
    intl,
    discountInfo = {},
    discountGoods,
    currency,
    requireType = RequireType.Detail,
    language,
    multiCurrencyInfo,
  } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
    totalDiscountAmount,
    promotionRules = [], // 梯度价格
    limitQuotaRule = {}, // 客户使用限制
  } = discountInfo;
  const { discountValue = 10 } = discountGets;
  const { type, requireValue = 1 } = minimumRequirement;
  const requireAmount = intlLiToYuan({ currency, value: requireValue, multiCurrencyInfo });
  const totalDiscountAmountValue = intlLiToYuan({ currency, value: totalDiscountAmount, multiCurrencyInfo });
  const discount = getValueByLang(discountValue, language, true);

  const text: any = [];
  if (requireType === RequireType.Detail) {
    // 梯度价格文案
    if (promotionRules.length > 1) {
      promotionRules.forEach((item) => {
        const discountAmount = intlLiToYuan({
          currency,
          value: item?.minimumRequirement?.requireValue,
          multiCurrencyInfo,
        });
        text.push(
          intl.formatMessage(
            { id: '5a2740986a094b2eacd6b6b011329b6c', defaultMessage: '满 {condition} - {result}% 折扣' },
            { condition: discountAmount, result: item?.discountGets?.discountValue || 0 },
          ),
        );
      });
    } else {
      text.push(
        intl.formatMessage(
          { id: '796a1d2fc7c94fc78446c67c0a0a87ea', defaultMessage: '买满{requireAmount}享受{discountValue}折优惠' },
          { discountValue: discount, requireAmount },
        ),
      );
    }
  } else {
    const rules = promotionRules.length > 0 ? promotionRules : [{ discountGets, minimumRequirement }];
    const { distance, discountValue, distanceRequireValue } = getLadderDistance({ type, rules, discountGoods });
    if (totalDiscountAmount > 0) {
      if (distance > 0) {
        text.push(
          intl.formatMessage(
            {
              id: '8ce5730d112d493cbfe3d723a958bdb7',
              defaultMessage: '已节省 {totalDiscountAmountValue}，多购买 {amount}，可共减免 {discountValue}',
            },
            {
              totalDiscountAmountValue,
              discountValue: `${discountValue}%`,
              amount: intlLiToYuan({ currency, value: distance, multiCurrencyInfo }),
            },
          ),
        );
      } else {
        text.push(
          intl.formatMessage(
            {
              id: '6421cfe5bccd493982ae2d5847ac5136',
              defaultMessage: '已满 {requireAmount}，已减 {totalDiscountValue}',
            },
            { requireAmount, totalDiscountValue: totalDiscountAmountValue },
          ),
        );
      }
    } else {
      if (distance > 0) {
        text.push(
          intl.formatMessage(
            {
              id: 'b41cca2702fc4938ab378eb30294fdb2',
              defaultMessage: '买满 {requireValue} 享受 {discountValue} 折扣，还差 {amount}',
            },
            {
              requireValue: intlLiToYuan({ currency, value: distanceRequireValue, multiCurrencyInfo }),
              discountValue: `${discountValue}%`,
              amount: intlLiToYuan({ currency, value: distance, multiCurrencyInfo }),
            },
          ),
        );
      }
    }
  }
  const limitDesc = getLimitDesc({ intl, limitQuotaRule, requireType });
  limitDesc && text.push(limitDesc);
  return text.join(', ');
};

/**
 * 满多少件打折
 * @param params
 */
const getPercentageQuantity = (params) => {
  const {
    intl,
    currency,
    discountInfo = {},
    discountGoods,
    requireType = RequireType.Detail,
    language,
    multiCurrencyInfo,
  } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
    totalDiscountAmount,
    promotionRules = [], // 阶梯价格
    limitQuotaRule = {}, // 客户使用限制
  } = discountInfo;
  const { discountValue = 10 } = discountGets;
  const { type, requireValue = 1 } = minimumRequirement;
  const discount = getValueByLang(discountValue, language, true);
  const totalDiscountAmountValue = intlLiToYuan({ currency, value: totalDiscountAmount, multiCurrencyInfo });

  const text: any = [];
  if (requireType === RequireType.Detail) {
    // 梯度价格文案
    if (promotionRules.length > 1) {
      promotionRules.forEach((item) => {
        text.push(
          intl.formatMessage(
            { id: '93307aa4f2f942af841c3f9216c99a90', defaultMessage: '满 {condition} 件 {result}% 折扣' },
            { condition: item?.minimumRequirement?.requireValue || 0, result: item?.discountGets?.discountValue || 0 },
          ),
        );
      });
    } else {
      text.push(
        intl.formatMessage(
          { id: '0705a890843e429bb83a0896d886f92b', defaultMessage: '买满 {requireValue} 件享受 {discountValue} 折扣' },
          { requireValue, discountValue: `${discountValue}%` },
        ),
      );
    }
  } else {
    const rules = promotionRules.length > 0 ? promotionRules : [{ discountGets, minimumRequirement }];
    const { distance, discountValue, distanceRequireValue } = getLadderDistance({ type, rules, discountGoods });
    if (totalDiscountAmount > 0) {
      if (distance > 0) {
        text.push(
          intl.formatMessage(
            {
              id: 'c10050443dda4711891bf54004f5e165',
              defaultMessage: '已节省 {totalDiscountAmountValue}，多购买 {amount} 件，可共减免 {discountValue}',
            },
            {
              totalDiscountAmountValue,
              discountValue: `${discountValue}%`,
              amount: distance,
            },
          ),
        );
      } else {
        text.push(
          intl.formatMessage(
            {
              id: '27f773c7ef354f77a2970018d7ec36d1',
              defaultMessage: '已满 {requireValue} 件，已减 {totalDiscountValue}',
            },
            { requireValue, totalDiscountValue: totalDiscountAmountValue },
          ),
        );
      }
    } else {
      if (distance > 0) {
        text.push(
          intl.formatMessage(
            {
              id: '0e84df7746f84b72bf113f76ac3e985f',
              defaultMessage: '买满 {requireValue} 件享受 {discountValue} 优惠，还差 {distance} 件',
            },
            {
              requireValue: distanceRequireValue,
              discountValue: `${discountValue}%`,
              distance: distance,
            },
          ),
        );
      }
    }
  }
  const limitDesc = getLimitDesc({ intl, limitQuotaRule, requireType });
  limitDesc && text.push(limitDesc);
  return text.join(', ');
};

// TODO 国际化
function getDiscountQuantityMessage(discountQuantity, discountValue, intl, language) {
  if (discountValue === 100) {
    // 免费
    return intl.formatMessage(
      {
        id: '540dced3e9f44953be3b71809384460a',
        defaultMessage: '送{discountQuantity}件赠品',
      },
      { discountQuantity },
    );
  } else {
    // 制定商品享受多少折
    const discount = getValueByLang(discountValue, language, true);
    return intl.formatMessage(
      {
        id: '86555837c1384277872826ecd4f8ecf2',
        defaultMessage: '指定{discountQuantity}件商品享受{discount}折优惠',
      },
      { discountQuantity, discount },
    );
  }
}

/**
 * 满多少元，送指定商品打折
 * @param params
 */
const getBxgyAmount = (params) => {
  const {
    intl,
    discountInfo = {},
    discountGoods,
    currency,
    requireType = RequireType.Detail,
    isGiftGoodsPage,
    giftGoodsCount,
    language,
    multiCurrencyInfo,
  } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
  } = discountInfo;
  const { discountQuantity, discountValue } = discountGets;
  const { type, requireValue = 1 } = minimumRequirement;

  const amount = intlLiToYuan({ currency, value: requireValue, multiCurrencyInfo });

  if (requireType === RequireType.Detail) {
    return intl.formatMessage(
      {
        id: '3e1dff79a6a84b4597626eb5754329ed',
        defaultMessage: '买满{requireValue}，{discountQuantityMessage}',
      },
      {
        requireValue: amount,
        discountQuantityMessage: getDiscountQuantityMessage(discountQuantity, discountValue, intl, language),
      },
    );
  } else {
    const distance = getFitDistance({ type, requireValue, discountGoods });
    const distanceAmount = intlLiToYuan({ currency, value: distance, multiCurrencyInfo });
    const leftGiftGoodsIntlMessage = intl.formatMessage(
      {
        id: 'cart_selected_already_goods_gift_total',
        defaultMessage: '已选{giftCount}/{totalCount}件',
      },
      { giftCount: giftGoodsCount, totalCount: discountQuantity },
    );

    const leftGiftGoodsMessage = isGiftGoodsPage ? `，${leftGiftGoodsIntlMessage}` : '';
    const fitMessage = intl.formatMessage(
      {
        id: 'bce41984e91e40c98b8673ce81af81d0',
        defaultMessage: '已满{requireValue}，{discountQuantityMessage}',
      },
      {
        requireValue: amount,
        discountQuantityMessage: getDiscountQuantityMessage(discountQuantity, discountValue, intl, language),
      },
    );
    const notFitMessage = intl.formatMessage(
      {
        id: 'a0a7c41711964eb9bcc73e698ea28cb7',
        defaultMessage: '买满{requireValue},{discountQuantityMessage},还差{distanceAmount}',
      },
      {
        requireValue: amount,
        discountQuantityMessage: getDiscountQuantityMessage(discountQuantity, discountValue, intl, language),
        distanceAmount,
      },
    );
    return distance > 0 ? notFitMessage : fitMessage + leftGiftGoodsMessage;
  }
};

/**
 * 满多少件，送指定商品打折
 * @param params
 */
const getBxgyQuantity = (params) => {
  const {
    intl,
    discountInfo = {},
    discountGoods,
    requireType = RequireType.Detail,
    isGiftGoodsPage,
    giftGoodsCount,
    language,
  } = params;
  const {
    discountGets = {},
    minimumRequirement = {}, // 折扣条件
  } = discountInfo;
  const { discountValue = 10, discountQuantity } = discountGets;
  const { type, requireValue = 1 } = minimumRequirement;
  if (requireType === RequireType.Detail) {
    return intl.formatMessage(
      {
        id: 'caf3e5a103f3452a9ec63226fe2b6565',
        defaultMessage: '买满{requireValue}件，{discountQuantityMessage}',
      },
      {
        requireValue,
        discountQuantityMessage: getDiscountQuantityMessage(discountQuantity, discountValue, intl, language),
      },
    );
  } else {
    const distance = getFitDistance({ type, requireValue, discountGoods });
    const fitMessage = intl.formatMessage(
      {
        id: '4ddaa5a98bb94212b0034d45b5f2d8b9',
        defaultMessage: '已满{requireValue}件，{discountQuantityMessage}',
      },
      {
        requireValue,
        discountQuantityMessage: getDiscountQuantityMessage(discountQuantity, discountValue, intl, language),
      },
    );
    // 组件传了 giftGoodsCount 并且 giftGoodsCount > 0, 展示leftGiftGoodsMessage
    const leftGiftGoodsIntlMessage = intl.formatMessage(
      {
        id: 'cart_selected_already_goods_gift_total',
        defaultMessage: '已选{giftCount}/{totalCount}件',
      },
      { giftCount: giftGoodsCount, totalCount: discountQuantity },
    );
    const leftGiftGoodsMessage = isGiftGoodsPage ? `，${leftGiftGoodsIntlMessage}` : '';
    const notFitMessage = intl.formatMessage(
      {
        id: 'f8c63c1097ef4bf8a4009a6d1a57078b',
        defaultMessage: '买满{requireValue}件，{discountQuantityMessage}，还差{distance}件',
      },
      {
        requireValue,
        discountQuantityMessage: getDiscountQuantityMessage(discountQuantity, discountValue, intl, language),
        distance,
      },
    );
    return distance > 0 ? notFitMessage : fitMessage + leftGiftGoodsMessage;
  }
};

/** 获取的折扣信息的描述文本 */
export const getDiscountDesc = (params) => {
  const { discountInfo = {}, multiCurrencyInfo } = params;
  const {
    discountType, // 折扣类型
    minimumRequirement = {},
  } = discountInfo;
  const { type } = minimumRequirement;
  const isLimitAmount = type === DiscountLimitType.Amount;
  switch (discountType) {
    case DiscountType.FixedAmount:
      return isLimitAmount ? getFixedAmountAmountDesc(params) : getFixedAmountQuantity(params);

    case DiscountType.Percentage:
      return isLimitAmount ? getPercentageAmount(params) : getPercentageQuantity(params);

    case DiscountType.Bxgy:
      return isLimitAmount ? getBxgyAmount(params) : getBxgyQuantity(params);

    default:
      return '';
  }
};

export const getDiscountPrice = (params) => {
  const { discountInfo = {}, price } = params;
  const { discountGets = {} } = discountInfo;
  const { discountValue = 0 } = discountGets;
  const {
    discountType = DiscountType.FixedAmount, // 折扣类型
  } = discountInfo;
  if (discountType === DiscountType.Percentage) {
    return price * (1 - new Big(discountValue).div(10));
  }
};

export const getUnitDiscount = (discount, quantity) => {
  if (quantity === 0) return 0;
  return Number(new Big(discount).div(quantity).toFixed(2));
};

// 计算商详页折扣价
export const calcGoodsDiscountPrice = (params) => {
  const { price, quantity, discountInfos = [] } = params;
  const { discountType, promotionRules = [] } = discountInfos?.[0] || {};
  if (discountType === 'bxgy' || discountType === 'freeShipping') return null;

  let newPrice: any = null;
  let totalPrice = price * quantity;
  for (const item of promotionRules) {
    const { discountGets, minimumRequirement } = item;
    if (!minimumRequirement?.type) {
      if (discountGets.type === 'percentage') {
        newPrice = (totalPrice * (100 - discountGets.discountValue)) / 100;
      }
      if (discountGets.type === 'amount') {
        if (totalPrice > discountGets.discountValue) {
          newPrice = totalPrice - discountGets.discountValue;
        }
      }
      break;
    }
    if (minimumRequirement.type === 'quantity') {
      if (quantity >= minimumRequirement.requireValue) {
        let tempPrice;
        if (discountGets.type === 'percentage') {
          tempPrice = (totalPrice * (100 - discountGets.discountValue)) / 100;
        }
        if (discountGets.type === 'amount') {
          if (totalPrice > discountGets.discountValue) {
            tempPrice = totalPrice - discountGets.discountValue;
          }
        }
        if (!newPrice || (tempPrice && tempPrice < newPrice)) {
          newPrice = tempPrice;
        }
      }
      continue;
    }
    if (minimumRequirement?.type === 'amount') {
      if (totalPrice >= minimumRequirement.requireValue) {
        let tempPrice;
        if (discountGets.type === 'percentage') {
          tempPrice = (totalPrice * (100 - discountGets.discountValue)) / 100;
        }
        if (discountGets.type === 'amount') {
          if (totalPrice > discountGets.discountValue) {
            tempPrice = totalPrice - discountGets.discountValue;
          }
        }
        if (!newPrice || (tempPrice && tempPrice < newPrice)) {
          newPrice = tempPrice;
        }
      }
    }
  }
  if (newPrice) {
    return newPrice / quantity;
  } else {
    return null;
  }
};
