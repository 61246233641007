import { useEffect, useState } from 'react';
import { SWIPER1_TEXT_SIZE_MAP } from '../../../../const/standard';

// 兼容旧数据
// 如果有titleTextSize或者descTextSize，证明是新的
export const useTextSize = (setting) => {
  const [textSizeMap, setTextSizeMap] = useState({});
  useEffect(() => {
    const { title_text_size: titleTextSize, desc_text_size: descTextSize, text_size: textSize } = setting;

    console.log('textSize', textSize, setting);

    if (titleTextSize || descTextSize) {
      setTextSizeMap({
        title: titleTextSize,
        desc: descTextSize,
      });
    } else {
      setTextSizeMap({
        title: SWIPER1_TEXT_SIZE_MAP[textSize]?.title,
        desc: SWIPER1_TEXT_SIZE_MAP[textSize]?.desc,
      });
    }
  }, [setting]);

  return {
    textSizeMap,
  };
};
