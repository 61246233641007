import styled from 'styled-components';

export const Style = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .login-bg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: ${(props) => props.theme.getSpace([12, 14, 16])};
  }
  .login-text {
    margin-top: ${(props) => props.theme.getSpace(props.theme.vText)};
  }
  .login-operate {
    display: flex;
    justify-content: center;
    gap: ${(props) => props.theme.getSpace(props.theme.hButtons)};
    margin-top: ${(props) => props.theme.getSpace(props.theme.vTextButtons)};
  }
  .user-avatar {
    margin-bottom: ${(props) => props.theme.getSpace(props.theme.vTexts)};
  }
`;
