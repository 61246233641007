/**
 * 获取数据
 * @param {*} props
 */
import qs from 'querystring';
import isArray from 'lodash/isArray';

export const safeJsonParse = (json = 'null') => {
  let result = null;
  try {
    result = JSON.parse(json);
  } catch (err) {
    console.error(err);
  }

  return result;
};

export const safeJsonStringify = (obj) => {
  let result = '';
  try {
    result = JSON.stringify(obj);
  } catch (err) {
    console.error(err);
  }

  return result;
};

/** 这个方法没什么鸟用，别用了 */
export const envInitialData = (props) => {
  return props.initialData || {};
};

export const getAimComp = (routeList, pathname) => {
  const len = routeList.length;
  for (let i = 0; i < len; i += 1) {
    if (pathname.includes(routeList[i].path)) {
      return routeList[i];
    }
  }
};

export const getQueryString = (hash) => {
  return qs.parse(hash.substr(hash.indexOf('?') + 1));
};

export const getPrice = (price) => {
  return (price / 1000).toFixed(2);
};
export const queryToUrlString = (query) => {
  return Object.keys(query)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
    })
    .join('&');
};

export const filterEmptyObj = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value == null || value == undefined || value == '') return;
    if (isArray(value) && !value.length) return;
    if (JSON.stringify(value) == '{}') return;
    newObj[key] = value;
  });
  return newObj;
};

export const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));
