import React from 'react';
import ShoppingCart from '../shopping-cart';
import 'zent/css/index.css';
import ReactDOM from 'react-dom';

const ShoppingCartBox = ({ initialData, onClose }) => (
  <div className='shopping-cart' id="'shopping-cart-box'">
    <div
      onClick={onClose}
      className='mask'
      style={{
        position: 'fixed',
        top: '0px',
        bottom: 0,
        background: 'rgba(0,0,0, 0.3)',
        zIndex: 9999,
        width: '100%',
      }}
    />
    <ShoppingCart initData={initialData} onClose={onClose} />
  </div>
);

export const CartModal = React.memo(({ initialData, visible, onClose }) => {
  if (__isServer || !visible) return null;
  const cartRef = document.getElementById('shopping-cart-box');
  return ReactDOM.createPortal(<ShoppingCartBox onClose={onClose} initialData={initialData} />, cartRef);
});
