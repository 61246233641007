import React from 'react';
import { Button, Radio } from 'zent';
import Style from './style';

const RadioGroup = Radio.Group;

function SelectLanguageCurrency(props) {
  const {
    language,
    languageList,
    multiCurrencies,
    multiCurrency,
    setLanguage,
    setMultiCurrency,
    updateLanguageCookie,
    updateCurrencyCookie,
    isShowMultiCurrency,
    isShowMultiLanguage,
    intl,
  } = props;
  const onLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const onMultiCurrencyChange = (e) => {
    setMultiCurrency(e.target.value);
  };

  const onConfirm = () => {
    updateLanguageCookie(language);
    updateCurrencyCookie(multiCurrency);
    window.location.reload();
  };

  return (
    <Style>
      <div className='setting-list'>
        {isShowMultiLanguage && (
          <div className='setting-item'>
            <div className='setting-item-label'>
              {intl.formatMessage({
                id: 'account_language',
                defaultMessage: '语言',
              })}
            </div>
            <div className='setting-item-content'>
              <RadioGroup className='radio-group' value={language} onChange={onLanguageChange}>
                {languageList.map((item) => (
                  <Radio value={item.language} className='radio-item'>
                    {item.name}
                  </Radio>
                ))}
              </RadioGroup>
            </div>
          </div>
        )}
        {isShowMultiCurrency && (
          <div className='setting-item'>
            <div className='setting-item-label'>
              {intl.formatMessage({
                id: '65fff7d21c2c4aad823fc7b136258734',
                defaultMessage: '币种',
              })}
            </div>
            <div className='setting-item-content'>
              <RadioGroup className='radio-group' value={multiCurrency} onChange={onMultiCurrencyChange}>
                {multiCurrencies.map((item) => (
                  <Radio value={item.currency} className='radio-item'>
                    {item.currency}
                  </Radio>
                ))}
              </RadioGroup>
            </div>
          </div>
        )}
      </div>
      <Button className='confirm-btn' type='primary' onClick={onConfirm}>
        {intl.formatMessage({
          id: 'common_confirm',
          defaultMessage: '确定',
        })}
      </Button>
    </Style>
  );
}

export default SelectLanguageCurrency;
