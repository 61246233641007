export const blockOrderFilter = (blockOrder, blocks) => {
  let newBlockOrder = blockOrder || [];
  try {
    newBlockOrder = blockOrder.filter((key) => Object.keys(blocks).includes(`${key}`));
  } catch (e) {
    // 防御性编程
    // eslint-disable-next-line no-console
    console.log('装修可能有脏数据 e', e);
  }
  return newBlockOrder;
};
