import styled from 'styled-components';

export default styled.div`
  .pc-coupon {
    position: fixed;
    z-index: 10;
    width: 320px;
    /* height: 202px; */
    background: linear-gradient(134deg, #f2e7d7 0%, #e6ceb7 47%, #f5dfc6 100%);
    box-shadow: 0px 4px 16px 0px rgba(74, 66, 61, 0.2);
    padding: 24px 16px 24px 24px;
    border-radius: 8px;
    right: 40px;
    bottom: 40px;
  }

  .coupon-close {
    text-align: right;
  }

  .close-coupon-icon {
    text-align: right;
    width: 16px;
    height: 16px;
    fill: #4a423d;
  }

  .distribution-name {
    height: 18px;
    font-size: 18px;
    font-weight: 600;
    color: #4a423d;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .coupon-text {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 400;
    color: #4a423d;
    line-height: 18px;
    margin-bottom: 24px;
  }

  .coupon-name {
    padding: 12px;
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
    color: #4a423d;
    background: rgba(255, 255, 255, 0.3);
  }

  .copy {
    display: inline-block;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    color: #f0e2d1;
    text-align: center;
    background: #4a423d;
  }

  .mask {
    display: none;
  }

  .coupon-block {
    max-width: 327px;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    background: linear-gradient(134deg, #f2e7d7 0%, #e6ceb7 47%, #f5dfc6 100%);
    border-radius: 4px;
    .coupon-block-left {
      flex: 2;
    }
    .coupon-block-right {
      flex: 1;
      text-align: right;
    }
    .coupon-text {
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      color: #4a423d;
      margin-bottom: 8px;
      margin-top: 0;
    }

    .coupon-name {
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      color: #4a423d;
      padding: 2px 4px;
      display: inline-block;
      margin-bottom: 0;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
    }

    .copy-coupon {
      padding: 5px 12px;
      display: inline-block;
      background: #4a423d;
      border-radius: 19px;
      font-size: 11px;
      font-weight: 400;
      color: #f0e2d1;
      line-height: 12px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 750px) {
    .mask {
      top: 0;
      left: 0;
      display: block;
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 10;
      background: rgba(0, 0, 0, 0.5);
    }
    .pc-coupon {
      left: 50%;
      top: 50%;
      width: 289px;
      bottom: auto;
      right: auto;
      padding: 16px 16px 22px 22px;
      transform: translate(-50%, -50%);
    }

    .distribution-name {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 12px;
    }

    .coupon-text {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 16px;
    }

    .coupon-name {
      margin-bottom: 16px;
    }
  }
`;
