import React, { useEffect, useState } from 'react';
import { sleep } from 'utils/index';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { diffRelativeDateTimeWithDay } from 'utils/datetime';
import { TimeStyle } from './style';

/**
 *
 * @param param0
 * onTimeDone 计时结束
 */
export const CountDown = ({ className = '', timeEndAt, styleType = 'style2', onTimeDone }) => {
  const [time, setTime] = useState({
    day: '0',
    hour: '00',
    min: '00',
    second: '00',
  }); // 倒计时时间
  let timer = null;
  const intl = useIntl();

  useEffect(() => {
    if (!timeEndAt) return;
    timer = setInterval(async () => {
      const relativeTime = diffRelativeDateTimeWithDay(timeEndAt);
      const formateTime = getWithZero(relativeTime); // 不满10的数补充0
      setTime(formateTime);
      if (!relativeTime?.second && !relativeTime?.hour && !relativeTime?.min && !relativeTime?.day) {
        clearInterval(timer);
        await sleep(3000);
        onTimeDone && onTimeDone();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timeEndAt]);

  const getWithZero = (relativeTime) => {
    const newTime = {};
    Object.keys(relativeTime).map((key) => {
      // 天不补充0
      if (key !== 'day') {
        newTime[key] = relativeTime?.[key] < 10 ? `0${relativeTime?.[key] ?? 0}` : relativeTime?.[key] + '';
      } else {
        newTime[key] = relativeTime?.[key] + '';
      }
    });
    return newTime;
  };

  const showDay = time.day !== '0';
  return (
    <TimeStyle className={cn(styleType, className)}>
      {showDay && <div className='time-item time-value time-day'>{time.day}</div>}
      {showDay && (
        <div className='time-item time-text'>
          {intl.formatMessage({
            id: '1621378f794d414a8a47b70d740d12eb',
            defaultMessage: 'd',
          })}
        </div>
      )}
      <div className='time-item time-value time-hour'>{time.hour}</div>
      <div className='time-item'>:</div>
      <div className='time-item time-value'>{time.min}</div>
      <div className='time-item'>:</div>
      <div className='time-item time-value'>{time.second}</div>
    </TimeStyle>
  );
};
