import styled from 'styled-components';

export const Style = styled.div`
  .footer-logo-section {
    .footer-logo-img img {
      max-width: 100%;
      max-height: 100%;
    }
    .footer-logo-title {
      font-size: ${(props) => props.theme.getHeaderFontSize([14, 20])};
      background: none !important;
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
    }
    .footer-logo-sub-title {
      margin: 6px 0 12px;
      opacity: 0.6;
      font-size: ${(props) => props.theme.getHeaderFontSize([12, 14])};
    }
    .social-media-icon {
      margin-top: 16px;
      margin-bottom: -12px;
      .footer-social-icon-link {
        margin: 0 14px 12px 0;
        display: inline-block;
        svg {
          height: ${(props) => `${props.mediaLogoSize}px`};
          width: ${(props) => `${props.mediaLogoSize}px`};
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    .footer-logo-section {
      text-align: ${(props) => props.mobileDeviceFooterTextAlign};
      .footer-logo-img {
        display: inline-block;
      }
      .social-media-icon {
        margin-top: 16px;
      }
      .mobile-pic-full-screen-true,
      .mobile-pic-full-screen-true img {
        width: 100% !important;
      }
    }
  }
`;
