/**
 * 菜单相关的api接口封装
 */
import BaseApi from './base';

class LocalApi extends BaseApi {
  getCurrencies(params) {
    return this._get('/api/node/com.youzan.i18n.locale.web.LocaleController/getCurrencies', params);
  }

  /**
   * 获取视频播放地址
   */
  getVideoPlayInfo(params = {}) {
    return this._get(
      '/api/soa/com.youzan.i18n.file.controller.web.ShopVideoCustomerController/getVideoPlayInfo',
      params,
    );
  }
}

export default new LocalApi();
