import styled, { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
    .review-success-dialog {
        .zent-dialog-r-close {
            top: 20px !important;
            right: 20px !important;

            .zenticon-close {
                font-size: 20px;
            }
        }
    }
`;

export const Confirm = styled.div`
  .review-confirm-text {
    font-size: 17px;
    font-weight: 500;
    color: var(--color_body_text);
    line-height: 24px;
    text-align: center;
  }

  .review-confirm-btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .zent-btn {
      flex: 1;
    }
  }

  @media screen and (min-width: 769px) {
    &.review-confirm-box {
      width: 288px;
    }

    .review-confirm-text {
      text-align: left;
    }
    .review-confirm-btn {
      margin-top: 32px;
    }
  }
`;

export const Success = styled.div`
  &.review-success-box {
    padding: 20px 0 64px;
    text-align: center;
  }

  .review-success-img {
    width: 80px;
  }

  .review-success-text {
    margin-top: 40px;
    color: var(--color_body_text);
    line-height: 1;
  }

  .success-text-title {
    font-size: 32px;
    font-weight: 500;
  }

  .success-text-desc {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    .success-text-title {
      font-size: 24px;
    }

    .success-text-desc {
      font-size: 14px;
    }
  }
`;

export const Poster = styled.div`
  &.review-poster-box {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;

    .close-pc-icon {
      display: block;
    }

    .close-mobile-icon {
      display: none;
    }

    .review-poster-header {
      display: none;
    }

    .review-score-rate {
      .zent-rate-star-icon {
        font-size: 28px;
      }
    }

    .review-box-title {
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: 600;
      color: var(--color_body_text);
      line-height: 1;
    }

    .review-text-content {
      margin-top: 40px;

      .zent-textarea {
        padding: 20px 16px;
      }
    }

    .zent-textarea-wrapper {
      width: 100%;
      height: 128px !important;

      .zent-textarea {
        height: 100% !important;
        color: var(--color_text_field_text) !important;
        background-color: var(--color_text_field) !important;
      }
    }

    .review-image-upload {
      margin-top: 16px;
    }

    .review-image-list {
      display: flex;
      flex-wrap: wrap;
    }

    .zent-image-upload-trigger {
      width: 90px;
      height: 90px;
    }

    .review-submit-btn {
      margin-top: 24px;
      margin-left: auto;
    }

    .review-person-info {
      margin-top: 32px;

      .review-email-box {
        margin-top: 16px;
      }

      .zent-input-wrapper {
        flex: 1;
        line-height: 56px;
        height: 56px;
      }
    }

    .review-name-box {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .zent-form-control {
        flex: 1;
        width: calc(50% - 8px);

        &:last-child {
          margin-left: 16px;
        }
      }
    }

    .close-review-icon {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 24px;
      width: 24px;
      height: 24px;
      fill: var(--color_body_text);
    }

    @media screen and (max-width: 768px) {
      &.review-poster-box {
        height: 100vh;
        background: #fff;
        z-index: 100;
        overflow-y: auto;
      }

      .close-pc-icon {
        display: none;
      }

      .close-mobile-icon {
        display: block;
      }

      .review-poster-header {
        display: block;
        line-height: 72px;
        background: #fff;
        font-size: 18px;
        font-weight: 500;
        color: var(--color_body_text);
        text-align: center;
      }

      .review-score-content {
        display: flex;
        align-items: center;

        .review-box-title {
          margin-bottom: 0;
        }
      }

      .review-text-title {
        display: none;
      }

      .review-text-content {
        margin-top: 24px;
      }

      .zent-textarea-wrapper {
        border: none !important;
      }

      .zent-image-upload-trigger {
        width: 104px;
        height: 104px;
      }

      .review-score-rate {
        margin-left: 12px;

        .zent-rate-star-icon {
          font-size: 18px;
        }
      }

      .review-submit-btn {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }
`;
