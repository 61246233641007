/**
 * 第三方配置的api接口封装
 */
import BaseApi from './base';

class ThirdPlatformApi extends BaseApi {
  /**
   * @name 获取店铺 Facebook Messenger 代码配置
   * @description http://zanapi.qima-inc.com/site/service/view/1055280
   */
  getFacebookMessengerCode(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.appstore.thridplatform.controller.web.FacebookMessengerController/getFacebookMessengerCode',
      params,
    );
  }

  /**
   * @name 获取店铺 Google Analytics配置
   * @description http://zanapi.qima-inc.com/site/service/view/1055280
   */
  getDataAnalyticsSetting(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.appstore.thridplatform.controller.web.ThirdPlatformSettingController/getDataAnalyticsSetting',
      params,
    );
  }

  reportFbPixelEvent(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.appstore.thridplatform.controller.web.FacebookPixelController/reportFbPixelEvent',
      params,
      {
        commonErrorHandle: false,
      },
    );
  }

  getAllPixel(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.appstore.thridplatform.controller.web.FacebookPixelController/getAllPixel',
      params,
    );
  }
  getCredentials(params) {
    return this._get('/api/soa/com.youzan.i18n.asset.controller.web.PaymentController/getCredentials', params);
  }
}

export default new ThirdPlatformApi();
