import styled, { createGlobalStyle } from 'styled-components';

export default styled.div`
  flex: 1;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  .operate-icon {
    width: 24px;
    height: 24px;
  }
  .settings-icon {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
  .icon-wrapper {
    position: relative;
  }
  .total-count {
    position: absolute;
    line-height: 1;
    font-size: 14px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #d40000;
    border-radius: 18px;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
`;

export const GlobalStyle = createGlobalStyle`
.mobile-header-dropdown {
    padding: 0 16px ;
    width: 170px;
    box-sizing: border-box;
    .dropdown-item {
        display: flex;
        align-items:center;
        padding: 18px 0;
        font-size: 14px;
        color: #333;
        border-bottom: 1px solid rgba(38, 38, 38, 0.08);;
    }
    .dropdown-icon {
        width: 24px;
        height: 24px;
        margin-right: 14px;
    }
    .zent-pop-inner {
        padding: 0 !important;
    }
    .dropdown-icon-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }
    .dropdown-icon-badge {
        position: absolute;
        line-height: 1;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #d40000;
        border-radius: 18px;
        top: 0;
        right: 0;
        transform: translate(-50%, -50%);
    }
    .dropdown-name {
        flex: 1;
        word-break: break-word;
        color: var(--color_body_text);
    }
    svg {
        fill: var(--color_body_text);
    }
}
`;
