import styled from 'styled-components';

export const Style = styled.div`
  position: relative;
  width: 100%;
  max-width: ${(props) => (props.theme?.containerWidth ? `${props.theme?.containerWidth}px` : '1280px')};
  margin: 0 auto;
  .header-wrapper {
    height: 80px;
    background-color: #fff;
    display: flex;
    align-items: center;
    .logo-wrapper {
      width: auto;
      height: 60px;
      display: flex;
      align-items: center;
      z-index: 100;
      img {
        max-height: 32px;
        margin-right: 27px;
      }
    }
    .checkout-wrapper {
      height: 20px;
      padding-left: 27px;
      border-left: solid 1px rgba(var(--color_body_text_rgb), 0.2);
      display: flex;
      align-items: center;
      .text {
        margin-left: 6px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .header-wrapper {
      height: 72px;
    }
    .background {
      height: 72px;
    }
  }
`;
