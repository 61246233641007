import { useEffect, useRef } from 'react';

let distanceContentLeft = 0;
let distanceContentRight = 0;
// let distanceContentPage = 0;

export const ScrollDomContainer = 'h5-preview-container2';
export const ScrollDomContent = 'h5-preview-content2';

// isScroll 是否需要滚动动效
// reverse 表示反序执行
const useScrollContainer = (isScroll, reverse = false) => {
  const containerPage = useRef('');
  const containerLeft = useRef('');
  const containerRight = useRef('');
  const contentPage = useRef('');
  const contentLeft = useRef('');
  const contentRight = useRef('');
  const disabledScrollStatusRef = useRef(false);
  const getScrollDistance = () => {
    const containerPageHeight = containerPage?.current?.offsetHeight;
    const containerLeftHeight = containerLeft?.current?.offsetHeight;
    const containerRightHeight = containerRight?.current?.offsetHeight;
    const contentPageHeight = contentPage?.current?.offsetHeight;
    const contentLeftHeight = contentLeft?.current?.offsetHeight;
    const contentRightHeight = contentRight?.current?.offsetHeight;

    distanceContentRight = contentRightHeight - containerRightHeight;
    // 左边容器可滚动的最大距离
    distanceContentLeft = contentLeftHeight - containerLeftHeight;
    // 页面容器可滚动的最大距离
    // distanceContentPage = contentPageHeight - containerPageHeight;
  };

  const preventDefault = (e) => {
    if (disabledScrollStatusRef.current) {
      return;
    }
    e.preventDefault();
  };

  const scrollLeft = (e) => {
    containerLeft.current.scrollTop = containerLeft.current.scrollTop - e.wheelDeltaY;
  };

  const scrollRight = (e) => {
    containerRight.current.scrollTop = containerRight.current.scrollTop - e.wheelDeltaY;
  };

  const scrollFullPage = (e) => {
    containerPage.current.scrollTop = containerPage.current.scrollTop - e.wheelDeltaY;
  };

  /** 正向滚动：按照左、右、全屏的顺序滚动 */
  const handlePositiveScroll = (e) => {
    if (e.wheelDelta < 0) {
      /** 由于offsetHeight值是整数，和实际值有一定程度的偏差，所以计算出的distanceContent*不一定正确，
       * 导致 *.current.scrollTop一直无法小于distanceContent*，
       * 这里把这段偏差考虑进去，给滚动实际距离+1，以补齐这部分偏差
       *  */

      /** 如果是向下滚动 */
      if (containerLeft.current.scrollTop + 1 < distanceContentLeft) {
        /** 滚动左侧部分 */
        scrollLeft(e);
        return;
      } else if (containerRight.current.scrollTop + 1 < distanceContentRight) {
        scrollRight(e);
        return;
      }

      scrollFullPage(e);
    } else {
      /** 如果是向上滚动 */
      if (containerPage.current.scrollTop > 0) {
        scrollFullPage(e);
        return;
      } else if (containerRight.current.scrollTop > 0) {
        scrollRight(e);
        return;
      }

      scrollLeft(e);
    }
  };

  /** 反向滚动：按照右、左、全屏的顺序滚动 */
  const handleReverseScroll = (e) => {
    if (e.wheelDelta < 0) {
      /** 如果是向下滚动 */
      if (containerLeft.current.scrollTop < distanceContentLeft) {
        /** 滚动左侧部分 */
        scrollLeft(e);
        return;
      } else if (containerRight.current.scrollTop < distanceContentRight) {
        scrollRight(e);
        return;
      }

      scrollFullPage(e);
    } else {
      /** 如果是向上滚动 */
      if (containerPage.current.scrollTop > 0) {
        scrollFullPage(e);
        return;
      } else if (containerRight.current.scrollTop > 0) {
        scrollRight(e);
        return;
      }

      scrollLeft(e);
    }
  };

  /** 关于滚动顺序的封装，相信还可以精进，⛽️ */
  const onScroll = (e) => {
    if (disabledScrollStatusRef.current) {
      return;
    }
    if (reverse) {
      handleReverseScroll(e);
    } else {
      handlePositiveScroll(e);
    }
  };
  // 监听详细页所有子节点的变化，已便重新计算详细页高度做滚动计算
  const watchDetailHeight = () => {
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    // 创建观察者对象
    const observer = new MutationObserver(() => {
      getScrollDistance();
    });
    // 配置观察选项:
    const config = { childList: true, attributes: true, characterData: true, subtree: true };
    observer.observe(containerPage.current, config);
    return () => {
      observer.disconnect();
    };
  };

  useEffect(() => {
    // 响应事件
    const handleEvent = ({ data: eventName }) => {
      if (eventName === 'closeRightShoppingCart') {
        disabledScrollStatusRef.current = false;
      }
      if (eventName === 'openRightShoppingCart') {
        disabledScrollStatusRef.current = true;
      }
    };
    window.addEventListener('message', handleEvent);
    return () => {
      disabledScrollStatusRef.current = false;
      window.removeEventListener('message', handleEvent);
    };
  }, []);

  useEffect(() => {
    if (!isScroll) return;
    if (!containerPage.current) {
      containerPage.current = document.getElementsByClassName(ScrollDomContainer)[0];
    }
    if (!contentPage.current) {
      contentPage.current = document.getElementsByClassName(ScrollDomContent)[0];
    }
    containerPage?.current && containerPage.current.addEventListener('mousewheel', preventDefault);
    containerLeft?.current && containerLeft.current.addEventListener('mousewheel', preventDefault);
    containerRight?.current && containerRight.current.addEventListener('mousewheel', preventDefault);
    document.body.addEventListener('mousewheel', onScroll, true);
    const watchReturn = watchDetailHeight();
    return () => {
      containerPage.current.removeEventListener('mousewheel', preventDefault);
      containerLeft.current.removeEventListener('mousewheel', preventDefault);
      containerRight.current.removeEventListener('mousewheel', preventDefault);
      document.body.removeEventListener('mousewheel', onScroll, true);
      watchReturn();
    };
  }, [isScroll, reverse]);

  return {
    containerPage,
    containerLeft,
    containerRight,
    contentPage,
    contentLeft,
    contentRight,
  };
};

export default useScrollContainer;
