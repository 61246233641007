import React, { useContext, useRef, useEffect } from 'react';
import DefaultImg from 'cpn/default-img';
import { Style } from './style';
import ImageContainer from '../../../../../../pages/design/components/image-container';
import VideoContainer from '../../../../../../pages/design/components/video-container';
import { ContextGoodDetail } from '../../../../../../pages/context/context-good-detail';

export const ProductMediaTwo = ({ grid }) => {
  const { imageRatio, productMedia } = useContext(ContextGoodDetail);
  const { mainMediaIndex, mediaList } = productMedia;
  const mediaRef = useRef(null);

  useEffect(() => {
    const scrollHeight = Array.from(mediaRef.current?.children).reduce((result, current, index) => {
      if (index <= mainMediaIndex) {
        return result + (current?.clientHeight + 8) || 0;
      }

      return result;
    }, 0) as number;

    const scrollContainer = document.querySelector('.detail-left-container');

    if (scrollContainer && mainMediaIndex !== 0) {
      scrollContainer.scrollTo(0, scrollHeight);
    }
  }, [mainMediaIndex]);

  return (
    <Style gridNum={grid}>
      <div ref={mediaRef} className='product-media-two'>
        {mediaList?.length > 0 ? (
          mediaList.map((item, index) => {
            return (
              <div key={index} className='item'>
                {item.type === 'IMAGE' ? (
                  <ImageContainer
                    heightType={imageRatio}
                    heightRules='type3'
                    fit='cover'
                    position='center center'
                    url={item?.url}
                  />
                ) : (
                  // 这里利用了ImageContainer的宽高计算功能
                  <ImageContainer heightType={imageRatio} heightRules='type3' hideImg>
                    <VideoContainer
                      autoPlay={false}
                      muted // 声音如果不禁止，有可能视频不能播放
                      mediaId={item.mediaId}
                      firstImage={item.coverUrl}
                    />
                  </ImageContainer>
                )}
              </div>
            );
          })
        ) : (
          <DefaultImg square height='41%' width='auto' />
        )}
      </div>
    </Style>
  );
};
