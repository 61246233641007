import styled, { css } from 'styled-components';
import { gapMap } from '../../../const/standard';
import isArray from 'lodash/isArray';

export const getPadding = (props) => {
  // const arr = [];
  const { paddingTop, paddingBottom, paddingLeft, paddingRight, padding } = props;
  let calcPadding = '';
  if (padding) {
    if (isArray(padding)) {
      calcPadding = padding.map((item) => props.theme.getSpace(gapMap[item])).join(' ');
    } else {
      calcPadding = props.theme.getSpace(gapMap[padding]);
    }
  }
  // padding: [20] [20,30] [20,30,20] [20, 20, 30, 30]
  return css`
    ${paddingTop ? `padding-top:${props.theme.getSpace(gapMap[paddingTop])}` : ''};
    ${paddingBottom ? `padding-bottom:${props.theme.getSpace(gapMap[paddingBottom])}` : ''};
    ${paddingLeft ? `padding-left:${props.theme.getSpace(gapMap[paddingLeft])}` : ''};
    ${paddingRight ? `padding-right:${props.theme.getSpace(gapMap[paddingRight])}` : ''};
    ${padding ? `padding:${calcPadding}` : ''};
  `;
};

export default styled.div`
  ${(props) => getPadding(props)}
`;
