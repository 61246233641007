import styled from 'styled-components';

export const HtFormInputPasswordWrapper = styled.div`
  position: relative;
  .ht-password-icon {
    position: absolute;
    right: 16px;
    top: ${(props) => props.theme.getSpace([13, 15, 17])};
  }
  .zent-input-close {
    margin-right: 50px !important;
    font-size: 16px;
  }
`;
