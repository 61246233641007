import React, { useState } from 'react';
import Image from 'components/base/image';
import { Style } from './style';
import cn from 'classnames';

function PhotoViewPc({ photoImages, handle }) {
  const [current, setCurrent] = useState(-1);

  const handleImgItemClick = (index) => {
    if (index === current) {
      setCurrent(-1);

      return;
    }
    setCurrent(index);
  };

  return (
    <Style className='pc-review-image'>
      <ul className='review-image-list'>
        {photoImages.map((item, index) => (
          <li
            key={item}
            className={cn('review-image-item', { 'review-image-zoom': index === current })}
            onClick={() => handleImgItemClick(index)}
          >
            <Image src={item} alt='review-image' alt={handle} />
          </li>
        ))}
      </ul>
      {photoImages[current] ? <Image src={photoImages[current]} className='current-view' alt={handle} /> : null}
    </Style>
  );
}

export default PhotoViewPc;
