export const fontSizeMap = {
  h1: [40, 56, 80],
  h2: [32, 40, 56],
  h3: [28, 32, 48],
  h4: [24, 28, 40],
  h5: [20, 24, 32],
  h6: [18, 20, 28],
  h7: [16, 18, 24],
  h8: [15, 16, 20],
  h9: [14, 15, 18],
  h10: [13, 14, 16],
  h11: [12, 13, 14],
  h12: [11, 11, 12],
  h13: [10, 10, 11],
};

export const fontWeightMap = {
  regular: 400,
  medium: 500,
  bold: 600,
};

export const configurationColorMap = {
  color_text: [20, 40], // '标题和链接',
  color_body_text: [80, 60, 40, 20, 8, 5, 4], // 正文文本
  color_sale_text: 1, // 销售价格
  color_button: [30, 8], // 主要按钮背景
  color_button_text: 1, // 主要按钮文字
  color_small_button_text_border: 1, // 次要标签和边框
  color_small_button: 1, // 次按钮背景
  color_text_field_text: [40], // 表单文本
  color_text_field_border: [40, 20, 8], // 表单边框
  color_text_field: 1, // 表单背景
  color_image_overlay_text: 1, // 图片叠加文本
  color_image_overlay: 1, // 图片叠加背景
  color_borders: 1, // 页面边框和线条
  color_body_bg: 1, // 页面背景
  color_card: [20, 60],
  color_small_button_bg: 1,
};

export const specialColorMap = {
  colorError: '#EC5B56', // 错误
  colorSuccess: '#72C03F', // 成功
  colorWarning: '#EC903A', // 警告
  colorStar: '#F7C844', // 评分
};

// 常规间距
export const gapMap = {
  hContainer: [16, 40, 80], // 全局左右外边距固定80
  vContainer: [16, 32, 40], // 全局上下外安全边距固定40
  vTitleContent: [16, 32, 40], // 标题到内容高度 上下间距
  vTexts: [4, 8, 12], // 文字和文字 上下间距（组合）
  vText: [8, 12, 16], // 文字和文字 上下间距（单个）
  vTextPic: [8, 12, 16], // 文字和到图片上下间距（组合）
  vTextButton: [16, 32, 40], // 文字和按钮 上下间距
  vTextButtons: [8, 12, 24], // 文字和按钮 上下间距（组合）
  hGoods: [8, 12, 24], // 商品到商品 左右间距
  hCards: [24, 32, 40], // 部分特殊卡片到卡片左右距离
  hCardsNew: [16, 20, 24], // 新的卡片外间距
  pCards: [16, 32, 40], // 卡片上下左右的内边距
  pCardsNew: [16, 20, 24], // 新的卡片内间距
  hButtons: [8, 12, 16], // 按钮到按钮 左右间距
  pImagesLarge: [16, 80, 120], // 背景图与内容之间的间距-大图
  pImagesMedium: [16, 40, 80], // 背景图与内容之间的间距-中图
  pImagesSmall: [8, 32, 40], // 背景图与内容之间的间距-小图
};

// 商家可配置间距
export const customerGap = {
  g0: [0, 0, 0],
  g4: [2, 4, 4],
  g8: [4, 6, 8],
  g12: [8, 10, 12],
  g16: [8, 12, 16],
  g20: [12, 16, 20],
  g24: [12, 18, 24],
  g28: [12, 20, 28],
  g32: [16, 24, 32],
  g36: [16, 28, 36],
  g40: [16, 32, 40],
};

export const SWIPER1_TEXT_SIZE_MAP = {
  medium: {
    title: 5,
    desc: 7,
  },
  large: {
    title: 3,
    desc: 5,
  },
};

export const IconSizeMap = {
  mini: [8, 10, 12],
  small: [12, 14, 16],
  xSmall: [16, 20, 24],
  xxSmall: [20, 24, 28],
  medium: [16, 24, 32],
  XMedium: [24, 32, 32],
  large: [24, 32, 40],
  XLarge: [32, 32, 56],
};
