/* eslint-disable lean-imports/import */
import React, { useEffect, useState, useRef } from 'react';
import { GridLayout, GridItem } from 'cpn/layout-ui/grid';
import GoodsPrev from '../goods-prev';
import { useThemeContext } from '../../../../hook/global-theme';
import { set } from 'lodash';

const GoodsCollectionWithLayout = (props) => {
  const { goodsList, initialData, settings, className, cardType = '', customGap, isActiveCustomSort = false } = props;
  const {
    collection,
    grid = 3,
    rows = 2,
    show_comment: showComment = {},
    show_shopping_car: showShoppingCar = {},
    mobile_display_type: mobileDisplayType = {},
    show_wish: showWish,
    show_sale: showSale,
    show_vendor: showVendor,
    open_card: openCard,
    card_color: cardColor,
    show_shopping_buy: showShoppingBuy,
    product_title_align: productTitleAlign,
    text_line_limit: textLineLimit = 'auto',
    product_name_size: productNameSize,
    show_product_name: showProductName = true,
    show_product_price: showProductPrice = true,
    show_dash_discount_label: showDashDiscountLabel = false,
  } = settings;

  const { main_val: showCommentMainVal = false, true: showCommentTrue = {} } = showComment;
  const { show_comment_num: showCommentNum = false, style: commentStyle = 'style1' } = showCommentTrue;
  const { main_val: mobileDisplayTypeMainVal = 'tiled' } = mobileDisplayType;
  const { main_val: showShoppingCarMainVal = false } = showShoppingCar;
  const goodsNameNodeList = useRef({});
  const goodsPriceNodeList = useRef({});
  const [goodsNameHeightList, setgoodsNameHeightList] = useState({});
  const [goodsPriceHeightList, setgoodsPriceHeightList] = useState({});
  const bottomInfoNodeList = useRef({});
  const [bottomInfoHeightList, setBottomInfoHeightList] = useState({});
  const themeContext = useThemeContext();
  const { clientWidth, isMobile } = themeContext;

  const setGoodsNameNode = (goodsId, node) => {
    const newValue = { ...goodsNameNodeList.current };
    set(newValue, [goodsId], node);
    goodsNameNodeList.current = newValue;
  };

  const setGoodsPriceNode = (goodsId, node) => {
    const newValue = { ...goodsPriceNodeList.current };
    set(newValue, [goodsId], node);
    goodsPriceNodeList.current = newValue;
  };

  const setBottomInfoNode = (goodsId, node) => {
    const newValue = { ...bottomInfoNodeList.current };
    set(newValue, [goodsId], node);
    bottomInfoNodeList.current = newValue;
  };

  const computedHeight = (nodeList) => {
    const newHeightList = {};
    // 宽度小于768(pad最小值)时，每行是固定两个，总数不变,需要重新计算grid和rows
    let newGrid = grid;
    let newRows = rows;
    if (clientWidth <= 768) {
      newGrid = 2;
      newRows = parseInt((grid * rows) / 2);
    }
    for (const key in nodeList) {
      set(newHeightList, [key], nodeList[key].clientHeight);
    }
    goodsList.slice(0, newGrid * newRows).forEach((item, index) => {
      if ((index + 1) % newGrid === 0 || index + 1 === goodsList.slice(0, newGrid * newRows).length) {
        let gridNum = newGrid;
        if (index + 1 === goodsList.slice(0, newGrid * newRows).length && (index + 1) % newGrid !== 0) {
          gridNum = (index + 1) % newGrid;
        }
        const heightArr = [];
        for (let i = 0; i < gridNum; i += 1) {
          const { goodsId } = goodsList[index - i];
          heightArr.push(newHeightList[goodsId] || 0);
        }
        const maxheight = Math.max(...heightArr);
        for (let i = 0; i < gridNum; i += 1) {
          const { goodsId } = goodsList[index - i];
          newHeightList[goodsId] = maxheight;
        }
      }
    });
    return newHeightList;
  };

  useEffect(() => {
    if (mobileDisplayTypeMainVal === 'carousel' && isMobile) {
      return;
    }
    if (
      goodsNameNodeList.current &&
      Object.keys(goodsNameNodeList.current).length >= goodsList?.slice(0, grid * rows).length
    ) {
      // 非不限制行数的时候，需要计算同行兄弟元素的商品标题高度
      if (textLineLimit !== 'auto' && showProductName === true) {
        setgoodsNameHeightList(computedHeight(goodsNameNodeList.current));
      } else {
        setgoodsNameHeightList({});
      }
    }
  }, [goodsList.length, collection, grid, rows, openCard, textLineLimit, productNameSize, showProductName]);

  useEffect(() => {
    if (mobileDisplayTypeMainVal === 'carousel' && isMobile) {
      return;
    }
    if (
      goodsPriceNodeList.current &&
      Object.keys(goodsPriceNodeList.current).length >= goodsList?.slice(0, grid * rows).length
    ) {
      if (showProductPrice === true) {
        setgoodsPriceHeightList(computedHeight(goodsPriceNodeList.current));
      } else {
        setgoodsPriceHeightList({});
      }
    }
  }, [goodsList.length, collection, grid, rows, showProductPrice]);
  useEffect(() => {
    if (mobileDisplayTypeMainVal === 'carousel' && isMobile) {
      return;
    }
    if (
      bottomInfoNodeList.current &&
      Object.keys(bottomInfoNodeList.current).length >= goodsList?.slice(0, grid * rows).length
    ) {
      // 非不限制行数的时候，需要计算同行兄弟元素的商品标题高度
      if (showVendor || showSale || showCommentMainVal) {
        setBottomInfoHeightList(computedHeight(bottomInfoNodeList.current));
      } else {
        setBottomInfoHeightList({});
      }
    }
  }, [
    grid,
    rows,
    openCard,
    goodsList.length,
    collection,
    showVendor,
    showSale,
    showCommentMainVal,
    showCommentNum,
    commentStyle,
  ]);

  useEffect(() => {
    // 装修的时候切换屏幕大小，获取到的商品名称实际高度不准确，需要延时获取
    const timeout = 1000; // 延时时长
    const timer = setTimeout(() => {
      if (goodsNameHeightList && Object.keys(goodsNameHeightList).length) {
        setgoodsNameHeightList(computedHeight(goodsNameNodeList.current));
      }
      if (bottomInfoHeightList && Object.keys(bottomInfoHeightList).length) {
        setBottomInfoHeightList(computedHeight(bottomInfoNodeList.current));
      }
      if (goodsPriceHeightList && Object.keys(goodsPriceHeightList).length) {
        setgoodsPriceHeightList(computedHeight(goodsPriceNodeList.current));
      }
    }, timeout);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [clientWidth]);

  const newGoodsList = isActiveCustomSort ? goodsList : goodsList?.slice(0, grid * rows);

  return (
    <GridLayout className={className}>
      {newGoodsList?.length > 0 &&
        newGoodsList.map((item) => {
          return (
            <GridItem
              key={item.goodsId}
              pGrid={grid}
              dGrid={grid}
              mGrid={mobileDisplayTypeMainVal === 'rowTwo' ? 1 : 2}
              pGap={customGap}
              dGap={customGap}
              mGap={customGap ? customGap / 2 : 8}
              className='flex-layout'
            >
              <GoodsPrev
                key={item.goodsId}
                goodsInfo={item}
                initialData={initialData}
                settings={settings}
                grid={grid}
                commentConfig={{
                  showComment: showCommentMainVal,
                  showCommentNum: showComment?.[showCommentMainVal]?.show_comment_num,
                  style: showComment?.[showCommentMainVal]?.style,
                }}
                shoppingCarConfig={{
                  showShoppingCar: showShoppingCarMainVal || showShoppingCar === true,
                  style: showShoppingCar?.[showShoppingCarMainVal]?.style || 'style1',
                }}
                showShoppingBuy={showShoppingBuy}
                showSale={showSale}
                showWish={showWish}
                showVendor={showVendor}
                openCard={cardType === 'big' ? false : openCard}
                cardColor={cardColor}
                productTitleAlign={productTitleAlign}
                returnGoodsNameNode={setGoodsNameNode}
                returnGoodsPriceNode={setGoodsPriceNode}
                returnBottomInfoNode={setBottomInfoNode}
                goodsNameHeight={goodsNameHeightList[item.goodsId] || ''}
                goodsPriceHeight={goodsPriceHeightList[item.goodsId] || ''}
                bottomInfoHeight={bottomInfoHeightList[item.goodsId] || ''}
                showProductName={showProductName}
                showProductPrice={showProductPrice}
                showDashDiscountLabel={showDashDiscountLabel}
              />
            </GridItem>
          );
        })}
    </GridLayout>
  );
};

export default GoodsCollectionWithLayout;
