import Header from '../pages/design/sections/header';
import preFetchHeaderData from '../pages/design/sections/header/get-initial-props';
import HeaderV2 from '../pages/design/sections/header-v2';
import preFetchHeaderV2Data from '../pages/design/sections/header-v2/get-initial-props';
import AnnouncementBar from '../pages/design/sections/announcement-bar';
import Footer from '../pages/design/sections/footer';

export const baseSectionsMap = {
  'announcement-bar': {
    component: AnnouncementBar,
  },
  'header-v2': {
    component: HeaderV2,
    getInitialProps: preFetchHeaderV2Data,
  },
  footer: {
    component: Footer,
  },
  header: {
    component: Header,
    getInitialProps: preFetchHeaderData,
  },
};
