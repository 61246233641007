import styled from 'styled-components';

export default styled.div`
  .video-container {
    position: relative;
    width: 100%;
    height: 100%;
    .video {
      width: 100%;
      height: 100%;
      //background: #000;
    }
    .pause-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .play-button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;

      width: ${(props) => props.theme.getSpace([80])};
      height: ${(props) => props.theme.getSpace([60])};
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      .play-icon {
        width: 16px;
        height: 16px;
        fill: #fff;
      }
    }
    .muted-switch-btn {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 16px;
      left: 16px;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      .play-icon {
        width: 16px;
        height: 16px;
        fill: #fff;
      }
    }
  }
`;
