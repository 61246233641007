import styled from 'styled-components';

export const CodeInputWrapper = styled.div`
  input:-internal-autofill-selected {
    color: auto !important;
  }
  position: relative;
  @media screen and (max-width: 560px) {
    .get-code {
      font-size: 14px !important;
      right: -5px !important;
      top: 5px !important;
    }
  }
  .get-code {
    position: absolute;
    right: -10px;
    top: 6px;
  }
  .code-input-email .zent-form-error {
    margin-top: -18px;
  }
  .email-tips {
    font-weight: normal;
    color: ${(props) => props.theme.colorButton};
    cursor: pointer;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  .zent-form-description {
    text-align: right;
    font-size: ${(props) => props.theme.getSpace([10, 12, 14])};
    margin-top: ${(props) => props.theme.getSpace([10, 12, 14])};
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
