/**
 * 
 * 入参：
 * const options = [
  {
    key: '1',
    text: 'Option 1',
  },
];
 */
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { Style, OptionsStyle } from './style';
/* 列表展开类型，向上/向下 */
enum PositionType {
  Up = 'up',
  Down = 'down',
}
const OptionClass = 'hunt-select-option';

// 没必要把instance放外面，会造成模块共用，视觉偏移闪烁,每个select组件应该有自己独立的option容器

// const 定义的组件，提前声明，改到放前面
const Position = (props) => {
  const { children, targetRef, position, count } = props;
  const [instance] = useState(document.createElement('div'));

  useEffect(() => {
    instance.className = `skiptranslate hunt-position${+new Date()}`;
    document.body.appendChild(instance);
    return () => {
      document.body.removeChild(instance);
    };
  }, []);

  /**
   * 获取下拉列表距离顶部的高度
   * @param height
   * @param top
   */
  const getOptionTop = (height, top) => {
    const optionItemHeight = 36;
    const optionListHeight = optionItemHeight * (count > 5 ? 6 : count);
    const margin = 10;

    return position === PositionType.Down ? top + height + margin : top - optionListHeight - margin;
  };

  const setInstanceStyle = () => {
    const { top, left, height, width } = targetRef.current.getBoundingClientRect();
    const OptionTop = getOptionTop(height, top);

    const style = {
      top: `${OptionTop}px`,
      left: `${document.documentElement.scrollLeft + left}px`,
    };

    instance.style.top = style.top;
    instance.style.width = `${width + 30}px`;
    instance.style.left = style.left;
    instance.style.position = 'fixed';
    instance.style.zIndex = '9999';

    return { top, left, height };
  };

  useEffect(() => {
    setInstanceStyle();
  }, [count]);

  const handleScroll = () => {
    setInstanceStyle();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, false);
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, []);

  return instance && ReactDOM.createPortal(children, instance);
};

const SelectOption = (props) => {
  const { optionValue = 'key', optionText = 'text', onSelectChange, value, keyword, selectList } = props;
  return (
    <OptionsStyle className={cn('hunt-select-options')}>
      {selectList.map((item) => {
        let text = item[optionText];
        if (keyword) {
          const pattern = keyword.replace(/\+/g, '\\+');
          const match = item[optionText].match(new RegExp(pattern, 'gi'));
          text = item[optionText].replace(new RegExp(pattern, 'gi'), `<font color="#476CF0">${match}</font>`);
        } else {
          text = item[optionText];
        }

        return (
          <div
            key={item[optionValue]}
            className={cn('skiptranslate', OptionClass, { active: value === item[optionValue] })}
            onClick={() => {
              onSelectChange(item);
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        );
      })}
    </OptionsStyle>
  );
};

export const Select = (props) => {
  const {
    placeholder,
    value,
    options,
    optionValue = 'key',
    optionText = 'text',
    onChange,
    showClear = false,
    position = PositionType.Down,
    width = 270,
    disabled = false,
    canEmpty = false,
    onFilter,
  } = props;
  const [curPlaceholder, setCurPlaceholder] = useState(placeholder); // 当前要展示的提示，1展示默认的 2 选中值之后展示已选的内容
  const [currentValue, setCurrentValue] = useState(value); // 当前选中的值
  const [keyword, setKeyword] = useState(''); // 搜索关键词，搜索选中后，清空
  const [visible, setVisible] = useState(false); // list是否显示
  const inputRef = useRef(null);
  const triggerContainerRef = useRef(null);

  /**
   * 聚焦，更改keyword和当前选中的值
   * @param
   */
  const onInput = (e) => {
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    setCurrentValue(newKeyword);
  };

  // 聚焦，显示列表，若之前选中的有值，则清空, 显示curPlaceholder
  const onFocus = () => {
    if (disabled) return;
    if (!visible) {
      setVisible(true);
    }
    if (currentValue) {
      setCurrentValue('');
    }
  };

  const getCurrentValue = () => {
    if (!value && !canEmpty) return;
    const index = options.findIndex((item) => item[optionValue] === value);
    return index !== -1 ? options[index].inputText || options[index][optionText] : '';
  };

  const onSelectChange = (item) => {
    setVisible(false);
    setKeyword('');
    onChange && onChange(item);
  };

  const onClear = () => {
    onChange({ [optionValue]: '' });
  };

  /** 监听当前value和列表，更新提示和当前的值 */

  useEffect(() => {
    if (!options?.length || (!value && !canEmpty)) return;
    const newValue = getCurrentValue();
    setCurrentValue(newValue);
    if (newValue) {
      setCurPlaceholder(newValue);
    } else {
      setCurPlaceholder(placeholder);
    }
  }, [value, options]);

  const bindBodyClick = (e) => {
    // 查看是否是trigger容器触发的，不用去一个个判断target
    if (triggerContainerRef?.current?.contains(e.target)) {
      setVisible(true);
      return;
    }
    // 若是点击了其他区域 就把之前选中的值设置回来
    // if (e.target.className !== OptionClass) {
    //     const newValue = getCurrentValue();
    //     setCurrentValue(newValue);
    // }

    setKeyword('');
    setVisible(false);
  };

  useEffect(() => {
    if (disabled) {
      return document.removeEventListener('click', bindBodyClick, false);
    }
    document.addEventListener('click', bindBodyClick, false);
    return () => {
      document.removeEventListener('click', bindBodyClick, false);
    };
  }, [disabled, visible]);

  const handleFilterList = () => {
    if (!keyword) return options;
    if (typeof onFilter === 'function') {
      return onFilter(options, keyword);
    }
    return options.filter((option) => option[optionText].toLowerCase().indexOf(keyword.toLowerCase()) !== -1);
  };

  const selectList = handleFilterList();

  return (
    <Style disabled={disabled} className={cn('hunt-select')}>
      <div
        className={cn('hunt-select-trigger', { active: visible })}
        style={{ width: width === 'auto' ? 'auto' : `${width}px` }}
        ref={triggerContainerRef}
      >
        <input
          type='text'
          disabled={disabled}
          value={currentValue || ''} // 避免控制台警告变成不可控组件(undefined的时候)
          placeholder={curPlaceholder}
          ref={inputRef}
          onFocus={onFocus}
          onInput={onInput}
          autoComplete='new-password'
        />
        {showClear && visible && value && (
          <svg className='icon icon-clear' width='14' height='14' aria-hidden='true' onClick={onClear}>
            <use xlinkHref='#iconic-qingchu' />
          </svg>
        )}

        <div className={cn('hunt-select-switch', disabled ? 'disabled' : '')} onClick={onFocus}>
          <svg className={cn('icon icon-switch', { visible })} width='16' height='16' aria-hidden='true'>
            <use xlinkHref='#iconic-xiala1' />
          </svg>
        </div>
      </div>

      {visible && (
        <Position
          targetRef={inputRef}
          onNotVisibleArea={() => setVisible(false)}
          position={position}
          count={selectList?.length}
        >
          <div>
            <SelectOption
              {...props}
              visible={visible}
              onSelectChange={onSelectChange}
              value={value}
              keyword={keyword}
              selectList={selectList}
            />
          </div>
        </Position>
      )}
    </Style>
  );
};
