/**
 * 用户相关的api接口封装
 */
import BaseApi from './base';

class UserApi extends BaseApi {
  getShopOwner() {
    return this._post('/api/soa/com.youzan.i18n.shop.controller.web.StaffReadDubboApi/getShopOwnerByKdt');
  }

  login(params) {
    return this._post('/api/node/login', params);
  }
  logout(params) {
    return this._post('/api/node/logout', params);
  }
  fblogin(params) {
    return this._post('/api/node/fblogin', params);
  }
  googleLogin(params) {
    return this._post('/api/node/googleLogin', params);
  }
  getUserLocation() {
    return this._post('/api/node/getUserLocation');
  }
  /** 获取登录方式 */
  getLoginChannels() {
    return this._get(
      '/api/soa/com.youzan.i18n.appstore.thridplatform.controller.web.ThridPlatformLoginController/getLoginChannels',
    );
  }

  // 新接口 获取支持的登陆方式
  getSupportChannels(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.caccount.controller.web.ThirdChannelController/getSupportChannels',
      params,
    );
  }
  /**
   * 关联facebook账号
   * http://zanapi.qima-inc.com/site/service/view/1012338
   * */
  bindFacebook(params) {
    return this._get('/api/soa/com.youzan.i18n.customer.controller.web.AccountBindController/bindFacebook', params);
  }
  // 绑定邮箱
  bindEmail(params) {
    return this._get('/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/createCustomer', params, {
      throttle: true,
    });
  }
  /**
   * 关联谷歌账号
   * @param {}} params
   */
  bindGoogle(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.caccount.controller.web.ThirdChannelController/loginWithChannel',
      params,
    );
  }

  registerCustomer(params) {
    return this._post('/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/registerCustomer', params);
  }
  /**
   * @name 发送客户重置密码通知
   * @description http://zanapi.qima-inc.com/site/service/view/978136
   */
  sendMailOnResetPwd(params, options) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/sendMailOnResetPwd', params, options);
  }

  /**
   * @name 获取客户重置密码时存储的信息
   * @description http://zanapi.qima-inc.com/site/service/view/978136
   */
  getCustomerResetPwdNotifyInfo(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/getCustomerResetPwdNotifyInfo',
      params,
    );
  }

  /**
   * @name 重置客户密码
   * @description http://zanapi.qima-inc.com/site/service/view/973367
   */
  resetCustomerPwd(params) {
    return this._post('/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/resetCustomerPwd', params);
  }

  /**
   * @name 获取邀请客户时存储的信息
   * @description http://zanapi.qima-inc.com/site/service/view/978036
   */
  getCustomerInvitNotifyInfo(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/getCustomerInvitNotifyInfo',
      params,
    );
  }

  /**
   * @name 接受客户邀请
   * @description http://zanapi.qima-inc.com/site/service/view/974099
   */
  acceptCustomerInvit(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/acceptCustomerInvit',
      params,
    );
  }
  /**
   * @name 拒绝客户邀请
   * @description http://zanapi.qima-inc.com/site/service/view/974100
   */
  declineCustomerInvit(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/declineCustomerInvit',
      params,
    );
  }

  /**
   * @name 查询客户详情
   * @description http://zanapi.qima-inc.com/site/service/view/978136
   */
  getCustomerById(params) {
    return this._post('/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/getCustomerById', params);
  }

  /**
   * @name C端查询客户地址列表
   * @description http://zanapi.qima-inc.com/site/service/view/979861
   */
  getAddressesByCustomerId(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerAddressController/getAddressesByCustomerId',
      params,
    );
  }

  /**
     * @name C端添加地址

     * @description http://zanapi.qima-inc.com/site/service/view/979856
     */
  createAddress(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerAddressController/createAddress',
      params,
    );
  }

  /**
   * @name C端修改地址
   * @description http://zanapi.qima-inc.com/site/service/view/979857
   */
  updateAddress(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerAddressController/updateAddress',
      params,
    );
  }

  /**
   * @name C端删除地址
   * @description http://zanapi.qima-inc.com/site/service/view/979858
   */
  deleteAddress(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerAddressController/deleteAddress',
      params,
    );
  }

  /**
   * @name 查询客户默认地址
   * @description http://zanapi.qima-inc.com/site/service/view/979861
   */
  getDefaultAddressById(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerAddressController/getDefaultAddressById',
      params,
    );
  }

  getShippingRatesByToken(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/getShippingRatesByToken', params, {
      throttle: true,
    });
  }

  /**
   * @name 获取店铺 Facebook Messenger 代码配置
   * @description http://zanapi.qima-inc.com/site/service/view/1055280
   */
  getFacebookMessengerCode(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.appstore.thridplatform.controller.web.FacebookMessengerController/getFacebookMessengerCode',
      params,
    );
  }
  loginByCode(params) {
    return this._post('/api/node/loginByCode', params);
  }

  // 邮箱校验
  validatePostcode(params) {
    return this._get('/api/node/validate/postcode', params);
  }
  // 根据验证码登录
  loginByVerifyCode(params) {
    return this._post('/api/node/loginByVerifyCode', params);
  }
  // 根据账号密码登录
  loginByPwd(params) {
    return this._post('/api/node/loginByPwd', params);
  }
  // 发送短信验证码
  sendSmsVerifyCode(params, options) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/sendSmsVerifyCode', params, options);
  }
  // 发送邮箱验证码
  sendMailVerifyCode(params, options) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/sendMailVerifyCode', params, options);
  }
  // 通过邮箱注册帐号
  registerByEmail(params) {
    return this._post('/api/node/registerByEmail', params, { throttle: true });
  }
  // 修改手机号
  updatePhone(params) {
    return this._post('/api/soa/com.youzan.i18n.account.admin.web.AccountController/updatePhone', params);
  }
  // 根据手机号/密码登录
  phoneLogin(params) {
    return this._post('/api/soa/com.youzan.i18n.account.admin.web.AccountController/phoneLogin', params);
  }
  // 根据手机号注册帐号时, 校验手机号是否可用
  checkMobileOnRegister(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/checkMobileOnRegister',
      params,
      { throttle: true },
    );
  }
  // 根据手机号重置密码时, 校验手机号是否可用
  checkMobileOnResetPwd(params, options) {
    return this._post(
      '/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/checkMobileOnResetPwd',
      params,
      options,
    );
  }
  // 绑定邮箱时,校验邮箱是否正确
  checkEmailOnBind(params) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/checkEmailOnBind', params);
  }
  checkEmailOnRegister(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/checkEmailOnRegister',
      params,
      { throttle: true },
    );
  }
  // 绑定邮箱
  bindEmailNew(params) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/bindEmail', params);
  }
  // 绑定手机号时,校验手机号是否正确
  checkMobileOnBind(params) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/checkMobileOnBind', params);
  }
  // 绑定手机号时
  bindMobile(params) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/bindMobile', params);
  }
  // 绑定手机号时
  getAccountByToken(params) {
    return this._get('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/getAccountByToken', params);
  }
  // 通过手机号注册帐号
  registerByMobile(params) {
    return this._post('/api/node/registerByMobile', params, { throttle: true });
  }

  // 获取谷歌联想地址
  getAutoCompleteAddress(params) {
    return this._get('/api/node/getAutoCompleteAddress', params);
  }
  // 获取谷歌地址详情
  getAddressPlaceDetails(params) {
    return this._get('/api/node/getAddressPlaceDetails', params);
  }
  // 根据邮箱重置密码step1:校验邮箱 & 发送重置密码邮件
  sendMailPreResetPwd(params) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/sendMailPreResetPwd', params);
  }
  // 根据邮箱重置密码step2:输入新密码 & 修改密码
  resetPwdByEmail(params) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/resetPwdByEmail', params);
  }
  // 根据手机号重置密码
  resetPwdByMobile(params) {
    return this._post('/api/soa/com.youzan.i18n.caccount.controller.web.AccountController/resetPwdByMobile', params);
  }

  getCustomerByToken(params) {
    return this._post('/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/getCustomerByToken', params);
  }
  // 获取会员二维码
  getCustomerDynamicCode(params) {
    return this._post('/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/getCustomerDynamicCode', params);
  }
}

export default new UserApi();
