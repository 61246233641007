import React from 'react';
import { Style } from './style';
import ThirdMenuGroup from '../third-menu-group';
import SecondMenuGroup from '../second-menu-group';

function MenuItemContent(props) {
  const { className, blocksTwo = {}, blockOrderTwo = [], settings = {}, containerHeight, ...restProps } = props;
  const {
    submenu_height: submenuHeight, // 百分比
    submenu_width: submenuWidth,
  } = settings;
  return (
    <Style className={className} {...restProps} height={(submenuHeight * containerHeight) / 100}>
      {blockOrderTwo.map((item) => {
        const blockItem = blocksTwo[item];
        if (!blockItem) return null;
        return blockItem.type === 'second_menu_group' ? (
          <SecondMenuGroup key={item} menuInfo={blockItem} width={submenuWidth} />
        ) : (
          <ThirdMenuGroup key={item} menuInfo={blockItem} width={submenuWidth} />
        );
      })}
    </Style>
  );
}

export default MenuItemContent;
