/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Pop, Radio, Select } from 'zent';
import find from 'lodash/find';
import cn from 'classnames';
import { Style } from './style';
import { checkIsGoodSoldOut } from 'helper/goods';
import { useIntl } from 'react-intl';
import { Tag } from '@youzan/hunt-design';
import { useThemeContext } from '../../../hook/global-theme';

const RadioGroup = Radio.Group;
const SKU_TYPE_ENUM = {
  square: 'rectangle',
  radio: 'radio',
  color: 'color_switch',
  select: 'drop_down',
  graphic: 'image_text',
};

/**
 * withVariants 是否是多规格（一个规格也算多规格）
 * @param {*} param0
 */
const SkuItem = ({ withVariants, sku, cart, options, variants, index, onSkuSelect, isNeedDisabled }) => {
  const intl = useIntl();
  const { typeStr = SKU_TYPE_ENUM.square, optionValues } = sku || {};
  const validList = Object.keys(SKU_TYPE_ENUM).map((key) => SKU_TYPE_ENUM[key]);
  const relType = validList.indexOf(typeStr) === -1 ? SKU_TYPE_ENUM.square : typeStr;
  const isGoodSoldOut = checkIsGoodSoldOut(variants);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const { isMobile } = useThemeContext(); // 是否是手机端

  const closeSelectOpen = () => {
    setIsSelectOpen(false);
  };
  useEffect(() => {
    document.body.addEventListener('click', closeSelectOpen, true);
    return () => {
      document.body.removeEventListener('click', closeSelectOpen);
    };
  }, []);

  const checkIsSkuSoldOut = (item, currentIndex) => {
    if (isNeedDisabled) return true;
    const cartSkuTitleArr = [...(cart?.sku || [])];

    cartSkuTitleArr[currentIndex] = item.title;

    const cartSkuLen = cartSkuTitleArr.filter((skuItem) => !!skuItem).length;

    /** 排除自己和自己比较、同级比较 */
    if (cartSkuLen < options.length) {
      return false;
    }

    const currentSkuVariant = find(variants, (skuItem) => {
      let isCurrent = true;
      const skuOptionMap = {};
      const cartSkuTitleMap = {};
      // 将数组转换成对象，解决options 跟optionValues排序不一致问题
      skuItem?.optionValues?.forEach((item) => {
        // 赋值任意一个truthy的值就好，只是做一个存在的标识
        skuOptionMap[item.title] = 1;
      });
      cartSkuTitleArr?.forEach((item) => {
        // 赋值任意一个truthy的值就好，只是做一个存在的标识
        cartSkuTitleMap[item] = 1;
      });
      for (const key in cartSkuTitleMap) {
        if (!skuOptionMap[key]) {
          isCurrent = false;
        }
      }

      return isCurrent;
    });
    /** 如果title组合无法在variants中找到，说明该规格值缺省 */
    return currentSkuVariant ? !currentSkuVariant?.quantity : true;
  };

  const onSkuClick = (item, isSoldOut) => {
    if (!withVariants) {
      return;
    }

    if (!isSoldOut) {
      onSkuSelect(item, index);
    }

    const myEvent = new CustomEvent('sku-change', {
      detail: {
        item,
        variants,
        withVariants,
      },
    });
    if (window) {
      setTimeout(() => {
        window.dispatchEvent(myEvent);
      }, 200);
    }
  };

  if (validList.indexOf(sku?.typeStr) === -1) {
    // console.warn('sku-list组件：这是一条未兼容数据,请找后端', sku);
  }

  if (relType === SKU_TYPE_ENUM.square) {
    return (
      <div className='square'>
        {optionValues?.map((item) => {
          const isSoldOut = isGoodSoldOut || checkIsSkuSoldOut(item, index);
          const isSelected = !isSoldOut && cart?.sku[index] === item?.title;
          return (
            <Tag
              className={{
                'sku-box': true,
                'tag-disabled': isSoldOut,
              }}
              key={item.title}
              checkable
              checked={isSelected}
              onCheck={() => {
                onSkuClick(item, isSoldOut);
              }}
            >
              <span className='tag-name'>{item.title}</span>
            </Tag>
          );
        })}
      </div>
    );
  }
  if (relType === SKU_TYPE_ENUM.radio) {
    return (
      <RadioGroup value={cart?.sku[index]}>
        {optionValues.map((item) => {
          const isSoldOut = isGoodSoldOut || checkIsSkuSoldOut(item, index);
          return (
            <Radio
              key={item?.title}
              onClick={() => {
                onSkuClick(item, isSoldOut);
              }}
              disabled={isSoldOut}
              value={item?.title}
            >
              {item?.title}
            </Radio>
          );
        })}
      </RadioGroup>
    );
  }

  if (relType === SKU_TYPE_ENUM.color) {
    return (
      <div className='color'>
        {optionValues?.map((item, optionIndex) => {
          const isSoldOut = isGoodSoldOut || checkIsSkuSoldOut(item, index);
          const isSelected = !isSoldOut && cart?.sku[index] === item?.title;
          const className = cn('color-item', { active: isSelected }, { disabled: isSoldOut });
          return (
            <>
              {item?.variantSkuColorOptionValue?.displayTitleSwitch && !isMobile ? (
                <Pop trigger='hover' position='bottom-center' content={item?.title}>
                  <div className={className} key={optionIndex} onClick={() => onSkuClick(item, isSoldOut)}>
                    <div
                      className={cn(
                        'color-item-inner',
                        `color-item-inner-length-${item?.variantSkuColorOptionValue?.colorCode?.length}`,
                        {
                          'color-item-hover': !!item?.variantSkuColorOptionValue?.displayTitleSwitch,
                        },
                      )}
                    >
                      {item?.variantSkuColorOptionValue?.colorCode?.map((background, backgroundIndx) => (
                        <span key={backgroundIndx} className='color-inner' style={{ background }} />
                      ))}
                    </div>
                  </div>
                </Pop>
              ) : (
                <div className={className} key={optionIndex} onClick={() => onSkuClick(item, isSoldOut)}>
                  <div
                    className={cn(
                      'color-item-inner',
                      `color-item-inner-length-${item?.variantSkuColorOptionValue?.colorCode?.length}`,
                    )}
                  >
                    {item?.variantSkuColorOptionValue?.colorCode?.map((background, backgroundIndx) => (
                      <span key={backgroundIndx} className='color-inner' style={{ background }} />
                    ))}
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  }
  if (relType === SKU_TYPE_ENUM.select) {
    return (
      <div className='sku-select-list-wrapper'>
        <Select
          className='select-type sku-select-list'
          onChange={(e, selected) => {
            const cur = optionValues.find((option) => selected.title === option?.title);
            const isSoldOut = isGoodSoldOut || checkIsSkuSoldOut(cur, index);
            onSkuClick(cur, isSoldOut);
            setIsSelectOpen(false);
          }}
          onOpen={() => {
            setIsSelectOpen(true);
          }}
          value={cart?.sku[index]}
          optionValue='title'
          optionText='title'
          popupClassName='sku-select-list-body'
          placeholder={intl.formatMessage({ id: 'common_please_select', defaultMessage: '请选择' })}
        >
          {optionValues.map((item) => {
            const isSoldOut = isGoodSoldOut || checkIsSkuSoldOut(item, index);
            const optionClass = isSoldOut ? 'zent-select-option disabled' : 'zent-select-option';
            return (
              <div key={index} className={optionClass}>
                {item?.title}
              </div>
            );
          })}
        </Select>
        <div className={cn('hunt-select-switch', `hunt-select-switch-is-open-${isSelectOpen}`)}>
          <svg className={cn('icon icon-switch')} width='16' height='16' aria-hidden='true'>
            <use xlinkHref='#iconic-xiala1' />
          </svg>
        </div>
      </div>
    );
  }

  if (relType === SKU_TYPE_ENUM.graphic) {
    return (
      <div className='graphic-type'>
        {optionValues?.map((item) => {
          const isSoldOut = isGoodSoldOut || checkIsSkuSoldOut(item, index);
          const isSelected = !isSoldOut && cart?.sku[index] === item?.title;
          const image = item?.variantImageTextOptionValue?.medias?.[0]?.url;
          return (
            <Tag
              className={{
                'tag-disabled': isSoldOut,
              }}
              key={item.title}
              checkable
              checked={isSelected}
              onCheck={() => {
                onSkuClick(item, isSoldOut);
              }}
              icon={<img src={image} alt={item?.title} />}
            >
              <span className='tag-name'>{item.title}</span>
            </Tag>
          );
        })}
      </div>
    );
  }
  return null;
};

const SkuList = (props) => {
  const {
    cart,
    variants = [],
    options = [],
    onSkuSelect,
    squareStyle,
    withVariants,
    preVariantId,
    isNeedDisabled,
  } = props;
  const autoSelectSingleOption = () => {
    options.forEach(({ optionValues = [] }, index) => {
      if (optionValues.length === 1) {
        onSkuSelect(optionValues[0], index);
      }
      if (preVariantId) {
        const item = find(variants, { variantId: preVariantId });
        const selectedOptionValues = item?.optionValues;
        optionValues.forEach((option, optionIndex) => {
          const title = option?.title;
          if (find(selectedOptionValues, { title })) onSkuSelect(optionValues[optionIndex], index);
        });
      }
    });
  };

  useEffect(() => {
    autoSelectSingleOption();
  }, [options]);

  return (
    <Style>
      {options.map((sku, index) => {
        return (
          <div className='sku-wrapper' key={sku?.title}>
            <span className='sku-title'>{sku?.title}</span>
            <div className='goods-sku-detail'>
              <SkuItem
                withVariants={withVariants}
                sku={sku}
                cart={cart}
                index={index}
                options={options}
                variants={variants}
                onSkuSelect={onSkuSelect}
                squareStyle={squareStyle}
                isNeedDisabled={isNeedDisabled}
              />
            </div>
          </div>
        );
      })}
    </Style>
  );
};

export default SkuList;
