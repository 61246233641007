import shopApi from '../../api/shop';

export default class CmRobin {
  constructor(kdtId) {
    this.kdtId = kdtId;
    this.getCmRobinConfig();
  }
  getCmRobinConfig() {
    if (!this.kdtId) {
      return;
    }
    shopApi.getRobinConfig({ kdtId: this.kdtId }).then((data) => {
      const { code, status } = data;
      const reg = /robin\/.+.js/;
      const robinJsId = reg.exec(code);
      if (code && status) {
        const scriptWrapper = document.createElement('script');
        scriptWrapper.setAttribute('src', `https://selfservice.robinhq.com/external/${robinJsId}`);
        document.head.appendChild(scriptWrapper);
      }
    });
  }
}
