import styled from 'styled-components';

export default styled.div`
  &.toast-item {
    height: 48px;
    line-height: 38px;
    min-width: 160px;
    text-align: center;
    font-size: 14px;
    box-sizing: border-box;
    padding: 5px 8px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 3px;

    & + .toast-item {
      margin-top: 10px;
    }
  }
`;
