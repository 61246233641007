import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;
  .text-position {
    width: 100%;
    height: 100%;
    display: flex;
    &.left-top {
      align-items: flex-start;
      justify-content: flex-start;
    }
    &.left-center {
      align-items: center;
      justify-content: flex-start;
    }
    &.left-bottom {
      align-items: flex-end;
      justify-content: flex-start;
    }
    &.center-top {
      align-items: flex-start;
      justify-content: center;
    }
    &.center-center {
      align-items: center;
      justify-content: center;
    }
    &.center-bottom {
      align-items: flex-end;
      justify-content: center;
    }
    &.right-top {
      align-items: flex-start;
      justify-content: flex-end;
    }
    &.right-center {
      align-items: center;
      justify-content: flex-end;
    }
    &.right-bottom {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
`;
