import jsCookie from 'js-cookie';
import get from 'lodash/get';
import thirdPlatformApi from 'api/third-platform';
import { getBrowserId } from '../util';

const eventMap = new Map();
eventMap.set('add_cart', 'AddToCart');
eventMap.set('search', 'search');
eventMap.set('completeOrder', 'InitiateCheckout');
eventMap.set('pay_success', 'Purchase');
eventMap.set('add_payment_info', 'AddPaymentInfo');
eventMap.set('product_add_to_wishlist', 'AddToWishlist');
eventMap.set('sign_up', 'CompleteRegistration');
eventMap.set('view_content', 'ViewContent');

const formateNameAndType = (goodsList) => {
  const title = get(goodsList, '[0].title', '');
  if (goodsList?.length > 1) {
    return {
      content_type: 'product_group',
    };
  }
  return {
    content_name: title,
    content_type: 'product',
  };
};

class FacebookPixelLog {
  constructor(eventName, data) {
    const { currency } = window.global_data?.shopSetting;
    this.eventName = eventMap.get(eventName);
    this.data = { content_name: document.title, ...data };
    this.currency = currency;
    this.dataMatch = { currency };
  }
  // 根据事件不同 数据格式化
  formatData(eventName) {
    const eventDataFormat = new Map();

    // 事件：浏览详情页面
    eventDataFormat.set('ViewContent', () => {
      const { goodsId, title: content_name, variants: sku = [], minPrice = 0 } = this.data;
      this.dataMatch = {
        ...this.dataMatch,
        content_name,
        content_ids: sku.map((item) => item?.variantId),
        content_type: 'product',
        contents: [
          {
            id: goodsId,
            quantity: 1,
          },
        ],
        value: minPrice / 1000,
        currency: this.currency,
      };
    });

    // 事件：添加到心愿单
    eventDataFormat.set('AddToWishlist', () => {
      const { goodsId, title: content_name, variants: sku = [], minPrice } = this.data;
      this.dataMatch = {
        ...this.dataMatch,
        content_name,
        content_ids: sku.map((item) => item?.variantId),
        contents: [
          {
            id: goodsId,
            quantity: 1,
          },
        ],
        value: minPrice / 1000,
      };
    });

    // 事件：注册事件
    eventDataFormat.set('CompleteRegistration', () => {
      this.dataMatch = {
        ...this.dataMatch,
        ...this.data,
        value: 0.0,
      };
    });

    // 事件：添加到购物车
    eventDataFormat.set('AddToCart', () => {
      const { goodsId, title: content_name, sku, price, quantity } = this.data;
      this.dataMatch = {
        ...this.dataMatch,
        content_name,
        content_ids: sku,
        content_type: 'product',
        contents: [
          {
            id: goodsId,
            quantity,
          },
        ],
        value: price / 1000,
      };
    });

    // 事件：添加到心愿单
    eventDataFormat.set('AddToWishlist', () => {
      const { goodsId, title: content_name, variants: sku = [], minPrice: price } = this.data;
      this.dataMatch = {
        content_name,
        content_ids: sku.map((item) => item?.variantId),
        content_type: 'product',
        contents: [
          {
            id: goodsId,
            quantity: 1,
          },
        ],
        currency: this.currency,
      };
    });

    // 事件：注册成功
    eventDataFormat.set('CompleteRegistration', () => {
      const content_category = 'register';
      const { goodsId, title: content_name, variants: sku = [], minPrice: price } = this.data;
      this.dataMatch = {
        content_category: 'register',
        value: Number(price) / 1000,
      };
    });

    // 事件：商品搜索
    eventDataFormat.set('search', () => {
      const { search_term: search_string } = this.data;
      this.dataMatch = { search_string, currency: this.currency };
    });

    // 事件: 开始结账
    eventDataFormat.set('InitiateCheckout', () => {
      let value = 0;
      const { goodsList = [] } = this.data?.source;
      const contents = goodsList.map((item) => {
        const quantity = item?.quantity;
        value += (item.price * quantity) / 1000;
        const id = item?.variantId;
        return { id, quantity, item_price: item.price / 1000 };
      });
      this.dataMatch = {
        ...this.dataMatch,
        value,
        content_ids: goodsList.map((item) => item?.variantId),
        num_items: goodsList.reduce((total, current) => total + current?.quantity, 0),
        ...formateNameAndType(goodsList),
        contents,
      };
    });

    // 事件成功购买
    eventDataFormat.set('Purchase', () => {
      const { source = {} } = this.data;
      const { formData = {} } = source;
      const { lineItems = [] } = formData;
      const sku = [];
      const contents = lineItems.map((item) => {
        sku.push(item?.variantId);
        return { id: item?.variantId, quantity: item?.quantity, content_name: item?.content_name };
      });
      const value = formData?.totalPrice / 1000;
      this.dataMatch = {
        ...this.dataMatch,
        content_ids: sku,
        num_items: lineItems.reduce((total, current) => total + current?.quantity, 0),
        ...formateNameAndType(lineItems),
        contents,
        value,
      };
    });

    // 事件：添加付款信息
    eventDataFormat.set('AddPaymentInfo', () => {
      const { source = {} } = this.data;
      const { formData = {} } = source;
      const { lineItems = [] } = formData;
      const sku = [];
      const contents = lineItems.map((item) => {
        sku.push(item?.variantId);
        return { id: item?.variantId, quantity: item?.quantity, content_name: item?.content_name };
      });
      const value = formData?.totalPrice / 1000;
      this.dataMatch = {
        ...this.dataMatch,
        ...formateNameAndType(lineItems),
        content_ids: sku,
        contents,
        value,
      };
    });

    // 执行对应事件数据的格式化
    if (eventDataFormat.get(eventName)) eventDataFormat.get(eventName)();
  }
  eventLog() {
    // 没有对应事件就不处理 不上报
    if (!this.eventName) {
      return;
    }
    this.formatData(this.eventName);
    const browserId = FacebookPixelLog?.browserId;
    const timeStamp = +new Date();
    const eventId = `${browserId}-${timeStamp}`;
    const { contents = [] } = this.dataMatch || {};
    const customData = { ...this.dataMatch, contents: JSON.stringify(contents) };
    const userDataFbp = jsCookie.get('_fbp'); // facebook pixels设置的用户数据
    if (window.fbq) {
      window.fbq('track', this.eventName, { eventTime: timeStamp, ...customData }, { eventID: eventId });
    }

    // facebook-conversion-api上报，用户数据为必传参数
    if (!userDataFbp) return;

    const params = {
      eventId,
      userData: {
        fbp: userDataFbp,
      },
      eventTime: timeStamp,
      actionSource: 'website',
      eventName: this.eventName,
      fbPixelId: String(window?.fbPixelId),
      customData,
      eventSourceUrl: window.location.href,
    };
    thirdPlatformApi.reportFbPixelEvent({
      fbPixelEventParamList: [params],
    });
  }
}

// 给FacebookPixelLog🥱挂载browserId;
getBrowserId(FacebookPixelLog);
export default FacebookPixelLog;
