import shopApi from 'api/shop';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import LocalStorage from 'utils/local-storage';
import { WISH_COUNT_CHANGE } from 'utils/local-storage/config';
import { buriedPoint } from 'utils/log-buried-point';
import { useTips } from 'hook/cart/notify';
import { HEAD_ICON_TYPE } from 'const';
import { useRootContext } from 'client/pages/context/root';

/** 心愿单 */
export const useWish = ({ goodsDetail, initialData, customerId }) => {
  const [wishActionLoading, setWishActionLoading] = useState(false);
  const [wishStatus, setWishStatus] = useState(false);
  const [wishId, setWishId] = useState('');
  const { showSuccessTip, showRemoveTips } = useTips();
  const { setLoginDialogInfo } = useRootContext();
  const intl = useIntl();

  /** 添加或者移除到心愿单 */
  const toggleToWishList = () => {
    const isLogin = Boolean(customerId);
    if (!isLogin) {
      setLoginDialogInfo({
        visible: true,
      });
      return;
    }
    if (wishActionLoading) return;
    setWishActionLoading(true);
    const wishChangeCount = LocalStorage.get(WISH_COUNT_CHANGE) || 0;
    if (wishStatus) {
      const params = {
        customerId,
        wishListId: wishId,
      };
      shopApi
        .deleteWishGoods(params)
        .then(() => {
          showRemoveTips(intl.formatMessage({ defaultMessage: '已取消收藏', id: 'has.cancel.in_wish' }));
          setWishStatus(false);
          LocalStorage.set(WISH_COUNT_CHANGE, wishChangeCount - 1);
        })
        .finally(() => {
          setWishActionLoading(false);
        });
    } else {
      const params = {
        customerId,
        goodsId: initialData?.goodsId,
        kdtId: initialData?.kdtId,
      };

      shopApi
        .addToWishList(params)
        .then((res) => {
          showSuccessTip({
            text: intl.formatMessage({ defaultMessage: '已添加至心愿单', id: 'has.add.to_wish' }),
            targetId: HEAD_ICON_TYPE.wish,
          });
          setWishStatus(true);
          setWishId(res?.wishListId);
          LocalStorage.set(WISH_COUNT_CHANGE, wishChangeCount + 1);
          buriedPoint(
            'product_add_to_wishlist',
            {
              source: '',
              goods_id: goodsDetail.goodsId,
              goods_ids: goodsDetail.goodsId,
              ...goodsDetail,
            },
            {
              name: '加心愿单',
              eventType: 'click',
            },
          );
        })
        .finally(() => {
          setWishActionLoading(false);
        });
    }
  };

  useEffect(() => {
    const { wishList } = goodsDetail || {};
    if (!goodsDetail) return;
    if (wishList?.isWishList === 1) {
      setWishStatus(true);
      setWishId(wishList?.wishListId);
    }
  }, [goodsDetail.goodsId]);

  return { wishStatus, wishActionLoading, toggleToWishList };
};
