import styled, { css } from 'styled-components';
import { gapMap } from '../../../const/standard';
import isArray from 'lodash/isArray';

export const getMargin = (props) => {
  // const arr = [];
  const { marginTop, marginBottom, marginLeft, marginRight, margin } = props;
  let calcMargin = '';
  if (margin) {
    if (isArray(margin)) {
      calcMargin = margin.map((item) => props.theme.getSpace(gapMap[item])).join(' ');
    } else {
      calcMargin = props.theme.getSpace(gapMap[margin]);
    }
  }
  // margin: [20] [20,30] [20,30,20] [20, 20, 30, 30]
  return css`
    ${marginTop ? `margin-top:${props.theme.getSpace(gapMap[marginTop])}` : ''};
    ${marginBottom ? `margin-bottom:${props.theme.getSpace(gapMap[marginBottom] || marginBottom)}` : ''};
    ${marginLeft ? `margin-left:${props.theme.getSpace(gapMap[marginLeft])}` : ''};
    ${marginRight ? `margin-right:${props.theme.getSpace(gapMap[marginRight])}` : ''};
    ${margin ? `margin:${calcMargin}` : ''};
  `;
};

export default styled.div`
  ${(props) => getMargin(props)};
`;
