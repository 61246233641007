import styled, { css } from 'styled-components';

// 移动设备菜单样式 展开折叠
const getMobileDeviceMenuStyleCSS = (props) => {
  if (props.mobileDeviceMenuStyle === 'fold') {
    return css`
      padding-top: 16px;
      padding-bottom: 16px;
      .footer-menu-collapse-content-box {
        padding-top: 28px !important;
      }
      .footer-menu-children {
        padding-bottom: 4px;
        .sub-menu {
          /* 子菜单第一级 */
          &.first-level-menu {
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
            .footer-menu-collapse-content-box {
              padding-top: 12px !important;
            }
          }
          /* 子菜单第二级 */
          &.not-first-leave-menu {
            .footer-menu-link {
              padding-bottom: 12px;
            }
          }
        }
      }
    `;
  }
  return '';
};

const getMobileDisplayTwoInOneLineCSS = (props) => {
  if (props.mobileDisplayTwoInOneLine) {
    return css`
      .footer-menu-children {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: -20px;
        .sub-menu.first-level-menu {
          width: 50%;
          box-sizing: border-box;
          margin-top: 0 !important;
          margin-bottom: 20px;
          &:nth-of-type(even) {
            padding-left: 8px;
          }
        }
      }
    `;
  }
};

const getMobileDeviceFooterTextAlignCSS = (props) => {
  const { mobileDeviceFooterTextAlign, mobileDeviceMenuStyle, mobileDisplayTwoInOneLine } = props;
  // 折叠 和 两行显示左对齐
  let newMobileDeviceFooterTextAlign = 'left';
  if (mobileDisplayTwoInOneLine || mobileDeviceMenuStyle === 'fold') {
    newMobileDeviceFooterTextAlign = 'left';
  } else {
    newMobileDeviceFooterTextAlign = mobileDeviceFooterTextAlign;
  }

  if (newMobileDeviceFooterTextAlign === 'left') {
    return css`
      .footer-menu-collapse .footer-menu-collapse-title {
        justify-content: space-between;
      }
      .footer-menu-section .sub-menu.not-first-leave-menu .footer-menu-collapse-title {
        justify-content: space-between !important;
      }
    `;
  }
  if (newMobileDeviceFooterTextAlign === 'right') {
    return css`
      .footer-menu-collapse .footer-menu-collapse-title {
        justify-content: flex-end;
      }
      .footer-menu-section .sub-menu.not-first-leave-menu .footer-menu-collapse-title {
        justify-content: flex-end !important;
      }
    `;
  }
  if (newMobileDeviceFooterTextAlign === 'center') {
    return css`
      .footer-menu-collapse .footer-menu-collapse-title {
        justify-content: center;
      }
      .footer-menu-section .sub-menu.not-first-leave-menu .footer-menu-collapse-title {
        justify-content: center !important;
      }
    `;
  }
};

export const Style = styled.div`
  .footer-menu-section {
    .footer-menu-title {
      font-size: ${(props) => props.theme.getHeaderFontSize([14, 16])};
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
      min-height: 20px;
    }
    .footer-menu-link {
      font-size: ${(props) => props.theme.getBaseFontSize([12, 14])};
      &.footer-menu-link-level-first {
        font-weight: var(--font-weight-header);
      }
      &.footer-menu-link-level-not-first {
        opacity: 0.6;
        padding-bottom: 8px;
      }
    }

    .sub-menu {
      &.first-level-menu {
        /* margin-top: 24px; */
        margin-top: 12px;
        &:first-child {
          margin-top: 0;
        }
      }
      &.not-first-leave-menu:last-child .footer-menu-link-level-not-first {
        padding-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .footer-menu-section {
      /* 获取对齐方式CSS */
      ${(props) => getMobileDeviceFooterTextAlignCSS(props)}

      /* 获取一行显示两个CSS */
            ${(props) => getMobileDisplayTwoInOneLineCSS(props)}

            /* 获取移动设备菜单样式 */
            ${(props) => getMobileDeviceMenuStyleCSS(props)}
            

            .footer-menu-link {
        font-size: ${(props) => props.theme.getBaseFontSize([12, 14])};
        &.footer-menu-link-level-first {
          font-weight: var(--font-weight-header);
        }
        &.footer-menu-link-level-not-first {
          opacity: 0.6;
          padding-bottom: 8px;
        }
      }
      .sub-menu {
        &.first-level-menu {
          margin-top: 12px;
          &:first-child {
            margin-top: 0;
          }
        }
        &.not-first-leave-menu:last-child .footer-menu-link-level-not-first {
          padding-bottom: 0;
        }
      }
    }
  }
`;
