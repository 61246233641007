import React, { useMemo, useEffect, useState } from 'react';
import { Form, Validators } from 'zent';
import HtFormInput from '../form-input';
import { HtFormInputPassPhone, GlobalStyle, MobileCountryCodeWrapper, MobileCountryCodeLabel } from './style';
import useCountrySelect from 'hook/use-country-select';
import HtFormSelect from '../form-select';
import { getClientLanguage } from '../../../../helper/multi-language';
import userApi from 'api/user';
import { isValidMobile } from 'helper/verify';
import EmptyFormFileds from '../empty-form-fileds';

function HtFormInputPhone(props) {
  const {
    onMobileChange,
    onMobileCountryCodeChange,
    form,
    intl,
    mobileCodeName = '',
    mobileAreaName = '',
    phoneName = 'mobile',
    autoMobileCode = true,
  } = props;
  const [placeholderText, setPlaceholderText] = useState('');
  const locale = getClientLanguage(window?.global_data);
  const { countryList } = useCountrySelect({ locale });
  const countryListData = useMemo(() => {
    return countryList.map((item, index) => {
      return {
        value: index,
        text: `${item.name}(+${item.phoneNumberPrefix})`,
        code: item.code,
        phoneNumberPrefix: item.phoneNumberPrefix,
      };
    });
  }, [countryList]);

  // 监听FormSelect值的变化
  const mobileCountryCodeIdx = Form.useFieldValue(form.model.get('mobileCountryCodeIdx'));

  // 监听外部传入的区号信息,实现数据回显
  const mobileCode = Form.useFieldValue(form.model.get(mobileCodeName));
  const mobileArea = Form.useFieldValue(form.model.get(mobileAreaName));

  useEffect(() => {
    const { phoneNumberPrefix, code: countryCode } = countryListData[mobileCountryCodeIdx] || {};
    if (phoneNumberPrefix) {
      const mobileCountryCode = `+${phoneNumberPrefix}`;
      setPlaceholderText(mobileCountryCode);
      form.patchValue({
        mobileCountryCode,
      });
      onMobileCountryCodeChange(mobileCountryCode, countryCode);
    }
  }, [mobileCountryCodeIdx]);

  useEffect(() => {
    if (countryListData?.length) {
      if (mobileArea && mobileCode) {
        const idx = countryListData.findIndex(
          (item) => item.code === mobileArea && mobileCode === `+${item.phoneNumberPrefix}`,
        );
        if (idx >= 0) {
          setPlaceholderText(mobileCode);
          form.patchValue({
            mobileCountryCodeIdx: idx,
            mobileCountryCode: mobileCode,
          });
        }
      } else if (autoMobileCode) {
        // 初始化时，自动根据ip匹配一个区号
        userApi.getUserLocation().then((res) => {
          let { countryCode, cityCode } = res?.ipData ?? {};
          // 是空串写解构里面赋值不行
          if (!countryCode) {
            countryCode = 'US';
          }
          const idx = countryListData.findIndex((item) => item.code === countryCode);
          if (idx >= 0) {
            const mobileCountryCode = `+${countryListData?.[idx]?.phoneNumberPrefix}`;
            setPlaceholderText(mobileCountryCode);
            form.patchValue({
              mobileCountryCodeIdx: idx,
              mobileCountryCode,
            });
            onMobileCountryCodeChange(mobileCountryCode, countryCode);
          }
        });
      }
    }
  }, [countryListData, mobileCode, mobileArea]);
  return (
    <HtFormInputPassPhone>
      <GlobalStyle />
      <MobileCountryCodeWrapper>
        <HtFormSelect
          name='mobileCountryCodeIdx'
          className='mobile-country-code'
          selectProps={{
            data: countryListData,
            width: '100px',
            filter: (item, keyword) => item.text.toLowerCase().indexOf(keyword?.toLowerCase()) > -1,
            popupClassName: 'ht-form-phone-select',
          }}
          validators={[
            (val) => {
              if (val === undefined || val < 0) {
                return {
                  name: 'error',
                  message: intl.formatMessage({
                    id: '6f1894ea4f5a479398e1f413645cab29',
                    defaultMessage: '请选择区号',
                  }),
                };
              }
            },
          ]}
        >
          <MobileCountryCodeLabel className='country-code-value'>{placeholderText}</MobileCountryCodeLabel>
        </HtFormSelect>
        {!!mobileCodeName && <EmptyFormFileds name={mobileCodeName} />}
        {!!mobileAreaName && <EmptyFormFileds name={mobileAreaName} />}
      </MobileCountryCodeWrapper>
      <HtFormInput
        {...props}
        placeholder={`${intl.formatMessage({
          id: 'login_phone_number',
          defaultMessage: '手机号',
        })}*`}
        name={phoneName}
        showClear
        onChange={onMobileChange}
        className='ht-form-phone-input'
        validators={[
          Validators.required(
            intl.formatMessage({
              id: '1b3c254437d149c8a84c8b95c62867c1',
              defaultMessage: '请输入手机号',
            }),
          ),
          isValidMobile(
            intl.formatMessage({
              id: '232cf690b4504611b22e8fa3315b483b',
              defaultMessage: '手机号格式不正确',
            }),
          ),
        ]}
      />
    </HtFormInputPassPhone>
  );
}

export default HtFormInputPhone;
