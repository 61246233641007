import styled from 'styled-components';

export default styled.div`
  .menu-of-mobile {
    display: none;
  }
  .sub-menu-title {
    display: inline-block;
    word-break: break-word;
    color: var(--color_text);
  }
  .header-mobile-first-menu {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-mobile-menu-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .first-menu-name {
    cursor: pointer;
    padding: 24px 0;
  }
`;
