import styled from 'styled-components';

export default styled.div`
  .new-tabs {
    padding-top: ${(props) => props.theme.getSpace(props.theme.vTitleContent)};
    .zent-tabs-nav {
      height: auto !important;
      border-bottom-color: ${(props) => props.theme.colorBodyTexts['8']};
      .zent-tabs-nav-content {
        margin-bottom: 0;
        padding: 0;
      }
      .zent-tabs-tab {
        padding: 0;
        margin-right: ${(props) => props.theme.getSpace(props.theme.hCards)};
        height: auto !important;
        font-weight: normal !important;
        .zent-tabs-tab-inner {
          ${(props) => props.theme.getHeaderSize([12, 14])};
          color: ${(props) => props.theme.colorText};
          padding: 0 0 11px 0 !important;
          border: none !important;
        }

        &:last-child {
          margin-right: 0;
        }
      }
      .zent-tabs-tab__actived {
        border: none !important;
        background-color: unset;
        .zent-tabs-tab-inner {
          font-weight: bold;
          &:after {
            content: '';
            height: 3px;
            background-color: ${(props) => props.theme.colorButton};
            width: 80%;
            position: absolute;
            display: inline-block;
            bottom: 0;
            left: 10%;
          }
        }
      }
    }
    .zent-tabs-panel-wrapper {
      margin-top: ${(props) => props.theme.getSpace(props.theme.hCardsNew)};
    }
  }
`;
