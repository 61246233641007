import React, { useState } from 'react';
import Style from './style';
import Image from 'components/base/image';
import cn from 'classnames';
import { Text, Badge } from '../../../../../../components/base';
import { getUrlFromConfig } from 'utils/formate';

export const toLink = (link, target) => {
  if (link) {
    window.open(link, `_${target}`);
  }
};
export const BlankMenuContainer = (props) => {
  const { className = '', style = {}, children } = props;

  return (
    <Style className={cn(className, 'blank-menu-container')} style={style}>
      {children}
    </Style>
  );
};
export const FirstMenu = (props) => {
  const { className = '', menuItem } = props;

  return (
    <Style className={cn(className, 'first-menu-title')}>
      <span className='first-menu-title-text' onClick={() => toLink(menuItem?.url, menuItem?.open_target)}>
        {menuItem?.menuItemName}
      </span>
      {menuItem?.settings?.show_label && menuItem?.settings?.label_text && (
        <Badge
          className='header-badge'
          color={menuItem?.settings?.label_text_color}
          bgColor={menuItem?.settings?.label_bg_color}
        >
          {menuItem?.settings?.label_text}
        </Badge>
      )}
    </Style>
  );
};
export const SubMenu = (props) => {
  const {
    className = '',
    iconType = '#iconic-xiala',
    title = '',
    children,
    menuItem,
    subMenuOpenType = 'dropdown',
    zIndex = 100,
    sideSubMenuAlign,
    navPosition,
  } = props;

  const [isSubOpen, setIsSubOpen] = useState(false);
  const [isSubInto, setIsSubInto] = useState(false);

  const toNextMenu = (e) => {
    if (e.target.className !== 'sub-menu-title-text') {
      if (subMenuOpenType === 'dropdown') {
        setIsSubOpen(!isSubOpen);
      } else {
        setIsSubInto(true);
      }
    }
  };

  return (
    <Style className={cn(className, 'sub-menu')}>
      <div className={cn('sub-menu-title-container')} onClick={toNextMenu}>
        <div
          className='sub-menu-title'
          onClick={(e) => {
            if (e.target.className === 'sub-menu-title-text') {
              toLink(getUrlFromConfig(menuItem?.announcement_link), menuItem?.open_target);
              e.stopPropagation();
            }
          }}
        >
          <span className='sub-menu-title-text'>{title}</span>
          {menuItem?.show_label && menuItem?.label_text && (
            <Badge className='header-badge' color={menuItem?.label_text_color} bgColor={menuItem?.label_bg_color}>
              {menuItem?.label_text}
            </Badge>
          )}
        </div>
        <div className={cn('sub-menu-icon')}>
          <svg
            className='right-icon'
            aria-hidden='true'
            style={{
              // eslint-disable-next-line no-nested-ternary
              transform: isSubOpen ? (iconType === '#iconic_open' ? 'rotate(180deg)' : 'rotate(90deg)') : '',
            }}
            width='16'
            height='16'
          >
            <use xlinkHref={iconType === '#iconic_open' && isSubOpen ? '#iconic_close' : iconType} />
          </svg>
          {/* {iconType === 'plus' && isSubOpen ? (
                        <span>—</span>
                    ) : (
                        <Icon
                            className="right-icon"
                            type={iconType}
                            style={{ transform: isSubOpen ? 'rotate(90deg)' : '' }}
                        />
                    )} */}
        </div>
      </div>
      {subMenuOpenType === 'dropdown' ? (
        <div className={cn('sub-menu-container')} style={{ height: isSubOpen ? 'auto' : '0px' }}>
          {children}
        </div>
      ) : (
        <BlankMenuContainer style={{ left: isSubInto ? '0' : '100%', zIndex }}>
          <svg className='back-icon' aria-hidden='true' onClick={() => setIsSubInto(false)}>
            <use xlinkHref='#iconic-jiantouzuo' />
          </svg>
          <div
            className='child-menu-container'
            style={{
              justifyContent: sideSubMenuAlign === 'top' || navPosition === 'horizontal' ? 'flex-start' : 'center',
            }}
          >
            <div className='child-menu'>{children}</div>
          </div>
        </BlankMenuContainer>
      )}
    </Style>
  );
};
export const ImageMenuItem = (props) => {
  const { className = '', imageMenuItem, style = {}, imageAlt = '' } = props;
  return (
    <Style
      className={cn(className, 'menu-image-item')}
      style={{ ...style, alignItems: imageMenuItem?.settings?.align_text === 'left' ? 'flex-start' : 'center' }}
      onClick={() => toLink(getUrlFromConfig(imageMenuItem.settings?.link), 'self')}
    >
      {imageMenuItem?.settings?.image ? (
        <Image src={imageMenuItem?.settings?.image} className='menu-image-item-image' alt={imageAlt} />
      ) : (
        <div className='menu-image-item-image'>
          <svg className='default-icon' aria-hidden='true' width='41%' height='100%'>
            <use xlinkHref='#iconic-morentu' />
          </svg>
        </div>
      )}
      {imageMenuItem?.settings?.align_text ? (
        <Text
          as='div'
          size={imageMenuItem.settings.text_size}
          style={{
            textAlign: imageMenuItem.settings.align_text,
            borderBottom:
              imageMenuItem.settings.show_decorative_lines === undefined || imageMenuItem.settings.show_decorative_lines
                ? ''
                : 'none',
          }}
          className='menu-image-item-text'
          dangerouslySetInnerHTML={{
            __html: imageMenuItem.settings?.text,
          }}
        />
      ) : null}
    </Style>
  );
};

export const MenuList = (props) => {
  const {
    firstMenuList = [],
    iconType = '#iconic-xiala',
    subMenuOpenType = 'dropdown',
    sideSubMenuAlign,
    navPosition = 'side',
  } = props;

  return (
    <Style className={cn('menu-container', { 'dropdown-menu-container': subMenuOpenType === 'clickinto' })}>
      {Array.isArray(firstMenuList) &&
        firstMenuList.map((firstMenu) => {
          const childMenusOfFirstMenu = firstMenu.shopTemplateMenuItemTreeNodeCustomerVO || [];
          if (!childMenusOfFirstMenu.length) {
            return <FirstMenu className='menu-level-one' key={firstMenu.menuItemId} menuItem={firstMenu} />;
          }

          const imageChildMenusOfFirstMenu = childMenusOfFirstMenu.filter((item) => item.type === 'image');
          const formateChildMenusOfFirstMenu = childMenusOfFirstMenu.filter((item) => item.type !== 'image');
          // 图片类型的二级菜单，合并到一起放置在最后
          formateChildMenusOfFirstMenu.push({
            type: 'image',
            menus: imageChildMenusOfFirstMenu,
          });
          return (
            <SubMenu
              className='menu-level-one'
              key={firstMenu.menuItemId}
              title={firstMenu.menuItemName}
              iconType={iconType}
              menuItem={firstMenu.settings}
              subMenuOpenType={subMenuOpenType}
              zIndex={100}
              sideSubMenuAlign={sideSubMenuAlign}
              navPosition={navPosition}
            >
              {Array.isArray(formateChildMenusOfFirstMenu) &&
                formateChildMenusOfFirstMenu.map((secondMenu) => {
                  const childrenList = secondMenu?.shopTemplateMenuItemTreeNodeCustomerVO ?? [];
                  const { type } = secondMenu;

                  if (type === 'second_menu_group') {
                    return childrenList.map((secondMenuGroupItem) => (
                      <FirstMenu
                        className='menu-level-two'
                        key={secondMenuGroupItem.menuItemId}
                        menuItem={secondMenuGroupItem}
                      />
                    ));
                  }
                  if (type === 'image') {
                    const { menus = [] } = secondMenu;

                    return menus.length ? (
                      <div key='image' className='menu-level-two menu-image'>
                        <div className='menu-image-wrapper'>
                          {menus.map((imageMenuItem, index) => (
                            <ImageMenuItem
                              key={index}
                              imageMenuItem={imageMenuItem}
                              imageAlt={firstMenu.menuItemName}
                              style={{
                                width: menus.length === 1 ? '100%' : '',
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    ) : null;
                  }
                  if (!childrenList.length) {
                    return <FirstMenu className='menu-level-two' key={secondMenu.menuItemId} menuItem={secondMenu} />;
                  }

                  return (
                    <SubMenu
                      className='menu-level-two'
                      key={secondMenu.menuItemId}
                      title={secondMenu.menuItemName}
                      iconType={iconType}
                      menuItem={secondMenu.settings}
                      subMenuOpenType={subMenuOpenType}
                      zIndex={200}
                      sideSubMenuAlign={sideSubMenuAlign}
                      navPosition={navPosition}
                    >
                      {Array.isArray(secondMenu.shopTemplateMenuItemTreeNodeCustomerVO) &&
                        secondMenu.shopTemplateMenuItemTreeNodeCustomerVO.map((thirdMenu) => {
                          return (
                            <FirstMenu className='menu-level-three' key={thirdMenu.menuItemId} menuItem={thirdMenu} />
                          );
                        })}
                    </SubMenu>
                  );
                })}
            </SubMenu>
          );
        })}
    </Style>
  );
};
