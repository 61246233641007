import React from 'react';
import { Menu } from 'zent';
import Style from './style';
import { SlideLeft } from '../../../../../components/slide-left';
import { useIntl } from 'react-intl';

const { MenuItem, SubMenu } = Menu;
export const MobileSubMenu = (props) => {
  const intl = useIntl();
  const { menuDetail = {}, jumpToMenuPage, jumpToOtherPage, initialData, totalCountWish, settings } = props;

  return (
    <Style>
      <div className='menu-of-mobile'>
        <SlideLeft
          expendIconName='iconic-zhucaidan'
          iconSize='24'
          jumpToOtherPage={jumpToOtherPage}
          initialData={initialData}
          totalCountWish={totalCountWish}
        >
          {Array.isArray(menuDetail.shopTemplateMenuItemTreeNodeCustomerVO) &&
            menuDetail.shopTemplateMenuItemTreeNodeCustomerVO.map((firstMenu, index) => {
              const childMenusOfFirstMenu = firstMenu.shopTemplateMenuItemTreeNodeCustomerVO || [];

              if (!childMenusOfFirstMenu.length) {
                return (
                  <div
                    className='header-mobile-home header-mobile-first-menu'
                    key={firstMenu.url}
                    onClick={(e) => jumpToMenuPage(e, firstMenu.url)}
                  >
                    {firstMenu.menuItemName}
                  </div>
                );
              }

              return (
                <div className='header-mobile-first-menu' key={index}>
                  <SlideLeft
                    title={firstMenu.menuItemName}
                    headIconName='iconic-zuoyouqiehuan'
                    customHeaderClass='menu-back-header'
                    customClass='sub-menu-header'
                    menuBackText={intl.formatMessage({
                      defaultMessage: '返回',
                      id: 'general.return',
                    })}
                    iconSize='20'
                    expendIconName='iconic-xiala'
                    showMask='false'
                    settings={settings}
                  >
                    <Menu className='header-mobile-menu' mode='inline' onClick={jumpToMenuPage}>
                      {Array.isArray(childMenusOfFirstMenu) &&
                        childMenusOfFirstMenu.map((secondMenu) => {
                          const hasSub =
                            Array.isArray(secondMenu.shopTemplateMenuItemTreeNodeCustomerVO) &&
                            secondMenu.shopTemplateMenuItemTreeNodeCustomerVO.length;

                          return hasSub ? (
                            <SubMenu
                              title={<span className='sub-menu-title'>{secondMenu.menuItemName}</span>}
                              key={secondMenu.url}
                            >
                              {secondMenu.shopTemplateMenuItemTreeNodeCustomerVO.map((thirdMenu) => {
                                return (
                                  <MenuItem key={thirdMenu.url}>
                                    <span className='sub-menu-title'>{thirdMenu.menuItemName}</span>
                                  </MenuItem>
                                );
                              })}
                            </SubMenu>
                          ) : (
                            <MenuItem key={secondMenu.url}>
                              <span className='sub-menu-title'>{secondMenu.menuItemName}</span>
                            </MenuItem>
                          );
                        })}
                    </Menu>
                  </SlideLeft>
                </div>
              );
            })}
        </SlideLeft>
      </div>
    </Style>
  );
};
