/**
 * 菜单相关的api接口封装
 */
import BaseApi from './base';

class ReportApi extends BaseApi {
  reportEvent(params = {}) {
    return this._post('/api/node/reportEvent', params);
  }
}

export default new ReportApi();
