import styled from 'styled-components';

export const Style = styled.div`
  /* padding: 0 40px; */
  .form-title {
    display: flex;
    font-size: var(--form_name_size);
    zoom: var(--form_title_scale);
    color: var(--form_title_color);
    padding: 3px 0;
    &.required::before {
      color: #d40000;
      color: var(--theme-error-2, #d40000);
      content: '*';
      display: inline-block;
      font-size: 16px;
      margin-right: 6px;
    }
  }

  .form-desc {
    padding-left: 18px;
    font-size: 12px;
    padding: 2px 0;
    color: rgba(var(--form_title_color_rgb), 0.4);
    margin-top: 8px;
  }

  .has-require {
    padding-left: 12px;
  }

  .form-container {
    .zent-form-control {
      margin-top: 16px;
      margin-bottom: 29px;
    }
    .zent-form-control-content {
      margin-left: 0;
    }
    .zent-checkbox-wrap {
      .zent-checkbox-inner {
        border-color: var(--form_border_color) !important;
        background-color: var(--form_bg_color);
      }
      &.zent-checkbox-checked > .zent-checkbox {
        .zent-checkbox-inner {
          border-color: var(--form_select_color) !important;
          background-color: var(--form_select_color) !important;
        }
        .zent-checkbox-inner::after {
          background-color: var(--form_select_color) !important;
        }
      }
    }
  }

  .form-container {
    display: flex;
    &.form-check-portrait {
      flex-direction: column;
      .form-item {
        margin-top: 24px;
        &:nth-child(1) {
          margin-top: 0px;
        }
      }
    }
    &.form-check-transverse {
      flex-direction: row;
      flex-flow: row wrap;
      align-items: center;
      .form-item {
        margin-right: 24px;
      }
    }
  }

  .form-check-item {
    font-size: 14px;
    line-height: 20px;
    color: var(--form_title_color);
  }
  .form-item {
    font-size: 14px;
    line-height: 20px;
    color: var(--form_title_color);
    .zent-checkbox-wrap {
      display: flex;
    }
    .zent-checkbox-label {
      word-break: break-all;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;
