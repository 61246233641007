import styled from 'styled-components';
import { getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  margin: 0 auto;
  width: 100%;

  .cn-fix_1440 {
    margin: 0 !important;
  }
  .slide-menu {
    position: absolute;
    width: 152px;
    bottom: 0;
    /* right: 0; */
    z-index: 1;
    display: flex;
    align-items: center;

    .slide-navigation-bar {
      background: rgba(255, 255, 255, 1);
      width: 50%;
      padding-top: 20%;
      padding-bottom: 20%;
      text-align: center;
      cursor: pointer;
      :hover {
        background: rgba(255, 255, 255, 0.3);
      }
      svg {
        width: 39px;
        height: 39px;
        fill: #394958;
      }
    }
  }

  .hidden-text {
    display: none;
  }
  .slide-item-img-wrapper-video {
    position: absolute !important;
    width: 100%;
  }
  .slide-item-btn-video {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 60px;
    fill: #fff;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    margin-top: 16px;
  }
  .video-align-full {
    object-fit: fill;
  }
  .video-align-auto {
    max-height: 100vh;
    text-align: center;
  }
  .swiper-wrapper {
    display: flex;
  }
  .slide-show {
    position: relative;
    /* overflow: hidden; */
  }

  .slide-img-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    background: var(--color_image_overlay);
    opacity: var(--image_overlay_opacity);
  }

  .initial {
    .swiper-slide {
      overflow: hidden;
    }
  }

  .swiper-slide {
    width: 100%;
    min-width: 100%;
    text-align: center;
    font-size: var(--font-18-14);
    /* background: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide-indicator-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;

    + .slide-indicator-dot {
      margin-left: 12px;
    }
  }

  .pause-control-btn {
    width: 32px;
    height: 32px;
    margin-left: 8px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(21, 22, 27, 0.5);

    .icon {
      fill: #fff;
    }
  }

  .slide-item {
    width: 100%;
    position: relative;
  }
  .slide-item-img-wrapper {
    height: 100%;
    position: relative;
  }
  .slide-item-img {
    width: 100%;
    object-fit: cover;
    position: relative;
  }

  .default-img-box {
    border: none;
  }

  /* .img-left-top {
        object-position: left top;
    }

    .img-center-top {
        object-position: center top;
    }

    .img-right-top {
        object-position: right top;
    }

    .img-left-center {
        object-position: left center;
    }

    .img-center-center {
        object-position: center center;
    }

    .img-right-center {
        object-position: right center;
    }

    .img-left-bottom {
        object-position: left bottom;
    }

    .img-center-bottom {
        object-position: center bottom;
    }

    .img-right-bottom {
        object-position: right bottom;
    } */

  .slide-item-img,
  .video-wrapper {
    height: ${(props) => props.slideImageHeight};
  }
  .slide-item-btn {
    display: flex;
    align-items: center;
    background: var(--color_button);
    .return-icon {
      margin-left: 6px;
    }
  }
  .slide-item-btn-wrapper {
    padding: ${(props) => props.theme.getSpace(props.theme.vTextButton)} 0;
  }
  @media screen and (min-width: 751px) {
    .slide-item-btn-wrapper {
      display: inline-block;
    }
    .text-mobile {
      display: none;
    }
    .slide-menu {
      right: 0;
    }
    .text-pc {
      display: block;
    }
    &.layout-two-left {
      .slide-item {
        display: flex;
        text-align: left;
        .slide-item-img-wrapper {
          width: 60%;
        }
        .slide-item-text {
          margin-top: 10%;
          margin-left: 40px;
        }
        .slide-item-desc {
          text-align: left;
        }
      }
    }
    &.layout-two-right {
      .slide-item {
        display: flex;
        flex-direction: row-reverse;
        text-align: left;
        .slide-item-img-wrapper {
          margin-left: 40px;
          width: 60%;
        }
        .slide-item-text {
          text-align: left;
          width: 40%;
          margin-top: 10%;
        }
        .slide-item-desc {
          text-align: left;
        }
      }
    }
    .slide-item-text {
      .slide-item-title {
        font-weight: 700;
        font-size: 48px;
      }

      .slide-item-desc {
        font-size: 16px;
        margin-top: 40px;
      }
    }

    .slide-item-btn {
      margin-top: var(--base-font-24-18);
    }

    .slide-indicator-dot {
      background: #fff;
      opacity: 0.6;
      &.slide-indicator-active {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .text-mobile {
      display: block;
    }
    .text-pc {
      display: none;
    }
    background: #fff;
    .slide-show-section-inner {
      width: calc(100% - 30px);
      margin: 0 auto;
      background: #fff;
    }
    .slide-item-img-wrapper {
      padding-right: 30px;
      background: #fff;
    }
    .slide-show {
      /* width: calc(100% - 60px); */
    }
    .swiper-container {
      overflow: inherit !important;
    }
    .slide-item-btn {
      color: #1a1a1b !important;
      .return-icon {
        fill: #394958 !important;
      }
    }

    .slide-menu {
      display: none;
    }
    max-width: 100%;
    .slide-item-img-wrapper,
    .slide-item-img,
    .video-wrapper {
      img {
        max-height: 100%;
      }
    }

    .hidden-text {
      display: block !important;
    }
    .slide-indicator-dot {
      background: var(--color_button);
      opacity: 0.6;
      &.slide-indicator-active {
        opacity: 1;
      }
    }

    .width-wrapper {
      padding-right: 30px;
      display: flex;
      justify-content: center;
      .slide-item-text {
        color: inherit !important;
        margin-bottom: 2px;
        color: inherit;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.06);
        .slide-item-title {
          font-size: 18px;
        }
        .slide-item-desc {
          font-size: 14px;
        }
      }
    }

    .slide-item-text {
      color: #1a1a1b !important;
      position: relative;
      width: 80%;
      text-align: left;
      padding: 30px;
      padding-left: 0;
      padding-top: 52px;
      box-sizing: border-box;
      margin-top: -50px;
      margin-left: -1px;
      background-color: #fff;
      .slide-item-title {
        font-size: 28px;
        font-weight: 700;
      }
      .slide-item-desc {
        margin-top: 15px;
        font-size: 14px;
        line-height: 1.6;
        word-break: break-word;
      }
    }

    .slide-item-btn {
      margin-top: var(--pm-16-10);
    }
  }
`;
