import React from 'react';
import { HeaderOperte } from '../components/header-operte';
import { MobileSubMenu } from '../components/mobile-submenu';
import { HeaderAnnouncement } from '../components/header-announcement';
import HeaderWrapper from './style';
import { useIntl } from 'react-intl';

export const MobileHeader = (props) => {
  const {
    settings,
    initialData,
    elemClass,
    jumpToOtherPage,
    jumpToMenuPage,
    totalCountWish,
    menuDetail,
    checkoutHeader,
  } = props;
  const {
    main_linklist: mainLinkList = {},
    show_announcement: showAnnouncement,
    announcement_text: announcementText,
    announcement_link: announcementLink,
    logo_max_width: logoMaxWidth,
    color_bg: colorBg,
    color_text: colorText,
    logo,
    align_logo: alignLogo,
  } = settings;

  const intl = useIntl();
  return (
    <HeaderWrapper>
      <div className={elemClass}>
        <HeaderAnnouncement
          showAnnouncement={showAnnouncement}
          colorBg={colorBg}
          colorText={colorText}
          announcementText={announcementText}
          announcementLink={announcementLink}
        />

        <div className='sub-header-container'>
          <div className='wrapper'>
            <MobileSubMenu
              jumpToMenuPage={jumpToMenuPage}
              jumpToOtherPage={jumpToOtherPage}
              menuDetail={menuDetail}
              initialData={initialData}
              totalCountWish={totalCountWish}
              settings={settings}
            />
            {checkoutHeader ? (
              <div className='pay-safe'>
                <span>
                  {intl.formatMessage({
                    defaultMessage: '安全支付',
                    id: '82255fdf2a344a43b51150dff6b6593a',
                  })}
                </span>
                <svg width={20} height={20} fill='#48AB7C'>
                  <use xlinkHref='#iconanquanzhifu'></use>
                </svg>
              </div>
            ) : (
              <HeaderOperte jumpToOtherPage={jumpToOtherPage} initialData={initialData} alignLogo={alignLogo} />
            )}
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};
