import React from 'react';
import { IconSizeMap } from '../../../const/standard';
import Style from './style';

function Icon(props) {
  const { name, size, ...reset } = props;
  return (
    <Style size={IconSizeMap[size] || [size]} {...reset} aria-hidden='true'>
      <use xlinkHref={`#${name}`} />
    </Style>
  );
}

export default Icon;
