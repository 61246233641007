import styled from 'styled-components';

export const Style = styled.div`
  padding: 12px var(--pm-24-18);
  /* background: #fafafa; */
  width: 100%;
  margin-left: -24px;
  @media screen and (max-width: 750px) {
    margin-left: -18px;
  }
  .title-operation {
    display: flex;
    justify-content: space-between;
    color: #646566;
    .overview {
      /* color: var(--color_button); */
      cursor: pointer;
    }
  }
  .title-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      fill: #982424;
      margin-right: 5px;
    }
  }
  .title-right {
    display: flex;
    align-items: center;
    color: #982424;
    cursor: pointer;
    svg {
      fill: #982424;
    }
  }
  .switch-icon {
    &.up {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    box-sizing: border-box;
    border-top: 1px solid rgba(51, 51, 51, 0.08);
    button {
      height: 32px !important;
      line-height: 20px !important;
    }
  }
`;

export const NewGiftStyle = styled.div`
  padding: 16px;
`;
