import styled from 'styled-components';

export const Style = styled.div`
  border-radius: var(--border_radius_pd_card);
  overflow: hidden;
  .image-container {
    position: relative;
    height: 0;

    //下面的高度计算，以屏幕宽度为基准，在屏幕宽度不变的情况下，容器宽度有变化，也不会改变高度。
    &.full-height {
      height: 100vh;
    }
    &.adapt {
      // js计算
    }
    &.x-small {
      height: ${(props) => props.theme.getSpace([183, 194, 215])};
    }

    &.small {
      height: ${(props) => props.theme.getSpace([240, 255, 300])};
    }
    &.medium {
      height: ${(props) => props.theme.getSpace([361, 394, 475])};
    }

    &.large {
      height: ${(props) => props.theme.getSpace([455, 468, 650])};
    }

    &.x-large {
      height: ${(props) => props.theme.getSpace([527, 543, 775])};
    }
    // 第2套高度规则
    &.x-small2 {
      height: ${(props) => props.theme.getSpace([361, 394, 475])};
    }
    &.medium2 {
      height: ${(props) => props.theme.getSpace([455, 468, 650])};
    }
    &.large2 {
      height: ${(props) => props.theme.getSpace([490, 504, 720])};
    }
    // 第3套宽度规则
    &.radio-16-9 {
      padding-top: calc(9 / 16 * 100%) !important;
    }
    &.radio-4-3 {
      padding-top: calc(3 / 4 * 100%) !important;
    }
    &.radio-1-1 {
      padding-top: calc(1 / 1 * 100%) !important;
    }
    &.radio-3-2 {
      padding-top: calc(2 / 3 * 100%) !important;
    }
    &.radio-3-4 {
      padding-top: calc(4 / 3 * 100%) !important;
    }
    &.radio-2-3 {
      padding-top: calc(3 / 2 * 100%) !important;
    }
    .bg-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .img-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${(props) => props.theme.colorImageOverlay};
    }
    .content {
      position: absolute;
      top: ${(props) => props.theme.getSpace(props.theme.pImagesMedium)};
      left: ${(props) => props.theme.getSpace(props.theme.pImagesMedium)};
      right: ${(props) => props.theme.getSpace(props.theme.pImagesMedium)};
      bottom: ${(props) => props.theme.getSpace(props.theme.pImagesMedium)};
      overflow: hidden;
    }
    &.hide-img {
      .bg-img {
        opacity: 0;
      }

      & > .content {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  @media screen and (max-width: 750px) {
    border-radius: 0;
  }
`;
