import React from 'react';
import { Style } from './style';
import NewLink from 'cpn/new-link';
import cn from 'classnames';
import Image from 'components/base/image';

/**
 * footer Logo
 * */
const FooterLogo = ({ settings = {}, style, socialLinkArr = [], mobileDeviceFooterTextAlign }) => {
  const {
    image,
    title,
    sub_title: subTitle,
    show_social_media_icon: showSocialMediaIcon = false,
    social_media_icon_color: socialMediaIconColor,
    mobile_pic_full_screen: mobilePicFullScreen,
    logo_size: logoSize,
    media_logo_size: mediaLogoSize = 14,
  } = settings;

  return (
    <Style mobileDeviceFooterTextAlign={mobileDeviceFooterTextAlign} mediaLogoSize={mediaLogoSize}>
      <div className='footer-logo-section'>
        {!image ? (
          <div style={style} className='footer-logo-title'>
            {title}
          </div>
        ) : (
          <div
            className={cn('footer-logo-img', `mobile-pic-full-screen-${mobilePicFullScreen}`)}
            style={{ width: logoSize }}
            title={title}
          >
            <Image src={image} style={{ width: logoSize }} alt={title} />
          </div>
        )}
        {/* 副标题 */}
        {subTitle && (
          <div style={style} className='footer-logo-sub-title'>
            <div dangerouslySetInnerHTML={{ __html: subTitle }} />
          </div>
        )}
        {showSocialMediaIcon && !!socialLinkArr?.length && (
          <div className='social-media-icon'>
            {socialLinkArr.map((item, index) => {
              const { icon, link } = item;
              return (
                <NewLink className='footer-social-icon-link' href={link} target='_blank' key={index}>
                  <svg className='footer-social-icon' aria-hidden='true' fill={socialMediaIconColor}>
                    <use xlinkHref={`#${icon}`} />
                  </svg>
                </NewLink>
              );
            })}
          </div>
        )}
      </div>
    </Style>
  );
};

export default FooterLogo;
