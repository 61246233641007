import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { Style } from './style';
import { TRACK } from 'const';

const GoodsButton = ({ style, size, onClick, customClassName, disabled, hover, active, type, text }) => {
  const intl = useIntl();

  return (
    <Style
      type='primary'
      onClick={onClick}
      className={cn(
        'goods-button',
        `goods-button-${type}`,
        `goods-button-${size}`,
        customClassName,
        TRACK.BuyOnceButton,
        { 'button-disabled': disabled },
        { 'button-hover': hover && !disabled },
        { 'button-active': active && !disabled },
      )}
      style={style}
    >
      {text || intl.formatMessage({ id: 'goods.buy_once' })}
    </Style>
  );
};

export default GoodsButton;
