import styled from 'styled-components';

export const Arrow = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .slide-btn-left {
    transform: rotate(180deg);
  }
  &.line,
  &.digital {
    top: 50%;
    transform: translateY(-50%);
    width: 52px;
    height: 52px;
    background: rgba(0, 0, 0, 0.1);
    &.slide-prev {
      left: 80px;
    }
    &.slide-next {
      right: 80px;
    }
    .slide-btn {
      font-size: 32px;
      fill: #fff;
    }
  }
  &.dots {
    bottom: 40px;
    right: 80px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colorButton};
    border: 1px solid ${(props) => props.theme.colorButton};
    &.show-ad {
      bottom: 92px;
    }
    /* &.disabled {
            border: 1px solid rgba(255, 255, 255, 0.3);
            background: transparent;
            .slide-btn {
                fill: rgba(255, 255, 255, 0.3);
            }
        } */
    &.slide-prev {
      right: 128px;
    }
    &.slide-next {
      right: 80px;
    }
    .slide-btn {
      width: 24px;
      height: 24px;
      fill: #fff;
    }
  }
`;

export const Indicator = styled.div`
  position: absolute;
  line-height: 1;
  bottom: ${(props) => props.theme.getSpace(props.theme.pCards)};
  z-index: 10;
  box-sizing: border-box;
  height: ${(props) => props.theme.getSpace([22, 32])};
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 0 16px;
  &.show-ad {
    bottom: ${(props) =>
      `${parseInt(props.theme.getSpace(props.theme.pCards)) + parseInt(props.theme.getSpace([34, 52]))}px`};
  }
  &.dots {
    left: ${(props) => props.theme.getSpace(props.theme.hContainer)};
    &.center {
      transform: translateX(-50%);
      left: 50%;
    }
    .indicator-item {
      width: ${(props) => props.theme.getSpace([4, 8])};
      height: ${(props) => props.theme.getSpace([4, 8])};
      border-radius: 50%;
      background-color: #fff;
      &.active {
        box-sizing: border-box;
        width: ${(props) => props.theme.getSpace([7, 14])};
        height: ${(props) => props.theme.getSpace([7, 14])};
        background-color: transparent;
        border: ${(props) => props.theme.getSpace([1, 2])} solid #ffffff;
      }
    }
    .indicator-item + .indicator-item {
      margin-left: ${(props) => props.theme.getSpace([6, 12])};
    }
  }
  &.line {
    border-radius: 0;
    left: 50%;
    transform: translateX(-50%);
    .indicator-text {
      color: rgba(255, 255, 255, 0.5);
      &.left {
        margin-right: ${(props) => props.theme.getSpace([8, 16])};
        color: #fff;
      }
      &.right {
        margin-left: ${(props) => props.theme.getSpace([8, 16])};
      }
    }
    .indicator-item {
      width: ${(props) => props.theme.getSpace([12, 32])};
      height: ${(props) => props.theme.getSpace([2, 3])};
      background-color: rgba(255, 255, 255, 0.3);
      &.active {
        background-color: ${(props) => props.theme.colorButton};
      }
    }
    .indicator-item + .indicator-item {
      margin-left: ${(props) => props.theme.getSpace([4, 8])};
    }
  }
  &.digital {
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    .indicator-text {
      &.active {
        color: #fff;
      }
    }
  }
  &.default {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    .slide-btn {
      width: 14px;
      height: 14px;
      fill: #fff;
    }
    .slide-btn-left {
      margin-right: ${(props) => props.theme.getSpace([8, 16])};
      transform: rotate(180deg);
    }
    .slide-btn-right {
      margin-left: ${(props) => props.theme.getSpace([8, 16])};
    }
    .indicator-item {
      width: ${(props) => props.theme.getSpace([5, 10])};
      height: ${(props) => props.theme.getSpace([5, 10])};
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      &.active {
        background-color: rgba(255, 255, 255, 1);
      }
    }
    .indicator-item + .indicator-item {
      margin-left: ${(props) => props.theme.getSpace([6, 12])};
    }
  }
  &.dots-btn {
    .indicator-item {
      width: ${(props) => props.theme.getSpace([6, 8])};
      height: ${(props) => props.theme.getSpace([6, 8])};
      border-radius: 50%;
      background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorButton, 0.3)};
      border: 0.5px solid #ffffff;
      &.active {
        background-color: ${(props) => props.theme.colorButton};
      }
    }
    .indicator-item + .indicator-item {
      margin-left: ${(props) => props.theme.getSpace([6, 12])};
    }
  }
`;

export const IndicatorNoBackground = styled.div`
  padding: ${(props) => props.theme.getSpace([16, 40])} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  &.dots-btn {
    .indicator-item {
      width: ${(props) => props.theme.getSpace([6, 8])};
      height: ${(props) => props.theme.getSpace([6, 8])};
      border-radius: 50%;
      background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorButton, 0.3)};
      cursor: pointer;
      &.active {
        background-color: ${(props) => props.theme.colorButton};
      }
    }
    .indicator-item::before {
      content: '  ';
      display: block;
      width: 200%;
      height: 200%;
      border: 1px solid #ffffff;
      transform-origin: 0 0;
      transform: scale(0.5) translate(-1px, -1px);
      border-radius: 50%;
    }
    .indicator-item + .indicator-item {
      margin-left: ${(props) => props.theme.getSpace([6, 12])};
    }
  }
  &.line-btn {
    .indicator-container {
      background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorButton, 0.3)};
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 1px solid #ffffff;
      transform: scale(0.5);
      .indicator-item {
        width: 32px;
        height: ${(props) => props.theme.getSpace([4, 8])};
        border-radius: 2px;
        cursor: pointer;
        &.active {
          background-color: ${(props) => props.theme.colorButton};
        }
      }
    }
    /* .indicator-container::before {
            content: '  ';
            position: absolute;
            left: 0;
            top: 0;
            width: 200%;
            height: 200%;
            border: 1px solid #ffffff;
            transform-origin: 0 0;
            transform: scale(0.5) translate(-1px, -1px);
            border-radius: 4px;
        } */
  }
`;

export const CustomArrow = styled.div`
  width: 100%;
  .arrow-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    z-index: 10;
    fill: var(--color_body_text);
    cursor: pointer;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    /* &.disabled {
            fill: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.4)} !important;
            cursor: not-allowed;
        } */
  }
  .arrow-container {
    width: 32px;
    height: 32px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    z-index: 10;
    background-color: var(--color_button);
    &.circle {
      border-radius: 50%;
    }
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--color_button_text);
    }
  }
  &.bottom-arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .bottom-arrow-icon {
      width: 24px;
      height: 24px;
      fill: var(--color_body_text);
    }
    .bottom-arrow-icon + .bottom-arrow-icon {
      margin-left: 24px;
    }
  }
`;
