import { useState, useEffect } from 'react';
import goodsApi from 'api/goods';

export const useReview = ({ overviews, total, kdtId, goodsId }) => {
  const [reviewOverview, setReviewOverview] = useState(overviews);
  const [reviewTotalCount, setReviewTotalCount] = useState(total || 0);
  const [showReview, setShowReview] = useState(total || 0);

  useEffect(() => {
    if (!kdtId) return;
    fetchIsOpenReview();
    // fetchGoodReviewOverview();
  }, [kdtId]);

  const fetchIsOpenReview = () => {
    goodsApi.getReviewsSettingsByKdtId({ kdtId }).then((res) => {
      setShowReview(res?.openSwitch);
    });
  };

  const fetchGoodReviewOverview = async () => {
    if (!showReview) {
      return;
    }

    if (!goodsId) {
      return;
    }

    const params = { kdtId, goodsId };

    goodsApi.searchReviewsWithPage({ ...params, page: 1, pageSize: 10 }).then((res) => {
      const { paginator: { totalCount } = {} } = res || {};
      setReviewTotalCount(totalCount);
    });

    // 这里后端接口其实没设计好 详情数据应该具有评论总数
    goodsApi.getReviewsAggregateByGoodsId(params).then((res) => {
      setReviewOverview(res);
    });
  };

  return {
    showReview,
    reviewOverview,
    setReviewOverview,
    reviewTotalCount,
    fetchIsOpenReview,
    setReviewTotalCount,
    fetchGoodReviewOverview,
  };
};
