import styled from 'styled-components';

export const Style = styled.div`
  .mobile-style,
  .pc-style {
    display: none;
  }
  /* .action-wrapper {
        display: flex;
        margin-top: 24px;
        .btn-buy {
            flex-basis: 20px;
            flex-grow: 1;
        }
        .btn-cart {
            flex-basis: 20px;
            flex-grow: 1;
        }
    } */
  .collection-v2-item-2:nth-child(-n + 2) {
    margin-top: 0;
  }
  .collection-v2-item-3:nth-child(-n + 3) {
    margin-top: 0;
  }
  .collection-v2-item-4:nth-child(-n + 4) {
    margin-top: 0;
  }
  .collection-v2-item-5:nth-child(-n + 5) {
    margin-top: 0;
  }
  .collection-v2-item-6:nth-child(-n + 6) {
    margin-top: 0;
  }
  /* .add-shopping-car-container {
        position: absolute;
        bottom: 0;
        width: 100%;
    } */
  /* .add-shopping-car-btn {
        border: none;
        font-size: var(--base-font-16-14);
        font-weight: 500;
        color: var(--color_small_button_text_border);
        line-height: 16px;
        text-align: center;
        width: 100%;
        background: transparent;
        cursor: pointer;
        border: 1px solid var(--color_small_button_text_border);
        white-space: nowrap;
        text-overflow: ellipsis;
    } */
  /* .discount-tag {
        margin-top: var(--pm-12-8);
    } */
  @media screen and (max-width: 750px) {
    /* margin: 20px; */
    // 手机
    .action-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .zent-btn {
        display: block;
        margin-left: 0 !important;
        &.btn-buy {
          margin-top: 10px;
        }
      }
    }
    .link-button {
      display: flex;
      justify-content: center;
    }
    .mobile-style {
      display: block;
      position: relative;
      width: 85%;
      margin: 0 auto;
      .swiper-container {
        width: 100%;
        position: relative;
        padding-bottom: 28px;
        overflow: inherit !important;
        .swiper-scrollbar {
          bottom: 0;
          height: 4px;
        }
      }

      .image-box {
        /* height: 255px !important; */
      }
      /* .default-img-box {
                padding-bottom: 0 !important;
                padding-top: 0 !important;
            } */
    }
    .feature-collection-grid-item {
      padding: 20px 0;
      margin-bottom: 20px;
    }
    .add-shopping-car-container {
      position: fixed;
      align-items: flex-end;
      background-color: rgba(0, 0, 0, 0.3);
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 9999;
      left: 0;
    }
    .feature-collection-section {
      flex-direction: column !important;
      .section-intro {
        text-align: center;
        margin: 0 auto;
      }
      .section-list {
        background: #f8f8f8;
      }
      .section-sub-title {
        margin-right: 0px;
      }
    }
  }
  @media screen and (min-width: 750px) {
    &.cn-center {
      .feature-collection-section {
        flex-direction: column;
        .section-intro {
          margin: 0 auto;
          text-align: center;
          margin-bottom: ${(props) => props.theme.getSpace(props.theme.vContainer)};
          .link-button {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    &.cn-right {
      .feature-collection-section {
        flex-direction: row-reverse;
        .section-intro {
          text-align: right;
          .link-button {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
    &.cn-left .section-intro {
      max-width: 25%;
      min-width: 0;
      margin-right: 24px;
      &.full-screen {
        margin-left: ${(props) => props.theme.getSpace(props.theme.hContainer)};
      }
    }
    &.cn-right .section-intro {
      max-width: 25%;
      min-width: 0;
      margin-left: 24px;
      &.full-screen {
        margin-right: ${(props) => props.theme.getSpace(props.theme.hContainer)};
      }
    }
    .pc-style {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .feature-collection-section {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .section-intro {
    min-width: 264px;
    .section-title {
      margin-bottom: ${(props) => props.theme.getSpace(props.theme.vText)};
      word-break: break-all;
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
      color: var(--color_text);
    }
    .section-sub-title {
      margin-bottom: ${(props) => props.theme.getSpace(props.theme.vTextButtons)};
      word-break: break-all;
    }
  }

  .section-list {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    .feature-collection-grid-item {
      /* position: relative;
            .default-img-box {
                padding-bottom: 10%;
                padding-top: 10%;
            }
            .image-box {
                cursor: pointer;
                position: relative;
                .add-shopping-car-btn {
                    opacity: 0;
                    position: absolute;
                    bottom: 10px;
                    width: 80%;
                    width: 85%;
                    left: 7.5%;
                }
                img {
                    width: 100%;
                }

                &:hover {
                    .add-shopping-car-btn {
                        opacity: 1;
                    }
                }
                .shop-guide {
                    margin-right: 20px;
                }
                .sold-status-tip {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    padding: 4px 10px;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-size: 14px;
                    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
                    &.sell-out-btn {
                        background: rgba(0, 0, 0, 0.3);
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            } */

      /* .goods-name {
                margin-top: 20px;
                color: var(--color_text);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: ${(props) => props.textLineLimit};
            }
            .goods-price {
                color: var(--color_sale_text);
                margin-top: 8px;
            } */
    }
  }

  .feature-collection-section {
    .section-intro {
      margin-bottom: ${(props) => props.theme.getSpace(props.theme.vContainer)};
    }
  }
`;
