import styled from 'styled-components';
import { getSizeVw } from './fn';
/**
 * layout
 *
 * 适配尺寸：
 *
 * 1. 1024
 * 2. 768
 * 3. 375
 *
 */

/**
 * 指定尺寸下显示
 * min: 最小宽度
 * max: 最大宽度
 */

export const ScreenShow = styled.section`
  && {
    && {
      display: none;
    }
  }

  @media screen and (min-width: ${(props) => props.min || '0px'}) and (max-width: ${(props) => props.max || '6000px'}) {
    && {
      && {
        display: flex;
      }
    }
  }
`;

/**
 * 两列布局。
 * PC端左右，缩小到一定尺寸变上下布局，默认1024变上下布局
 * paddingTop： 变上下布局后的距离,默认16px
 */
export const TwoCol = styled.section.attrs({
  className: 'col-box',
})`
  display: flex;
  width: 100%;
  @media screen and (max-width: ${(props) => props.screenWidth || '1024px'}) {
    flex-direction: ${(props) => props.direction || 'column'};
    .col-1,
    .col-2 {
      width: 100%;
      margin-left: 0px !important;
      margin-top: ${(props) => props.mTop || '16px'};
    }
    .col-1 {
      margin-top: 0;
    }
  }
`;

/**
 * width: 宽度
 */
export const LeftCol = styled.div.attrs({
  className: 'col-1',
})`
  width: ${(props) => props.width || '40%'};
`;

/**
 * right：宽度
 */
export const RightCol = styled.div.attrs({
  className: 'col-2',
})`
  flex: 1;
  width: ${(props) => props.width || '40%'};
`;

/**
 * 二级页外层布局。
 * 默认布局：中间固定宽度 = 1040  两边margin 最低16 ，最小宽度=1024+32 = 1072
 * < 1072 : 两边margin保持24px
 * < 750  : 两边margin保持16px
 */
export const FixLayout = styled.div.attrs({
  className: 'fixed-width',
})`
  width: ${(props) => (props.width ? (props.width === '100%' ? '100%' : `${props.width}px`) : '100%')};
  margin: 0 auto;
  box-sizing: ${(props) => (props.width === '100%' ? 'border-box' : 'auto')};
  padding-left: ${(props) => (props.width === '100%' ? '20px' : 'auto')};
  padding-right: ${(props) => (props.width === '100%' ? '20px' : 'auto')};
  /* padding-top: 40px; */
  @media screen and (max-width: ${(props) =>
      props.width ? (props.width == '100%' ? '100%' : `${props.width - 0 + 32}px`) : '100%'}) {
    padding-top: 0px;
    margin-top: 0px;
    width: calc(100% - 48px);
    margin: 0 24px;
  }
  @media screen and (max-width: 750px) {
    padding-top: 0px;
    width: calc(100% - 0px);
    margin: 0px;
  }
`;
/**
 * 二级页两列布局。
 *  默认：左右布局，中间间距16px
 * <1072:到一定尺寸变上下布局, 上下间距16px
 */
export const SubTwoCol = styled.section.attrs({
  className: 'col-box',
})`
  display: flex;
  width: 100%;
  margin-top: ${(props) => props.mTop};
  margin-bottom: 40px;
  @media screen and (max-width: 1072px) {
    flex-direction: ${(props) => props.direction || 'column'};
    .col-item {
      width: 100%;
      margin-left: 0px !important;
      &.col-1 {
        margin-top: ${(props) => (props.direction === 'column-reverse' ? '16px' : '0px')};
      }
      &.col-2 {
        margin-top: ${(props) => (props.direction === 'column-reverse' ? '0px' : '16px')};
      }
    }
  }
`;

/**
 *  二级页left: 默认左侧为主区域
 */
export const SubLeftCol = styled.div.attrs({
  className: 'col-1 col-item',
})`
  width: ${(props) => props.width || '64%'};
`;

/**
 * 二级页 right
 */
export const SubRightCol = styled.div.attrs({
  className: 'col-2 col-item',
})`
  flex: 1;
  margin-left: 16px;
  width: ${(props) => props.width || '33%'};
`;

/**
 * 图片拉伸 以宽度为标准作为背景
 * backgroundImage
 */
export const ImgSpreadBackground = styled.section`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  background-image: url(${(props) => props.imageUrl});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 1000px) {
    /* background-size: auto 100%; */
    background-size: cover;
  }
`;

/**
 *
 * 图片布局。适用于一行都是图片，在不同屏幕下一列显示不同的个数
 */
export const ImgLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .goods-item {
    margin-right: 40px;
    margin-bottom: 40px;
    display: inline-block;
  }
  @media screen and (min-width: 1000px) {
    .goods-item {
      margin-right: 40px;
      width: calc((100% - 120px) / 4);
      :nth-of-type(4n) {
        margin-right: 0px;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1000px) {
    .goods-item {
      margin-right: 15px;
      margin-bottom: 15px;
      width: calc((100% - 30px) / 3);
      :nth-of-type(3n) {
        margin-right: 0px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .goods-item {
      margin-right: 15px;
      margin-bottom: 15px;
      width: calc((100% - 15px) / 2);
      :nth-of-type(2n) {
        margin-right: 0px;
      }
    }
  }
`;

/**
 *
 * 商详图片布局 可以进行装修配置
 */
export const ImgLayoutProductCollection = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .goods-item {
    padding: 0 20px;
    margin-bottom: 40px;
    display: inline-block;
    box-sizing: border-box;
    width: calc((100%) / ${(props) => props.grid || 3});
  }
  @media screen and (min-width: 768px) and (max-width: 1000px) {
    .goods-item {
      padding: 0 5px;
      margin-bottom: 15px;
    }
  }
  @media screen and (max-width: 767px) {
    .goods-item {
      padding: 0 5px;
      margin-bottom: 15px;
      width: calc((100%) / 2);
    }
  }
`;

/**
 * 指定尺寸显示
 * PC：80
 * H5: 16
 * padding
 */
export const PaddingLayout = styled.section`
  padding: 0 ${(props) => props.pcPadding || '80px'};
  padding-top: ${(props) => props.pcMarginTop || '40px'};
  box-sizing: border-box;
  @media screen and (max-width: 750px) {
    padding: 0 ${(props) => props.h5Padding || '16px'};
    padding-top: ${(props) => props.h5MarginTop || '24px'};
    box-sizing: border-box;
  }
`;

export const RichText = styled.section`
  img {
    max-width: 100%;
    height: auto;
    margin-top: 15px;
  }
  table,
  p {
    max-width: 100%;
  }
  ol li,
  ul li {
    list-style-position: inside;
  }
`;
