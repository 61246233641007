import isFunction from 'lodash/isFunction';
import get from 'lodash/get';

export const MessageType = {
  CLIENT_CHANGE: 'clientChange',
  ADMIN_CHANGE: 'adminChange',
  CURRENT_SECTION: 'currentSection',
  HASH_CHANGE: 'hashChange',
};

export class Message {
  constructor(props, callBack) {
    this.messageHandler = callBack;
    this.props = props;
    this.addListenMessage();
  }

  isMessageSafe(origin) {
    const domain = get(this.props, 'initialData.shopInfo.primaryDomain', '');
    const safeOrigin = `https://${domain}`;
    return origin === safeOrigin;
  }

  handlerMessage(message) {
    // 后端把domain字段干掉了，擦，先注释
    // if (!this.isMessageSafe(message.origin)) return;

    const messageType = get(message, 'data.type');
    const messageContent = get(message, 'data.content');

    if (MessageType) {
      if (isFunction(this.messageHandler)) {
        this.messageHandler(messageContent, messageType);
      }
    }
  }

  addListenMessage() {
    window.addEventListener('message', this.handlerMessage.bind(this), false);
  }

  removeListenMessage() {
    window.removeEventListener('message', this.handlerMessage);
  }
}
