import styled from 'styled-components';
// 本组件的难点：容器宽度有关键点，如果屏幕尺寸在关键点之间的，需要自动适配
// 解决方案，1:拿到容器实际展示的宽度，取配置的宽度和屏幕宽度的较小值
// 2:组件里的内容都按相对实际宽度的百分比布局（除规范外）
export const Style = styled.div`
  /* 如果存在多个视频的情况，会使用sticky布局，以实现视觉上的效果：滚动时页面不滚动，滚动视频 */
  .area-stage {
    & > div {
      display: flex;
      justify-content: center;
      &.main-image-right .content {
        flex-direction: row-reverse;
      }
    }
    .content {
      display: flex;
    }
    .area-content {
      position: sticky;
      top: 0;
      left: 0;
      width: 50%;
      height: 100vh;
      overflow: hidden;

      .cover-images {
      }
    }

    .goods-images {
      width: 50%;
      min-height: 100%;
      padding: ${(props) => props.theme.getSpace(props.theme.pImagesLarge)} 0;
      position: relative;
      .goods-images-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content {
          top: ${(props) => props.theme.getSpace(props.theme.pImagesSmall)};
          left: ${(props) => props.theme.getSpace(props.theme.pImagesSmall)};
          right: ${(props) => props.theme.getSpace(props.theme.pImagesSmall)};
          bottom: ${(props) => props.theme.getSpace(props.theme.pImagesSmall)};
        }
      }
      .good-image {
        width: calc(480 / 720 * 100%);
        margin-bottom: ${(props) => props.theme.getSpace(props.theme.hCards)};
        z-index: 9;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .goods-images-bg-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .goods-images-bg {
          width: 100%;
        }
      }
    }
    // pc端特有出场入场动效
    .is-pc {
      .area-content {
        .cover-images {
          transition: opacity 1s;
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 9;
          background-color: ${(props) => props.bgColor};
          transition: all 1s;
        }
        &.hide .cover-images {
          opacity: 0;
        }
        &.hide:before {
          right: 0;
          left: 0;
        }
        &.show:before {
          right: 0;
          left: 100%;
        }
      }
      &.main-image-right {
        .area-content {
          &.hide:before {
            left: 0;
            right: 0;
          }
          &.hide .cover-images {
            opacity: 0;
          }
          &.show:before {
            left: 0;
            right: 100%;
          }
        }
      }
    }
    // 手机端适配
    .is-mobile {
      .container-size {
        height: auto !important;
      }
      .content {
        flex-direction: column !important;
        .area-content {
          height: auto;
          width: 100%;
          position: relative;
          .cover-images.mobile-text-up {
            margin-bottom: ${(props) => props.theme.getSpace(props.theme.hCards)};
            .image-container {
              height: auto;
              .bg-img {
                position: relative;
              }
            }
          }
          .cover-images.mobile-text-down {
            .image-container {
              height: auto;
              .bg-img {
                position: relative;
                height: calc(469 / 375 * 100vw);
              }
              .content {
                position: relative;
                left: 0;
                top: 0;
                padding: 16px 16px 32px 16px;
              }
            }
          }
        }
        .goods-images {
          width: 100%;
          padding: 0 16px;
          box-sizing: border-box;
          .good-image {
            width: 100%;
          }
          .good-image.mobile-text-up {
            margin-bottom: ${(props) => props.theme.getSpace(props.theme.hCards)};
          }
          .good-image.mobile-text-down {
            width: 100%;
            margin-bottom: 0px;
            .image-container {
              height: auto;
              padding: 0 !important;
              &.radio-4-3 {
                .bg-img {
                  padding-top: calc(3 / 4 * 100%);
                }
              }
              &.radio-1-1 {
                .bg-img {
                  padding-top: calc(1 / 1 * 100%);
                }
              }
              &.radio-3-2 {
                .bg-img {
                  padding-top: calc(2 / 3 * 100%);
                }
              }
              &.radio-3-4 {
                .bg-img {
                  padding-top: calc(4 / 3 * 100%);
                }
              }
              &.radio-2-3 {
                .bg-img {
                  padding-top: calc(3 / 2 * 100%);
                }
              }
              .bg-img {
                position: relative;
                height: 0;
                & > div {
                  position: absolute;
                  top: 0;
                  right: 0;
                }
                .img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                }
              }
              .content {
                position: relative;
                top: 0;
                left: 0;
                padding: 16px 0 32px 0;
              }
            }
          }
        }
      }
    }
  }
`;
