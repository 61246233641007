import cn from 'classnames';
import React from 'react';
import { jumpToPage } from 'utils/web-tool';
import { DefaultImg } from 'cpn/default-img';
import { getDesignLocalText } from 'utils/i18n';
import NewLink from 'cpn/new-link';
import Image from 'components/base/image';

const dealTextAlign = (str) => {
  const align = str?.split(' ');
  const [horizontal, vertical] = align;
  return `text-${horizontal}-${vertical}`;
};

export const ImageItem = ({ settings }) => {
  const {
    image,
    slide_title: slideTitle,
    subheading,
    compress,
    alignment = 'center center',
    button_label: buttonLabel,
    button_link: buttonLink,
    show_overlay: showOverlay,
    text_alignment: textAlignment = 'center center',
    color_text: colorText = '#fff',
    pic_link: picLink = '',
  } = settings;

  const linkUrl = JSON.parse(buttonLink || null)?.url;
  const alignmentClass = `img-${alignment.split(' ').join('-')}`;
  const alignClass = dealTextAlign(textAlignment);
  const slideTxt = getDesignLocalText(slideTitle);

  return (
    <div
      className={cn('slide-item', alignClass)}
      onClick={() => {
        jumpToPage(linkUrl);
      }}
    >
      <div className='slide-item-img-wrapper'>
        <NewLink href={picLink} target='_self'>
          {image ? (
            <Image
              className={cn('slide-item-img', alignmentClass)}
              src={image}
              compress={compress}
              alt={slideTxt || buttonLabel}
            />
          ) : (
            <DefaultImg customName='slide-item-img' width='auto' height='41%' />
          )}
          {showOverlay ? <div className='slide-img-mask' /> : null}
        </NewLink>
      </div>

      <div className={cn('slide-item-text')} style={{ color: colorText }}>
        <h3 className='slide-item-title'>{slideTxt}</h3>
        <div className='slide-item-desc'>
          <div
            dangerouslySetInnerHTML={{
              __html: getDesignLocalText(subheading) || '',
            }}
          />
        </div>
        {buttonLabel ? (
          <div className='slide-item-btn-wrapper'>
            <NewLink className='slide-item-btn' href={linkUrl} alt={buttonLabel}>
              {buttonLabel}
            </NewLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};
