import { useMemo } from 'react';
import { DEFAULT_COLLECTION_TEMPLATE } from '../default-settings';

export const getSectionData = (theme, type) => {
  if (!theme?.current?.sections) return;
  return theme.current.sections?.[type] || {};
};

export const htmlTranText = (value) => {
  value = value?.replace(/(\n)/g, '');
  value = value?.replace(/(\t)/g, '');
  value = value?.replace(/(\r)/g, '');
  value = value?.replace(/<\/?[^>]*>/g, '');
  // value = value.replace(/\s*/g, "");
  return value;
};

export const useCollectionTheme = ({ theme }) => {
  const collectionTemplateData = getSectionData(theme, 'collection-template');

  const filterSettings = useMemo(() => {
    const { block_order: blockOrder, blocks } = collectionTemplateData;
    const { layout = 'horizontal', settings = {} } = blocks?.[blockOrder?.[0]]?.settings || {};
    return {
      layout,
      ...settings?.[layout],
    };
  }, [collectionTemplateData]);

  const collectionTemplateSettings = useMemo(() => {
    const {
      layout = 'horizontal', // horizontal vertical
      text_display: textDisplay = 'under_pic', // superimposed // under_pic
      settings = DEFAULT_COLLECTION_TEMPLATE?.settings?.settings,
    } = collectionTemplateData?.settings || {};

    const defaultSettings = DEFAULT_COLLECTION_TEMPLATE?.settings?.settings?.[layout];
    const data = {
      ...defaultSettings, // 默认值
      layout,
      text_display: textDisplay,
      ...settings?.[layout],
    };
    // 文本显示图片上
    if (textDisplay === 'superimposed') {
      data.text_position = settings?.[layout]?.text_position || defaultSettings?.text_position;
    }
    // 显示在图片下
    if (textDisplay === 'under_pic') {
      data.align_text = settings?.[layout]?.align_text || defaultSettings?.align_text;
    }
    return data;
  }, [collectionTemplateData]);

  return {
    collectionTemplateSettings,
    filterSettings,
  };
};
