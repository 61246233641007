import {
  UserAccountParams,
  loginByVerifyCodeParams,
  loginByPwdParams,
  resetPwdByMobileParams,
  registerByMobileParams,
  registerByEmailParams,
  bindEmailParams,
  bindMobileParams,
} from './interface';
import userApi from 'api/user';

class UserAccount {
  email: string | undefined;
  mobile: string | undefined;
  password: string | undefined;
  constructor(params: UserAccountParams = {}) {
    this.email = params.email;
    this.mobile = params.mobile;
    this.password = params.password;
  }
  static async loginByVerifyCode(params: loginByVerifyCodeParams) {
    try {
      const result = await userApi.loginByVerifyCode(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async loginByPwd(params: loginByPwdParams) {
    try {
      // return { sid: '123' };
      const result = await userApi.loginByPwd(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async checkMobileOnResetPwd(params: loginByVerifyCodeParams, options) {
    try {
      // return {};
      const result = await userApi.checkMobileOnResetPwd(params, options);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async resetPwdByMobile(params: resetPwdByMobileParams) {
    try {
      // return {};
      const result = await userApi.resetPwdByMobile(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async sendMailOnResetPwd(params: { customerNo: string }, options) {
    try {
      const result = await userApi.sendMailOnResetPwd(params, options);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async checkMobileOnRegister(params: loginByVerifyCodeParams) {
    try {
      // return {};
      const result = await userApi.checkMobileOnRegister(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async checkEmailOnRegister(params: bindEmailParams) {
    try {
      // return {};
      const result = await userApi.checkEmailOnRegister(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async registerByMobile(params: registerByMobileParams) {
    try {
      // return {};
      const result = await userApi.registerByMobile(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async registerByEmail(params: registerByEmailParams) {
    try {
      // return {};
      const result = await userApi.registerByEmail(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async checkEmailOnBind(params: { email: string }) {
    try {
      // return {};
      const result = await userApi.checkEmailOnBind(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async bindEmail(params: bindEmailParams) {
    try {
      // return {};
      const result = await userApi.bindEmailNew(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async checkMobileOnBind(params: { oldMobile: string }) {
    try {
      // return {};
      const result = await userApi.checkMobileOnBind(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  static async bindMobile(params: bindMobileParams) {
    try {
      // return {};
      const result = await userApi.bindMobile(params);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default UserAccount;
