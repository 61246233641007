import { STORE_STATUS, MAIN_DOMAIN_REDIRECT_STATUS, OPEN_MODE } from '../../const';
import shopApi from '../../api/shop';

const initialData = window?.global_data ?? {};
const { shopInfo, websiteAuthInfo } = initialData;

// 是否小程序 webview
const isMiniappWebview = () => {
  return (navigator.userAgent.match(/micromessenger/i) && navigator.userAgent.match(/miniprogram/i)) ||
    window.__wxjs_environment === 'miniprogram' ||
    navigator.userAgent.includes('xhsminiapp');
};

export const storeStatusRedirect = () => {
  const { pathname, search } = window.location;

  if (pathname.startsWith('/order/receipt/') && global_data.h5AuthInfo?.authStatus === 'valid') {
    return false
  }

  if (
    shopInfo &&
    shopInfo.trafficRedirect === MAIN_DOMAIN_REDIRECT_STATUS.OPEN &&
    shopInfo.primaryDomain !== document.domain &&
    window.self === window.top && // 在iframe下面，不要跳走，分销需要
    !isMiniappWebview()
  ) {
    window.location.href = `https://${shopInfo.primaryDomain}${pathname}${search}`; // 补充页面路径和页面参数
    return true;
  }
  // 预览模式不跳转
  const queryParams = (new URL(location.href)).searchParams;
  const mode = queryParams.get('mode');
  if ([OPEN_MODE.PREVIEW_SIMPLE, OPEN_MODE.PREVIEW_NORMAL, OPEN_MODE.EDIT_NORMAL].includes(Number(mode))) {
    return false;
  }
  if (pathname !== '/payment/bogus') {
    if (websiteAuthInfo.authStatus !== 'valid' && !window.location.href.includes('/close')) {
      window.location.href = `https://${window.location.host}/close`;
      return;
    }
    shopApi.checkGray({ bizCode: 'commercial', kdtId: shopInfo?.kdtId }).then((res) => {
      const { isGray } = res;
      if (isGray < 1) return;
      shopApi.getAuthInfoByToken({ kdtId: shopInfo?.kdtId }).then((data) => {
        if (data?.authStatus === STORE_STATUS.CLOSING && window.location.href.indexOf('close') === -1) {
          window.location.href = `https://${window.location.host}/close`;
        }
        if (data?.authStatus !== STORE_STATUS.CLOSING && websiteAuthInfo.authStatus === 'valid' && window.location.pathname === '/close') {
          window.location.href = `https://${window.location.host}`;
        }
      });
    });
  }
};
