import styled from 'styled-components';

export const OptionsStyle = styled.div`
  background-color: var(--color_card, #fff) !important;
  border-radius: 2px 2px 0px 0px;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 8%);
  color: var(--color_body_text, #fff);
  max-height: 200px;
  overflow: scroll;
  .hunt-select-option {
    padding: 0 12px;
    box-sizing: border-box;
    min-height: 36px;
    line-height: 36px;
    cursor: pointer;
    &:hover {
      /* background: rgba(71, 108, 240, 0.1); */
      color: var(--color_button);
    }
    &.active {
      background: rgba(var(--color_body_text_rgb), 0.2);
      color: var(--color_button);
    }
  }
`;

export const Style = styled.div`
  .hunt-select-trigger {
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid var(--color_text_field_border);
    background: ${(props) => (props.disabled ? 'rgba(236, 236, 236, 0.2)' : 'var(--color_text_field)')};
    border-radius: var(--border_radius_form);
    overflow: hidden;
    &.active {
      border-width: 1px;
      border-style: solid;
      border-color: var(--color_button);
    }
    input {
      flex: 1;
      height: 100%;
      line-height: 1;
      border: 0;
      text-indent: 8px;
      cursor: pointer;
      outline: none;
      background: var(--color_text_field);
      color: var(--color_text_field_text);
      &:disabled {
        background: rgba(236, 236, 236, 0.2);
      }
    }
    .icon-clear {
      fill: #c8c9cc;
      cursor: pointer;
    }
  }
  .hunt-select-switch {
    width: 30px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    line-height: 46px;
    text-align: center;
    .icon-switch {
      fill: var(--color_text_field_text, #fff);
    }
  }
  .disabled {
    background: #fafafa;
    cursor: not-allowed;
  }
  .icon-switch.visible {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
  }
`;
