import { useEffect, useState } from 'react';
import goodsApi from 'api/goods';
import shopApi from 'api/shop';
import isEmpty from 'lodash/isEmpty';
// import { fetchGoodsByCollection } from 'helper/collection';
import isObject from 'lodash/isObject';
import { filterEmptyObj } from 'utils';
import { isArray } from 'lodash';
import { WISH_COUNT_CHANGE } from 'utils/local-storage/config';
import LocalStorage from 'utils/local-storage';
import { formatGoodsFieldName } from 'helper/goods';

/**
 *  获取分组数据-用于装修组件
 * */

export const fetchGoodsByCollection = async ({ kdtId, collection }) => {
  const MAX_GOODS_LENGTH = 25;
  const collectionId = isObject(collection) ? collection?.collectionId : collection; // 此处是为了兼容旧数据，最新版本的collection是分组ID

  if (!collectionId) {
    return new Promise((resolve) => {
      resolve([]);
    });
  }
  // 获取排序方式
  const collectionInfo = await goodsApi.getGoodsCollectionDetail({
    collectionId,
    kdtId,
  });
  const { sort = '', sortBy = '' } = collectionInfo || {};
  let params = {
    kdtId,
    currentPage: String(collectionId),
    page: 1,
    pageSize: MAX_GOODS_LENGTH,
    collectionQuery: [], // 分组
    vendorQuery: [], // 供应商
    goodsTagsQuery: [], // 标签
    goodsTypeQuery: [], // 商品类型
  };
  if (sort && sortBy) {
    params = { ...params, sort, sortBy };
  }
  const res = await goodsApi.searchGoodsSmartFilter(params);

  let { simpleGoods = [] } = res;
  simpleGoods = formatGoodsFieldName(simpleGoods);

  return new Promise((resolve) => {
    resolve(simpleGoods);
  });
};
/**
 *  获取推荐商品数据-用于装修组件
 * */

export const getRecommendProducts = async ({ kdtId, pageSize = 12 }) => {
  const res = await goodsApi.getRecommendGoods({
    kdtId,
    pageSize,
    page: 1,
  });
  const { items = [] } = res;
  return new Promise((resolve) => {
    const filterItems = items.filter(item => item.goodsFeatureType !== 'new_service');
    resolve(filterItems);
  });
};
export const getCollectionInitialProps = async ({ kdtId, collection }) => {
  return fetchGoodsByCollection({
    collection,
    kdtId,
  });
};

/**
 *  获取多个商品数据-用于装修组件
 * */
export const fetchBulkGoods = async ({ kdtId, goodsIdList }) => {
  if (!goodsIdList) {
    return new Promise((resolve) => {
      resolve([]);
    });
  }
  const res = await goodsApi.bulkGetGoodsById({
    goodsIdList,
    kdtId,
  });
  return new Promise((resolve) => {
    resolve(res);
  });
};

export const fetchWishData = ({ pageSize = 100, page = 1, customerId }) => {
  return shopApi.searchWishListWithPage(filterEmptyObj({ pageSize, page, customerId }));
};

export const fetchWishCount = async ({ customerId }) => {
  if (!customerId) {
    return new Promise((resolve) => {
      resolve(0);
    });
  }
  const res = await shopApi.getWishCountGoods({ customerId });
  return new Promise((resolve) => {
    resolve(res);
  });
};

/**
 *  获取分组数据-用于装修组件
 *  新增一个type传值;以让该组件适用更多情况
 *  collection：商品分组
 *  recommend：推荐商品
 * */
export const useCollection = ({
  kdtId,
  initData = [],
  collection,
  grid,
  rows = 1,
  type = 'collection',
  needDefault = true,
  defaultGoodData,
  productList = [],
  customerId,
}) => {
  const groupId = isObject(collection) ? collection?.collectionId : collection;
  const goodsIdList = isArray(productList) ? productList.map((item) => item.goodsId) : [];
  /** 下面这一行不需要多语言 */
  const DefaultGoodData = defaultGoodData || {
    title: "Your product's name",
    minPrice: 19.99,
  };
  const [goodsList, setGoodsList] = useState(formatGoodsFieldName(initData));
  const setWishList = (count, list) => {
    const listWithWishList = [...list];
    fetchWishData({ pageSize: count, customerId }).then((res) => {
      listWithWishList.forEach((item) => {
        res.items.forEach((wishGoods) => {
          if (item.goodsId === wishGoods.goodsId) {
            if (!item.wishList) {
              item.wishList = { isWishList: true, wishListId: wishGoods.wishListId };
            }
          }
        });
      });
      setGoodsList(listWithWishList);
    });
  };
  const getGoodsList = async () => {
    const list = await fetchGoodsByCollection({
      collection,
      kdtId,
    });
    setGoodsList(list);
    const count = await fetchWishCount({ customerId });
    if (count) {
      setWishList(count, list);
    }
  };

  const getBulkGoodsList = async () => {
    const list = await fetchBulkGoods({
      goodsIdList,
      kdtId,
    });
    const newList = [];
    goodsIdList.forEach((goodsId) => {
      let newItem = {};
      list.forEach((item) => {
        if (item.goodsId === goodsId) {
          newItem = { ...item };
        }
      });
      newList.push(newItem);
    });
    setGoodsList(newList);
    const count = await fetchWishCount({ customerId });
    if (count) {
      setWishList(count, newList);
    }
  };

  const getRecommendProductList = async () => {
    const list = await getRecommendProducts({
      kdtId,
    });

    setGoodsList(list);
  };
  useEffect(() => {
    // 只考虑指定商品时，商品数量不变的情况，可能是排序，可能是换商品
    if (type === 'product_list' && productList?.length && goodsList?.length === productList?.length) {
      try {
        goodsList.forEach((item, index) => {
          if (item.goodsId !== productList[index].goodsId) {
            throw new Error('goodsChange');
          }
        });
      } catch (err) {
        if (err?.message === 'goodsChange') {
          const newGoodsList = [...goodsList];
          const productIdList = isArray(productList) ? productList.map((item) => item.goodsId) : [];
          let isChange = false;
          goodsList.forEach((good) => {
            if (productIdList.indexOf(good.goodsId) === -1) {
              isChange = true;
            }
          });
          // 如果长度匹配不上，说明换商品了，需要重新请求商品
          if (isChange) {
            getBulkGoodsList();
          } else {
            newGoodsList.sort((a, b) => {
              return productIdList.indexOf(a.goodsId) - productIdList.indexOf(b.goodsId);
            });
            setGoodsList(newGoodsList);
          }
        }
      }
    }
  }, [productList]);
  useEffect(() => {
    if (type === 'recommend') {
      // 如果已经有传递数据，则不用再获取接口
      if (!initData || initData.length <= 0) {
        getRecommendProductList();
      }
    } else if (type === 'product_list') {
      if (goodsIdList && goodsIdList.length) {
        getBulkGoodsList();
      }
    } else if (type === 'collection') {
      if (groupId) {
        getGoodsList();
      }
    }
  }, [groupId, type, goodsIdList.length]);

  useEffect(() => {
    if (type === 'recommend') return;
    const getRenderGoodsList = () => {
      const list = [];
      for (let i = 0; i < grid * rows; i += 1) {
        list.push(DefaultGoodData);
      }
      setGoodsList(list);
    };
    if (!groupId && needDefault && type === 'collection') {
      getRenderGoodsList();
    }
    if (!goodsIdList.length && needDefault && type === 'product_list') {
      getRenderGoodsList();
    }
  }, [groupId, type, grid, rows, needDefault, goodsIdList.length]);

  return {
    goodsList,
  };
};
