import styled from 'styled-components';

export const PolicyStyle = styled.div`
  padding: 13px 16px;
  background-color: rgba(var(--color_body_text_rgb), 0.04);
  border-radius: var(--border_radius_other);
  .policy-item:not(:last-child) {
    padding-bottom: 12px;
  }
`;

export const PolicyDialogStyle = styled.div`
  min-height: 150px;
  max-height: 390px;
  overflow: auto;
  .policy-wrapper {
    width: 560px;
    max-width: 100%;
  }
  .view-detail {
    color: #304ffe;
    margin-top: 16px;
    padding-bottom: 24px;
    cursor: pointer;
  }
  @media screen and (max-width: 750px) {
    min-height: 70px;
    .policy-wrapper {
      min-height: 68px;
    }
  }
`;
