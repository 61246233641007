import { PackageCodeSorted, PackageCode } from 'const/shop';
/**
 * 是否大于某个版本-注意 不包含当前版本
 * @param current 当前店铺版本
 * @param target 目前版本
 */
const isOverPackage = (current, target) => {
  const currentIndex = PackageCodeSorted.indexOf(current); // 当前版本的索引
  const targetIndex = PackageCodeSorted.indexOf(target); // 目标版本的索引
  if (currentIndex === -1 || targetIndex === -1) {
    console.error('无法获取到当前店铺的商业化版本，请检查currentIndex/targetIndex');
    return false;
  }
  return currentIndex > targetIndex;
};

/** 是否大于基础版 */
export const getIsOverBasic = (packageCode) => {
  return isOverPackage(packageCode, PackageCode.BASIC);
};

/** 是否大于专业版 */
export const getIsOverStandard = (packageCode) => {
  return isOverPackage(packageCode, PackageCode.STANDARD);
};

/** 是否大于高级版本 */
export const getIsOverPremium = (packageCode) => {
  return isOverPackage(packageCode, PackageCode.PREMIUM);
};

/** 是否大于旗舰版 */
export const getIsOverUltimate = (packageCode) => {
  return isOverPackage(packageCode, PackageCode.ULTIMATE);
};
