import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;
  background: var(--color_image_overlay);
  opacity: var(--image_overlay_opacity);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
  z-index: 9;
`;
