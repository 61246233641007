import styled from 'styled-components';

export const Style = styled.div`
  .form-title {
    display: flex;
    padding: 3px 0;
    font-size: var(--form_name_size);
    color: var(--form_title_color);
    zoom: var(--form_title_scale);
    &.require::before {
      color: #d40000;
      color: var(--theme-error-2, #d40000);
      content: '*';
      display: inline-block;
      font-size: 16px;
      margin-right: 6px;
    }
    &.has-require {
      margin-left: 14px;
    }
  }
  .form-has-require {
    padding-left: 12px;
    .form-desc {
      padding-left: 18px;
      font-size: 12px;
      padding: 2px 0;
      color: rgba(var(--form_title_color_rgb), 0.6);
    }
  }

  .form-content {
    padding-bottom: 20px;
    .zent-form-control {
      margin-top: 16px;
      padding-bottom: 0;
      .zent-form-control-content {
        margin-left: 0;
      }
    }
    .zent-input-wrapper {
      width: 100%;
      border-color: var(--form_border_color) !important;
      .zent-input,
      .zent-textarea {
        background-color: var(--form_bg_color) !important;
        color: var(--form_title_color) !important;
        &::placeholder {
          color: rgba(var(--form_title_color_rgb), 0.6);
        }
      }
    }
  }
`;
