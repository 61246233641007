import styled from 'styled-components';

export const Style = styled.div`
  .other {
    margin-top: ${(props) => props.theme.getSpace([40, 46])};
  }
  .goods-description-title {
    color: var(--color_body_text);
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .service-wrapper {
    margin-top: 0;
    svg {
      fill: var(--color_body_text);
    }
  }
  .remove-border {
    border: none;
  }
  @media screen and (max-width: 750px) {
    .service-wrapper {
      margin-top: 0;
    }
  }
`;
