import React, { useState } from 'react';
import HtDialog from '../../standard/dialog';
import ForgetPasswordDialogBody from './components/forget-password-dialog-body';
import HtIcon from '../../base/icon/index';
import { STEP_ONE } from './const';
import { useIntl } from 'react-intl';

function ForgetPasswordDialog(props) {
  const intl = useIntl();
  const { visible, onClose, setLoginDialogInfo, withMobileLogin } = props;
  const [step, setStep] = useState(STEP_ONE);

  return (
    <HtDialog
      visible={visible}
      onClose={onClose}
      title={intl.formatMessage({ id: 'message_tpl.custom_text.forget_password', defaultMessage: '忘记密码' })}
      titleIcon={
        step > STEP_ONE ? <HtIcon name='iconshangyige' size='small' onClick={() => setStep(STEP_ONE)} /> : null
      }
      maskClosable={false}
    >
      <ForgetPasswordDialogBody
        step={step}
        setStep={setStep}
        intl={intl}
        setLoginDialogInfo={setLoginDialogInfo}
        onClose={onClose}
        withMobileLogin={withMobileLogin}
      />
    </HtDialog>
  );
}

export default ForgetPasswordDialog;
