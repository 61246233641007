import { useState, useEffect } from 'react';
import userApi from '../../api/user';
import { Notify } from 'zent';
// eslint-disable-next-line import/no-default-export
import { LOGIN_CHANNELS } from '../../const';
import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from '../sdk/load-third-javascript';

export default function useCountrySelect(props = {}) {
  const [userInfo, setUserInfo] = useState([]);
  const { onLoginSuccess, intl } = props;
  const fbInit = (faceBookInfo) => {
    const { FB } = window;
    /**
     * facebook message 会实例化FB，当FB已经存在时则直接调用FB.init，不要再加载登陆相关的sdk，不然调用facebook login会报错。
     * https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin/sdk/ 官方文档说明：
     * xfbml.customerchat.js包含了Facebook Javascript SDK以及客户聊天SDK的所有功能
     */
    const fbLoginInitParams = {
      appId: faceBookInfo?.appKey,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v9.0',
    };
    if (FB) return FB.init(fbLoginInitParams);
    loadThirdJavascript(
      THIRD_JAVASCRIPT_CONFIG.FACEBOOK_LOGIN.name,
      THIRD_JAVASCRIPT_CONFIG.FACEBOOK_LOGIN.url,
      true,
    ).then(() => {
      window.fbAsyncInit = () => {
        const sdkFB = window?.FB;
        if (sdkFB) {
          sdkFB.init(fbLoginInitParams);
        }
      };
    });
  };
  const getUserInfo = () => {
    FB.api('/me?fields=name,first_name,last_name,email', function (response) {
      const params = {
        channelAccountId: response?.id,
        email: response?.email,
        firstName: response.first_name,
        lastName: response.last_name,
      };
      setUserInfo(params);
      userApi
        .fblogin(params)
        .then(() => {
          Notify.success(
            intl.formatMessage({
              id: 'account.login_success',
              defaultMessage: '登录成功',
            }),
          );
          onLoginSuccess();
        })
        .catch((err) => {
          Notify.error(err.message);
        });
    });
  };
  const onFbLogin = () => {
    FB.login(
      function (response) {
        if (response.status === 'connected') {
          getUserInfo();
        } else {
          Notify.error(
            intl.formatMessage({
              id: 'login.account.fail',
              defaultMessage: '登录失败',
            }),
          );
        }
      },
      { scope: 'public_profile,email' },
    );
  };
  // 获取地址
  useEffect(() => {
    userApi.getLoginChannels().then((data) => {
      const faceBookInfo = data.find((item) => item.channel === LOGIN_CHANNELS.facebook);
      !!faceBookInfo && fbInit(faceBookInfo);
    });
  }, []);

  return {
    onFbLogin,
    userInfo,
  };
}
