import styled from 'styled-components';

export const Style = styled.div`
  .recommend-product {
    .grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: ${(props) => props.theme.getSpace(props.theme.hCards)};
      grid-column-gap: ${(props) => props.theme.getSpace(props.theme.hGoods)};
    }
    &.is-mobile {
      .grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
`;
