// 对应装修文本-图文
import React from 'react';
import cn from 'classnames';
import { Mask } from 'cpn/mask';
import NewLink from 'cpn/new-link';
import { Style } from './style';
import { DefaultImg } from 'cpn/default-img';
import { Wrapper } from 'components/base';
import LinkButton from '../../snippets/link-button';
import { useThemeContext } from '../../../../hook/global-theme';
import Image from 'components/base/image';
import { Background } from '../../components/background';
import fullfillImage from 'utils/fullfillImage';

const LayoutMap = {
  left: 'align-left',
  right: 'align-right',
};

// 图文
const FeaturedRow = ({ settings = {}, elemClass }) => {
  const {
    title,
    text,
    image,
    layout,
    bg_txt: bgText,
    with_cover: withCover,
    button_label: buttonLabel,
    button_link: buttonLink,
    container_width: containerWidth = '80',
    image_ratio: imageRatio = 'auto',
    button_label_align: buttonLabelAlign = 'center',
    link_type: linkType = 'button',
    image_title: imageTitle = '',
    pic_link: picLink = '',
    background_style: backgroundStyle = 'custom_color',
    background_color: backgroundColor = 'rgba(250, 250, 250, 0)',
    background_image: backgroundImage,
    compress = true,
  } = settings;
  const Bg = Background(backgroundStyle);
  const themeContext = useThemeContext();

  const linkUrl = JSON.parse(buttonLink || null)?.url;
  const classMap = {
    80: 'cn-margin_80',
    960: 'cn-fix_960',
    1280: 'cn-fix_1280',
    full: 'cn-full_screen',
  };
  const hasMargin = !(containerWidth === 'full');

  return (
    <Bg
      backgroundColor={backgroundColor}
      backgroundImage={fullfillImage(backgroundImage, 'origin', {
        toWebp: true,
        compress,
      })}
      style={{ overflow: 'hidden' }}
    >
      <Style className={cn('featured-row-section', elemClass)}>
        <Wrapper marginTop={hasMargin ? 'vContainer' : 0} marginBottom={hasMargin ? 'vContainer' : 0}>
          <div
            className={cn('featured-row', LayoutMap[layout], {
              [classMap[containerWidth]]: !themeContext.isMobile,
            })}
          >
            {image ? (
              <div className={cn('featured-img-wrap', `image-ratio-${imageRatio}`)}>
                <NewLink href={picLink} target='_self'>
                  {withCover ? <Mask /> : null}
                  <Image className='featured-row-img' src={image} alt={imageTitle} compress={compress} />
                  {imageTitle && <div className='image-title'> {imageTitle}</div>}
                </NewLink>
              </div>
            ) : (
              <div className={cn('featured-row-default', `image-ratio-${imageRatio}`)}>
                <NewLink href={picLink} target='_self'>
                  {withCover ? <Mask /> : null}
                  <DefaultImg square height='41%' width='auto' />
                  {imageTitle && <div className='image-title'> {imageTitle}</div>}
                </NewLink>
              </div>
            )}
            <div
              style={{
                background: bgText,
              }}
              className={cn('featured-row-text', `featured-text-${buttonLabelAlign}`)}
            >
              <div>
                <h3 className='featured-row-title'>{title}</h3>
                <div
                  className='featured-row-desc'
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              </div>
              {buttonLabel && (
                <LinkButton linkStyle={linkType} linkText={buttonLabel} path={linkUrl} className='button-link' />
              )}
              {/* {buttonLabel ? (
                        <a className={cn('button-link', `link-type-${linkType}`)} href={linkUrl}>
                            {buttonLabel}
                        </a>
                    ) : null} */}
            </div>
          </div>
        </Wrapper>
      </Style>
    </Bg>
  );
};

export default FeaturedRow;
