import styled from 'styled-components';

export const Style = styled.div`
  .scrollup-icon {
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px ${props.borderRadius}px 0 0  ` : 0)};
  }
  .scrolldown-icon {
    border-radius: ${(props) => (props.borderRadius ? `0 0 ${props.borderRadius}px ${props.borderRadius}px` : 0)};
  }
  .swiper-container {
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : 0)};
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  height: ${(props) => `${props.height}px`};
  display: grid;

  /* grid-template-rows: 0.2fr 1fr 0.5fr 0.5fr; */
  /* grid-template-columns: repeat(3, 33.33%); */
  padding: ${(props) => (props.showMargin ? '16px' : 0)};
  overflow: auto;
  /* grid-template-columns: 16.7% 54.2% 29.1%; */
  grid-template-columns: 0.167fr 0.542fr 0.291fr;
  /* grid-template-rows: ${(props) => `${props.height / 2}px`} ${(props) => `${props.height / 2}px`}; */
  grid-template-areas:
    'custom-menu carousel single-module-1'
    'custom-menu carousel single-module-2';
  grid-template-rows: ${(props) => (props.showModuleSpacing ? 'calc(50% - 8px) calc(50% - 8px)' : '50% 50%')};
  text-align: center;
  grid-gap: ${(props) => (props.showModuleSpacing ? '16px' : 0)};

  @media screen and (max-width: 800px) {
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: ${(props) => `auto ${(props.height * 269.23) / 394}px  ${(props.height * 125) / 394}px`};
    height: initial;
    grid-template-areas:
      'custom-menu custom-menu'
      'carousel carousel'
      'single-module-1 single-module-2';
    text-align: center;
    grid-gap: ${(props) => (props.showModuleSpacing ? '8px' : 0)};
  }
`;
