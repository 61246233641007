import styled, { css } from 'styled-components';

// 移动设备菜单样式 展开折叠
const getMobileDeviceMenuStyleCSS = (props) => {
  if (props.mobileDeviceMenuStyle === 'fold') {
    return css`
      padding-top: 16px;
      padding-bottom: 16px;
      .footer-menu-collapse-content-box {
        padding-bottom: 4px !important;
        .footer-custom-menu-section-item {
          padding-bottom: 12px;
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    `;
  }
};

const getMobileDeviceFooterTextAlignCSS = (props) => {
  const { mobileDeviceFooterTextAlign, mobileDeviceMenuStyle, mobileDisplayTwoInOneLine } = props;
  // 折叠 和 两行显示左对齐
  let newMobileDeviceFooterTextAlign = 'left';

  if (mobileDisplayTwoInOneLine || mobileDeviceMenuStyle === 'fold') {
    newMobileDeviceFooterTextAlign = 'left';
  } else {
    newMobileDeviceFooterTextAlign = mobileDeviceFooterTextAlign;
  }

  if (newMobileDeviceFooterTextAlign === 'left') {
    return css`
      text-align: left;
      .footer-menu-collapse .footer-menu-collapse-title {
        justify-content: space-between;
      }
    `;
  }
  if (newMobileDeviceFooterTextAlign === 'right') {
    return css`
      text-align: right;
      .footer-menu-collapse .footer-menu-collapse-title {
        justify-content: flex-end;
      }
    `;
  }
  if (newMobileDeviceFooterTextAlign === 'center') {
    return css`
      text-align: center;
      .footer-menu-collapse .footer-menu-collapse-title {
        justify-content: center;
      }
    `;
  }
};

export const Style = styled.div`
  .footer-custom-menu-section {
    &-title-link {
      font-weight: var(--font-weight-header);
      font-size: ${(props) => props.theme.getHeaderFontSize([14, 16])};
      min-height: 20px;
    }
    &-item {
      font-size: ${(props) => props.theme.getHeaderFontSize([12, 14])};
      padding-bottom: 8px;
      &:last-child {
        padding-bottom: 0;
      }
      &-link {
        opacity: 0.6;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .footer-custom-menu-section {
      /* 对齐方式CSS */
      ${(props) => getMobileDeviceFooterTextAlignCSS(props)}

      /* 展开折叠CSS */
            ${(props) => getMobileDeviceMenuStyleCSS(props)}
           
            &-item {
        padding-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
