import cn from 'classnames';
import { Style } from './style';
import { Icon } from 'components/base';
import { ThirdMenuGroup, SecondMenuGroup } from '../mobile-menu';
import NewLink from 'cpn/new-link';
import { parseSettings } from '../../utils';

function MobileMenuContent(props) {
  const {
    showMenu,
    setShowMenu,
    blocks = {},
    blockOrder = [],
    getFirstMenuTitle,
    handleSecondMenuItemClick,
    handleFirstMenuItemClick,
    activeMap,
    activeFirstMenuId,
    menuContentTop,
    textColor,
    cardColor,
    submenuTextColor,
    submenuCardColor,
    intl,
  } = props;

  const renderOldBlockItem = (blockItem, blockId) => {
    const menuItemInfo = parseSettings(blockItem.settings);
    return (
      <div className='menu-first' key={blockId}>
        <div className='menu-first-item'>
          <div className='menu-first-item-title'>
            <NewLink href={menuItemInfo.path} target={menuItemInfo.openTarget}>
              {menuItemInfo.title}
            </NewLink>
          </div>
        </div>
      </div>
    );
  };
  const renderNewBlockItem = (blockItem, blockId) => {
    const { block_order_two: blockOrderTwo = [], blocks_two: blocksTwo = {} } = blockItem;
    const firstMenuTitle = getFirstMenuTitle(blockItem);
    return (
      <div className='menu-first' key={blockId}>
        <div className='menu-first-item' onClick={() => handleFirstMenuItemClick(blockId)}>
          <div className='menu-first-item-title'>{firstMenuTitle}</div>
          {blockOrderTwo?.length > 0 && (
            <Icon
              className={cn('menu-icon', {
                rotate: activeFirstMenuId === blockId,
              })}
              color={textColor}
              name='iconic-xiala'
              size='medium'
            ></Icon>
          )}
        </div>
        <div
          className={cn('menu-first-item-content', {
            active: activeFirstMenuId === blockId,
          })}
        >
          {blockOrderTwo?.map((blockTwoId) => {
            const blockTwoItem = blocksTwo[blockTwoId];
            if (!blockTwoItem) return null;
            const { type, settings, blocks, block_order: blockOrder } = blockTwoItem;
            return type === 'second_menu_group' ? (
              <SecondMenuGroup
                key={blockTwoId}
                {...blockTwoItem}
                activeMap={activeMap}
                submenuTextColor={submenuTextColor}
                handleSecondMenuItemClick={handleSecondMenuItemClick}
              />
            ) : (
              <ThirdMenuGroup
                key={blockTwoId}
                blockTwoId={blockTwoId}
                {...blockTwoItem}
                activeMap={activeMap}
                submenuTextColor={submenuTextColor}
                handleSecondMenuItemClick={handleSecondMenuItemClick}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Style
      textColor={textColor}
      cardColor={cardColor}
      submenuCardColor={submenuCardColor}
      submenuTextColor={submenuTextColor}
      menuContentTop={menuContentTop}
      className={cn({
        active: showMenu,
      })}
      onTouchMove={(e) => {
        e.preventDefault();
      }}
    >
      <div className='menu-nav' onClick={() => setShowMenu(false)}>
        <Icon size='medium' name='iconic-zuoyouqiehuan' />
        <span>
          {intl.formatMessage({
            id: 'general.return',
            defaultMessage: '返回',
          })}
          /
        </span>
        <span className='menu-nav-name'>
          {intl.formatMessage({
            id: '6ac06c82ac974d7f8b30c165c51192b8',
            defaultMessage: '类目',
          })}
        </span>
      </div>
      <div className='menu-container'>
        {blockOrder.map((blockId) => {
          const blockItem = blocks[blockId];
          if (!blockItem) return null;
          const isOldData = !blockItem.blocks;

          return isOldData ? renderOldBlockItem(blockItem, blockId) : renderNewBlockItem(blockItem, blockId);
        })}
      </div>
    </Style>
  );
}

export default MobileMenuContent;
