import { Message } from 'utils/postmessage';
import { MessageType } from 'const';
import { useEffect } from 'react';

export const useDisableJs = () => {
  // 设置禁止js交互 在预览模式和装修模式下使用
  const disableJsFun = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const disableJsFunMessage = (data, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case MessageType.DISABLEJS:
        window.addEventListener('click', disableJsFun, true);
        break;
    }
  };
  useEffect(() => {
    const message = new Message(null, disableJsFunMessage);

    // /** 主动取数据 */
    return () => {
      message.removeListenMessage();
    };
  }, []);
};
