import React, { useState } from 'react';
import Style from './style';
import { Popover } from 'zent';
import cn from 'classnames';
import { useIntl } from 'react-intl';

const ThirdBtnList = ({ thirdPlatformBtnList }) => {
  const isH5 = window.innerWidth < 450;
  const [visible, setVisible] = useState(false);
  const intl = useIntl();
  const onMaskClose = () => {
    if (isH5) {
      setVisible(false);
    }
  };
  const defaultImgUrl = 'https://img01.yzcdn.cn/upload_files/2022/02/08/Fr9uK7IvS-pXib4tK08v3THE19Hc.png';

  // 跳转第三方平台
  const onVisitThirdLink = (url) => {
    const redirectUrl = url.indexOf('http') > -1 ? url : `http://${url}`;
    window.open(redirectUrl);
  };

  const ElementTrigger = isH5 ? Popover.Trigger.Base : Popover.Trigger.Hover;

  return (
    <Style>
      {thirdPlatformBtnList?.length > 0 && (
        <div className='more-third-btn-container'>
          <Popover
            className='zent-doc-popover'
            visible={visible}
            onVisibleChange={(v) => setVisible(v)}
            position={Popover.Position.TopLeft}
            display='inline'
            cushion={5}
          >
            <ElementTrigger autoClose>
              <div
                className={cn('third-btn-entry', { 'is-h5': isH5 }, { 'is-pc': !isH5 })}
                onClick={() => {
                  setVisible(true);
                }}
              >
                <span>
                  {intl.formatMessage({
                    defaultMessage: '更多购买渠道',
                    id: '326b130f92da4b779631c844753ac297',
                  })}
                </span>
                <svg width={16} height={16} className={cn('entry-toggle-icon', { 'is-expand': visible })}>
                  <use xlinkHref='#iconic-xiala1' />
                </svg>
              </div>
            </ElementTrigger>
            <Popover.Content>
              <Style>
                <div className={isH5 ? 'mask-container' : 'mask'} onClick={onMaskClose}>
                  <div className='third-btn-container'>
                    {isH5 && (
                      <div className='third-btn-header'>
                        <span className='title'>
                          {intl.formatMessage({
                            defaultMessage: '购买渠道',
                            id: '4c729d71edf54ef88a348d8a2f141c17',
                          })}
                        </span>
                        <svg width={24} height={24} color='#15161B' onClick={onMaskClose}>
                          <use xlinkHref='#iconic-shanchu' />
                        </svg>
                      </div>
                    )}
                    {thirdPlatformBtnList.map((item) => {
                      return (
                        <div
                          className='third-btn-item'
                          onClick={() => {
                            onVisitThirdLink(item?.url);
                          }}
                          key={item?.buttonName + item?.bImgUrl}
                        >
                          <img src={item?.bImgUrl || defaultImgUrl} className='third-btn-logo' />
                          <span className='third-btn-name'>{item?.buttonName}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Style>
            </Popover.Content>
          </Popover>
        </div>
      )}
    </Style>
  );
};

export default ThirdBtnList;
