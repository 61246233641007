import styled from 'styled-components';
import { getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  .quote {
    width: 100%;
    margin: auto;
    position: relative;
    box-sizing: border-box;
  }

  .hide-navigation {
    .swiper-pagination {
      display: none;
    }
  }

  .quote-content-title {
    text-align: center;
    /* color: #1a1a1b; */
    line-height: 32px;
    font-size: var(--base-header-font-26-20);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
  }

  .quote-blocks-symbol {
    /* font-size: var(--base-font-24-18); */
    ${(props) => props.theme.getBaseSize([18, 24])}
    margin-bottom: ${(props) => props.theme.getSpace([8, 16])};
    svg {
      fill: var(--color_body_text);
    }
  }

  .quote-blocks-item {
    text-align: center;
    min-width: 33.33%;
    margin-top: ${(props) => props.theme.getSpace([32, 45])};
    &:first-child {
      margin-top: 0;
    }
  }

  .quote-blocks-desc {
    ${(props) => props.theme.getBaseSize([12, 16])};
    color: var(--color_text);
  }

  .quote-blocks-author {
    ${(props) => props.theme.getBaseSize([12, 14])}
    color: #646566;
    margin-top: ${(props) => props.theme.getSpace([8, 16])};
  }
  .show-navigation {
    .swiper-container {
      padding-bottom: 32px !important;
    }
    .swiper-pagination {
      bottom: 0 !important;
    }
  }
  .swiper-container {
    width: 100%;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    background: var(--color_button);
    opacity: 0.2;
    .swiper-pagination-clickable & {
      cursor: pointer;
    }
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--color_button);
  }
`;
