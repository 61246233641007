import { useState, useEffect } from 'react';
import memberApi from 'api/member';
import { filterEmptyObj } from 'utils';
/**
 * 会员计划信息
 * @param param0
 */
export const useMemberPlan = ({ kdtId }) => {
  const [memberPlan, setMemberPlan] = useState({});
  useEffect(() => {
    if (!kdtId) return;
    memberApi
      .getMemberPlan({
        kdtId,
      })
      .then((res) => {
        setMemberPlan(res || {});
      });
  }, [kdtId]);

  return {
    memberPlan,
  };
};

/** 获取会员状态 */
export const useMemberStatus = ({ customerId, kdtId }) => {
  const [memberStatus, setMemberStatus] = useState({});
  useEffect(() => {
    if (!kdtId) return;
    memberApi
      .getMemberStatus(
        filterEmptyObj({
          kdtId,
          customerId,
        }),
      )
      .then((res) => {
        setMemberStatus(res);
      });
  }, [kdtId, customerId]);
  return {
    memberStatus,
  };
};
