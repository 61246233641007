export enum MemberStatus {
  None = 'notOpen', // 未开通
  Done = 'hadOpen', // 已付费
  Test = 'trial', // 适用中
  Expire = 'expired', // 已过期
  Forbid = 'forbid',
}

export enum OrderStatus {
  Auditing = 'auditing',
}
