import React, { useEffect, useState, useContext, useRef } from 'react';
import { DefaultImg } from 'cpn/default-img';
import PreviewVideo from '../preview-video';
import PhotoLoupe from '../photo-loupe';
import { getImageInfo } from 'utils/image';
import { ContextGoodDetail } from '../../../context/context-good-detail';
import { Style } from './style';
import Image from 'components/base/image';

const MAX_IMAGE_HEIGHT = 630;
const DEFAULT_IMAGE_WIDTH = 460;

const MainMedia = ({ coverUrl, handle, mediaId, url, type, isZoom = false }) => {
  const ref = useRef(); // 本身ref
  const { imageRatio } = useContext(ContextGoodDetail);
  const [photoLoupeHeight, setPhotoLoupeHeight] = useState();
  const [imageInfo, setImageInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [defaultImageWidth, setDefaultImageWidth] = useState(DEFAULT_IMAGE_WIDTH);
  const setPhotoLoupeHeightFun = (info = imageInfo) => {
    if (imageRatio === 'auto' || imageRatio === 'adapt') {
      if (info) {
        const { width, height } = info;
        const percent = width / height;
        setPhotoLoupeHeight(defaultImageWidth / percent || MAX_IMAGE_HEIGHT);
      } else {
        setPhotoLoupeHeight('auto');
      }
    } else {
      const temp = imageRatio.split('/');
      setPhotoLoupeHeight((defaultImageWidth * temp[1]) / temp[0] || MAX_IMAGE_HEIGHT);
    }
  };
  const handleImageChange = async () => {
    const mediaUrl = type === 'VIDEO' ? coverUrl : url;
    if (!mediaUrl) return;
    setLoading(true);
    const info = await getImageInfo(mediaUrl);
    setImageInfo(info);
    setLoading(false);
  };

  useEffect(() => {
    setPhotoLoupeHeightFun();
  }, [imageRatio, imageInfo, defaultImageWidth]);
  useEffect(() => {
    handleImageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, coverUrl]);

  useEffect(() => {
    const getScreenWidth = () => {
      const screenWidth = ref.current.getBoundingClientRect().width;
      setDefaultImageWidth(screenWidth < DEFAULT_IMAGE_WIDTH ? screenWidth : DEFAULT_IMAGE_WIDTH);
    };
    if (ref.current) {
      getScreenWidth();
    }
    window.addEventListener('resize', getScreenWidth);

    return () => {
      window.removeEventListener('resize', getScreenWidth);
    };
  }, [ref.current]);

  if (type !== 'VIDEO' && !photoLoupeHeight) {
    return <DefaultImg square height='41%' width='auto' />;
  }
  return (
    <Style>
      <div ref={ref} className='main-media' data-height={`${photoLoupeHeight}`} style={{ height: photoLoupeHeight }}>
        {type === 'VIDEO' && <PreviewVideo mediaId={mediaId} coverUrl={coverUrl} handle={handle} />}
        {type !== 'VIDEO' && (
          <>
            {url ? (
              <>
                {isZoom && !loading ? (
                  <PhotoLoupe imgUrl={url} height={photoLoupeHeight} position='right' handle={handle} />
                ) : (
                  <Image
                    style={{ height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                    src={url}
                    alt={handle}
                  />
                )}
              </>
            ) : (
              <DefaultImg square height='41%' width='auto' />
            )}
          </>
        )}
      </div>
    </Style>
  );
};

export default MainMedia;
