import styled from 'styled-components';
import { getPercentWidth } from '../../style/fn';

export default styled.div`
  // 装修外层不能加overflow：hidden,不然有滚动效果的组件会失效
  // overflow: hidden;
  flex: 1;
  .custom-page img {
    max-width: 100%;
    height: auto !important;
  }

  .custom-page-title {
    font-size: var(--base-header-font-24-18);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    color: #1a1a1b;
    line-height: 32px;
    text-align: center;
  }
  .custom-page-content {
    * {
      font-family: var(--font-stack-body) !important;
      font-style: var(--font-style-body) !important;
      font-weight: var(--font-weight-body) !important;
    }
  }
  @media screen and (min-width: 768px) {
    .custom-page {
      margin: 60px auto;
      width: ${getPercentWidth(200)};
    }

    .custom-page-content {
      margin-top: 40px;
      img {
        vertical-align: top;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .custom-page {
      margin: 40px auto;
      width: ${getPercentWidth(24)};
      span {
        font-size: 100% !important;
      }
    }

    .custom-page-content {
      margin-top: 24px;
    }
  }

  @media screen and (min-width: 768px) {
    .form-page {
      margin: 60px auto;
      background: var(--form_card_color);
      width: ${getPercentWidth(200)};
    }

    .dialog-limit-text {
      color: #323233;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 54px;
      margin: 8px 0px 54px 0px;
    }

    .dialog-limit-footer {
      text-align: right;
      padding-top: 16px;
      border-top: 1px solid #ebedf0;
    }
    .submit-btn-warp {
      padding: 0 12px;
      text-align: center;
      padding-bottom: 40px;
      .zent-btn-primary {
        width: 319px;
      }
    }

    .form-page-content {
      margin-top: 40px;
      img {
        vertical-align: top;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .form-page {
      margin: 16px auto;
      background: var(--form_card_color);
      width: ${getPercentWidth(24)};
      span {
        font-size: 100% !important;
      }
    }

    .submit-btn-warp {
      padding: 0 12px;
      text-align: center;
      padding-bottom: 32px;
      .zent-btn-primary {
        width: 100%;
      }
    }

    .form-page-content {
      margin-top: 24px;
    }
  }
  background: var(--form_page_bg_color);
  .zent-form-horizontal {
    .slide-show {
      border-bottom: 24px solid var(--form_page_bg_color);
    }
  }

  .empty-form {
    height: 100%;
    display: flex;
    min-height: 372px;
    align-items: center;
    justify-content: center;
    background: var(--form_page_bg_color);
    .empty-form-container {
      text-align: center;
    }
    .empty-form-icon {
      width: 80px;
      height: 80px;
      fill: #b9bfc9;
      display: inline-block;
    }
    .tip-text {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: rgba(var(--color_body_text_rgb), 0.4);
    }
  }
`;
