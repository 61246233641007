// 装修共用-轮播组件
// 可配置项：
// 轮播的内容数组：contents={[
//     {
//         type: 'img',
//         src:
//             'https://img01.yzcdn.cn/upload_files/2021/06/29/FvO7GrZpBKJEz48Q9awuqPY3okRz.jpeg?imgId=9425404',
//         position: 'center center',
//     },
//     {
//         type: 'video',
//         src:
//             'https://mps-video-test.yzcdn.cn/multi_trans/Qg0Ocg2IAgMu-07nVPYXxmFjwc5X3WO1.mp4.f20.mp4?sign=7ab2e0d31210c23dd4b032cd8d457e34&t=60e7e621',
//         autoPlay: false,
//     },
//     { type: 'dom', dom: <div>dom元素萝卜</div> },
// ]}
// 默认展示项：currentIndex={0}
// 是否自动播放：autoPlay={false}
// 轮播速度：autoPlaySpeed={3}
// 容器宽度：width={'100%'}
// 容器高度：height={'400px'}
// 分页器类型：paginatorStyle={'dots'}，具体见组件swiper-indicator-arrow
// effect  轮播的效果，默认效果；淡入淡出效果fade；其他效果见swiper/react组件
import React, { useRef, useState, forwardRef } from 'react';
import Style from './style';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
// 淡入淡出效果
import SwiperCore, { EffectFade } from 'swiper';
import 'swiper/components/effect-fade/effect-fade.scss';
import SwiperIndicator from '../swiper-indicator';
import BackgroundImage from '../background-image';
import VideoContainer from '../video-container';
import fullfillImage from 'utils/fullfillImage';

SwiperCore.use([EffectFade]);
const SwiperContainer = forwardRef((props, ref) => {
  const {
    contents, // 轮播内容的类型：图片img，视频video，dom
    currentIndex: index = 0,
    autoPlay = true,
    autoPlaySpeed,
    width,
    height,
    paginatorStyle,
    className,
    effect,
    loop,
    direction = 'horizontal',
    compress,
    ...extra
  } = props;
  if (!contents || contents.length < 1) {
    return '';
  }
  const [currentIndex, setCurrentIndex] = useState(index);
  const proxyRef = useRef();
  proxyRef.current = { currentIndex };
  const swiperRef = ref || useRef();

  const getContent = (item) => {
    const image = fullfillImage(item.src, 'origin', { toWebp: true, compress });
    return item.type === 'img' ? (
      <BackgroundImage fit='cover' position={item.position} url={image} />
    ) : item.type === 'video' ? (
      <VideoContainer {...item} autoPlay={false} />
    ) : (
      item.dom
    );
  };

  return (
    <Style className={className} style={{ width, height }}>
      {/* // 只有一个内容的情况如果不用swiper，那外部关于swiper的操作将会bug，自己给自己挖的深坑！！！ */}
      <div className={cn('swiper-container')} style={{ width, height }}>
        <Swiper
          {...extra}
          direction={direction}
          loop={loop || false}
          effect={effect}
          style={{ width, height }}
          autoplay={autoPlay && { delay: autoPlaySpeed * 1000 }}
          ref={swiperRef}
          noSwiping
          pagination={false}
          observer
          observeParents
          onSlideChange={(swiper) => {
            // 如果上一个是视频，则要暂停视频
            if (contents[proxyRef.current.currentIndex]?.ref?.current?.pause) {
              contents[proxyRef.current.currentIndex].ref.current.pause();
            }
            const { realIndex } = swiper;
            // 如果这个是视频，则要播放视频
            if (contents[realIndex]?.ref?.current?.play) {
              contents[realIndex].ref.current.play();
            }
            setCurrentIndex(realIndex);
            // 异步函数无法拿到最新值，所以通过proxyRef转发一个
            proxyRef.current.currentIndex = realIndex;

            props.onSlideChange && props.onSlideChange(swiper);
          }}
        >
          {contents.map((item, i) => {
            return (
              <SwiperSlide
                style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                key={`${`${i}`}_${item.mediaId}_${item.src}`}
              >
                {getContent(item)}
              </SwiperSlide>
            );
          })}
        </Swiper>

        {paginatorStyle && contents.length > 1 && (
          <SwiperIndicator type={paginatorStyle} total={contents.length} currentIndex={currentIndex} />
        )}
      </div>
    </Style>
  );
});

export default SwiperContainer;
