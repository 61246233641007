import React, { useEffect, useState, useMemo } from 'react';
import cn from 'classnames';
import { Text } from 'components/base';
import Style from './style';

const AnnouncementBar = (props) => {
  const { settings = {}, socialLinkArr = [], elemClass, theme } = props;
  const [hasScroll, setHasScroll] = useState(false);
  const [announcementBarHeight, setAnnouncementBarHeight] = useState();

  const {
    announcement_link: announcementLink,
    announcement_text: announcementText,
    background_opacity: backgroundOpacity,
    color_bg: colorBg,
    color_text: colorText,
    home_page_only: homePageOnly,
    show_social_media_icon: showSocialMediaIcon,
    announcement_width: announcementWidth,
    color_icon: colorIcon,
    show_telephone: showTelephone,
    telephone,
    fixed_top: fixedTop = false,
    text_size: textSize = 11,
  } = settings;

  const {
    settings: { switch_status: siteSwitchStatus = false },
    block_order: switchBlockOrder = [],
  } = theme?.current?.sections?.['site-select'] ?? { settings: {} };

  const addSiteSwitchFixedHeight = useMemo(() => {
    if (!fixedTop) {
      return false;
    }
    if (siteSwitchStatus && switchBlockOrder.length !== 0) {
      return true;
    }
    return false;
  }, [siteSwitchStatus, switchBlockOrder, fixedTop]);

  const isHome = props?.history?.location?.pathname === '/';

  const handleScroll = (e) => {
    if (document.documentElement.scrollTop > 0 || e.target.scrollingElement.scrollTop > 0) {
      setHasScroll(true);
    } else {
      setHasScroll(false);
    }
  };
  useEffect(() => {
    if (fixedTop) {
      document.addEventListener('scroll', handleScroll, false);
      const dom = document.getElementsByClassName('header-announcement')[0];
      const BoundingClientRect = dom?.getBoundingClientRect();
      setAnnouncementBarHeight(BoundingClientRect?.height);
    }
    return () => {
      document.removeEventListener('scroll', handleScroll, false);
    };
  }, [fixedTop]);

  useEffect(() => {
    const dom = document.getElementsByClassName('header-announcement')[0];
    const BoundingClientRect = dom?.getBoundingClientRect();
    setAnnouncementBarHeight(BoundingClientRect?.height);
  }, [hasScroll]);

  const formateAnnouncementUrl = () => {
    let announcementUrl = '';
    try {
      announcementUrl = announcementLink ? JSON.parse(announcementLink)?.url : announcementLink;
    } catch (error) {
      console.log(error);
    }
    return announcementUrl;
  };
  if (homePageOnly && !isHome) {
    return <></>;
  }
  return (
    <Style
      className={cn(elemClass, {
        'fixed-top': fixedTop && hasScroll,
        'site-switch-height': addSiteSwitchFixedHeight,
      })}
      style={{
        height: fixedTop && hasScroll ? announcementBarHeight : '',
        position: fixedTop && hasScroll ? 'fixed' : '',
      }}
    >
      <div className='header-announcement'>
        <div
          className='announcement-body-bg'
          style={{
            background: colorBg,
            opacity: backgroundOpacity * 0.01,
          }}
        />
        <ul className={cn('announcement-body', `announcement-width-${announcementWidth}`)}>
          <li className='announcement-body-left'>
            {showTelephone && telephone && (
              <span className='announcement-telephone'>
                <svg className='telephone-icon' aria-hidden='true' height='16px' width='16px' fill={colorIcon}>
                  <use xlinkHref='#iconic_phone' />
                </svg>
                <a
                  href={`tel:${telephone}`}
                  style={{
                    color: colorIcon,
                  }}
                  rel='nofollow'
                >
                  {telephone}
                </a>
              </span>
            )}
          </li>
          <li className='announcement-body-center'>
            <a
              style={{
                color: colorText,
              }}
              className='announcement_link'
              href={formateAnnouncementUrl()}
            >
              <Text as='div' color={colorText} size={textSize} className='text'>
                {announcementText && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: announcementText,
                    }}
                  />
                )}
              </Text>
            </a>
          </li>
          <li className='announcement-body-right'>
            {showSocialMediaIcon && !!socialLinkArr?.length && (
              <span className='social-media-icons'>
                {socialLinkArr.map((item, index) => {
                  const { icon, link } = item;
                  return (
                    <a className='social-icon-link' rel='nofollow' href={link} key={index}>
                      <svg className='social-icon' aria-hidden='true' height='16px' width='16px' fill={colorIcon}>
                        <use xlinkHref={`#${icon}`} />
                      </svg>
                    </a>
                  );
                })}
              </span>
            )}
          </li>
        </ul>
      </div>
    </Style>
  );
};

export default AnnouncementBar;
