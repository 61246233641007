// 对应装修的视频轮播图，视频轮播图的内容有两种：图片和视频，对应image-item和video-item
import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'zent';
import cn from 'classnames';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Style } from './style';
import { ImageItem } from './image-item';
import { VideoItem } from './video-item';
import useSlide from '../../hooks/slide';

SwiperCore.use([]);

const SlideShow = (props) => {
  const { settings = {}, block_order: blockOrder = [], blocks, elemClass } = props;
  const {
    slideshow_height: slideshowHeight = 'medium',
    text_size: textSize = 'medium',
    autorotate_speed: autoRotateSpeed = 3,
    autorotate: autoRotate = false,
    mobile_text_type: mobileTextType,
    box_width: boxWidth,
  } = settings;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [status, setSwiperStatus] = useState('initial');
  const [autoPlayParams, setAutoPlayParams] = useState({});
  const [showSwiper, setShowSwiper] = useState(true);
  const [textAlignClass, setTextAlignClass] = useState('');

  const { sliderRef, sliderWrapRef, slideImageHeight, textSizeClass } = useSlide({
    blockOrder,
    blocks,
    slideshowHeight,
    settings,
    textSize,
    eventName: 'slide-show-v2',
  });

  const onNextClick = () => {
    sliderRef?.current?.swiper?.slideNext();
  };

  const onPrevClick = () => {
    sliderRef?.current?.swiper?.slidePrev();
  };

  const slideToIndex = (index) => {
    sliderRef?.current?.swiper?.slideTo(index);
  };
  /** 是否播放选项 */
  useEffect(() => {
    const autoplay = { delay: autoRotateSpeed * 1000 };
    if (autoRotate && blockOrder.length > 1) {
      setAutoPlayParams({ autoplay });
      sliderRef?.current?.swiper?.autoplay?.start();
    } else {
      setAutoPlayParams({});
      sliderRef?.current?.swiper?.autoplay?.stop();
    }
  }, [autoRotateSpeed, autoRotate]);

  /** 播放间隔选项 */
  // 下面代码我看不懂 可能导致内存泄露
  // useEffect(() => {
  //     setShowSwiper(false);
  //     setTimeout(() => {
  //         setShowSwiper(true);
  //     });
  // }, [autoRotateSpeed]);
  useEffect(() => {
    sliderRef?.current?.swiper?.update();
  }, [boxWidth, autoRotateSpeed]);
  return (
    <Style
      className={cn('slide-show-section', elemClass, mobileTextType, `cn-${boxWidth}`)}
      pcHeight={slideImageHeight}
      mobileHeight={slideImageHeight}
      slideImageHeight={slideImageHeight}
    >
      <div ref={sliderWrapRef} className={cn('slide-show', status, textSizeClass, textAlignClass)}>
        {showSwiper && (
          <Swiper
            loop
            {...autoPlayParams}
            ref={sliderRef}
            noSwiping
            onResize={(swiper) => {
              swiper.update();
            }}
            onSwiper={() => {
              setSwiperStatus('loaded');
            }}
            onSlideChange={(swiper) => {
              const { realIndex } = swiper;
              setCurrentIndex(realIndex);
            }}
          >
            {blockOrder.map((key, index) => {
              const blockSettings = blocks[key]?.settings || {};
              const type = blocks[key]?.type;
              return (
                <SwiperSlide key={index}>
                  {type === 'image' ? (
                    <ImageItem settings={blockSettings} />
                  ) : (
                    <VideoItem settings={blockSettings} globalSettings={settings} />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}

        {blockOrder.length > 1 ? (
          <div className={cn('slide-menu')}>
            <div className='slide-navigation-bar'>
              <Icon onClick={onPrevClick} className='slide-prev-btn' type='left' />
              <div className='slide-indicator-wrap'>
                {blockOrder.map((item, index) => {
                  const activeClass = index === currentIndex ? 'slide-indicator-active' : '';
                  return (
                    <div
                      onClick={() => slideToIndex(index + 1)}
                      className={cn('slide-indicator-dot', activeClass)}
                      key={index}
                    />
                  );
                })}
              </div>
              <Icon onClick={onNextClick} className='slide-next-btn' type='right' />
            </div>
          </div>
        ) : null}
      </div>
    </Style>
  );
};

export default SlideShow;
