import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import NewLink from 'cpn/new-link';
import { Style } from './style';
import TextPosition from '../text-position';
import TextContainer, { formateTextContainerProps } from '../text-container';
import SwiperIndicator, { INDICATORE_MAP } from '../swiper-indicator';
import { DefaultImg } from '../../../components/default-img';
import Image from 'components/base/image';
import { blockOrderFilter } from '../../../../util/edit';

function Carousel(props) {
  const { settings, elemClass, blocks } = props;
  const blockOrder = blockOrderFilter(props.block_order, blocks);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef();

  console.log(sliderRef);

  useEffect(() => {
    sliderRef?.current?.swiper?.update();
  }, [props]);

  const goTo = (item) => {
    item.settings?.pic_link && window.open(JSON.parse(item.settings.pic_link).url);
  };

  return (
    <Style className={cn(elemClass)} style={props.style}>
      <Swiper
        loop
        ref={sliderRef}
        onResize={(swiper) => {
          swiper.update();
        }}
        className='swiper-container'
        onSlideChange={(swiper) => {
          const { realIndex } = swiper;
          setCurrentIndex(realIndex);
        }}
        autoplay={settings.autorotate && { delay: settings.autorotate_speed * 1000 }}
      >
        {blockOrder.map((blockId) => {
          const item = blocks[blockId];
          const textContainerProps = formateTextContainerProps(
            item.block_order,
            item.blocks,
            item.settings.button_label_align,
          );

          return (
            <SwiperSlide key={blockId} className='swiper-item'>
              <div
                onClick={() => {
                  goTo(item);
                }}
              >
                {item.settings?.image ? (
                  <Image
                    className='swiper-image'
                    src={item.settings.image}
                    alt={textContainerProps.blocks[0]?.description}
                  />
                ) : (
                  <DefaultImg customName='swiper-image' width='auto' height='41%' alt='swiper-image' />
                )}

                <TextPosition
                  style={{
                    height: '95%',
                  }}
                  className='swiper-text'
                  position={item.settings.text_position}
                  // position={'right bottom'}
                >
                  <TextContainer {...textContainerProps}></TextContainer>
                </TextPosition>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {settings.indicator && blockOrder.length > 1 && (
        <SwiperIndicator
          type={INDICATORE_MAP[settings.indicator]}
          total={blockOrder.length}
          color={settings.indicator_color}
          currentIndex={currentIndex}
          position={settings.indicator_align}
        />
      )}
    </Style>
  );
}

export default Carousel;

// 图片对其方式去掉
// indicator style_one 取值规范
