import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import FooterMenu from './components/footer-menu';
import FooterText from './components/footer-text';
import FooterNews from './components/footer-news';
import FooterLogo from './components/footer-logo';
import FooterCustomMenu from './components/footer-custom-menu';
import FooterBottom from './components/footer-bottom';
import GDPR from '../../../../components/page/gdpr';
import { useClientWidth } from 'hook/global-theme';
import { hexToRGBA } from 'utils/color';

import { Style } from './style';

React.useLayoutEffect = useEffect;

const snippetsMap = {
  link_list: FooterMenu,
  text: FooterText,
  newsletter: FooterNews,
  'footer-logo': FooterLogo,
  'custom-menu': FooterCustomMenu,
};

const Footer = (props) => {
  const {
    settings = {},
    blocks,
    block_order: initBlockOrder = [],
    elemClass,
    socialLinkArr = [],
    initialData = {},
  } = props;
  const {
    show_payment_icons: showPaymentIcons,
    color_footer_bg: colorFooterBg,
    color_footer_text: colorFooterText,
    mobile_device_menu_style: mobileDeviceMenuStyle,
    mobile_device_footer_text_align: mobileDeviceFooterTextAlign,
    mobile_display_two_in_one_line: mobileDisplayTwoInOneLine,
  } = settings;

  const { kdtId } = initialData;
  const [blockOrder, setBlockOrder] = useState(initBlockOrder);

  const [pathname, setPathName] = useState('');

  const style = {
    background: colorFooterBg,
    color: colorFooterText,
  };

  useEffect(() => {
    setPathName(window.location.pathname);
    setBlockOrder(initBlockOrder);
  }, [initBlockOrder]);

  /**
        展开（半行的）
            系统菜单的二级
            文本
        折叠（半行的）
            系统菜单的二级
     * */

  // 新的对齐方式
  const { clientWidth } = useClientWidth();
  const isMobile = clientWidth > 780;
  const newMobileDeviceFooterTextAlign = useMemo(() => {
    if (mobileDisplayTwoInOneLine && isMobile) {
      return 'left';
    }
    return mobileDeviceFooterTextAlign;
  }, [mobileDisplayTwoInOneLine, mobileDeviceFooterTextAlign]);

  const menuTypeList = ['custom-menu', 'link_list'];

  // borderColor
  const borderColor = useMemo(() => {
    return hexToRGBA(style?.color, 0.2);
  }, [style?.color]);

  return (
    <div>
      <div className='no-margin'></div>
      <Style
        className={elemClass}
        mobileDeviceFooterTextAlign={newMobileDeviceFooterTextAlign}
        borderColor={borderColor}
      >
        <div
          style={style}
          data-path={pathname}
          className={cn(
            'page-footer',
            elemClass,
            `mobile-device-footer-text-align-${newMobileDeviceFooterTextAlign}`,
            `mobile-device-menu-style-${mobileDeviceMenuStyle}`,
            `mobile-display-two-in-one-line-${mobileDisplayTwoInOneLine}`,
          )}
        >
          <div className={cn('page-footer-width', `footer-width-${settings?.footer_width}`)}>
            {!!blockOrder?.length && (
              <div className={cn('footer-link-container')}>
                {blockOrder.map((blockId, index) => {
                  const blockItem = blocks[blockId];
                  const Snipper = snippetsMap[blockItem?.type];
                  return (
                    Snipper && (
                      <div
                        key={index}
                        className={cn(
                          'link-item',
                          `link-item-type-${blockItem?.type}`,
                          `link-item-is-menu-${menuTypeList.includes(blockItem?.type)}`,
                        )}
                      >
                        <Snipper
                          style={style}
                          {...blockItem}
                          mobileDeviceFooterTextAlign={newMobileDeviceFooterTextAlign}
                          mobileDeviceMenuStyle={mobileDeviceMenuStyle}
                          mobileDisplayTwoInOneLine={mobileDisplayTwoInOneLine}
                          socialLinkArr={socialLinkArr}
                          initialData={initialData}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            )}
            <FooterBottom
              settings={settings}
              style={style}
              kdtId={kdtId}
              initialData={initialData}
              mobileDeviceFooterTextAlign={newMobileDeviceFooterTextAlign}
            />
          </div>
        </div>
      </Style>
      <GDPR />
    </div>
  );
};

export default Footer;
