import React from 'react';
import { pushToHistory } from 'utils/web-tool';
import { withRouter } from 'react-router-dom';
import Style from './style';
import Image from 'components/base/image';

const HeaderLogo = ({ className, logo, shopName, history, menuHeight, logoColor }) => {
  return (
    <Style className={className} pcHeight={menuHeight - 36} logoColor={logoColor}>
      <a href='/'>
        {logo && <Image className='logo' src={logo} alt={shopName} />}
        {!logo && <span className='shop-name'>{shopName}</span>}
      </a>
    </Style>
  );
};

export default withRouter(HeaderLogo);
