import styled from 'styled-components';
import { getPadding } from '../padding/style';
import { getMargin } from '../margin/style';

export default styled.span`
  word-break: break-word;
  /* 字体大小和行高 */
  ${(props) => (props.type === 'header' ? props.theme.getHeaderSize(props.size) : props.theme.getBaseSize(props.size))};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  ${(props) => getMargin(props)}
  ${(props) => getPadding(props)}
`;
