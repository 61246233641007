import styled from 'styled-components';

export const Style = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  img {
    /* width: 100%; */
    height: 100%;
  }
  .default-img-box {
    fill: rgba(var(--color_body_text_rgb), 0.08);
  }
`;
