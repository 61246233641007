import shopApi from '../../api/shop';
import { SWITCH_OPEN_STATUS } from '../../const';
import { isFromXHSAndWeapp } from '../env';

// 这里目前接口请求过多，需要做接口聚合 后端答应在公交做
const THIRD_CONTAINER_ID = 'third-channel-container';
export default class WhatsAppMessage {
  constructor(kdtId) {
    this.kdtId = kdtId;
    this.thirdContainer = '';
    window.addEventListener('load', this.getWhatsAppMsgConfig.bind(this));
    window.addEventListener('load', this.getLineConfig.bind(this));
  }
  initContainer() {
    // 微信小程序和小红书小程序webview加载去除聊天客服
    if (isFromXHSAndWeapp() || this.thirdContainer) return;
    this.thirdContainer = document.createElement('div');
    this.thirdContainer.setAttribute('id', THIRD_CONTAINER_ID);
    const containerMove = (clientX, clientY) => {
      if (clientX === 0 && clientY === 0) return;
      const x = clientX > 0 ? clientX : 0;
      const y = clientY > 0 ? clientY : 0;
      this.thirdContainer.setAttribute('style', `position:fixed;z-index:1000;left:${x - 28}px;top:${y - 28}px`);
    };
    const handleContainerMove = (e) => {
      const { changedTouches } = e;
      if (changedTouches) {
        const { clientX, clientY, target } = changedTouches[0];
        if (target?.id === THIRD_CONTAINER_ID) {
          e.preventDefault();
          e.stopPropagation();
          containerMove(clientX, clientY);
        }
        return;
      }
      const { clientX, clientY } = e;
      containerMove(clientX, clientY);
    };
    if (window.self === window.top) {
      this.thirdContainer.ondrag = (e) => {
        handleContainerMove(e);
      };
      window.addEventListener('touchmove', handleContainerMove, { passive: false });
    }
    const isH5 = window.innerWidth < 750;
    const containerStyle = `position:fixed;z-index:1000;`;
    const positionStyle = isH5
      ? `${containerStyle} right:16px;bottom:16px`
      : `${containerStyle} right:24px;bottom:140px`;
    this.thirdContainer.setAttribute('style', positionStyle);
    document.body.appendChild(this.thirdContainer);
  }
  getWhatsAppMsgConfig() {
    if (!this.kdtId) return;
    this.initContainer();
    shopApi.getUsageConfig({ kdtId: this.kdtId }).then((data) => {
      const { status, mobileCtyCode, mobile } = data;
      if (status) {
        const whatsAppLinkElement = document.createElement('a');
        const href = `https://api.whatsapp.com/send?phone=${mobileCtyCode}${mobile}&text=Hello`;
        whatsAppLinkElement.setAttribute('href', href);
        whatsAppLinkElement.setAttribute('target', '_blank');
        whatsAppLinkElement.setAttribute('style', 'display:block');
        const linkImgElement = document.createElement('img');
        linkImgElement.setAttribute(
          'src',
          'https://img01.yzcdn.cn/upload_files/2021/09/16/Fu1RAy0_oP6dVPNqp4ds7BS4k0Wo.png',
        );
        linkImgElement.setAttribute('style', `height:60px;width:60px`);
        whatsAppLinkElement.appendChild(linkImgElement);
        this.thirdContainer.appendChild(whatsAppLinkElement);
      }
    });
  }
  getLineConfig() {
    if (!this.kdtId) return;
    this.initContainer();
    shopApi.getLineBindInfo({ kdtId: this.kdtId }).then((res) => {
      const { htmlTag, status } = res;
      if (!htmlTag || status === SWITCH_OPEN_STATUS.CLOSE) return;
      const ele = document.createElement('div');
      ele.innerHTML = htmlTag;
      const tagElement = ele.getElementsByTagName('a');
      if (!tagElement[0]) return;
      const href = tagElement[0].getAttribute('href');
      if (!href) return;
      const lineElement = document.createElement('a');
      lineElement.setAttribute('href', href);
      lineElement.setAttribute('target', '_blank');
      lineElement.setAttribute('style', 'display:block');
      const lineImage = document.createElement('img');
      lineImage.setAttribute(
        'src',
        'https://i18n-img.yzcdn.cn/upload_files/2022/01/18/FqWRghkFCMVGqlWJDshnvfnHh_fE.png',
      );
      lineImage.setAttribute('style', `height:60px;width:60px`);
      lineElement.appendChild(lineImage);
      this.thirdContainer.appendChild(lineElement);
    });
  }
}
