import React, { forwardRef } from 'react';
import Style from './style';
import { fontSizeMap, fontWeightMap } from '../../../const/standard';

const Text = forwardRef((props, ref) => {
  const { size = 10, fontWeight, type = 'base', children, ...restProps } = props;
  return (
    <Style
      ref={ref}
      {...restProps}
      size={fontSizeMap[`h${size}`]}
      type={type}
      fontWeight={fontWeightMap[fontWeight] || 'inherit'}
    >
      {children}
    </Style>
  );
});

export default Text;
