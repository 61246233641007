import styled from 'styled-components';
import { getSizeVw } from '../../../../../../style/fn';

export default styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  .header-center-logo-container .header-operate-container {
    position: absolute;
    transform: translateY(-50%);
    right: 80px;
    top: 50%;
  }

  .header-icon {
    width: 24px;
    height: 24px;
    fill: var(--color_text);
    display: inline-block;
    vertical-align: bottom;
  }
  .header-icon-container {
    margin-left: 24px;
    display: inline-block;
    cursor: pointer;
    padding: 6px 0;
  }
  @media (max-width: 769px) {
    .dynamic-display {
      display: none;
    }
  }
`;
