import styled from 'styled-components';

const specialMaxWidthEnum = {
  margin80: 'margin_80',
  full: 'full',
};

const getWidth = (props) => {
  const { maxWidth } = props;
  let value = '';
  switch (maxWidth) {
    case specialMaxWidthEnum.full:
    case specialMaxWidthEnum.margin80:
      value = '100%';
      break;
    default:
      value = `${maxWidth}px`;
  }
  return value;
};

export default styled.div`
  box-sizing: border-box;
  width: ${(props) => getWidth(props)};
  max-width: 1920px;
  margin: 0 auto;
  padding: ${(props) =>
      props.maxWidth === specialMaxWidthEnum.full ? 0 : props.theme.getSpace(props.theme.vContainer)}
    ${(props) => (props.maxWidth === specialMaxWidthEnum.full ? 0 : props.theme.getSpace(props.theme.hContainer))};
`;
