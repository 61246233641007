import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  line-height: 1;
  padding: 2px 4px;
  font-size: 11px;
  border-radius: 8px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
`;
