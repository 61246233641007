import React, { useState, useEffect, useRef } from 'react';
import { EmailSubscribe, DiscountActivity, WarnNote } from '@youzan/allvalue-pop-template'; // 这个依赖需要将源设置为"youzan ----- http://registry.npm.qima-inc.com/"才能下载
import { Dialog } from 'zent';
import LocalStorage from 'utils/local-storage';
import { WARM_NOTE_IDS_AFTER_SHOW, POP_SHOW_ONCE_IDS } from 'utils/local-storage/config';
import OrderApi from '../../../../api/order';
import ShopApi from '../../../../api/shop';
import { POP_THEME_TYPE } from '../../../../const/index';
import { buriedPoint } from '../../../../util/log-buried-point';
import singleStoreRoute from '../../../../route/single-store';
import { GlobalStyle } from './style';

export const ComponentList = {
  [POP_THEME_TYPE.EMAIL_SUBSCRIBE]: EmailSubscribe,
  [POP_THEME_TYPE.DISCOUNT_ACTIVITY]: DiscountActivity,
  [POP_THEME_TYPE.WARM_NOTE]: WarnNote,
};

export const FREQUENCY_TYPE = {
  EVERY_TIME: 'everytime',
  ONLY_FIRST_TIME: 'only_first_time',
};

// 活动弹窗页面展示配置
export const PAGE_TYPE_MAP = {
  ALL: 'all',
  Home: 'home',
};

export const PAGE_TYPE_MAP_CONFIG = {
  // 活动弹窗的配置项
  [PAGE_TYPE_MAP.Home]: ['/'],

  // 传统网页不存在关注有礼
};

// 只展示一次逻辑的弹窗类型
export const ONLY_ONCE_POP_TYPE = [POP_THEME_TYPE.EMAIL_SUBSCRIBE];

const PopList = ({ themeData, locale, initialData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [popData, setPopData] = useState([]);

  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const { kdtId } = initialData;

  const currentPop = popData?.[currentIndex];
  const popType = currentPop?.popType;
  const popId = currentPop?.popId;
  const PopDom = popType ? ComponentList?.[popType] : null;

  const openPop = () => {
    setVisible(true);
    setTimeout(() => {
      buriedPoint('popup_open', { pop_id: currentPop?.popId }, { eventType: 'view' });
    });
  };

  // 获取已经展示过的弹窗id集合
  const getPopAfterShow = () => {
    const ids = LocalStorage.get(POP_SHOW_ONCE_IDS) || [];
    return ids;
  };

  // 更新和存储已经展示过的弹窗id
  const storePopAfterShow = () => {
    const popIds = getPopAfterShow();
    const newPopIds = {
      ...popIds,
      [popType]: Array.from(new Set([...(popIds?.[popType] || []), popId])),
    };

    LocalStorage.set(POP_SHOW_ONCE_IDS, newPopIds, 30);
  };

  // 获取已完成的弹窗id集合
  const getHasFinishPopIds = () => {
    const popIds = JSON.parse(window.sessionStorage.getItem('has_finish_pop_ids')) || {};
    return popIds;
  };

  // 设置已完成的弹窗id集合
  const setHasFinishPopIds = () => {
    const popIds = getHasFinishPopIds();
    const newPopIds = {
      ...popIds,
      [popType]: Array.from(new Set([...(popIds?.[popType] || []), popId])),
    };
    window.sessionStorage.setItem('has_finish_pop_ids', JSON.stringify(newPopIds));
  };

  const handleData = (popData) => {
    const taskPopIds = getHasFinishPopIds();
    const path = window.location.pathname;
    const popListData = popData?.filter((item) => {
      const { popType, settings: { frequencyType } = {} } = item;

      // 过滤已经完成确认逻辑的弹窗
      if (taskPopIds?.[popType]?.includes(item?.popId)) {
        return false;
      }

      const hasShowPopIds = getPopAfterShow();

      // 过滤只展示一次的弹窗：1、配置只展示一次，2、任何配置都只展示一次
      if (
        (ONLY_ONCE_POP_TYPE.includes(popType) || frequencyType === FREQUENCY_TYPE.ONLY_FIRST_TIME) &&
        hasShowPopIds?.[popType]?.includes(item?.popId)
      ) {
        return false;
      }

      const pageType = item?.settings?.pageType || PAGE_TYPE_MAP.Home;

      // 根据页面展示配置过滤
      if (pageType === PAGE_TYPE_MAP.ALL) {
        return true;
      } else {
        return PAGE_TYPE_MAP_CONFIG[pageType].includes(path);
      }
    });

    setPopData(popListData);
    openPop();
  };

  useEffect(() => {
    ShopApi.getAllPopMuster().then((res) => handleData(res));
  }, []);

  const closePop = () => {
    const popIds = getHasFinishPopIds();
    if (!popIds?.[popType]?.includes(popId)) {
      setHasFinishPopIds();
    }
    if (currentPop?.settings?.frequencyType === FREQUENCY_TYPE.ONLY_FIRST_TIME) {
      storePopAfterShow();
    }
    setVisible(false);
    if (popData.length <= currentIndex + 1) return;
    setCurrentIndex(currentIndex + 1);
    setCurrentStep(1);
    setTimeout(() => openPop(), 100);
  };

  const onEmailSubmit = (email) => {
    const param = {
      popId,
      email,
      kdtId,
    };
    ShopApi.subscribeEmail(param).then((res) => {
      const popIds = getHasFinishPopIds();
      if (!popIds?.[popType]?.includes(popId)) {
        setHasFinishPopIds();
      }
      setCurrentStep(currentStep + 1);
      storePopAfterShow();
    });
  };

  return (
    <>
      {popData.length >= currentIndex + 1 && (
        <>
          <GlobalStyle />
          <Dialog
            mask
            visible={visible}
            maskClosable={false}
            closeBtn={false}
            className='pop-list-dialog'
            style={{ padding: 0 }}
          >
            {PopDom && (
              <PopDom
                info={popData[currentIndex]?.settings || {}}
                i18nLang={{ locale, lang: window?.i18nLang?.lang }}
                API={OrderApi}
                useType='DOM'
                currentPop={currentPop}
                showCode={popData[currentIndex]?.popInfo?.extInfo?.discountCode || ''}
                popupId={popData[currentIndex]?.popId}
                currentStep={currentStep}
                onEmailSubmit={onEmailSubmit}
                onClose={() => closePop(popData[currentIndex])}
                mainConfiguration={themeData}
              />
            )}
          </Dialog>
        </>
      )}
    </>
  );
};

export default PopList;
