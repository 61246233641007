import React from 'react';
import NewPopover from '../new-popover';
import Style, { GlobalStyle } from './style';
import cn from 'classnames';
// 适用气泡内容为列表的情况
// @param text：气泡内容，其他参数使用同Popover
// @example

function NewPopoverList(props) {
  const { className, list = [], ...others } = props;
  return (
    <Style>
      <GlobalStyle />
      <NewPopover className={cn('new-popover-list', className)} {...others}>
        {props.children}
        <NewPopover.Content>
          <div className='list'>
            {list?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={cn('item', { disabled: item?.disabled })}
                  onClick={() => {
                    if (!item?.disabled && item?.onClick) {
                      item.onClick();
                    }
                  }}
                >
                  <span className={cn({ 'show-red-point': item?.showRedPoint })}>{item?.text}</span>
                </div>
              );
            })}
          </div>
        </NewPopover.Content>
      </NewPopover>
    </Style>
  );
}
NewPopoverList.Trigger = NewPopover.Trigger;
NewPopoverList.Position = NewPopover.Position;

export default NewPopoverList;
