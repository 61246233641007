import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from './load-third-javascript';
import ThirdPlatformApi from '../../api/third-platform';

export default class FacebookMessage {
  constructor(options = {}) {
    this.config = options;
    window.addEventListener('load', this.getFacebookMsgConfig.bind(this));
  }

  getFacebookMsgConfig() {
    ThirdPlatformApi.getFacebookMessengerCode().then((res = {}) => {
      const { status, language, initSetting, attributeSetting } = res || {};
      if (status === 'disable') return;
      if (attributeSetting) {
        const attributeConfig = JSON.parse(attributeSetting);
        this.generatorFacebookMsgEle(attributeConfig);
      }

      if (initSetting) {
        const initFbMsgConfig = {};
        const initConfigArr = initSetting.match(/\{(.*?)\}/)[1].split(',');
        // eslint-disable-next-line array-callback-return
        initConfigArr.map((keyAndVal) => {
          const [key, val] = keyAndVal.replace(/'/g, '').split(':');
          if (key === 'xfbml') {
            initFbMsgConfig[key] = val === 'true';
            return;
          }
          initFbMsgConfig[key] = val;
        });
        this.loadFacebookMsgSdk({
          facebookInitConfig: initFbMsgConfig,
          lang: language,
        });
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  generatorFacebookMsgEle(attributeConfig = {}) {
    // 生成facebook-message需要的dom节点
    const bodyEle = document.getElementsByTagName('body')[0];
    const fbRootEle = document.createElement('div');
    const fbCustomerChatEle = document.createElement('div');
    fbRootEle.setAttribute('id', 'fb-root');
    // eslint-disable-next-line array-callback-return
    Object.keys(attributeConfig).map((key) => {
      fbCustomerChatEle.setAttribute(key, attributeConfig[key].replace(/\"/g, ''));
    });

    bodyEle.appendChild(fbRootEle);
    bodyEle.appendChild(fbCustomerChatEle);
  }

  loadFacebookMsgSdk({ facebookInitConfig = {}, lang = 'en_US' }) {
    loadThirdJavascript(
      THIRD_JAVASCRIPT_CONFIG.FACEBOOK_MESSAGE.name,
      THIRD_JAVASCRIPT_CONFIG.FACEBOOK_MESSAGE.url.replace('{lang}', lang),
      true,
      {
        id: 'facebook-jssdk',
      },
    ).then(() => {
      window.fbAsyncInit = function fbAsyncInit() {
        window.FB.init(facebookInitConfig);
      };
    });
  }

  static triggerFbStatus(show) {
    if (show) {
      return FacebookMessage.showFbMsg();
    }
    FacebookMessage.hideFbMsg();
  }

  static hideFbMsg() {
    if (window.FB && window.FB.CustomerChat) window.FB.CustomerChat.hide();
  }

  static showFbMsg() {
    if (window.FB && window.FB.CustomerChat) window.FB.CustomerChat.show();
  }
}

export const triggerFbMessage = (showFbMsg) => {
  if (__isServer) return;
  const fb = window.FB;
  if (showFbMsg && !fb) new FacebookMessage();
  if (fb) FacebookMessage.triggerFbStatus(showFbMsg);
};
