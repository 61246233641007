import styled, { css } from 'styled-components';

const POSITION_SPACE = 16;
const MOBILE_POSITION_SPACE = 8;

const getPositionCSS = (props) => {
  const { textPosition = 'default' } = props;
  const newPositionSpace = POSITION_SPACE;
  const textSpace = props.theme.getSpace([MOBILE_POSITION_SPACE, newPositionSpace]);
  const textPositionMap = {
    left_top: css`
      top: ${textSpace};
      left: ${textSpace};
    `,
    right_top: css`
      top: ${textSpace};
      right: ${textSpace};
    `,
    left_bottom: css`
      bottom: ${textSpace};
      left: ${textSpace};
    `,
    right_bottom: css`
      bottom: ${textSpace};
      right: ${textSpace};
    `,
    center_center: css`
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    `,
    default: css`
      top: ${textSpace};
      left: ${textSpace};
    `,
  };
  const textPositionStr = textPositionMap[textPosition.replace(' ', '_')];

  if (!textPosition) {
    return;
  }

  return css`
    ${textPositionStr};
  `;
};
const getSellOutPositionCSS = (props) => {
  const { textPosition = 'default' } = props;
  const textSpace = '0';
  const textPositionMap = {
    left_top: css`
      bottom: ${textSpace};
      right: ${textSpace};
    `,
    right_top: css`
      bottom: ${textSpace};
      right: ${textSpace};
    `,
    left_bottom: css`
      top: ${textSpace};
      left: ${textSpace};
    `,
    right_bottom: css`
      top: ${textSpace};
      left: ${textSpace};
    `,
    center_center: css`
      top: ${textSpace};
      left: ${textSpace};
    `,
    default: css`
      top: ${textSpace};
      left: ${textSpace};
    `,
  };
  const textPositionStr = textPositionMap[textPosition.replace(' ', '_')];

  if (!textPosition) {
    return;
  }

  return css`
    ${textPositionStr};
  `;
};

export const Style = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &.hover-class:hover {
    .goods-icon-container {
      display: block;
    }
  }
  &.show-dash-discount-label-true .ht-goods-list-price {
    .cur-price {
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: var(--color_activity_label) !important;
      }
    }
  }
  &.goods-card-opend {
    border-radius: var(--border_radius_pd_card);
    overflow: hidden;
    .grid-info-image {
      border-radius: 0 !important;
    }
  }
  .desc-text {
    white-space: nowrap;
  }
  .feature-collection-grid-item {
    position: relative;
    a {
      pointer-events: auto !important;
    }
  }
  .tag-list-container {
    width: 100%;
    &.show-margin-top {
      margin-top: ${(props) => props.theme.getSpace([8, 12])};
      .tag-list {
        margin-top: 0 !important;
      }
    }
  }
  .goods-price-container {
    &.show-margin-top {
      margin-top: 4px;
    }
    .cur-price {
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: ${(props) =>
          props?.customPriceColor || props?.theme?.hexToRGBA(props.theme.colorSaleText, 1)} !important;
      }
    }
  }
  .bottom-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .vendor-name {
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.6)} !important;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .sell-comment {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.6)} !important;
      .comment-num:hover {
        color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 1)};
        cursor: pointer;
      }
      /* .sell-num:hover {
                color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 1)};
                cursor: pointer;
            } */
      .sell-num {
        max-width: 100%;
        display: flex;
        align-items: center;
      }
      .comment-num + .sell-num::before {
        content: '';
        width: 2px;
        height: 2px;
        border-radius: 50%;
        margin: 0 4px;
        background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 1)};
        display: inline-block;
        vertical-align: middle;
      }
      .sell-goods-score + .sell-num::before {
        content: '';
        width: 2px;
        height: 2px;
        border-radius: 50%;
        margin: 0 4px;
        background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 1)};
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .bottom-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .bottom-info-container {
      width: 100%;
      .dash-discount-label {
        margin-bottom: 12px;
      }
      &.show-margin-top {
        margin-top: ${(props) => props.theme.getSpace([8, 12])};
      }
    }
    &.open-card-class {
      box-sizing: border-box;
      padding-bottom: ${(props) => props.theme.getSpace([8, 16])};
      .bottom-info-container {
        box-sizing: border-box;
        padding: 0 ${(props) => props.theme.getSpace([8, 16])};
      }
      .bottom-btn-container {
        box-sizing: border-box;
        padding: 0 ${(props) => props.theme.getSpace([8, 16])};
      }
    }
  }

  .sku-page-container {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .goods-img {
    max-width: 100%;
    display: block;
  }
  .action-wrapper {
    width: 100%;
    margin-top: ${(props) => props.theme.getSpace([8, 24])};
    display: flex;
  }
  .action-wrapper-overflow {
    width: 100%;
    margin-top: ${(props) => props.theme.getSpace([8, 24])};
    flex-direction: column;
    .btn-buy {
      margin: 8px 0 0 0 !important;
    }
  }
  .goods-name-container {
    margin-top: ${(props) => props.theme.getSpace([8, 16])};
  }
  .goods-name {
    width: 100%;
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    color: ${(props) => props.customProductColor || props.theme.hexToRGBA(props.theme.colorBodyText, 1)};
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--text_line_limit);
  }

  .add-shopping-car-container {
    position: absolute;
    z-index: 14;
    width: 100%;
    bottom: 0;
    .sku-container {
      max-height: ${(props) => `${props.containerHeight}px`};
      overflow: scroll;
      box-sizing: border-box;
    }
  }

  .grid-info-wrap {
    width: 100%;
    position: relative;
    .grid-info-image {
      background-color: transparent;
    }
    .goods-default-img {
      background-color: #ffffff;
    }
    .sell-out-tag {
      width: ${(props) => props.theme.getSpace([78, 91, 96])};
      height: ${(props) => props.theme.getSpace([30, 35, 36])};
      line-height: ${(props) => props.theme.getSpace([30, 35, 36])};
      text-align: center;
      position: absolute;
      ${(props) => getSellOutPositionCSS(props)}
      background-color: rgba(51, 51, 51, 0.6);
      color: #ffffff;
      font-size: ${(props) => props.theme.getSpace([12, 13, 14])};
    }
    .sell-out-tag-min {
      width: 78px;
      font-size: 12px;
    }
    .goods-icon-container {
      z-index: 10;
      display: none;
    }
    .superimposed-goods-info {
      width: ${(props) =>
        `calc(100% - ${props.isShowWishOrCart ? props.theme.getSpace([16, 32]) : '0px'} - ${props.theme.getSpace([
          24,
          28,
          32,
        ])})`};
      position: absolute;
      ${(props) => getPositionCSS(props)}
      .goods-name-container {
        margin-top: 0;
      }
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: ${(props) =>
          props?.customPriceColor || props?.theme?.hexToRGBA(props.theme.colorSaleText, 1)} !important;
      }
    }
  }

  .open-card-class {
    .goods-name,
    .ht-goods-list-price,
    .tag-list {
      box-sizing: border-box;
      padding: 0 ${(props) => props.theme.getSpace([8, 16])};
    }
    .superimposed-goods-info {
      .goods-name,
      .ht-goods-list-price,
      .tag-list {
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 749px) {
    .grid-info-wrap {
      width: 100%;
      position: relative;
      .sell-out-tag {
        width: 78px;
        box-sizing: border-box;
        text-align: center;
        position: absolute;
        ${(props) => getSellOutPositionCSS(props)}
        background-color: rgba(51, 51, 51, 0.6);
        color: #ffffff;
        font-size: 12px;
      }
    }
    .bottom-info {
      flex-direction: column !important;
      align-items: flex-start !important;
      .sell-comment {
        flex-direction: column;
        align-items: flex-start !important;
        .comment-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
        }
      }
    }
    .add-shopping-car-container {
      position: fixed !important;
      align-items: flex-end;
      background-color: rgba(0, 0, 0, 0.3);
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 9999;
      left: 0;
    }
    .action-wrapper {
      flex-direction: column;
      .btn-buy {
        margin: 8px 0 0 0 !important;
      }
    }

    .featured-row {
      flex-direction: column;
    }

    .featured-row-text {
      margin-left: 0;
    }

    .featured-row-img,
    .featured-row-text {
      width: 100%;
    }
  }
  &.cn-full_screen {
    .goods-name,
    .ht-goods-list-price {
      padding: 0 ${(props) => props.theme.getSpace(props.theme.g16)};
    }
  }
  &.product-title-align-center {
    .grid-info-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .goods-name {
        text-align: center;
      }
    }
    .bottom-info-container {
      text-align: center;
    }
    .superimposed-goods-info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .tag-list {
      justify-content: center;
    }
    .bottom-info {
      flex-direction: column !important;
      align-items: center !important;
    }
    .comment-container {
      justify-content: center;
    }
    .sell-comment {
      margin-bottom: 4px;
      justify-content: center;
      align-items: center !important;
    }
  }
  &.product-title-align-right {
    .grid-info-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      .goods-name {
        text-align: right;
      }
    }
    .bottom-info-container {
      text-align: right;
    }
    .superimposed-goods-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .tag-list {
      justify-content: flex-end;
    }
    .bottom-info {
      flex-direction: column !important;
      align-items: flex-end !important;
    }
    .comment-container {
      justify-content: flex-end;
    }
    .sell-comment {
      margin-bottom: 4px;
      justify-content: flex-end;
      align-items: flex-end !important;
    }
  }
`;

export const PriceStyle = styled.div`
  &.ht-goods-list-price {
    margin-top: 4px;
    color: ${(props) => props.customPriceColor || props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
    /* color: var(--color_sale_text); */
    text-align: left;
    max-width: 100%;
    overflow: hidden;
    .discount-text {
      margin-left: 16px !important;
    }
    .cur-price {
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: ${(props) => props.customPriceColor || props.theme.hexToRGBA(props.theme.colorSaleText, 1)} !important;
      }
    }
  }
`;
