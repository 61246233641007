import React, { useState, useEffect } from 'react';
import Image from 'components/base/image';

import { CUSTOM_CONFIG } from 'const/custom-config';

const PAYMENT_ICON_LIST = [
  {
    image: 'https://img01.yzcdn.cn/upload_files/2022/03/29/FnwLtpTP1MuIfTio4XJsTgB0HOlK.png',
    title: 'VISA',
  },
  {
    image: 'https://b.yzcdn.cn/Mastercard@3x.png',
    title: '',
  },
  {
    image: 'https://img01.yzcdn.cn/upload_files/2022/03/29/FpV9bMCRU4V7iBcDdCWX21rwBeuA.png',
    title: '',
  },
  {
    image: 'https://img01.yzcdn.cn/upload_files/2022/03/29/FiJsARPZZVRUken_X5ElTWNL4piC.png',
    title: '',
  },
  {
    image: 'https://b.yzcdn.cn/JapanCreditBureau@3x.png',
    title: '',
  },
  {
    image: 'https://img01.yzcdn.cn/upload_files/2022/03/29/Fmb8rwWMsNhQA-pp-Gvchxs_CNz5.png',
    title: '',
  },
  {
    image: 'https://img01.yzcdn.cn/upload_files/2022/03/29/FtApDapKy3LK7QkWLovrMP0czZPN.png',
    title: '',
  },
  {
    image: 'https://img01.yzcdn.cn/upload_files/2022/03/29/FotwLpTALgmo9feMBjS-Z1oV2uiF.png',
    title: '',
  },
];

const CustomPaymentIcons = ({ paymentBorderColor }) => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    console.log('CUSTOM_CONFIG.', CUSTOM_CONFIG);
    console.log('CUSTOM_CONFIG.CustomPaymentIcons', CUSTOM_CONFIG.customPaymentIcons);
    if (CUSTOM_CONFIG.customPaymentIcons.includes(+window?.global_data?.kdtId)) {
      setIsShow(true);
    }
  }, []);

  if (!isShow) {
    return null;
  }

  return (
    <>
      {PAYMENT_ICON_LIST.map((item, index) => {
        const { image, title } = item;
        return (
          <span className='footer-payment-icons-item' style={{ borderColor: paymentBorderColor }} key={image + index}>
            <Image src={image} alt={title} title={title} />
          </span>
        );
      })}
    </>
  );
};

export default CustomPaymentIcons;
