import { createGlobalStyle } from 'styled-components';

const itemStyle = `
    display: flex;
    align-items: center;
    width: 100%;
    .shop-logo, .default-logo {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        object-fit: cover;
        flex-shrink: 0;
    }
    .shop-name {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
        font-size: 14px;
    }
    .logo-item + .shop-name {
        margin-left: 8px;
    }
`;

export const GlobalStyle = createGlobalStyle`
    .multi-select-container{
        margin-right: 0 !important;
        height: 36px;
        line-height: 36px;
        display: flex !important;
        align-items: center;
        .item-wrapper {
            ${itemStyle}
            color: #323233;
            .shop-currency {
                display: none;
            }
        }   
        .sel-icon {
            display: none;
        }
        .zent-select-text {
            padding: 0 25px 0 12px;
            height: 36px;
            display: flex;
            align-items: center;
            border: 1px solid #EBEDF0 !important;
            color: red;
            &::after {
                content: ""; //这里是下拉图标
                font-family: zenticonv8x5x11 !important;
                background: none !important;
                box-sizing: border-box;
                font-size: 12px;
                color: #323233;
            }
        }
    }
    .multi-select-container-popup{
        z-index: 1000;
        margin-top: -4px;
        .zent-select-option {
            display: flex !important;
            padding: 0 16px !important;
            line-height: 52px;
            height: 52px;
            align-items: center;
            justify-content: space-between;
            .sel-icon {
                margin-left: 18px;
                visibility: hidden;
                &.show-icon {
                    visibility: visible;
                }
            }
        }
        .item-wrapper {
            ${itemStyle}
            color: #323233;
            .shop-currency {
                font-size: 14px;
                line-height: 20px;
                color: #C8C9CC;
            }
        }        
    }
`;
