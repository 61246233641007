import React from 'react';
import { FormSelectField } from 'zent';
import { HtFormSelectWrapper } from './style';
import cn from 'classnames';

function HtFormSelect(props) {
  const { name, selectProps, defaultValue, className, children, validators } = props;

  return (
    <HtFormSelectWrapper>
      <FormSelectField
        destroyOnUnmount
        className={cn('ht-form-select', className)}
        name={name}
        defaultValue={defaultValue}
        props={selectProps}
        validators={validators}
      />
      {children}
    </HtFormSelectWrapper>
  );
}

export default HtFormSelect;
