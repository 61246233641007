import styled from 'styled-components';

export const ThirdLoginWrapper = styled.div`
  margin-top: 24px;
`;

export const ThirdLoginTitleWrapper = styled.div`
  text-align: center;
`;
export const ThirdLoginTitleText = styled.span`
  position: relative;
  color: ${(props) => props.theme.colorBodyTexts[60]};
  font-weight: normal;
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 1px;
    background: var(--color_text_field_border);
  }
  &::after {
    left: -24px;
  }
  &::before {
    right: -24px;
  }
`;

export const ThirdLoginLists = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 16px;
`;
export const ThirdLoginListItem = styled.div`
  .image-logo {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  & + & {
    margin-left: 24px;
  }
`;
