import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { DiscountTag } from 'cpn/discount';
import HuntTag from 'cpn/hunt-tag';
import { TagStyle } from './style';
// import RootContext from '../../../../pages/context';

export const TagList = forwardRef((props, ref) => {
  const {
    discountInfo = {},
    memberInfo = null,
    groupActivityDescription,
    language,
    showDiscount = true, // 是否展示折扣配置【装修】
  } = props;
  const { discountType, discountValue, discountGets } = discountInfo || {};
  // const { member } = useContext(RootContext);
  // const isMember = member?.isMember;
  // const showMemberPriceFirst = isMember && memberInfo; // 优先展示会员价 @刘静怡
  const intl = useIntl();
  const newDiscountValue = discountValue || discountGets?.discountValue || 0;

  const showDiscountTag = !!discountType && showDiscount;
  const showGroup = !!groupActivityDescription;
  if (!showDiscountTag && !showGroup) return null;

  return (
    <TagStyle ref={ref} className='tag-list'>
      {/* 折扣标识 */}
      {showDiscountTag && (
        <div className='tag-item'>
          <DiscountTag
            className='discount-tag'
            discountType={discountType}
            discountValue={newDiscountValue}
            language={language}
          />
        </div>
      )}
      {showGroup && (
        <div className='tag-item'>
          <HuntTag className='tag-item-inner'>
            {intl.formatMessage({
              id: '96a88360cb79486cb9ebed4bf2b801c2',
              defaultMessage: '拼团',
            })}
          </HuntTag>
        </div>
      )}
    </TagStyle>
  );
});
