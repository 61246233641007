import React, { useMemo, useState } from 'react';
import { Style } from './style';

const GoodsColorSku = ({ settings, data, onChange }) => {
  const { text_display: textDisplay = 'under_pic', show_product_color_sku: showProductColorSku = false } = settings;
  if (!showProductColorSku) {
    return null;
  }
  const { options = [], variants } = data;

  const colorList = useMemo(() => {
    return options?.filter((item) => item?.typeStr === 'color_switch') || [];
  }, [options]);

  const [activeIndex, setActiveIndex] = useState();

  // const getColorMapImg = (data) => {
  //     const imageList = [];
  //     variants?.forEach((item) => {
  //         let isFlag = false;
  //         item?.optionValues.forEach((subItem) => {
  //             if (
  //                 !isFlag &&
  //                 subItem?.optionTitle === data?.optionTitle &&
  //                 subItem?.title === data?.title &&
  //                 item?.imageUrl
  //             ) {
  //                 imageList.push(item);
  //                 isFlag = true;
  //             }
  //         });
  //     });
  //     onChange && onChange({ imageList });
  // };

  const handleChangeColor = ({ e, item, index }) => {
    e.stopPropagation();
    e.preventDefault();
    if (index === activeIndex) {
      setActiveIndex(null);
      onChange && onChange(null);
      return;
    }
    // getColorMapImg(item);
    variants.some((variant) => {
      let isGetImg = false;
      variant.optionValues.forEach((opt) => {
        if (opt.optionTitle === item.optionTitle && opt.title === item.title) {
          isGetImg = true;
        }
      });
      if (isGetImg) {
        onChange && onChange(variant.imageUrl);
        return true;
      }
    });
    setActiveIndex(index);
  };

  return (
    <>
      {colorList?.[0]?.optionValues?.length && (
        <Style textDisplay={textDisplay}>
          <div className='goods-color-sku'>
            <ul>
              {colorList?.[0]?.optionValues.map((item, index) => {
                const { variantSkuColorOptionValue = {} } = item;
                const { skuColorTypeEnum, colorCode } = variantSkuColorOptionValue;
                // title: "颜色"
                // typeStr: "color_switch"
                item.optionTitle = colorList?.[0].title;
                return (
                  <li
                    key={index}
                    className={activeIndex === index ? 'active' : ''}
                    title={item?.title}
                    onClick={(e) => {
                      handleChangeColor({
                        e,
                        item,
                        index,
                      });
                    }}
                  >
                    {skuColorTypeEnum && skuColorTypeEnum !== 'two' ? (
                      <span className='color-box' style={{ backgroundColor: colorCode?.[0] }} />
                    ) : (
                      <span className='color-box'>
                        <span style={{ backgroundColor: colorCode?.[0] }} />
                        <span style={{ backgroundColor: colorCode?.[1] }} />
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </Style>
      )}
    </>
  );
};

export default GoodsColorSku;
