import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Style } from './style';
import { CartItem } from './cart-goods-list-item';
import { BlockLoading } from 'zent';

import CartActivityCard from './cart-activity-card';

/**
 *
 * @name 新的购物车的列表
 * @param param0
 * @returns
 */

const CartGoodsList = ({
  loading,
  cartList,
  multipleAutoDiscountsCart = {},
  currency,
  language,
  isExpiredGoodsStatus,
  isDrawer = false,
  itemProps,
  giftProps,
}) => {
  const { applyDiscountInfosObj = {}, joinDiscountInfosObj = {}, noDiscountInfosList = [] } = multipleAutoDiscountsCart;

  console.log('multipleAutoDiscountsCart__CartGoodsList', multipleAutoDiscountsCart);

  if (loading) {
    return <BlockLoading loading={loading} icon='circle' iconSize={32} />;
  }

  if (!cartList?.length) {
    return (
      <Style>
        <div className='empty-cart-box'>
          <svg className='empty-cart-icon' aria-hidden='true' height='60px' width='60px'>
            <use xlinkHref='#iconic-kong' />
          </svg>
          <p>
            <FormattedMessage id='goods.no_goods_you_choose' defaultMessage='您尚未添加过任何商品' />
          </p>
        </div>
      </Style>
    );
  }

  return (
    <Style>
      {Object.keys(joinDiscountInfosObj).map((key) => {
        const { activeJoinDiscountInfos, lineItems } = joinDiscountInfosObj[key];
        return (
          <CartActivityCard
            key={key}
            discountInfos={activeJoinDiscountInfos}
            lineItems={lineItems}
            currency={currency}
            language={language}
            isExpiredGoodsStatus={isExpiredGoodsStatus}
            itemProps={itemProps}
            giftProps={giftProps}
            isDrawer={isDrawer}
          />
        );
      })}

      {Object.keys(applyDiscountInfosObj).map((key) => {
        const applyDiscountInfo = applyDiscountInfosObj[key];
        return (
          <CartActivityCard
            key={key}
            discountInfos={[applyDiscountInfo]}
            lineItems={applyDiscountInfo?.lineItems}
            currency={currency}
            language={language}
            isExpiredGoodsStatus={isExpiredGoodsStatus}
            itemProps={itemProps}
            giftProps={giftProps}
            isDrawer={isDrawer}
          />
        );
      })}

      {/* 正常商品 */}
      {!!noDiscountInfosList?.length && (
        <div className='cart-activity-card'>
          {noDiscountInfosList.map((item, index) => {
            return (
              <CartItem
                item={item}
                index={index}
                key={`${item?.variantId}-${item?.goodsId}-${item.quantity}-${item.giftGoodsFlag}`}
                currency={currency}
                isExpiredGoodsStatus={isExpiredGoodsStatus}
                isDrawer={isDrawer}
                {...itemProps}
              />
            );
          })}
        </div>
      )}
    </Style>
  );
};

export default CartGoodsList;
