export const INPUT_SIZE = {
  large: 'large',
  small: 'small',
};
export const INPUT_SIZE_VALUE = {
  large: [44, 48, 52],
  small: '44px',
};

export const INPUT_TYPE = {
  text: 'text',
  number: 'number',
  password: 'password',
  textarea: 'textarea',
};
