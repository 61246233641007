import React from 'react';
import cn from 'classnames';
import Style from './style';

export const DefaultImg = ({
  customName = '',
  width = '30%',
  height = '100%',
  square = false,
  paddingTopPer = '100%',
  description = '',
  defaultHeight = false,
}) => {
  const obj = {
    width,
    height,
  };
  if (width === 'auto') delete obj.width;
  return (
    <Style className={cn(customName, { 'default-square-img': square })} paddingTopPer={paddingTopPer}>
      <div className={cn('default-img-box', { 'default-min-height': defaultHeight })}>
        <svg className='icon default-img' aria-hidden='true' {...obj}>
          <use xlinkHref='#iconic-morentu' />
        </svg>
        {description && <div className='description-wrapper'>{description}</div>}
      </div>
    </Style>
  );
};

export default DefaultImg;
