import React, { useEffect, useState } from 'react';
import { Badge } from 'zent';
import cn from 'classnames';
import { TabType } from 'const/account';
import Style from './style';
import { CartModal } from '../../../../../components/open-cart-dialog';
import { useRootContext } from 'client/pages/context/root';
import { IS_OPEN_LOGIN } from '../../../../../../const/index';
import { getQueryString } from 'utils';
import { HEAD_ICON_TYPE } from 'const';
import cartApi from 'api/cart';
import { CART_COUNT_CHANGE_EVENT } from 'const/cart';
import { useRequireLogin } from 'cpn/require-login-wrapper';

export const HeaderOperte = (props) => {
  const { jumpToOtherPage, initialData, customClass, totalCountWish } = props;
  const { setLoginDialogInfo } = useRootContext();
  const [visible, setVisible] = useState(false);
  const { userInfo = {} } = initialData || {};
  const isLogin = userInfo && userInfo?.customerId;
  const [totalOfGoods, setTotalOfGoods] = useState(0);
  const { checkedRequireLogin } = useRequireLogin();

  const cartChangeHandler = async () => {
    const count = await cartApi.getCartCount();
    setTotalOfGoods(count);
  };

  // 重置密码 等返回打开登录弹窗
  const handleOpenLogin = () => {
    const isOpenLogin = window.sessionStorage.getItem(IS_OPEN_LOGIN);
    if (isOpenLogin) {
      window.sessionStorage.removeItem(IS_OPEN_LOGIN);
      if (!isLogin) {
        setLoginDialogInfo({
          visible: true,
        });
      }
    }
    const qs = getQueryString(window?.location?.search);
    if (qs && qs?.awaken === 'resetPassword') {
      setTimeout(() => {
        // TODO
        setLoginDialogInfo({
          visible: true,
        });
      }, 0);
    }
  };

  useEffect(() => {
    cartChangeHandler(); // 初始化进入页面时需要获取购物车物品数量
    window.addEventListener(CART_COUNT_CHANGE_EVENT, cartChangeHandler); // 当在本页面内监听到localstorage变化，计算物品数量
    // 处理是否要打开登录弹窗
    handleOpenLogin();
    return () => {
      window.removeEventListener(CART_COUNT_CHANGE_EVENT, cartChangeHandler);
    };
  }, []);

  return (
    <Style className={cn(customClass)}>
      <span className='header-icon-container' onClick={() => jumpToOtherPage('/search')}>
        <svg className='icon header-icon' aria-hidden='true'>
          <use xlinkHref='#iconic-sousuo' />
        </svg>
      </span>
      <span
        onClick={() => {
          if (isLogin) {
            jumpToOtherPage('/me');
          } else {
            setLoginDialogInfo({
              visible: true,
            });
          }
        }}
        className='header-icon-container dynamic-display'
      >
        <svg className='icon header-icon' aria-hidden='true'>
          <use xlinkHref='#iconic-gerenzhanghao' />
        </svg>
      </span>
      {/* 新增心愿单入口 */}
      <span
        className='header-icon-container dynamic-display'
        id={HEAD_ICON_TYPE.wish}
        onClick={() => {
          if (isLogin) {
            jumpToOtherPage(`/me?tabId=${TabType.Wish}`);
          } else {
            setLoginDialogInfo({
              visible: true,
            });
          }
        }}
      >
        <Badge count={totalCountWish}>
          <svg className='icon header-icon' aria-hidden='true'>
            <use xlinkHref='#iconic-shoucang2' />
          </svg>
        </Badge>
      </span>
      <span
        onClick={() => {
          if (checkedRequireLogin()) return;
          window.postMessage('openRightShoppingCart', '*');
          setVisible(true);
        }}
        className='header-icon-container'
      >
        <Badge count={totalOfGoods}>
          <svg className='icon header-icon' aria-hidden='true'>
            <use xlinkHref='#iconic-gouwuche' />
          </svg>
        </Badge>
      </span>
      <CartModal
        initialData={initialData}
        visible={visible}
        onClose={() => {
          window.postMessage('closeRightShoppingCart', '*');
          setTimeout(() => {
            setVisible(false);
          }, 300);
        }}
      />
    </Style>
  );
};
