import React, { useEffect, useMemo, useState } from 'react';
import { Style } from './style';
import { useCollection } from 'hook/collection';
import GoodsCollectionWithLayout from '../../components/goods-collection-with-layout';
import GoodsCollectionWithSwiper from '../../components/goods-collection-with-swiper';
import { useThemeContext } from '../../../../hook/global-theme';
import { Wrapper } from 'components/base';
import { Background } from '../../components/background';
import { buriedPoint } from 'utils/log-buried-point';
import cn from 'classnames';

const SimpleGoodsList = (props) => {
  const { settings = {}, initialData, elemClass, data } = props;
  const {
    container_width: boxWidth,
    pic_padding: picPadding,
    grid = 3,
    rows = 2,
    collection, //  分组的ID
    mobile_display_type: mobileDisplayType = {},
    background_style: backgroundStyle = 'null',
    background_color: backgroundColor,
    background_image: backgroundImage,
    data_type: dataType,
    product_list: productList = [],
  } = settings;

  const { kdtId, userInfo } = initialData;
  const { main_val: mobileDisplayTypeMainVal = 'tiled', carousel = {} } = mobileDisplayType;
  const { auto_play_interval: mobileAutoPlayInterval = 3 } = carousel;
  const themeContext = useThemeContext();
  const { isMobile } = themeContext;

  const Bg = Background(backgroundStyle);
  const [showSwiper, setShowSwiper] = useState(true);

  /** 播放间隔选项 */
  useEffect(() => {
    setShowSwiper(false);
    setTimeout(() => {
      setShowSwiper(true);
    });
  }, [mobileAutoPlayInterval]);

  const { goodsList } = useCollection({
    initData: data,
    type: dataType || 'collection',
    kdtId,
    collection,
    grid,
    rows,
    productList,
    customerId: userInfo?.customerId,
  });

  useEffect(() => {
    if (goodsList.length) {
      buriedPoint('goods_view', {
        goods_component_location: 'HOME_simple-product',
        goods_component_location_name: '简约商品',
        goodsList: goodsList.map((item) => {
          // eslint-disable-next-line
          const { goodsId: goods_id, goodsType: goods_type = '', title } = item;
          return {
            goods_id,
            goods_type,
            title,
          };
        }),
      });
    }
  }, [goodsList.length]);

  const alignText =
    !dataType && settings.text_position && settings.text_display === 'superimposed'
      ? settings.text_position.split(' ')[0]
      : '';
  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Style
        className={cn(
          'simple-product-section',
          `cn-${boxWidth !== 'margin_80' && boxWidth !== 'full_screen' ? `fix_${boxWidth}` : boxWidth}`,
          elemClass,
        )}
      >
        <Wrapper
          paddingTop={boxWidth !== 'full_screen' ? 'vContainer' : ''}
          paddingBottom={boxWidth !== 'full_screen' ? 'vContainer' : ''}
        >
          <div className={cn('simple-product-box')}>
            {!(mobileDisplayTypeMainVal === 'carousel' && isMobile) && (
              <GoodsCollectionWithLayout
                goodsList={goodsList}
                initialData={initialData}
                settings={{
                  ...settings,
                  box_width: settings.container_width,
                  show_shopping_car: settings.show_product_cart_sku,
                  show_shopping_buy: settings.show_product_buy_btn,
                  product_title_align: alignText || settings.align_text,
                }}
                customGap={picPadding}
              />
            )}
            {isMobile && mobileDisplayTypeMainVal === 'carousel' && showSwiper && (
              <GoodsCollectionWithSwiper
                goodsList={goodsList}
                initialData={initialData}
                settings={{
                  ...settings,
                  box_width: settings.container_width,
                  show_shopping_car: settings.show_product_cart_sku,
                  show_shopping_buy: settings.show_product_buy_btn,
                  product_title_align: alignText || settings.align_text,
                }}
              />
            )}
          </div>
        </Wrapper>
      </Style>
    </Bg>
  );
};

export default SimpleGoodsList;
