import loadable from '@loadable/component';

export default [
  {
    path: '/distribution/dashboard',
    name: '/distribution/dashboard',
    canNotEditLayout: true,
    exact: true,
    hideHeader: true,
    component: loadable(() => import('../pages/distribution/dashboard')),
    pageType: '分销员中心',
  },
  {
    path: '/distribution/recruit',
    name: '/distribution/recruit',
    canNotEditLayout: true,
    exact: true,
    hideHeader: true,
    hideFooter: true,
    component: loadable(() => import('../pages/distribution/recruit')),
    pageType: '招募页',
  },
  {
    path: '/distribution/register',
    name: '/distribution/register',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/register')),
    pageType: '注册分销员',
  },
  {
    path: '/distribution/login',
    name: '/distribution/login',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/login')),
    pageType: '分销员登录',
  },
  {
    path: '/distribution/forget',
    name: '/distribution/forget',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/forget')),
    pageType: '忘记密码',
  },
  {
    path: '/distribution/reset',
    name: '/distribution/reset',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/reset')),
    pageType: '重置密码',
  },
  {
    path: '/distribution/import',
    name: '/distribution/import',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/reset')),
    pageType: '商家导入分销员',
  },
  {
    path: '/distribution/commission-bill',
    name: '/distribution/commission-bill',
    canNotEditLayout: true,
    hideHeader: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/commission-bill')),
    pageType: '佣金账单',
  },
  {
    path: '/distribution/bill-detail/:affiliateBillId/:orderQuantity/:orderTotalAmount/:totalSettledAmount',
    name: '/distribution/bill-detail',
    canNotEditLayout: true,
    exact: true,
    hideHeader: true,
    component: loadable(() => import('../pages/distribution/bill-detail')),
    pageType: '账单明细',
  },
  {
    path: '/distribution/me',
    name: '/distribution/me',
    canNotEditLayout: true,
    hideHeader: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/account')),
    pageType: '我的资料',
  },
  {
    path: '/distribution/transfer/:avTrack',
    canNotEditLayout: true,
    name: 'transfer',
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/transfer')),
    pageType: '分销中转页',
  },
  {
    path: '/distribution/rule/:affiliateId',
    canNotEditLayout: true,
    name: '/distribution/rule',
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/rule')),
    pageType: '分销规则页',
  },
  {
    path: '/design-demo',
    name: '/distribution',
    canNotEditLayout: true,
    hideHeader: true,
    exact: true,
    component: loadable(() => import('../pages/design/demo')),
    pageType: '装修基础组件的demo页',
  },
  {
    path: '/affiliate/dashboard',
    name: '/affiliate/dashboard',
    canNotEditLayout: true,
    exact: true,
    hideHeader: true,
    component: loadable(() => import('../pages/distribution/dashboard')),
    pageType: '分销员中心',
  },
  {
    path: '/affiliate/recruit',
    name: '/affiliate/recruit',
    canNotEditLayout: true,
    exact: true,
    hideHeader: true,
    hideFooter: true,
    component: loadable(() => import('../pages/distribution/recruit')),
    pageType: '招募页',
  },
  {
    path: '/affiliate/register',
    name: '/affiliate/register',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/register')),
    pageType: '注册分销员',
  },
  {
    path: '/affiliate/login',
    name: '/affiliate/login',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/login')),
    pageType: '分销员登录',
  },
  {
    path: '/affiliate/forget',
    name: '/affiliate/forget',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/forget')),
    pageType: '忘记密码',
  },
  {
    path: '/affiliate/reset',
    name: '/affiliate/reset',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/reset')),
    pageType: '重置密码',
  },
  {
    path: '/affiliate/import',
    name: '/affiliate/import',
    canNotEditLayout: true,
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/reset')),
    pageType: '商家导入分销员',
  },
  {
    path: '/affiliate/commission-bill',
    name: '/affiliate/commission-bill',
    canNotEditLayout: true,
    hideHeader: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/commission-bill')),
    pageType: '佣金账单',
  },
  {
    path: '/affiliate/bill-detail/:affiliateBillId/:orderQuantity/:orderTotalAmount/:totalSettledAmount',
    name: '/affiliate/bill-detail',
    canNotEditLayout: true,
    exact: true,
    hideHeader: true,
    component: loadable(() => import('../pages/distribution/bill-detail')),
    pageType: '账单明细',
  },
  {
    path: '/affiliate/me',
    name: '/affiliate/me',
    canNotEditLayout: true,
    hideHeader: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/account')),
    pageType: '我的资料',
  },
  {
    path: '/affiliate/transfer/:avTrack',
    canNotEditLayout: true,
    name: 'transfer',
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/transfer')),
    pageType: '分销中转页',
  },
  {
    path: '/affiliate/rule/:affiliateId',
    canNotEditLayout: true,
    name: '/affiliate/rule',
    hideHeader: true,
    hideFooter: true,
    exact: true,
    component: loadable(() => import('../pages/distribution/rule')),
    pageType: '分销规则页',
  },
];
