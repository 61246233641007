import styled from 'styled-components';
import { getSizeVw } from '../../../../../style/fn';

export default styled.div`
  .pay-safe {
    flex: 1;
    flex-direction: row-reverse;
    color: #48ab7c;
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  .header-layout {
    justify-content: space-between !important;
  }
  .absolute-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .slide-container-header {
    border-bottom: 0px !important;
    line-height: 1;
    padding-bottom: 16px;
  }
  .slide-container {
    background: ${(props) => props.subMenuBgColor} !important;
    color: ${(props) => props.subMenuTextColor} !important;
  }
  .slide-container .header-menu-wrapper {
    background-color: ${(props) => props.theme.hexToRGBA(props.subMenuTextColor, 0.04)} !important;
  }
  .slide-container-head-icon {
    fill: ${(props) => props.gColor} !important;
  }
  .zent-submenu-title-operate-icon,
  a {
    color: ${(props) => props.defaultTextIconColor} !important;
  }
  .zent-menu {
    color: ${(props) => props.defaultTextIconColor} !important;
    background: ${(props) => props.defaultBgColor} !important;
  }
  .header-icon {
    fill: ${(props) => props.gColor} !important;
  }
  .shop-name {
    color: ${(props) => props.gColor};
  }
  .zent-menu__inner {
    color: ${(props) => props.defaultTextIconColor} !important;
    background: ${(props) => props.defaultBgColor} !important;
  }

  .header-announcement {
    line-height: 24px;
    padding: 12px 0;
    background: #1a1a1b;
    text-align: center;
  }
  .sub-header-container {
    padding: 15px;
    position: relative;
  }
  .wrapper {
    font-size: 0;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }

  .mask {
    left: -0;
    top: 0px;
    z-index: 99;
    width: 100%;
    height: 200%;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
  }
  .menu-of-mobile {
    display: block;
  }
  .menu-list {
    display: none;
    margin-right: ${getSizeVw(80)};
  }
  .header-mobile-home {
    font-size: var(--base-font-16-14);
    cursor: pointer;
    font-weight: 600;
    line-height: 64px;
  }
  .header-mobile-first-menu {
    font-size: var(--base-font-16-14);
    font-weight: 400;
    line-height: 64px;
    padding: 0 16px;
  }

  .header-show-sub-menu-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .sub-menu-header {
    position: absolute;
    width: 100%;
    right: 0px;
    top: 0px;
  }

  .menu-back-header {
    text-align: left;
    .slide-container-head-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      fill: ${(props) => props.defaultTextIconColor} !important;
    }
    font-size: var(--font-18-14);
    font-weight: 400;
    color: ${(props) => props.defaultTextIconColor} !important;
  }

  /* .header-mobile-menu {
        .zenticon-right {
            color: var(--color_text) !important;
            font-size: var(--base-font-16-14) !important;
            font-weight: normal !important;
        }
        .zent-menu-item.zent-menu__inline-item {
            padding-left: 32px !important;
            line-height: 64px !important;
            height: 64px !important;
            font-weight: 400;
        }
        .zent-menu__inline-item-selected {
            background: none !important;
        }
        .zent-menu__inline-item-selected {
            background: none !important;
            font-weight: 600 !important;
            color: var(--color_text) !important;
        }
    } */
`;
