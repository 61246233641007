import styled from 'styled-components';

export const DialogTitleStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .close-icon {
    margin-top: -8px;
    margin-right: -12px;
    cursor: pointer;
  }
`;
