import React from 'react';
import cn from 'classnames';
import NewButton from 'components/base/new-button';
import { TabType } from 'const/account';
import { Style } from './style';
import { View, Icon } from 'components/base';
import { Background } from '../background';
import { useRootContext } from 'client/pages/context/root';
import { openRegisterDialog } from 'components/page/register-dialog';
import { useIntl } from 'react-intl';

// 未登陆的时候
function UnloginModule(props) {
  const { settings, intl } = props;
  const { setLoginDialogInfo, theme, withMobileLogin } = useRootContext();
  const {
    title,
    text,
    color_button_bg: colorButtonBg,
    color_button_text: colorButtonText,
    title_size: titleSize,
    text_size: textSize,
    title_color: titleColor,
    text_color: textColor,
  } = settings;

  return (
    <>
      <View className='login-title' size={titleSize} color={titleColor}>
        {title}
      </View>
      <View className='login-text' size={textSize} color={textColor} dangerouslySetInnerHTML={{ __html: text }} />
      <div className='login-operate'>
        <NewButton
          type='primary'
          size='small'
          bgColor={colorButtonBg}
          color={colorButtonText}
          onClick={(e) => {
            e.preventDefault();
            setLoginDialogInfo({
              visible: true,
            });
          }}
        >
          {intl.formatMessage({
            id: 'account.login',
            defaultMessage: '登录',
          })}
        </NewButton>
        <NewButton
          type='primary'
          size='small'
          bgColor={colorButtonBg}
          color={colorButtonText}
          onClick={(e) => {
            e.preventDefault();
            openRegisterDialog({
              theme,
              intl,
              withMobileLogin,
            });
          }}
        >
          {intl.formatMessage({
            id: 'account_register',
            defaultMessage: '注册',
          })}
        </NewButton>
      </div>
    </>
  );
}
// 已登陆的时候
function LoginedModule(props) {
  const { settings, intl, userInfo } = props;

  const {
    color_button_bg: colorButtonBg,
    color_button_text: colorButtonText,
    text_size: textSize,
    text_color: textColor,
    show_avatar_after_login: showAvatarAfterLogin,
  } = settings;
  return (
    <>
      {showAvatarAfterLogin && <Icon size='large' name='iconic-touxiang' className='user-avatar' />}
      <View size={textSize} color={textColor} className='login-user-name skiptranslate'>
        Hi! {userInfo.firstName} {userInfo.lastName}
      </View>
      <div className='login-operate'>
        <NewButton type='primary' size='small' bgColor={colorButtonBg} color={colorButtonText} href='/me'>
          {intl.formatMessage({
            id: 'general.order',
            defaultMessage: '订单',
          })}
        </NewButton>
        <NewButton
          type='primary'
          size='small'
          bgColor={colorButtonBg}
          color={colorButtonText}
          href={`/me?tabId=${TabType.Wish}`}
        >
          {intl.formatMessage({
            id: '04aa02460ca54c6c9addc40b06e67fbc',
            defaultMessage: '心愿单',
          })}
        </NewButton>
      </div>
    </>
  );
}

function LoginRegisterModule(props) {
  console.log('LoginRegisterModule props', props);
  const { settings = {}, elemClass, initialData } = props;
  const { userInfo = {} } = initialData;
  const intl = useIntl();
  const {
    background_color: backgroundColor,
    background_image: backgroundImage,
    background_style: backgroundStyle,
  } = settings;

  const Bg = Background(backgroundStyle);

  return (
    <Style className={cn(elemClass)} style={props.style}>
      <Bg className='login-bg' backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
        {!userInfo?.customerId && <UnloginModule settings={settings} intl={intl} initialData={initialData} />}
        {userInfo?.customerId && <LoginedModule settings={settings} intl={intl} userInfo={userInfo} />}
      </Bg>
    </Style>
  );
}

export default LoginRegisterModule;
