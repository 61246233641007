import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import shopApi from 'api/shop';
import cn from 'classnames';
import { Style } from './style';
import { hexToRGBA } from 'utils/color';
import Image from 'components/base/image';
import CustomPaymentIcons from './custom-payment-icons';
import { PackageCode, PackageCodeSorted } from 'const/shop';

/* 
const PAYMENT_ICON = {
    VISA: 'https://b.yzcdn.cn/public_files/491686416c6030a4ff61a24bddb66b95.png',
    AMEX: 'https://b.yzcdn.cn/public_files/b81d537271b6c0fadec11287f60096b1.png',
    Stripe: 'https://img01.yzcdn.cn/upload_files/2021/02/26/FiXj4n7fYLMY_wkMjoHhKPzfPmDn.png',
    PayPal: 'https://b.yzcdn.cn/public_files/13ced979c7b7041725f4b9424ce6005e.png',
};

const StripeCardImgList = [
    'https://b.yzcdn.cn/AmericanExpress@3x.png',
    'https://b.yzcdn.cn/ChinaUnionpay@3x.png',
    'https://b.yzcdn.cn/DiscoverDiners@3x.png',
    'https://b.yzcdn.cn/JapanCreditBureau@3x.png',
    'https://b.yzcdn.cn/Mastercard@3x.png',
    'https://su.yzcdn.cn/Visa@3x.png',
];
*/

const FooterBottom = ({ settings = {}, style, initialData = [], mobileDeviceFooterTextAlign }) => {
  const { show_dividing_line: showDividingLine, show_payment_icons: showPaymentIcons } = settings;

  const { kdtId } = initialData;

  const [paymentList, setPaymentList] = useState([]);
  const currentVersionIndex = PackageCodeSorted.indexOf(initialData?.packageInfo?.versionCode);
  const needVersionIndex = PackageCodeSorted.indexOf(PackageCode.ULTIMATE);
  // 115099419 是之前产品要求添加的特殊逻辑
  const isUltimate = currentVersionIndex >= needVersionIndex || initialData?.packageInfo?.kdtId === 115099419;
  const isNotUltimate = !isUltimate;

  // 是否是就店铺的逻辑
  const judgeTime = 1629355295257; // 2021-08-19 下午3点
  const isOldShop = initialData?.shopBasicInfo?.createdAt <= judgeTime;

  // console.log('isNotUltimate', isNotUltimate);
  // console.log('isOldShop', isOldShop);
  // console.log('initialData?.global_data?.shopBasicInfo',initialData?.shopBasicInfo);

  const getTailPowerdByAllValueText = () => {
    if (isOldShop) return '';
    if (!isOldShop && isNotUltimate) return 'Powered by AllValue';
    return '';
  };

  const tailText = getTailPowerdByAllValueText();
  // console.log('tailText',tailText);

  const emptyCopyRightText = () => {
    const year = new Date().getFullYear();
    const shopName = get(initialData, 'shopSetting.name', '');
    return `© ${year} ${shopName} Powered by AllValue`;
  };
  const formateResult = (result) => {
    if (typeof result !== 'string') {
      if (!result) {
        return '';
      } else {
        try {
          return result[initialData?.shopSetting?.language || 'en'] || '';
        } catch (e) {
          console.log('e', e);
          return '';
        }
      }
    } else {
      return result;
    }
  };
  const getCopyRightText = () => {
    const result = (() => {
      const year = new Date().getFullYear();
      const shopName = get(initialData, 'shopSetting.name', '');
      if (settings?.footer_copyright) {
        return `${year} ${shopName} ${settings?.footer_copyright}`;
      }
      // 一次都没有保存过，只是预览，settings中就没有这个字段
      if (!settings.hasOwnProperty('footer_copyright_v2')) {
        return `${year} ${shopName}`;
      }
      return settings?.footer_copyright_v2;
    })();
    if (isOldShop) {
      return formateResult(result);
    } else {
      if (isNotUltimate) {
        // 采用新逻辑 之前所有的如果有 powered by all value 直接替换成 ''
        // 处理服务端报错 result.replace is not a function
        // 还有一个报错 Objects are not valid as a React child  (found: object with keys {zh-CN, en, ja, zh-TW}).
        // 应该是之前的脏数据
        if (typeof result !== 'string') {
          if (!result) {
            return '';
          } else {
            try {
              return result[initialData?.shopSetting?.language || 'en'] || '';
            } catch (e) {
              console.log('e', e);
              return '';
            }
          }
        } else {
          return result?.replace('Powered by AllValue', '') || '';
        }
      }
      return formateResult(result);
    }
  };
  const copyRightText = getCopyRightText();

  const getAllPaymentGateways = () => {
    return shopApi
      .getPaymentGatewaysByCategory({ kdtId })
      .then((res) => {
        // 仅保留唯一的自定义图标
        let payIconList = [];
        res.forEach((item) => {
          if (item?.paymentCategory === 'PAYPAL') {
            payIconList.push({
              logoUrl: item?.logoUrl,
              paymentMethodName: 'PAYPAL',
            });
          } else {
            payIconList = payIconList.concat(item?.allPaymentMethods);
          }
        });
        setPaymentList(payIconList);
      })
      .catch(() => {
        setPaymentList([]);
      });
  };

  useEffect(() => {
    getAllPaymentGateways();
  }, []);

  const paymentBorderColor = useMemo(() => {
    return hexToRGBA(style?.color, 0.2);
  }, [style?.color]);

  return (
    <Style mobileDeviceFooterTextAlign={mobileDeviceFooterTextAlign}>
      <div
        className={cn(
          'footer-bottom-section',
          'footer-local-info-container',
          `show-dividing-line-${showDividingLine}`,
          `show-payment-icons-${showPaymentIcons}`,
        )}
      >
        <div className='footer-menu-section-border-bottom' style={{ borderBottomColor: style?.color }} />
        <div className={cn(`footer-company-info`)} style={style}>
          {showPaymentIcons && !!paymentList?.length && (
            <div className='footer-payment-icons'>
              {paymentList.map((item, index) => {
                const image = item?.logoUrl;
                const title = item?.paymentMethodName || '';
                return (
                  <span
                    className='footer-payment-icons-item'
                    style={{ borderColor: paymentBorderColor }}
                    key={image + index}
                  >
                    <Image src={image} alt={title} title={title} />
                  </span>
                );
              })}
            </div>
          )}
          {copyRightText && (
            <p className={cn('footer-support-by', 'notranslate')}>
              {' '}
              © {copyRightText} {tailText}
            </p>
          )}
          {!copyRightText && isNotUltimate && (
            // 如果这个copyRightText空且非旗舰版 那么显示默认值
            <p className={cn('footer-support-by', 'notranslate')}>{emptyCopyRightText()}</p>
          )}
        </div>
      </div>
    </Style>
  );
};

export default FooterBottom;
