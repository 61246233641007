// 巧克力组件
import React, { useRef, useEffect } from 'react';
import { formateCamelcase } from 'utils/formate';
import { useClientWidth, useThemeContext } from 'hook/global-theme';

import cn from 'classnames';
import throttle from 'lodash/throttle';
import { Style } from './style';
import useDeepCompareEffect from 'use-deep-compare-effect';
import SwiperContainer from '../../components/swiper-container';
import ImageContainer from '../../components/image-container';
import TextContainer from '../../components/text-container';
import TextPosition from '../../components/text-position';

const Chocolate = (props) => {
  const {
    blocks: blocksOuter,
    block_order,
    elemClass,
    settings: {
      text_position: textPosition,
      align_text: alignText,
      product_image_size: productImageSize,
      compress = true,
    },
  } = props;
  console.log(':::::::::', props);
  const settimeoutid = null;
  // 转换props，setting数据成该组件需要的格式
  const data = { topList: [], bottomList: [], centerList: [], textList: [] };
  block_order?.forEach((item) => {
    // 转换每一组的数据
    const { block_order, block_order_two, blocks, blocks_two, settings } = blocksOuter[item] || {};
    // 转换文本数据
    const textContainerProps = { blocks: [], buttonLabelAlign: alignText };
    block_order_two?.forEach((textBtnKey) => {
      let newBlock2 = blocks_two[textBtnKey];
      newBlock2 = formateCamelcase(newBlock2);
      const settings = newBlock2?.settings;
      // 按钮链接地址需要转换
      if (newBlock2.type === 'btn') {
        const btnLink = settings?.btnLink || '';
        if (btnLink) {
          settings.btnLink = JSON.parse(btnLink).url;
        }
      }
      textContainerProps.blocks.push({ type: newBlock2.type, ...newBlock2.settings });
    });
    data.textList.push({
      type: 'dom',
      dom: (
        <TextPosition position={textPosition} style={{ height: '100%' }}>
          <TextContainer {...textContainerProps} />
        </TextPosition>
      ),
    });
    // 转换上下图片数据
    block_order?.forEach((imagsKey, index) => {
      const setting = blocks[imagsKey]?.settings || {};
      const obj = {
        type: 'img',
        src: setting.background_style === 'background_image' ? setting.background_image : '',
        backgroundColor: setting.background_color,
        showOverlay: setting.show_mask,
        overlayColor: setting.mask_color,
        picScroll: setting.pic_scroll,
      };
      index === 0 ? data.topList.push(obj) : data.bottomList.push(obj);
    });
    // 中间图数据转换
    data.centerList.push({
      type: 'img',
      src: settings.product_image,
    });
  });

  const getSwiperContentByImagesData = (obj) => {
    let returnObj = {};

    returnObj = {
      type: 'dom',
      dom: obj.src ? (
        <ImageContainer
          heightType='inherit'
          showOverlay={obj.showOverlay}
          overlayColor={obj.overlayColor}
          fit='cover'
          position={obj.position}
          url={obj.src}
          compress={compress}
        />
      ) : (
        <div className='img' style={{ backgroundColor: obj.backgroundColor }} />
      ),
    };
    return returnObj;
  };
  // 获取top，bottom swiper的内容格式
  let bottomSwiperContent = [];
  let topSwiperContent = [];
  const getTopAndBottomSwiperContents = () => {
    // 根据数据转成swiper内容
    const arrayTop = [];
    const arrayButtom = [];
    data.bottomList.forEach((item, index) => {
      arrayButtom.push(getSwiperContentByImagesData(item));
      const topItem = data.topList[index];
      arrayTop.push(getSwiperContentByImagesData(topItem));
    });
    topSwiperContent = arrayTop;
    bottomSwiperContent = arrayButtom;
  };
  getTopAndBottomSwiperContents();

  // 跟sticky布局相关的ref
  const areaStageRef = useRef();
  const areaContentRef = useRef();
  // 几个轮播器的ref
  const swiperTopRef = useRef();
  const swiperBottomRef = useRef();
  const swiperCenterRef = useRef();
  const swiperTextRef = useRef();
  // 异部函数拿不到最新值，用proxyRef中转
  const { isMobile } = useThemeContext();
  const proxyRef = useRef();
  const { clientHeight, clientWidth } = useClientWidth();
  proxyRef.current = {
    clientHeight,
    clientWidth,
    areaStageHeight: clientHeight * data.topList.length,
    topList: data.topList,
    bottomList: data.bottomList,
  };

  // dom:需要动画处理的dom
  // speed:每次移动的像素
  // topPosition,bottomPosition:极限的2位置
  // position：当前位置
  // type:top|bottom,指定处理的是top的图，还是bottom的图
  // index：索引值，type和index控制要不要继续执行动画
  const animationFrame = (dom, speed, topPosition, bottomPosition, position, type, index) => {
    position += speed;
    // 判断当前位置是否超出界限，超出则反向移动
    if (position > topPosition) {
      position = topPosition;
      speed = -speed;
    } else if (position < bottomPosition) {
      position = bottomPosition;
      speed = -speed;
    }
    const list = type === 'top' ? proxyRef.current.topList : proxyRef.current.bottomList;
    if (list[index]?.picScroll && topPosition - bottomPosition >= 40) {
      dom.style.objectPosition = `center ${position}px`;
      dom.animationFrame = requestAnimationFrame(() =>
        animationFrame(dom, speed, topPosition, bottomPosition, position, type, index),
      );
    } else {
      // 使用这个一定要注意清除！！！！！这是个大坑
      dom.animationFrame && cancelAnimationFrame(dom.animationFrame);
      dom.style.objectPosition = `center center`;
    }
  };
  // type:top|bottom,指定处理的是top的图，还是bottom的图
  const dealImgScroll = (type) => {
    // 处理图片的滑动动效
    const dom = type === 'top' ? swiperTopRef.current : swiperBottomRef.current;
    const imgArray = dom?.getElementsByClassName('img') || [];
    Array.from(imgArray)?.forEach((item, index) => {
      // 方案1：js控制animation，但是通过计算得出的动画时间，在不同电脑上效果差别大，手机端还不能播放
      // 方案2:js requestAnimationFrame，控制每侦移动背景图一定位置,使用这个一定要注意清除！！！！！这是个大坑
      // 采取方案2

      item.loadFun = () => {
        // 背景图最bottom位置 = 图片高/宽比*图片容器宽度 - 图片容器高度
        const bottomHeight = (item.naturalHeight / item.naturalWidth) * clientWidth - clientHeight / 2;

        item.animationFrame = requestAnimationFrame(() => animationFrame(item, 0.3, 0, -bottomHeight, 0, type, index));
      };
      if (item.complete) {
        item.loadFun();
      } else {
        item.addEventListener('load', item.loadFun);
      }
    });
    return () => {
      // 清除cancelAnimationFrame，使用这个一定要注意清除！！！！！这是个大坑
      Array.from(imgArray)?.forEach((item) => {
        if (item.loadFun) {
          item.removeEventListener('load', item.loadFun);
        }
        if (item.animationFrame) {
          cancelAnimationFrame(item.animationFrame);
        }
      });
    };
  };
  useDeepCompareEffect(() => {
    return dealImgScroll('bottom');
  }, [data.bottomList]);
  useDeepCompareEffect(() => {
    return dealImgScroll('top');
  }, [data.topList]);

  // 这里也是埋了一个坑啊！！！装修时切换屏幕模式，页面布局可能没有实时更新
  // 加了updateSize，依然会偶现！！！
  useEffect(() => {
    swiperTopRef?.current?.swiper?.update();
    swiperBottomRef?.current?.swiper?.update();
    swiperCenterRef?.current?.swiper?.update();
    swiperTextRef?.current?.swiper?.update();
    const return1 = dealImgScroll('top');
    const return2 = dealImgScroll('bottom');
    return () => {
      return1 && return1();
      return2 && return2();
    };
  }, [clientWidth, clientHeight]);
  useEffect(() => {
    if (swiperCenterRef.current) {
      swiperCenterRef.current.swiper.update();
    }
  }, [productImageSize]);
  // 下面是处理轮播图滚动动效相关
  // 滚动事件处理
  // 尝试滚动动效方案
  // 1.sticky+根据滚动条的位置范围，决定当前展示的轮播图组。。。。缺点：一段滚动区域对应一个轮播组，当滚动动作很小没有跨域这段范围，不会切换轮播图
  // 2.sticky+根据滚动的事件次数，决定轮播切换的动作。。。缺点：每次滚动的动作大小不可控，有的滚很短的距离，有的滚很大的距离，可能轮播图没完全切换完成，已经滚到了其他组件；或者轮播图都切换完了，离下一个组件还剩好一段距离
  // 3.页面展示当前组件时，隐藏body滚动条，组件轮播完成再显示body滚动条。。。缺点：隐藏滚动条后没有滚动事件了，也就没有触发轮播的事件;并且隐藏滚动条的时机是该组件填满屏幕，而没有sticky布局，用户的操作满足不了这个条件
  // 4.在第2点的基础上做优化，a.对于轮播图切完，滚动条还没到下一个组件：滚完时强制设置页面滚动条的位置；b.轮播图还没切完，已经滚到其他组件：加大area-stage的高度..缺点：强制移动滚动条位置很突兀
  // 5.在4的基础上做优化，每次切轮播的时候都控制下滚动条位置，而不是等切完才控制，scrollTo与默认的滚动之间可能会冲突，导致失效
  // 6.最后还是建议采用方案1!!!
  const handleScroll = (e) => {
    const { areaStageHeight, topList } = proxyRef.current;
    const areaStageTop = areaStageRef.current.getBoundingClientRect().top;
    // 得到当前应该展示的轮播索引
    let index = Math.round(areaStageTop / (-areaStageHeight / topList.length));
    if (index <= 0) {
      index = 0;
    }
    if (index >= topList.length - 1) {
      index = topList.length - 1;
    }
    swiperTopRef?.current?.swiper?.slideTo(index);
    swiperBottomRef?.current?.swiper?.slideTo(index);
    swiperTextRef?.current?.swiper?.slideTo(index);
    swiperCenterRef?.current?.swiper?.slideTo(index);
  };
  const handleScrollThrottle = throttle(handleScroll, 500); // 600

  useEffect(() => {
    // 滚动事件处理
    window.addEventListener('scroll', handleScrollThrottle, {
      passive: false,
    });
    return () => {
      window.removeEventListener('scroll', handleScrollThrottle, {
        passive: false,
      });
    };
  }, []);

  return (
    <Style className={cn('chocolate-section', elemClass, { 'is-mobile': isMobile })}>
      <div className='area-stage' ref={areaStageRef} style={{ height: `${proxyRef.current.areaStageHeight}px` }}>
        <div className='area-content' ref={areaContentRef}>
          <div className='chocolate-top'>
            <SwiperContainer
              ref={swiperTopRef}
              effect='fade'
              className='swiper'
              contents={topSwiperContent}
              autoPlay={false}
              allowTouchMove={false}
              width='100%'
              height='100%'
              compress={compress}
            />
          </div>
          <div className='chocolate-bottom'>
            <SwiperContainer
              ref={swiperBottomRef}
              direction='vertical'
              className='swiper'
              contents={bottomSwiperContent}
              autoPlay={false}
              allowTouchMove={false}
              width='100%'
              height='100%'
              compress={compress}
            />
          </div>
          <div className={cn('chocolate-center', productImageSize)}>
            <SwiperContainer
              ref={swiperCenterRef}
              direction='vertical'
              className='swiper'
              contents={data.centerList}
              autoPlay={false}
              allowTouchMove={false}
              width='100%'
              height='100%'
              compress={compress}
            />
          </div>
          <div className='chocolate-text'>
            <SwiperContainer
              ref={swiperTextRef}
              direction='vertical'
              className='swiper'
              contents={data.textList}
              autoPlay={false}
              allowTouchMove={false}
              width='100%'
              height='100%'
              compress={compress}
            />
          </div>
        </div>
      </div>
    </Style>
  );
};

export default Chocolate;
