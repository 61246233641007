import { useState, useEffect } from 'react';
import groupBuyingApi from 'api/group-buying';
import { useIntl } from 'react-intl';

export const useGroupBuyingList = (rows, grid, kdtId) => {
  const intl = useIntl();

  const [totalList, setTotalList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const getRenderGoodsList = () => {
    const list = [];
    for (let i = 0; i < 2; i += 1) {
      list.push({
        title: intl.formatMessage({
          id: '61cd295703424be8b6dd6ea4eaf51bba',
          defaultMessage: '你的商品名称',
        }),
        discountOff: 100,
        minPrice: 0,
        participateNum: 0,
      });
    }
    setGroupList(list);
  };

  useEffect(() => {
    if (!kdtId) return;

    const params = {
      page: 1,
      pageSize: 20, // 目前默认最大只会展示20条
    };

    groupBuyingApi.searchGroupActivityGoodsWithPage(params).then((res) => {
      setTotalList(res?.items ?? []);
    });
  }, [kdtId]);

  /** 根据配置行数获取商品数量，出于性能优化，默认获取最大数量20，配置变动时进行剪贴 */
  useEffect(() => {
    if (!totalList?.length) {
      getRenderGoodsList();
      return;
    }
    /** pageSize指需要请求的商品数量：小卡片模式是一行2个商品，其他模式是一行一个商品 */
    const pageSize = rows * grid;

    setGroupList(totalList.slice(0, pageSize));
  }, [grid, totalList, rows]);

  return {
    groupList,
    totalList,
    pageSize: 20,
  };
};
