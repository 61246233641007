export enum DiscountType {
  FreeShipping = 'freeShipping', // 免运费
  FixedAmount = 'fixedAmount', // 固定金额优惠
  Percentage = 'percentage', // 折扣
  Bxgy = 'bxgy', // 买X送Y
}

/** 折扣条件限制类型 */
export enum DiscountLimitType {
  Amount = 'amount', // 金额
  Quantity = 'quantity', // 数量
}

/**
 * 折扣应用结果
1：已满足折扣，2：提示去凑单，3：提示选赠品，0：应用结果为空
*/
export enum ApplyDiscountStatus {
  Fit = 1,
  NotFit = 2,
  SelectGift = 3,
  Fail = 0,
}

export enum DiscountStorageName {
  openDiscountModal = 'openDiscountModal',
}

// 客户限制
export enum DISCOUNT_LIMIT_FREQUENCY {
  everyDay = 'every_day',
  cycle = 'cycle',
}
