import styled, { createGlobalStyle } from 'styled-components';

export const Style = styled.div`
  .form-title {
    display: flex;
    padding: 3px 0;
    font-size: var(--form_name_size);
    color: var(--form_title_color);
    zoom: var(--form_title_scale);
    &.require::before {
      color: #d40000;
      color: var(--theme-error-2, #d40000);
      content: '*';
      display: inline-block;
      font-size: 16px;
      margin-right: 6px;
    }
    &.has-require {
      margin-left: 14px;
    }
  }
  .form-has-require {
    padding-left: 12px;
    .form-desc {
      padding-left: 18px;
      font-size: 12px;
      padding: 2px 0;
      color: rgba(var(--form_title_color_rgb), 0.6);
    }
  }
  .form-content {
    padding-bottom: 20px;
    .form-container {
      padding-top: 16px;
      color: var(--form_title_color);
      &.form-direction-portrait {
        .zent-checkbox-group,
        .zent-radio-group {
          display: flex;
          gap: 20px;
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &.form-direction-transverse {
        .zent-checkbox-group,
        .zent-radio-group {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          align-items: center;
        }
      }
      .zent-radio-wrap {
        margin-right: 0;
        display: flex;
        align-items: flex-start;
        .zent-radio {
          height: 20px;
          display: flex;
          align-items: center;
        }
        .zent-radio-inner {
          border-color: var(--form_border_color) !important;
          background-color: var(--form_bg_color);
        }
        &.zent-radio-checked > .zent-radio {
          .zent-radio-inner {
            border-color: var(--form_select_color) !important;
          }
          .zent-radio-inner::after {
            background-color: var(--form_select_color) !important;
          }
        }
      }
      .zent-checkbox-wrap {
        margin-right: 0;
        display: flex;
        align-items: flex-start;
        .zent-checkbox {
          height: 20px;
          display: flex;
          align-items: center;
        }
        .zent-checkbox-label {
          font-weight: var(--font-weight-body) !important;
        }
        .zent-checkbox-inner {
          border-color: var(--form_border_color) !important;
          background-color: var(--form_bg_color);
        }
        &.zent-checkbox-checked > .zent-checkbox {
          .zent-checkbox-inner {
            border-color: var(--form_select_color) !important;
            background-color: var(--form_select_color) !important;
          }
          .zent-checkbox-inner::after {
            background-color: var(--form_select_color) !important;
          }
        }
      }
      .zent-select .zent-select-text {
        border-color: var(--form_border_color) !important;
        background-color: var(--form_bg_color) !important;
        color: var(--form_title_color) !important;

        .zent-select-placeholder {
          color: rgba(var(--form_title_color_rgb), 0.6);
        }
      }
      .zent-input-wrapper {
        margin-top: 8px;
        border: 1px solid var(--form_border_color, #cecfd7);
        background: var(--form_bg_color, #fbfbfb);
        .zent-input {
          width: 100%;
          background: var(--form_bg_color, #fbfbfb);
          color: var(--form_title_color) !important;
          &::placeholder {
            color: rgba(var(--form_title_color_rgb), 0.6) !important;
          }
        }
      }
      .zent-select {
        width: 320px;
        max-width: 100%;
      }
      .zent-select + .zent-input-wrapper {
        width: 320px;
        max-width: 100%;
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
    .form-option_select-popup {
      width: 320px !important;
      .zent-select-option {
        white-space: break-spaces !important;
      }
    }
`;
