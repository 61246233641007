/* eslint-disable prettier/prettier */
/** 获取商品首图 */
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_PRODUCT_IMAGE } from 'const/default';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import pick from 'lodash/pick';
import { MEDIA_TYPE } from 'const/';

export const getFirstImgUrl = (mainMedia) => {
  if (!isEmpty(mainMedia)) {
    mainMedia = mainMedia[0];
  }
  if (mainMedia?.type === MEDIA_TYPE.VIDEO) {
    return mainMedia.coverUrl;
  }
  return mainMedia?.url || mainMedia?.coverUrl || DEFAULT_PRODUCT_IMAGE;
};

/**
 * 匹配是不是当前规格，若是返回当前规格
 * 规格详情信息
 * 当前选中 规格sku
 */
const getSelectedVariant = ({ variant, sku }) => {
  const titles = variant?.optionValues?.map((item) => item.title).join('') || ''; // ['S', '白色']
  // let flag = 0;
  // sku.forEach((item) => {
  //   const isExist = titles && titles.indexOf(item) !== -1;
  //   if (isExist) {
  //     flag += 1;
  //   } else {
  //     flag -= 1;
  //   }
  // });
  // 相等时说明匹配到了
  // if (flag === sku.length) {
  //   flag = 0;
  //   return true;
  // }
  // return false;
  return titles === sku?.join('');
};

/**
 * 根据选中的sku相关信息计算价格
 * @param {*} sku 当前选中的sku ['白色', 'S']
 * @param {*} goodsDetail 当前商品信息: skuTitle
 * variants 所有规格组合
 * {
 *   variantId:'111',
 *   optionValues: [{
 *      optionTitle: "尺寸",
 *      title: "S"
 *    }, {
 *      optionTitle: "颜色",
 *      title: "白色"
 *    }]
 * }
 */
export const getSkuInfo = ({ sku, goodsDetail = {} }) => {
  const { variants = [], media } = goodsDetail;
  const isSelectedComplete = sku?.length === goodsDetail?.options?.length; // 是否选择了所有可选选项
  const keys = [
    'variantId',
    'price',
    'imageUrl',
    'requiresShipping',
    'compareAtPrice',
    'discountInfo',
    'bestPriceInfo',
    'memberInfo',
  ];
  let variantInfo = {};
  if (!isSelectedComplete) {
    return {};
  }
  variants.forEach((variant) => {
    const isMatch = getSelectedVariant({ variant, sku });
    if (isMatch) {
      variantInfo = {
        ...pick(variant, keys),
        imageUrl: variant.imageUrl || getFirstImgUrl(media),
        inventory: variant.quantity,
      };
    }
  });
  return variantInfo;
};

export const checkIsGoodSoldOut = (skuList = []) => {
  const hasQ = skuList.find(s => s.quantity > 0)
  return !hasQ;
  // return (
  //   skuList.reduce((result, skuItem) => {
  //     return result + skuItem.quantity;
  //   }, 0) === 0
  // );
};

export const getMedia = (productMedia, cartVal, variantList = []) => {
  const currentVariant = find(variantList, { variantId: cartVal?.variantId });
  if (currentVariant?.imageUrl) {
    return [
      {
        type: 'IMAGE',
        url: currentVariant.imageUrl,
      },
    ];
  }

  return productMedia;
};

export const getProductMedia = (mediaList, variantList) => {
  const variantMediaList = (variantList || [])
    .filter(({ imageUrl }) => imageUrl)
    .map(({ imageUrl, variantId }) => ({
      url: imageUrl,
      type: 'IMAGE',
      mediaId: variantId,
      variantId,
    }));

  return (mediaList || [])
    .map((item, index) => ({
      ...item,
      mediaId: item.mediaId || index,
    }))
    .concat(variantMediaList);
};

export const getVariantMediaIndex = (cartVal, mediaList) => {
  return findIndex(mediaList, { mediaId: cartVal?.variantId });
};

/**
 *  获取当前页商品详情页装修数据
 */
export const getPageDecorationData = (curThemeData = {}, isMobile) => {
  const { settings = {}, block_order: blockOrder = [], blocks = [] } = curThemeData; // 设置页面装修数据
  // 获取基础信息配置
  const baseConfig = blocks?.[blockOrder?.[0]]?.settings || {};

  /**
   * 手机端只用style1 ！！！
   * 其他的配置但是其他的配置还是按用户选择的样式来，所以取baseConfigInfo还是用baseConfigInfo.style
   */
  const baseImageStyle = isMobile ? 'style1' : baseConfig?.style || 'style1';
  const baseConfigInfo = baseConfig?.settings?.[baseConfig?.style];

  // 获取详细信息的配置
  const detailConfigInfo = blocks?.[blockOrder?.[1]]?.settings || {};

  return {
    baseConfig,
    baseImageStyle,
    baseConfigInfo,
    detailConfigInfo,
    globalSetting: settings,
    socialShareData: {
      share_button_color: baseConfigInfo?.share_button_color,
      share_button_custom: baseConfigInfo?.share_button_custom,
      share_button_shape: baseConfigInfo?.share_button_shape,
      show_share_button: baseConfigInfo?.show_share_button,
    },
  };
};

export const formateGoodsSale = (sale: number): string | number => {
  if (sale >= 1000000) {
    return '9M+';
  }
  if (sale >= 1000 && sale < 1000000) {
    let result = (sale / 1000).toFixed(1);
    if (result.endsWith('0')) {
      // 1.0K -> 1k
      result = Number(result).toFixed(0);
    }
    return `${result}k+`;
  }
  if (sale > 999) {
    return '999+';
  }
  return sale;
};

export const formatGoodsFieldName = (goodsList) => {
  return goodsList.map((item) => {
    const newItem = { ...item };
    newItem.inventory = item.quantity;
    delete newItem.quantity;
    newItem.variants = item?.variants?.map((variant) => {
      const newVariant = { ...variant };
      newVariant.inventory = item.quantity;
      delete newVariant.quantity;
      return newVariant;
    });
    return newItem;
  });
};

export const getGoodsSkuUrl = ({ item, cart, selectedSkuList, goodsDetail }) => {
  const newSku = cart.sku;
  const selectSku = newSku.filter((i) => !!i);
  const isSelectedComplete = selectSku?.length === goodsDetail?.options?.length; // 是否选择了完整的规格
  let url = '';
  // 图文组合类型的值
  let variantImageTextOptionUrl = '';

  if (item.typeStr === 'image_text' && item?.variantImageTextOptionValue?.medias?.[0]?.url) {
    variantImageTextOptionUrl = item?.variantImageTextOptionValue?.medias?.[0]?.url;
  }

  if (!isSelectedComplete) {
    if (variantImageTextOptionUrl) {
      url = variantImageTextOptionUrl;
    } else {
      const { variants, withVariants } = goodsDetail;
      if (!withVariants) return '';
      let variantImageTextOptionUrl = '';

      variants.forEach((variantsItem) => {
        const { optionValues, imageUrl } = variantsItem;
        if (!url && optionValues && optionValues.length) {
          optionValues.forEach((optionItem) => {
            // isHasImageTextUrl =
            // 获取到图文的图
            if (
              optionItem.typeStr === 'image_text' &&
              optionItem?.variantImageTextOptionValue?.medias?.[0]?.url &&
              optionItem?.title === item?.title
            ) {
              variantImageTextOptionUrl = optionItem?.variantImageTextOptionValue?.medias?.[0]?.url;
            }
          });
          if (variantImageTextOptionUrl) {
            url = variantImageTextOptionUrl;
          } else {
            selectedSkuList.forEach((selectedItem) => {
              if (
                selectedItem.typeStr === 'image_text' &&
                selectedItem?.variantImageTextOptionValue?.medias?.[0]?.url
              ) {
                url = selectedItem?.variantImageTextOptionValue?.medias?.[0]?.url;
                // url = variantImageTextOptionUrl;
              }
            });
            // console.log('selectedSkuList', selectedSkuList);
            // debugger;
            // url = isHasImageTextUrl;
          }
        }
      });
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (goodsDetail?.options?.length === 1) {
      url = variantImageTextOptionUrl;
    } else if (!url) {
      // 是否可以匹配到图片 sku的图片
      const { variants = [] } = goodsDetail;
      variants.forEach((variant) => {
        const isMatch = getSelectedVariant({ variant, sku: cart.sku });
        if (isMatch) {
          url = variant.imageUrl;
          if (!variant.imageUrl) {
            variant.optionValues.forEach((subOptionValues) => {
              if (
                subOptionValues.typeStr === 'image_text' &&
                subOptionValues?.variantImageTextOptionValue?.medias?.[0]?.url
              ) {
                variantImageTextOptionUrl = subOptionValues?.variantImageTextOptionValue?.medias?.[0]?.url;
              }
            });
            url = variantImageTextOptionUrl;
          }
          //
        }
      });
      if (!url) {
        // 没匹配到sku 的图片 使用 图文选项的
        selectedSkuList.forEach(
          (subItem: { typeStr: string; variantImageTextOptionValue: { medias: { url: string }[] } }) => {
            if (subItem.typeStr === 'image_text' && subItem.variantImageTextOptionValue?.medias?.[0]?.url) {
              url = subItem.variantImageTextOptionValue?.medias?.[0]?.url;
            }
          },
        );
      }
    }
  }
  return url;
};
