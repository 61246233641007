import { closeDialog } from 'zent';
import HtDialog from '../../standard/dialog';
import CompleteInfo from './components/complete-info';
import { QuitTipContent, QuitTipFooter } from './components/quit-tip';
import isEmpty from 'lodash/isEmpty';
import { DialogTitleStyle } from './style';

const { openHtDialog } = HtDialog;

function ApplyAffiliateDialog(props) {
  const { visible, onClose, intl } = props;

  return (
    <HtDialog
      visible={visible}
      onClose={onClose}
      title={intl.formatMessage({
        defaultMessage: '补充信息',
        id: '72fbff6d755c4fb691feddbf76b184c6',
      })}
    >
      <CompleteInfo intl={intl} />
    </HtDialog>
  );
}

export default ApplyAffiliateDialog;

const APPLY_AFFILIATEDIALOG_ID = 'open-apply-affiliate-dialog';

const QUIT_TIP_DIALOG_ID = 'OPEN_QUIT_TIP_DIALOG_ID';

export const openQuitTipeDialog = (props) => {
  const { theme, intl, refreshPage } = props;
  const closeQuitTipDialog = () => {
    if (isEmpty(window?.global_data?.userInfo) && refreshPage) {
      window.location.reload();
    }
    closeDialog(APPLY_AFFILIATEDIALOG_ID);
    closeDialog(QUIT_TIP_DIALOG_ID);
  };
  const continueEdit = () => {
    closeDialog(QUIT_TIP_DIALOG_ID);
  };
  openHtDialog({
    className: 'open-quit-tipe-dialog',
    theme,
    dialogId: QUIT_TIP_DIALOG_ID,
    title: intl.formatMessage({
      id: 'general.prompt',
      defaultMessage: '提示',
    }),
    children: <QuitTipContent intl={intl} />,
    footer: <QuitTipFooter closeDialog={closeQuitTipDialog} continueEdit={continueEdit} />,
  });
};
export const closeApplyAffiliateDialog = ({ refreshPage = true, intl, theme }) => {
  openQuitTipeDialog({ intl, theme, refreshPage });
};

const DialogTitle = ({ title, intl, theme }) => {
  return (
    <DialogTitleStyle>
      <div>{title}</div>
      <svg
        className='close-icon'
        aria-hidden='true'
        height='16px'
        width='16px'
        onClick={() => openQuitTipeDialog({ refreshPage: true, intl, theme })}
      >
        <use xlinkHref='#iconic-shanchu' />
      </svg>
    </DialogTitleStyle>
  );
};

export const openApplyAffiliateDialog = (props) => {
  const { theme, intl, title, ...rest } = props;
  openHtDialog({
    theme,
    dialogId: APPLY_AFFILIATEDIALOG_ID,
    title: (
      <DialogTitle
        title={
          title ||
          intl.formatMessage({
            defaultMessage: '补充信息',
            id: '72fbff6d755c4fb691feddbf76b184c6',
          })
        }
        intl={intl}
        theme={theme}
      />
    ),
    closeBtn: false,
    maskClosable: false,
    children: <CompleteInfo intl={intl} {...rest} />,
    onClose: () => {
      closeApplyAffiliateDialog({ refreshPage: true, theme, intl });
    },
    autoClose: false,
  });
};
