import { getMenu } from './index';

export const getInitialProps = async (data) => {
  const { main_linklist: mainLinkList = {} } = data;
  const { kdtId, menuId } = mainLinkList || {};

  return getMenu({
    menuId,
    kdtId,
  });
};

export default getInitialProps;
