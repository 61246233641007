import styled from 'styled-components';
import { getSizeVw } from '../../../../../../style/fn';

export default styled.div`
  left: 0;
  top: calc(100% + 1px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  box-sizing: border-box;
  gap: 40px 24px;
  z-index: 2;
  justify-content: left;
  flex-wrap: wrap;
  &.first-level-header-sub-menu {
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
  }
  .header-sub-menu-box {
    /* background: blue; */
    width: ${(props) => props.menuGroupWidth};
  }
  .header-menu-item-image {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
  &.adapt-content-width {
    width: fit-content !important;
    /* transform: translateX(-50%); */
  }
  .header-menu-item-text {
    display: block;
    width: 100%;
    color: ${(props) => props.textColor};
  }
  .decorative-style {
    display: inline-block;
    width: auto;
    border-bottom: 1px solid ${(props) => props.textColor};
  }

  &.header-sub-menu-not-first {
    font-size: var(--base-font-14-12);
    display: block;
    font-weight: 400;
    .header-sub-menu-box {
      margin-left: 0 !important;
    }
    .header-sub-menu-box + .header-sub-menu-box {
      margin-top: 16px;
    }
    .header-sub-minu-item {
      &:hover {
        opacity: 0.65;
      }
    }
  }

  .header-sub-minu-item {
    cursor: pointer;
    box-sizing: border-box;
    max-width: ${getSizeVw(400)};
  }
  .header-sub-minu-item a {
    color: ${(props) => props.textColor};
    &:hover {
      opacity: 0.65;
    }
  }

  .header-sub-menu-box {
    .header-sub-menu-box {
      padding: 0;
    }
  }

  .first-level-menu {
    position: relative;
    margin-bottom: 24px;
  }

  .first-level-menu a {
    color: ${(props) => props.textColor};
  }
  .menu-level-3 {
    opacity: 0.7;
  }
`;
