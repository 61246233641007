import React, { useState, useRef, useEffect } from 'react';
import { getImageInfo } from 'utils/image';
import { mobileSideHeight, pcSideHeight, TextSize } from '../config/slide_data';
import throttle from 'lodash/throttle';

function useSlide({ blockOrder, blocks, slideshowHeight, settings, textSize, eventName, isMobile }) {
  // 给默认初始值，放置页面抖动
  const heightMap = isMobile ? mobileSideHeight : pcSideHeight;
  const textSizeClass = TextSize[textSize];
  const [imagePercent, setImagePercent] = useState();
  const [slideImageHeight, setSlideImageHeight] = useState(heightMap[slideshowHeight]);
  const sliderRef = useRef(null);
  const sliderWrapRef = useRef(null);
  const getFirstImage = () => {
    let url = '';
    let flag = 0;
    blockOrder.map((key) => {
      if (flag === 0 && blocks?.[key]?.type === 'image') {
        url = blocks?.[key]?.settings?.image;
        flag = 1;
      }
    });
    return url;
  };
  const getImageHeight = (percent = imagePercent) => {
    const deviceWidth = sliderWrapRef?.current?.clientWidth;
    let realSlideshowHeight = slideshowHeight;
    if (!percent && realSlideshowHeight === 'with-first') {
      realSlideshowHeight = 'medium';
    }
    if (realSlideshowHeight === 'with-first') {
      return `${deviceWidth * (percent / 100)}px`;
    } else if (deviceWidth <= 700) {
      return mobileSideHeight[realSlideshowHeight];
    } else {
      return pcSideHeight[realSlideshowHeight];
    }
  };
  const getFirstImagePercent = () => {
    const url = getFirstImage();
    try {
      if (!url) {
        setSlideImageHeight(getImageHeight(0));
        return;
      }
      getImageInfo(url).then((imageInfo) => {
        const { width, height } = imageInfo;
        const percent = (height / width) * 100;
        setImagePercent(percent);
        setSlideImageHeight(getImageHeight(percent));
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFirstImagePercent();
  }, [blocks, blockOrder, settings, slideshowHeight]);

  useEffect(() => {
    const addEventOnResize = (name, fn) => {
      const slideEventList = window.cacheSlideEventList || {};
      slideEventList[name] = fn;
      window.cacheSlideEventList = slideEventList;
      window.onresize = throttle(() => {
        const fnList = Object.values(slideEventList);
        fnList.forEach((fnItem) => fnItem());
        if (fnList.length === 0) {
          window.onresize = null;
        }
      }, 100);
    };
    addEventOnResize(eventName, getFirstImagePercent);
    return () => {
      delete window.cacheSlideEventList[eventName];
    };
  }, [blocks, blockOrder, settings, slideshowHeight]);

  return {
    sliderRef,
    sliderWrapRef,
    slideImageHeight,
    textSizeClass,
  };
}
export default useSlide;
