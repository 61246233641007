export enum ShopRoleType {
  Single = 'single_shop',
  Headerquater = 'chain_hq',
  SubSite = 'chain_site',
}
export const MULTI_CURRENCY_COOKIE_NAME_PREFIX = 'MULTI_CURRENCY_COOKIE_NAME';
export const MULTI_CURRENCY_COOKIE_EXPIRE = 30;
export const MULTI_LANGUAGE_COOKIE_NAME_PREFIX = 'MULTI_LANGUAGE_COOKIE_NAME';
export const MULTI_LANGUAGE_COOKIE_EXPIRE = 30;

export const UTM_INFO_COOKIE_EXPIRE = 7;

export const MULTI_CURRENCY_TYPE = {
  general: 'general',
  special: 'special',
};

export const SWITCH_STATUS = {
  ON: 'on',
  OFF: 'off',
};
