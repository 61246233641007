import styled from 'styled-components';

export const Style = styled.div`
  width: 100%;
  height: 100%;
  .media-swiper {
    margin: -16px -16px 0 -16px;
    .swiper-slide-active {
      padding-right: 32px;
    }
  }
  .video-container {
    background-color: #000;
  }

  .media-list-gallery {
    margin-top: 16px;
    display: flex;
    overflow: auto;
    padding-bottom: 12px;
    .media-gallery-item {
      margin-left: 8px;
      border: 2px solid transparent;
      width: 64px;
      height: 64px;
      min-width: 64px;
      /* min-height: 64px; */
      border-radius: var(--border_radius_pd_card);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        object-fit: cover;
      }
      &.media-gallery-item-active {
        border: 2px solid var(--color_button);
      }
      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 8px;
      }
    }
  }
`;
