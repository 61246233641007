import styled from 'styled-components';

export default styled.div`
  position: absolute;
  z-index: 99999;
  right: 0;
  top: 0;
  border-radius: 0 0 0 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  .short-cut-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: #ff6d00;
    &:hover {
      background-color: #e66200;
    }
    &:active {
      background-color: #cc5700;
    }
    &.roate180 {
      transform: rotate(180deg);
    }
    .move-disabled {
      opacity: 0.2;
    }
  }
`;
