import styled, { css } from 'styled-components';

const getPositionCSS = (props) => {
  const { textDisplay } = props;
  // 显示在图片下面
  if (textDisplay === 'under_pic') {
    return css`
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 28px;
    `;
  } else if (textDisplay === 'superimposed') {
    return css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: auto;
      margin-top: ${(props) => props.theme.getSpace([8, 16])};
      min-height: 30px;
    `;
  }
};

export const Style = styled.div`
  /* color sku 位置 */
  ${(props) => getPositionCSS(props)}
  max-width: 100%;
  padding: 0 ${(props) => props.theme.getSpace([8, 16])};
  box-sizing: border-box;
  z-index: 10;
  .goods-color-sku {
    width: 100%;
    overflow: auto;
    text-align: center;
    &::-webkit-scrollbar {
      height: 0 !important;
      width: 0 !important;
    }
    ul {
      display: inline-flex;
      gap: 8px;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border: 1px solid transparent;
        border-radius: 50%;
        cursor: pointer;
        box-sizing: border-box;
        &.active {
          border-color: var(--color_button) !important;
          .color-box::after {
            border-width: 4px;
          }
        }
        .color-box {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          span {
            display: inline-block;
            width: 50%;
            height: 100%;
          }
        }
        .color-box::after {
          content: '';
          position: absolute;
          width: 200%;
          height: 200%;
          border-radius: 50%;
          border: 1px solid #c8c9cc;
          transform: scale(0.5);
          top: -50%;
          left: -50%;
          box-sizing: border-box;
        }
        .color-box:hover::after {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    bottom: 18px !important;
    ul {
      gap: 7px !important;
      li {
        width: 20px !important;
        height: 20px !important;
        .color-box {
          width: 14px !important;
          height: 14px !important;
        }
      }
    }
  }
`;
