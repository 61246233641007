import styled from 'styled-components';

export const Style = styled.div`
  width: 100%;
  /* background-color: lightgray; */
  .content {
    height: 100%;
    position: relative;
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  .title {
    margin-bottom: ${(props) => props.theme.getSpace(props.theme.vText)};
  }
  .text {
    margin-bottom: ${(props) => props.theme.getSpace(props.theme.vTextButton)};
  }
`;
