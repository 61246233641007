import loadable from '@loadable/component';

export default [
  {
    path: '/auth/test-provider',
    canNotEditLayout: true,
    name: 'test-provider',
    canDesignPage: false,
    exact: true,
    component: loadable(() => import('../pages/auth/test-provider')),
    pageType: '测试第三方介入',
  },
  {
    path: '/auth/exchange-token',
    canNotEditLayout: true,
    name: 'exchange-token',
    canDesignPage: false,
    exact: true,
    component: loadable(() => import('../pages/auth/exchange-token')),
    pageType: '授权中间页',
  },
];
