export const BUTTON_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
};
export const BUTTON_SIZE = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  full: 'full',
};
