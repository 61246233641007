// 对应装修的轮播图2.0-图片轮播图
import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'zent';
import cn from 'classnames';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Style } from './style';
import { ImageItem } from './image-item';
import useSlide from '../../hooks/slide';
import ContainerSize from '../../components/container-size';

SwiperCore.use([]);

const SlideShow = (props) => {
  const { settings = {}, block_order: blockOrder = [], blocks, elemClass } = props;
  const {
    slideshow_height: slideshowHeight = 'with-first',
    text_size: textSize = 'medium',
    autorotate_speed: autoRotateSpeed = 3, // 自动播放速度
    autorotate: autoRotate = false, // 是否自动播放
    mobile_text_type: mobileTextType,
    box_width: boxWidth,
    layout = 'full', // 图片布局
  } = settings;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [status, setSwiperStatus] = useState('initial');
  const [autoPlayParams, setAutoPlayParams] = useState({});
  const [showSwiper, setShowSwiper] = useState(true);
  const [textAlignClass, setTextAlignClass] = useState('');
  // const [slideHeight, setSlideHeight] = useState(0);
  const { sliderRef, sliderWrapRef, slideImageHeight, textSizeClass } = useSlide({
    blockOrder,
    blocks,
    slideshowHeight,
    settings,
    textSize,
    eventName: 'slide-show-v3',
  });

  const onNextClick = () => {
    sliderRef?.current?.swiper?.slideNext();
  };

  const onPrevClick = () => {
    sliderRef?.current?.swiper?.slidePrev();
  };

  const slideToIndex = (index) => {
    sliderRef?.current?.swiper?.slideTo(index);
  };
  /** 是否播放选项 */
  useEffect(() => {
    const autoplay = { delay: autoRotateSpeed * 1000 };
    if (autoRotate && blockOrder.length > 1) {
      setAutoPlayParams({ autoplay });
      sliderRef?.current?.swiper?.autoplay?.start();
    } else {
      setAutoPlayParams({});
      sliderRef?.current?.swiper?.autoplay?.stop();
    }
  }, [autoRotateSpeed, autoRotate]);

  /** 播放间隔选项 */
  useEffect(() => {
    setShowSwiper(false);
    setTimeout(() => {
      setShowSwiper(true);
    });
  }, [autoRotateSpeed]);
  // 容器更改，swiper要重新计算宽度
  useEffect(() => {
    sliderRef?.current?.swiper?.update();
  }, [boxWidth]);
  return (
    <Style
      className={cn('slide-show-section', elemClass, mobileTextType, `layout-${layout}`)}
      pcHeight={slideImageHeight}
      mobileHeight={slideImageHeight}
      slideImageHeight={slideImageHeight}
    >
      <ContainerSize boxWidth={boxWidth} needSafeSpace>
        <div className='slide-show-section-inner'>
          <div ref={sliderWrapRef} className={cn('slide-show', status, textSizeClass, textAlignClass)}>
            {showSwiper && (
              <Swiper
                loop
                {...autoPlayParams}
                ref={sliderRef}
                noSwiping
                onResize={() => {
                  sliderRef?.current?.swiper?.update();
                }}
                onSwiper={() => {
                  setSwiperStatus('loaded');
                }}
                onSlideChange={(swiper) => {
                  const { realIndex } = swiper;
                  setCurrentIndex(realIndex);
                }}
              >
                {blockOrder.map((key, index) => {
                  const blockSettings = blocks[key]?.settings || {};
                  const type = blocks[key]?.type;
                  return (
                    <SwiperSlide key={index}>
                      <ImageItem settings={blockSettings} globalSettings={settings} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}

            {blockOrder.length > 1 ? (
              <div className={cn('slide-menu')}>
                <div className='slide-navigation-bar slide-navigation-bar--prev'>
                  <svg className='return-icon' aria-hidden='true' height='16px' width='16px' onClick={onPrevClick}>
                    <use xlinkHref='#iconshangyige' />
                  </svg>
                </div>
                <div className='slide-navigation-bar slide-navigation-bar--next'>
                  <svg className='return-icon' aria-hidden='true' height='16px' width='16px' onClick={onNextClick}>
                    <use xlinkHref='#iconxiayige' />
                  </svg>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </ContainerSize>
    </Style>
  );
};

export default SlideShow;
