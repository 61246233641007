// 多图列表
import React, { useEffect, useState, useMemo } from 'react';
import cn from 'classnames';
import { Style, GlobalStyle } from './style';
import ContainerSize from '../../components/container-size';
import { MultiSelect } from '../../components/multi-select';
import { MobileFold } from './mobile-fold/index';
import isEmpty from 'lodash/isEmpty';

export const SiteSelect = (props) => {
  const { settings = {}, block_order = [], blocks = {}, initialData = {}, theme } = props;
  const {
    fixed_top: announcementFixedTop = false,
    home_page_only: announcementHomePageOnly = false,
    announcementDisabled = false,
  } = theme?.current?.sections?.['announcement-bar']?.settings ?? {};
  const [hasScroll, setHasScroll] = useState(false);
  const handleScroll = () => {
    if (document.documentElement.scrollTop > 0) {
      setHasScroll(true);
    } else {
      setHasScroll(false);
    }
  };
  const isHomePage = props?.history?.location?.pathname === '/';
  const addFixedTopClassName = useMemo(() => {
    if (!announcementFixedTop || announcementDisabled) {
      return false;
    }
    if (announcementHomePageOnly) {
      return isHomePage;
    }
    return true;
  }, [isHomePage, announcementFixedTop, announcementHomePageOnly]);
  const sectionThemeData = {
    settings,
    block_order,
    blocks,
  };
  const { kdtId } = initialData;
  const {
    color_nav_background: colorNavBackground,
    color_nav_sel_background: colorNavSelBackground,
    color_nav_sel_text: colorNavSelText,
    color_nav_text: colorNavText,
    display_style: displayStyle,
    show_site_logo: showSiteLogo,
    show_site_name: showSiteName,
    switch_status: switchStatus,
    top_nav_width: topNavWidth,
  } = settings;

  const isShow = useMemo(() => {
    if (!switchStatus) {
      return false;
    }
    let currentSiteShow = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in blocks) {
      if (blocks[key]?.settings?.kdtId === kdtId) {
        currentSiteShow = true;
        break;
      }
    }
    return currentSiteShow;
  }, [switchStatus, blocks, kdtId]);

  useEffect(() => {
    if (announcementFixedTop && !announcementDisabled) {
      document.addEventListener('scroll', handleScroll, false);
    }
    return () => {
      document.removeEventListener('scroll', handleScroll, false);
    };
  }, [announcementFixedTop, announcementDisabled]);

  return (
    isShow && (
      <>
        <GlobalStyle />
        <Style
          colorNavBackground={colorNavBackground}
          colorNavText={colorNavText}
          colorNavSelText={colorNavSelText}
          colorNavSelBackground={colorNavSelBackground}
          className={addFixedTopClassName && hasScroll ? 'fixed-top' : ''}
        >
          <div className='top-nav-site'>
            {isEmpty(block_order) ? null : (
              <ContainerSize boxWidth={topNavWidth} needSafeSpace>
                {displayStyle === 'unfold' && (
                  <div className='expand-site-list'>
                    {block_order.map((key, index) => {
                      const item = blocks?.[key]?.settings || {};
                      return (
                        <a
                          className={cn('shop-item skiptranslate', {
                            active: kdtId === item.kdtId,
                          })}
                          href={`https://${item.mainHost}`}
                          key={index}
                        >
                          {showSiteLogo &&
                            // eslint-disable-next-line no-nested-ternary
                            (kdtId === item.kdtId ? (
                              !!item.active_image || !!item.image ? (
                                <img src={item.active_image || item.image} alt='' className='shop-logo logo-item' />
                              ) : (
                                <svg className='default-logo logo-item'>
                                  <use xlinkHref='#icondianputouxiang' />
                                </svg>
                              )
                            ) : item.image ? (
                              <img src={item.image} alt='' className='shop-logo logo-item' />
                            ) : (
                              <svg className='default-logo logo-item'>
                                <use xlinkHref='#icondianputouxiang' />
                              </svg>
                            ))}
                          {showSiteName && <span className='shop-name'>{item.site_name}</span>}
                        </a>
                      );
                    })}
                  </div>
                )}
                {displayStyle === 'fold' && (
                  <div className='right-fold-select-wrapper'>
                    <MobileFold sectionThemeData={sectionThemeData} kdtId={kdtId} colorNavText={colorNavText} />
                    <MultiSelect
                      sectionThemeData={sectionThemeData}
                      defaultSite={kdtId}
                      popupClassName='right-fold-select-popup'
                    />
                  </div>
                )}
              </ContainerSize>
            )}
          </div>
        </Style>
      </>
    )
  );
};
