import { useMemo, useRef, useState } from 'react';
import { MessageType } from 'const';

export const useDecorationActiveMap = (contentForIndex) => {
  const decorationActiveMapOriginRef = useRef();
  const decorationActiveMapOrigin = useMemo(() => {
    const map = {};
    contentForIndex?.forEach((i) => {
      map[i] = false;
    });
    decorationActiveMapOriginRef.current = map;
    return map;
  }, [contentForIndex]);
  const [decorationActiveMap, setDecorationActiveMap] = useState(decorationActiveMapOrigin);
  const changeDecorationActiveFun = (hashId, active, sendToEditFrame = false) => {
    // if (active) return; // 取消原来选中的点击的反选
    if (decorationActiveMapOriginRef?.current?.[hashId] === undefined) return;
    setDecorationActiveMap({ ...decorationActiveMapOriginRef?.current, [hashId]: !active });
    // 是否要传给b端
    if (!sendToEditFrame) return;
    window.parent.postMessage(
      {
        type: MessageType.SECTIONSELECT,
        payload: {
          sectionHashId: active ? '' : hashId,
        },
      },
      '*',
    );
  };
  return {
    decorationActiveMap,
    changeDecorationActiveFun,
  };
};
