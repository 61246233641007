import React, { useState, useEffect } from 'react';
import { Style } from './style';
import ShopApi from 'api/shop';
import { GDPR_COOKIE_STATUS } from 'utils/local-storage/config';
import LocalStorage from 'utils/local-storage';
import { useIntl } from 'react-intl';

const GDPR_PATH_MAP = {
  HOME: '/', // 首页
  ALL_GOODS: '/all/goods', // 全部商品
  COLLECTION: 'collections', // /collections/all, /collections/:collectionId
  PRODUCT: '/product', // 分组、商品详情 /product/list/, /products
};

// 所有商品页：all/product -》   collections/all
// 商品分组页面 /product/list/:collectionId     =》 /collections/:collectionId
// 商品详情页： /product/:id  =》 /products/:id
// 自定义页： /custom/:id    =》 /pages/:id

const GDPR = () => {
  const intl = useIntl();
  const [isShowGDPR, setIsShowGDPR] = useState(false);
  const [cookieDetail, setCookieDetail] = useState('');

  useEffect(() => {
    const gdprStatus = LocalStorage.get(GDPR_COOKIE_STATUS);
    // 页面是否要显示
    if (!gdprStatus) {
      const pathname = window?.location?.pathname;
      if (
        pathname === GDPR_PATH_MAP.HOME ||
        pathname === GDPR_PATH_MAP.ALL_GOODS ||
        pathname.startsWith(GDPR_PATH_MAP.COLLECTION) ||
        pathname.startsWith(GDPR_PATH_MAP.PRODUCT)
      ) {
        ShopApi.getRuleDetail({ ruleType: 'cookie' }).then((data) => {
          if (data?.cookieSwitch && data?.contactDetail) {
            setIsShowGDPR(true);
            setCookieDetail(data?.contactDetail);
          }
        });
      }
    }
  }, []);

  const handleAccept = () => {
    LocalStorage.set(GDPR_COOKIE_STATUS, 'true');
    setIsShowGDPR(false);
  };

  if (!isShowGDPR) {
    return null;
  }

  return (
    <Style>
      <div className='gdpr-cookie-detail'>{cookieDetail}</div>
      <div className='gdpr-action'>
        <a className='privacy-policy' href='/policy/Privacy-Policy'>
          {intl.formatMessage({ description: 'Privacy Policy', id: 'privacy' })}
        </a>
        <div className='gdpr-accept' onClick={handleAccept}>
          {intl.formatMessage({ description: 'Accept', id: '0a8a546ab0c2476eac6a1da3e400eac5' })}
        </div>
      </div>
    </Style>
  );
};

export default GDPR;
