import styled from 'styled-components';
import { getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  /* padding: 0 40px; */
  .form-title {
    display: flex;
    font-size: var(--form_name_size);
    color: var(--form_title_color);
    zoom: var(--form_title_scale);
    padding: 3px 0;
    &.required::before {
      color: #d40000;
      color: var(--theme-error-2, #d40000);
      content: '*';
      display: inline-block;
      font-size: 16px;
      margin-right: 6px;
    }
  }

  .form-desc {
    padding-left: 18px;
    font-size: 12px;
    padding: 2px 0;
    color: rgba(var(--form_title_color_rgb), 0.4);
    margin-top: 8px;
  }

  .has-require {
    padding-left: 12px;
  }

  .form-container {
    .zent-form-control {
      margin-top: 16px;
      margin-bottom: 29px;
    }
    .zent-form-control-content {
      margin-left: 0;
      .zent-input-wrapper {
        flex: 1;
      }
    }

    .zent-input-wrapper {
      border-color: var(--form_border_color) !important;
      .zent-input {
        background-color: var(--form_bg_color) !important;
        &::placeholder {
          color: rgba(var(--form_title_color_rgb), 0.6);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;
