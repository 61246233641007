import styled from 'styled-components';

export default styled.div`
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  z-index: ${(props) => (props.openAnimation ? 99 : 'initial')};
  @media screen and (min-width: 768px) {
    min-height: ${(props) =>
      `${
        props.isHome ? 0 : props.menuHeight
      }px`} !important; // 如果是其他页面，设置下最小高度，不然一开始高度为0 ，从服务端返回的代码样式就不对了
  }
  .fixed_header {
    transition: top 0.5s ease-in-out;
    width: 100%;
    z-index: 99;
  }
  .design-mobile-header {
    display: none;
  }
  .design-pc-header {
    display: block;
    margin-top: -1px;
  }
  @media screen and (max-width: 768px) {
    & {
      height: 64px !important;
      display: flex;
      align-items: center;
      background: ${(props) => props.defaultBgColor} !important;
      z-index: 99;
    }
    .fixed_header {
    }
    .design-mobile-header {
      display: block;
    }
    .design-pc-header {
      display: none;
    }
  }
  .link_has_label {
    position: relative;
    z-index: 2;
    display: inline-block;
  }
`;
