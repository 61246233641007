import React, { useState } from 'react';
import FindPasswordByAccount from '../find-password-by-account';
import ResetPassword from '../reset-password';
import { STEP_ONE } from '../../const';

function ForgetPasswordDialogBody(props) {
  const { step, setStep, intl, setLoginDialogInfo, onClose, withMobileLogin } = props;
  const [step1Info, setStep1Info] = useState({});

  return step === STEP_ONE ? (
    <FindPasswordByAccount
      setStep={setStep}
      intl={intl}
      setStep1Info={setStep1Info}
      withMobileLogin={withMobileLogin}
    />
  ) : (
    <ResetPassword intl={intl} step1Info={step1Info} setLoginDialogInfo={setLoginDialogInfo} onClose={onClose} />
  );
}
export default ForgetPasswordDialogBody;
