import styled from 'styled-components';

export const Style = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .click-able {
    cursor: pointer;
  }

  .feature-collection-title {
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    color: var(--color_text);
    margin-bottom: ${(props) => props.theme.getBaseFontSize([20, 40])};
    &.full-screen {
      padding: 0 ${(props) => props.theme.getSpace(props.theme.hContainer)};
    }
  }

  .view-container {
    margin-top: ${(props) => props.theme.getSpace([12, 40])};
    .ui-big-button {
      margin: 0 auto;
      border-radius: var(--border_radius_btn);
    }
  }

  .view-all-btn {
    display: inline-block;
    width: 200px;
    cursor: pointer;
    background-color: var(--color_button);
    color: var(--color_button_text);
    height: 56px !important;
    line-height: 56px !important;
  }

  .collection-grid-placeholder {
    height: 0;
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  .feature-collection-box {
    width: 100%;
  }

  @media screen and (min-width: 750px) {
    .align-right {
      flex-direction: row-reverse;
    }
  }

  @media screen and (max-width: 749px) {
    .featured-row {
      flex-direction: column;
    }

    .featured-row-text {
      margin-left: 0;
    }

    .featured-row-img,
    .featured-row-text {
      width: 100%;
    }
  }
  &.cn-full_screen {
    .goods-name,
    .goods-price {
      padding: 0 ${(props) => props.theme.getSpace(props.theme.g16)};
    }
  }
`;
export const PriceStyle = styled.div`
  &.goods-price {
    margin-top: ${(props) => props.theme.getSpace([4, 12])};
    /* ${(props) => props.theme.getSalePriceFontSize(props.theme[props.size])}; */
    color: var(--color_sale_text);
    text-align: left;
    .goods-sku-price .discount-price {
      /* .price-symbol,
            .price-integer,
            .price-decimal {
                ${(props) => props.theme.getSaleOriginPriceFontSize(props.theme[props.size])};
            } */
    }
    .discount-text {
      margin-left: 16px !important;
      /* ${(props) => props.theme.getSaleOriginPriceFontSize(props.theme[props.size])}
            .price-symbol,
            .price-integer,
            .price-decimal {
                ${(props) => props.theme.getSaleOriginPriceFontSize(props.theme[props.size])};
            } */
    }
    .underlined-price {
      /* ${(props) => props.theme.getSaleOriginPriceFontSize(props.theme[props.size])};
            .price-symbol,
            .price-integer,
            .price-decimal {
                ${(props) => props.theme.getSaleOriginPriceFontSize(props.theme[props.size])};
            } */
    }
  }
`;
