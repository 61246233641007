import styled from 'styled-components';

export const Style = styled.div`
  .footer-bottom-section {
    margin-top: 32px;
    &.show-dividing-line-true {
      margin-top: 40px;
      .footer-menu-section-border-bottom {
        margin-bottom: 40px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        opacity: 0.08;
      }
    }
    &.show-payment-icons-true {
      .footer-support-by {
        opacity: 0.6;
      }
    }
  }

  .footer-company-info {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .footer-payment-icons {
    margin-bottom: -20px;
    .footer-payment-icons-item {
      display: inline-block;
      min-width: 40px;
      vertical-align: bottom;
      margin-right: ${(props) => props.theme.getSpace([6, 16])};
      margin-bottom: ${(props) => props.theme.getSpace([6, 16])};
      border: 1px solid;
      padding: 1px 3px;
      border-radius: 2px;
      text-align: center;
      box-sizing: border-box;
      background-color: #fff;
      img {
        display: inline-block;
        height: ${(props) => props.theme.getSpace([16, 20])};
        width: auto;
        vertical-align: middle;
      }
    }
  }
  .footer-support-by {
    font-size: ${(props) => props.theme.getBaseFontSize([12, 14])};
    margin-top: ${(props) => props.theme.getSpace([16, 0])};
    letter-spacing: 0;
    min-width: 93px;
    display: inline-block;
  }
  @media screen and (max-width: 750px) {
    .footer-bottom-section {
      text-align: ${(props) => props.mobileDeviceFooterTextAlign};
      /* 显示分割线 */
      &.show-dividing-line-true {
        .footer-menu-section-border-bottom {
          margin-bottom: 32px;
        }
      }
      /* 对齐方式 */
      .footer-payment-icons {
        margin-bottom: 0;
      }
    }
  }
`;
