import { useState } from 'react';
import { Form, FormStrategy, Validators, Notify, closeDialog } from 'zent';
import HtFormInput from 'components/standard/form-input';
import HtFormInputPassword from 'components/standard/form-input-password';
import HtButton from 'components/standard/button';
import CodeInput from '../../../code-input';
import HtFormInputPhone from 'components/standard/form-input-phone';
import { InlineFormWrapper, InlineFormItem } from './style';
import { SCENE_TYPE, ACCOUNT_TYPE_MAP } from 'client/const/account';
import UserAccount from 'client/controllers/user-account';
import { mergeCart } from 'helper/cart';
import { isEmpty, isSamePassword, isValidPassword } from 'helper/verify';
import { buriedPoint } from 'utils/log-buried-point';
import { openApplyAffiliateDialog } from '../../../apply-affiliate-dialog';

function RegisterBody(props) {
  const { intl, withMobileLogin, isFromAffiliate, dialogId, theme } = props;
  const [accountType, setAccountType] = useState(withMobileLogin ? ACCOUNT_TYPE_MAP.phone : ACCOUNT_TYPE_MAP.email);
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [mobileCountryCode, setMobileCountryCode] = useState('');
  const onMobileChange = (val) => {
    setMobile(val.trim());
  };
  const onEmailChange = (val) => {
    setEmail(val.trim());
  };
  const onMobileCountryCodeChange = (val) => {
    setMobileCountryCode(val.trim());
  };

  const form = Form.useForm(FormStrategy.View);

  const changeAccountType = () => {
    setAccountType(accountType === ACCOUNT_TYPE_MAP.email ? ACCOUNT_TYPE_MAP.phone : ACCOUNT_TYPE_MAP.email);
  };

  const handleRegisterSuccess = ({ userInfo }) => {
    // const { sid } = params;
    mergeCart();
    if (window.isEdit) return;
    buriedPoint('sign_up', { status: true });
    if (!isFromAffiliate) {
      window.location.reload();
      Notify.success(
        intl.formatMessage({
          id: 'account.register_success',
        }),
      );
      return;
    }
    if (isFromAffiliate) {
      closeDialog(dialogId);
      openApplyAffiliateDialog({ intl, theme, userInfo });
    }
  };

  const onsubmit = async () => {
    console.log('onsubmit', form.getValue());
    const formData = form.getValue();
    const { firstName, lastName, password, mobile, verifyCode, email } = formData;
    if (accountType === ACCOUNT_TYPE_MAP.phone) {
      UserAccount.checkMobileOnRegister({
        mobileCountryCode,
        mobile,
        verifyCode,
      }).then(() => {
        UserAccount.registerByMobile({
          firstName,
          lastName,
          password,
          mobile,
          mobileCountryCode,
          verifyCode,
          sourceType: '1',
        }).then((res) => {
          handleRegisterSuccess({ ...res, userInfo: { firstName, lastName } });
        });
      });
    } else {
      // 邮箱注册
      UserAccount.checkEmailOnRegister({
        email,
        verifyCode,
      }).then(() => {
        UserAccount.registerByEmail({
          firstName,
          lastName,
          password,
          verifyCode,
          email,
          sourceType: '1',
        }).then((res) => {
          handleRegisterSuccess({ ...res, userInfo: { firstName, lastName } });
        });
      });
    }
  };
  return (
    <Form
      layout='vertical'
      form={form}
      onSubmit={onsubmit}
      onSubmitFail={(err) => {
        console.log('onSubmitFail', err);
      }}
    >
      <InlineFormWrapper>
        <InlineFormItem>
          <HtFormInput
            name='firstName'
            width='100%'
            placeholder={intl.formatMessage({
              id: 'account.form.first_name',
              defaultMessage: '名字',
            })}
            validators={[
              Validators.required(
                intl.formatMessage({
                  id: 'e2c11f74d1bad0a89d57a3fcb8500ceb',
                  defaultMessage: '请输入名字',
                }),
              ),
              isEmpty(
                intl.formatMessage({
                  id: 'e2c11f74d1bad0a89d57a3fcb8500ceb',
                  defaultMessage: '请输入名字',
                }),
              ),
            ]}
          />
        </InlineFormItem>
        <InlineFormItem>
          <HtFormInput
            name='lastName'
            width='100%'
            placeholder={intl.formatMessage({
              id: 'order.address.firstname',
              defaultMessage: '姓氏',
            })}
            validators={[
              Validators.required(
                intl.formatMessage({
                  id: 'reviews.about.reviewer.name',
                  defaultMessage: '请输入姓氏',
                }),
              ),
              isEmpty(
                intl.formatMessage({
                  id: 'reviews.about.reviewer.name',
                  defaultMessage: '请输入姓氏',
                }),
              ),
            ]}
          />
        </InlineFormItem>
      </InlineFormWrapper>
      {accountType === ACCOUNT_TYPE_MAP.phone ? (
        <HtFormInputPhone
          name='phone'
          width='100%'
          intl={intl}
          form={form}
          onMobileChange={onMobileChange}
          onMobileCountryCodeChange={onMobileCountryCodeChange}
        />
      ) : (
        <HtFormInput
          name='email'
          width='100%'
          placeholder={intl.formatMessage({
            id: 'common_mailbox',
            defaultMessage: '邮箱',
          })}
          onChange={onEmailChange}
          validators={[
            Validators.required(
              intl.formatMessage({
                id: 'please_input_your_email',
                defaultMessage: '请输入邮箱',
              }),
            ),
            isEmpty(
              intl.formatMessage({
                id: 'please_input_your_email',
                defaultMessage: '请输入邮箱',
              }),
            ),
          ]}
        />
      )}

      <CodeInput
        intl={intl}
        mobile={mobile}
        accountType={accountType}
        email={email}
        form={form}
        scene={SCENE_TYPE.register}
        mobileCountryCode={mobileCountryCode}
        verifyCodeId='verify_code_register'
      />
      <HtFormInputPassword
        name='password'
        width='100%'
        placeholder={intl.formatMessage({
          id: 'account.form.password',
          defaultMessage: '密码',
        })}
        validators={[
          Validators.required(
            intl.formatMessage({
              id: 'pleaseInputPassword',
              defaultMessage: '请输入密码',
            }),
          ),
          isValidPassword(
            intl.formatMessage({
              id: 'bf1ed1a42da342f887137ff2bccf5308',
              defaultMessage: '密码仅支持输入数字、字母、特殊符号。最长32个字符，最短10个字符。',
            }),
          ),
        ]}
        autoComplete='new-password'
      />
      <HtFormInputPassword
        name='confirmPassword'
        width='100%'
        placeholder={intl.formatMessage({
          id: 'Confirm_new_password',
          defaultMessage: '确认新密码',
        })}
        validators={[
          Validators.required(
            intl.formatMessage({
              id: '3069d1354695456685a0bf7e5b467171',
              defaultMessage: '请输入确认密码',
            }),
          ),
          isEmpty(
            intl.formatMessage({
              id: '3069d1354695456685a0bf7e5b467171',
              defaultMessage: '请输入确认密码',
            }),
          ),
          isSamePassword(
            form,
            intl.formatMessage({
              id: 'a646e4e4bf864096a07801750bff2d30',
              defaultMessage: '两次密码输入不一致',
            }),
          ),
        ]}
        autoComplete='new-password'
      />
      <HtButton full size='large' type='primary' htmlType='submit'>
        {isFromAffiliate
          ? intl.formatMessage({
              id: '38ce27d84639f3a6e07c00b3b4995c0e',
              defaultMessage: '下一步',
            })
          : intl.formatMessage({
              id: 'general.submit',
              defaultMessage: '提交',
            })}
      </HtButton>
      {withMobileLogin && (
        <HtButton full plain onClick={changeAccountType}>
          {accountType === ACCOUNT_TYPE_MAP.email
            ? intl.formatMessage({
                id: 'b4460dd9918c48bfa2c864ad084bfcd7',
                defaultMessage: '手机号注册',
              })
            : intl.formatMessage({
                id: '62f46c35c2234f07a3ca44600387299a',
                defaultMessage: '邮箱注册',
              })}
        </HtButton>
      )}
    </Form>
  );
}

export default RegisterBody;
