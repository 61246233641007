import React, { useEffect, useState } from 'react';
import { Badge, Pop } from 'zent';
import { Select } from 'cpn/hunt-select';
import cn from 'classnames';
import Style, { GlobalStyle } from './style';
import { useIntl } from 'react-intl';
import { IS_OPEN_LOGIN } from '../../../../../../const/index';
import { getQueryString } from 'utils';
import NewPopoverList from 'components/base/new-popover-list';
import { TabType } from 'const/account';
import { useMultiCurrencyLanguage } from '../../hooks/multi-currency-language';
import MobileHeaderOperate from '../mobile-header-operate';
import { HEAD_ICON_TYPE } from 'const';
import shopApi from 'api/shop';
import cartApi from 'api/cart';
import { CART_COUNT_CHANGE_EVENT } from 'const/cart';
import { useRootContext } from 'client/pages/context/root';
import { useRequireLogin } from 'cpn/require-login-wrapper';

export const HeaderOperte = (props) => {
  const intl = useIntl();
  const {
    jumpToOtherPage,
    initialData,
    customClass,
    iconColor,
    totalCountWish,
    alignLogo,
    isMobile = false,
    isSide = false,
    showSearchIcon = true,
    showPersonalCenterIcon = true,
    showWishlistIcon = true,
    showShopCartIcon = true,
    defaultTextIconColor,
    onOpenCart,
  } = props;
  const { userInfo = {}, kdtId } = initialData || {};
  const isLogin = userInfo && userInfo?.customerId;
  const [visible, setVisible] = useState(false);
  const { setLoginDialogInfo } = useRootContext();
  const [totalOfGoods, setTotalOfGoods] = useState(0);
  const { checkedRequireLogin } = useRequireLogin();
  const menuList = [
    {
      text: intl.formatMessage({
        id: 'dd15e7b34d0e4546aa6658ef4cfa98cf',
        defaultMessage: '我的账户',
      }),
      onClick: () => {
        if (isLogin) {
          jumpToOtherPage('/me');
        } else {
          setLoginDialogInfo({
            visible: true,
          });
        }
      },
      disabled: false,
      showRedPoint: false,
      key: TabType.account,
    },
    {
      text: intl.formatMessage({
        id: 'myCoupons',
        defaultMessage: '我的优惠券',
      }),
      onClick: () => {
        if (isLogin) {
          jumpToOtherPage(`/me?tabId=${TabType.Coupon}`);
        } else {
          setLoginDialogInfo({
            visible: true,
          });
        }
      },
      disabled: false,
      showRedPoint: false,
      key: TabType.Coupon,
    },
    {
      text: intl.formatMessage({
        id: 'message_notification',
        defaultMessage: '消息通知',
      }),
      onClick: () => {
        if (isLogin) {
          jumpToOtherPage(`/me?tabId=${TabType.Notify}`);
        } else {
          setLoginDialogInfo({
            visible: true,
          });
        }
      },
      disabled: false,
      showRedPoint: false,
      key: TabType.Notify,
    },
  ];
  const [accountList, setAccountList] = useState(menuList);

  const cartChangeHandler = async () => {
    const count = await cartApi.getCartCount();
    setTotalOfGoods(count);
  };
  const {
    isShowMultiSettingEntry,
    isShowMultiCurrency,
    isShowMultiLanguage,
    languageList,
    multiCurrencies,
    handleCurrencyChange,
    handleLanuageChange,
    multiCurrency,
    language,
  } = useMultiCurrencyLanguage(kdtId, initialData);

  // 重置密码 忘记密码 等返回打开登录弹窗
  const handleOpenLogin = () => {
    const isOpenLogin = window.sessionStorage.getItem(IS_OPEN_LOGIN);
    if (isOpenLogin) {
      window.sessionStorage.removeItem(IS_OPEN_LOGIN);
      if (!isLogin) {
        setLoginDialogInfo({
          visible: true,
        });
      }
    }
    const qs = getQueryString(window?.location?.search);
    if (qs && qs?.awaken === 'resetPassword') {
      setTimeout(() => {
        setLoginDialogInfo({
          visible: true,
        });
      }, 0);
    }
  };
  // 获取是否需要有未读消息
  const getNotifyRemindStatus = () => {
    if (!userInfo?.customerId) return;
    // 获取概览
    shopApi.getNotifySummary({ hasLatestNotify: 0, customerId: userInfo?.customerId }).then((res) => {
      const isUnreadNotify = res.some((item) => {
        return item?.unReadCount > 0;
      });
      if (isUnreadNotify) {
        const list = accountList.map((item) => {
          if (item?.key === TabType.Notify) {
            return { ...item, showRedPoint: isUnreadNotify };
          }
          return item;
        });
        setAccountList(list);
      }
    });
  };

  const beforeOnOpenCart = () => {
    if (checkedRequireLogin()) return;
    onOpenCart();
  }

  const handlePopListVisibleChange = (status) => {
    setVisible(status);
    if (status) {
      getNotifyRemindStatus();
    }
  };

  useEffect(() => {
    cartChangeHandler(); // 初始化进入页面时需要获取购物车物品数量
    window.addEventListener(CART_COUNT_CHANGE_EVENT, cartChangeHandler); // 当在本页面内监听到localstorage变化，计算物品数量
    // 处理是否要打开登录弹窗
    handleOpenLogin();
    return () => {
      window.removeEventListener(CART_COUNT_CHANGE_EVENT, cartChangeHandler);
    };
  }, []);

  return isMobile ? (
    <MobileHeaderOperate
      initialData={initialData}
      jumpToOtherPage={jumpToOtherPage}
      intl={intl}
      totalOfGoods={totalOfGoods}
      totalCountWish={totalCountWish}
      showSearchIcon={showSearchIcon}
      showPersonalCenterIcon={showPersonalCenterIcon}
      showWishlistIcon={showWishlistIcon}
      showShopCartIcon={showShopCartIcon}
      defaultTextIconColor={defaultTextIconColor}
      isSide={isSide}
      onOpenCart={beforeOnOpenCart}
    />
  ) : (
    // 下面判断是否是移动端的逻辑都可以去掉了
    <Style
      className={cn(customClass, {
        'no-grow': alignLogo === 'center',
        'is-mobile': isMobile,
      })}
      iconColor={iconColor}
      isSide={isSide}
    >
      <GlobalStyle />

      {showSearchIcon && (
        <span className='header-icon-container' onClick={() => jumpToOtherPage('/search')}>
          <svg className='icon header-icon' aria-hidden='true'>
            <use xlinkHref='#iconic-sousuo' />
          </svg>
        </span>
      )}
      {showPersonalCenterIcon && (
        <NewPopoverList
          className='account-popover'
          position={NewPopoverList.Position.BottomRight}
          list={accountList}
          cushion={5}
          visible={visible}
          onVisibleChange={(v) => {
            handlePopListVisibleChange(v);
          }}
        >
          <NewPopoverList.Trigger.Hover>
            <span className='header-icon-container dynamic-display'>
              <svg className='icon header-icon' aria-hidden='true'>
                <use xlinkHref='#iconic-gerenzhanghao' />
              </svg>
            </span>
          </NewPopoverList.Trigger.Hover>
        </NewPopoverList>
      )}

      {/* 新增心愿单入口 */}
      {showWishlistIcon && (
        <span
          className='header-icon-container dynamic-display'
          id={HEAD_ICON_TYPE.wish}
          onClick={() => {
            if (isLogin) {
              jumpToOtherPage(`/me?tabId=${TabType.Wish}`);
            } else {
              setLoginDialogInfo({
                visible: true,
              });
            }
            return true;
          }}
          content={
            <div className='settings-wrapper'>
              {isShowMultiCurrency && (
                <>
                  <div className='currency-select-label'>
                    {intl.formatMessage({
                      id: '65fff7d21c2c4aad823fc7b136258734',
                      defaultMessage: '币种',
                    })}
                  </div>
                  <Select
                    popupClassName='setting-zent-popover'
                    options={multiCurrencies}
                    value={multiCurrency}
                    optionValue='currency'
                    optionText='currency'
                    onChange={handleCurrencyChange}
                  />
                </>
              )}
              {isShowMultiLanguage && (
                <>
                  <div className='currency-select-label skiptranslate'>
                    {intl.formatMessage({
                      id: 'account_language',
                      defaultMessage: '语言',
                    })}
                  </div>
                  <Select
                    options={languageList}
                    popupClassName='setting-zent-popover'
                    value={language}
                    optionValue='language'
                    optionText='name'
                    onChange={handleLanuageChange}
                  />
                </>
              )}
            </div>
          }
        >
          <Badge count={totalCountWish}>
            <svg className='icon header-icon' aria-hidden='true'>
              <use xlinkHref='#iconic-shoucang2' />
            </svg>
          </Badge>
        </span>
      )}
      {showShopCartIcon && (
        <span onClick={beforeOnOpenCart} className='header-icon-container' id={HEAD_ICON_TYPE.cart}>
          <Badge count={totalOfGoods}>
            <svg className='icon header-icon' aria-hidden='true'>
              <use xlinkHref={isSide ? '#icondaohanglantubiao_gouwuche' : '#iconic-gouwuche'} />
            </svg>
          </Badge>
        </span>
      )}

      {/* 多币种切换 */}
      {isShowMultiSettingEntry && (
        <Pop
          trigger='click'
          position='bottom-right'
          className='setting-currency-pop'
          cushion={5}
          isOutside={(target, { contentNode }) => {
            const classList = Array.prototype.slice.call(target.classList);
            if (
              contentNode?.contains(target) ||
              classList.includes('hunt-select-option') ||
              classList.includes('header-icon')
            ) {
              return false;
            }
            return true;
          }}
          content={
            <div className='settings-wrapper'>
              {isShowMultiCurrency && (
                <>
                  <div className='currency-select-label'>
                    {intl.formatMessage({
                      id: '65fff7d21c2c4aad823fc7b136258734',
                      defaultMessage: '币种',
                    })}
                  </div>
                  <Select
                    popupClassName='setting-zent-popover'
                    options={multiCurrencies}
                    value={multiCurrency}
                    optionValue='currency'
                    optionText='currency'
                    onChange={handleCurrencyChange}
                  />
                </>
              )}
              {isShowMultiLanguage && (
                <>
                  <div className='currency-select-label skiptranslate'>
                    {intl.formatMessage({
                      id: 'account_language',
                      defaultMessage: '语言',
                    })}
                  </div>
                  <Select
                    options={languageList}
                    popupClassName='setting-zent-popover'
                    value={language}
                    optionValue='language'
                    optionText='name'
                    onChange={handleLanuageChange}
                  />
                </>
              )}
            </div>
          }
        >
          <span className='header-icon-container dynamic-display'>
            <svg className={cn('icon header-icon', isSide ? 'side-header-icon' : '')} aria-hidden='true'>
              <use xlinkHref='#iconduoyuyanduobizhong1' />
            </svg>
          </span>
        </Pop>
      )}
    </Style>
  );
};
