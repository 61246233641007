import React from 'react';
import View from 'components/base/view';
import { DescStyle } from './style';

export const GoodsDesc = ({ desc }) => {
  return desc ? (
    <DescStyle>
      <View size={11}>{desc}</View>
    </DescStyle>
  ) : null;
};
