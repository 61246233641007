export const getMemberGlobalStyle = (theme) => {
  const defaultColor = {
    color_card_bg: '#FFFFFF',
    color_body_bg: '#F5F5F5',
    color_btn_primary_bg: '#D2B08E',
    color_btn_primary: '#382702',
    label_bg: '#F1E4CC',
    label_text: '#382702',
    color_title: '#CEAB72',
    color_text: '#15161B',
  };

  const globalStyle = theme?.current ?? {};
  const colorStyleType = globalStyle?.color?.style ?? 'style1';
  const colorStyle = globalStyle?.color?.settings?.[colorStyleType] ?? defaultColor;

  return {
    ...colorStyle, // 全局的颜色配置
    full_border_radius: globalStyle?.['full_border_radius'] ?? 8,
    half_border_radius: globalStyle?.['half_border_radius'] ?? 4,
    container_width: globalStyle?.['container_width'] ?? '1280', // 页面宽度
    colorStyleType, // 颜色的模版类型
  };
};
