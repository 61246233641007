import React, { useMemo } from 'react';
import { Style } from './style';
import cn from 'classnames';
import { Icon } from 'components/base';
// import { Icon } from 'zent';
import { isNaN } from 'lodash';

/**
 * 展示商品评分
 */
const GoodsScore = ({ score = 0, styleType = 'style1', className = '', scoreSize = 16 }) => {
  const scoreNum = isNaN(parseInt(score)) || parseInt(score) === 0 ? 5 : parseInt(score);
  const greyScoreNum = isNaN(parseInt(5 - score)) || scoreNum === 5 ? 0 : parseInt(5 - score);
  const halfScoreNum = score - scoreNum;
  return (
    <Style className={cn(className)} scoreSize={scoreSize}>
      {styleType === 'style1' ? (
        <>
          {Array.from(new Array(scoreNum), (item, index) => {
            return <Icon key={`start-${index}`} name='iconpingfen-xingxing-wanzheng' className='star-icon' />;
          })}
          {halfScoreNum > 0 && halfScoreNum < 1 && (
            <div className='half-star-container'>
              <Icon name='iconpingfen-xingxing-yiban' className='half-star-icon_left' />
              <Icon name='iconpingfen-xingxing-yiban' className='half-star-icon_right' />
            </div>
          )}
          {Array.from(new Array(greyScoreNum), (item, index) => {
            return <Icon key={`half-${index}`} name='iconpingfen-xingxing-wanzheng' className='grey-star-icon' />;
          })}
        </>
      ) : (
        <>
          <Icon name='iconpingfen-xingxing-wanzheng' className='star-icon' />
          <span>{score || 0}&nbsp;</span>
        </>
      )}
    </Style>
  );
};

export default GoodsScore;
