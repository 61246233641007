import jsCookie from 'js-cookie';

export const setCookie = (key, value, options) => {
  jsCookie.set(key, value, options);
};

export const getCookie = (key) => {
  return jsCookie.get(key);
};

export const removeCookie = (key) => {
  jsCookie.remove(key);
};
