import React from 'react';
import { Pop } from 'zent';
import { PolicyItemStyle, PolicyItemPopContentStyle } from './style';
import { useIntl } from 'react-intl';

interface PropsType {
  icon?: string;
  description: string;
  detailUrl: string;
  isShowBtn: boolean;
}
export const PolicyItem = (props: PropsType) => {
  const { icon, description, detailUrl, isShowBtn } = props;
  const intl = useIntl();

  const Content = Pop.withPop(function Content({ pop }) {
    return (
      <PolicyItemPopContentStyle>
        <div
          style={{
            maxWidth: '400px',
            minWidth: '100px',
            maxHeight: '120px',
            fontSize: '12px',
            color: 'rgba(var(--color_body_text_rgb),0.8)',
            overflow: 'auto',
          }}
        >
          {description}
        </div>
        {isShowBtn && (
          <div
            className='view-detail'
            onClick={() => {
              window.open(`/policy/${detailUrl}`);
              pop.close();
            }}
          >
            {intl.formatMessage({
              description: '查看详情',
              id: 'general.preview_detail',
            })}
            <svg width={12} height={12}>
              <use xlinkHref='#iconic-xiala' />
            </svg>
          </div>
        )}
      </PolicyItemPopContentStyle>
    );
  });

  return (
    <PolicyItemStyle>
      {/* <Pop trigger='hover' content={<Content />} position='bottom-left'>
                <div className='policy-item-content'>
                    <svg width={20} height={20}>
                        <use xlinkHref={icon} />
                    </svg>
                    <div className='description'>{description}</div>
                </div>
            </Pop> */}
      <div
        className='policy-item-content'
        title={description}
        onClick={() => {
          window.open(`/policy/${detailUrl}`);
        }}
      >
        <svg width={20} height={20}>
          <use xlinkHref={icon} />
        </svg>
        <div className='description'>{description}</div>
      </div>
    </PolicyItemStyle>
  );
};
