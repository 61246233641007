import React, { useEffect, useState } from 'react';
import { Form, FormStrategy, Validators, FormInputField, Button } from 'zent';
import SelectFileds from 'client/pages/components/select-fileds';
import distributionApi from 'api/distribution';
import LocalStorage from 'utils/local-storage';
import { AFFILIATE_SUB_TRACK } from 'utils/local-storage/config';
import BankDetail from 'client/pages/distribution/components/bank-detail';
import { closeApplyAffiliateDialog } from 'client/components/page/apply-affiliate-dialog';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import Style from './style';

const CompleteInfo = (props) => {
  const { intl, isReApply, userInfo = {} } = props;
  // 这里是弹窗，不会存在服务端渲染，所以可以用window
  const globalUserInfol = window.global_data?.userInfo ?? {};
  const currentUserInfo = isEmpty(globalUserInfol) ? userInfo : globalUserInfol;
  const [withdrawalWay, setWithdrawalWay] = useState([]);
  const form = Form.useForm(FormStrategy.View);
  const getAdvancedSetting = () => {
    return distributionApi.getAdvancedSetting().then((res) => {
      const { withdrawalWay: withdrawalWayList = [] } = res || {};
      const withdrawalList = withdrawalWayList.map((way) => {
        return {
          key: way,
          value: intl.formatMessage({ id: way }),
        };
      });
      const defaultPayMethod = withdrawalList[0].key;
      setWithdrawalWay(withdrawalList);
      form.patchValue({ paymentMethod: defaultPayMethod });
    });
  };

  const { firstName, lastName } = currentUserInfo || {};

  useEffect(() => {
    getAdvancedSetting();
    if (firstName) {
      form.patchValue({
        firstName,
        lastName,
      });
    }
  }, []);

  const onSubmit = () => {
    const {
      firstName,
      lastName,
      paymentMethod,
      paymentAccount,
      email,
      bank,
      bankBranch,
      bankDepositType,
      phone,
    } = form.getValue();
    const extend = {
      bank,
      bankBranch,
      bankDepositType,
    };
    const params = {
      firstName,
      lastName,
      paymentMethod,
      paymentAccount,
      email,
      extend,
      phone,
    };
    const tracking = LocalStorage.get(AFFILIATE_SUB_TRACK);
    if (tracking) {
      params.tracking = tracking;
    }
    distributionApi[isReApply ? 'reapply' : 'apply'](params)
      .then(() => {
        const pagePath = '/affiliate/dashboard';
        if (window.location.href.includes(pagePath)) {
          window.location.reload();
        } else {
          window.location.href = pagePath;
        }
      })
      .finally(() => {
        closeApplyAffiliateDialog({ refreshPage: false });
      });
  };

  return (
    <Style>
      <Form form={form} layout='horizontal' scrollToError onSubmit={onSubmit}>
        <div className={cn('input-container', { 'hidden-block': firstName && lastName })}>
          <div className='name-input mr-10'>
            <FormInputField
              name='firstName'
              validators={[
                Validators.required(
                  intl.formatMessage({
                    id: 'account_please_enter_your_first_name',
                    description: `请输入您的名字`,
                  }),
                ),
              ]}
              props={{
                placeholder: intl.formatMessage({
                  id: 'account_please_enter_your_first_name',
                  description: `请输入您的名字`,
                }),
              }}
            />
          </div>
          <div className='name-input'>
            <p className='label-text'>
              {intl.formatMessage({
                id: 'account.form.last_name',
                description: `姓`,
              })}
            </p>
            <FormInputField
              name='lastName'
              validators={[
                Validators.required(
                  intl.formatMessage({
                    id: 'account_please_enter_your_last_name',
                    description: `请输入您的姓氏`,
                  }),
                ),
              ]}
              props={{
                placeholder: intl.formatMessage({
                  id: 'account_please_enter_your_last_name',
                  description: `请输入您的姓氏`,
                }),
              }}
            />
          </div>
        </div>
        <div className='input-container'>
          <div className='name-input mr-10'>
            <SelectFileds
              name='paymentMethod'
              data={withdrawalWay}
              optionText='value'
              optionValue='key'
              validators={[
                Validators.required(
                  intl.formatMessage({
                    id: 'please_select_payment',
                    description: `请选择收款方式`,
                  }),
                ),
              ]}
            />
          </div>
          <div className='name-input'>
            <FormInputField
              name='paymentAccount'
              validators={[
                Validators.required(
                  intl.formatMessage({
                    id: 'please_receiving_account',
                    description: `请输入收款账号账号`,
                  }),
                ),
              ]}
              props={{
                placeholder: intl.formatMessage({
                  id: 'please_receiving_account',
                  description: `请输入收款账号账号`,
                }),
              }}
            />
          </div>
        </div>
        <BankDetail intl={intl} form={form} mr='mr-10' />
        <div className='name-input'>
          <FormInputField
            name='email'
            validators={[
              (value) => {
                if (!value) {
                  return;
                }
                return value
                  ? Validators.email(
                      intl.formatMessage({
                        id: 'account.form.Please_fill_in_the_correct_email',
                        description: `请填写正确的邮件`,
                      }),
                    )(value)
                  : null;
              },
            ]}
            props={{
              placeholder: intl.formatMessage({
                id: 'b48146e384c34eb9b91ab3e0ac95fa74',
                defaultMessage: '账单通知邮箱（选填）',
              }),
            }}
          />
        </div>
        <div className='name-input'>
          <FormInputField
            name='phone'
            props={{
              placeholder: intl.formatMessage({
                id: '83e522ba5b49441783985b7db136cbfa',
                defaultMessage: '联系电话（选填）',
              }),
            }}
          />
        </div>

        <Button type='primary' className='sub-btn' htmlType='submit'>
          {intl.formatMessage({
            id: 'application',
            description: `申请`,
          })}
        </Button>
      </Form>
    </Style>
  );
};

export default CompleteInfo;
