import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { Style } from './style';
import GoodsPrev from '../goods-prev';
import { useThemeContext } from '../../../../hook/global-theme';
import SwiperCore, { Navigation, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperIndicatorArrow from '../swiper-indicator-arrow';

SwiperCore.use([Navigation, Scrollbar, A11y, Autoplay]);

const GoodsCollectionWithSwiper = (props) => {
  const { goodsList, initialData, settings, className, indicatorArrowStyle } = props;
  const {
    grid = 3,
    rows = 2,
    show_comment: showComment = {},
    show_shopping_car: showShoppingCar = {},
    mobile_display_type: mobileDisplayType = {},
    show_wish: showWish,
    show_sale: showSale,
    show_vendor: showVendor,
    open_card: openCard,
    card_color: cardColor,
    show_shopping_buy: showShoppingBuy,
    product_title_align: productTitleAlign,
    show_product_name: showProductName = true,
    show_product_price: showProductPrice = true,
  } = settings;
  const { main_val: showCommentMainVal = false } = showComment;
  const { main_val: mobileDisplayTypeMainVal = 'tiled', carousel = {} } = mobileDisplayType;
  const { main_val: showShoppingCarMainVal = false } = showShoppingCar;
  const {
    style: mobileDisplayStyle = 'line-btn',
    auto_play: mobileAutoPlay = false,
    auto_play_interval: mobileAutoPlayInterval = 3,
  } = carousel;

  const themeContext = useThemeContext();
  const { clientWidth } = themeContext;

  const sliderRef = useRef(null);
  // const [autoPlayParams, setAutoPlayParams] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // 装修的时候切换屏幕大小，获取到的商品名称实际宽度不准确，需要延时获取
    const timeout = 1000; // 延时时长
    const swiperTimer = setTimeout(() => {
      sliderRef?.current?.swiper?.updateSize();
      sliderRef?.current?.swiper?.update(true);
    }, timeout);
    return () => {
      if (swiperTimer) {
        clearTimeout(swiperTimer);
      }
    };
  }, [clientWidth]);

  /** 是否播放选项 */
  useEffect(() => {
    // const autoplay = { delay: mobileAutoPlayInterval * 1000 };
    if (mobileDisplayTypeMainVal === 'carousel' && mobileAutoPlay && goodsList.length > 1) {
      sliderRef?.current?.swiper?.autoplay?.start();
    } else {
      sliderRef?.current?.swiper?.autoplay?.stop();
    }
  }, [mobileAutoPlay, mobileAutoPlayInterval, mobileDisplayTypeMainVal, goodsList]);

  const onNextClick = () => {
    sliderRef?.current?.swiper?.slideNext();
  };

  const onPrevClick = () => {
    sliderRef?.current?.swiper?.slidePrev();
  };

  const onIndexClick = (index) => {
    sliderRef?.current?.swiper?.slideToLoop(index, 1000, false);
  };

  return (
    <Style className={cn(className)}>
      <Swiper
        loop
        autoplay={{ delay: mobileAutoPlayInterval * 1000 }}
        ref={sliderRef}
        noSwiping
        pagination={false}
        spaceBetween={16}
        onSwiper={() => {
          // setSwiperStatus('loaded');
        }}
        onSlideChange={(swiper) => {
          const { realIndex } = swiper;
          setCurrentIndex(realIndex);
        }}
      >
        {goodsList?.length > 0 &&
          goodsList?.slice(0, grid * rows).map((item) => {
            return (
              <SwiperSlide key={item.goodsId}>
                <GoodsPrev
                  key={`swiper-${item.goodsId}`}
                  goodsInfo={item}
                  initialData={initialData}
                  settings={settings}
                  grid={grid}
                  commentConfig={{
                    showComment: showCommentMainVal,
                    showCommentNum: showComment?.[showCommentMainVal]?.show_comment_num,
                    style: showComment?.[showCommentMainVal]?.style,
                  }}
                  shoppingCarConfig={{
                    showShoppingCar: showShoppingCarMainVal || showShoppingCar === true,
                    style: showShoppingCar?.[showShoppingCarMainVal]?.style || 'style1',
                  }}
                  showShoppingBuy={showShoppingBuy}
                  showSale={showSale}
                  showWish={showWish}
                  showVendor={showVendor}
                  openCard={openCard}
                  cardColor={cardColor}
                  productTitleAlign={productTitleAlign}
                  showProductName={showProductName}
                  showProductPrice={showProductPrice}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      {goodsList.length > 1 && (
        <SwiperIndicatorArrow
          className='collection-swiper-indicator'
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          type={indicatorArrowStyle || mobileDisplayStyle}
          total={goodsList.slice(0, grid * rows).length}
          currentIndex={currentIndex}
          onIndexClick={onIndexClick}
        />
      )}
    </Style>
  );
};

export default GoodsCollectionWithSwiper;
