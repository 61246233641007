import React, { useEffect } from 'react';
import { RichText } from '../../../../../style/media';
import { Style } from './style';
import { useIntl } from 'react-intl';
import { getQueryString } from 'utils';
import cn from 'classnames';
import goodsApi from 'api/goods';
import SectionTitle from '../../../../../pages/components/section-title';
import { intlMessage } from 'utils/intl/formate';

export const GoodsDesc = ({ descriptionHtml, titleConfig }) => {
  const { hideTitle = false, textAlign = 'center', noLine = false } = titleConfig || {};
  const intl = useIntl();

  const getMediaLinkById = async (mediaIds) => {
    const videos = await goodsApi.getVideosByIds({ mediaIds });

    return videos.map(({ playUrl }) => playUrl);
  };

  const dealVideoSource = async () => {
    try {
      const richText = document.querySelector('#goods-detail-text');
      const videos = richText.querySelectorAll('video.video-with-id');
      const mediaIds = Array.from(videos).map((item) => {
        const { mediaId } = getQueryString(item.children[0].src) || {};

        return mediaId;
      });

      const videosUrls = mediaIds.length ? await getMediaLinkById(mediaIds) : [];

      Array.from(videos).forEach((item, index) => {
        item.children[0].src = videosUrls[index];
        item.className = item.className.replace('video-with-id', '');
        item.load();
      });
    } catch (err) {
      console.log(err, '替换视频资源异常');
    }
  };

  useEffect(() => {
    dealVideoSource();
  }, [descriptionHtml]);

  return (
    <Style>
      {!hideTitle && <SectionTitle textAlign={textAlign} noLine={noLine} title={intlMessage(intl, 'product_detail')} />}
      <div className={cn('item-wrapper', { 'remove-border': !descriptionHtml })}>
        {descriptionHtml && (
          <RichText>
            <div
              id='goods-detail-text'
              className='goods-detail-text'
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            />
          </RichText>
        )}
      </div>
    </Style>
  );
};
