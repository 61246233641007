import styled from 'styled-components';

export const Style = styled.div`
  position: fixed;
  z-index: 99;
  top: ${(props) => props.menuContentTop + 'px'};
  left: 100%;
  height: calc(100vh - ${(props) => props.menuContentTop + 'px'});
  overflow: scroll;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  background-color: ${(props) => props.theme.colorBodyBg};
  transition: left 0.3s ease-in-out;
  &.active {
    left: 0;
  }
  .seperate {
    margin-left: 4px;
    margin-right: 4px;
  }
  a {
    color: ${(props) => props.textColor};
  }
  .menu-third-item a {
    color: ${(props) => props.theme.hexToRGBA(props.submenuTextColor, 0.6)};
  }
  .menu-nav {
    padding: 24px 0;
    display: flex;
    align-items: center;
    color: $color-text;
    font-size: 14px;
  }
  .menu-nav-name {
    ${(props) => props.theme.getBaseSize([20])};
    font-weight: bold;
    margin-left: 4px;
  }
  .menu-first {
    background-color: ${(props) => props.cardColor};
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 12px;
  }
  .menu-icon {
    position: absolute;
    right: 8px;
    transition: all 0.3s ease-in-out;
    &.rotate {
      transform: rotate(90deg);
    }
  }
  .menu-first-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 26px 16px 16px;
    ${(props) => props.theme.getBaseSize([14])};
    color: $color-text;
    font-weight: bold;
  }
  .menu-first-item-title {
    flex: 1;
  }
  .menu-second {
    background: ${(props) => props.submenuCardColor};
  }
  .menu-second-item {
    position: relative;
    display: flex;
    padding: 12px 32px;
    ${(props) => props.theme.getBaseSize([13])};
  }
  .menu-second-item-title {
    flex: 1;
    a {
      color: ${(props) => props.submenuTextColor};
    }
  }
  .menu-third-item {
    padding: 10px 48px;
    ${(props) => props.theme.getBaseSize([11])};
    color: $color-text;
  }
  .menu-first-item-content,
  .menu-third {
    height: 0;
    overflow: hidden;
    &.active {
      height: auto;
    }
  }
`;
