import React from 'react';
import cn from 'classnames';

function Animation(props) {
  const { type = '', children } = props;
  const map = {
    hoverZoomIn: 'i18n-animation-hover-zoom-in',
    hoverZoomOut: 'i18n-animation-hover-zoom-out',
    fadeIn: 'i18n-animation-fade-in',
    fadeOut: 'i18n-animation-fade-in',
  };
  if (!type || !map[type]) {
    return children;
  }
  const childrenNew = React.cloneElement(children, {
    className: cn(children.props.className, map[type]),
    key: children.key,
  });

  return childrenNew;
}

export default Animation;
