import React, { useState, useEffect } from 'react';
import { Style } from './style';
import { useIntl } from 'react-intl';
import PickupLocationDialog from '../../pick-up-location-dialog';
import deliveryApi from 'api/delivery';
import { formatLocationText } from 'utils/global';

interface ILocationProps {
  address1: string;
  address2: string;
  city: string;
  country: string;
  countryCode: string;
  isDefault: number;
  isEnablePickup: number;
  locationId: number;
  name: string;
  phone: string;
  province: string;
  provinceCode: string;
  zip: string;
}

export const PickupInfo = (props) => {
  const { goodsId } = props;
  const intl = useIntl();
  const $t = (id, defaultMessage = 'default', params = {}) => {
    if (!id) return '';
    return intl.formatMessage({ id, defaultMessage }, params);
  };

  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [locationList, setLocationList] = useState<ILocationProps[]>([]);

  useEffect(() => {
    getPickUpLocation();
  }, []);

  // 获取提货点列表
  const getPickUpLocation = () => {
    deliveryApi
      .getGoodsPickupLocations({
        goodsId,
      })
      .then((res) => {
        setLocationList(res);
      });
  };

  // 关闭弹窗
  const onClose = () => {
    setVisibleDialog(false);
  };

  if (locationList.length === 0) return null;

  return (
    <Style>
      <div className='pick-up-overview'>
        <div className='location-item__left'>
          <div className='location-item__name'>{locationList[0].name}</div>
          <div className='location-item__detail'>{formatLocationText(locationList[0])}</div>
        </div>
        <div className='location-item__right'>
          <span className='location-item__more' onClick={() => setVisibleDialog(true)}>
            {$t('103498ed70d542489327ec470b73f2b7', '查看地点信息')}
            <svg className='icon icon-arrow-right' aria-hidden='true'>
              <use xlinkHref='#iconic-xiala' />
            </svg>
          </span>
        </div>
      </div>
      <PickupLocationDialog $t={$t} visibleDialog={visibleDialog} onClose={onClose} locationList={locationList} />
    </Style>
  );
};
