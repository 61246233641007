import React from 'react';
import { Dialog, Button } from 'zent';
import { QuitStyle } from './style';

const { openDialog, closeDialog } = Dialog;
const LeaveConfirm = ({ intl, callback }) => {
  const onQuit = () => {
    closeDialog('quit-confirm-dialog');
    callback();
  };
  return (
    <QuitStyle>
      <div className='quit-content'>
        <div>
          {intl.formatMessage({
            id: 'c884e7e8f7604588aa572c9c0c636a15',
            defaultMessage: '您可以在未支付订单中查询到这笔订单',
          })}
        </div>
        <div className='quit-action-wrapper'>
          <Button onClick={onQuit}>
            {intl.formatMessage({ defaultMessage: '确认退出', id: '62a3c725180740b8a51d3ddb74f6ce1c' })}
          </Button>
          <Button
            type='primary'
            onClick={() => {
              closeDialog('quit-confirm-dialog');
            }}
          >
            {intl.formatMessage({ defaultMessage: '继续支付', id: '799fdd090fc64347aec5f9ffd343c42b' })}
          </Button>
        </div>
      </div>
    </QuitStyle>
  );
};

export const onQuitConfirm = (intl, callback) => {
  openDialog({
    title: intl.formatMessage({ defaultMessage: '确认退出?', id: '0b3962369e5b403db870f4a171db5823' }),
    children: <LeaveConfirm intl={intl} callback={callback} />,
    dialogId: 'quit-confirm-dialog',
  });
};
