import React from 'react';
import { TabHeader, TabHeaderItem } from './style';

function HtTabHeader(props) {
  const { tabs = [], activeTabId = tabs[0] && tabs[0].id, onTabChange } = props;
  return (
    <TabHeader>
      {tabs.map((tabItem) => {
        return (
          <TabHeaderItem
            key={tabItem.id}
            active={activeTabId === tabItem.id}
            onClick={() => {
              onTabChange && onTabChange(tabItem.id);
            }}
          >
            {tabItem.text}
          </TabHeaderItem>
        );
      })}
    </TabHeader>
  );
}

export default HtTabHeader;
