import styled from 'styled-components';
// import { getSizeVw, getPercentWidth } from '../../../style/fn';

export default styled.div`
  .content-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    margin-left: 8px;
    .media-list-item {
      /* height: auto !important; */
    }
    .sku-block {
      padding-top: 0 !important;
    }
    .sku-title {
      font-size: 14px;
      color: #8c8c8c;
    }
  }
  .content-wrapper-for-h5 {
    display: block;
    margin-left: 0;
    .sku-container {
      padding: 16px;
    }
    .sku-block {
      margin-left: 0;
      width: 100%;
    }
  }
  .goods-img-container {
    width: 320px;
    overflow: hidden;
  }
  .close-wrapper {
    height: 72px;
    position: relative;
    .close-buy-now-icon {
      position: absolute;
      right: 24px;
      top: 24px;
      fill: var(--color_body_text);
    }
  }
  .goods-title {
    color: var(--color_body_text);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 750px) {
    width: 100%;
    .title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .goods-title {
      margin-bottom: 12px;
    }
  }
  .price {
    font-size: 32px;
    color: var(--color_sale_text);
  }

  .sku-container {
    /* background: #fff; */
    /* background: red; */
    position: relative;
    padding: 0 var(--pm-16-10) var(--pm-16-10);
    display: flex;
    justify-content: center;
    padding-bottom: 58px;
    .media-list {
      flex-wrap: nowrap !important;
    }
    .sku-goods-img {
      width: 320px;
    }
  }
  .goods-title-wrapper-h5 {
    display: flex;
    justify-content: space-between;
    .goods-main-info {
      display: flex;
      .img-wrapper {
        width: 72px;
        height: 72px;
        line-height: 72px;
        img {
          max-width: 72px;
          max-height: 72px;
        }
      }
      .title-wrapper {
        margin-left: 12px;
      }
    }
  }

  .sku-close-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sku-block {
    /* background: #fff; */
    cursor: pointer;
    padding-top: var(--pm-16-10);
    margin-left: 24px;
    width: 328px;
  }

  .close-buy-now-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
  }
  #sku-img-wrapper {
    .main-media-wrapper {
      width: 320px;
      height: 320px;
      .main-media {
        height: 320px !important;
      }
    }
    .product-media-preview {
      width: 320px !important;
    }
    .photo-loupe {
      width: 320px !important;
    }
    .photo-loupe-body {
      width: 320px !important;
      height: auto !important;
    }
  }
  .product-main-media-wrapper {
    width: auto !important;
  }
`;
