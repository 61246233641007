import React from 'react';
import { Menu } from 'zent';
import Style from './style';
import { SlideLeft } from 'cpn/slide-left';
import { getUrlFromConfig } from 'utils/formate';
import Image from 'components/base/image';
import { MenuList } from '../menu-list';

// const { MenuItem, SubMenu } = Menu;
export const MobileSubMenu = (props) => {
  const {
    menuDetail = {},
    jumpToMenuPage,
    menuHeight,
    totalCountWish,
    settings,
    initialData,
    jumpToOtherPage,
    levelOneMenuTextSize = 11,
    levelTwoMenuTextSize = 11,
    levelThreeMenuTextSize = 11,
    defaultBgColor,
    defaultTextIconColor,
  } = props;
  const {
    sub_menu_bg_color: subMenuBgColor = '#ffffff',
    sub_menu_text_color: subMenuTextColor = '#000000',
    h5_child_menu_show_type: h5ChildMenuShowType = 'dropdown',
    h5_open_icon_style: h5OpenIconStyle = 'style1',
  } = settings;
  const firstMenuList = menuDetail.shopTemplateMenuItemTreeNodeCustomerVO;
  const iconType =
    h5OpenIconStyle === 'style1' || h5ChildMenuShowType === 'clickinto' ? '#iconic-xiala' : '#iconic_open';

  return (
    <Style
      menuHeight={menuHeight}
      levelOneMenuTextSize={levelOneMenuTextSize}
      levelTwoMenuTextSize={levelTwoMenuTextSize}
      levelThreeMenuTextSize={levelThreeMenuTextSize}
      // defaultBgColor={defaultBgColor}
      // defaultTextIconColor={defaultTextIconColor}
      subMenuTextColor={subMenuTextColor}
      subMenuBgColor={subMenuBgColor}
    >
      <div className='menu-of-mobile'>
        <SlideLeft
          customClass='home-page-header-slide'
          expendIconName='iconic-zhucaidan'
          iconSize='24'
          totalCountWish={totalCountWish}
          settings={settings}
          initialData={initialData}
          jumpToOtherPage={jumpToOtherPage}
          defaultTextIconColor={defaultTextIconColor}
        >
          <MenuList
            firstMenuList={firstMenuList}
            iconType={iconType}
            subMenuOpenType={h5ChildMenuShowType}
            navPosition='horizontal'
          />
        </SlideLeft>
      </div>
    </Style>
  );
};
