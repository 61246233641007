import React from 'react';
import PhotoViewMobile from './photo-view-mobile';
import PhotoViewPc from './photo-view-pc';
import { Style } from './style';

function ImageView(props) {
  return (
    <Style className='photo-view-box'>
      <PhotoViewMobile {...props} />
      <PhotoViewPc {...props} />
    </Style>
  );
}

export default ImageView;
