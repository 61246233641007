import styled from 'styled-components';

export const Style = styled.div`
  justify-content: space-between;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  background: #333333;
  color: #fff;
  box-sizing: border-box;
  gap: 29px 40px;
  flex-wrap: wrap;
  z-index: 11;
  .gdpr-cookie-detail {
    min-width: 100px;
    flex: 1;
  }
  .gdpr-action {
    display: inline-flex;
    align-items: center;
    .privacy-policy {
      text-decoration-line: underline;
      color: #fff;
      font-size: 14px;
      line-height: 20px;
    }
    .gdpr-accept {
      padding: 8px 16px;
      background: #ffffff;
      color: #333333;
      margin-left: 16px;
      cursor: pointer;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;
