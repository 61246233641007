import { openWindow } from './share-tools';
import { ShareListType } from '../const/share-type';
import copy from 'copy-to-clipboard';
import openPosterDialog from 'cpn/open-poster-dialog';
import { Notify } from 'zent';
import shareApi from 'api/share';
import { POSTER_TYPE } from '../../server/constants/poster';

export const shareHandle = async (params) => {
  const { intl, shareType, linkUrl, goodsDetail, goodsList = [] } = params;
  const encodeUrl = encodeURIComponent(linkUrl);
  switch (shareType) {
    case ShareListType.Facebook:
      openWindow({
        url: `https://www.facebook.com/sharer.php?u=${encodeUrl}`,
      });
      break;

    case ShareListType.Twitter:
      openWindow({
        url: `https://twitter.com/share?url=${encodeUrl}`,
      });
      break;
    case ShareListType.Pinterest:
      openWindow({
        url: `https://www.pinterest.com/pin/create/button/?url=${encodeUrl}`,
      });
      break;
    case ShareListType.Line:
      openWindow({
        url: `https://social-plugins.line.me/lineit/share?url=${encodeUrl}`,
      });
      break;
    case ShareListType.WeChat:
      const imgUrl = (
        await shareApi.createPosterPicture({
          posterType: goodsDetail?.goodsId ? POSTER_TYPE.wxGoods : POSTER_TYPE.wxShop,
          qrCodeLink: linkUrl,
          goodsId: goodsDetail?.goodsId || null,
          goodsList,
        })
      )?.img;
      openPosterDialog({
        intl,
        title: goodsDetail?.goodsId ? 
          intl.formatMessage({
            id: 'share_merchandise',
          }) :
          intl.formatMessage({
            id: 'ef5d26ef3b4b48be83d685a1cdb07811',
          }),
        imgUrl,
        introduction: intl.formatMessage({
          id: '5c6e683b9db346b7830045aa941aefa7',
          defaultMessage: '微信扫一扫分享链接给好友',
        }),
      });
      break;
    case ShareListType.WhatsApp:
      openWindow({
        url: `https://api.whatsapp.com/send?text=${encodeUrl}`,
      });
      break;
    case ShareListType.Messenger:
      openWindow({
        url: `fb-messenger://share/?link=${encodeUrl}`,
      });
      break;
    case ShareListType.CopyLink:
      copy(linkUrl);
      Notify.success(intl.formatMessage({ id: 'copy_successfully' }));
      break;
    default:
      return null;
  }
};
