/**
 * 购物车api接口封装
 */
import BaseApi from './base';
import { getCartUniqueId, requestStatus } from 'helper/cart';

const getPrefixPath = (name) => {
  const prefixPath = '/api/soa/com.youzan.i18n.cart.controller.web.ShoppingCartController/';
  return prefixPath + name;
};

// 购物车接口暂时只有客户端使用，如果需要服务端调需要修改

class CartApi extends BaseApi {
  // 对购物车的方法进行拦截
  _post(url, params = {}, options = {}) {
    if (options.notVerifyRequestStatus || requestStatus()) {
      return this._requestByJson(
        url,
        { uniqueId: getCartUniqueId(), ...params },
        {
          ...options,
          method: 'POST',
        },
      );
    }
    return new Promise((resolve) => {
      resolve({});
    });
  }

  addGoods(params) {
    return this._post(getPrefixPath('addGoods'), params, {
      notVerifyRequestStatus: true,
    });
  }

  deleteGoods(params) {
    return this._post(getPrefixPath('deleteGoods'), params);
  }

  getCartGoods(params) {
    return this._post(getPrefixPath('getCartGoods'), params, {
      throttle: true,
    });
  }

  mergeCart(params) {
    return this._post(getPrefixPath('mergeCart'), params);
  }

  updateVariant(params) {
    return this._post(getPrefixPath('updateVariant'), params);
  }

  getCartCount(params) {
    return this._post(getPrefixPath('getCartCount'), params, { throttle: true });
  }

  deleteAllGoods(params) {
    return this._post(getPrefixPath('deleteAllGoods'), params);
  }

  updateGoodsNum(params) {
    return this._post(getPrefixPath('updateGoodsNum'), params);
  }

  getCartGoodsV2(params = {}) {
    return this._graphqlApi({
      query: `
        query($params: GetCartGoodsParams) {
          getCartGoods(params: $params) {
            uniqueId
            calcCartVO {
              totalPrice
              memberInfo {
                discountValue
              }
              kdtId
              discountInfo {
                ...discountInfo
              }
              applyDiscountInfos {
                ...discountInfo
              }
              joinDiscountInfos {
                ...discountInfo
              }
              lineItems {
                compareAtPrice
                disableSelect
                disableSelectMsg
                goodsStatus
                goodsId
                giftGoodsFlag
                imageUrl
                inventory
                limitRule {
                  limitPeriod
                  quota
                  quantityLimitType
                }
                minPurchaseQty
                modifyQuantity
                optionValues {
                  ...optionValues
                }
                options {
                  order
                  title
                  typeStr
                }
                price
                quantity
                selectState
                sku
                title
                variantId
                withVariants
                discountGoodsFlag
                discountCode
                discountAmount
                exceedInventory
                memberLineItemInfoVO {
                  price
                }
                memberGoodsFlag
                requiresShipping
                requiresTax
                sort
                joinPromotionIds
                promotionId
              }
            }
          }
        }
        
        fragment minimumRequirement on MinimumRequirement {
          type
          requireValue
        }
        
        fragment discountGets on DiscountGets {
          discountQuantity
          discountValue
          type
        }
        
        fragment optionValues on OptionValue {
          isDefault
          optionTitle
          order
          title
          typeStr
          variantImageTextOptionValue {
            medias {
              url
              coverUrl
            }
          }
          variantSkuColorOptionValue {
            skuColorTypeEnum
            colorCode
            displayTitleSwitch
          }
        }
        
        fragment discountInfo on DiscountInfo {
          discountTitle
          discountType
          discountGets {
            ...discountGets
          }
          limitQuotaRule {
            limitNum
            limitFrequency
          }
          minimumRequirement {
            ...minimumRequirement
          }
          promotionRules {
            discountGets {
              ...discountGets
            }
            minimumRequirement {
              ...minimumRequirement
            }
          }
          promotionId
          totalDiscountAmount
          discountMode
          discountCode
          applyDiscountStatus
          umpType
        }
      `,
      variables: {
        params: {
          uniqueId: getCartUniqueId(),
          ...params,
        }
      },
      clearNull: true,
    });
  }
}

export default new CartApi();
