import React, { Component } from 'react';
import Style from './style';

class ToastItem extends Component {
  componentDidMount() {
    const { id, duration, onClose, isShowMask } = this.props;
    this.timer = setTimeout(() => {
      if (onClose) {
        onClose(id, isShowMask);
      }
    }, duration);
  }
  // 卸载组件后，清除定时器
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  render() {
    const { text } = this.props;
    return <Style className='toast-item'>{text}</Style>;
  }
}

export default ToastItem;
