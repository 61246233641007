import styled from 'styled-components';

export const Style = styled.div`
  @media screen and (min-width: 769px) {
    .mobile-review-image {
      display: none !important;
    }
  }

  @media screen and (max-width: 768px) {
    .pc-review-image {
      display: none !important;
    }
  }
`;
