import React, { useState, useEffect } from 'react';
import { Button } from 'zent';
import { FormattedMessage } from 'react-intl';
import { checkIsGoodSoldOut } from 'helper/goods';
import { GOODS_STATUS_SELLING, SWITCH_OPEN_STATUS_ON_OFF, GOODS_FEATURE_TYPE } from '../../../../const';
import shopApi from '../../../../api/shop';
import goodsApi from '../../../../api/goods';
import { Style } from './style';
import PaypalButton from './paypal-button';
import GoodsThirdButtonList from '../goods-third-button-list';
import { TRACK } from 'const';
import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from '../../../../util/sdk/load-third-javascript';
import ThirdBtnList from '../goods-third-button-list/third-btn-list';
import { useRequireLogin } from 'cpn/require-login-wrapper';

export const GoodsBuyButton = ({ goodsDetail, cart, onAddCart, onBuyOnce, kdtId, currency, setIsNeedDisabled }) => {
  // 是否售罄
  const isSoldOut = checkIsGoodSoldOut(goodsDetail.variants) || goodsDetail?.goodsStatus === GOODS_STATUS_SELLING.DOWN
  const currentSkuItem = cart?.variantId ? goodsDetail?.variants?.find(v => v.variantId === cart?.variantId) : goodsDetail?.variants?.[0]; // 当前选中的 sku
  // 最小起购数是否超出库存数
  const isExceedMinPurchase = goodsDetail?.minPurchaseQty > goodsDetail?.quantity || (currentSkuItem && goodsDetail?.minPurchaseQty > currentSkuItem.quantity);
  
  // 是否为服务商品
  const isServiceGoods = goodsDetail?.goodsFeatureType === GOODS_FEATURE_TYPE.newService;

  const { checkedRequireLogin } = useRequireLogin();

  const [thirdPlatformBtnList, setThirdPlatformBtnList] = useState([]);
  const [thirdPlatformBaseInfo, setThirdPlatformBaseInfo] = useState({
    isEnableOutside: false,
    isEnableInside: true,
  });

  const isCloseInsideBuy = thirdPlatformBaseInfo?.isEnableOutside && !thirdPlatformBaseInfo?.isEnableInside;
  const [expressCheckoutStatus, setExpressCheckoutStatus] = useState(false);
  const [paypalReady, setPaypalReady] = useState(false);
  const [paypalGatewayId, setPaypalGatewayId] = useState('');

  // const initPaypalSdk = (id) => {
  //   loadThirdJavascript(
  //     THIRD_JAVASCRIPT_CONFIG.PAYPAL.name,
  //     `${THIRD_JAVASCRIPT_CONFIG.PAYPAL.url}${id}&currency=${currency}`,
  //   )
  //     .then(() => {
  //       setPaypalReady(true);
  //     })
  //     .catch(() => {
  //       // 报错即关闭paypal快捷支付
  //       setExpressCheckoutStatus(false);
  //     });
  // };

  // 判断是否开启了paypal 快捷支付
  useEffect(() => {
    goodsApi.getThirdPlatformGoodsLinkInfo({ goodsId: goodsDetail?.goodsId, kdtId }).then((data) => {
      const isEnableOutside = data?.enableOutside === SWITCH_OPEN_STATUS_ON_OFF.on;
      const isEnableInside = data?.enableInside === SWITCH_OPEN_STATUS_ON_OFF.on;
      setThirdPlatformBaseInfo({
        isEnableOutside,
        isEnableInside,
      });
      setIsNeedDisabled(isEnableOutside && !isEnableInside);
      if (!isEnableOutside) return;
      const { linkInfo } = data;
      const list = linkInfo.map((item) => {
        const { buttonColorCode } = item;
        if (buttonColorCode) {
          const style =
            item?.buttonColorCode?.length === 1
              ? { background: buttonColorCode[0] }
              : {
                  backgroundImage: `linear-gradient(to right, ${buttonColorCode[0]} ,  ${buttonColorCode[1]})`,
                };
          return { ...item, buttonStyle: style };
        }
        return item;
      });
      setThirdPlatformBtnList(list);
    });
  }, []);

  // 正常按钮情况下 判断是否开启了paypal 快捷支付
  // useEffect(() => {
  //     if (thirdPlatformBaseInfo.isEnableOutside) return;
  //     shopApi.getExpressPaymentParam({ kdtId, providerId: 10001 }).then((data) => {
  //         const { expressPayment, clientId, paymentGatewayId } = data;
  //         if (expressPayment === SWITCH_OPEN_STATUS_ON_OFF.on && goodsDetail?.maxPrice > 0) {
  //             setExpressCheckoutStatus(true);
  //             initPaypalSdk(clientId);
  //             setPaypalGatewayId(paymentGatewayId);
  //         }
  //     });
  // }, [thirdPlatformBaseInfo.isEnableOutside]);

  // 服务商品暂不支持
  if (isServiceGoods) {
    return (
      <div className='goods-buy-button'>
        <Button className='sold-out-button theme-sub theme-primary-disabled sealOut' disabled>
          <FormattedMessage id='9798e8f7f61b4de19e4fd5bfb80af4f5' defaultMessage="暂不支持预订" />
        </Button>
      </div>
    )
  }

  return (
    <Style>
      {/* 按钮情况比较多  这里备注下 */}
      {/* 没有开通第三方平台购买 且已经售罄 */}
      {!thirdPlatformBaseInfo.isEnableOutside && (isSoldOut || isExceedMinPurchase) && (
        <div className='goods-buy-button'>
          <Button className='sold-out-button theme-sub theme-primary-disabled sealOut' disabled>
            {
              isExceedMinPurchase ?
                <FormattedMessage id='goods.none.enough_store' defaultMessage='库存不足' /> :
                <FormattedMessage id='goods.sold_out' defaultMessage='售罄' />
            }
          </Button>
        </div>
      )}
      {/* 开通了第三方平台购买 没有保留站内购买 */}
      {thirdPlatformBaseInfo.isEnableOutside && !thirdPlatformBaseInfo.isEnableInside && (
        <GoodsThirdButtonList thirdPlatformBtnList={thirdPlatformBtnList} />
      )}

      {!isSoldOut && !isExceedMinPurchase && !isCloseInsideBuy && (
        <div className='goods-buy-button'>
          <div>
            {/* 保留站内购买  且站内没有激活paypal快捷支付 */}
            {!expressCheckoutStatus && (
              <div>
                <div>
                  <Button 
                    className={`theme-sub  ${TRACK.AddCartButton}`}
                    onClick={checkedRequireLogin(onAddCart) as any}
                  >
                    <FormattedMessage id='goods.cart.add' />
                  </Button>
                  <Button
                    type='primary'
                    className={`theme-primary goods-buy-button-2 ${TRACK.BuyOnceButton}`}
                    onClick={checkedRequireLogin(onBuyOnce) as any}
                  >
                    <FormattedMessage id='goods.buy_once' />
                  </Button>
                </div>
                {thirdPlatformBaseInfo?.isEnableOutside && <ThirdBtnList thirdPlatformBtnList={thirdPlatformBtnList} />}
              </div>
            )}
            {/* 保留站内购买  且站内激活paypal快捷支付 */}
            {/* 已停用 */}
            {/* {expressCheckoutStatus && paypalReady && (
              <div>
                <PaypalButton
                  paypalGatewayId={paypalGatewayId}
                  onAddCart={onAddCart}
                  onBuyOnce={onBuyOnce}
                  kdtId={kdtId}
                  goodsDetail={goodsDetail}
                  thirdPlatformBtnList={thirdPlatformBtnList}
                />
              </div>
            )} */}
          </div>
        </div>
      )}
    </Style>
  );
};
