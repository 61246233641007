import styled from 'styled-components';
import { getSizeVw } from '../../../style/fn';

export const Style = styled.div`
  .featured-products-share-item {
    padding: 6px ${getSizeVw(12)};
    margin-right: ${getSizeVw(8)};
    margin-top: ${getSizeVw(8)};
    cursor: pointer;
    font-size: var(--base-font-14-12);
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    display: inline-block;
    border: 1px solid var(--color_text_field_border);
  }
  .share-buttons-container {
    margin-top: var(--pm-40-20);
  }

  .featured-products-share-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: ${getSizeVw(8)};
  }

  .circular-icon-item {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
    margin-right: 16px;
  }
`;
