import styled from 'styled-components';

export const Style = styled.div`
  width: 100%;
  .area-content {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .chocolate-top,
  .chocolate-bottom {
    height: 50%;
    width: 100%;
    .image-container {
      height: 50vh;
    }
    .img {
      width: 100%;
      height: 100%;
    }
    // 防止轮播里的透明图片叠加
    .swiper-slide {
      background: #fff;
    }
  }
  // 中间图的轮播区域是本身和以上的区域，所以height=50vh+本身高度/2
  // 其里面的图靠底对齐
  .chocolate-center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    .background-image {
      height: 100%;
      box-sizing: border-box;
    }
    &.small {
      width: calc(720 / 1440 * 100vw);
      height: calc(720 / 1440 * 100vw / 2 + 50vh);
      .background-image {
        padding-top: calc(50vh - 720 / 1440 * 100vw / 2);
      }
    }
    &.large {
      width: calc(900 / 1440 * 100vw);
      height: calc(900 / 1440 * 100vw / 2 + 50vh);
      .background-image {
        padding-top: calc(50vh - 900 / 1440 * 100vw / 2);
      }
    }
  }
  .chocolate-text {
    position: absolute;
    top: ${(props) => props.theme.getSpace(80, 96, 120)};
    left: ${(props) => props.theme.getSpace(props.theme.pImagesMedium)};
    right: ${(props) => props.theme.getSpace(props.theme.pImagesMedium)};
    bottom: ${(props) => props.theme.getSpace(props.theme.pImagesMedium)};
    & > div {
      height: 100%;
    }
    .swiper-container {
      z-index: 9;
    }
    .text-container .textH {
      // 最多2行
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .swiper-slide {
      opacity: 0;
      transition: opacity 1s !important;
    }
    .swiper-slide-active {
      opacity: 1;
    }
  }
  // 动效处理
  @keyframes bottom-image {
    from {
      object-position: center bottom;
    }
    to {
      object-position: center top;
    }
  }
  .chocolate-bottom .background-image img {
    //animation: bottom-image 10s linear infinite alternate;
  }
  &.is-mobile {
    .chocolate-center {
      width: calc(343 / 375 * 100vw);
      height: calc(343 / 375 * 100vw / 2 + 50vh);
      .background-image {
        padding-top: calc(50vh - 343 / 375 * 100vw / 2);
      }
    }
  }
`;
