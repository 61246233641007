import userApi from 'api/user';
import * as cartHelper from 'helper/cart';
import { mergeCart } from 'helper/cart';
import { openApplyAffiliateDialog } from 'client/components/page/apply-affiliate-dialog';
import { IdentityList } from 'const/account';
/** 退出登录 */

export const logout = () => {
  if (window && window?.gapi) {
    const auth2 = window?.gapi.auth2.getAuthInstance();
    if (auth2) {
      auth2.signOut();
    }
  }
  userApi.logout().then(() => {
    cartHelper.removeStorage();
    window.location.href = window.location.origin;
  });
};

export const handLoginSuccess = async ({ intl, history, theme, isFromAffiliate, callbackUrl }) => {
  if (window.isEdit) return;
  mergeCart();
  if (isFromAffiliate) {
    const { identities = [] } = (await userApi.getCustomerByToken({})) || {};
    const isAffiliateRole = identities.includes(IdentityList.Affiliate);
    if (!isAffiliateRole) {
      openApplyAffiliateDialog({ intl, history, theme });
    }
    window.location.href = '/affiliate/dashboard';
    return;
  }
  if (callbackUrl) {
    window.location.href = callbackUrl;
    return;
  }
  window.location.reload();
};
