/**
 * 商品相关的api接口封装
 */
import BaseApi from './base';

class Member extends BaseApi {
  getMemberBenefits(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.member.controller.web.MemberPlanOnlineController/getMemberBenefits',
      params,
    );
  }

  getMemberPlan(params) {
    return this._get('/api/soa/com.youzan.i18n.member.controller.web.MemberPlanOnlineController/getMemberPlan', params);
  }

  getMemberStatus(params) {
    return this._get('/api/soa/com.youzan.i18n.member.controller.web.MemberOnlineController/getMemberStatus', params);
  }
  getMemberPackages(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.member.controller.web.MemberPlanOnlineController/getMemberPackages',
      params,
    );
  }
  searchGoodsWithPage(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.member.controller.web.MemberPlanOnlineController/searchGoodsWithPage',
      params,
    );
  }
  createMemberBuyOrder(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.member.controller.web.MemberBuyOrderOnlineController/createMemberBuyOrder',
      params,
    );
  }
  getMemberDiscounts(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.member.controller.web.MemberPlanOnlineController/getMemberDiscounts',
      params,
    );
  }
  getMemberAgreement(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.member.controller.web.MemberPlanOnlineController/getMemberAgreement',
      params,
    );
  }
}

export default new Member();
