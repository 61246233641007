import { Collapse } from 'zent';
import React, { useState } from 'react';
import View from 'components/base/view';
import { CollapseStyle, CollapseTitleStyle } from './style';

type Item = {
  name: string;
  key: string;
  value: string;
};

const Title = ({ title, activeStatus }) => {
  return (
    <CollapseTitleStyle>
      <View size={10}>{title}</View>
      <svg aria-hidden='true' width='16' height='16'>
        <use xlinkHref={`#${activeStatus ? 'iconic_close' : 'iconic_open'}`} />
      </svg>
    </CollapseTitleStyle>
  );
};

function HtCollapse(props) {
  const { itemsData } = props;
  const [activeKey, setActiveKey] = useState('');
  return (
    <CollapseStyle>
      <Collapse
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key as string);
        }}
        accordion
        bordered={false}
        className='ht-collapse'
      >
        {itemsData.map((item: Item) => {
          return (
            <Collapse.Panel
              panelTitleBackground='none'
              title={<Title title={item.name} activeStatus={activeKey === item.key} />}
              key={item.key}
              showArrow={false}
            >
              <View size={11}>{item.value}</View>
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </CollapseStyle>
  );
}

export default HtCollapse;
