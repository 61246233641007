// 视频组件（当前的播放按钮样式等设计师统一，不能暂停待处理）
// 可配置项src，是否自动播放autoPlay
// 视频组件的坑：1:地址需要通过接口获取  2:自动播放：pc端也需要禁止声音，在手机端有问题，手机端使用controls，  3:手机端获取不到第一侦  4.禁止安卓手机自动全屏
import React, { useState, useRef, forwardRef, useEffect } from 'react';
import Style from './style';
import cn from 'classnames';
import localApi from 'api/local';
import BackgroundImage from '../background-image';
import { useThemeContext } from '../../../../hook/global-theme';

const VideoContainer = forwardRef((props, ref) => {
  const {
    src, // 视频地址
    mediaId, // 媒体id
    autoPlay, // 自动播放
    // muted, // 禁止声音
    mutedSwitch = false,
    controls, // 显示控制器
    firstImage = '', // 第一侦图
    width = '100%',
    height = '100%',
    className,
    ...extra
  } = props;
  const defaultRef = useRef();
  const videoRef = ref || defaultRef;
  const [imgSrc, setImgSrc] = useState(src);
  const [isPlay, setIsPlay] = useState(autoPlay);
  const [openSound, setOpenSound] = useState(false);
  const themeContext = useThemeContext();
  const { isMobile } = themeContext;

  const getMediaUrl = async (videoId) => {
    const res = await localApi.getVideoPlayInfo({ mediaId: videoId });
    setImgSrc(res?.playUrl);
  };
  const onPlay = () => {
    if (!imgSrc) return;
    setIsPlay(true);
    videoRef.current.play();
  };

  useEffect(() => {
    if (mediaId) {
      getMediaUrl(mediaId);
    }
  }, [mediaId]);
  const obj = {};
  // if (muted) {
  //     obj.muted = true;
  // }
  if (controls) {
    obj.controls = 'controls';
  }

  return (
    <Style className={className} {...extra} style={{ width, height }}>
      <div className={cn('video-container')}>
        <video
          // poster={firstImage}   // 这个烂东西，在手机端没效果
          // onDataLoad={onLoadedData}
          loop='loop'
          x5-playsinline='true'
          src={imgSrc}
          autoPlay={autoPlay}
          {...obj}
          ref={videoRef}
          className='video'
          muted={!openSound}
        />
        {!isPlay && (
          <div className='pause-image'>
            <BackgroundImage fit='cover' position='left center' url={firstImage} />
            <div className='play-button' onClick={onPlay}>
              <svg className='icon play-icon' aria-hidden='true'>
                <use xlinkHref='#iconbofang' />
              </svg>
            </div>
          </div>
        )}
        {mutedSwitch && !isMobile && (
          <div className={cn('muted-switch-btn')} onClick={() => setOpenSound(!openSound)}>
            {openSound ? (
              <svg className='icon play-icon' aria-hidden='true'>
                <use xlinkHref='#iconkai' />
              </svg>
            ) : (
              <svg className='icon play-icon' aria-hidden='true'>
                <use xlinkHref='#iconguan' />
              </svg>
            )}
          </div>
        )}
      </div>
    </Style>
  );
});

export default VideoContainer;
