// 动态表单 select
import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Validators, FormSelectField } from 'zent';
import { Style } from './style';

export const FormSelect = ({ settings = {}, blocks, componentId, hasRequire, className }) => {
  const intl = useIntl();
  const { title = '', label = '', require = false } = settings || {};
  const blockValues = Object.values(blocks) || [];
  const selectItems = blockValues.map((item) => {
    const { settings = {} } = item || {};
    const { title = '' } = settings || {};
    return {
      value: title,
      text: title,
    };
  });

  return (
    <Style>
      <div className={className}>
        <p className={cn('form-title', require && 'required', hasRequire && !require && 'has-require')}>{title}</p>
        <div className={cn('form-container', hasRequire && 'has-require')}>
          {Boolean(label) && <p className='form-desc'>{label}</p>}
          <FormSelectField
            withoutLabel
            name={componentId}
            props={{
              data: selectItems,
              placeholder: intl.formatMessage({
                id: 'common_please_select',
                defaultMessage: '请选择',
                description: '请选择',
              }),
            }}
            validators={
              require
                ? [
                    Validators.required(
                      intl.formatMessage({
                        id: 'common_please_select',
                        defaultMessage: '请选择',
                        description: '请选择',
                      }),
                    ),
                  ]
                : undefined
            }
          />
        </div>
      </div>
    </Style>
  );
};
