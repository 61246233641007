/* eslint-disable no-new */
/* eslint-disable no-undef */
import { useEffect } from 'react';
import emitter from '../../event';

const useManMachineVerify = ({ id }) => {
  const { multiLanguage: language } = window.global_data?.multiLanguageInfo || {};
  const triggerCode = id;
  const captchaAppId = 2013267651;

  useEffect(() => {
    if (id && window.TencentCaptcha && language) {
      const el = document.querySelector(`#${id}`);
      new TencentCaptcha(
        el,
        captchaAppId,
        (res) => {
          if (res.ret === 0) {
            // 发送手机或者短信验证码的
            const { ticket, randstr } = res;
            emitter.emit(triggerCode, { ticket, randstr, userIp: window.global_data?.ip })
          }
        },
        { needFeedBack: false, userLanguage: language || 'en' },
      );
    }
  }, [window.TencentCaptcha, id, language]);
};

export { useManMachineVerify };
