import React from 'react';
import { HeaderOperte } from '../components/header-operte';
import HeaderLogo from '../components/header-logo';
import { PcSideSubMenu } from '../components/pc-side-submenu';
import { HeaderAnnouncement } from '../components/header-announcement';
import HeaderWrapper from './style';
import { useIntl } from 'react-intl';
import { useThemeContext } from '../../../../../hook/global-theme';
import cn from 'classnames';

export const PcSideHeader = (props) => {
  const {
    settings,
    initialData,
    elemClass,
    jumpToOtherPage,
    jumpToMenuPage,
    menuDetail,
    totalCountWish,
    checkoutHeader,
    headerGlobalStyle,
    onOpenCart,
  } = props;
  const {
    show_announcement: showAnnouncement,
    announcement_text: announcementText,
    announcement_link: announcementLink,
    color_bg: colorBg,
    color_text: colorText,
    logo,
    align_logo: alignLogo,
    menu_height: menuHeight = 72,
    default_text_icon_color: defaultTextIconColor,
    level_one_menu_text_size: levelOneMenuTextSize,
    level_two_menu_text_size: levelTwoMenuTextSize,
    level_three_menu_text_size: levelThreeMenuTextSize,
    main_menu_align: mainMenuAlign,
    nav_position: navPosition,
    show_search_icon: showSearchIcon,
    show_personal_center_icon: showPersonalCenterIcon = true,
    show_wishlist_icon: showWishlistIcon = true,
    show_shop_cart_icon: showShopCartIcon = true,
  } = settings;
  const { shopSetting = {} } = initialData;
  const themeContext = useThemeContext();
  const isMobile = themeContext.isMobile;
  const isPad = themeContext.isPad;
  let containerPadding = '24px 80px';
  let iconSize = '32';
  let absoluteClass = '';
  if (isMobile) {
    containerPadding = '14px 16px';
    iconSize = '24';
    absoluteClass = 'absolute-left-mobile';
  } else if (isPad) {
    containerPadding = '18px 40px';
    iconSize = '24';
    absoluteClass = 'absolute-left-pad';
  }
  const intl = useIntl();
  return (
    <HeaderWrapper
      gColor={headerGlobalStyle.color}
      defaultTextIconColor={defaultTextIconColor}
      mainMenuAlign={mainMenuAlign}
    >
      <div className={elemClass}>
        <HeaderAnnouncement
          showAnnouncement={showAnnouncement}
          colorBg={colorBg}
          colorText={colorText}
          announcementText={announcementText}
          announcementLink={announcementLink}
        />
        <div
          className={cn(
            'wrapper',
            { 'menu-icon-left': navPosition === 'left' },
            { 'menu-icon-right': navPosition === 'right' },
          )}
          style={{ padding: containerPadding }}
        >
          <PcSideSubMenu
            key={navPosition}
            className={cn('wrapper-sub-menu')}
            jumpToMenuPage={jumpToMenuPage}
            jumpToOtherPage={jumpToOtherPage}
            menuDetail={menuDetail}
            menuHeight={menuHeight}
            totalCountWish={totalCountWish}
            settings={settings}
            initialData={initialData}
            levelOneMenuTextSize={levelOneMenuTextSize}
            levelTwoMenuTextSize={levelTwoMenuTextSize}
            levelThreeMenuTextSize={levelThreeMenuTextSize}
            iconSize={iconSize}
            headerGlobalStyle={headerGlobalStyle}
            isMobile={isMobile}
          />
          <HeaderLogo
            className={cn(alignLogo === 'center' ? 'absolute-center' : absoluteClass, {
              'absolute-left': alignLogo === 'left',
            })}
            jumpToOtherPage={jumpToOtherPage}
            logo={logo}
            menuHeight={68}
            shopName={shopSetting.name}
            logoColor={headerGlobalStyle.color}
          />
          <HeaderOperte
            jumpToOtherPage={jumpToOtherPage}
            initialData={initialData}
            alignLogo={alignLogo}
            isMobile={isMobile}
            isSide
            isPad={isPad}
            iconColor={headerGlobalStyle.color}
            showSearchIcon={showSearchIcon}
            showPersonalCenterIcon={showPersonalCenterIcon}
            showWishlistIcon={showWishlistIcon}
            showShopCartIcon={showShopCartIcon}
            totalCountWish={totalCountWish}
            onOpenCart={onOpenCart}
          />
        </div>
      </div>
    </HeaderWrapper>
  );
};
