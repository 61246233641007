import {
  ThirdLoginWrapper,
  ThirdLoginTitleWrapper,
  ThirdLoginTitleText,
  ThirdLoginLists,
  ThirdLoginListItem,
} from './style';
import { LOGIN_CHANNELS } from '../../../../../const/index';
import HtIcon from '../../../../base/icon';

const LOGIN_ICON_MAP = {
  [LOGIN_CHANNELS.facebook]: 'iconFacebook',
  [LOGIN_CHANNELS.google]: 'icongugelogo',
  [LOGIN_CHANNELS.line]: 'iconline',
};
function ThirdLogin(props) {
  const { loginChannels = [], onLineLogin, onFbLogin, onProviderLogin, intl } = props;
  const handleClick = (item) => {
    switch (item.channelCode) {
      case LOGIN_CHANNELS.facebook:
        onFbLogin();
        break;
      case LOGIN_CHANNELS.google:
        // 这个有点复杂，但是绑定事件做的（沿用以前的逻辑）
        break;
      case LOGIN_CHANNELS.line:
        onLineLogin(item.appKey);
        break;
      case LOGIN_CHANNELS.thirdApp:
        onProviderLogin(item);
        break;
      default:
        console.error('channel 找不到');
    }
  };
  return loginChannels.length > 0 ? (
    <ThirdLoginWrapper>
      <ThirdLoginTitleWrapper>
        <ThirdLoginTitleText>
          {intl.formatMessage({
            id: 'f244555330414e81b10478a7db9d9efd',
            defaultMessage: '其他登录方式',
          })}
        </ThirdLoginTitleText>
      </ThirdLoginTitleWrapper>
      <ThirdLoginLists>
        {loginChannels.map((item, index) => {
          if (item.channelCode === LOGIN_CHANNELS.thirdApp) {
            // 图片
            return (
              <ThirdLoginListItem key={index} onClick={() => handleClick(item)}>
                <img src={item.icon} className='image-logo' />
              </ThirdLoginListItem>
            );
          } else {
            // svg
            return (
              <ThirdLoginListItem
                key={index}
                onClick={() => handleClick(item)}
                id={item.channelCode === LOGIN_CHANNELS.google ? 'customBtn' : ''}
              >
                <HtIcon name={LOGIN_ICON_MAP[item.channelCode]} size={31} />
              </ThirdLoginListItem>
            );
          }
        })}
      </ThirdLoginLists>
    </ThirdLoginWrapper>
  ) : null;
}

export default ThirdLogin;
