// 装修共用-容器组件
// 可配置项：目前只容器的宽boxWidth，needSafeSpace：是否需要预留安全边距

import React, { useEffect, useRef } from 'react';
import { Style } from './style';
import cn from 'classnames';
import { useClientWidth, useThemeContext } from 'hook/global-theme';

const BOX_WIDTH = {
  full_screen: 'full-screen',
  full_width: 'full-width',
  fix_960: 'fix-960',
  fix_1280: 'fix-1280',
  fix_1440: 'fix-1440',
  margin_80: 'margin-80',
};

function ContainerSize(props) {
  const { boxWidth = 'full_width', children, className, needSafeSpace, ...extra } = props;
  // 拿到组件真实展示的宽度高度
  const { getSpace, hContainer, clientWidth } = useThemeContext();
  const ref = useRef();
  // 根据容器计算宽度,js只处理960，1280，1440的情况，其他情况用css处理
  // 为啥要用js处理，css的min方法可能存在一些兼容问题
  // const getWidth = ()=>{
  //     // 是否预留安全边距
  //     const tempWidth = needSafeSpace ? clientWidth - 2 * parseFloat(getSpace(hContainer)) : clientWidth;
  //     console.log("&&&&&&&&&&获取到的宽度",)
  //     switch (boxWidth) {
  //         case 'fix_960':
  //             return `${Math.min(960, tempWidth)}px`;
  //         case 'fix_1280':
  //             return `${Math.min(1280, tempWidth)}px`;
  //         case 'fix_1440':
  //             return`${Math.min(1440, tempWidth)}px`;
  //         default:
  //             return `unset`;
  //     }
  // }
  // useEffect(() => {
  //     ref.current.style.width = getWidth();
  // }, [boxWidth, clientWidth]);
  return (
    <Style className={cn(className)} {...extra} needSafeSpace={needSafeSpace}>
      <div ref={ref} className={cn('container-size', BOX_WIDTH[boxWidth], { 'need-safe-space': needSafeSpace })}>
        {children && <div className='content'>{children}</div>}
      </div>
    </Style>
  );
}

export default ContainerSize;
