import { useEffect, useState } from 'react';
import LocalStorage from 'utils/local-storage';
import { buriedPoint } from 'utils/log-buried-point';
import orderApi from 'api/order';
import discountApi from 'api/discount';
import { SHOP_CART } from 'utils/local-storage/config';
import { formateGoodsToSku, formatLineItemSelected, getFormatMultipleAutoDiscountsCart } from './helper';
import * as cartHelper from 'helper/cart';
import cartApi from 'api/cart';
import pick from 'lodash/pick';
import debounce from 'lodash/debounce';
import { unstable_batchedUpdates } from 'react-dom';
import { dealCartItemQuantity } from 'helper/cart';

// 获取所有赠品
const useAllGift = (customerId) => {
  const [allGiftGoods, setAllGiftGoods] = useState([]); // 所有赠品

  /* 预请求所有赠品数据 */
  useEffect(() => {
    const getAllGiftGoods = async () => {
      const res = await discountApi.getDiscountActivityGoods({
        type: 1,
        page: 1,
        pageSize: 200,
        withVariants: 1,
        customerId,
      });
      const goodsList = res?.items ?? [];
      const skuList = formateGoodsToSku(goodsList);
      setAllGiftGoods(skuList);
    };
    getAllGiftGoods();
  }, []);

  return {
    allGiftGoods,
  };
};

/**
 * 购物车逻辑-目前复用此逻辑的是 购物车弹窗/我的购物车页面
 * 1. 从缓存获取购物车数据
 * 2. 更改购物车数量
 * 3. 删除购物车
 * 4. 应用折扣-getGoodsList 有任何变动都要调用这个数据
 */
export const useCartList = ({ customerId, needLoading = true }) => {
  const [loading, setLoading] = useState(false);
  const [cartList, setCartList] = useState([]); // 购物车所有商品【包括赠品】
  const noDiscountItems = cartList.filter((item) => !item.discountGoodsFlag); // 非参与折扣商品
  const discountItems = cartList.filter((item) => !!item.discountGoodsFlag && !item.giftGoodsFlag); // 参与折扣商品
  const selectedGoods = cartList.filter((item) => !!item.giftGoodsFlag); // 选好的Y 【买X送Y场景】
  const [discountInfo, setDiscountInfo] = useState({}); // 当前的折扣活动
  const [memberInfo, setMemberInfo] = useState({}); // 当前的折扣活动
  const { discountQuantity } = discountInfo?.discountGets ?? {}; // 【买X送Y场景】赠品要选的数量限制-不满足这个数量就不会成功参与折扣活动
  const [isEditGift, setIsEditGift] = useState(false); // 是否展示选择赠品界面-【买X送Y场景】
  const [giftGoods, setGiftGoods] = useState([]); // 当前正在选择Y【买X送Y场景】

  // const { allGiftGoods } = useAllGift(customerId);

  const [totalPrice, setTotalPrice] = useState(0);
  const [multipleAutoDiscountsCart, setMultipleAutoDiscountsCart] = useState({});
  const updateCartList = (list) => {
    setCartList(list);
    LocalStorage.set(SHOP_CART, list, -1);
  };
  /**
   * 应用自动折扣
   * 初次进入页面，需要使用
   * 更改购物车数量需要使用
   * 赠品选择点击确实时需要使用
   * @param list
   */
  const getGoodsList = (list = cartList) => {
    if (!list?.length) {
      setCartList([]);
      setDiscountInfo({});
      setTotalPrice(0);
      setMemberInfo({});
      return;
    }
    const isInShopCartPage = window?.location.pathname.indexOf('shopping-cart') > -1;
    const goodsList = list
      .filter((item) => item?.hasSelected || item?.giftGoodsFlag || !isInShopCartPage)
      .map((item) => {
        const { goodsId, quantity, variantId, giftGoodsFlag = 0, hasSelected } = item || {};
        return {
          goodsId,
          variantId,
          quantity,
          giftGoodsFlag,
          hasSelected,
        };
      });
    if (goodsList?.length === 0) {
      setDiscountInfo({});
      setTotalPrice(0);
      setMemberInfo({});
      const goodsListSelectedStatus = formatLineItemSelected([], list);
      updateCartList(goodsListSelectedStatus);
      return;
    }

    if (needLoading) {
      setLoading(true);
    }

    // 算价 获取新商品
    orderApi
      .calculateCart({
        lineItems: goodsList,
        customerId,
      })
      .then((res) => {
        const { applyDiscountInfos, joinDiscountInfos, lineItems, totalPrice } = res;

        lineItems?.forEach(item => {
          dealCartItemQuantity(item)
        })
        const data = getFormatMultipleAutoDiscountsCart(applyDiscountInfos, joinDiscountInfos, lineItems);
        const goodsListSelectedStatus = formatLineItemSelected(lineItems, list);
        unstable_batchedUpdates(() => {
          setMultipleAutoDiscountsCart(data);
          updateCartList(goodsListSelectedStatus); // 更新购物车列表
          setTotalPrice(totalPrice ?? 0);
          setMemberInfo(res?.memberInfo);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCartGoods = async () => {
    let list = (await cartHelper.getCartGoods()) || [];
    list = list.map((item) => {
      const newItem = { ...item };
      if (newItem.hasSelected === undefined) {
        newItem.hasSelected = 1;
      }
      return newItem;
    });
    LocalStorage.set(SHOP_CART, list, -1);
    // setCartList(list || []);
    getGoodsList(list);
  };

  // 更新sku
  const onCartSkuChange = async (preVariantId, currentItem) => {
    await cartApi.updateVariant({
      originItem: {
        ...pick(
          cartList.find((item) => item.variantId === preVariantId),
          ['giftGoodsFlag', 'goodsId', 'variantId', 'quantity'],
        ),
      },
      currentItem: pick(currentItem, ['giftGoodsFlag', 'goodsId', 'variantId', 'quantity']),
    });
    getCartGoods();
    // updateCartList(list);
    // getGoodsList(list);
  };

  /**
   * 【买X送Y场景】
   *  切换选择Y的选择面板
   */
  const onSwitch = () => {
    const curValue = !isEditGift;
    setIsEditGift(curValue);
  };

  /**
   * 【买X送Y场景】
   * 点击Y的选择面板的确认按钮，调用接口计算价格
   */
  const onConfirm = () => {
    const list = cartList.filter((item) => !item.giftGoodsFlag);
    setIsEditGift(false);
    getGoodsList([...list, ...giftGoods]);
  };

  /**
   *【买X送Y场景】
   * 更改赠品选择
   * type: true 选中  false 取消选中
   * */
  const onSwitchSelect = (type, cur) => {
    if (type) {
      const newItem = { ...cur, quantity: 1 };
      setGiftGoods([newItem, ...giftGoods]);
    } else {
      const index = giftGoods.findIndex((item) => item.variantId === cur.variantId);
      const list = [...giftGoods];
      list.splice(index, 1);
      setGiftGoods(list);
    }
  };

  /**
   * 【买X送Y场景】
   * 更改Y的数量
   * @param variantId
   * @param count
   */
  const onChangeGift = debounce(async (cur, count) => {
    const params = pick(cur, ['goodsId', 'variantId', 'quantity', 'giftGoodsFlag']);
    params.quantity = count;
    await cartApi.updateGoodsNum(params);
    getCartGoods();
  }, 300);

  /**
   * 更改购物车数量【不包括买X送Y中的Y】
   * @param variantId  规格ID
   * @param count 当前数量
   */
  const onChange = debounce(async (cur, count) => {
    await cartApi.updateGoodsNum({
      ...pick(cur, ['giftGoodsFlag', 'goodsId', 'quantity', 'variantId']),
      quantity: count,
    });
    getCartGoods();
  }, 300);

  // 切换购物车的勾选状态
  const onToggleSelect = (cur) => {
    const { variantId, giftGoodsFlag, hasSelected, isAllSelect, expiredVariantIdList } = cur;
    const isSetAll = isAllSelect !== undefined;
    if (isSetAll) {
      const list = cartList.map((item) => {
        if (!expiredVariantIdList.includes(item?.variantId)) {
          return { ...item, hasSelected: isAllSelect };
        }
        return item;
      });
      updateCartList(list);
      getGoodsList(list);
    } else {
      const index = cartList.findIndex(
        (item) => item?.variantId === variantId && !!item?.giftGoodsFlag === !!giftGoodsFlag, // 区分赠品和非赠品-主要适用于买X送Y场景下，X和Y中，存在相同规格的商品
      );
      cartList[index].hasSelected = Number(!hasSelected);
      updateCartList(cartList);
      getGoodsList(cartList);
    }
  };

  // 去掉过期商品的勾选状态
  const onClearExpiredSelected = (variantIdList) => {
    if (variantIdList?.length === 0) return;
    const list = cartList.map((item) => {
      if (variantIdList.includes(item?.variantId)) {
        return { ...item, hasSelected: 0 };
      }
      return item;
    });
    updateCartList(list);
    getGoodsList(list);
  };

  // 删除购物车某一项
  const onDelete = async (item) => {
    const { goodsId, variantId, giftGoodsFlag = 0 } = item;
    await cartHelper.deleteGoods({ items: [{ goodsId, variantId, giftGoodsFlag }] });
    cartHelper.notifyCartCountChange();
    getCartGoods();
    buriedPoint('remove_from_cart', { item });
  };

  // 清空购物车失效的商品 传人过期 variantId list
  const onClearExpired = (variantIdList) => {
    if (variantIdList?.length === 0) return;
    const list = cartList.filter((item) => {
      return !variantIdList.includes(item?.variantId);
    });
    updateCartList(list);
    getGoodsList(list);
  };

  useEffect(() => {
    getCartGoods();
  }, []);

  useEffect(() => {
    buriedPoint('view_cart', { source: { cartList } });
  }, []);

  const totalGift = giftGoods.reduce((pre, item) => pre + item.quantity, 0);

  return {
    loading,
    totalGift,
    discountQuantity,
    cartList, // 购物车列表
    discountInfo, // 折扣信息
    discountItems, // 折扣商品
    noDiscountItems, // 非折扣商品
    // allGiftGoods, // 买X送Y专用
    giftGoods, // 买X送Y专用
    selectedGoods, // 买X送Y专用
    isEditGift, // 买X送Y专用
    onSwitch, // 买X送Y专用
    onConfirm, // 买X送Y专用
    onChangeGift, // 买X送Y专用
    onSwitchSelect, // 买X送Y专用
    onChange,
    onDelete,
    updateCartList,
    onToggleSelect,
    onClearExpiredSelected,
    onClearExpired,
    onCartSkuChange,
    memberInfo,
    totalPrice,
    multipleAutoDiscountsCart,
  };
};
