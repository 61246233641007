import goodsApi from 'api/goods';

/**
 *  获取分组数据-用于装修组件
 * */
export const fetchGoods = async ({ kdtId, goodsId }) => {
  if (!goodsId) {
    return new Promise((resolve) => {
      resolve({});
    });
  }
  const res = await goodsApi.getGoodsById({
    kdtId,
    goodsId,
  });
  return new Promise((resolve) => {
    resolve(res);
  });
};

const getInitialProps = async (params) => {
  const { kdtId, featured_product: featuredProduct = {} } = params;
  const { goodsId } = featuredProduct;

  return fetchGoods({
    goodsId,
    kdtId,
  });
};

export default getInitialProps;
