import React from 'react';
import Style from './style';

function Badge(props) {
  const { children, color = '#fff', bgColor = 'rgba(255,101,0,1)', ...restProps } = props;
  return (
    <Style color={color} bgColor={bgColor} {...restProps}>
      {children}
    </Style>
  );
}

export default Badge;
