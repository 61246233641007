import { useState, useEffect } from 'react';
import shopApi from 'api/shop';
import isEmpty from 'lodash/isEmpty';
import { getClientLanguage } from '../../helper/multi-language';
// eslint-disable-next-line import/no-default-export
export default function useCountrySelect(props = {}) {
  const { form = {}, locale, shippingAddress } = props;
  const [countryList, setCountryList] = useState([]); // 城市数据
  const [currentAddress, setCurrentAddress] = useState(null);
  const [provinceList, setProvinceList] = useState([]); // 当前省份列表
  const [labels, setLabels] = useState({}); // 表单标题
  const [countryInfo, setCountryInfo] = useState({});
  // eslint-disable-next-line no-undef
  const cacheCountryInfo = !__isServer ? window?.countryInfo : {};

  const setOptionList = (countryCode) => {
    if (!countryList.length) return;
    const country = (countryCode ? countryList.find((item) => item.code === countryCode) : countryList[0]) || {};
    const { zones = [] } = country;
    setProvinceList(zones);
    setCountryInfo(country);
    setLabels(country?.labels || {});
  };
  /** 城市改变，重新设置城市相关信息 */
  const onCountryChange = (countryCode) => {
    if (!countryCode) return;
    setOptionList(countryCode);
  };

  /**
   * 只在初始化的时候调用
   * 设置城市信息，城市列表
   * 初始化表单数据
   * @param {*} address
   */
  const onSetAddress = (address) => {
    setOptionList(address?.countryCode);
    setCurrentAddress(address);
    setTimeout(() => {
      if (isEmpty(address)) return;
      form.initialize(address);
    }, 100);
  };
  const initCountryList = async () => {
    if (cacheCountryInfo?.locale === locale && cacheCountryInfo?.countries) {
      setCountryList(cacheCountryInfo.countries);
    } else {
      const language = getClientLanguage(window?.global_data);
      const countries = await shopApi.getCountries({ locale: language || locale || 'en' });
      setCountryList(countries);
      window.countryInfo = {
        locale,
        countries,
      };
    }
  };
  // 获取地址
  useEffect(() => {
    initCountryList();
  }, []);

  useEffect(() => {
    if (shippingAddress?.countryCode && countryList?.length) {
      // setOptionList(shippingAddress.countryCode);
      onSetAddress(shippingAddress);
    }
  }, [shippingAddress, countryList]);

  const getCountryAndProvinceName = (data = {}) => {
    let curCountryInfo = {};
    let provinceInfo = {};
    if (data.countryCode && data.provinceCode) {
      curCountryInfo = countryList.find((item) => data.countryCode === item.code);
      provinceInfo = curCountryInfo?.zones?.find((item) => data.provinceCode === item.code);
    } else if (data.countryCode) {
      curCountryInfo = countryList.find((item) => data.countryCode === item.code);
    } else if (data.provinceCode) {
      provinceInfo = provinceList?.find((item) => data.provinceCode === item.code);
    }
    return {
      country: curCountryInfo?.name || '',
      province: provinceInfo?.name || '',
    };
  };
  return {
    labels,
    countryList,
    provinceList,
    setAddress: onSetAddress,
    getCountryAndProvinceName,
    onCountryChange,
    countryInfo,
    currentAddress,
    setCurrentAddress,
  };
}
