// 对应装修-感言
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Radio, Form, FieldUtils, Input, FieldSet, CheckboxGroup, Checkbox, Select } from 'zent';
import { useI18n } from 'hook/intl';
import cn from 'classnames';
import { Style, GlobalStyle } from './style';

enum OPTION_TYPE {
  radio = 'form-radio',
  checkbox = 'form-checkbox',
  select = 'form-select',
}

enum DIRECTION {
  portrait = 'portrait', // 纵向
  transverse = 'transverse', // 横向
}

export const FormOptionContent = ({ settings = {} }) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);
  const {
    option_type: optionType = OPTION_TYPE.radio,
    option_direction: optionDirection = DIRECTION.portrait,
    options = [],
    show_other: showOther = false,
    other_title: otherTitle = '',
  } = settings;

  const optionField = Form.useField('option', optionType === OPTION_TYPE.checkbox ? [] : '');
  const otherField = Form.useField('other', '');
  const selectData = useMemo(() => {
    const data = options.map((item) => ({ ...item, text: item.value }));
    showOther && data.push({ value: `other-${otherTitle}`, text: otherTitle });
    return optionType === OPTION_TYPE.select ? data : [];
  }, [options, optionType]);

  const getValue = (e) => {
    return e.target.value;
  };

  const getCheckboxGroupValue = (checkedList) => {
    return checkedList;
  };

  const onOptionChange = FieldUtils.useMAppend(
    useCallback(() => (optionField.isTouched = true), [optionField]),
    FieldUtils.usePipe(
      optionType === OPTION_TYPE.checkbox ? getCheckboxGroupValue : getValue,
      FieldUtils.makeChangeHandler(optionField, Form.ValidateOption.Default),
    ),
  );

  const onOtherChange = FieldUtils.usePipe(
    getValue,
    FieldUtils.makeChangeHandler(otherField, Form.ValidateOption.Default),
  );

  return (
    <div className={cn('form-container', `form-direction-${optionDirection}`)}>
      {optionType === OPTION_TYPE.radio && (
        <Radio.Group value={optionField.value} onChange={onOptionChange}>
          {options.map((item) => {
            return (
              <Radio key={item.id} value={item.value}>
                {item.value}
              </Radio>
            );
          })}
          {showOther && <Radio value={`other-${otherTitle}`}>{otherTitle}</Radio>}
        </Radio.Group>
      )}
      {optionType === OPTION_TYPE.checkbox && (
        <CheckboxGroup value={optionField.value} onChange={onOptionChange}>
          {options.map((item) => {
            return (
              <Checkbox key={item.id} value={item.value}>
                {item.value}
              </Checkbox>
            );
          })}
          {showOther && <Checkbox value={`other-${otherTitle}`}>{otherTitle}</Checkbox>}
        </CheckboxGroup>
      )}
      {optionType === OPTION_TYPE.select && (
        <Select
          value={optionField.value}
          onChange={onOptionChange}
          data={selectData}
          popupClassName='form-option_select-popup'
        />
      )}

      <Input
        style={{
          display:
            (optionField.value === `other-${otherTitle}` || optionField.value.includes?.(`other-${otherTitle}`)) &&
            showOther
              ? 'block'
              : 'none',
        }}
        value={otherField.value}
        {...FieldUtils.useCompositionHandler(otherField)}
        onChange={onOtherChange}
        onBlur={useCallback(() => {
          otherField.isTouched = true;
          otherField.validate();
        }, [otherField])}
        placeholder={$fm('793a238e7ada4756b2bc4024bcf68265', '请补充描述')}
      />
    </div>
  );
};

export const FormOption = ({ settings = {}, componentId, hasRequire, className, orderNumber }) => {
  const intl = useIntl();
  const { $fm } = useI18n(intl);
  const { title, label, require, option_type: optionType = OPTION_TYPE.radio, other_title: otherTitle = '' } = settings;

  const sectionValidator = (values) => {
    if (optionType === OPTION_TYPE.checkbox && require) {
      if (!values.option?.length) {
        return {
          name: 'error',
          message: $fm('common_please_select', '请选择'),
        };
      }
      if (values.option.includes?.(`other-${otherTitle}`) && !values.other) {
        return {
          name: 'error',
          message: $fm('common_please_fill', '请填写'),
        };
      }
    } else if (require) {
      if (!values.option) {
        return {
          name: 'error',
          message: $fm('common_please_select', '请选择'),
        };
      }

      if (values.option === `other-${otherTitle}` && !values.other) {
        return {
          name: 'error',
          message: $fm('common_please_fill', '请填写'),
        };
      }
    }

    return null;
  };

  return (
    <Style className={cn(`section-${componentId}`, className)}>
      <GlobalStyle />
      <div className='form-field-item'>
        {!!title && (
          <p className={cn('form-title', { require, 'has-require': hasRequire && !require })}>
            {orderNumber ? `${orderNumber}.` : ''}
            {title}
          </p>
        )}
        <div className={cn('form-content', { 'form-has-require': hasRequire })}>
          {!!label && <p className='form-desc'>{label}</p>}
          <FieldSet name={componentId} validators={[sectionValidator]}>
            <FormOptionContent settings={settings} />
          </FieldSet>
        </div>
      </div>
    </Style>
  );
};
