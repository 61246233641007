import { Style } from './style';
import shopApi from '../../api/shop';
import { RichText } from '../../style/media';
import React, { useEffect, useState } from 'react';

const TermsOfUse = (props) => {
  const { item } = props?.match?.params || {};
  enum RuleType {
    'Shipping-Policy' = 'shipping',
    'Return-Policy' = 'refund',
    'Secure-Payment' = 'pay',
    'Terms-Of-Use' = 'agreement',
    'Privacy-Policy' = 'privacy',
    'Contact-Us' = 'contact',
    'Policy-Data' = 'Policy-Data',
  }

  enum RULE_DETAIL_TYPE {
    'Shipping-Policy' = 'shippingDetail',
    'Return-Policy' = 'refundDetail',
    'Secure-Payment' = 'payDetail',
    'Terms-Of-Use' = 'agreementDetail',
    'Privacy-Policy' = 'privacyDetail',
    'Contact-Us' = 'contactDetail',
  }

  const [descriptionHtml, setDescriptionHtml] = useState('');


  useEffect(() => {
    if (item === RuleType['Policy-Data']) { // 富文本太长, cp 翻译保存不了, 所以在网关层存一份国际化翻译数据
      const key = 'policyDataRichText';
      shopApi.getTranslateText({
        key,
      }).then(res => {
        setDescriptionHtml(res[key]);
      });
    } else {
      const ruleType = RuleType[item];
      shopApi.getRuleDetail({ ruleType }).then((data) => {
        const detail = data[RULE_DETAIL_TYPE[item]];
        setDescriptionHtml(detail);
      });
    }
  }, []);
  /*
       规则类型（RuleTypeEnum） shipping.detail:物流政策 refund.detail:退款政策 pay.detail:安全保障 agreement.detail:用户协议 privacy.detail:隐私政策 contact.detail:联系我们 null或其他：查询所有
    */
  return (
    <Style>
      <div className='policy-content'>
        <RichText>
          <div className='goods-detail-text' dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
        </RichText>
      </div>
    </Style>
  );
};

export default TermsOfUse;
