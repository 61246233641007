import { lazy, useEffect } from 'react';
import throttle from 'lodash/throttle';
import get from 'lodash/get';

function getElementTop(element) {
  let actualTop = element.offsetTop;
  let current = element.offsetParent;

  while (current !== null) {
    actualTop += current.offsetTop;
    current = current.offsetParent;
  }

  return actualTop;
}
function isInViewPortOfOne(el) {
  // viewPortHeight 兼容所有浏览器写法
  const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  // const { offsetTop } = el;
  const topReal = getElementTop(el);
  const { scrollTop } = document.documentElement;
  const top = topReal - scrollTop;
  // 这里有个+100是为了提前加载+ 100
  return top <= viewPortHeight + 100;
}

function removeAnimated() {
  // console.log('removeAnimated');
  const animationDoms = document.querySelectorAll('.has-animated');
  animationDoms.forEach((item) => {
    item.classList.remove('.has-animated');
  });
}

export default function useGlobalAnimation(theme) {
  // 动效相关
  const {
    open_animation: openAnimation = false,
    open_page_transition: openPageTransition = false,
    animation_type: animationType = 'fade_in',
  } = get(theme, 'current', {});
  const animationClassMap = {
    fade_in: 'fadeIn',
    fade_in_up: 'fadeInUp',
    fade_in_left: 'fadeInLeft',
  };

  function lazyload() {
    console.log('lazyload animationType', animationType);
    const animationDoms = document.querySelectorAll('.shown-on-scroll:not(.has-animated)');
    animationDoms.forEach((item) => {
      if (isInViewPortOfOne(item)) {
        console.log('inviewport', item);
        item.className += ` animated has-animated ${animationClassMap[animationType]}`;
        if (item.className.indexOf('section-header') > -1) {
          setTimeout(() => {
            // header fixed定位失效问题解决
            item.className = item.className.replace(
              `shown-on-scroll animated has-animated ${animationClassMap[animationType]}`,
              'opacity1',
            );
          }, 1000);
        }
        if (item.className.indexOf('hero-section') > -1) {
          setTimeout(() => {
            // hero  fixed定位失效问题解决
            item.className = item.className.replace('fadeInLeft', 'opacity1');
          }, 1000);
        }
      }
    });
  }

  // 思路 滚动到视区才添加相关的动效类名
  const animationSelectorClass = openAnimation ? 'shown-on-scroll' : '';

  useEffect(() => {
    if (openAnimation) {
      lazyload(); // 加载完成后执行动效
      setTimeout(() => {
        // 轮播图1.0似乎会修改dom结构 导致类名没加上 具体原因还未查明 先这样hack修复一下
        lazyload();
      }, 100);
      const fun = throttle(lazyload, 100);
      window.addEventListener('scroll', fun);
      return () => {
        console.log('unregister fun');
        window.removeEventListener('scroll', fun);
        removeAnimated();
      };
    }
  }, [openAnimation, animationType]);

  useEffect(() => {
    if (openAnimation) {
      setTimeout(() => {
        lazyload();
      }, 100);
    }
  });

  useEffect(() => {
    if (openPageTransition) {
      document.body.classList.add('loaded');
      // document.body.classList.add('page-fade');
      window.addEventListener('beforeunload', function () {
        document.body.classList.add('unloading');
      });
    }
  }, [openPageTransition]);
  return { animationSelectorClass };
}
