import LocalStorage from '../util/local-storage';
import SessionStorage from '../util/session-storage';
import { DISTRIBUTION_COOKIES } from '../util/local-storage/config';
import { DISTRIBUTION_SESSIONS, DISTRIBUTION_SESSIONS_SHARD } from '../util/session-storage/config';

export const getDistributionCookies = () => {
  const cookies = LocalStorage.get(DISTRIBUTION_COOKIES) || '{}';
  const sessions = SessionStorage.getSessionStorage(DISTRIBUTION_SESSIONS) || '{}';
  if (cookies.expiresAt) {
    return cookies;
  }
  return JSON.parse(sessions);
};
