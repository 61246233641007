import React, { useState } from 'react';
import { Input, Notify } from 'zent';
import { useIntl } from 'react-intl';
import { intlMessage as $t } from 'utils/intl';
import StoreApi from 'api/store';
import cn from 'classnames';
import { Style } from './style';
import { Background } from '../background';
import { Text } from 'components/base';
import NewButton from 'components/base/new-button';

import TextPosition from '../text-position';

function Newsletter(props) {
  const { settings = {}, elemClass, initialData } = props;
  console.log('Newsletter props', props);

  const {
    btn_title: btnTitle = '',
    text = '',
    title = '',
    title_color,
    text_color,
    text_position,
    placeholder: buttonTips,
    background_style,
    background_color,
    background_image,
    title_size,
    text_size,
    color_button_bg: colorButtonBg,
    color_button_text: colorButtonText,
  } = settings;

  const intl = useIntl();

  const { kdtId } = initialData;

  const [value, setValue] = useState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const createCustomer = () => {
    if (!value || value.indexOf('@') === -1) {
      Notify.error($t(intl, 'pleaseInputEmailByValid'));
      return;
    }
    setIsLoading(true);
    StoreApi.subscribe({
      email: value,
      kdtId,
      acceptsMarketing: 1, // 订阅状态
    })
      .then((res) => {
        setIsLoading(false);
        Notify.success(res?.message || 'Successful.');
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const Bg = Background(background_style);
  return (
    <Style className={cn(elemClass)} style={props.style}>
      <Bg className='news-letter' backgroundColor={background_color} backgroundImage={background_image}>
        <TextPosition position={text_position}>
          <div
            className={cn('content', {
              [`content-${text_position.split(' ')[0]}`]: true,
            })}
          >
            <Text as='div' color={title_color} size={title_size} className='news-letter-title'>
              {title}
            </Text>

            <Text
              as='div'
              color={text_color}
              size={text_size}
              className='news-letter-text'
              dangerouslySetInnerHTML={{ __html: text }}
            />

            <div className='input-btn'>
              <Input
                size='large'
                className='footer-news-email-input'
                placeholder={buttonTips}
                // value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
              <NewButton
                size='small'
                className='footer-news-email-btn'
                type='primary'
                bgColor={colorButtonBg}
                color={colorButtonText}
                onClick={createCustomer}
                title={buttonTips}
              >
                {btnTitle || 'SUBSCRIBE'}
              </NewButton>
            </div>
          </div>
        </TextPosition>
      </Bg>
    </Style>
  );
}

export default Newsletter;
