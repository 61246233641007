import styled from 'styled-components';

export default styled.div`
  margin-top: ${(props) => (props.labelType === 'inner' ? '16px' : 0)};
  .filed-item {
    position: relative;
    .hunt-select-trigger {
      input {
        outline: none;
        height: 96%;
      }
    }
  }

  .filed-name {
    color: #9b9ea4;
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 12px;
    padding-left: 8px;
    position: absolute;
    top: 10px;
    z-index: 2;
  }
  .zent-form-control {
    margin-bottom: 0px;
  }
  .input-warp {
    border: none;
  }
  .zent-popover-wrapper {
    display: block;
  }
  .zent-popover-wrapper {
    display: block !important;
  }
  .zent-select-text {
    height: 56px;
    line-height: 56px !important;
    padding-left: 8px !important;
    /* padding-top: 26px; */
  }
  // .filed-item .zent-select-tags {
  //     line-height: 56px !important;
  // }
`;
