import LocalStorage from './index';

export const GOODS_INFO = 'goodsList';
export const GOODS_BUY_ONCE = 'goodsBuyOnce';
export const SHOP_CART = 'shopCart';
export const GROUP_BUYING = 'groupBuying';
export const AUTO_DISCOUNT_CART = 'autoDiscountCart';
export const WISH_COUNT_CHANGE = 'wishChangeCount';
export const DISTRIBUTION_COOKIES = 'distribution_cookies';
export const SAVE_USER_TEMP_ADDRESS = 'save_user_temp_address';
export const SAVE_USER_BILL_ADDRESS = 'save_user_bill_address';
export const AFFILIATE_SUB_TRACK = 'affiliate_sub_track';
export const WARM_NOTE_IDS_AFTER_SHOW = 'warm_note_ids_after_show'; // key用来缓存自定义政策弹窗的弹窗id
export const POP_SHOW_ONCE_IDS = 'pop_show_once_ids'; // 弹窗只展示一次的id存储列表（方便后续拓展），数据格式： { [弹窗类型]： 对应类型的id列表 }
export const GDPR_COOKIE_STATUS = 'gdpr_cookie_status'; // Cookie使用提示
export const SHOP_EXCHANGE = 'exchange'; // 积分兑换

/**
 * 删除自动折扣的缓存数据
 * */
export const removeStorageOfAutoDiscount = () => {
  LocalStorage.remove(AUTO_DISCOUNT_CART);
};
