import React, { useState, useEffect } from 'react';
import { Wrapper } from 'components/base';
import { GoodsImageContainer } from 'components/page/goods-detail/image-container';
import { Style, BaseGoodsStyle, ProductEmpty } from './style';
import useScrollContainer from './hooks/useScrollContainer';
import ContainerSize from '../../../../pages/design/components/container-size';
import { ContextGoodDetail } from '../../../../pages/context/context-good-detail';
import { DetailContainer } from './detail-container';
import cn from 'classnames';
import { getVariantMediaIndex, getProductMedia } from 'helper/goods';
import { Button } from 'zent';
import { useIntl } from 'react-intl';
import { get } from 'lodash';

enum LayoutType {
  UpDown = 'layout_style_up_down', // 上下布局商品详情信息【详情/评价】 在【商品基础信息】的下面
  LeftRight = 'layout_style_left_right', // 左右布局 商品详情信息【详情/评价】商品购买按钮那边
}

/** 拼团详情页  */
export const GoodsLayout = (props) => {
  const {
    kdtId,
    baseContainer, // 商品基础信息【价格等】
    expandContainer, // 扩展信息
    currency,
    language,
    detailConfigInfo,
    baseConfigInfo,
    baseImageStyle, // 图片的样式-样式 样式2 样式3
    // layoutStyle,
    globalSetting = {},
    goodsDetail = {},
    userInfo = {},
    isMobile,
    cartHook = {},
    reviewHook = {},
    elemClass,
  } = props;

  const history = get(props, 'history', null);

  const intl = useIntl();

  const { cart } = cartHook;
  const {
    layout_style: layoutStyle = LayoutType.LeftRight, //
    box_width: boxWidth = 'fix_1440',
  } = globalSetting;
  const isScroll = baseImageStyle === 'style2' || baseImageStyle === 'style3';
  const { containerLeft, containerRight, contentLeft, contentRight } = useScrollContainer(
    isScroll,
    baseConfigInfo?.layout === 'right',
  );

  const media = getProductMedia(goodsDetail?.media, goodsDetail?.variants);
  const [mediaList, setMediaList] = useState(media || []);
  const [mainMediaIndex, setMainMediaIndex] = useState(0);
  const [mainMediaSrc, setMainMediaSrc] = useState('');

  const goodsDetailProviderData = {
    imageRatio: baseConfigInfo?.image_ratio || 'adapt',
    layoutStyle: baseImageStyle,
    productMedia: {
      mainMediaIndex,
      mediaList,
      mainMediaSrc,
    },

    updateMainMediaIndex: setMainMediaIndex,
    updateProductMediaList: setMediaList,
  };

  useEffect(() => {
    const { isSelectedComplete, goodsSkuUrl, imageUrl } = cart;

    if (isSelectedComplete) {
      setMainMediaSrc(goodsSkuUrl || imageUrl);
      if (goodsSkuUrl || imageUrl) {
        setMainMediaIndex(-1);
      }
    } else {
      setMainMediaSrc(goodsSkuUrl);
      setMainMediaIndex(goodsSkuUrl ? -1 : 0);
    }
  }, [cart, cart.isSelectedComplete, cart.goodsSkuUrl, cart.imageUrl, cart.withVariants]);

  const reviewProps = {
    detailConfigInfo,
    layoutStyle,
    LayoutType,
    isMobile,
    goodsDetail,
    // showReview,
    // reviewTotalCount,
    reviewHook,
    userInfo,
    kdtId,
  };

  return (
    <ContextGoodDetail.Provider value={goodsDetailProviderData}>
      <Wrapper marginBottom='vContainer'>
        <ContainerSize boxWidth={boxWidth} needSafeSpace>
          {goodsDetail?.goodsId ? (
            <Style
              className={cn('goods-detail-layout', elemClass, {
                'is-scroll-animation': isScroll,
                'is-mobile': isMobile,
              })}
            >
              <>
                <BaseGoodsStyle className={cn('goods-detail-top-content', baseImageStyle, baseConfigInfo?.layout)}>
                  <GoodsImageContainer
                    baseImageStyle={baseImageStyle}
                    baseConfigInfo={baseConfigInfo}
                    goodsDetail={goodsDetail}
                    containerLeft={containerLeft}
                    contentLeft={contentLeft}
                  />

                  {/* 右侧商品信息 */}
                  <div className='detail-right-container' ref={containerRight}>
                    <div className='detail-right-content' ref={contentRight}>
                      {baseContainer}
                      {/* 商品详细和评论-左右布局展示 */}
                      {layoutStyle === LayoutType.LeftRight && <DetailContainer {...reviewProps} />}
                    </div>
                  </div>
                </BaseGoodsStyle>
                {/* 商品详细和评论-上下布局展示 */}
                {layoutStyle === LayoutType.UpDown && <DetailContainer {...reviewProps} />}
              </>

              {/* 其他信息 */}
              {expandContainer}
            </Style>
          ) : (
            <ProductEmpty>
              <div className='empty-product-container'>
                <svg className='empty-product-icon'>
                  <use xlinkHref='#iconshangpinyishixiao' />
                </svg>
                <p className='tip-text'>
                  {intl.formatMessage({
                    id: 'order.item.all.invalid',
                    defaultMessage: '商品已失效',
                  })}
                </p>
                <Button
                  type='primary'
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  {intl.formatMessage({
                    id: '11858eaafcd444069a3f15c9a4510f8b',
                    defaultMessage: '回到首页',
                  })}
                </Button>
              </div>
            </ProductEmpty>
          )}
        </ContainerSize>
      </Wrapper>
    </ContextGoodDetail.Provider>
  );
};
