// 对应装修-感言
import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Radio, FormRadioGroupField, Validators } from 'zent';
import { Style } from './style';

export const FormRadio = ({ settings = {}, blocks, componentId, hasRequire, className }) => {
  const intl = useIntl();
  const { title, label, require, direction } = settings || {};
  const radios = Object.values(blocks) || [];
  const DIRECTION = {
    portrait: 'portrait', // 纵向
    transverse: 'transverse', // 横向
  };
  const radioContainerClassName = direction === DIRECTION.portrait ? 'form-radio-portrait' : 'form-radio-transverse';

  return (
    <Style>
      <div className={className}>
        <p className={cn('form-title', require && 'required', hasRequire && !require && 'has-require')}>{title}</p>
        <div className={cn(hasRequire && 'has-require')}>
          {Boolean(label) && <p className='form-desc'>{label}</p>}
          <div className='form-container'>
            <FormRadioGroupField
              withoutLabel
              name={componentId}
              validators={
                require
                  ? [
                      Validators.required(
                        intl.formatMessage({
                          id: 'common_please_select',
                          defaultMessage: '请选择',
                          description: '请选择',
                        }),
                      ),
                    ]
                  : undefined
              }
            >
              <div className={cn('form-container', radioContainerClassName)}>
                {radios.map((item) => {
                  const { title } = item?.settings || {};
                  return (
                    <div className='form-item'>
                      <Radio value={title}>{title}</Radio>
                    </div>
                  );
                })}
              </div>
            </FormRadioGroupField>
          </div>
        </div>
      </div>
    </Style>
  );
};
