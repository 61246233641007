import { Icon } from 'zent';
import cn from 'classnames';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DefaultImg } from 'cpn/default-img';
import { jumpToPage } from 'utils/web-tool';
import NewLink from 'cpn/new-link';
import { getDesignLocalText } from 'utils/i18n';
import TextPosition from '../../../snippets/text-position';
import NewButton from 'components/base/new-button';
import Image from 'components/base/image';

const dealTextAlign = (str) => {
  const align = str?.split(' ');
  const [horizontal, vertical] = align;
  return `text-${horizontal}-${vertical}`;
};

export const ImageItem = ({ settings, globalSettings }) => {
  const {
    image,
    slide_title: slideTitle,
    subheading,
    compress,
    alignment = 'center center',
    button_label: buttonLabel,
    button_link: buttonLink,
    show_overlay: showOverlay,
    text_alignment: textAlignment = 'center center',
    color_text: colorText = '#fff',
    pic_link: picLink = '',
  } = settings;

  const { layout } = globalSettings;
  const linkUrl = JSON.parse(buttonLink || null)?.url;
  const alignmentClass = `img-${alignment.split(' ').join('-')}`;
  const slideItemText = () => {
    return (
      <>
        <h3 className='slide-item-title'>{getDesignLocalText(slideTitle)}</h3>
        <div className='slide-item-desc'>
          <p
            dangerouslySetInnerHTML={{
              __html: getDesignLocalText(subheading) || '',
            }}
          />
        </div>
        {buttonLabel ? (
          <div className='slide-item-btn-wrapper'>
            <NewButton type='arrow' href={linkUrl}>
              {buttonLabel}
            </NewButton>
          </div>
        ) : null}
      </>
    );
  };
  return (
    <div
      className={cn('slide-item')}
      onClick={() => {
        jumpToPage(linkUrl);
      }}
    >
      <div className='slide-item-img-wrapper'>
        <NewLink href={picLink} target='_blank' style={{ display: 'block' }} onClick={(e) => e.stopPropagation()}>
          {image ? (
            <Image className={cn('slide-item-img', alignmentClass)} compress={compress} src={image} alt={slideTitle} />
          ) : (
            <DefaultImg customName='slide-item-img' width='auto' height='41%' />
          )}
          {showOverlay ? <div className='slide-img-mask' /> : null}
        </NewLink>
      </div>
      {layout === 'full' ? (
        <TextPosition
          className={cn('slide-item-text', 'text-pc')}
          textPosition={textAlignment}
          style={{ color: colorText }}
        >
          {slideItemText()}
        </TextPosition>
      ) : (
        <div className={cn('slide-item-text', 'text-pc')} style={{ color: colorText }}>
          {slideItemText()}
        </div>
      )}
      <div className='width-wrapper'>
        <div className={cn('slide-item-text', 'text-mobile')} style={{ color: colorText }}>
          {slideItemText()}
        </div>
      </div>
    </div>
  );
};
