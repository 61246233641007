import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import { Style, Container } from './style';

import Carousel from '../../components/carousel';
import CustomMenu from '../../components/custom-menu';
import Hero from '../../components/hero';
import LoginRegisterModule from '../../components/login-register-module';
import Newsletter from '../../components/newsletter';
import MobileMenu from '../../components/custom-menu/components/mobile-menu';

import { Background } from '../../components/background';

function ComprehensiveNav(props) {
  const {
    settings: {
      box_width: boxWidth,
      show_margin: showMargin,
      show_module_spacing: showModuleSpacing,
      module_radius: moduleRadius,
      background_style: backgroundStyle,
      background_color: backgroundColor,
      background_image: backgroundImage,
    },
    elemClass,
    initialData,
    blocks,
    block_order: blockOrder,
  } = props;

  const blocksValue = Object.values(props.blocks);
  const customMenuInfo = blocksValue.find((block) => block.type === 'custom-menu');
  const carouselInfo = blocksValue.find((block) => block.type === 'carousel');

  const moduleFn = (blockType) => {
    if (blockType === 'login-register-module') return LoginRegisterModule;
    if (blockType === 'newsletter') return Newsletter;
    if (blockType === 'hero') return Hero;
  };
  const heightBase = 560; // 1440高度
  const calHeight = (boxWidth) => {
    // 根据boxwidth 确定宽度

    let width = 0;
    switch (boxWidth) {
      case 'full_width':
        width = document.body.clientWidth;
        break;
      case 'fix_1440':
        width = 1440;
        break;
      case 'fix_960':
        width = 960;
        break;
      case 'fix_1280':
        width = 1280;
        break;

      case 'margin_80':
        width = document.body.clientWidth - 160;
        break;
    }
    const rate = heightBase / 1440;
    if (showModuleSpacing) {
      return width * rate + 16;
    } else {
      return width * rate;
    }
  };
  const [height, setHeight] = useState(560);
  useEffect(() => {
    setHeight(calHeight(boxWidth));
  }, [boxWidth]);

  const Bg = Background(backgroundStyle);

  // h5 高度

  const [isH5, setIsH5] = useState(false);

  useEffect(() => {
    const calH5AndPcHeight = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 800) {
        const heightRatio = 375 / 394;
        const height = heightRatio * screenWidth;
        setHeight(height);
      } else {
        setHeight(calHeight(boxWidth));
      }
    };

    const calcH5 = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 800) {
        setIsH5(true);
      } else {
        setIsH5(false);
      }
    };

    const throttleCalH5AndPcHeight = throttle(calH5AndPcHeight, 200);
    calH5AndPcHeight();
    calcH5();

    window.addEventListener('resize', () => {
      throttleCalH5AndPcHeight();
      calcH5();
    });
  }, []);

  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Style className={cn(elemClass, `cn-${boxWidth}`)} borderRadius={isH5 ? moduleRadius * 0.5 : moduleRadius}>
        <Container
          height={height}
          showMargin={showMargin}
          showModuleSpacing={showModuleSpacing}
          style={{ position: 'relative', overflow: 'hidden' }}
        >
          {isH5 ? (
            <MobileMenu style={{ borderRadius: isH5 ? moduleRadius * 0.5 : moduleRadius }} {...customMenuInfo} />
          ) : (
            <CustomMenu style={{ borderRadius: isH5 ? moduleRadius * 0.5 : moduleRadius }} {...customMenuInfo} />
          )}
          <Carousel
            style={{ borderRadius: isH5 ? moduleRadius * 0.5 : moduleRadius, overflow: 'hidden' }}
            {...carouselInfo}
          />
          {blockOrder.map((blockId, index) => {
            if (index === 0 || index === 1) return null; // 菜单和轮播图一定有

            const block = blocks[blockId];
            const blockType = block.type;
            const RenderEle = moduleFn(blockType);
            return (
              <RenderEle
                key={blockId}
                {...block}
                initialData={initialData}
                style={{
                  gridArea: `single-module-${Math.floor((index + 1) / 4) + 1}`,
                  borderRadius: isH5 ? moduleRadius * 0.5 : moduleRadius,
                  overflow: 'hidden',
                }}
              />
            );
          })}
        </Container>
      </Style>
    </Bg>
  );
}

export default ComprehensiveNav;
