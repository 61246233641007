import React from 'react';
import Style from './style';

function Container(props) {
  const { children, maxWidth = 'full', as = 'div' } = props;
  return (
    <Style maxWidth={maxWidth} as={as}>
      {children}
    </Style>
  );
}

export default Container;
