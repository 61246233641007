/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable indent */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { SectionsMap } from '../../../design/config/sections_map';
import { ContextManager } from '../../../context/context-manager';
import ShotCut from '../shortcut';
import throttle from 'lodash/throttle';
import { MessageType } from 'const';
import Style from './style';
import { FIXED_SECTIONS, COLLECTION_PAGE_SECTIOCNS } from 'const';

// secitonName 是为了product-template 和分组这两个组件准备的
export const DecorationWrapper = (SectionEle, sectionName) => (props) => {
  // console.log('sectionName',sectionName);
  // 暂时没看到在这里用hook的问题 也没想到其他好的方法
  const { changeDecorationActiveFun: callbackFun, decorationActive: activeFather, isEdit } =
    useContext(ContextManager) || {};
  // eslint-disable-next-line prefer-const
  let { decorationActive: active, isFirst = false, isCustom, theme = {}, hashId, ...otherProps } = props;
  const {
    open_animation: openAnimation = false,
    open_page_transition: openPageTransition = false,
    animation_type: animationType = 'fade_in',
  } = get(theme, 'current', {});

  const [name, setName] = useState(SectionEle.name);
  const EditRely = typeof window === 'undefined' ? null : window?.EDIT_INFO;
  useEffect(() => {
    let funType = '';
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(SectionsMap)) {
      // console.log('key,value', key, value, SectionEle);
      if (value.component === SectionEle || value === SectionEle) funType = key;
    }
    if (sectionName === 'product-template') {
      funType = 'product-template';
    }
    if (sectionName === 'collection-template') {
      funType = 'collection-template';
    }
    setName(window?.EDIT_INFO?.[funType]?.name || funType);
  }, [EditRely]);

  const [styleInfo, setStyleInfo] = useState({ height: 'initial', top: 'initial', position: 'initial' });

  const handleScroll = () => {
    let className = 'fixed_header';
    switch (sectionName) {
      case 'announcement-bar':
        className = 'section-announcement-bar';
        break;
      case 'header-v2':
        className = 'fixed_header';
        break;
      default:
        className = 'fixed_header';
        break;
    }
    const dom = document.getElementsByClassName(className)[0];
    if (!dom) return;

    const BoundingClientRect = dom.getBoundingClientRect();
    const newStyle = {
      height: `${BoundingClientRect.height}px`,
      top: `${dom.style.top}`,
      position: dom.style.position || 'absolute',
      'z-index': '1001',
    };
    setStyleInfo(newStyle);
  };
  const handleScrollThrottle = throttle(handleScroll, 100);
  useEffect(() => {
    if (['header-v2', 'header', 'announcement-bar'].includes(sectionName)) {
      let className = 'fixed_header';
      // 特殊处理pc头部逻辑
      switch (sectionName) {
        case 'announcement-bar':
          className = 'section-announcement-bar';
          break;
        case 'header-v2':
          className = 'fixed_header';
          break;
        default:
          className = 'fixed_header';
          break;
      }
      const dom = document.getElementsByClassName(className)[0];
      if (!dom) return;

      const BoundingClientRect = dom.getBoundingClientRect();
      const newStyle = {
        height: `${BoundingClientRect.height}px`,
        top: `${dom.style.top}`,
        position: dom.style.position || 'absolute',
        'z-index': '100',
      };
      setStyleInfo(newStyle);

      window.addEventListener('scroll', handleScrollThrottle, false);
      return () => {
        window.removeEventListener('scroll', handleScrollThrottle, false);
      };
    }
  }, []);

  if (typeof isEdit !== 'undefined' && !isEdit) return <SectionEle {...otherProps} />;
  if (!SectionEle) return null;

  if (typeof activeFather !== 'undefined') active = activeFather;
  const onWrapperedClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const callback = props.changeDecorationActiveFun || callbackFun;
    callback && callback(props.hashId || sectionName, active, true);
  };
  const onWrapperedClickFun = props.onClick
    ? () => {
        props.onClick();
        onWrapperedClick();
      }
    : onWrapperedClick;

  const contentForIndex = get(theme, 'current.content_for_index') || [];
  const moveConfig = useMemo(() => {
    // [1,2,3,4,5,6,7]
    const config = {
      up: true,
      down: true,
    };
    const idx = contentForIndex.findIndex((id) => id === hashId);
    if (idx === 0) {
      config.up = false;
    }
    if (idx === contentForIndex.length - 1) {
      config.down = false;
    }
    return config;
  }, [hashId, contentForIndex]);

  const showOperate = useMemo(() => {
    return (
      FIXED_SECTIONS.findIndex((id) => id === hashId) === -1 &&
      COLLECTION_PAGE_SECTIOCNS.findIndex((id) => id === (hashId || sectionName)) === -1
    ); // 在不影响原来逻辑的基础上，加上商品分组页面的组件无法操作的判断
  }, [FIXED_SECTIONS, COLLECTION_PAGE_SECTIOCNS]);
  // c端并没有维护这个名字翻译 必须由b端传过来 暂时用函数名替代一下
  const onSectionEdit = (type, sectionHashId) => {
    window.parent.postMessage(
      {
        type,
        payload: {
          sectionHashId,
        },
      },
      '*',
    );
  };
  const onSectionMoveUp = () => {
    if (moveConfig.up) {
      onSectionEdit(MessageType.SECTION_MOVE_UP, props.hashId);
    }
  };
  const onSectionMoveDown = () => {
    console.log('moveConfig', moveConfig);
    if (moveConfig.down) {
      onSectionEdit(MessageType.SECTION_MOVE_DOWN, props.hashId);
    }
  };
  const onSectionDelete = () => {
    onSectionEdit(MessageType.SECTION_DELETE, props.hashId);
  };
  const onSectionCopy = () => {
    onSectionEdit(MessageType.SECTION_COPY, props.hashId);
  };

  const Wrappered = (
    <Style
      className={cn({ active }, 'decoration-wrapper', sectionName)}
      styleInfo={['header-v2', 'header', 'announcement-bar'].includes(sectionName) ? styleInfo : {}}
      openAnimation={openAnimation}
      onClick={onWrapperedClickFun}
    >
      <div className={cn('wrapper-section-name', { 'is-first': isFirst })}>{name}</div>
      {showOperate && (
        <ShotCut
          className={cn('wrapper-section-operate', { active })}
          hashId={props.hashId}
          onSectionMoveUp={onSectionMoveUp}
          onSectionMoveDown={onSectionMoveDown}
          onSectionDelete={onSectionDelete}
          onSectionCopy={onSectionCopy}
          moveConfig={moveConfig}
        />
      )}
      <div className='click-wrapper'>
        <SectionEle theme={theme} {...otherProps} />
      </div>
    </Style>
  );
  return Wrappered;
};
