import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from './load-third-javascript';
import { GOOGLE_TAG_MANAGER } from './const';

export default class GoogleAnalytics {
  constructor(googleAnalyticsId, googleTagManagerSwitch, gtmId) {
    this.googleAnalyticsId = googleAnalyticsId;
    this.googleTagManagerSwitch = googleTagManagerSwitch;
    this.gtmId = gtmId;
    //  window.addEventListener('load', this.getGoogleAnalyticsConfig.bind(this));
    this.getGoogleAnalyticsConfig();
  }

  // eslint-disable-next-line class-methods-use-this
  getGoogleAnalyticsConfig() {
    if (!this.googleAnalyticsId) {
      return;
    }
    loadThirdJavascript(
      `${THIRD_JAVASCRIPT_CONFIG.GOOGLE_ANALYTICS.name}_new`,
      `${THIRD_JAVASCRIPT_CONFIG.GOOGLE_ANALYTICS.url}?id=${this.googleAnalyticsId}`,
      true,
    ).then(() => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', this.googleAnalyticsId);
    });
  }
}
