import styled from 'styled-components';

export const Style = styled.div`
  padding: 24px;
  min-height: 80vh;
  .policy-title {
    color: #262626;
    font-size: 40px;
    text-align: center;
  }
`;
