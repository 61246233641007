import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { OPEN_MODE, MessageType } from 'const';
import { enterpage } from '../../../util/log-buried-point/ambush-log';
import { sendTimlingLog } from '../../../util/log-buried-point/ambush-log/performance-timing';
import ShopApi from '../../../api/shop';
import { Message } from '../../../util/postmessage';
import { SectionsMap, globalConfigOfSectionMap } from '../config/sections_map';
import { initGlobalStyle } from '../../../util/set-style';
import Style from './style';
import { getQueryString } from '../../../util';
import { triggerFbMessage } from '../../../util/sdk/facebook-message';
import { ContextManager } from '../../context/context-manager';
import { LayoutWrapper } from '../../components/layout-wrapper';
import { memoedWrapedSectionsMap } from '../../components/decoration-wrapper';
import useGlobalAnimation from '../../../hook/use-global-animation';
import { useDecorationActiveMap } from '../../../hook/decoration-active-map';
import cn from 'classnames';
import { useThemeContext } from 'hook/global-theme';
import lodashMerge from 'lodash/merge';
import { blockJump } from 'helper/theme';

function getBoundingClientRect(element) {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
  };
}

const Layout = (props) => {
  const { mode, tid } = getQueryString(props.location.search);
  const {
    initialData = {},
    history,
    children,
    pageType,
    canNotEditLayout,
    name,
    checkoutHeader,
    isGreyBody,
    showFbMsg,
  } = props;
  let { hideHeader, hideFooter } = props;
  const { kdtId } = initialData;
  const domain = get(initialData, 'shopInfo.primaryDomain', '');
  const [theme, setThemeData] = useState(initialData.themeData || {});
  const [current, setCurrent] = useState('');
  const isEdit = Number(mode) === OPEN_MODE.EDIT_NORMAL;

  // console.log('isEdit mode layout', isEdit, mode);

  // 自定义页header 和footer 根据装修数据  hide_header 和  hide_footer定义
  const { themeCustom } = initialData;
  if (themeCustom?.current?.hide_header) hideHeader = true;
  if (themeCustom?.current?.hide_footer) hideFooter = true;

  const [sectionOrderInfo, setSectionOrderInfo] = useState(null);
  const getTemplateInitData = async () => {
    const getPreviewTemplate = (templateId) => {
      return ShopApi.getSystemTemplateById({
        kdtId,
        templateId,
      });
    };

    const template = tid && (await getPreviewTemplate(tid));

    return {
      template,
    };
  };

  const handleNewMessage = (data, type) => {
    window.isEdit = true;
    if (!data && MessageType.CURRENT_SECTION !== type) return;
    // console.log('decoration data', data, type);
    switch (type) {
      case MessageType.CURRENT_SECTION:
        setCurrent(data);
        break;

      case 'StorefrontMessage::SetSectionOrder':
        setThemeData(data.themeData);
        setSectionOrderInfo(data.payload);

        break;
      case MessageType.EDIT_INFO:
        // console.log('data.payload,EDIT_INFO', data.payload);
        window.EDIT_INFO = JSON.parse(data);
        break;
      case 'decoration_b_lang':
        break;
      case MessageType.SITE_SWITCH: {
        if (theme?.current?.sections?.['site-select']) {
          delete theme.current.sections['site-select'];
        }
        const newThemeData = lodashMerge({}, theme, data);
        setThemeData(newThemeData);
        setTimeout(() => {
          blockJump();
        }, 0);
        break;
      }
      case MessageType.ADMIN_CHANGE:
      default:
        initGlobalStyle(data);
        setThemeData(data);
        break;
    }
  };

  useEffect(() => {
    if (sectionOrderInfo) {
      // 这里存在数据滞后性问题 暂时采用这样的hack解决
      setTimeout(() => {
        const target = document.getElementsByClassName(`section-${sectionOrderInfo.selectedSectionGid}`)[0];
        if (target) {
          target.scrollIntoView({ block: 'start' });
          window.scrollBy(0, -280);
          // 这里需要延时不然获取的位置不准确 需要等滚动完毕 不然getBoundingClientRect获取的数据不准确
          setTimeout(() => {
            window.parent.postMessage(
              {
                type: 'StorefrontMessage::SetSectionOrder',
                payload: getBoundingClientRect(target),
              },
              '*',
            );
          }, 200);
        }
      }, 0);
    }
  }, [sectionOrderInfo]);

  const scrollSectionInView = () => {
    const sectionEl = document.querySelector(`.section-${current}`);

    if (sectionEl) {
      sectionEl.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (window.self !== window.parent) {
      const message = new Message(props, handleNewMessage);

      // /** 主动取数据 */
      return () => {
        message.removeListenMessage();
      };
    } else {
      getTemplateInitData().then(({}) => {
        // setThemeData(theme);
        initGlobalStyle(theme);
      });
    }
  }, [initialData.themeData]);

  // 埋点
  useEffect(() => {
    enterpage({
      params: {
        path: window.location.pathname,
        pageType,
      },
      initialData,
    });
    window.onload = function () {
      sendTimlingLog({ initialData });
    };
  }, [pageType]);

  useEffect(() => {
    initGlobalStyle(theme);

    // console.log('c hash Change', props.location.pathname);
    // 这里为什么需要把这个事件发送回去 因为从不可装修页面到首页需要更新需要更新装修左侧的数据
    window.parent.postMessage(
      {
        type: MessageType.HASH_CHANGE,
        content: {
          pathname: props.location.pathname,
        },
      },
      '*',
    );
    triggerFbMessage(showFbMsg);
  }, []);

  const { animationSelectorClass } = useGlobalAnimation(theme);

  const TopList = ['site-select', 'announcement-bar', 'header'];
  const sections = get(theme, 'current.sections', {});
  let { getValueAfterFormate } = globalConfigOfSectionMap.footer || {};
  const socialLinkArrFooter = isFunction(getValueAfterFormate) ? getValueAfterFormate(theme) : [];

  let routerRenderEle = null;
  if (canNotEditLayout) {
    routerRenderEle = children;
  } else if (name === 'shoppingCart') {
    routerRenderEle = (
      <div style={{ backgroundColor: 'var(--color_body_bg, #f7f7f7)' }}>
        <LayoutWrapper containerWidth={theme?.current?.container_width}>{children}</LayoutWrapper>
      </div>
    );
  } else if (name === 'product-template') {
    routerRenderEle = <div className='goods-detail-page'>{children}</div>;
  } else {
    routerRenderEle = <LayoutWrapper containerWidth={theme?.current?.container_width}>{children}</LayoutWrapper>;
  }

  const { decorationActiveMap, changeDecorationActiveFun } = useDecorationActiveMap([
    ...TopList,
    'footer',
    'product-template',
    'collection-template',
    'collection-cover-pic', // 解决商品分组页面的封面图组件无法选中的问题
  ]);

  useEffect(() => {
    if (typeof current !== 'undefined') {
      scrollSectionInView();
      changeDecorationActiveFun(current, false);
    }
  }, [current]);

  // 商品分组封面图需要支持高亮 所以移到这里而不是在组件进行渲染
  const isCollectionTemplatePage = name === 'GoodsList';
  const CollectionTemplatePageList = isCollectionTemplatePage ? ['collection-cover-pic'] : [];
  // 商品详细页组件
  const ProductTemplatePageList = name === 'product-template' ? ['product-recommendation'] : [];

  const generateEle = (hashId, index) => {
    const item = sections[hashId];
    if (!item) return null; // 解决恼人的报错
    const Elem = isEdit ? memoedWrapedSectionsMap[item?.type] : SectionsMap[item?.type]?.component;
    const ElemWithProps = isEdit ? (
      <Elem
        theme={theme}
        key={hashId}
        {...item}
        history={history}
        elemClass={`section-${hashId} ${animationSelectorClass}`}
        initialData={initialData}
        socialLinkArrFooter={socialLinkArrFooter}
        domain={domain}
        hashId={hashId}
        isFirst={index === 0}
        isEdit={isEdit}
        decorationActive={decorationActiveMap[hashId]}
        changeDecorationActiveFun={changeDecorationActiveFun}
        checkoutHeader={checkoutHeader}
      />
    ) : (
      <Elem
        theme={theme}
        key={hashId}
        {...item}
        history={history}
        elemClass={`section-${hashId} ${animationSelectorClass}`}
        initialData={initialData}
        socialLinkArrFooter={socialLinkArrFooter}
        domain={domain}
        checkoutHeader={checkoutHeader}
      />
    );
    return !item?.disabled && Elem ? ElemWithProps : null;
  };
  // 商品详细页如果配置的样式二，并且是左右布局时，要加上动效样式,这个动效是页面级的，所以需要放这里特殊处理
  const productTemplateConfig = theme?.current?.sections['product-template'];
  const productTemplateBaseConfig =
    productTemplateConfig?.block_order && productTemplateConfig?.block_order[0]
      ? productTemplateConfig?.blocks[productTemplateConfig?.block_order[0]]
      : {};
  const { isMobile } = useThemeContext();
  const isScroll =
    !isMobile &&
    (name === 'product-template' || name === 'group-buying-product') &&
    (productTemplateBaseConfig?.settings?.style === 'style3' ||
      productTemplateBaseConfig?.settings?.style === 'style2');
  return (
    <Style>
      <div
        className={cn('h5-preview-container', {
          'is-scroll-animation': isScroll,
        })}
      >
        <div className={cn('h5-preview-content', { 'is-checkout-body': checkoutHeader || isGreyBody })}>
          <>
            {!hideHeader &&
              TopList.map((hashId, index) => {
                return generateEle(hashId, index);
              })}
            {!!CollectionTemplatePageList.length &&
              CollectionTemplatePageList.map((hashId, index) => {
                return generateEle(hashId, index);
              })}

            <ContextManager.Provider
              value={{
                kdtId,
                theme,
                changeDecorationActiveFun,
                decorationActive: decorationActiveMap[name === 'GoodsList' ? 'collection-template' : name],
                isEdit,
                animationSelectorClass,
                elemClass: `section-${name === 'GoodsList' ? 'collection-template' : name}`,
              }}
            >
              {routerRenderEle}
            </ContextManager.Provider>
            {!!ProductTemplatePageList.length &&
              ProductTemplatePageList.map((hashId, index) => {
                return generateEle(hashId, index);
              })}

            {!hideFooter &&
              ['footer'].map((hashId) => {
                const item = sections[hashId];
                getValueAfterFormate = (globalConfigOfSectionMap[item?.type] || {}).getValueAfterFormate;
                const socialLinkArr = isFunction(getValueAfterFormate) ? getValueAfterFormate(theme) : [];
                const Elem = isEdit
                  ? memoedWrapedSectionsMap[item?.type]?.component
                  : SectionsMap[item?.type]?.component;
                const ElemWithProps = isEdit ? (
                  <Elem
                    key={hashId}
                    {...item}
                    history={history}
                    elemClass={`section-${hashId} ${animationSelectorClass}`}
                    initialData={initialData}
                    domain={domain}
                    socialLinkArr={socialLinkArr}
                    hashId={hashId}
                    decorationActive={decorationActiveMap[hashId]}
                    changeDecorationActiveFun={changeDecorationActiveFun}
                  />
                ) : (
                  <Elem
                    key={hashId}
                    {...item}
                    history={history}
                    elemClass={`section-${hashId} ${animationSelectorClass}`}
                    initialData={initialData}
                    domain={domain}
                    socialLinkArr={socialLinkArr}
                  />
                );
                return !item?.disabled && Elem ? ElemWithProps : null;
              })}
          </>
        </div>
      </div>
    </Style>
  );
};

export default Layout;
