import styled from 'styled-components';
import { getPercentWidth } from '../../../../style/fn';

export const Style = styled.div`
  .feature-collection-grid-item {
    overflow: hidden;
    position: relative;
    &.is-pc:hover {
      .feature-content {
        top: 98px;
      }
      .feature-content--bottom {
        margin-top: -8px;
      }
      /* .feature-collection-cover {
                transition: transform 0.25s ease-in-out;
                transform: translateY(-10px);
            } */
      /* .feature-collection-cover {
                transition: transform 0.25s ease-in-out;
                transform: translateY(-10px);
            }
            .feature-content--bottom {
                max-height: 999px;
            }
            .feature-content {
                height: calc(100% - 24px);
                overflow-y: scroll;
                background: rgba(var(--color_image_overlay_rgb), 1);
            } */
    }
  }
  .feature-content.is-pc {
    top: 100%;
    transform: translateY(-74px);
    height: calc(100% - 24px);
  }
  .feature-content.is-mobile {
    bottom: 0;
    max-height: calc(100% - 24px);
  }
  .feature-content {
    width: 100%;
    position: absolute;
    z-index: 9;
    background: ${(props) => props.overlayBgColor || 'rgba(var(--color_image_overlay_rgb), 0.9)'};
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;

    .title {
      padding: ${(props) => props.theme.getBaseFontSize([16, 24])};
      ${(props) => props.theme.getBaseSize([16, 20])}
      color:  ${(props) => props.textColor || '#fff'};
      display: inline-block;
      word-break: break-word;
    }
    .feature-content--bottom {
      box-sizing: border-box;
      padding: 0 ${(props) => props.theme.getSpace([16, 24])} ${(props) => props.theme.getSpace([16, 24])};
      height: calc(100% - 74px);
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .sub-title {
      ${(props) => props.theme.getBaseSize([14, 16])}
      color: ${(props) => props.descTextColor};
      word-break: break-word;
    }
    .link-button {
      margin-top: 16px;
    }
  }
  .click-able {
    cursor: pointer;
  }

  .feature-collection-title {
    font-size: var(--base-header-font-24-18);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    color: var(--color_text);
    line-height: 32px;
    margin-bottom: var(--pm-40-20);
  }

  .feature-collection-grid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .feature-collection-grid-item {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: ${(props) => props.theme.getSpace([20, 40])};
    position: relative;
    box-sizing: border-box;
    &.no-margin-top {
      margin-top: 0;
    }
  }

  .feature-collection-cover {
    width: 100%;
    max-height: 100%;
    display: block;
    min-height: ${(props) => (props.theme.isMobile ? '240px' : null)};
    object-fit: cover;
  }

  .collection-grid-placeholder {
    height: 0;
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  .feature-collection-box {
    /* width: ${getPercentWidth(80)};
        margin: auto; */
  }

  @media screen and (min-width: 750px) {
    .feature-collection-grid.pc {
      display: flex;
    }
    .mobile-style {
      display: none;
    }
    .collection-grid-2 {
      .feature-collection-grid-item {
        width: calc(((100% - 24px) / 2));
        :nth-of-type(2n) {
          margin-right: 0px;
        }
      }
    }

    .collection-grid-3 {
      .feature-collection-grid-item {
        width: calc(((100% - 48px) / 3));
        :nth-of-type(3n) {
          margin-right: 0px;
        }
      }
    }

    .collection-grid-4 {
      .feature-collection-grid-item {
        width: calc(((100% - 72px) / 4));
        :nth-of-type(4n) {
          margin-right: 0px;
        }
      }
    }

    .collection-grid-5 {
      .feature-collection-grid-item {
        width: calc(((100% - 96px) / 5));
        :nth-of-type(5n) {
          margin-right: 0px;
        }
      }
    }

    .align-right {
      flex-direction: row-reverse;
    }

    .feature-collection-grid-item {
      margin-right: 24px;
    }

    .feature-collection-grid {
      /* margin-left: -40px; */
    }
  }

  .grid-info-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  @media screen and (max-width: 749px) {
    .swiper-container {
      padding-bottom: 10px;
    }
    .swiper-pagination-bullets {
      bottom: 0 !important;
    }
    .swiper-pagination-bullet-active {
      background: var(--color_button) !important;
    }
    .feature-collection-grid-item {
      margin-bottom: 16px;
    }
    .feature-collection-grid.pc {
      display: none;
    }
    .mobile-style {
      display: block;
    }

    .feature-collection-grid {
      /* margin-left: -15px; */
    }
  }
  .link-button {
    .zent-btn {
      color: ${(props) => props.btnTextColor} !important;
      background: ${(props) => props.btnBgColor} !important;
    }
  }
`;
