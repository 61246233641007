/**
 * 前端性能指标埋点
 */

import get from 'lodash/get';
import { log } from './index';

/**
 * 获取性能分析时间差
 */
const getTimlingDisTime = (startKey, endKey, id) => {
  const startValue = get(window.performance, `timing[${startKey}]`);
  const endValue = get(window.performance, `timing[${endKey}]`);
  let disTime = endValue - startValue;
  // 为了保证神策那边数据区间跨度不要很大
  if (disTime > 20000) {
    disTime = 20000;
  } else if (disTime < 0) {
    disTime = 0;
  }
  return disTime;
};

const TIMMING_LOG_LIST = [
  // dns查询耗时
  { id: 'dns', startKey: 'domainLookupStart', endKey: 'domainLookupEnd' },
  // tcp 耗时
  { id: 'tcp', startKey: 'connectStart', endKey: 'connectEnd' },
  // 响应时间
  { id: 'request', startKey: 'responseStart', endKey: 'responseEnd' },
  // 白屏时间
  { id: 'blank', startKey: 'fetchStart', endKey: 'domLoading' },
  // 解析dom耗时
  { id: 'dom', startKey: 'domInteractive', endKey: 'domComplete' },
  // html请求耗时
  { id: 'html', startKey: 'fetchStart', endKey: 'responseEnd' },
  // DOM解析时间
  { id: 'domReady', startKey: 'fetchStart', endKey: 'domContentLoadedEventEnd' },
  // onLoad 总时间
  { id: 'loadEnd', startKey: 'fetchStart', endKey: 'loadEventEnd' },
];

/**
 * 发送性能分析埋点日志
 */
export const sendTimlingLog = ({ initialData }) => {
  // window.onload之后再延迟20ms，不然有点时间可能获取不到
  setTimeout(() => {
    const params = {
      path: decodeURIComponent(window.location.pathname),
    };

    TIMMING_LOG_LIST.forEach((item) => {
      const disTime = getTimlingDisTime(item.startKey, item.endKey, item.id);
      params[item.id] = disTime;
    });
    log({
      event: {
        et: 'custom',
        ei: 'timling',
        en: '前端性能指标分析',
        si: initialData?.kdtId,
        params,
      },
    });
  }, [20]);
};
