import React from 'react';
import { useIntl } from 'react-intl';
import NewLink from 'cpn/new-link';
import { HtPrice } from 'cpn/goods-price';
import { Icon } from 'components/base';
import { CountDown } from 'components/page/group-buying/count-down';
import { useGroupInfo } from './hook';
import { GroupBuyingEntryStyle, GroupBuyingItemStyle } from './style';
import { useRequireLogin } from 'cpn/require-login-wrapper';

export const GroupBuyingEntry = (props) => {
  const { onTimeDone, goodsId, customerId, currency } = props;
  const { activityEndAt, minGroupPrice, participateNum, activityId } = useGroupInfo({ goodsId, customerId });
  const link = `/group-buying/product?goodsId=${goodsId}&activityId=${activityId}`;
  const intl = useIntl();
  const { checkedRequireLogin } = useRequireLogin();
  if (!activityId) return null;

  return (
    <GroupBuyingEntryStyle>
      <div className='group-buy-title'>
        {intl.formatMessage({
          id: '3e577de5cad849f3b51361fe4ae1cbaa',
          defaultMessage: 'Item Supports Group Buy',
        })}
      </div>
      <NewLink onClick={checkedRequireLogin} href={link}>
        <GroupBuyingItemStyle>
          <div className='left'>
            <div className='profile-box-wrapper'>
              <div className='default-profile'>
                <Icon name='iconpintuantouxiang' size='xxSmall' fill='#fff' />
              </div>
            </div>
            <div>
              <div className='group-count'>
                {intl.formatMessage(
                  {
                    id: '72399f5ce02d4dac8d02942f846fc2d3',
                    defaultMessage: '{num}人拼团',
                  },
                  {
                    num: participateNum,
                  },
                )}
              </div>
              {!!activityEndAt && (
                <div className='group-time'>
                  <div className='end-in-text'>
                    {intl.formatMessage({
                      id: '695af2a94b5e4d06bf22bcb0f6badbc2',
                      defaultMessage: 'End in',
                    })}
                  </div>
                  <CountDown timeEndAt={activityEndAt} styleType='normal' onTimeDone={onTimeDone} />
                </div>
              )}
            </div>
          </div>
          <div className='right'>
            <div className='group-desc'>
              <HtPrice price={minGroupPrice} currency={currency} />
              <span className='group-buying-text'>
                {intl.formatMessage({
                  id: '96a88360cb79486cb9ebed4bf2b801c2',
                  defaultMessage: 'Group Buy',
                })}
              </span>
            </div>
            <div>
              <Icon name='iconic-xiala' size='small' fill='#1A1A1B' />
            </div>
          </div>
        </GroupBuyingItemStyle>
      </NewLink>
    </GroupBuyingEntryStyle>
  );
};
