export const blockJump = (cache = false) => {
  // 不缓存，是重复执行，去掉新增a标签的跳转
  if (!window.blockJumpStatus || !cache) {
    document.querySelectorAll('a').forEach((a) => {
      a.onclick = (e) => {
        e.preventDefault();
      };
    });
    if (cache) {
      window.blockJumpStatus = true;
    }
  }
};
