export const DEFAULT_COLLECTION_COVER_PIC = {
  sectionId: 'collection-cover-pic',
  type: 'collection-cover-pic',
  settings: {
    style: 'style1',
    settings: {
      style1: {
        container_width: 'full_screen',
        cover_pic_ratio: 'crop', // 裁剪 crop 原比例 adaptive
        text_alignment: 'left center',
        title_size: 3,
        desc_size: 9,
        title_color: 'rgba(255,255,255,1)',
        desc_color: 'rgba(255,255,255,1)',
        show_cover_image: true,
        show_mask: true,
        show_text_card: false,
        mask_color: 'rgba(255,255,255,0.3)',
        text_card_color: 'rgba(255,255,255,0.3)',
      },
      style2: {
        container_width: 'full_screen',
        cover_pic_ratio: 'crop', // 裁剪 crop 原比例 adaptive
        text_alignment: 'left center',
        title_size: 3,
        desc_size: 9,
        title_color: '#1a1a1b',
        desc_color: '#1a1a1b',
        show_cover_image: true,
        show_mask: false,
        mask_color: 'rgba(0,0,0,0.3)',
      },
    },
  },
};

export const DEFAULT_COLLECTION_TEMPLATE = {
  sectionId: 'collection-template',
  type: 'collection-template',
  settings: {
    layout: 'horizontal',
    settings: {
      horizontal: {
        container_width: '1280',
        image_ratio: '1-1',
        grid: 4,
        pic_padding: 8,
        text_display: 'under_pic',
        product_name_size: 7,
        product_price_size: 7,
        product_name_color: '#1a1a1b',
        product_price_color: '#323233',
        align_text: 'left',
        text_position: 'left top',
        show_product_name: true,
        show_product_price: true,
        show_product_buy_btn: true,
        show_product_cart_sku: true,
        hover_show_second_pic: true,
        style: 'style1',
        align: 'left',
      },
      vertical: {
        container_width: '1440',
        image_ratio: '1-1',
        grid: 4,
        pic_padding: 8,
        show_product_name: true,
        show_product_price: true,
        show_product_buy_btn: true,
        show_product_cart_sku: true,
        hover_show_second_pic: true,
        product_name_size: 7,
        product_price_size: 7,
        product_name_color: 'rgba(0,0,0,0.2)',
        product_price_color: 'rgba(0,0,0,0.2)',
        text_position: 'center center',
        align_text: 'left',
        style: 'style1',
        align: 'left',
      },
    },
  },
};
