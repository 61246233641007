import styled, { css } from 'styled-components';

/**
 * 宽度
 * 每行数量
 * 图片之间的间距
 */
const getGridCSS = (props) => {
  const { grid = 4, picPadding = 10 } = props;
  const gridTemplateColumns = `repeat(${grid}, 1fr)`;
  // 图片之间的间距 指左右的间距 上下固定40px
  const gridGap = `40px ${picPadding}px`;
  return css`
    grid-template-columns: ${gridTemplateColumns};
    grid-gap: ${gridGap};
  `;
};

/**
 * 获取宽度
 *
 * */
const getContainerWidthCSS = (props) => {
  const { containerWidth } = props;
  if (containerWidth === 'margin_80') {
    return css`
      width: 100%;
      padding: 0 ${(props) => props.theme.getSpace(16, 80)};
    `;
  }
  return css`
    width: ${containerWidth === 'full_screen' ? '100%' : `${containerWidth}px`};
    max-width: 100%;
  `;
};

export const Style = styled.div`
  /* display: flex;
    justify-content: center;
    margin-bottom: 50px;
    .simple-goods-list {
        获取宽度 每行数量 图片之间的间距 样式
        ${(props) => getContainerWidthCSS(props)};
        margin-left: auto;
        margin-right: auto;
        display: grid;
        ${(props) => getGridCSS(props)};
    }
    @media screen and (max-width: 750px) {
        .simple-goods-list {
            grid-template-columns: repeat(2, 2fr);
        }
    } */
  .simple-product-box {
    width: 100%;
  }
`;
