import shopApi from 'api/shop';

export const goToAuthPage = (appId, referer) => {
  shopApi
    .getConnectUrl({
      appId,
      referer,
    })
    .then((res) => {
      const { connectUrl } = res;
      window.location.href = connectUrl;
    });
};
