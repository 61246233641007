import styled from 'styled-components';

// link类型这个颜色是主按钮背景颜色，btn类型这个样式会被主按钮文本颜色覆盖
export const Style = styled.div`
  /* .shop-button {
        color: #fff;
    } */
  .shop-link {
    ${(props) => props.theme.getHeaderSize([14, 14])};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: ${(props) => props.theme.getSpace([32, 40])} !important;
    color: var(--color_button) !important;
    text-decoration: underline;
    cursor: pointer;
    padding: 0 ${(props) => props.theme.getSpace([16, 32])};
    :hover {
      opacity: 0.5;
    }
    width: fit-content;
    font-family: var(--font-stack-body);
    font-style: var(--font-style-body);
    /* font-weight: var(--font-weight-body); */
    font-weight: 500;
    &.zent-btn {
      border-radius: var(--border_radius_btn);
      cursor: pointer;
      text-decoration: none;
    }
  }
`;
