import App from './app';
import React from 'react';
import 'lazysizes'; // 懒加载库
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { initSentry } from './sentry';
import { addFavicon } from './util/set-style';
import { storeStatusRedirect } from './util/global/store-status-redirect';
import { getCurrentStoreRoute } from '../helper/route-client';
import { getClientLanguage } from '../helper/multi-language';

const initialData = window?.global_data ?? {};
const i18nLang = window?.i18nLang ?? {};

/**
 * 把此处的逻辑抽取函数出去@包卫  业务逻辑最好不要混杂在这里
 * 已经抽离为一个工具函数
 */
const redirectState = storeStatusRedirect();
/**
 * 客户端渲染
 */

loadableReady(() => {
  if (redirectState) {
    return; // 重定向的时候，还是会走后面页面的逻辑，直接return掉
  }
  const routeList = getCurrentStoreRoute(window?.global_data);
  const { pathname } = document.location;
  const root = document.getElementById('root');
  const language = getClientLanguage(initialData);
  // 这里有时候后在控制台报错 Did not expect server HTML to contain a <div> in <div>
  // settimeout 下面函数可解决但是会产生新问题而且感觉性能不太好 先标记一下
  hydrate(
    <BrowserRouter>
      <App
        pathname={pathname}
        i18nLang={i18nLang}
        initialData={initialData}
        routeList={routeList}
        language={language}
      />
    </BrowserRouter>,
    root,
  );
});

initSentry();
addFavicon();
if (module.hot) {
  module.hot.accept();
}
