import { Style } from './style';
import HeaderLogo from '../../design/sections/header/components/header-logo';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SHOP_LOGO_URL } from '../../../const';
import shopAPi from '../../../api/shop';

const OrderHeader = (props) => {
  const { settings, jumpToOtherPage, style } = props;
  const { logo } = settings;
  const { color } = style || {};
  const shopName = window?.global_data?.shopSetting?.name;

  const intl = useIntl();
  const [logoImg, setLogoImg] = useState(sessionStorage.getItem(SHOP_LOGO_URL));

  useEffect(() => {
    shopAPi.getShopLogo({ kdtId: window?.global_data?.kdtId }).then((data) => {
      setLogoImg(data?.shopLogoUrl);
    });
  }, []);

  return (
    <Style>
      <div className='header-wrapper' style={style}>
        <div className='logo-wrapper'>
          <HeaderLogo jumpToOtherPage={jumpToOtherPage} logo={logo || logoImg} isOrderHeader shopName={shopName} />
        </div>
        <div className='checkout-wrapper'>
          <svg width={20} height={20} fill={color}>
            <use xlinkHref='#iconanquanzhifu' />
          </svg>
          <span className='text'>
            {intl.formatMessage({ defaultMessage: '安全支付', id: '82255fdf2a344a43b51150dff6b6593a' })}
          </span>
        </div>
      </div>
    </Style>
  );
};

export default OrderHeader;
