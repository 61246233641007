import get from 'lodash/get';
import { getBrowserId } from '../util';
import reportApi from 'api/report';

const eventMap = new Map();
eventMap.set('add_cart', 'AddToCart');
eventMap.set('search', 'search');
eventMap.set('Cartview_gocheckout', 'InitiateCheckout');
eventMap.set('buy_now', 'InitiateCheckout');
eventMap.set('pay_success', 'CompletePayment');
eventMap.set('product_add_to_wishlist', 'AddToWishlist');
eventMap.set('add_payment_info', 'AddPaymentInfo');
eventMap.set('sign_up', 'CompleteRegistration');
eventMap.set('view_content', 'ViewContent');
eventMap.set('Subscribe', 'Subscribe');

const REPORT_EVENT_NAME_MAP = {
  ViewContent: 'view_content',
  AddToWishlist: 'add_to_wishlist',
  AddToCart: 'add_to_cart',
  InitiateCheckout: 'initiate_checkout',
  AddPaymentInfo: 'add_payment_info',
  CompletePayment: 'complete_payment',
  CompleteRegistration: 'complete_registration',
  Subscribe: 'subscribe',
};

const formateNameAndType = (goodsList) => {
  const title = get(goodsList, '[0].title', '');
  if (goodsList?.length > 1) {
    return {
      content_name: title,
      content_type: 'product_group',
    };
  }
  return {
    content_name: title,
    content_type: 'product',
  };
};

class TiktokPixelLog {
  constructor(eventName, data) {
    const { currency, kdtId } = window?.global_data.shopSetting;
    const userInfo = window?.global_data.userInfo;
    const customerId = userInfo?.customerId;
    const ttclid = new URLSearchParams(window.location.search).get('ttclid') || '';
    const cacheTTAdInfo = window.sessionStorage.getItem('tt_adInfo') || '{}';
    let callback = '';
    try {
      callback = JSON.parse(cacheTTAdInfo).callback;
    } catch (err) {
      console.warn(err);
    }
    this.eventName = eventMap.get(eventName);
    this.data = { content_name: document.title, ...data };
    this.currency = currency;
    this.dataMatch = { currency };
    const timestamp = String(Date.now());
    const tiktokCallback = ttclid || callback;
    this.reportEventParams = {
      customMap: {
        tiktok_callback: tiktokCallback,
      },
      eventName: REPORT_EVENT_NAME_MAP[this.eventName],
      eventId: `${kdtId}-${REPORT_EVENT_NAME_MAP[this.eventName]}-${timestamp}`,
      eventContext: {
        eventPage: {
          referrer: document.referrer,
          url: window.location.href,
        },
        eventUser: {
          customerId: customerId ? String(customerId) : '',
        },
      },
      eventProperties: {},
      thirdPlatformList: ['tiktok'],
      timestamp,
    };
    this.kdtId = kdtId;
  }
  // 根据事件不同 数据格式化
  formatData(eventName) {
    const eventDataFormat = new Map();
    // 事件：添加到购物车
    eventDataFormat.set('AddToCart', () => {
      const { goodsId, title: description, price, quantity } = this.data;

      this.dataMatch = {
        ...this.dataMatch,
        description,
        content_id: goodsId,
        quantity,
        content_type: 'product',
        price: price / 1000,
        value: (price * quantity) / 1000,
        currency: this.currency,
      };
      const eventProperties = {
        currency: this.currency,
        description,
        eventPropertiesContents: [
          {
            contentId: goodsId,
            contentType: 'product',
            price,
            quantity,
          },
        ],
        value: price * quantity,
      };
      Object.assign(this.reportEventParams, { eventProperties });
    });

    // 事件：浏览详情页面
    eventDataFormat.set('ViewContent', () => {
      const { goodsId: content_id, title: description, minPrice } = this.data;
      this.dataMatch = {
        ...this.dataMatch,
        description,
        content_id,
        quantity: 1,
        content_type: 'product',
        price: minPrice / 1000,
        value: minPrice / 1000,
        currency: this.currency,
      };
      const eventProperties = {
        currency: this.currency,
        description,
        eventPropertiesContents: [
          {
            contentId: content_id,
            contentType: 'product',
            price: minPrice,
            quantity: 1,
          },
        ],
        value: minPrice,
      };
      Object.assign(this.reportEventParams, { eventProperties });
    });

    // 事件：注册事件
    eventDataFormat.set('CompleteRegistration', () => {
      this.dataMatch = {};
      this.reportEventParams = null;
    });

    // 事件：添加到心愿单
    eventDataFormat.set('AddToWishlist', () => {
      const { goodsId, minPrice, title } = this.data;
      this.dataMatch = {
        ...this.dataMatch,
        description: title,
        content_id: goodsId,
        quantity: 1,
        content_type: 'product',
        price: minPrice / 1000,
        value: minPrice / 1000,
      };
      this.reportEventParams = null;
    });

    // 事件：商品搜索
    eventDataFormat.set('search', () => {
      const { search_term: search_string } = this.data;
      this.dataMatch = { search_string, currency: this.currency };
    });

    // 事件: 开始结账
    eventDataFormat.set('InitiateCheckout', () => {
      let value = 0;
      console.log('InitiateCheckout', this.data);
      const { cartList, title } = this.data.source;
      cartList.forEach((item) => {
        value += (item.price * item.quantity) / 1000;
      });
      this.dataMatch = {
        ...this.dataMatch,
        description: title,
        ...formateNameAndType(cartList),
        currency: this.currency,
        contents: cartList.map((item) => {
          return {
            content_id: item.variantId,
            content_type: 'product',
            quantity: item.quantity,
            price: item.price / 1000,
          };
        }),
        value,
      };
      const eventProperties = {
        currency: this.currency,
        description: title,
        eventPropertiesContents: cartList.map((item) => {
          return {
            contentId: item.variantId,
            contentType: 'product',
            price: item.price,
            quantity: item.quantity,
          };
        }),
        value: 1000 * value,
      };
      Object.assign(this.reportEventParams, { eventProperties });
    });

    // 事件成功购买 biz_id: orderId
    eventDataFormat.set('CompletePayment', () => {
      const { goodsList, content_name: title, orderId, totalPrice } = this.data || {};
      console.log('CompletePayment', this.data);

      this.dataMatch = {
        ...this.dataMatch,
        value: totalPrice / 1000,
        currency: this.currency,
        contents: goodsList.map((item) => {
          return {
            content_id: item.variantId,
            content_type: 'product',
            quantity: item.quantity,
            price: item.unitPrice / 1000,
          };
        }),
        ...formateNameAndType(goodsList),
      };
      const eventProperties = {
        currency: this.currency,
        description: title,
        eventPropertiesContents: goodsList.map((item) => {
          return {
            contentId: item.variantId,
            contentType: 'product',
            price: item.unitPrice,
            quantity: item.quantity,
          };
        }),
        value: totalPrice,
      };
      const eventId = `${this.kdtId}-${REPORT_EVENT_NAME_MAP[this.eventName]}-${orderId}`;
      Object.assign(this.reportEventParams, { eventProperties, eventId });
    });

    // 事件：添加付款信息
    eventDataFormat.set('AddPaymentInfo', () => {
      console.log('AddPaymentInfo', this.data);
      const { source = {} } = this.data;
      const { lineItems = {}, title, totalPrice } = source?.formData || [];

      this.dataMatch = {
        ...this.dataMatch,
        contents: lineItems.map((item) => {
          return {
            content_id: item.variantId,
            content_type: 'product',
            quantity: item.quantity,
            price: item.unitPrice / 1000,
          };
        }),
        ...formateNameAndType(lineItems),
        value: totalPrice / 1000,
      };
      const eventProperties = {
        currency: this.currency,
        description: title,
        eventPropertiesContents: lineItems.map((item) => {
          return {
            contentId: item.variantId,
            contentType: 'product',
            price: item.unitPrice,
            quantity: item.quantity,
          };
        }),
        value: totalPrice,
      };
      Object.assign(this.reportEventParams, { eventProperties });
    });
    // 订阅
    eventDataFormat.set('Subscribe', () => {
      this.dataMatch = {};
      this.reportEventParams = null;
    });

    // 执行对应事件数据的格式化
    if (eventDataFormat.get(eventName)) eventDataFormat.get(eventName)();
  }
  eventLog() {
    // 没有对应事件就不处理 不上报
    if (!this.eventName) {
      return;
    }
    this.formatData(this.eventName);
    const browserId = TiktokPixelLog?.browserId;
    const timeStamp = +new Date();
    const eventId = `${browserId}-${timeStamp}`;
    const { contents = [] } = this.dataMatch || {};
    const customData = { ...this.dataMatch, contents };
    if (window.ttq) {
      const userInfo = window?.global_data?.userInfo ?? {};
      if (userInfo.customerNo || userInfo.phone) {
        window.ttq.identify({
          phone_number: userInfo.customerNo,
          email: userInfo.phone,
        });
        window.ttq.track(this.eventName, customData, {
          event_id: eventId,
          pixel_code: window?.tiktokPixelId,
        });
      } else {
        window.ttq.track(this.eventName, customData, {
          event_id: eventId,
          pixel_code: window?.tiktokPixelId,
        });
      }

      if (this.reportEventParams) {
        reportApi.reportEvent(this.reportEventParams);
      }
    }
  }
}

getBrowserId(TiktokPixelLog);

export default TiktokPixelLog;
