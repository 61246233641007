import styled from 'styled-components';

export const Style = styled.div`
  width: ${(props) => props.width + 'px'};
  .menu-title {
    color: #000;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .menu-item {
    padding-left: 0;
    color: #979797;
    margin-bottom: 8px;
  }
`;
