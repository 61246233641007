// 对应装修的滑动商品
import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import { Wrapper } from 'components/base';
import ImageContainer from '../../components/image-container';
import { useCollection, getCollectionInitialProps } from 'hook/collection';
import { Style, PriceStyleT, GoodsPrevStyle } from './style';
import { Button } from 'zent';
import NewLink from 'cpn/new-link';
import BuyNow from '../../../components/bay-now';
import { TagList } from 'components/page/goods/tag-list';
import { HtGoodsListPrice } from 'cpn/goods-price';
import { TRACK } from 'const';
import { useThemeContext } from '../../../../hook/global-theme';
import { Background } from '../../components/background';
import GoodsCollectionWithLayout from '../../components/goods-collection-with-layout';
import GoodsCollectionWithSwiper from '../../components/goods-collection-with-swiper';
import SwiperCore, { Navigation, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Text } from '../../../../components/base';
import useFillImage from 'hook/use-fill-image';
import { buriedPoint } from 'utils/log-buried-point';

SwiperCore.use([Navigation, Scrollbar, A11y, Autoplay]);

export const GoodsPrev = (props) => {
  const {
    settings,
    media = [],
    title,
    vendor,
    minPrice,
    goodsId,
    currency,
    handle,
    quantity,
    kdtId,
    compareAtPrice,
    discountInfo = {},
    activityDescriptionInfo = {},
    language,
    bestVariant = {},
    onTriggerClick,
    inventory,
    variants = [],
  } = props;
  const intl = useIntl();
  const ele = useRef(null);
  const skuSelectRef = useRef({});
  const [isMultipleSku, setIsMultipleSku] = useState(false);
  const [skuBlockStatus, setSkuBlockStatus] = useState(false);
  // 是否直接立即购买  否则就是显示添加到购物车
  const [enablePaymentButton, setEnablePaymentButton] = useState(false);
  // sku page 组件是否需要
  const [skuPageShow, setSkuPageShow] = useState(false);
  // sku page 组件是否可用
  const [skuPageCompleteStatus, setSkuPageCompleteStatus] = useState(false);
  const { discountType, discountValue } = discountInfo;

  const { show_product_name: showProductName = true, show_product_price: showProductPrice = true } = settings;
  // 是否售罄
  const isSellout = () => {
    return Number(inventory) <= 0;
  };

  const triggerSkuBlock = () => {
    setSkuBlockStatus(false);
  };

  const showSkuOrAction = (event, buyShowStatus) => {
    if (event) event.stopPropagation();
    setEnablePaymentButton(buyShowStatus);
    setSkuPageShow(true);
    if (!skuPageCompleteStatus) return;
    if (isMultipleSku) {
      setSkuBlockStatus(true);
    }
    if (!isMultipleSku && buyShowStatus) {
      skuSelectRef.current.buyNow();
    }
    if (!isMultipleSku && !buyShowStatus) {
      skuSelectRef.current.addCar();
    }
  };

  useEffect(() => {
    if (skuPageCompleteStatus) {
      showSkuOrAction(false, enablePaymentButton);
    }
  }, [skuPageCompleteStatus]);

  const setGoodsMultipleSkuStatus = (hasSkuOptions) => {
    setIsMultipleSku(hasSkuOptions);
  };
  // 兼容视频情况，取封面图
  const { url = '', coverUrl = '', type } = media?.[0] || {};
  const { image } = useFillImage(type === 'VIDEO' ? coverUrl : url);

  const clickClass = goodsId ? 'click-able' : '';

  const SkuPage = () => {
    const isPC = window && document.body.clientWidth > 750;
    const containerDisplay = isPC ? 'block' : 'flex';
    const skuElement = (
      <Style>
        <div
          style={{
            display: skuBlockStatus ? containerDisplay : 'none',
          }}
          ref={ele}
          className='add-shopping-car-container'
          onClick={(e) => e.stopPropagation()}
        >
          <BuyNow
            skuSelectRef={skuSelectRef}
            setGoodsMultipleSkuStatus={setGoodsMultipleSkuStatus}
            goodsId={goodsId}
            kdtId={kdtId}
            triggerSkuBlock={triggerSkuBlock}
            enablePaymentButton={enablePaymentButton}
            setSkuPageCompleteStatus={setSkuPageCompleteStatus}
            currency={currency}
          />
        </div>
      </Style>
    );
    if (isPC) {
      return skuElement;
    }
    return createPortal(skuElement, document.getElementById('root'));
  };

  return (
    <GoodsPrevStyle>
      <div className='feature-collection-grid-item'>
        <NewLink
          href={`/products/${handle}`}
          onClick={() => {
            onTriggerClick && onTriggerClick();
          }}
        >
          <div className={cn('grid-info-wrap', clickClass)}>
            <div className='image-box'>
              <ImageContainer
                heightType={settings.image_ratio}
                heightRules='type3'
                fit='cover'
                position='center center'
                url={image}
                alt={title}
              />
            </div>

            {showProductName && <h3 className='goods-name'>{title || "Your product's name"}</h3>}
            {settings.show_vendor && vendor && <p className='vendors-name'>{vendor}</p>}
            {showProductPrice && (
              <PriceStyleT className='goods-price'>
                <HtGoodsListPrice bestVariant={bestVariant} variants={variants} />
              </PriceStyleT>
            )}
            <TagList
              discountInfo={discountInfo}
              groupActivityDescription={activityDescriptionInfo?.groupActivityDescription}
              language={language}
            />
            {skuPageShow && <SkuPage />}
          </div>
        </NewLink>
      </div>
      {(settings.show_shopping_car || settings.show_shopping_buy) && (
        <div
          className='action-wrapper'
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {settings.show_shopping_car && (
            <Button
              className={`btn-cart add-shopping-car-btn ${TRACK.AddCartButton}`}
              disabled={isSellout()}
              onClick={(e) => {
                e.stopPropagation();
                showSkuOrAction(e, false);
              }}
            >
              {intl.formatMessage({ id: 'goods.cart.add' })}
            </Button>
          )}
          {settings.show_shopping_buy && (
            <Button
              className={`btn-buy add-shopping-car-btn ${TRACK.BuyOnceButton}`}
              type='primary'
              disabled={isSellout()}
              onClick={(e) => {
                e.stopPropagation();
                showSkuOrAction(e, true);
              }}
            >
              {intl.formatMessage({ id: 'goods.buy_once' })}
            </Button>
          )}
        </div>
      )}
    </GoodsPrevStyle>
  );
};

const cutGoodsList = (data, quantity, showProductNum, dataType) => {
  const array = [];
  let newData = [];
  if (dataType === 'product_list') {
    newData = data;
  } else {
    newData = data.slice(0, showProductNum);
  }
  let index = 0;
  while (index < newData.length) {
    array.push(newData.slice(index, (index += quantity)));
  }
  return array;
};
// 新增一个type传值;以让该组件适用更多情况
// collection：商品分组
// recommend：推荐商品
const ProductGrid = (props) => {
  const { settings = {}, initialData, elemClass, data = [], type = 'collection' } = props;
  const { kdtId, userInfo } = initialData;
  const sliderRef = useRef(null);
  const {
    title = '',
    collection,
    box_width: boxWidth,
    grid: showGrid = 8,
    rows = 1,
    text_align: textAlign = 'left',
    title_size: titleSize = 7,
    background_style: backgroundStyle = 'null',
    background_color: backgroundColor,
    background_image: backgroundImage,
    product_list: productList = [],
    image_ratio: imageRatio = 'adapt',
    slide_style: slideStyle = 'arrow',
    show_sale: showSale,
    show_vendor: showVendor,
    open_card: openCard,
    show_shopping_buy: showShoppingBuy,
    text_line_limit: textLineLimit,
    product_name_size: productNameSize,
    show_comment: showComment = {},
    show_shopping_car: showShoppingCar = {},
    product_price_size: productPriceSize,
  } = settings;
  let dataType = settings.data_type;
  const { main_val: showCommentMainVal = false } = showComment;
  const { main_val: showShoppingCarMainVal = false } = showShoppingCar;
  const { goodsList } = useCollection({
    initData: data,
    type: dataType || type,
    kdtId,
    collection,
    grid: 4,
    rows,
    productList,
    customerId: userInfo?.customerId,
  });
  if (!dataType) dataType = 'collection';
  const goodsGroup = cutGoodsList(goodsList, 4, showGrid, dataType);
  const themeContext = useThemeContext();
  const { isMobile, clientWidth } = themeContext;
  const Bg = Background(backgroundStyle);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    // 装修的时候切换屏幕大小，获取到的商品名称实际宽度不准确，需要延时获取
    const timeout = 1000; // 延时时长
    const swiperTimer = setTimeout(() => {
      sliderRef?.current?.swiper?.updateSize();
      sliderRef?.current?.swiper?.update(true);
    }, timeout);
    return () => {
      if (swiperTimer) {
        clearTimeout(swiperTimer);
      }
    };
  }, [
    boxWidth,
    clientWidth,
    imageRatio,
    showSale,
    showVendor,
    openCard,
    showShoppingBuy,
    textLineLimit,
    productNameSize,
    showCommentMainVal,
    showShoppingCarMainVal,
    productPriceSize,
    goodsList.length,
    backgroundStyle,
  ]);
  const onNextClick = () => {
    sliderRef?.current?.swiper?.slideNext();
  };

  const onPrevClick = () => {
    sliderRef?.current?.swiper?.slidePrev();
  };

  useEffect(() => {
    if (goodsGroup.length) {
      // eslint-disable-next-line no-shadow
      const goodsList = goodsGroup[currentIndex] || [];
      buriedPoint('goods_view', {
        goods_component_location: 'HOME_product-grid',
        goods_component_location_name: '滑动商品',
        goodsList: goodsList.map((item) => {
          // eslint-disable-next-line
          const { goodsId: goods_id, goodsType: goods_type = '', title } = item;
          return {
            goods_id,
            goods_type,
            title,
          };
        }),
      });
    }
  }, [goodsGroup.length, currentIndex]);

  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Style className={cn(`cn-${boxWidth}`, elemClass)}>
        <Wrapper paddingTop='vContainer' paddingBottom='vContainer'>
          <div className={cn('feature-collection-box')}>
            {title && (
              <div className={cn('title-container', textAlign)}>
                <Text size={titleSize}>{title}</Text>
              </div>
            )}
            {!isMobile && (
              <div className={cn('swiper-box')}>
                <div
                  className={cn('section-button', `section-button-${slideStyle}`, 'section-button--prev', {
                    disabled: currentIndex === 0,
                  })}
                  onClick={onPrevClick}
                >
                  <svg className={cn('section-button-icon')} aria-hidden='true'>
                    <use xlinkHref={`#${slideStyle === 'long-arrow' ? 'iconic-jiantouzuo' : 'iconic-zuoyouqiehuan'}`} />
                  </svg>
                </div>
                <Swiper
                  ref={sliderRef}
                  noSwiping
                  autoHeight
                  pagination={false}
                  onSlideChange={(swiper) => {
                    const { realIndex } = swiper;
                    setCurrentIndex(realIndex);
                  }}
                >
                  {goodsGroup?.length > 0 &&
                    goodsGroup?.map((list, index) => {
                      return (
                        <SwiperSlide key={`group-${index}`}>
                          <GoodsCollectionWithLayout
                            className='feature-collection-grid'
                            goodsList={list || []}
                            initialData={initialData}
                            settings={{ ...settings, grid: 4, rows: 1 }}
                          />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
                <div
                  className={cn('section-button', `section-button-${slideStyle}`, 'section-button--next', {
                    disabled: currentIndex === goodsGroup?.length - 1,
                  })}
                  onClick={onNextClick}
                >
                  <svg className={cn('section-button-icon')} aria-hidden='true'>
                    <use xlinkHref={`#${slideStyle === 'long-arrow' ? 'iconic-jiantouyou' : 'iconic-xiala'}`} />
                  </svg>
                </div>
              </div>
            )}
            {isMobile && (
              <GoodsCollectionWithSwiper
                goodsList={goodsList || []}
                initialData={initialData}
                settings={settings}
                indicatorArrowStyle={slideStyle}
              />
            )}
          </div>
        </Wrapper>
      </Style>
    </Bg>
  );
};

ProductGrid.getInitialProps = getCollectionInitialProps;
export default ProductGrid;
