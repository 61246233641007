import styled from 'styled-components';

export const Style = styled.div`
  position: relative;
  z-index: 2;
  .main-media {
    display: flex;
    align-items: center;
    justify-content: center;
    .photo-loupe-body {
      border-radius: var(--border_radius_pd_card);
      overflow: hidden;
    }
  }
`;
