import styled from 'styled-components';

export const Style = styled.div`
  .click-able {
    cursor: pointer;
  }
  .logo-img-item {
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
  }
  .default-img-wrap {
    padding-top: 100%;
    width: 100%;
    display: block;
    position: relative;

    .logo-img-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
