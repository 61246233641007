import { hexToRGBA, RGBAToHex } from 'utils/color';
import styled from 'styled-components';

export const Style = styled.div`
  height: ${(props) => props.height + 'px'};
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 24px;
  background: #ffffff;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  gap: 24px;
  align-content: flex-start;
`;
