// 视频轮播&图片（电子）
import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { Style } from './style';
import { formateCamelcase } from 'utils/formate';
import TextContainer from '../../components/text-container';
import { useClientWidth, useThemeContext } from 'hook/global-theme';

import SwiperContainer from '../../components/swiper-container';
import VideoContainer from '../../components/video-container';
import throttle from 'lodash/throttle';
// import localApi from 'api/local';
import useFillImage from 'hook/use-fill-image';

// 容器宽度
const BoxWidth = {
  fix_960: 'fix-960',
  fix_1280: 'fix-1280',
  fix_1440: 'fix-1440',
};
const SlideVideoPic = (props) => {
  const sectionRef = useRef();
  const areaRef = useRef();
  const videosSwiperRef = useRef();
  const imageSwiper = useRef();
  const [videoDoms, setVideoDoms] = useState([]);
  const { settings, blocks, block_order: blockOrder, elemClass } = props;
  const {
    videoAlign = 'left', // 视频方向
    autorotateSpeed = 5, // 轮播图播放时间间隔
    backgroundImage, // 背景图片
    boxWidth, // 容器宽度
  } = formateCamelcase(settings);
  const { image: bgImg } = useFillImage(backgroundImage);

  // 拆分图片和视频数据
  const images = [];
  const videos = [];

  blockOrder?.forEach((order) => {
    let newBlock = blocks[order] || {};

    if (newBlock?.type === 'image') {
      newBlock = formateCamelcase(newBlock);
      newBlock &&
        images.push({
          type: 'img',
          src: newBlock?.settings.image,
          position: newBlock?.settings.imagePosition,
        });
    } else {
      // 这里是处理视频里的文字按钮配置
      const { settings: settings2, blocks: blocks2, block_order: blockOrder2 } = newBlock;
      const textBtnArray = [];
      blockOrder2?.forEach((order2) => {
        let newBlock2 = blocks2[order2];
        newBlock2 = formateCamelcase(newBlock2);
        const settings = newBlock2?.settings;
        // 按钮链接地址需要转换
        if (newBlock2.type === 'btn') {
          const btnLink = newBlock2?.settings?.btnLink || '';
          if (btnLink) {
            settings.btnLink = JSON.parse(btnLink).url;
          }
        }
        newBlock2 && textBtnArray.push({ type: newBlock2?.type, ...newBlock2?.settings });
      });

      videos.push({
        blocks: textBtnArray,
        settings: formateCamelcase(settings2),
      });
    }
  });
  // 初始化当前展示的视频块
  videos.currentIndex = 0;
  // 获取到需要的样式
  const { clientWidth, clientHeight } = useClientWidth();
  const { isMobile } = useThemeContext();
  // 拿到组件真实展示的宽度高度
  const boxWidthReal = Math.min(clientWidth, boxWidth.substr(boxWidth.indexOf('_') + 1));
  const boxHeightReal = (800 / 1440) * boxWidthReal;
  // 异部函数拿不到最新值，用proxyRef中转
  const proxyRef = useRef();
  proxyRef.current = {
    videoDoms,
    clientHeight,
    boxWidthReal,
    boxHeightReal,
    beforeScrollTop: 0,
    canChangeVideo: true,
  };
  // const getMedieUrl = (mediaId) => {
  //     return localApi.getVideoPlayInfo({ mediaId });
  // };
  useEffect(() => {
    // 获取所有的视频dom
    setVideoDoms(sectionRef.current.getElementsByTagName('video'));
    // // 转换媒体的地址
    // videos.forEach(async (video) => {
    //     if (video?.settings?.mediaId) {
    //         const res = await getMedieUrl(video?.settings?.mediaId);
    //         video.settings.image = res?.playUrl;
    //     }
    // });
  }, [videos]);

  // 滚动事件处理
  const handleScroll = () => {
    const { videoDoms, boxHeightReal } = proxyRef.current;
    // 根据area的滚动位置判定当前展示的视频，当前展示的视频播放，其他则暂停
    const areatop = areaRef?.current?.getBoundingClientRect()?.top;
    const areaHeight = areaRef?.current?.getBoundingClientRect()?.height;
    // 得到当前应该展示的视频索引
    let index = Math.round(areatop / (-areaHeight / videos.length));
    if (index <= 0) {
      index = 0;
    }
    if (index >= videos.length - 1) {
      index = videos.length - 1;
    }
    Array.from(videoDoms)?.forEach((video, i) => {
      if (i === index) {
        videoDoms[i].play();
      } else {
        videoDoms[i].pause();
      }
    });
    videosSwiperRef.current.style.marginTop = `${-index * boxHeightReal}px`;
  };
  const handleScrollThrottle = throttle(handleScroll, 100);

  useEffect(() => {
    if (!isMobile && videos.length > 1) {
      // 滚动事件处理
      window.addEventListener('scroll', handleScrollThrottle, {
        passive: false,
      });

      return () => {
        window.removeEventListener('scroll', handleScrollThrottle, {
          passive: false,
        });
      };
    }
  }, [isMobile, videos.length]);
  // 容器变化或屏幕宽度变化，更新swiper
  const resize = () => {
    // onResize事件里执行update一直不生效，加个延时很重要！！！
    setTimeout(() => {
      imageSwiper?.current?.swiper?.update();
    }, 500);
  };
  useEffect(() => {
    resize();
  }, [boxWidth]);

  const contentDom = (
    <div
      ref={sectionRef}
      className={cn('slide-video-pic-section', elemClass, BoxWidth[boxWidth], { 'is-mobile': isMobile })}
    >
      <div
        className={cn('slide-video-pic', !images || images.length === 0 ? 'no-image' : 'has-image', {
          'video-left': videoAlign === 'left',
        })}
      >
        {images && images.length > 0 && (
          <div className='imgs-container'>
            <SwiperContainer
              ref={imageSwiper}
              effect='fade'
              className='swiper'
              loop
              height='100%'
              // 手机端只取第一张图片展示
              contents={isMobile ? [images[0]] : images}
              autoPlay
              autoPlaySpeed={autorotateSpeed}
              paginatorStyle='dots'
              onResize={resize}
            />
          </div>
        )}
        {videos && videos.length > 0 && (
          <div className={cn('videos-container', { 'first-has-video': videos[0].settings?.videoLink })}>
            <div className='videos-swiper' ref={videosSwiperRef}>
              {videos.map((video, index) => {
                return (
                  <div
                    className={cn('video-text-container', {
                      'no-video': !video?.settings?.videoLink,
                    })}
                    key={index}
                  >
                    <TextContainer
                      className={cn('txt-container')}
                      blocks={video?.blocks}
                      buttonLabelAlign={video?.settings?.buttonLabelAlign}
                    />
                    {video?.settings?.videoLink && (
                      <div className='video-div'>
                        <VideoContainer
                          width='100%'
                          height='100%'
                          ref={video.ref}
                          loop='loop'
                          firstImage={video.settings.image}
                          mutedSwitch={!video?.settings?.muteVideoPlaying}
                          controls={!!isMobile}
                          autoPlay={!isMobile}
                          mediaId={video?.settings.mediaId}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
  return (
    <Style boxWidthReal={boxWidthReal} bgImg={bgImg}>
      {/* 如果存在多个视频的情况，会使用sticky布局，以实现视觉上的效果：滚动时页面不滚动，滚动视频 */}
      {videos.length > 1 && !isMobile ? (
        <div className='area-stage' ref={areaRef} style={{ height: boxHeightReal * (videos.length + 1) }}>
          <div className='area-content'>{contentDom}</div>{' '}
        </div>
      ) : (
        contentDom
      )}
    </Style>
  );
};

export default SlideVideoPic;
