import React, { useState, useEffect } from 'react';
import { Style } from './style';
import { GoodsPolicy } from './goods-policy';

export const ServiceSupport = (props) => {
  return (
    <Style>
      {props.children}
      <GoodsPolicy />
    </Style>
  );
};
