import styled from 'styled-components';

export const BaseGoodsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  &.right {
    flex-direction: row-reverse;
  }
  &.style1 {
    .detail-right-container {
      flex: 1;
      margin-left: ${(props) => props.theme.getSpace(props.theme.hCards)};
      overflow: hidden;
    }
    &.right {
      .detail-right-container {
        margin-left: 0;
      }
      .detail-left-container {
        margin-left: ${(props) => props.theme.getSpace(props.theme.hCards)};
      }
    }
  }
  &.style2 {
    .detail-left-container,
    .detail-right-container {
      width: calc((100% - ${(props) => props.theme.getSpace(props.theme.hCards)}) / 2);
    }
  }
  &.style3 {
    & > div {
      width: calc((100% - ${(props) => props.theme.getSpace(props.theme.hCards)}) / 2);
    }
  }

  // 老样式1适配存在遗留问题，这里做下特殊处理
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    &.style1 {
      flex-direction: column !important;
      .detail-left-container {
        margin: 0 auto !important;
      }
      .detail-right-container {
        margin: 0 !important;
      }
    }
  }
`;
export const Style = styled.div`
  &.goods-detail-layout {
    margin-top: ${(props) => props.theme.getSpace(props.theme.vContainer)};
    box-sizing: border-box;
    &.is-scroll-animation {
      .detail-left-container,
      .detail-right-container {
        // 这里的高度计算 = 整屏高-导航高度-marginTop
        max-height: calc(100vh - 60px - ${(props) => props.theme.getSpace(props.theme.vContainer)});
        overflow: hidden;
      }
    }

    .goods-detail-top-content {
      /* display: flex;
            justify-content: space-between;
            &.right {
                flex-direction: row-reverse;
            }
            &.style1 {
                .detail-right-container {
                    flex: 1;
                    margin-left: ${(props) => props.theme.getSpace(props.theme.hCards)};
                    overflow: hidden;
                }
                &.right {
                    .detail-right-container {
                        margin-left: 0;
                    }
                    .detail-left-container {
                        margin-left: ${(props) => props.theme.getSpace(props.theme.hCards)};
                    }
                }
            }
            &.style2 {
                .detail-left-container,
                .detail-right-container {
                    width: calc((100% - ${(props) => props.theme.getSpace(props.theme.hCards)}) / 2);
                }
            }
            &.style3 {
                & > div {
                    width: calc((100% - ${(props) => props.theme.getSpace(props.theme.hCards)}) / 2);
                }
            } */
    }

    &.is-mobile {
      .goods-detail-top-content {
        flex-direction: column !important;
      }
      .detail-right-container {
        margin-top: ${(props) => props.theme.getSpace(props.theme.vContainer)};
        margin-left: 0 !important;
      }
      .detail-left-container {
        margin: 0 !important;
      }
    }
  }
  // 老样式1适配存在遗留问题，这里做下特殊处理
  /* @media screen and (max-width: 1024px) and (min-width: 768px) {
        .goods-detail-top-content.style1 {
            flex-direction: column !important;
            .detail-left-container {
                margin: 0 auto !important;
            }
            .detail-right-container {
                margin: 0 !important;
            }
        }
    } */

  .price-wish-wrapper {
    /* margin-top: var(--pm-16-10); */
    /* display: flex;
        align-items: center;
        justify-content: space-between; */
    /* .wish-wait {
            cursor: wait;
        }
        .wish-point {
            cursor: pointer;
        }
        .wish-icon-wrapper {
            display: flex;
            align-items: center;
            span {
                padding-left: 10px;
            }
        }
        .goods-detail-icon {
            width: 20px;
            height: 20px;
        } */
  }

  .compare-price {
    text-decoration: line-through;
    padding-left: 12px;
    font-size: ${(props) => props.theme.getBaseFontSize([14, 16])};
    font-weight: 400;
    color: #969799;
  }

  .cur-image {
    display: flex;
    align-items: center;
    img {
      /* max-height: 630px; */
      max-width: 100%;
      object-fit: contain;
    }
    .arrow {
      cursor: pointer;
      width: 40px;
      cursor: pointer;
      display: block;
      height: 40px;
      text-align: center;
      line-height: 40px;
      fill: var(--color_body_text);
      &.disabled {
        opacity: 0.4;
      }
    }
    .left-arrow {
      margin-right: ${(props) => props.theme.getSpace([10, 16])};
    }
    .right-arrow {
      margin-left: ${(props) => props.theme.getSpace([10, 16])};
    }
    #photo-loupe {
      .photo-loupe-body {
        /* height: auto !important; */
        .mouse-block {
          height: 50% !important;
        }
      }
      .magnifier-memo {
        height: 100% !important;
        img {
          height: auto !important;
        }
      }
    }
  }

  /* 移动轮播图 */
  .goods-detail-swiper.swiper-container {
    width: 100vmin;
    overflow: hidden;
    margin-top: -30px;
    padding-bottom: 40px;
    .swiper-pagination-bullet-active {
      background-color: var(--color_button);
    }
    .swiper-slide {
      align-items: center;
      display: flex;
      &.swiper-slide-active {
        padding: 0 24px;
        box-sizing: border-box;
      }
      &.swiper-slide-prev {
        position: relative;
        right: -24px;
      }
      &.swiper-slide-next {
        position: relative;
        left: -12px;
      }
      &.swiper-slide-prev {
        position: relative;
        right: -12px;
      }
      img {
        width: 100%;
      }
    }
  }

  .goods-title {
    font-size: ${(props) => props.theme.getBaseFontSize(props.theme.h6)};
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    color: var(--color_body_text);
    word-break: break-word;
  }
  .sku-title {
    color: rgba(var(--color_body_text_rgb), 0.8);
  }
  #share {
    color: var(--color_body_text);
  }
  .goods-price {
    font-size: ${(props) => props.theme.getBaseFontSize([16, 18])};
    font-weight: bold;
    color: var(--color_sale_text);
  }

  .sku-wrapper {
    margin-top: ${(props) => props.theme.getSpace([18, 24])};
  }
  .goods-sku-detail {
    display: flex;
    flex-wrap: wrap;
    margin-top: ${(props) => props.theme.getSpace([8, 12])};
  }
  .goods-detail-share-btns {
    margin-top: ${(props) => props.theme.getSpace([24, 40])} 0;
  }

  .goods-buy-button {
    margin-top: ${(props) => props.theme.getSpace([18, 24])};

    button {
      font-weight: 600;
    }
    .zent-btn-primary {
      background: ${(props) => props.theme.colorButton} !important;
      color: ${(props) => props.theme.colorButtonText} !important;
    }
  }
  .theme-sub {
    width: 48%;
    background: var(--color_small_button_bg);
  }

  .theme-primary {
    margin-left: 4% !important;
    width: 48%;
  }

  .goods-detail-text {
    font-size: 16px;
    margin-top: 16px;
    /* 富文本显示的优化*/
    * {
      font-family: var(--font-stack-body);
    }
    img {
      margin-top: 0;
      max-width: 100%;
      vertical-align: top;
    }
    video {
      max-width: 100%;
      height: auto;
    }
    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      padding-left: 20px !important;
      li {
        display: list-item;
        text-align: -webkit-match-parent;
      }
    }
    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      padding-left: 20px !important;
      li {
        display: list-item;
        text-align: -webkit-match-parent;
      }
    }
    table,
    tr,
    td {
      border-width: 1px;
      border-style: solid;
    }
  }
  /* .arrow {
        fill: #d1d5db;
    } */
  /* .point {
        width: 8px;
        height: 8px;
        background: #d1d5db;
        border-radius: 50%;
        margin-right: 10px;
        &.active {
            background: #1a1a1b;
        }
    } */
  .sealOut {
    width: 312px;
  }
  /* .point-list {
        display: flex;
        margin-top: margin-top: var(--pm-24-18);
        cursor: pointer;
    } */
  .zent-number-input {
    .zent-input {
      min-width: 10px !important;
    }
    &.zent-input-wrapper {
      width: 105px !important;
    }
    .zent-number-input-arrow-disable {
      background: transparent !important;
    }
  }

  /* @media screen and (max-width: 768px) {
        .rate-area {
            display: none;
        }
    } */
`;
/**
 *  移动端 padding： 15px
 *  PC: padding: 80px
 *
 *
 * 第一种：
 * 平铺： 各个端宽度都是100%
 *
 * 第2种：表单页
 * PC : padding： 120px
 * 移动端： padding： 160px
 *
 *  第3种：表单页
 *  padding： 80px
 * 移动端：padding 24px
 */
export const ImgContainer = styled.div`
  left: ${(props) => props.left || '0px'};
  position: absolute;
  top: 0;
  display: flex;
`;

export const ProductEmpty = styled.div`
  height: 100%;
  display: flex;
  min-height: 372px;
  align-items: center;
  justify-content: center;
  .empty-product-container {
    text-align: center;
  }
  .empty-product-icon {
    width: 80px;
    height: 80px;
    fill: rgba(var(--color_body_text_rgb), 0.6);
    display: inline-block;
  }
  .tip-text {
    margin: 8px 0 24px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgba(var(--color_body_text_rgb), 0.4);
  }
`;
