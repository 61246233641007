import React, { useState, useEffect, useRef } from 'react';
import { Select, Icon } from 'zent';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import siteApi from 'api/site';
import { GlobalStyle } from './style';

const { Option } = Select;

// 考虑到这个组件其他地方复用的可能性不大，只供总部选择子店用
export const MultiSelect = (props) => {
  const intl = useIntl();
  const { sectionThemeData = {}, defaultSite = '', popupClassName, callback } = props;
  const { settings = {}, block_order: blockOrder = [], blocks = {} } = sectionThemeData;
  const {
    switch_method: switchMethod = 'full_screen',
    show_site_logo: showSiteLogo = true,
    show_site_name: showSiteName = true,
    show_site_currency: showSiteCurrency = true,
  } = settings;
  const [currentSite, setCurrentSite] = useState(defaultSite);

  const [newBlocks, setNewBlocks] = useState(blocks);

  const onSiteChange = (event) => {
    const kdtId = event?.target?.value;
    setCurrentSite(kdtId);
    let domain = '';
    for (const key in blocks) {
      if (blocks[key]?.settings?.kdtId === kdtId) {
        domain = `https://${blocks[key]?.settings?.mainHost}`;
        break;
      }
    }
    if (callback) {
      callback(domain);
    }
    if (defaultSite && !window.isEdit) {
      // 如果传入了默认店铺，说明在子店，选择后直接跳走
      window.location.href = domain;
    }
  };

  const lastBlockRef = useRef(null);

  const isShowCurrency = showSiteCurrency && switchMethod === 'full_screen';

  useEffect(() => {
    if (isEmpty(blocks)) {
      // 为空直接退出
      return;
    }

    if (!isEqual(blocks, lastBlockRef.current) && showSiteCurrency) {
      // 查询blocks店铺对应的货币
      lastBlockRef.current = blocks;
      const kdtIds = [];
      const blocksKey = [];
      for (const key in blocks) {
        const item = blocks[key];
        kdtIds.push(item?.settings?.kdtId);
        blocksKey.push(key);
      }
      siteApi.bulkGetChainSites({ kdtIds }).then((res) => {
        blocksKey.forEach((key, index) => {
          blocks[key].settings.siteCurrency = res?.[index]?.currency;
        });
        setNewBlocks({ ...blocks });
      });
    }
  }, [blocks]);

  return (
    <>
      <GlobalStyle />
      <Select
        className='multi-select-container'
        popupClassName={cn('multi-select-container-popup', popupClassName)}
        placeholder={intl.formatMessage({
          id: 'a1ea401983f8472d9cbe237e223be3a0',
          defaultMessage: '请选择站点',
        })}
        value={currentSite}
        onChange={onSiteChange}
        autoWidth
      >
        {blockOrder.map((key) => {
          const item = newBlocks?.[key]?.settings || {};
          return (
            <Option value={item.kdtId} key={key}>
              <div className='item-wrapper'>
                {showSiteLogo &&
                  (item.image ? (
                    <img src={item.image} alt='' className='shop-logo logo-item' />
                  ) : (
                    <svg className='default-logo logo-item' aria-hidden='true'>
                      <use xlinkHref='#icondianputouxiang' />
                    </svg>
                  ))}
                {showSiteName && <span className='shop-name'>{item.site_name}</span>}

                {isShowCurrency && <span className='shop-currency'>{item.siteCurrency}</span>}
              </div>
              <svg
                width={16}
                height={16}
                fill='#2654FF'
                className={cn('sel-icon', { 'show-icon': currentSite === item.kdtId })}
              >
                <use xlinkHref='#iconic-gouxuan' />
              </svg>
            </Option>
          );
        })}
      </Select>
    </>
  );
};
