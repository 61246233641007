import styled from 'styled-components';

export const Style = styled.div`
  .product-media-two {
    display: grid;
    grid-template-columns: repeat(${(props) => props.gridNum || 2}, 1fr);
    grid-row-gap: 8px;
    grid-column-gap: 8px;
    .item {
      .content {
        .video-container {
          background-color: #000;
          .img {
            object-fit: contain !important;
          }
        }
      }
    }
  }
`;
