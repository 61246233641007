import React from 'react';
import cn from 'classnames';
import { Style } from './style';

const dealTextAlign = (str) => {
  const align = str?.split(' ');
  const [horizontal, vertical] = align;
  return `text-${horizontal}-${vertical}`;
};

const TextPosition = (props) => {
  const {
    textPosition = 'center center',
    className,
    matchScreen = false,
    rDistance = 15, // 百分比 or px 水平
    cDistance = 10, // 垂直
    style,
  } = props;
  const positionClass = dealTextAlign(textPosition);

  return (
    <Style
      className={cn(className, positionClass, { 'match-screen': matchScreen }, { 'match-self': !matchScreen })}
      style={style}
      rDistance={rDistance}
      cDistance={cDistance}
    >
      {props.children}
    </Style>
  );
};

export default TextPosition;
