import { useEffect } from "react";
import { generateRandomString } from 'utils/web-tool';
import shareApi from 'api/share';
import LocalStorage from 'utils/local-storage';


export const useShareLinkCallback = () => {
  const sharerId = __isServer ? null : new URLSearchParams(window?.location?.search)?.get('sharerId');
  const customerId = __isServer ? null : window?.global_data?.userInfo?.customerId;
  
  /** 分享店铺获取积分 */
  useEffect(() => {
    if (__isServer || !sharerId) return;
    if (sharerId == customerId) return; // 分享者和点击者为同一个人不添加积分
    const storageClickerId = LocalStorage.get('shareClickerId');
    if (!customerId && storageClickerId) return; // 未登录的场景下有点击过, 也不添加积分

    let clickerId = customerId;
    if (!clickerId) { // H5 未登录, 生成一个随机串
      clickerId = generateRandomString(10);
      LocalStorage.set('shareClickerId', clickerId);
    }
    shareApi.shareShopGetPoints({
      clicker: {
        clickerId,
      },
      sharer: {
        sharerId,
      },
      source: 'h5',
    });
  }, [sharerId, customerId]);
}