import styled from 'styled-components';

// typeof props.rDistance === 'string' 传px进来 r水平 c垂直
export const Style = styled.div`
  position: absolute;
  word-wrap: break-word;
  max-width: 80%;
  &.text-left-top {
    text-align: left;
    left: ${(props) => props.theme.getSpace(props.theme.pCards)};
    top: ${(props) => props.theme.getSpace(props.theme.pCards)};
  }

  &.match-screen {
    &.text-center-top {
      text-align: center;
      top: ${(props) => props.theme.getSpace(props.theme.pCards)};
      left: 0;
      right: 0;
      margin: auto;
    }
    &.text-center-center {
      text-align: center;
      top: 50%;
      transform: translate(0%, -50%);
      left: 0;
      right: 0;
      margin: auto;
    }
    &.text-center-bottom {
      text-align: center;
      bottom: ${(props) => props.theme.getSpace(props.theme.pCards)};
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &.match-self {
    &.text-center-top {
      text-align: center;
      top: ${(props) => props.theme.getSpace(props.theme.pCards)};
      left: 50%;
      transform: translateX(-50%);
      margin: auto;
    }
    &.text-center-bottom {
      text-align: center;
      bottom: ${(props) => props.theme.getSpace(props.theme.pCards)};
      left: 50%;
      margin: auto;
      transform: translateX(-50%);
    }
    &.text-center-center {
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
    }
  }

  &.text-right-top {
    text-align: right;
    right: ${(props) => props.theme.getSpace(props.theme.pCards)};
    top: ${(props) => props.theme.getSpace(props.theme.pCards)};
  }
  &.text-left-center {
    text-align: left;
    left: ${(props) => props.theme.getSpace(props.theme.pCards)};
    top: 50%;
    transform: translate(0, -50%);
  }

  &.text-right-center {
    text-align: right;
    right: ${(props) => props.theme.getSpace(props.theme.pCards)};
    top: 50%;
    transform: translate(0, -50%);
  }

  &.text-left-bottom {
    text-align: left;
    left: ${(props) => props.theme.getSpace(props.theme.pCards)};
    bottom: ${(props) => props.theme.getSpace(props.theme.pCards)};
  }
  &.text-right-bottom {
    text-align: right;
    right: 50%;
    bottom: ${(props) => props.theme.getSpace(props.theme.pCards)};
    right: ${(props) => props.theme.getSpace(props.theme.pCards)};
  }
`;
