import styled from 'styled-components';
import { PriceStyle } from '../collection/style';

export const PriceStyleT = styled(PriceStyle)`
  text-align: center;
  display: inline-block;
`;

export const Style = styled.div`
  .feature-collection-box {
    width: 100%;
    .title-container {
      padding: 0 0 ${(props) => props.theme.getSpace(props.theme.vTitleContent)};
      display: flex;
      align-items: center;
      font-weight: 500;
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
      color: var(--color_text);
      &.center {
        justify-content: center;
      }
      &.left {
        justify-content: flex-start;
      }
    }
    .swiper-box,
    .mobile-swiper-box {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      .swiper-container {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
      }
      .section-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${(props) => props.theme.getSpace([32, 36, 40])};
        height: ${(props) => props.theme.getSpace([32, 36, 40])};
        position: absolute;
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
        }
      }
      .section-button-icon {
        height: ${(props) => props.theme.getSpace([24, 28, 32])};
        width: ${(props) => props.theme.getSpace([24, 28, 32])};
      }
      .section-button-arrow {
        &.section-button--prev {
          left: ${(props) => props.theme.getSpace([0, -52, -56])};
        }
        &.section-button--next {
          right: ${(props) => props.theme.getSpace([0, -52, -56])};
        }
        .section-button-icon {
          fill: var(--color_body_text);
        }
        &.disabled {
          .section-button-icon {
            fill: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.4)} !important;
          }
        }
      }
      .section-button-square {
        background-color: var(--color_button);
        &.section-button--prev {
          left: ${(props) => props.theme.getSpace([0, -52, -56])};
        }
        &.section-button--next {
          right: ${(props) => props.theme.getSpace([0, -52, -56])};
        }
        .section-button-icon {
          fill: var(--color_button_text);
        }
        &.disabled {
          background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorButton, 0.6)} !important;
        }
      }
      .section-button-circle {
        background-color: var(--color_button);
        border-radius: 50%;
        &.section-button--prev {
          top: ${(props) => props.theme.getSpace([-72, -76, -80])};
          right: ${(props) => props.theme.getSpace([56, 60, 64])};
        }
        &.section-button--next {
          top: ${(props) => props.theme.getSpace([-72, -76, -80])};
          right: 0;
        }
        .section-button-icon {
          fill: var(--color_button_text);
        }
        &.disabled {
          background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorButton, 0.6)} !important;
        }
      }
      .section-button-long-arrow {
        width: ${(props) => props.theme.getSpace([16, 20, 24])};
        height: ${(props) => props.theme.getSpace([16, 20, 24])};
        &.section-button--prev {
          right: ${(props) => props.theme.getSpace([40, 44, 48])};
          top: -70px;
        }
        &.section-button--next {
          right: 0;
          top: -70px;
        }
        .section-button-icon {
          fill: var(--color_body_text);
        }
        &.disabled {
          .section-button-icon {
            fill: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.4)} !important;
          }
        }
      }
      .section-button-arrow-bottom {
        &.section-button--prev {
          right: ${(props) => props.theme.getSpace([40, 44, 48])};
          top: -72px;
        }
        &.section-button--next {
          right: 0;
          top: -72px;
        }
        .section-button-icon {
          fill: var(--color_body_text);
        }
        &.disabled {
          .section-button-icon {
            fill: ${(props) => props.theme.hexToRGBA(props.theme.colorBodyText, 0.4)} !important;
          }
        }
      }
    }
  }
  &.cn-full_screen {
    .goods-name,
    .goods-price {
      padding: 0 ${(props) => props.theme.getSpace(props.theme.g16)};
    }
  }
`;
export const GoodsPrevStyle = styled.div`
  .add-shopping-car-btn {
    border: none;
    font-size: var(--base-font-16-14);
    font-weight: 500;
    color: var(--color_small_button_text_border);
    line-height: 16px;
    text-align: center;
    width: 100%;
    background: transparent;
    cursor: pointer;
    border: 1px solid var(--color_small_button_text_border);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .action-wrapper {
    display: flex;
    margin-top: ${(props) => props.theme.getSpace([2, 24])};
    .btn-buy {
      flex-basis: 20px;
      flex-grow: 1;
    }
    .btn-cart {
      flex-basis: 20px;
      flex-grow: 1;
    }
  }
  .add-shopping-car-container {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .goods-name {
    margin-top: 20px;
    ${(props) => props.theme.getBaseSize([12, 16])};
    color: var(--color_body_text);
  }
  .goods-price {
    color: var(--color_sale_text);
    margin-top: ${(props) => props.theme.getSpace([4, 12])};
    ${(props) => props.theme.getBaseSize([12, 14])};
  }
  .goods-img {
    width: 100%;
  }

  @media screen and (max-width: 1140px) {
    .action-wrapper {
      flex-direction: column;
      .btn-buy {
        margin: 8px 0 !important;
      }
    }
  }
  @media screen and (min-width: 769px) {
    .add-shopping-car-container {
      max-height: 100%;
      overflow: auto;
      box-shadow: 0px 2px 24px 0px rgba(209, 213, 219, 0.4);
    }
  }
  @media screen and (max-width: 768px) {
    .add-shopping-car-container {
      position: fixed;
      align-items: flex-end;
      background-color: rgba(0, 0, 0, 0.3);
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 9999;
      left: 0;
    }
  }
`;
