import React from 'react';
import Style from './style';
import cn from 'classnames';
// import { getUrl, isUrl } from '../../../../../../util/formate';
import { Link } from 'react-router-dom';

export const PcSubMenu = (props) => {
  const { menuList = [], menuIndex, refNode } = props;
  const show = Array.isArray(menuList) && menuList.length;
  const isFirstLevelMenu = menuIndex === 1;
  return show ? (
    <Style
      ref={refNode}
      className={cn(
        'header-sub-menu-container',
        isFirstLevelMenu ? 'first-level-header-sub-menu' : 'header-sub-menu-not-first',
      )}
    >
      {menuList.map((item) => {
        const { menuItemName, shopTemplateMenuItemTreeNodeCustomerVO: subMenuList = [], url } = item;
        return (
          <div className='header-sub-menu-box'>
            <p className={cn('header-sub-minu-item', isFirstLevelMenu && 'first-level-menu')}>
              <a href={url}>{menuItemName}</a>
              {/* {isUrl(url) ? <a href={url}>{menuItemName}</a> : <Link to={url}>{menuItemName}</Link>} */}
            </p>
            <PcSubMenu menuIndex={menuIndex + 1} menuList={subMenuList} />
          </div>
        );
      })}
    </Style>
  ) : null;
};
