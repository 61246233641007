// 服装展示组件
// 设计方案，此组件其实并没有跟滚动条相关的动效，商品图列表跟着滚动条正常滚动就行，封面图用sticky布局，就能有封面图和商品图错乱的轮播效果
// 封面图用sticky布局，商品图采用列表（非轮播）正常布局。
// 难点：1:sticky布局的外层容器的高度计算，高度跟商品列表图数量有关 2:主图入场和出场动画对应滚动条位置
import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { Style } from './style';
import { formateCamelcase } from 'utils/formate';
import TextContainer from '../../components/text-container';
import TextPosition from '../../components/text-position';
import { useClientWidth, useThemeContext } from 'hook/global-theme';
import ContainerSize from '../../components/container-size';
import ImageContainer from '../../components/image-container';
import throttle from 'lodash/throttle';

const Clothes = (props) => {
  const { isMobile, getSpace, hContainer, hCards, pImagesLarge } = useThemeContext();
  const areaRef = useRef();
  const mainImageAreaContentRef = useRef();
  let { elemClass, blocks, block_order, settings } = props;
  // 转换数据格式
  settings = formateCamelcase(settings);
  // 处理主图片数据
  const mainImageProps = blocks[block_order[0]] || {};
  const mainImageData = {
    settings: formateCamelcase(mainImageProps.settings),
    textContainerProps: {
      blocks: [],
      buttonLabelAlign: mainImageProps?.settings?.align_text,
    },
  };
  mainImageProps.block_order?.forEach((key) => {
    const newBlock = mainImageProps?.blocks[key];
    const settings = formateCamelcase(newBlock?.settings);
    settings.type = newBlock?.type;
    // 按钮链接地址需要转换
    if (settings.type === 'btn' && settings?.btnLink) {
      settings.btnLink = JSON.parse(settings?.btnLink || '')?.url;
    }
    mainImageData.textContainerProps.blocks.push({ ...settings });
  });
  // 处理列表图片数据
  const listImageProps = blocks[block_order[1]];
  const listImageData = {
    settings: formateCamelcase(listImageProps?.settings),
    data: [],
  };
  listImageProps.block_order?.forEach((key) => {
    const imageProps = listImageProps?.blocks[key];
    const imageData = {
      key,
      settings: formateCamelcase(imageProps?.settings),
      textContainerProps: {
        blocks: [],
        buttonLabelAlign: listImageProps?.settings?.align_text,
      },
    };
    imageProps?.block_order?.forEach((textBtnKey) => {
      const settings = formateCamelcase(imageProps?.blocks[textBtnKey]?.settings);
      settings.type = imageProps?.blocks[textBtnKey]?.type;
      // 按钮链接地址需要转换
      if (settings.type === 'btn' && settings?.btnLink) {
        settings.btnLink = JSON.parse(settings?.btnLink)?.url;
      }
      imageData.textContainerProps.blocks.push({ ...settings });
    });
    listImageData.data.push(imageData);
  });

  // 拿到组件容器真实展示的宽度
  const { clientWidth, clientHeight } = useClientWidth();
  let boxWidthReal;
  if (settings?.boxWidth === 'full_screen' || settings?.boxWidth === 'full_width') {
    boxWidthReal = clientWidth;
  } else if (settings?.boxWidth === 'margin_80') {
    boxWidthReal = clientWidth - parseFloat(getSpace(hContainer));
  } else {
    boxWidthReal = Math.min(clientWidth, settings?.boxWidth?.substr(settings?.boxWidth?.indexOf('_') + 1));
  }
  // 高度 = （商品图长度）* （  商品图的宽度 * 宽高比 + 底部边距）- 商品图底部边距 + 子图容器上下边距 （pImagesLarge*2）
  const temp = (listImageData?.settings?.imageRatio || '1/1').split('/');
  const areaHeight = Math.max(
    clientHeight,
    listImageData?.data?.length * ((480 / 1440) * boxWidthReal * (temp[1] / temp[0]) + parseFloat(getSpace(hCards))) -
      parseFloat(getSpace(hCards)) +
      parseFloat(getSpace(pImagesLarge)) * 2,
  );
  // 异部函数拿不到最新值，用proxyRef中转
  const proxyRef = useRef();
  proxyRef.current = {
    clientHeight,
    areaHeight,
  };

  // 滚动事件处理,主图的入场和出场动画
  const handleScroll = (e) => {
    const { clientHeight, areaHeight } = proxyRef.current;
    // 根据area的滚动位置判定当前展示的视频，当前展示的视频播放，其他则暂停
    const areatop = areaRef?.current?.getBoundingClientRect()?.top;
    // 如果areatop<=屏幕高度的60%，且大于等于-areaHeight+屏幕高度的40% 则算show范围，否则算hide范围
    if (areatop <= clientHeight * 0.6 && areatop >= -areaHeight + clientHeight * 0.4) {
      mainImageAreaContentRef?.current?.classList.remove('hide');
      mainImageAreaContentRef?.current?.classList.add('show');
    } else {
      mainImageAreaContentRef?.current?.classList.add('hide');
      mainImageAreaContentRef?.current?.classList.remove('show');
    }
  };
  const handleScrollThrottle = throttle(handleScroll, 100);
  useEffect(() => {
    if (!isMobile) {
      // 滚动事件处理
      window.addEventListener('scroll', handleScrollThrottle, {
        passive: false,
      });

      return () => {
        window.removeEventListener('scroll', handleScrollThrottle, {
          passive: false,
        });
      };
    }
  }, [isMobile]);

  return (
    <Style className={cn('clothes-section', elemClass)} bgColor={settings?.bgColor} boxWidthReal={boxWidthReal}>
      <div
        ref={areaRef}
        className='area-stage'
        style={{ height: !isMobile && `${areaHeight}px`, backgroundColor: settings?.bgColor }}
      >
        <ContainerSize
          boxWidth={settings?.boxWidth}
          className={cn(isMobile ? 'is-mobile' : 'is-pc', {
            'main-image-right': settings?.mainImageAlign === 'right',
          })}
        >
          {' '}
          <div className='area-content' ref={mainImageAreaContentRef}>
            <ImageContainer
              heightType='full-height'
              heightRules='type3'
              className={cn(
                'cover-images',
                mainImageData?.settings?.mobileTextPosition === 'down' ? 'mobile-text-down' : 'mobile-text-up',
              )}
              fit='cover'
              position='center center'
              url={mainImageData?.settings?.image}
              alt={mainImageData?.textContainerProps?.blocks[0]?.description}
              compress={settings?.compress}
            >
              <TextPosition position={mainImageData?.settings?.textPosition}>
                <TextContainer {...mainImageData?.textContainerProps} />
              </TextPosition>
            </ImageContainer>
          </div>
          {/* // pc端子内容可以设置背景颜色或背景图，手机端则忽略 */}
          <div
            className={cn('goods-images')}
            style={{ backgroundColor: !isMobile && listImageData?.settings?.backgroundColor }}
          >
            <div className='goods-images-content'>
              {listImageData?.data?.map((imageData) => {
                return (
                  <ImageContainer
                    key={imageData.key}
                    heightType={listImageData?.settings?.imageRatio}
                    heightRules='type3'
                    className={cn(
                      'good-image',
                      listImageData?.settings?.mobileTextPosition === 'down' ? 'mobile-text-down' : 'mobile-text-up',
                    )}
                    fit='cover'
                    position='center center'
                    alt={imageData?.textContainerProps?.blocks[0]?.description}
                    url={imageData?.settings?.image}
                    compress={settings?.compress}
                  >
                    <TextPosition position={listImageData?.settings?.textPosition}>
                      <TextContainer {...imageData?.textContainerProps} />
                    </TextPosition>
                  </ImageContainer>
                );
              })}
            </div>

            {!isMobile && listImageData?.settings?.backgroundStyle === 'background_image' && (
              <div className='goods-images-bg-container'>
                <div className='area-content goods-images-bg'>
                  <ImageContainer
                    heightType='full-height'
                    heightRules='type3'
                    className='cover-images'
                    fit='cover'
                    position='center center'
                    url={listImageData?.settings?.backgroundImage}
                    alt={listImageData?.textContainerProps?.blocks[0]?.description}
                    compress={settings?.compress}
                  />
                </div>
              </div>
            )}
          </div>
        </ContainerSize>
      </div>
    </Style>
  );
};

export default Clothes;
